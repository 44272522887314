export enum RoutePaths {
  DefaultPage = '',
  Login = '/:language/login',
  Stats = '/:language/stats',
  Support = '/:language/support',
  Account = '/:language/account',
  Company = '/:language/dashboard/:companyId',
  CheckIns = '/:language/stats/check-ins',
  Invoices = '/:language/invoices',
  Contract = '/:language/contract',
  Settings = '/:language/settings/:activeTab',
  SettingsCustomizeForm = '/:language/settings/customise-form',
  UsersList = '/:language/user-management/users',
  CouponUsage = '/:language/stats/coupon-usage',
  AllCompanies = '/:language/dashboard',
  ResetPassword = '/:language/reset-password',
  ActiveMembers = '/:language/stats/active-members',
  UsersOverview = '/:language/user-management',
  SupportDetail = '/:language/support/:id',
  SupportArticles = '/:language/support/:categoryId/articles/:articleId',
  InitialPassword = '/:language/initial-password',
  SelfRegistration = '/:language/self-registration',
  EmployeeOverview = '/:language/employee-management',
  EmployeeBulkUpload = '/:language/employee-management/bulk-upload',
  EmployeeGeneralInfo = '/:language/employee-management/:employeeId/general-info/:isEdit',
  EmployeePendingRequests = '/:language/employee-management/pending-requests',
  UserChangePassword = '/:language/user-management/user/change-password',
  MarketingMaterialList = '/:language/marketing-materials',
  UserNotificationSettings = '/:language/user-management/user/notification-settings',
  ServiceProviders = '/:language/service-provides',
}
