import * as yup from 'yup';

const UpdateUserInfoSchema = yup
  .object({
    firstName: yup
      .string()
      .required('form.employee.error.first_name.required')
      .min(2, 'schema.add.new_user.first_name.min')
      .max(255, 'schema.add.new_user.first_name.max')
      .matches(/^(?!\s+$).*/, 'schema.add.new_user.first_name.match'), //  (/^[^\s]+(?:$|.*[^\s]+$)/
    lastName: yup
      .string()
      .required('form.employee.error.last_name.required')
      .min(2, 'schema.add.new_user.last_name.min')
      .max(255, 'schema.add.new_user.last_name.max')
      .matches(/^(?!\s+$).*/, 'schema.add.new_user.last_name.match'),
    // email: yup.string().required('Email is required').email('Invalid email format'),
    // position: yup.string().required('Position is required'),
  })
  .required();

export default UpdateUserInfoSchema;
