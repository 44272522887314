import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { FetchingStatus, SettingsState } from 'types';
import { getRegistrationUsers } from './getRegistrationUsers';

export const getRegistrationUsersExtraReducer = (
  builder: ActionReducerMapBuilder<SettingsState>,
) => {
  builder.addCase(getRegistrationUsers.pending, (state, action) => {
    state.selfRegistrationUsers.fetchingStatus = FetchingStatus.PENDING;
    state.selfRegistrationUsers.error = null;
  });
  builder.addCase(getRegistrationUsers.fulfilled, (state, action) => {
    state.selfRegistrationUsers.fetchingStatus = FetchingStatus.FULFILLED;
    state.selfRegistrationUsers.data = action.payload;
  });

  builder.addCase(getRegistrationUsers.rejected, (state, action) => {
    state.selfRegistrationUsers.fetchingStatus = FetchingStatus.REJECTED;
    state.selfRegistrationUsers.error = action.error;
  });
};
