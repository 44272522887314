import { Box, List, ListItem, Typography } from '@mui/material';
import contentTypeToIcon from '@utils/contentTypeToIcon';
import fileTypeToSimpleName from '@utils/fileTypeToSimpleName';
import { formatBytes } from '@utils/numberHelpers';
import DeleteIcon from '@mui/icons-material/Delete';
import { default as theme } from '@theme/theme';

type UploadedFileListProps = { data: FileList; onRemove?: () => void };

const UploadedFile = ({ file, onRemove }: { file: File; onRemove?: () => void }) => (
  <ListItem
    css={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0.5rem 0',
      gap: '1rem',
    }}>
    <Box
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: 64,
        height: 64,
        backgroundColor: theme.palette.neutralLight.main,
      }}>
      {contentTypeToIcon(file.type)}
      <Typography variant='caption'>{fileTypeToSimpleName(file.type).toUpperCase()}</Typography>
    </Box>
    <Box css={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', flex: 1 }}>
      <Typography variant='body2'>{file.name}</Typography>
      <Typography variant='caption'>{formatBytes(file.size)}</Typography>
    </Box>

    {onRemove && (
      <button
        css={{
          border: 'none',
          backgroundColor: 'transparent',
          cursor: 'pointer',
          color: theme.palette.neutralDark.main,
          '&:hover': {
            opacity: 0.8,
          },
        }}
        onClick={onRemove}>
        <DeleteIcon />
      </button>
    )}
  </ListItem>
);

const UploadedFileList = ({ data, onRemove }: UploadedFileListProps) => {
  if (!data) {
    return null;
  }

  return (
    <List>
      {Array.from(data).map((file, index) => (
        <UploadedFile key={index} file={file} onRemove={onRemove} />
      ))}
    </List>
  );
};

export default UploadedFileList;
