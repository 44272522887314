import { FetchingStatus } from 'types';
import { MarketingMaterialState } from './interfaces';

export const marketingMaterialInitialState: MarketingMaterialState = {
  api: {
    getMarketingMaterials: {
      data: null,
      fetchingStatus: FetchingStatus.IDLE,
      error: null,
    },
    addMarketingMaterials: {
      fetchingStatus: FetchingStatus.IDLE,
      data: null,
      error: null,
    },
    getFileCategory: {
      fetchingStatus: FetchingStatus.IDLE,
      data: [],
      error: null,
    },
  },
};
