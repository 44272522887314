import clsx from 'clsx';
import OtpInput from 'react18-input-otp';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'store';
import { VerifyCodeProps } from 'types';
import { UserManagementActions } from 'features/userManagement/store';
import { useIntl } from 'react-intl';
import { CCPDefaultButton, Spacer } from 'components';
import { AuthenticationSelectors } from 'features/authentication/store/authentication.selectors';
import { default as theme } from '@theme/theme';
import { CodeWrapper, ResendCodeLink } from '../ChangeEmailModal.styles';

const VerifyCode: React.FC<VerifyCodeProps> = ({ email, updateStep }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(AuthenticationSelectors.getAuthentication);

  const [value, setValue] = useState<string>('');

  const { handleSubmit } = useForm({
    mode: 'onChange',
  });

  const changeValueHandler = (code: string): void => {
    setValue(code);
  };

  const onSubmit = () => {
    if (!userInfo || !userInfo.cognitoId) {
      return;
    }
    dispatch(
      UserManagementActions.verifyChangeUserEmail({
        cognitoID: userInfo.cognitoId,
        verificationCode: value,
      }),
    );
  };

  const resendCodeHandler = () => {
    if (!userInfo || !userInfo.cognitoId) {
      return;
    }
    setValue('');
    dispatch(
      UserManagementActions.changeUserEmail({
        cognitoID: userInfo.cognitoId,
        newEmail: email,
        isToastValid: true,
      }),
    );
  };

  return (
    <Dialog open component='form' onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>
        <Box className='dialog-icon' textAlign='center'>
          <ErrorOutlineIcon htmlColor={theme.palette.primary.main} />
        </Box>
        <Typography variant='h2' textAlign='center'>
          {intl.formatMessage({
            id: 'account.tab.info.verify_email_title',
            defaultMessage: 'Verify new company email',
          })}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1' textAlign='center' fontFamily='Roboto-Medium'>
          {email}
        </Typography>
        <Spacer size='lg' />
        <Typography variant='subtitle1' textAlign='center'>
          {intl.formatMessage({
            id: 'account.tab.info.verify_email_subtitle',
            defaultMessage:
              'We send an verification code that need to be entered here. Please, check your inbox and verify your email.',
          })}
        </Typography>
        <Spacer size='lg' />
        <Typography variant='subtitle1' textAlign='center' fontFamily='Roboto-Medium'>
          {intl.formatMessage({
            id: 'account.tab.info.verification_code_title',
            defaultMessage: 'Enter the verification code:',
          })}
        </Typography>
        <Spacer size='md' />
        <CodeWrapper>
          <OtpInput
            isInputNum
            inputStyle={clsx('verify__code_item', {
              verify__code_item_active: value.length === 6,
            })}
            containerStyle='verify__code'
            value={value}
            onChange={changeValueHandler}
            numInputs={6}
          />
        </CodeWrapper>
      </DialogContent>
      <DialogActions>
        <CCPDefaultButton onClick={() => updateStep(1)} variant='outlined'>
          {intl.formatMessage({ id: 'form.employee.cta.cancel', defaultMessage: 'Cancel' })}
        </CCPDefaultButton>
        <CCPDefaultButton disabled={value.length !== 6} type='submit' variant='contained'>
          {intl.formatMessage({ id: 'form.employee.cta.continue', defaultMessage: 'Continue' })}
        </CCPDefaultButton>
      </DialogActions>
      <ResendCodeLink variant='subtitle1'>
        {intl.formatMessage({
          id: 'account.tab.info.verify_footer',
          defaultMessage: 'Didn’t receive verification code?',
        })}
        <Box onClick={resendCodeHandler}>
          {intl.formatMessage({
            id: 'account.tab.info.verify_resend',
            defaultMessage: 'Resend code',
          })}
        </Box>
      </ResendCodeLink>
    </Dialog>
  );
};

export default VerifyCode;
