import ToastWrapper from './ToastProvider.styles';

const ToastProvider = () => (
  <ToastWrapper
    rtl={false}
    icon={false}
    position='top-center'
    draggable={false}
    autoClose={3000}
    className='toast__wrapper'
    closeButton={false}
    newestOnTop={false}
    hideProgressBar
  />
);

export default ToastProvider;
