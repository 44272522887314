import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { EditPositionModalProps } from 'types';
import { useIntl } from 'react-intl';
import { CCPDefaultButton, CCPTextField, Spacer } from 'components';

const EditPositionModal: React.FC<EditPositionModalProps> = ({
  position,
  isOpen,
  onSubmit,
  closeModal,
}) => {
  const { formatMessage } = useIntl();
  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(
      yup
        .object({
          position: yup.string().required('schema.add_personal_info.error.position.required'),
        })
        .required(),
    ),
    defaultValues: {
      position,
    },
  });
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = methods;

  return (
    <Dialog open={isOpen} onClose={closeModal}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>
            <Typography variant='h2'>
              {formatMessage({
                id: 'form.employee.cta.position.title',
                defaultMessage: 'Edit position',
              })}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant='subtitle1'>
              {formatMessage({
                id: 'form.employee.cta.position.subtitle',
                defaultMessage: 'Fill in this field to describe your position in the company.',
              })}
            </Typography>
            <Spacer size='xl' />
            <CCPTextField
              required
              control={control}
              name='position'
              label={`${formatMessage({
                id: 'user.info.position',
                defaultMessage: 'Position',
              })}`}
            />
          </DialogContent>
          <DialogActions>
            <CCPDefaultButton onClick={closeModal} variant='outlined'>
              {formatMessage({ id: 'form.employee.cta.back', defaultMessage: 'Back to list' })}
            </CCPDefaultButton>
            <CCPDefaultButton type='submit' disabled={!isValid} variant='contained'>
              {formatMessage({
                id: 'form.employee.cta.save_changes',
                defaultMessage: 'Save changes',
              })}
            </CCPDefaultButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default EditPositionModal;
