import * as yup from 'yup';

const SetNewPasswordSchema = yup
  .object({
    password: yup
      .string()
      .required('schema.set_new_password.error.password.required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])\S{10,99}$/,
        'schema.set_new_password.error.password.match',
      ),
    passwordRepeat: yup
      .string()
      .required('schema.set_new_password.error.password_repeat.required')
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])\S{8,99}$/,
      //   'schema.set_new_password.error.password_repeat.match',
      // )
      .oneOf([yup.ref('password')], 'schema.set_new_password.error.password_repeat.not_same'),
  })
  .required();

export default SetNewPasswordSchema;
