import { MouseEvent, ChangeEvent, useCallback } from 'react';
import { gridPaginationSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid-pro';
import { useAppDispatch, useAppSelector } from 'store';
import { TablePaginationStyled } from './Footer.styles';
import LabelDisplayedRows from './LabelDisplayedRows';
import { useIntl } from 'react-intl';
import { TablesManagementActions } from '../store';

const Footer = (props: { tableName: string }) => {
  const { tableName } = props;
  const apiRef = useGridApiContext();
  const editRowCount = apiRef.current.state.pagination.rowCount;
  const paginationState = useGridSelector(apiRef, gridPaginationSelector);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const company = useAppSelector(state => state.company.api.getCompany.data);

  const handlePageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, page: number) => {
      if (company && apiRef.current) {
        dispatch(
          TablesManagementActions.updateCurrentPage({
            tableName,
            companyId: company.id,
            currentPage: page,
          }),
        );
        apiRef.current.setPage(page);
      }
    },
    [apiRef, dispatch, company, tableName],
  );

  const handleRowsPerPageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (company && apiRef.current) {
        dispatch(
          TablesManagementActions.updateRowsPerPage({
            tableName,
            companyId: company.id,
            rowsPerPage: event.target.value as unknown as number,
          }),
        );

        if (paginationState.paginationModel.page !== 0) {
          dispatch(
            TablesManagementActions.updateCurrentPage({
              tableName,
              companyId: company.id,
              currentPage: 0,
            }),
          );
          apiRef.current.setPage(0);
        }
      }
    },
    [apiRef, paginationState.paginationModel.page, company, tableName, dispatch],
  );

  return (
    <>
      {editRowCount && paginationState.rowCount > 0 ? (
        <TablePaginationStyled
          showFirstButton
          showLastButton
          labelRowsPerPage={formatMessage({
            id: 'table.footer.row_per_page',
            defaultMessage: 'Rows per Page',
          })}
          labelDisplayedRows={LabelDisplayedRows}
          rowsPerPageOptions={[10, 25, 50, 100]}
          count={paginationState.rowCount}
          page={paginationState.paginationModel.page}
          rowsPerPage={paginationState.paginationModel.pageSize}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      ) : null}
    </>
  );
};

export default Footer;
