import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { addEmployee } from './addEmployee';
import { EmployeeManagementState, FetchingStatus } from 'types';

export const addEmployeeExtraReducer = (
  builder: ActionReducerMapBuilder<EmployeeManagementState>,
) => {
  builder.addCase(addEmployee.pending, (state, action) => {
    state.api.addEmployee.fetchingStatus = FetchingStatus.PENDING;
    state.api.addEmployee.error = null;
  });
  builder.addCase(addEmployee.fulfilled, (state, action) => {
    state.api.addEmployee.fetchingStatus = FetchingStatus.FULFILLED;
    state.api.addEmployee.data = action.payload;
    state.api.addEmployee.error = null;

    if (state.api.getEmployees.data) {
      state.api.getEmployees.data = [action.payload, ...state.api.getEmployees.data];
    } else {
      state.api.getEmployees.data = [action.payload];
    }

    state.newEmployees = [action.payload.id, ...state.newEmployees];
  });
  builder.addCase(addEmployee.rejected, (state, action) => {
    state.api.addEmployee.fetchingStatus = FetchingStatus.REJECTED;
    state.api.addEmployee.error = action.payload;
  });
};
