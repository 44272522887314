import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosWithConfig } from 'api';
import { AxiosError } from 'axios';
import { GetUsersRequestParams, GetUsersResponse, UserManagementBaseResponse } from 'types';

export const getUsers = createAsyncThunk<GetUsersResponse, GetUsersRequestParams>(
  'userManagement/getUsers',
  async ({ companyId }, { rejectWithValue }) => {
    const endpoint = process.env.REACT_APP_USER_MANAGEMENT_ENDPOINT + `getUsers/${companyId}`;
    const axios = await axiosWithConfig();

    try {
      return (await axios.get(endpoint)).data;
    } catch (e) {
      const error: AxiosError<UserManagementBaseResponse> =
        e as AxiosError<UserManagementBaseResponse>;

      return rejectWithValue(error.response?.data);
    }
  },
);
