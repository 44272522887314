import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Box, Chip, CircularProgress, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'store';
import { CompanyActions } from 'features/company/store';
import { AllCompaniesProps } from 'types';

import { CardWrapper } from './CompanyCard.styles';
import { useEffect, useMemo, useState } from 'react';
import { StatsActions } from 'features/stats/store';
import { useLanguage } from 'hooks';
import moment from 'moment';
import { CCPDefaultButton } from '@components/index';
import { default as theme } from '@theme/theme';
import { AuthenticationSelectors } from '@features/authentication/store/authentication.selectors';
import LightTooltip from '@components/Tooltip/LightTooltip';
import WarningIcon from '@mui/icons-material/Warning';

const currentYear = moment().year();
const currentMonth = moment().month() + 1;

const CompanyCard: React.FC<AllCompaniesProps> = ({ companyInfo, isCompanyPage = false }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const activeLanguage = useLanguage();
  const { userInfo } = useAppSelector(AuthenticationSelectors.getAuthentication);

  const [isGetStatData, setIsGetStatData] = useState<boolean>(true);
  const [activeMembers, setActiveMembers] = useState<number>(0);

  const changeActiveCompanyHandler = () => {
    dispatch(CompanyActions.updateActiveCompany(companyInfo));
    navigate(`/${activeLanguage}/dashboard/${companyInfo?.companyId}`);
  };

  const changeCompanyStatesHandler = () => {
    if (!isCompanyPage) {
      dispatch(CompanyActions.updateActiveCompany(companyInfo));
    }
    navigate(`/${activeLanguage}/stats`);
  };

  const getStatData = async () => {
    try {
      if (!isGetStatData) {
        setIsGetStatData(true);
      }

      const month = currentMonth < 10 ? `0${currentMonth}` : `${currentMonth}`;
      const filterMonth = month + `.${currentYear}`;
      const responseData = await dispatch(
        StatsActions.getActiveMembers({
          companyId: companyInfo?.companyId,
          startMonth: filterMonth,
          endMonth: filterMonth,
        }),
      ).unwrap();
      if (responseData) {
        const activeMembersResponse = responseData?.explain?.Count || 0;
        if (activeMembers !== activeMembersResponse) {
          setActiveMembers(activeMembersResponse);
        }
      }
    } catch (error) {
      if (activeMembers !== 0) {
        setActiveMembers(0);
      }
    } finally {
      setIsGetStatData(false);
    }
  };

  useEffect(() => {
    if (companyInfo?.companyId) {
      getStatData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyInfo?.companyId]);

  const isLimitedAccess = useMemo(() => {
    if (userInfo && companyInfo?.companyId) {
      const currentCompanyData = userInfo.companydetails.find(
        item => item.companyId === companyInfo.companyId.toString(),
      );
      return currentCompanyData && currentCompanyData.role.length < 3;
    }
    return false;
  }, [userInfo, companyInfo?.companyId]);

  const getStatusColor = (statusName: string | undefined) => {
    if (!statusName) return theme.palette.neutralDark.main;
    switch (statusName.toLowerCase()) {
      case 'new':
        return theme.palette.transparent.dropdownSelected;
      case 'active':
        return theme.palette.success.main;
      case 'paused':
        return theme.palette.warning.main;
      case 'locked':
        return theme.palette.error.main;
      default:
        return theme.palette.neutralDark.main;
    }
  };

  return (
    <CardWrapper>
      <Box className='state'>
        {!isCompanyPage && (
          <Typography className='state_title notranslate' variant='body1'>
            {companyInfo?.companyName}
            {isLimitedAccess && (
              <LightTooltip
                title={intl.formatMessage({
                  id: 'dashboard__limited_access.tooltip',
                  defaultMessage: `Access to some data, like employee details or contracts, is restricted due to permissions. Contact your administrator for access.`,
                })}>
                <Chip
                  className='dashboard-chip'
                  variant='outlined'
                  size='small'
                  icon={<WarningIcon />}
                  label={intl.formatMessage({
                    id: 'dashboard__limited_access.message',
                    defaultMessage: 'Limited access',
                  })}
                />
              </LightTooltip>
            )}
          </Typography>
        )}

        <Box className='state_info' data-test-id='activeEmployeesContainer'>
          <Box className='state_info_item'>
            {!isGetStatData && (
              <>
                <Typography
                  variant='body1'
                  className='state_info_item_title'
                  data-test-id='activeEmployeesValue'>
                  {activeMembers}
                </Typography>
                <Typography variant='body1' className='state_info_item_text'>
                  {intl.formatMessage({
                    id: 'dashboard__active_users',
                    defaultMessage: 'active users',
                  })}
                </Typography>
              </>
            )}
            {isGetStatData && <CircularProgress size={40} />}
          </Box>
        </Box>
        <Box className='state_footer'>
          <CCPDefaultButton
            onClick={changeCompanyStatesHandler}
            variant='outlined'
            data-test-id='showAllStatiscticsButton'>
            {intl.formatMessage({
              id: 'dashboard__show_stats',
              defaultMessage: 'Show all stats',
            })}
          </CCPDefaultButton>
          {!isCompanyPage && (
            <CCPDefaultButton
              onClick={changeActiveCompanyHandler}
              variant='contained'
              data-test-id='goToCompanyButton'>
              {intl.formatMessage({
                id: 'dashboard__go_to_company',
                defaultMessage: 'Go to company',
              })}
            </CCPDefaultButton>
          )}
        </Box>
      </Box>
      <Box className='info'>
        <Box data-test-id='generalInfoContainer'>
          <Typography className='info_title' variant='body1'>
            {intl.formatMessage({
              id: 'dashboard__general_info',
              defaultMessage: 'General info',
            })}
          </Typography>
          <Typography className='info_text' variant='body1'>
            {intl.formatMessage({
              id: 'dashboard__company_id',
              defaultMessage: 'Company ID',
            })}
            : {companyInfo?.casCompanyId}
          </Typography>
          <Box
            className='info_status'
            bgcolor={getStatusColor(companyInfo?.status)}
            data-test-id='companyStatus'>
            {companyInfo?.status}
          </Box>
        </Box>
        <Box data-test-id='biliingAddressContainer'>
          <Typography className='info_title' variant='body1'>
            {intl.formatMessage({
              id: 'dashboard__billing_address',
              defaultMessage: 'Billing address',
            })}
          </Typography>
          <Typography className='info_text notranslate' variant='body1'>
            {companyInfo?.street} {companyInfo?.number}, {companyInfo?.city}, {companyInfo?.zip},{' '}
            {companyInfo?.country}
          </Typography>
        </Box>
      </Box>
    </CardWrapper>
  );
};

export default CompanyCard;
