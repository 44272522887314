import { styled, Box } from '@mui/material';

const CustomStatsWrapper = styled(Box)(() => ({
  '.container': {
    gap: 2,
    display: 'flex',
    minHeight: 770,
    height: 'auto',
  },
}));

export default CustomStatsWrapper;
