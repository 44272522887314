import { FetchingStatus, PimcoreErrorResponse } from 'types';
import { SerializedError } from '@reduxjs/toolkit';
import { AxiosRequestHeaders } from 'axios';
import { GridFilterModel } from '@mui/x-data-grid-pro';
import { EditEmployeeForm } from '../components/EmployeeBulkUpload/EditEmployeeModal/interfaces';

type BasicEmployee = {
  title?: string | null;
  firstName: string;
  lastName: string;
  businessEmail: string | null;
  gender: string;
  dateOfBirth: string;
  phoneNumber: string;
  companyId: number;
  customFields: Record<string, string>;
  globalCustomFields: Record<string, string>;
};

export interface EmployeeManagementState {
  api: {
    addEmployee: {
      fetchingStatus: FetchingStatus;
      data: Employee | null;
      error: PimcoreErrorResponse | undefined | null;
    };
    getEmployees: {
      fetchingStatus: FetchingStatus;
      data: Employee[] | null;
      error: SerializedError | null;
    };
    getEmployee: {
      fetchingStatus: FetchingStatus;
      data: Employee | null;
      error: SerializedError | null;
    };
    getEmployeeStatus: {
      fetchingStatus: FetchingStatus;
      data: EmployeeStatusResponse | null;
      error: SerializedError | null;
    };
    changeEmployee: {
      fetchingStatus: FetchingStatus;
      data: Employee | null;
      error: PimcoreErrorResponse | undefined | null;
    };
    changeEmployeeStatus: {
      fetchingStatus: FetchingStatus;
      data: EmployeeStatusTransition | null;
      error: SerializedError | null;
    };
    changeEmployeesStatus: {
      fetchingStatus: FetchingStatus;
      data: EmployeeStatusBulkActionBody | null;
      error: PimcoreErrorResponse | undefined | null;
    };
    createEndUser: {
      fetchingStatus: FetchingStatus;
      data: unknown | null;
      error: PimcoreErrorResponse | undefined | null;
    };
    bulkImportCsvFile: {
      data: UploadCsvFileResponse | null;
      fetchingStatus: FetchingStatus;
      error: PimcoreErrorResponse | null | undefined;
    };
    cancelImportProgress: {
      fetchingStatus: FetchingStatus;
      data: unknown | null;
      error: SerializedError | null;
    };
    getBulkUploadFile: {
      fetchingStatus: FetchingStatus;
      data: {
        companyBulkUpload: UploadCsvFileResponse | null;
        hansefitBulkUpload: UploadCsvFileResponse | null;
      };
      error: SerializedError | null;
    };
    employeeLicense: {
      fetchingStatus: FetchingStatus;
      data: EmployeeLicense[] | null;
      error: SerializedError | null;
    };
  };
  currentStep: BULK_IMPORT_STEPS;
  newEmployees: number[];
  tableConfigurations: {
    [key: string]: EmployeeTableConfiguration;
  };
  addEmployeeModalOpen: boolean;
}

export enum TransitionStatus {
  DEACTIVATED = 'leave_company',
  DEACTIVATING = 'leave_company_from_active',
  PAUSE = 'pause',
  PAUSED = 'pause_ended',
  PAUSED_UNLIMITED = 'end_date_reached',
  PENDING = 'entry_date_reached',
  DELETED = 'deleted',
}

export enum EmployeeStatus {
  INVITED = 'invited',
  APPLIED = 'applied',
  CONFIRMED = 'confirmed',
  PENDING = 'pending',
  ELIGIBLE = 'eligible',
  ACTIVE = 'active',
  PAUSED = 'paused',
  PAUSE_REMOVED = 'remove_pause',
  BLOCKED = 'blocked',
  LOCKED = 'locked',
  INACTIVE = 'inactive',
  UNASSIGNED = 'unassigned',
  DELETED = 'deleted',
  DENIED = 'denied',
  NEW = 'new',
}

export interface EmployeeStatusBulkActionBody {
  companyId: number;
  employeeTransitions: EmployeeStatusTransition[];
}

export interface CsvFilesManagementState {
  api: {
    uploadCsvFile: {
      data: UploadCsvFileResponse | null;
      fetchingStatus: FetchingStatus;
      error: PimcoreErrorResponse | null | undefined;
    };
  };
}

export type ImportedErrorEntry = {
  details: Record<string, string[]>; // key: field name either solo or concated with ', ', value: error message
  line: number | null;
  message: string;
};

export type ImportDataValidation = {
  success: Record<string, ImportedEmployee>;
  allData: Record<string, ImportedEmployee>;
  errorsArray: Record<string, ImportedErrorEntry>;
};

export type UploadCsvFileResponse = {
  errors: string;
  owner: string;
  confirmationId: string;
  lastModifiedAt: string;
  lastModifiedAtTS: number;
  version: number;
  filePath: string;
  code: number;
} & ImportDataValidation;

export interface UploadCsvRequestBody {
  payload: FormData;
  onUploadProgressCallback: (progress: number) => void;
}

export interface EmployeeStatusTransition {
  endUserId?: string;
  transition: string;
  startDate?: string;
  endDate?: string;
}

export interface EmployeeStatusResponse {
  status: string;
  transitions: { transition: string; requiredParameters: string[] }[];
  plannedTransition: { status: string; startDate: string; endDate: string } | null;
}

export interface EmployeeTableConfiguration {
  rowsPerPage: number;
  currentPage: number;
  columns: { [key: string]: boolean };
  filters: GridFilterModel;
  searchData: { hasVisibleSearchField: boolean; searchTerm: string };
}

export type Employee = BasicEmployee & {
  id: number;
  status: EmployeeStatus;
  entryDate: string;
  registrationDate: string;
  exitDate: string;
  userImage: string;
  userLocked: boolean;
  plannedTransition: plannedTransition;
  licenses: Array<EmployeeLicense>;
};

export type plannedTransition = {
  endDate: string | undefined;
  startDate: string | undefined;
  status: TransitionStatus;
};

export interface EmployeeTableOverwiewData {
  firstName: string;
  lastName: string;
  businessEmail: string | null;
  gender: string;
  phoneNumber: string;
  customFields: Record<string, string>;
  globalCustomFields: Record<string, string>;
  id: number;
  status: EmployeeStatus;
  plannedTransition: plannedTransition;
  entryDate: string;
  userId: number;
  userTitle?: string | null;
  birthDate: string;
  licenses: Array<EmployeeLicense>;
}

export type GetEmployeesResponse = Employee[];

export interface GetEmployeesRequestParams {
  companyId: number;
  page?: number;
  headers?: AxiosRequestHeaders | undefined;
}

export interface GetEmployeeRequestParams {
  employeeId: string;
}

export interface GetEmployeeStatusRequestParams {
  employeeId: string;
}

export type GetEmployeeResponse = Employee | null;

export interface changeEmployeeRequestParams {
  employeeId: string;
  body: ChangeEmployeeRequestBody;
}

export type ChangeEmployeeRequestBody = BasicEmployee;

export type AddEmployeeRequestBody = ChangeEmployeeRequestBody & {
  startDate: string;
};

export type ImportedMultipleFieldErrors = Record<keyof EditEmployeeForm, string>;
export type ImportedEmployee = Omit<AddEmployeeRequestBody, 'companyId' | 'id'> & {
  isDatabaseDuplicate?: boolean;
  isTemplateDuplicate?: boolean;
  multipleFieldErrors?: ImportedMultipleFieldErrors;
  notUniqueCombination?: boolean;
  importedLineId: string;
  invalid?: boolean;
};

export interface CancelImportProgressRequestParams {
  companyId: number;
  confirmationId: string;
}

export type CancelImportProgressResponse = unknown | null;

export enum BULK_IMPORT_STEPS {
  UPLOAD_STEP = 1,
  REVIEW_STEP = 2,
  APPLY_STEP = 3,
  UPLOAD_IN_PROGRESS,
  ERROR,
  IMPORT_IN_PROGRESS,
}
export interface CreateEndUserRequestBody {
  confirmationId: string;
  companyId: number;
}

export interface GetBulkUploadFileParams {
  companyId: number;
}

export interface EmployeeLicense {
  sequenceNumber: number;
  type: string;
  licenseId?: number;
  contractId?: number;
  createDate: string;
  startDate: string;
  endDate: string;
  product?: { name: string }[];
}

export interface GetEmployeeLicenseParam {
  employeeId: number;
}

export interface ResendActivationParam {
  endUserId: number;
  language: string;
}
export interface ResendActivationBulkParam {
  companyId: number;
  endUserIds: number[];
}
