import { CaseReducer, createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';

import { initialState } from '../../initialState';
import { CompanyResponse, CompanyState, ICompanyAsset } from 'types';

export const updateCompanyAssets = createAction(
  'updateCompanyAssets',
  (type: 'logo' | 'picture', value: ICompanyAsset) => {
    return { payload: { value, type } };
  },
);

export const updateAssets: CaseReducer<
  CompanyState,
  PayloadAction<{ type: 'logo' | 'picture'; value: ICompanyAsset }>
> = (state, { payload }) => {
  if (state.api.getCompany.data) state.api.getCompany.data[payload.type] = payload.value;
};
