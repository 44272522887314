import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { Box, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useAppDispatch, useAppSelector } from 'store';
import { BULK_IMPORT_STEPS, FetchingStatus, IUserOverview, S3Data } from 'types';
import { EmployeeManagementSelectors } from '../../store/employeeManagement.selectors';
import { CCPDefaultButton } from 'components';
import { BulkImportFooter, CardSubtitle, CardTitle, MainBlock } from './EmployeeBulkUpload.styles';
import UploadTab from './UploadTab';
import { StepName, Topbar } from './EmployeeTable.styles';
import ApplyModal from 'features/employeeManagement/components/EmployeeBulkUpload/ApplyModal';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { EmployeeManagementActions } from 'features/employeeManagement/store';
import S3 from 'aws-sdk/clients/s3';
import { S3CredentialsActions } from '@features/shared/s3Credentials/store';
import { useIntl } from 'react-intl';
import { getS3Credential } from 'utils/getS3Credential';
import { useCookies } from 'react-cookie';
import { FormatDate } from '@components/FormatDate';
import { Breadcrumb } from '@components/Breadcrumb';
import { checkTypeParameter } from '@utils/contractDependencies';
import { SettingsActions } from '@features/settings/store';
import Loader from '@components/Loader';
import { UserManagementActions } from '@features/userManagement/store';
import { AuthenticationSelectors } from '@features/authentication/store/authentication.selectors';
import InfoIconOutlined from '@mui/icons-material/InfoOutlined';
import { default as theme } from '@theme/theme';

const currentTimestamp = +new Date();
const aDayTS = 86400;

const EmployeeBulkUpload = () => {
  const { formatMessage } = useIntl();
  const [cookies] = useCookies(['language_app']);
  const activeLanguage = cookies.language_app || 'de';
  const [employees, setEmployees] = useState({ errors: {}, success: {}, allData: {} });
  const [fileInvalid, setFileInvalid] = useState(true);
  const [fileName, setFileName] = useState('');
  const [open, setOpen] = useState(false);
  const currentStep = useAppSelector(EmployeeManagementSelectors.currentStep);
  const companyApi = useAppSelector(CompanySelectors.getApi);
  const { data: users } = useAppSelector(state => state.userManagement.users);
  const company = companyApi.getCompany;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { bulkImportCsvFile } = useAppSelector(state => state.employeeManagement.api);
  const bulkUploadFiles = useAppSelector(EmployeeManagementSelectors.getBulkUploadFiles);
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);
  const activeContract = useAppSelector(state => state.contracts.euCreationCondition.data);
  const { userInfo: currentUser } = useAppSelector(AuthenticationSelectors.getAuthentication);

  const selfRegistrationFetchingStatus = useAppSelector(
    state => state.settings.selfRegistrationFetching,
  );
  const isDirectPay = useAppSelector(state => state.settings.selfRegistration.directPay);

  useEffect(() => {
    if (selfRegistrationFetchingStatus === FetchingStatus.IDLE) {
      dispatch(SettingsActions.getRegistrationSettings({}));
    }
  }, [dispatch, selfRegistrationFetchingStatus]);

  useEffect(() => {
    const companyId = activeCompany?.companyId;
    if (company.fetchingStatus === FetchingStatus.FULFILLED && companyId) {
      dispatch(UserManagementActions.getUsers({ companyId }));
    }
  }, [activeCompany?.companyId, company.fetchingStatus, dispatch]);

  const userOfFileUpload = useMemo(() => {
    if (!users || !bulkImportCsvFile.data) {
      return {} as IUserOverview;
    }

    const user = users.find(user => user.cognitoId === bulkImportCsvFile.data?.owner);

    if (!user) {
      return {} as IUserOverview;
    }

    return user;
  }, [bulkImportCsvFile.data, users]);

  const isCurrentUserOwnerOfFileUpload = useMemo(() => {
    if (!currentUser || !bulkImportCsvFile.data) {
      return false;
    }

    return currentUser.cognitoId === bulkImportCsvFile.data?.owner;
  }, [bulkImportCsvFile.data, currentUser]);

  useEffect(() => {
    if (
      bulkUploadFiles.data &&
      bulkUploadFiles.data.companyBulkUpload &&
      bulkUploadFiles.data.companyBulkUpload.lastModifiedAtTS - currentTimestamp > aDayTS
    ) {
      dispatch(EmployeeManagementActions.resetBulkImportCsvFile());
      dispatch(EmployeeManagementActions.setCurrentStep(BULK_IMPORT_STEPS.UPLOAD_STEP));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCancelImportProgress = async () => {
    await dispatch(
      EmployeeManagementActions.cancelImportProgress({
        companyId: company!.data!.id,
        confirmationId: bulkUploadFiles.data?.companyBulkUpload?.confirmationId!,
      }),
    );
    dispatch(EmployeeManagementActions.resetBulkImportCsvFile());
    dispatch(S3CredentialsActions.resetGetS3CredentialsState());
    dispatch(EmployeeManagementActions.setCurrentStep(BULK_IMPORT_STEPS.UPLOAD_STEP));
    if (activeCompany?.companyId) {
      await dispatch(
        EmployeeManagementActions.getBulkUploadFile({ companyId: activeCompany?.companyId }),
      );
    }
    navigate(`/${activeLanguage}/employee-management`);
  };

  const onSetFileName = (name: string) => {
    setFileName(name);
  };

  const setValidEmployees = (employee: any) => {
    setEmployees(employee);
  };

  const uploadFileToS3 = (credentials: S3Data) => {
    const companyId = activeCompany?.companyId;
    const data = { ...employees };
    data.allData = data.success;
    data.errors = [];
    const s3 = new S3({
      accessKeyId: credentials.AccessKeyId,
      secretAccessKey: credentials.SecretAccessKey,
      region: 'eu-central-1',
      sessionToken: credentials.SessionToken,
    });

    const params = {
      Bucket: credentials.s3Url?.split('/')[2],
      Key: `assets/Temporary_Files/EndUser_Bulk_Uploads/${companyId}/${fileName}`,
      Body: JSON.stringify(data, null, 2),
      // ACL: 'public-read',
      ContentType: 'application/json',
    };

    dispatch(EmployeeManagementActions.setCurrentStep(BULK_IMPORT_STEPS.APPLY_STEP));

    s3.upload(params, async function (err: Error, data: unknown) {
      if (err) throw err;
      if (companyId && data) {
        const confirmationId = bulkUploadFiles.data?.companyBulkUpload?.confirmationId || '';
        const endUserRequest = {
          companyId,
          confirmationId,
        };
        await dispatch(EmployeeManagementActions.createEndUser(endUserRequest));
        dispatch(EmployeeManagementActions.resetBulkImportCsvFile());
        dispatch(S3CredentialsActions.resetGetS3CredentialsState());
        dispatch(EmployeeManagementActions.setCurrentStep(BULK_IMPORT_STEPS.UPLOAD_STEP));
        setOpen(true);
      }
    });
  };

  const onApply = async () => {
    debugger;
    if (Array.isArray(employees?.success) || _.isEmpty(employees?.success)) {
      alert('List does not contain valid data');
    } else {
      const data = _.omit(employees, 'errorsArray');
      Object.assign(data, { errorsArray: [] });
      const companyFetchingStatus = company.fetchingStatus;
      const companyId = activeCompany?.companyId;

      if (companyFetchingStatus === FetchingStatus.FULFILLED && companyId) {
        setEmployees(data);
        try {
          const s3Response = await dispatch(
            S3CredentialsActions.getS3CredentialsCompany({ companyId }),
          ).unwrap();
          const bulkUploadCredentials = getS3Credential({
            data: s3Response,
            type: 'EndUser_Bulk_Uploads',
          });
          if (bulkUploadCredentials) {
            uploadFileToS3(bulkUploadCredentials);
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  const breadcrumbRoutes = [
    {
      url: `/${activeLanguage}/employee-management`,
      text: formatMessage({
        id: 'employee_management.overview.title',
        defaultMessage: 'Employees',
      }),
    },
    {
      url: '',
      text: formatMessage({
        id: 'employee_management.bulk_upload.header.bulk',
        defaultMessage: 'Bulk import',
      }),
    },
  ];

  if (
    isDirectPay ||
    !activeContract ||
    !checkTypeParameter(activeContract, 'createEditEUPossible')
  ) {
    navigate(`/${activeLanguage}/employee-management`);
  }

  return (
    <div id='bulkImport' style={{ position: 'relative' }}>
      {currentStep === BULK_IMPORT_STEPS.REVIEW_STEP &&
        bulkImportCsvFile.fetchingStatus === FetchingStatus.PENDING && (
          <Loader show portalId='bulkImport' />
        )}
      <Box sx={{ flex: 1, px: 2, pb: 2, boxSizing: 'border-box' }}>
        <Topbar>
          <Breadcrumb routes={breadcrumbRoutes} />
          {bulkUploadFiles.data?.companyBulkUpload && (
            <div className='topbar-right'>
              {formatMessage(
                {
                  id: 'employee_management.bulk_upload.header',
                  defaultMessage: 'Started at {date} by {name}',
                },
                {
                  date: FormatDate(bulkUploadFiles.data?.companyBulkUpload?.lastModifiedAt),
                  name: [userOfFileUpload?.name, userOfFileUpload?.surname]
                    .filter(Boolean)
                    .join(' '),
                },
              )}
            </div>
          )}
        </Topbar>
        <MainBlock>
          <div className='steps'>
            <CCPDefaultButton
              className={`${currentStep === BULK_IMPORT_STEPS.UPLOAD_STEP ? 'active' : ''}`}>
              {currentStep !== BULK_IMPORT_STEPS.UPLOAD_STEP ? (
                <CheckCircleIcon htmlColor={theme.palette.success.main} />
              ) : (
                <span className='step-no'>1</span>
              )}
              <StepName>
                {formatMessage({
                  id: 'employee_management.bulk_upload.step.upload',
                  defaultMessage: 'Upload file',
                })}
              </StepName>
            </CCPDefaultButton>
            <CCPDefaultButton
              className={`${currentStep === BULK_IMPORT_STEPS.REVIEW_STEP ? 'active' : ''}`}>
              {fileInvalid && currentStep === BULK_IMPORT_STEPS.REVIEW_STEP ? (
                <ErrorIcon htmlColor={theme.palette.error.main} />
              ) : (
                <span className='step-no'>2</span>
              )}{' '}
              <StepName>
                {formatMessage({
                  id: 'employee_management.bulk_upload.step.review',
                  defaultMessage: 'Review import',
                })}
              </StepName>
            </CCPDefaultButton>
            <CCPDefaultButton>
              <span className='step-no'>3</span>
              <StepName>
                {formatMessage({
                  id: 'employee_management.bulk_upload.step.apply',
                  defaultMessage: 'Apply import',
                })}
              </StepName>
            </CCPDefaultButton>
          </div>

          <div className='bulk-upload-wrapper'>
            <div>
              <CardTitle>
                {formatMessage({
                  id: 'employee_management.bulk_upload.upload.title',
                  defaultMessage: 'Bulk upload',
                })}
              </CardTitle>
              <CardSubtitle>
                {currentStep === 2 ? (
                  <>
                    <div>
                      {formatMessage({
                        id: 'employee_management.bulk_upload.review.system.label',
                        defaultMessage: 'Review uploaded data before adding it to the system.',
                      })}
                    </div>
                    <div>
                      {' '}
                      {formatMessage({
                        id: 'employee_management.bulk_upload.review.store.label',
                        defaultMessage:
                          'Data is safe for 24h. After that process you have to start from the beginning.',
                      })}
                    </div>
                  </>
                ) : (
                  formatMessage({
                    id: 'employee_management.bulk_upload.upload.label',
                    defaultMessage:
                      'Add multiple employee profiles to your company space via CSV data import.',
                  })
                )}
              </CardSubtitle>
            </div>

            <UploadTab
              onValidEmployee={setValidEmployees}
              onSetFileName={onSetFileName}
              handleSetFileInvalid={setFileInvalid}
            />
          </div>
        </MainBlock>
      </Box>
      {currentStep === BULK_IMPORT_STEPS.REVIEW_STEP && (
        <BulkImportFooter>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
            }}>
            <CCPDefaultButton
              onClick={onCancelImportProgress}
              disabled={!isCurrentUserOwnerOfFileUpload}
              variant='outlined'
              className='cancel-import-btn '>
              {formatMessage({
                id: 'employee_management.bulk_upload.cta.cancel',
                defaultMessage: 'Cancel upload',
              })}
            </CCPDefaultButton>

            {!isCurrentUserOwnerOfFileUpload && (
              <div
                css={{
                  fontSize: '0.875rem',
                  color: theme.palette.neutralDark.main,
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '1rem',
                }}>
                <InfoIconOutlined fontSize='inherit' />
                <Typography
                  fontSize={12}
                  css={{
                    marginLeft: '0.2rem',
                  }}>
                  {formatMessage({
                    id: 'employee_management.bulk_upload.error.not_owner',
                    defaultMessage:
                      'You are not the owner of this file. Only the owner can cancel the upload.',
                  })}
                </Typography>
              </div>
            )}
          </div>

          <CCPDefaultButton variant='contained' onClick={onApply} disabled={fileInvalid}>
            {formatMessage({
              id: 'employee_management.bulk_upload.cta.apply',
              defaultMessage: 'Apply & import',
            })}
          </CCPDefaultButton>
        </BulkImportFooter>
      )}
      <ApplyModal isOpen={open} />
    </div>
  );
};

export default EmployeeBulkUpload;
