import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { CompanyState, FetchingStatus } from 'types';
import { getCompany } from './getCompany';

export const getCompanyExtraReducer = (builder: ActionReducerMapBuilder<CompanyState>) => {
  builder.addCase(getCompany.pending, state => {
    state.api.getCompany.fetchingStatus = FetchingStatus.PENDING;
    state.api.getCompany.data = null;
    state.api.getCompany.error = null;
  });

  builder.addCase(getCompany.fulfilled, (state, action) => {
    state.api.getCompany.fetchingStatus = FetchingStatus.FULFILLED;
    state.api.getCompany.data = action.payload;
  });

  builder.addCase(getCompany.rejected, (state, action) => {
    state.api.getCompany.fetchingStatus = FetchingStatus.REJECTED;
    state.api.getCompany.error = action.payload;
  });
};
