import { Auth } from 'aws-amplify';
import { AxiosError } from 'axios';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToast } from 'utils';
import { ForgotPasswordRequestParams, UserManagementBaseResponse } from 'types';

export const forgotPassword = createAsyncThunk<CognitoUser, ForgotPasswordRequestParams>(
  'authentication/forgotPassword',
  async ({ email, isShowToast }, { rejectWithValue }) => {
    try {
      const response = await Auth.forgotPassword(email);
      if (isShowToast) {
        showToast('success', {
          id: 'toast.account.password.confirmation_code_resent.success',
          defaultMessage: 'We have sent you a <span>new confirmation code</span>',
        });
      }

      return response;
    } catch (e) {
      const error: AxiosError<UserManagementBaseResponse> =
        e as AxiosError<UserManagementBaseResponse>;
      showToast('error', {
        id: 'toast.account.password.confirmation_code_resent.error',
        defaultMessage: error.message,
      });

      return rejectWithValue(error.message);
    }
  },
);
