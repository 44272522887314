import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { CompanySelectors } from 'features/company/store/company.selectors';

import { CompanyWrapper } from './Company.styles';
import CompanyCard from '@features/dashboard/components/CompanyCard';

const CompanyPage = () => {
  const { activeCompany } = useSelector(CompanySelectors.getCompanyState);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <CompanyWrapper>
      <CompanyCard companyInfo={activeCompany} isCompanyPage />
    </CompanyWrapper>
  );
};

export default CompanyPage;
