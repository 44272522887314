import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { FetchingStatus, SettingsState } from 'types';
import { addNewEmployeeInvitation } from './addNewEmployeeInvitation';

export const addNewEmployeeInvitationExtraReducer = (
  builder: ActionReducerMapBuilder<SettingsState>,
) => {
  builder.addCase(addNewEmployeeInvitation.pending, (state, action) => {
    state.selfRegistrationUsers.fetchingStatus = FetchingStatus.PENDING;
    state.selfRegistrationUsers.violationError = null;
  });
  builder.addCase(addNewEmployeeInvitation.fulfilled, (state, action) => {
    state.selfRegistrationUsers.fetchingStatus = FetchingStatus.FULFILLED;
    state.selfRegistrationUsers.violationError = null;
    state.selfRegistrationUsers.data.push(action.payload);
  });

  builder.addCase(addNewEmployeeInvitation.rejected, (state, action) => {
    state.selfRegistrationUsers.fetchingStatus = FetchingStatus.REJECTED;
    state.selfRegistrationUsers.violationError = action.payload;
  });
};
