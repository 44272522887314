import { FetchingStatus } from '@api/interfaces';
import { AuthenticationState } from './interfaces';

export const initialState: AuthenticationState = {
  fetchingStatus: FetchingStatus.IDLE,
  error: null,
  isOpenSidebar: true,
  authenticated: false,
  userInfo: null,
  forgotPassword: {
    fetchingStatus: FetchingStatus.IDLE,
    error: null,
    activeStep: 1,
    email: '',
  },
};
