import { default as theme } from '@theme/theme';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import TableViewIcon from '@mui/icons-material/TableView';

const contentTypeToIcon = (contentType: string) => {
  const commonStyle = {
    width: 24,
    height: 24,
    color: theme.palette.neutralDark.main,
  };
  if (contentType.includes('image')) {
    return <ImageIcon css={commonStyle} />;
  }
  if (contentType.includes('pdf')) {
    return <PictureAsPdfIcon css={commonStyle} />;
  }
  if (
    contentType.includes('word') ||
    contentType.includes('document') ||
    contentType.includes('text')
  ) {
    return <ArticleIcon css={commonStyle} />;
  }
  if (contentType.includes('sheet')) {
    return <TableViewIcon css={commonStyle} />;
  }
  return null;
};

export default contentTypeToIcon;
