import clsx from 'clsx';
import { Box, Typography } from '@mui/material';
import { useEffect, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { SettingsWrapper } from './Settings.styles';
import { useIntl } from 'react-intl';
import { useAppDispatch } from 'store';
import { SettingsActions } from 'features/settings/store';
import Spacer from '@components/Spacer';
import { useGetSettingTab } from '@hooks/useGetSettingTab';

const Settings = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { activeTab, language } = useParams();
  const userSettingsTabs = useGetSettingTab();

  const currentTab = useMemo(() => Number(activeTab || 0), [activeTab]);

  const changeActiveTab = useCallback(
    (newTab: number): void => {
      navigate(`/${language}/settings/${newTab}`);
    },
    [navigate, language],
  );

  useEffect(() => {
    dispatch(SettingsActions.getRegistrationSettings({}));
  }, [dispatch]);

  useEffect(() => {
    if (
      currentTab < 0 ||
      userSettingsTabs.length < currentTab ||
      !userSettingsTabs[currentTab]?.isVisible
    ) {
      let newTabIndex = -1;
      userSettingsTabs.forEach((item, index) => {
        if (newTabIndex !== -1) return;
        if (item.isVisible) {
          newTabIndex = index;
        }
      });
      changeActiveTab(newTabIndex);
    }
  }, [currentTab, userSettingsTabs, changeActiveTab]);

  const Component = useMemo(
    () =>
      userSettingsTabs[currentTab] && userSettingsTabs[currentTab].isVisible
        ? userSettingsTabs[currentTab].component
        : Box,
    [currentTab, userSettingsTabs],
  );

  return (
    <SettingsWrapper>
      <Typography variant='h2'>
        {intl.formatMessage({ id: 'navigation.settings', defaultMessage: 'Settings' })}
      </Typography>
      <Spacer size='lg' />
      <Box className='wrapper'>
        <Box className='wrapper__sidebar'>
          <Box>
            {userSettingsTabs.map((tab, index) => (
              <Box
                hidden={!tab.isVisible}
                onClick={() => changeActiveTab(index)}
                className={clsx('wrapper__sidebar_item', {
                  wrapper__sidebar_item_active: Number(activeTab) === index,
                })}
                key={tab.value}>
                {tab.label}
              </Box>
            ))}
          </Box>
        </Box>
        <Box className='content'>
          <Component />
        </Box>
      </Box>
    </SettingsWrapper>
  );
};

export default Settings;
