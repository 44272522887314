import { styled, TextField } from '@mui/material';

export const SearchField = styled(TextField)(({ theme }) => ({
  minWidth: '160px',
  maxWidth: '360px',
  width: '100%',
  // [theme.breakpoints.up('xl')]: {
  //   width: '275px',
  // },
  '& .MuiOutlinedInput-root.MuiInputBase-root': {
    height: 40,
    paddingRight: 0,
  },
  '& input::placeholder': {
    fontSize: 16,

    color: theme.palette.neutralDark.main,
  },
  '& .clear-icon img': {
    width: 16,
    height: 16,
  },
  '& .search-icon img': {
    width: 24,
    height: 24,
    color: theme.palette.primary.main,
  },
}));
