import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import { FetchingStatus } from 'api/interfaces';
import clsx from 'clsx';
import {
  CCPDefaultButton,
  CCPTextField,
  HorizontalSpacer,
  Spacer,
  StepContainer,
} from 'components';
import { SelfRegistrationActions } from 'features/selfRegistration/store';
import { SELF_REGISTRATION_STEPS } from 'features/selfRegistration/store/interfaces';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { AddPersonalInfoSchema } from 'shared/schemas';
import { useAppDispatch, useAppSelector } from 'store';
import { SelfRegistrationSelectors } from '../../../store/selfRegistration.selectors';
import { AddPersonalInfoForm } from './interfaces';

const AddPersonalInfo = () => {
  const addPersonalInfoStep = useAppSelector(SelfRegistrationSelectors.getAddPersonalInfoStep);
  const api = useAppSelector(SelfRegistrationSelectors.api);
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const methods = useForm<AddPersonalInfoForm>({
    defaultValues: {
      name: addPersonalInfoStep ? addPersonalInfoStep.name : '',
      surname: addPersonalInfoStep ? addPersonalInfoStep.surname : '',
      position: addPersonalInfoStep ? addPersonalInfoStep.position : '',
      email: addPersonalInfoStep ? addPersonalInfoStep.email : '',
    },
    mode: 'onChange',
    resolver: yupResolver(AddPersonalInfoSchema),
  });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid },
    trigger,
  } = methods;
  const onSubmit = (data: AddPersonalInfoForm) => {
    dispatch(SelfRegistrationActions.updatePersonalInfoStepData(data));
    dispatch(SelfRegistrationActions.setStep(SELF_REGISTRATION_STEPS.SET_NEW_PASSWORD));
  };

  useEffect(() => {
    const {
      getSignUpKeyData: { data, fetchingStatus },
    } = api;
    if (fetchingStatus === FetchingStatus.FULFILLED && data && !addPersonalInfoStep) {
      setValue('name', data.name);
      setValue('surname', data.surname);
      setValue('position', data.position);
      setValue('email', data.email);
      trigger();
    }
  }, [addPersonalInfoStep, setValue, dispatch, api, trigger]);

  return (
    <StepContainer>
      <Typography variant='body1' className={clsx('hint')}>
        {intl.formatMessage({
          id: 'self_registration.hint',
          defaultMessage: 'Please, provide your personal data to set up your account.',
        })}
      </Typography>
      <Spacer height={32} />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CCPTextField
            label={intl.formatMessage({
              id: 'form.employee.field.first_name.label',
              defaultMessage: 'First name',
            })}
            name='name'
            control={control}
          />
          <Spacer height={32} />
          <CCPTextField
            label={intl.formatMessage({
              id: 'form.employee.field.last_name.label',
              defaultMessage: 'Last name',
            })}
            name='surname'
            control={control}
          />
          <Spacer height={32} />
          <CCPTextField
            label={intl.formatMessage({
              id: 'user.info.position',
              defaultMessage: 'Position',
            })}
            name='position'
            control={control}
          />
          <Spacer height={32} />
          <CCPTextField
            label={intl.formatMessage({
              id: 'user_setting.self_registration.table.email',
              defaultMessage: 'Email',
            })}
            name='email'
            control={control}
            reset={true}
          />
          <Spacer height={50} />
          <Box className='ctaContainer'>
            <HorizontalSpacer />
            <CCPDefaultButton type='submit' variant='contained' color='primary' disabled={!isValid}>
              {intl.formatMessage({
                id: 'self_registration.next',
                defaultMessage: 'Next: Set password',
              })}
            </CCPDefaultButton>
          </Box>
        </form>
      </FormProvider>
    </StepContainer>
  );
};

export default AddPersonalInfo;
