import { styled } from '@mui/material';

export const FormContainer = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 620,
  borderRadius: 2,
  backgroundColor: theme.palette.background.paper,
  paddingTop: 48,
  paddingBottom: 36,
  paddingLeft: 50,
  paddingRight: 50,
  margin: '0 auto 80px',

  '.header': {
    textAlign: 'center',
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: 25,
    fontWeight: 900,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.4,
    letterSpacing: 0.5,
    color: theme.palette.primary.darker,
  },
}));
