import { useIntl } from 'react-intl';
import { CCPDefaultButton } from '@components/index';
import MainBlock from './CustomizationFooter.styles';
import { ReactElement } from 'react';
import { Box, Stack } from '@mui/material';

type customizationFooterProps = {
  resetMethod: () => void;
  leftChild?: ReactElement;
  saveText?: string;
  isValid: boolean;
};

const CustomizationFooter: React.FC<customizationFooterProps> = ({
  resetMethod,
  leftChild = null,
  saveText = '',
  isValid,
}) => {
  const { formatMessage } = useIntl();

  return (
    <MainBlock>
      <Box display='flex' alignItems='center'>
        {leftChild}
      </Box>
      <Stack flexDirection='row' gap='16px'>
        <CCPDefaultButton variant='outlined' onClick={resetMethod}>
          {formatMessage({
            id: 'settings.self_registration.customize.footer.reset_changes',
            defaultMessage: 'Reset changes',
          })}
        </CCPDefaultButton>
        <CCPDefaultButton variant='contained' type='submit' disabled={!isValid}>
          {saveText
            ? saveText
            : formatMessage({
                id: 'settings.self_registration.customize.footer.save_changes',
                defaultMessage: 'Save changes',
              })}
        </CCPDefaultButton>
      </Stack>
    </MainBlock>
  );
};

export default CustomizationFooter;
