import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosWithConfig } from 'api';
import { BaseErrorResponse, RootState } from 'types';
import { showToast } from 'utils';

export const removeEmployeeInvitation = createAsyncThunk<
  string,
  { companyId?: number; token: string }
>(
  'settings/removeEmployeeInvitation',
  async ({ companyId, token }, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    companyId = companyId || state.company.activeCompany?.companyId;

    const endpoint = `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/companies/sp-token/${companyId}/${token}`;

    const axios = await axiosWithConfig();

    try {
      await axios.delete(endpoint);

      return token;
    } catch (e) {
      const error: AxiosError<BaseErrorResponse> = e as AxiosError<BaseErrorResponse>;
      showToast('error', {
        id: 'toast.employee_invitation.remove.error',
        defaultMessage: error.message,
      });
      return rejectWithValue(error.response?.data);
    }
  },
);
