import { useState } from 'react';
import { AxiosError } from 'axios';
import showToast from '@utils/showToast';
import { useAppDispatch } from '@store/index';
import { UserAccountPasswordProps } from '@localTypes/user';
import { UserManagementBaseResponse } from '@api/interfaces';
import { defaultTextfieldError } from '@utils/defaultErrorMessage';
import { UserManagementActions } from '@features/userManagement/store';

export const useChangePassword = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const updatePassword = async (data: UserAccountPasswordProps) => {
    setIsLoading(true);
    try {
      await dispatch(
        UserManagementActions.changePassword({
          oldPassword: data.currentPassword,
          newPassword: data.newPassword,
        }),
      ).unwrap();
      showToast('success', {
        id: 'toast.account.password.changed',
        defaultMessage: 'User password <span>has been changed</span>',
      });
    } catch (e) {
      const error: AxiosError<UserManagementBaseResponse> =
        e as AxiosError<UserManagementBaseResponse>;
      const errorMessage = defaultTextfieldError[error.name] || error.message;
      showToast('error', {
        id: 'toast.account.change_password.error',
        defaultMessage: errorMessage,
      });
    }
    setIsLoading(false);
  };

  return { isLoading, updatePassword };
};
