import React, { useEffect, useMemo, useState } from 'react';
import { MenuItem, Stack, Typography, Box } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import { useIntl } from 'react-intl';

import { useAppDispatch, useAppSelector } from 'store';
import {
  CCPDatePicker,
  CCPDefaultButton,
  CCPSelect,
  CCPTextField,
  Spacer,
  ModalError,
} from 'components';

import { EmployeeProfileEditForm, EmployeeProfileEditProps } from './interfaces';
import { EmployeeManagementSelectors } from 'features/employeeManagement/store/employeeManagement.selectors';
import EmployeeEditProfileSchema from 'shared/schemas/EmployeeEditProfileSchema';
import { EmployeeManagementActions } from 'features/employeeManagement/store';

import { CompanySelectors } from 'features/company/store/company.selectors';
import { useGendersList } from 'utils/genders';
import CustomFields from 'features/employeeManagement/components/EmployeeOverview/CustomFields';
import { useUserStatus } from 'utils/userStatus';
import { useEmployeeTitles } from 'utils/employeeTitle';
import { ValidationUtils, useDialCodes, useGetPhoneNumberValue } from 'utils';
import GlobalCustomFields from 'features/employeeManagement/components/EmployeeOverview/GlobalCustomFields';
import { EmployeeStatus } from 'features/employeeManagement/store/interfaces';
import { checkTypeParameter } from 'utils/contractDependencies';
import { getCustomFieldValidationObject } from 'utils/customField';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';
import { useAsyncViolationFeedback } from 'hooks';
import { getBirthDate } from '@utils/getUpdatedDate';
import Loader from '@components/Loader';

import { CompanyCustomFieldType, FetchingStatus, PimcoreErrorResponse } from 'types';

import { EmployeeProfileEditStyled } from './EmployeeProfileEdit.styles';

const EmployeeProfileEdit: React.FC<EmployeeProfileEditProps> = ({
  allowEdit,
  handleEditProfile,
}) => {
  const { formatMessage } = useIntl();
  const params = useParams<{ language: string; employeeId: string }>();
  const genders = useGendersList();
  const employeeTitles = useEmployeeTitles();
  const dispatch = useAppDispatch();
  const { activationLetterRecipientMode } = useAppSelector(SettingsSelectors.settingsSettings);
  const activeContract = useAppSelector(state => state.contracts.euCreationCondition.data);
  const contracts = useAppSelector(state => state.contracts.getContracts.data);
  const allProducts = contracts.reduce((result: string[], currentContract) => {
    currentContract.products.forEach(product => {
      if (!result.includes(product.name)) {
        result.push(product.name);
      }
    });
    return result;
  }, []);
  const employeeApi = useAppSelector(EmployeeManagementSelectors.getGetEmployeeApi);
  const employeeChangeApi = useAppSelector(EmployeeManagementSelectors.getApi);
  const changeEmployee = employeeChangeApi.changeEmployee;
  const { companiesNames } = useAppSelector(CompanySelectors.getCompanyState);

  const company = useAppSelector(CompanySelectors.getApi);
  const companyData = company.getCompany.data;
  const dialCodes = useDialCodes();
  const [isReseted, setReset] = useState(false);
  const customFields: CompanyCustomFieldType[] = useMemo(
    () => companyData?.customFields || [],
    [companyData?.customFields],
  );
  const globalCustomFields: CompanyCustomFieldType[] = useMemo(
    () => companyData?.globalCustomFields || [],
    [companyData?.globalCustomFields],
  );

  const employee = employeeApi.data;
  const userStatus = useUserStatus({
    status: employee?.status || '',
    entryDate: employee?.entryDate,
  });

  const [dialCode, phoneNumber] = useGetPhoneNumberValue(employee?.phoneNumber || '');

  useEffect(() => {
    if (!allowEdit) {
      handleEditProfile(false);
    }
  }, [allowEdit, handleEditProfile]);

  const isNotEditPermission = employee?.status === EmployeeStatus.DENIED;

  const schema: any = useMemo(() => {
    let newSchema: any = {};
    let EmployeeEditProfileSchemaShaped = EmployeeEditProfileSchema;
    if (activationLetterRecipientMode.toLowerCase() === 'eu') {
      EmployeeEditProfileSchemaShaped = EmployeeEditProfileSchemaShaped.shape({
        businessEmail: yup
          .string()
          .required('form.employee.error.corporate_email.required')
          .trim()
          .email('form.employee.error.corporate_email.invalid'),
      });
    }

    const mandatoryCustomField = customFields;
    if (mandatoryCustomField.length) {
      mandatoryCustomField.forEach(field => {
        newSchema[field.key] = getCustomFieldValidationObject(field);
      });
      EmployeeEditProfileSchemaShaped = EmployeeEditProfileSchemaShaped.shape({
        customFields: yup.object().shape({ ...newSchema }),
      });
    }
    const mandatoryGlobalCustomField = globalCustomFields.filter(
      field => field.mandatory === 'yes',
    );
    if (mandatoryGlobalCustomField.length) {
      newSchema = {};
      mandatoryGlobalCustomField.forEach(field => {
        newSchema[field.key] = yup.string().required('form.employee.error.custom_field.required');
      });
      EmployeeEditProfileSchemaShaped = EmployeeEditProfileSchemaShaped.shape({
        globalCustomFields: yup.object().shape({ ...newSchema }),
      });
    }
    return EmployeeEditProfileSchemaShaped;
  }, [customFields, globalCustomFields, activationLetterRecipientMode]);

  const initialData = useMemo(
    () =>
      employee
        ? {
            ...employee,
            firstName: employee.firstName,
            lastName: employee.lastName,
            userId: employee.id,
            status: userStatus,
            gender: employee.gender,
            dateOfBirth: employee.dateOfBirth,
            businessEmail: employee.businessEmail || '',
            product1: '',
            product2: '',
            entryDate: employee.entryDate,
            exitDate: employee.exitDate,
            customFields: Array.isArray(employee?.customFields) ? {} : employee.customFields,
            globalCustomFields: Array.isArray(employee?.globalCustomFields)
              ? {}
              : employee.globalCustomFields,
            title: employee.title || '',
            dialCode,
            phoneNumber,
          }
        : {},
    [employee, userStatus, dialCode, phoneNumber],
  );

  const methods = useForm<EmployeeProfileEditForm>({
    defaultValues: initialData,
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const {
    control,
    handleSubmit,
    getValues,
    watch,
    clearErrors,
    setError,
    formState,
    formState: { errors, isValid },
    reset,
    trigger,
  } = methods;

  const employeeChangeError = useMemo(() => {
    const error = { ...changeEmployee.error };

    const notUniqueErrorTitle = 'is_not_unique_name_and_birthdate';
    const notUniqueErrorCode = 'IS_NOT_UNIQUE_NAME_AND_BIRTHDATE';

    const isNotUniqueFirstName = error?.violations?.some(
      error => error.propertyPath === 'firstName' && error.message === notUniqueErrorTitle,
    );

    if (isNotUniqueFirstName) {
      error.violations = error.violations || [];
      error.violations = [...error.violations];

      error?.violations?.push({
        propertyPath: 'lastName',
        message: notUniqueErrorTitle,
        code: notUniqueErrorCode,
      });
      error?.violations?.push({
        propertyPath: 'dateOfBirth',
        message: notUniqueErrorTitle,
        code: notUniqueErrorCode,
      });

      error.violations = error.violations?.filter(error => error.propertyPath !== 'firstName');
    }

    return error;
  }, [changeEmployee.error]);

  const changeEmployeeViolationErrors = ValidationUtils.getViolationErrors({
    error: employeeChangeError as PimcoreErrorResponse,
    scope: 'employee_management',
  });

  useAsyncViolationFeedback({
    withMessage: true,
    setError,
    errors: changeEmployeeViolationErrors,
    watch,
    clearErrors,
    isSubmitting: formState.isSubmitting,
  });

  useEffect(() => {
    //@ts-ignore
    const subscription = watch((form, field) => {
      if (field.name === 'phoneNumber' && !form.phoneNumber && errors?.dialCode) {
        clearErrors('dialCode');
      }
      if (field.name === 'businessEmail' && !form.businessEmail) {
        clearErrors('businessEmail');
      }
    });

    return () => subscription.unsubscribe();
  }, [clearErrors, errors, watch]);

  useEffect(() => {
    // example from react-hook-form git https://github.com/orgs/react-hook-form/discussions/3700
    reset(initialData);
    setReset(true);
  }, [reset, setReset, initialData]);

  useEffect(() => {
    isReseted && trigger();
  }, [trigger, isReseted]);

  const editablePhoneNumber = watch('phoneNumber');
  useEffect(() => {
    if (editablePhoneNumber) trigger('dialCode');
  }, [editablePhoneNumber, trigger]);

  const onSubmit = async (data: EmployeeProfileEditForm) => {
    const body = {
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim(),
      businessEmail: data.businessEmail || null,
      gender: data.gender,
      dateOfBirth: format(new Date(data.dateOfBirth as Date), 'yyyy-MM-dd'),
      phoneNumber: data.phoneNumber ? (data.dialCode || '') + data.phoneNumber : '',
      companyId: companyData?.id || companiesNames.data[0].companyId,
      customFields: data?.customFields || {},
      globalCustomFields: data?.globalCustomFields || {},
      title: data.title || null,
    };

    const paramsChangeEmployee = {
      employeeId: params.employeeId as string,
      body,
    };

    dispatch(EmployeeManagementActions.changeEmployee(paramsChangeEmployee)).then(resp => {
      if (resp.meta.requestStatus === 'fulfilled') {
        handleEditProfile(false);
      }
    });
  };

  if (!activeContract || !checkTypeParameter(activeContract, 'createEditEUPossible')) {
    handleEditProfile(false);
  }

  const isLastNameError = useMemo(
    () =>
      errors?.lastName?.message ===
      'employee_management.form.error.lastname.is_not_unique_name_and_birthdate',
    [errors?.lastName?.message],
  );

  const isDateOfBirthError = useMemo(
    () =>
      errors?.dateOfBirth?.message ===
      'employee_management.form.error.dateofbirth.is_not_unique_name_and_birthdate',
    [errors?.dateOfBirth?.message],
  );

  return (
    <>
      <Loader
        show={changeEmployee.fetchingStatus === FetchingStatus.PENDING}
        inCard
        portalId='employeeInfo'
      />
      <EmployeeProfileEditStyled>
        <Typography variant='body1' className='rightSideHeader'>
          {formatMessage({
            id: 'employee.edit_profile.title',
            defaultMessage: 'Edit profile',
          })}
        </Typography>
        {(isLastNameError || isDateOfBirthError) && (
          <>
            <Spacer size='xl' />
            <ModalError
              headerErrorMessage={formatMessage({
                id: 'employee_management.form.error.is_not_unique_name_and_birthdate.error_title',
                defaultMessage: 'Changes cannot be saved',
              })}
              descriptionErrorMessage={formatMessage({
                id: 'employee_management.form.error.firstname.is_not_unique_name_and_birthdate',
                defaultMessage:
                  'Please check your name and date of birth. If the information is correct, please contact a Hansefit representative.',
              })}
              errorContainerClassName='errorContainer'
            />
          </>
        )}
        <Spacer size='xl' />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CCPSelect
              disabled={isNotEditPermission}
              control={control}
              name='title'
              label={formatMessage({
                id: 'form.employee.field.title.label',
                defaultMessage: 'Title',
              })}
              width={254}>
              {employeeTitles.map(employeeTitle => (
                <MenuItem
                  key={employeeTitle.value}
                  value={employeeTitle.value}
                  selected={employeeTitle.value === getValues('title')}
                  style={{ minHeight: 36 }}>
                  {employeeTitle.title}
                </MenuItem>
              ))}
            </CCPSelect>
            <Spacer height={30} />
            <Stack direction='row' spacing={2}>
              <CCPTextField
                disabled
                required
                control={control}
                name='firstName'
                label={formatMessage({
                  id: 'form.employee.field.first_name.label',
                  defaultMessage: 'First name',
                })}
                width={254}
              />
              <CCPTextField
                disabled={isNotEditPermission}
                control={control}
                name='lastName'
                required
                label={formatMessage({
                  id: 'form.employee.field.last_name.label',
                  defaultMessage: 'Last name',
                })}
                width={254}
                notShowErrorText={isLastNameError}
                isWarningError={isLastNameError}
              />
            </Stack>
            <Spacer height={30} />
            <Stack direction='row' spacing={2}>
              <CCPTextField
                control={control}
                name='userId'
                label={formatMessage({
                  id: 'employee.details.user_id',
                  defaultMessage: 'User ID',
                })}
                width={254}
                disabled
              />
              <CCPTextField
                control={control}
                name='status'
                label={formatMessage({
                  id: 'employee.details.status',
                  defaultMessage: 'Status',
                })}
                width={254}
                disabled
              />
            </Stack>
            <Spacer height={55} />
            <Stack direction='row' spacing={2}>
              <CCPSelect
                disabled={isNotEditPermission}
                control={control}
                name='gender'
                required
                label={formatMessage({
                  id: 'form.employee.field.gender.label',
                  defaultMessage: 'Gender',
                })}
                className='notranslate'
                width={254}>
                {genders.map(gender => (
                  <MenuItem key={gender.value} value={gender.value} className='notranslate'>
                    {gender.title}
                  </MenuItem>
                ))}
              </CCPSelect>
              <CCPDatePicker
                disabled={isNotEditPermission}
                control={control}
                name='dateOfBirth'
                minDate={getBirthDate('min')}
                maxDate={getBirthDate('max')}
                isBirthdayPicker
                required
                label={formatMessage({
                  id: 'form.employee.field.date_of_birth.label',
                  defaultMessage: 'Date of birth',
                })}
                width={254}
                notShowErrorText={isDateOfBirthError}
                isWarningError={isDateOfBirthError}
              />
            </Stack>
            <Spacer height={30} />
            <CCPTextField
              disabled={isNotEditPermission}
              control={control}
              name='businessEmail'
              required={activationLetterRecipientMode.toLowerCase() === 'eu'}
              label={formatMessage({
                id: 'form.employee.field.corporate_email.label',
                defaultMessage: 'Corporate email',
              })}
              width={524}
            />
            <Spacer height={30} />
            <Stack direction='row' spacing={2}>
              <CCPSelect
                disabled={isNotEditPermission}
                control={control}
                name='dialCode'
                label={`${formatMessage({
                  id: 'form.employee.field.dialCode.label',
                  defaultMessage: 'Prefix',
                })}`}
                width={104}>
                {dialCodes.map(({ dialCode }) => (
                  <MenuItem
                    key={dialCode}
                    value={dialCode}
                    selected={dialCode === getValues('dialCode')}>
                    {dialCode}
                  </MenuItem>
                ))}
              </CCPSelect>
              <CCPTextField
                disabled={isNotEditPermission}
                control={control}
                name='phoneNumber'
                label={formatMessage({
                  id: 'form.employee.field.phone.label',
                  defaultMessage: 'Phone number',
                })}
                width={404}
                type='integer'
              />
            </Stack>
            <Spacer height={30} />
            <GlobalCustomFields width={524} isAddressFields />
            <Spacer height={30} />
            {employee &&
              !(
                employee.status === EmployeeStatus.INACTIVE ||
                employee.status === EmployeeStatus.PAUSED
              ) && (
                <Stack direction='row' spacing={2}>
                  {allProducts &&
                    allProducts.length > 0 &&
                    allProducts.map((item, index) => (
                      <Box key={`${item}_${index}`} className='productChip'>
                        {item}
                      </Box>
                    ))}
                </Stack>
              )}
            <Spacer height={55} />
            <Stack direction='row' spacing={2}>
              <CCPDatePicker
                control={control}
                name='entryDate'
                label={formatMessage({
                  id: 'form.employee.field.start_date.label',
                  defaultMessage: 'Start date',
                })}
                width={254}
                disabled
              />
              <CCPDatePicker
                control={control}
                name='exitDate'
                label={formatMessage({
                  id: 'form.employee.field.end_date.label',
                  defaultMessage: 'End date',
                })}
                width={254}
                disabled
              />
            </Stack>
            <Spacer height={55} />
            <GlobalCustomFields width={524} />
            <CustomFields width={524} />
            {customFields.length > 0 || globalCustomFields.length > 0 ? (
              <Spacer height={55} />
            ) : null}
            <Stack direction='row' justifyContent='space-between'>
              <Stack direction='row' justifyContent='flex-end' spacing={2} width='100%'>
                <CCPDefaultButton
                  variant='outlined'
                  onClick={() => {
                    dispatch(EmployeeManagementActions.resetChangeEmployeeApi());
                    handleEditProfile(false);
                  }}>
                  {formatMessage({ id: 'form.employee.cta.cancel', defaultMessage: 'Cancel' })}
                </CCPDefaultButton>
                <CCPDefaultButton variant='contained' type='submit' disabled={!isValid}>
                  {formatMessage({
                    id: 'form.employee.cta.save_changes',
                    defaultMessage: 'Save changes',
                  })}
                </CCPDefaultButton>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </EmployeeProfileEditStyled>
    </>
  );
};

export default EmployeeProfileEdit;
