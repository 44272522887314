import { Box, TableCell, Typography } from '@mui/material';
import { FormatDate } from 'components/FormatDate';
import React from 'react';
import { useIntl } from 'react-intl';
import { EmployeeLicense } from 'types';
import { StyledTableRow } from './Table.styles';

export interface ITableCellRow {
  item: EmployeeLicense;
}

const TableCellRow: React.FC<ITableCellRow> = ({ item }) => {
  const intl = useIntl();
  const { type, startDate, endDate, product } = item;
  return (
    <>
      <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell className="event">
          <Typography variant="body1">{type}</Typography>
        </TableCell>
        <TableCell className="date">
          <Typography variant="body1">
            {startDate ? FormatDate(startDate) : ''}
            {endDate ? <> - {FormatDate(endDate)}</> : ''}
          </Typography>
        </TableCell>
        <TableCell className="product">
          <Box display="flex">
            {product?.map((pro, index) => (
              <React.Fragment key={index}>
                {index < 2 && <Box className="productName">{pro.name}</Box>}
              </React.Fragment>
            ))}
            {product && product.length > 2 && (
              <Box className="more">
                +{product.length - 2}{' '}
                {intl.formatMessage({
                  id: 'employee.membership.table.row.more',
                  defaultMessage: 'more',
                })}
              </Box>
            )}
          </Box>
        </TableCell>
      </StyledTableRow>
    </>
  );
};

export default TableCellRow;
