import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';

import { ListType, TabsType } from 'types';

import { InvoicesWrapper } from './Invoices.styles';
import { useIntl } from 'react-intl';
import { useAppDispatch } from 'store';
import { SettingsActions } from 'features/settings/store';
import { Spacer } from 'components';
import DistributionList from '@features/settings/components/DistributionList';

const Invoice: React.FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState<TabsType>(TabsType.EXTERNAL);

  const updateActiveTab = (tab: TabsType): void => {
    setActiveTab(tab);
  };

  const isExternalTab = useMemo(() => activeTab === TabsType.EXTERNAL, [activeTab]);

  useEffect(() => {
    dispatch(SettingsActions.getDistributionList({ listType: ListType.INVOICES }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InvoicesWrapper id='invoicesSettingsWrapper' position='relative'>
      <Typography variant='h6'>
        {intl.formatMessage({
          id: 'setting.invoice.title',
          defaultMessage: 'Invoices e-mail distribution',
        })}
      </Typography>
      <Spacer size='md' />
      <Box className='new'>
        <Typography variant='subtitle1'>
          {intl.formatMessage({
            id: 'setting.invoice.sub_title',
            defaultMessage: 'Maintain the list of e-mail recipients for monthly invoices.',
          })}
        </Typography>
      </Box>
      <Box className='tabs'>
        <Box
          onClick={() => updateActiveTab(TabsType.EXTERNAL)}
          className={clsx('tabs__item', {
            tabs__item_active: isExternalTab,
          })}>
          {intl.formatMessage({
            id: 'setting.invoice.tab.external',
            defaultMessage: 'External users',
          })}
        </Box>
        <Box
          onClick={() => updateActiveTab(TabsType.INTERNAL)}
          className={clsx('tabs__item', {
            tabs__item_active: activeTab === TabsType.INTERNAL,
          })}>
          {intl.formatMessage({
            id: 'setting.invoice.tab.system',
            defaultMessage: 'System users',
          })}
        </Box>
      </Box>
      <DistributionList
        listType={ListType.INVOICES}
        loaderCardId='invoicesSettingsWrapper'
        externalUsersList={isExternalTab}
      />
    </InvoicesWrapper>
  );
};

export default Invoice;
