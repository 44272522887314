import { startOfTomorrow } from 'date-fns';
import * as yup from 'yup';

export const EmployeeBulkActionsSchema = yup
  .object({
    startDate: yup
      .date()
      .min(new Date(), 'form.employee.error.past_date')
      .typeError('form.employee.error.start_date.invalid')
      .min(startOfTomorrow(), 'form.employee.error.past_date')
      .nullable(),
    endDate: yup
      .date()
      .min(yup.ref('startDate'), 'form.employee.error.end_date_before_start_date')
      .typeError('form.employee.error.end_date.invalid')
      .nullable(),
  })

export default EmployeeBulkActionsSchema;
