import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { FetchingStatus, SettingsState } from 'types';
import updateLandingSettings from './updateLandingSettings';
import { initialState } from '@features/settings/store/initialState';

const updateLandingSettingsExtraReducer = (builder: ActionReducerMapBuilder<SettingsState>) => {
  builder.addCase(updateLandingSettings.pending, (state, action) => {
    if (state.updateLandingSettings) {
      state.updateLandingSettings.fetchingStatus = FetchingStatus.PENDING;
      state.updateLandingSettings.data = { lastUpdated: '' };
    } else {
      state['updateLandingSettings'] = initialState.updateLandingSettings;
    }
  });
  builder.addCase(updateLandingSettings.fulfilled, (state, action) => {
    state.updateLandingSettings.fetchingStatus = FetchingStatus.FULFILLED;
    state.selfRegistration = { ...state.selfRegistration, ...action.payload };
  });

  builder.addCase(updateLandingSettings.rejected, (state, action) => {
    state.updateLandingSettings.fetchingStatus = FetchingStatus.REJECTED;
    state.updateLandingSettings.error = action.error;
  });
};

export default updateLandingSettingsExtraReducer;
