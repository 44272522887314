import { TranslationsKeys } from 'types';
import { EmployeeName } from './EmployeeName.styles';
import { GridRowId } from '@mui/x-data-grid-pro';

export interface EmployeeFullNameProps {
  fullName: string;
  id: number;
  onClick: (id: number) => void;
}

const EmployeeFullName = ({ fullName, onClick, id }: EmployeeFullNameProps) => (
  <EmployeeName onClick={() => onClick(id)}>{fullName}</EmployeeName>
);

export default EmployeeFullName;

export interface EmployeeNameProps {
  employeeName: string;
  id: GridRowId;
  onClick: (id: GridRowId) => void;
  activeLanguage?: TranslationsKeys;
}

const EmployeeNameInstance = ({ employeeName, onClick, id }: EmployeeNameProps) => (
  <EmployeeName onClick={() => onClick(id)} className='notranslate'>
    {employeeName}
  </EmployeeName>
);

export { EmployeeNameInstance };
