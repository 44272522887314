import { UploadCsvFileResponse } from 'types';

// keep export to ensure compatibility with old code
export const changeImportingData = (resp: UploadCsvFileResponse | undefined) => {
  if (resp) {
    const allData = resp.allData;
    for (var key in allData) {
      if (allData[key].gender) {
        switch (allData[key].gender) {
          case 'm':
          case 'männlich':
          case 'male': {
            allData[key].gender = 'male';
            break;
          }
          case 'w':
          case 'weiblich':
          case 'female':
          case 'f': {
            allData[key].gender = 'female';
            break;
          }
          case 'divers':
          case 'd':
          case 'o': {
            allData[key].gender = 'other';
            break;
          }
          default:
            break;
        }
      }

      if (allData[key].title) {
        let keyChanged = false;
        switch (allData[key].title?.toLowerCase()) {
          case 'dr.':
          case 'dr': {
            allData[key].title = 'Dr.';
            keyChanged = true;
            break;
          }
          case 'prof.':
          case 'prof': {
            allData[key].title = 'Prof.';
            keyChanged = true;
            break;
          }
          case 'prof. dr.':
          case 'prof dr':
          case 'prof. dr':
          case 'prof dr.':
          case 'prof.dr.':
          case 'prof.dr': {
            allData[key].title = 'Prof. Dr.';
            keyChanged = true;
            break;
          }
          default:
            break;
        }
        if (resp.errorsArray[key]) {
          if (
            Object.keys(resp.errorsArray[key].details).length === 1 &&
            resp.errorsArray[key].details.title &&
            keyChanged
          ) {
            delete resp.errorsArray[key];
            resp.success = { ...resp.success, [key]: allData[key] };
          } else if (
            Object.keys(resp.errorsArray[key].details).includes('title') &&
            ['Prof. Dr.', 'Prof.', 'Dr.'].includes(allData[key].title!)
          ) {
            delete resp.errorsArray[key].details.title;
          }
        }
      }
    }
    if (Object.keys(resp.errorsArray).length === 0) {
      resp.errorsArray = {};
    }
    resp.allData = allData;
    return resp;
  }
  return undefined;
};

export default changeImportingData;
