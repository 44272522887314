import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, FormControlLabel, RadioGroup, Typography } from '@mui/material';
import { Spacer } from 'components';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';
import { SettingsActions } from 'features/settings/store';
import { useAppDispatch, useAppSelector } from 'store';
import { FetchingStatus, ListType, UserTypeShortcut } from 'types';
import {
  ActivationCodeWrapper,
  RadioContainer,
  RadioDescription,
  StyledRadio,
} from './ActivationCode.styles';
import Loader from '@components/Loader';
import { GlobalStateActions } from '@store/globalState';
import DistributionList from '@features/settings/components/DistributionList';

const ActivationCode = () => {
  const [currentActivationLetterRecipientMode, setCurrentActivationLetterRecipientMode] =
    useState<UserTypeShortcut>('eu');
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);
  const {
    fetchingStatus: settingsFetchingStatus,
    activationLetterRecipientMode: defaultRecipientMode,
  } = useAppSelector(SettingsSelectors.settingsSettings);
  const activationLetterDistributionList = useAppSelector(
    SettingsSelectors.emailActivationLetterDistributionList,
  );
  const activationLetterDistributionListFetchingStatus = useAppSelector(
    SettingsSelectors.activationLetterDistributionListFetchingStatus,
  );

  const handleActivationLetterRecipientModeChange = async (value: UserTypeShortcut) => {
    if (activeCompany) {
      setCurrentActivationLetterRecipientMode(value);

      if (value === 'cu' && !activationLetterDistributionList.length) {
        dispatch(GlobalStateActions.setActivationLettersPopupState(true));
        return;
      }

      const resp = await dispatch(
        SettingsActions.updateSettings({
          companyId: activeCompany.companyId,
          activationLetterRecipientMode: value,
        }),
      );
      if (resp.meta.requestStatus === 'rejected') {
        setCurrentActivationLetterRecipientMode(defaultRecipientMode);
      }
    }
  };

  useEffect(() => {
    if (activeCompany) {
      const { companyId } = activeCompany;
      dispatch(
        SettingsActions.getDistributionList({ companyId, listType: ListType.ACTIVATION_LETTER }),
      );

      // we don't need requesting settings when fetching status is pending,
      // because update settings executing longer than get settings and in this case
      // sometimes user can see guard popup while settings is updating https://hansefit.atlassian.net/browse/FE-1015
      if (settingsFetchingStatus !== FetchingStatus.PENDING) {
        dispatch(SettingsActions.getSettings({ companyId }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, activeCompany]);

  useEffect(() => {
    if (
      settingsFetchingStatus === FetchingStatus.FULFILLED &&
      activationLetterDistributionListFetchingStatus === FetchingStatus.FULFILLED
    ) {
      setCurrentActivationLetterRecipientMode(defaultRecipientMode);
    }
  }, [
    activationLetterDistributionListFetchingStatus,
    setCurrentActivationLetterRecipientMode,
    settingsFetchingStatus,
    defaultRecipientMode,
  ]);

  //show guard popup when list had users, but all users were removed
  useEffect(() => {
    if (
      settingsFetchingStatus === FetchingStatus.FULFILLED &&
      activationLetterDistributionListFetchingStatus === FetchingStatus.FULFILLED &&
      defaultRecipientMode === 'cu' &&
      activationLetterDistributionList.length === 0
    ) {
      dispatch(GlobalStateActions.setActivationLettersPopupState(true));
    }
  }, [
    activationLetterDistributionList,
    defaultRecipientMode,
    dispatch,
    settingsFetchingStatus,
    activationLetterDistributionListFetchingStatus,
  ]);

  return (
    <ActivationCodeWrapper id='activationCodeWrapper'>
      <Loader
        show={settingsFetchingStatus === FetchingStatus.PENDING}
        inCard
        portalId='activationCodeWrapper'
      />
      {defaultRecipientMode && (
        <>
          <Typography variant='h6'>
            {intl.formatMessage({
              id: 'setting.activation_code.title',
              defaultMessage: 'Activation code settings',
            })}
          </Typography>
          <Spacer size='md' />

          <Typography variant='subtitle1'>
            {intl.formatMessage({
              id: 'setting.activation_code.description',
              defaultMessage:
                'Description text, for example: select receiver of the activation code.',
            })}
          </Typography>

          <Spacer height={48} />

          <Box className='content__fields_wrapper'>
            <RadioGroup
              name='activationLetterRecipientMode'
              value={currentActivationLetterRecipientMode}
              onChange={event =>
                handleActivationLetterRecipientModeChange(event.target.value as UserTypeShortcut)
              }>
              <RadioContainer>
                <FormControlLabel
                  value='eu'
                  control={<StyledRadio />}
                  label={intl.formatMessage({
                    id: 'user_setting.activation_code.send_to_eu.label',
                    defaultMessage: 'Send activation codes to end user',
                  })}
                />
                <RadioDescription>
                  {intl.formatMessage({
                    id: 'user_setting.activation_code.send_to_eu.description',
                    defaultMessage:
                      'All activation codes are sent directly to end users via email.',
                  })}
                </RadioDescription>
              </RadioContainer>

              <Spacer height={28} />
              <RadioContainer>
                <FormControlLabel
                  value='cu'
                  control={<StyledRadio />}
                  label={intl.formatMessage({
                    id: 'user_setting.activation_code.send_to_cu.label',
                    defaultMessage: 'Send activation codes to company user',
                  })}
                />
                <RadioDescription>
                  {intl.formatMessage({
                    id: 'user_setting.activation_code.send_to_cu.description',
                    defaultMessage:
                      'All activation codes are sent to selected company users via email.',
                  })}
                </RadioDescription>
              </RadioContainer>
            </RadioGroup>
          </Box>
          {currentActivationLetterRecipientMode === 'cu' && (
            <DistributionList
              loaderCardId='activationCodeWrapper'
              listType={ListType.ACTIVATION_LETTER}
            />
          )}
        </>
      )}
    </ActivationCodeWrapper>
  );
};

export default ActivationCode;
