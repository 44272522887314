import axiosRetryClient from '@utils/axiosRetryClient';

const postHubspotSupportForm = async (data: FormData, formId: string, portalId: number) => {
  const url = `${process.env.REACT_APP_HUBSPOT_SUPPORT_FORM_POST_URL}/${portalId}/${formId}`;

  return await axiosRetryClient(url, {
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
  });
};

export default postHubspotSupportForm;
