import { styled, Box } from '@mui/material';

export const WrapperOwnWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.transparent.blue07,
  borderRadius: 2,
  height: 22,
  padding: '4px 6px',
  marginLeft: 8,
  '& .own_label': {
    textTransform: 'uppercase',
    fontFamily: 'Roboto-Medium',
    color: theme.palette.neutralDark.main,
    lineHeight: 1,
  },
}));
