import { Box, Accordion, styled } from '@mui/material';

export const TileAccordion = styled(Accordion)(({ theme }) => ({
  borderRadius: '4px',
  border: `solid 1px ${theme.palette.neutralLight.main}`,
  boxShadow: 'none',
  '&.Mui-expanded .expand-icon': {
    transform: 'rotate(180deg)',
  },
  '.accordion-header': { float: 'right', padding: '0 24px', cursor: 'default' },
  '.accordion-details': {
    marginTop: '-98px',
    padding: '24px',
  },
}));

export const TileWrapper = styled(Box)(({ theme }) => ({
  padding: '24px',
  borderRadius: 4,
  border: `solid 1px ${theme.palette.neutralLight.main}`,
  '.tile-header': {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
}));
