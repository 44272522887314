import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { isNull } from 'lodash';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'store';
import { useUserAccountTabs } from 'utils';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { AuthenticationActions } from 'features/authentication/store';

import UserAccountContent from './UserAccountContent';
import { UserAccountWrapper } from './UserAccount.styles';
import { AuthenticationSelectors } from 'features/authentication/store/authentication.selectors';
import Spacer from '@components/Spacer';

const UserAccountPage: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { activeCompany, companiesNames } = useSelector(CompanySelectors.getCompanyState);
  const { userInfo } = useSelector(AuthenticationSelectors.getAuthentication);
  const userAccountTabs = useUserAccountTabs();

  const [activeTab, setActiveTab] = useState<number>(0);

  useEffect(() => {
    //needs to be fixed
    if (!userInfo?.companyId && (activeCompany || companiesNames.data.length > 0)) {
      dispatch(
        AuthenticationActions.getUserInfo({
          companyId: activeCompany ? activeCompany.companyId : companiesNames.data[0].companyId,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany, companiesNames.data]);

  useEffect(() => {
    const active = Number(searchParams.get('activeIndex'));

    if (!isNull(active)) {
      setActiveTab(active);
    }
  }, [searchParams]);

  const changeActiveTab = (newTab: number): void => {
    setActiveTab(newTab);
    navigate({
      search: `?activeIndex=${newTab}`,
    });
  };

  const renderAccountTabs = userAccountTabs.map((tab, index) => (
    <Box
      onClick={() => changeActiveTab(index)}
      className={clsx('wrapper__sidebar_item', {
        wrapper__sidebar_item_active: activeTab === index,
      })}
      key={tab.value}>
      {tab.text}
    </Box>
  ));

  return (
    <UserAccountWrapper>
      <Typography variant='h2'>
        {intl.formatMessage({ id: 'account.title', defaultMessage: 'Profile Settings' })}
      </Typography>
      <Spacer size='lg' />
      <Box className='wrapper'>
        <Box className='wrapper__sidebar'>{renderAccountTabs}</Box>
        <UserAccountContent activeTab={activeTab} />
      </Box>
    </UserAccountWrapper>
  );
};

export default UserAccountPage;
