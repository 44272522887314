import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { getStatsExtraReducer } from './actions/async/getStats/getStats.reducer';
import { getCouponsExtraReducer } from './actions/async/getCoupons/getCoupons.reducer';
import { getCheckInsExtraReducer } from './actions/async/getCheckIns/getCheckIns.reducer';
import { getActiveMembersExtraReducer } from './actions/async/getActiveMembers/getActiveMembers.reducer';
import {
  resetState,
  updateCouponFilters,
  updateCheckInsFilters,
  updateActiveMembersFilters,
} from './actions/sync/stats.actions';

const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: { updateCheckInsFilters, updateCouponFilters, updateActiveMembersFilters, resetState },
  extraReducers: builder => {
    getStatsExtraReducer(builder);
    getCouponsExtraReducer(builder);
    getCheckInsExtraReducer(builder);
    getActiveMembersExtraReducer(builder);
  },
});

export const { name, actions } = statsSlice;
export const statsReducer = statsSlice.reducer;
