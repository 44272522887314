export const getStatsMonth = ({
  startMonth,
  endMonth,
}: {
  startMonth: number;
  endMonth: number;
}) => {
  const startMonthString = startMonth + 1 < 10 ? `0${startMonth + 1}` : `${startMonth + 1}`;
  const endMonthString = endMonth + 1 < 10 ? `0${endMonth + 1}` : `${endMonth + 1}`;
  return { startMonthString, endMonthString };
};
