import { styled } from '@mui/material';

export const StepIconContainer = styled('div')(({ theme }) => ({
  fontSize: 12,
  backgroundColor: theme.palette.primaryLight.main,
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 2,
  letterSpacing: 0.11,
  padding: '0 10px',
  borderRadius: 12,
  display: 'flex',
  flexDirection: 'row',
  zIndex: 2,
  border: `1px solid ${theme.palette.primary.contrastText}`,
  marginLeft: 4,
  marginRight: 4,

  '&.active': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.primary.main}`,
  },
  '&.completed': {
    backgroundColor: theme.palette.primaryLight.main,
    color: theme.palette.primary.darker,
    border: '1px solid transparent',
    display: 'flex',
    alignItems: 'center',
  },
  '.stepNumber': {
    fontWeight: 900,
    paddingRight: 8,
  },
  '.completedIcon': {
    height: 16,
    width: 16,
    position: 'relative',
    right: 6,
  },
}));
