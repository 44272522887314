import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToast } from 'utils';
import { axiosWithConfig } from 'api';
import { ChangeUserRequestBody } from 'features/userManagement/store/interfaces';
import { UserManagementActions } from 'features/userManagement/store';
import { UserManagementBaseResponse } from 'api/interfaces';

export const changeUser = createAsyncThunk<UserManagementBaseResponse, ChangeUserRequestBody>(
  'userManagement/changeUser',
  async (body, { rejectWithValue, dispatch }) => {
    const endpoint = process.env.REACT_APP_USER_MANAGEMENT_ENDPOINT + 'changeUser';
    const axios = await axiosWithConfig();
    const transition = body.transition;

    try {
      const { data } = await axios.put(endpoint, body);

      switch (body.status) {
        case 'Blocked':
          showToast(
            'success',
            {
              id: 'user_account.status.blocked',
              defaultMessage: 'Account of {account_info} has been blocked',
            },
            undefined,
            { account_info: `<span>${body.fullName} (${body.email})</span>` },
          );
          break;
        case 'Active':
          if (transition === 'permissions') {
            showToast(
              'success',
              {
                id: 'account.status.permissios_changed',
                defaultMessage: `Permissions of {account_info} has been changed`,
              },
              undefined,
              { account_info: `<span>${body.fullName} (${body.email})</span>` },
            );
            break;
          } else {
            showToast(
              'success',
              {
                id: 'account.status.unblocked',
                defaultMessage: `Account of {account_info} has been reactivated`,
              },
              undefined,
              { account_info: `<span>${body.fullName} (${body.email})</span>` },
            );
            break;
          }
        case 'Deleted':
          showToast(
            'success',
            {
              id: 'user_account.status.deleted',
              defaultMessage: `Account of {account_info} has been deleted`,
            },
            undefined,
            { account_info: `<span>${body.fullName} (${body.email})</span>` },
          );
          break;
        default:
          break;
      }

      if (!body.deleteOwnUser)
        dispatch(
          UserManagementActions.getUsers({
            companyId: body.companyId,
          }),
        );

      return data;
    } catch (e) {
      const error: AxiosError<UserManagementBaseResponse> =
        e as AxiosError<UserManagementBaseResponse>;
      showToast('error', {
        id: 'toast.change_user.error',
        defaultMessage: error.message,
      });
      return rejectWithValue(error.response?.data);
    }
  },
);
