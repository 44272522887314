import { Step, StepLabel, Stepper } from '@mui/material';
import { CCPStepConnector, StepIcon, StepperContainer } from 'components';
import { useIntl } from 'react-intl';

export interface SelfRegistrationStepperProps {
  activeStep: number;
}

const SelfRegistrationStepper = ({ activeStep }: SelfRegistrationStepperProps) => {
  const intl = useIntl();
  return (
    <StepperContainer>
      <Stepper
        alternativeLabel
        connector={<CCPStepConnector />}
        activeStep={activeStep}
        className='stepper'>
        <Step className='step'>
          <StepLabel
            StepIconComponent={props => (
              <StepIcon
                {...props}
                stepNumber={1}
                label={intl.formatMessage({
                  id: 'add_personal_info',
                  defaultMessage: 'Add personal info',
                })}
              />
            )}
          />
        </Step>
        <Step className='step'>
          <StepLabel
            StepIconComponent={props => (
              <StepIcon
                {...props}
                stepNumber={2}
                label={intl.formatMessage({
                  id: 'set_new_password',
                  defaultMessage: 'Set new password',
                })}
              />
            )}
          />
        </Step>
        <Step className='step'>
          <StepLabel
            StepIconComponent={props => (
              <StepIcon
                {...props}
                stepNumber={3}
                label={intl.formatMessage({
                  id: 'verify_email',
                  defaultMessage: 'Verify email',
                })}
              />
            )}
          />
        </Step>
      </Stepper>
    </StepperContainer>
  );
};

export default SelfRegistrationStepper;
