import React, { useCallback, useState, useMemo } from 'react';
import { CCPDefaultButton, Spacer } from 'components';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { useIntl } from 'react-intl';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PendingEmployeeForm } from './interfaces';
import { format, startOfTomorrow } from 'date-fns';
import PendingEmployeeSchema from 'shared/schemas/PendingEmployeeSchema';
import { EmployeeStatus } from 'types';
import { nextMonday } from 'date-fns/esm';
import {
  checkIsMonthPicker,
  getDate,
  prepareMaxDateForPicker,
  prepareMinDateForPicker,
} from 'utils/contractDependencies';
import { useAppSelector } from 'store';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';
import StartDatePicker from '@components/Form/StartDatePicker';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';

export const PendingEmployeeModal: React.FC<{
  employeeTitle?: string | null;
  employeeName?: string;
  transitionDate: string | undefined;
  transitionType: EmployeeStatus | string;
  cantResend: boolean;
  resendLetter: () => void;
  close?: () => void;
  onSubmit?: ({
    transition,
    startDate,
  }: {
    transition: EmployeeStatus | string;
    startDate?: string;
  }) => void;
}> = ({
  employeeTitle,
  employeeName,
  transitionDate,
  transitionType,
  cantResend,
  resendLetter,
  close = () => {},
  onSubmit = () => {},
}) => {
  const intl = useIntl();
  const [open, setOpen] = useState(true);
  const [resendFlag, setRsendFlag] = useState(false);

  const { activationLetterRecipientMode } = useAppSelector(SettingsSelectors.settingsSettings);
  const isEuMode = activationLetterRecipientMode.toLowerCase() === 'eu';

  const activeContract = useAppSelector(state => state.contracts.euCreationCondition.data);

  const isMonthPicker = useMemo(
    () => checkIsMonthPicker(activeContract?.regularStartDate),
    [activeContract?.regularStartDate],
  );

  const prefilledDate = useMemo(() => {
    let prefilled = undefined;
    if (transitionDate) {
      prefilled = new Date(transitionDate);
    }
    if (isMonthPicker) {
      prefilled = prepareMinDateForPicker(startOfTomorrow(), isMonthPicker);
    } else {
      prefilled = nextMonday(new Date());
    }
    return prefilled ? format(prefilled, 'yyyy-MM-dd') : undefined;
  }, [transitionDate, isMonthPicker]);

  const handleClose = useCallback(() => {
    setOpen(false);
    if (close) {
      close();
    }
  }, [close]);

  const handleModalSubmit = (data?: PendingEmployeeForm) => {
    const startDate = format(
      getDate(data?.startDate || null, activeContract?.regularStartDate),
      'yyyy-MM-dd',
    );
    if (onSubmit) {
      onSubmit({ transition: transitionType, startDate });
    }
    if (resendFlag && !cantResend) {
      resendLetter();
      setRsendFlag(false);
    }
  };

  const methods = useForm<PendingEmployeeForm>({
    defaultValues: {
      startDate: prefilledDate,
    },
    mode: 'all',
    resolver: yupResolver(PendingEmployeeSchema),
  });
  const { control, handleSubmit, formState } = methods;
  const { isValid } = formState;

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleModalSubmit)}>
            <CloseIcon onClick={handleClose} className='dialog-close' />
            <DialogTitle>
              {intl.formatMessage({
                id: 'employee.modal.edit_start_date.title',
                defaultMessage: 'Edit start date for the employee membership',
              })}
            </DialogTitle>

            <DialogContent>
              <Typography variant='h3'>
                {`${employeeTitle ? ` ${employeeTitle} ` : ''}${employeeName}`}
              </Typography>
              <Spacer size='xl' />
              <Typography variant='subtitle1'>
                {intl.formatMessage({
                  id: 'employee.modal.edit_start_date.content',
                  defaultMessage: 'Choose a new start date for the employee membership',
                })}
              </Typography>
              <Spacer size='2xl' />
              <Stack direction='row' justifyContent='space-between' spacing={2}>
                <StartDatePicker
                  label={`${intl.formatMessage({
                    id: 'form.employee.field.start_date.label',
                    defaultMessage: 'Start date',
                  })}`}
                  name='startDate'
                  control={control}
                  width={254}
                  minimumDate={prepareMinDateForPicker(startOfTomorrow(), isMonthPicker)}
                  maximumDate={prepareMaxDateForPicker(activeContract?.endDate, isMonthPicker)}
                  isMonthPicker={isMonthPicker}
                />
              </Stack>
              <Spacer size='xl' />

              <FormControlLabel
                control={
                  <Checkbox
                    value={resendFlag}
                    onChange={() => setRsendFlag(flag => !flag)}
                    disabled={cantResend}
                  />
                }
                label={
                  <Typography variant='h3'>
                    {isEuMode
                      ? intl.formatMessage({
                          id: 'form.employee.start_date_change.checkbox.label.eu',
                          defaultMessage: 'Resend activation letter directly to the employee',
                        })
                      : intl.formatMessage({
                          id: 'form.employee.start_date_change.checkbox.label.cu',
                          defaultMessage: 'Resend activation letter to the portal users',
                        })}
                  </Typography>
                }
              />
              {cantResend && (
                <Typography variant='subtitle1'>
                  *' '
                  {intl.formatMessage(
                    isEuMode
                      ? {
                          id: 'view.user_management.actions.resend_letter.not_possible.eu',
                          defaultMessage:
                            'Cannot resend letter, because this employee does not have an email address',
                        }
                      : {
                          id: 'view.user_management.cant_create_user.description',
                          defaultMessage:
                            'Activation letter should be send to company user but company users list is empty',
                        },
                  )}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <CCPDefaultButton variant='outlined' onClick={handleClose}>
                {intl.formatMessage({ id: 'form.employee.cta.cancel', defaultMessage: 'Cancel' })}
              </CCPDefaultButton>
              <CCPDefaultButton variant='contained' type='submit' disabled={!isValid}>
                {intl.formatMessage({
                  id: 'form.employee.cta.edit_start_date',
                  defaultMessage: 'Confirm',
                })}
              </CCPDefaultButton>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default PendingEmployeeModal;
