import { styled, TextField } from '@mui/material';

type TextFieldProps = {
  width?: string | number;
  height?: string | number;
  isWarningError?: boolean;
};

export const DefaultTextField = styled(TextField)<TextFieldProps>(
  ({ width, height, theme, isWarningError }) => ({
    width: width ? width : '100%',
    position: 'relative',
    '.MuiFormHelperText-root.Mui-error': {
      color: theme.palette.error.main,
      fontFamily: 'Roboto-Medium',
      fontSize: 14,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.29,
      letterSpacing: 'normal',
      marginLeft: 0,
    },
    '.MuiFormLabel-root.MuiInputLabel-root.Mui-error': {
      color: isWarningError ? theme.palette.warning.main : theme.palette.error.main,
    },
    '.MuiInputBase-root.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: isWarningError ? theme.palette.warning.main : theme.palette.error.main,
    },
    '.MuiSelect-icon': {
      marginRight: 17,
      color: theme.palette.neutralDark.main,
      position: 'absolute',
      right: 0,
      pointerEvents: 'none',
    },
    '.Mui-disabled': {
      color: theme.palette.transparent.dark05,
    },
    '.MuiInputBase-formControl.Mui-disabled': {
      backgroundColor: theme.palette.transparent.disabledInput,
    },
    '.Mui-disabled fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.transparent.disabledInput,
    },
    '.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
      borderWidth: 1,
    },
    '.placeholder': {
      fontSize: 16,
      letterSpacing: 0.15,
      color: theme.palette.neutralDark.main,
    },
    '.MuiOutlinedInput-root.MuiInputBase-root': {
      height: height ? `${height}px` : 'auto',
    },
    '&.domains-wrapper .MuiOutlinedInput-root.MuiInputBase-root': {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '12px',
      padding: '16.5px',
      height: 'auto',
      '.MuiOutlinedInput-input': {
        padding: '0',
        flexGrow: 1,
        width: 'auto',
        minWidth: '150px',
      },
    },
    'input::-ms-reveal': {
      display: 'none',
    },
  }),
);
