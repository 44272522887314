import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useIntl } from 'react-intl';
import { CCPDefaultButton } from 'components';
import { useAppDispatch } from '@store/index';
import { AuthenticationActions } from '@features/authentication/store';
import { default as theme } from '@theme/theme';

const SuccessChanged = () => {
  const { formatMessage } = useIntl();
  const { language } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const redirectToLogin = () => {
    dispatch(AuthenticationActions.signOut());
    navigate(`/${language}/login`);
  };

  return (
    <Dialog open>
      <DialogTitle>
        <Box className='dialog-icon'>
          <CheckCircleOutlineIcon htmlColor={theme.palette.success.main} />
        </Box>
        <Typography variant='h2' textAlign='center'>
          {formatMessage({
            id: 'account.tab.info.email_changed_title',
            defaultMessage: 'Email successfully changed',
          })}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1' textAlign='center'>
          {formatMessage({
            id: 'account.tab.info.email_changed_subtitle_row1',
            defaultMessage: 'You have been logged out.',
          })}
          <br />
          {formatMessage({
            id: 'account.tab.info.email_changed_subtitle_row2',
            defaultMessage: 'Please login with the new e-mail again.',
          })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <CCPDefaultButton color='primary' variant='contained' onClick={redirectToLogin}>
          {formatMessage({
            id: 'Login',
            defaultMessage: 'Login',
          })}
        </CCPDefaultButton>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessChanged;
