import { resendTempPassword } from 'features/userManagement/store/actions/async/resendTempPassword/resendTempPassword';
import { addUser } from './actions/async/addUser/addUser';
import { getUsers } from './actions/async/getUsers/getUsers';
import { updateUser } from './actions/async/updateUser/updateUser';
import { changeUser } from './actions/async/changeUser/changeUser';
import { changePassword } from './actions/async/changePassword/changePassword';
import { changeUserEmail } from './actions/async/changeUserEmail/changeUserEmail';
import { verifyChangeUserEmail } from './actions/async/verifyChangeUserEmail/verifyChangeUserEmail';
import { actions as UserManagementSyncActions } from './userManagement.slice';

export const UserManagementActions = {
  ...UserManagementSyncActions,
  addUser,
  getUsers,
  updateUser,
  changeUser,
  changePassword,
  changeUserEmail,
  resendTempPassword,
  verifyChangeUserEmail,
};
