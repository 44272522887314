import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { RootState } from 'types';
import { useAppDispatch } from 'store';
import { SupportActions } from 'features/support/store';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { SupportCard } from 'features/support/components';
import { SupportWrapper } from './Support.styles';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import HubspotSupportForm from '@components/HubspotSupportForm';
import Spacer from '@components/Spacer';
import { HashLink as Link } from 'react-router-hash-link';

const ANCHOR_ID = 'hubspot_support_form';

const SupportPage = () => {
  const { formatMessage } = useIntl();
  const { language } = useParams();
  const dispatch = useAppDispatch();
  const { data } = useSelector((state: RootState) => state.support);
  const { activeCompany } = useSelector(CompanySelectors.getCompanyState);

  useEffect(() => {
    if (activeCompany?.companyId) {
      dispatch(SupportActions.getFaqs({ language, companyId: activeCompany.companyId }));
    }
  }, [activeCompany?.companyId, dispatch, language]);

  const scrollWithOffset = useCallback((el: HTMLElement) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80; // 72px header + 8px small top margin
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  }, []);

  return (
    <SupportWrapper>
      <Box className='header'>
        <Typography variant='h2'>
          {formatMessage({ id: 'navigation.support', defaultMessage: 'Support' })}
        </Typography>
        <Box className='header__status'>
          <Typography variant='caption'>{process.env.REACT_APP_VERSION}</Typography>
          <Typography variant='caption'>
            {formatMessage({ id: 'support.date', defaultMessage: 'Status: March 2022' })}
          </Typography>
        </Box>
      </Box>
      <Spacer size='sm' />
      <Typography variant='caption'>
        {formatMessage(
          {
            id: 'navigation.support.go_to_hubspot_form',
            defaultMessage: 'Any other question? Use the {form}.',
          },
          {
            form: (
              <Link to={`#${ANCHOR_ID}`} scroll={scrollWithOffset}>
                {formatMessage({
                  id: 'navigation.support.go_to_hubspot_form.link_text',
                  defaultMessage: 'form',
                })}
              </Link>
            ),
          },
        )}
      </Typography>
      <Spacer size='lg' />
      <Box className='main'>
        <Box className='questions'>
          {data?.categoryFaqs ? (
            <Box className='questions__list'>
              {data.categoryFaqs.map((item, index) => (
                <SupportCard key={index} data={item} />
              ))}
            </Box>
          ) : (
            <Box className='faq-empty'>
              <Box className='info'>
                <ErrorOutlineIcon />
                <Typography className='text' variant='body1'>
                  <strong>
                    {formatMessage({
                      id: 'faq.no_available',
                      defaultMessage: 'No FAQs are available',
                    })}
                  </strong>
                </Typography>
              </Box>
            </Box>
          )}
        </Box>

        <HubspotSupportForm anchorId={ANCHOR_ID} />
      </Box>
    </SupportWrapper>
  );
};

export default SupportPage;
