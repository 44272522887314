import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { CompanyState, FetchingStatus } from 'types';

import { getCompaniesNames } from './getCompaniesNames';

export const getCompaniesNamesExtraReducer = (builder: ActionReducerMapBuilder<CompanyState>) => {
  builder.addCase(getCompaniesNames.pending, state => {
    state.companiesNames.fetchingStatus = FetchingStatus.PENDING;
    state.companiesNames.data = [];
    state.companiesNames.error = null;
  });
  builder.addCase(getCompaniesNames.fulfilled, (state, action) => {
    state.companiesNames.fetchingStatus = FetchingStatus.FULFILLED;
    state.companiesNames.data = action.payload;
    if (
      !state.activeCompany ||
      action.payload.filter(item => item.companyId === state.activeCompany?.companyId).length === 0
    ) {
      state.activeCompany = action.payload.length === 1 ? action.payload[0] : null;
    }
  });
  builder.addCase(getCompaniesNames.rejected, (state, action) => {
    state.companiesNames.fetchingStatus = FetchingStatus.REJECTED;
    state.companiesNames.error = action.payload;
  });
};
