import { useMemo } from 'react';
import { StatusChip } from 'components/StatusChip';
import { useIntl } from 'react-intl';
import { default as theme } from '@theme/theme';

export interface ContractStatusChipProps {
  startDate?: any;
  status: string;
}

const ContractStatusChip = ({ startDate, status }: ContractStatusChipProps) => {
  const intl = useIntl();
  const backgroundColor = useMemo(() => {
    switch (status) {
      case 'active':
        return theme.palette.success.main;
      case 'pending':
        return theme.palette.primaryLight.darker || theme.palette.primaryLight.main;
      case 'paused':
        return theme.palette.warning.main;
      case 'blocked':
        return theme.palette.error.main;
      case 'not_started':
        return theme.palette.primaryLight.darker || theme.palette.primaryLight.main;
      default:
        return theme.palette.neutralDark.main;
    }
  }, [status]);
  // eslint-disable-next-line react/jsx-no-undef
  return (
    <StatusChip
      background={backgroundColor}
      label={
        status === 'not_started'
          ? intl.formatMessage(
              { id: 'view.contract.status.pending_label', defaultMessage: 'Active from {date}' },
              { date: startDate },
            )
          : intl.formatMessage({ id: `view.contract.status.${status}`, defaultMessage: status })
      }
    />
  );
};

export default ContractStatusChip;
