import { Box, Typography } from '@mui/material';
import { ReactComponent as BasicIcon } from 'assets/icons/hf-basic.svg';
import { ReactComponent as ProIcon } from 'assets/icons/hf-pro.svg';
import { ReactComponent as BestIcon } from 'assets/icons/hf-best.svg';
import { ReactComponent as DisabledIcon } from 'assets/icons/hf-disabled.svg';
import { useLanguage } from '@hooks/useLanguage';
import { TranslationsKeys } from '@localTypes/user';
import { ReactNode, useMemo } from 'react';
import Spacer from '@components/Spacer';
import { ContractStatus, IContractProduct } from '@localTypes/index';
import theme from '@theme/theme';
import { useIntl } from 'react-intl';

export const ContractTitle = ({ children }: { children?: string }) => (
  <Typography
    variant='h5'
    color={theme.palette.neutralDark.main}
    fontWeight='500'
    textTransform='uppercase'
    fontFamily='Roboto-Medium'>
    {children}
  </Typography>
);

export const ContractValue = ({
  status,
  children,
  bold,
}: {
  status: ContractStatus;
  children?: ReactNode;
  bold?: boolean;
}) => (
  <Typography
    variant={`subtitle${status === ContractStatus.Active ? '1' : '2'}`}
    fontSize='16px'
    display='flex'
    gap='8px'
    fontWeight={bold ? '600' : '400'}
    alignItems='center'>
    {children}
  </Typography>
);

export const DirectPayBadge = ({ isDirectPay }: { isDirectPay: boolean }) => {
  const { formatMessage } = useIntl();

  return isDirectPay ? (
    <Box
      borderRadius='12px'
      border={`1px solid ${theme.palette.neutralDark.light}`}
      display='inline-block'
      padding='0 10px'>
      {formatMessage({
        id: 'view.contract.direct_pay',
        defaultMessage: 'Direct pay',
      })}
    </Box>
  ) : null;
};

export const ContractProductsWrapper = ({
  title,
  productsList,
  status,
}: {
  title: string;
  productsList: IContractProduct[];
  status: ContractStatus;
}) => (
  <>
    <Spacer size='xl' />
    <ContractTitle>{title}</ContractTitle>
    <Spacer size='md' />
    <Box display='flex' flexDirection='column' gap='24px'>
      <ContractProductsList
        products={productsList}
        isDisabled={status === ContractStatus.Expired || status === ContractStatus.Paused}
      />
    </Box>
  </>
);

const ContractProductsList = ({ products, isDisabled }: any) => {
  const activeLanguage = useLanguage();

  const anotherTranslation: TranslationsKeys = useMemo(
    () => (activeLanguage === 'de' ? 'en' : 'de'),
    [activeLanguage],
  );

  const checkProductType = (product: string) => {
    if (!product) {
      return null;
    }
    const productType = product.toLocaleLowerCase();
    if (productType.includes('basic')) {
      return <BasicIcon />;
    }
    if (productType.includes('pro')) {
      return <ProIcon />;
    }
    if (productType.includes('best')) {
      return <BestIcon />;
    }
    return <ProIcon />;
  };

  return products?.map((product: any, index: number) => (
    <Box key={index} display='flex' flexDirection='column' alignItems='flex-start'>
      <Box display='flex' gap='5px' className='productChip'>
        {isDisabled ? <DisabledIcon /> : checkProductType(product.name)}
        {product.name}
      </Box>
      <Spacer size='md' />
      <Typography variant='caption'>
        {product.localizedFields[activeLanguage]?.description ||
          product.localizedFields[anotherTranslation]?.description ||
          ''}
      </Typography>
    </Box>
  ));
};
