import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { FetchingStatus, SettingsState } from 'types';
import { removeEmployeeInvitation } from './removeEmployeeInvitation';

export const removeEmployeeInvitationExtraReducer = (
  builder: ActionReducerMapBuilder<SettingsState>,
) => {
  builder.addCase(removeEmployeeInvitation.pending, (state, action) => {
    state.selfRegistrationUsers.fetchingStatus = FetchingStatus.PENDING;
  });
  builder.addCase(removeEmployeeInvitation.fulfilled, (state, action) => {
    state.selfRegistrationUsers.fetchingStatus = FetchingStatus.FULFILLED;
    state.selfRegistrationUsers.data.splice(
      state.selfRegistrationUsers.data.findIndex(item => item.token === action.payload),
      1,
    );
    //state.selfRegistrationUsers.data = action.payload;
  });

  builder.addCase(removeEmployeeInvitation.rejected, (state, action) => {
    state.selfRegistrationUsers.fetchingStatus = FetchingStatus.REJECTED;
  });
};
