import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosWithConfig } from 'api';
import { AxiosError } from 'axios';
import { showToast } from 'utils';
import { SettingsResponse, GetSettingsRequestParams } from 'types';

export const getSettings = createAsyncThunk<
  SettingsResponse,
  GetSettingsRequestParams
>('settings/getSettings', async ({ companyId }, { rejectWithValue }) => {
  const endpoint = process.env.REACT_APP_PIMCORE_ENDPOINT + `api/companies/${companyId}/settings`;
  const axios = await axiosWithConfig();

  try {
    return (await axios.get(endpoint)).data;
  } catch (e) {
    const error = e as AxiosError;
    showToast('error', {
      id: 'toast.get_settings.error',
      defaultMessage: error.message,
    } );
    return rejectWithValue(e);
  }
});
