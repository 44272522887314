export interface TwoRowsSideBarItemProps {
  text: string;
  type: EManagementView;
  isActive: boolean;
}

export enum EManagementView {
  View = 'view',
  Membership = 'membership',
  Edit = 'edit',
}
