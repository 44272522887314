import { Box, Stack, Typography } from '@mui/material';

import { CompanySelectors } from 'features/company/store/company.selectors';

import { AllCompaniesWrapper } from './AllCompanies.styles';
import { useIntl } from 'react-intl';
import { useAppSelector } from '@store/index';
import { Spacer } from '@components/index';
import CompanyCard from '@features/dashboard/components/CompanyCard';
import ExportEmployee from '@features/dashboard/components/ExportEmployee';

const AllCompaniesPage = () => {
  const { formatMessage } = useIntl();
  const { companiesNames } = useAppSelector(CompanySelectors.getCompanyState);
  const companiesList = companiesNames.data || [];

  return (
    <AllCompaniesWrapper>
      <Stack flexDirection='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='h2'>
          {formatMessage({ id: 'all_companies', defaultMessage: 'All companies' })}
        </Typography>
        <ExportEmployee />
      </Stack>
      <Spacer size='xl' />
      <Box>
        {companiesList.map(item => (
          <CompanyCard key={item.companyId} companyInfo={item} />
        ))}
      </Box>
    </AllCompaniesWrapper>
  );
};

export default AllCompaniesPage;
