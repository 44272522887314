import { FetchingStatus, EmployeeManagementState, BULK_IMPORT_STEPS } from 'types';

export const initialState: EmployeeManagementState = {
  api: {
    addEmployee: {
      fetchingStatus: FetchingStatus.IDLE,
      data: null,
      error: null,
    },
    getEmployees: {
      fetchingStatus: FetchingStatus.IDLE,
      data: null,
      error: null,
    },
    getEmployee: {
      fetchingStatus: FetchingStatus.IDLE,
      data: null,
      error: null,
    },
    getEmployeeStatus: {
      fetchingStatus: FetchingStatus.IDLE,
      data: null,
      error: null,
    },
    changeEmployee: {
      fetchingStatus: FetchingStatus.IDLE,
      data: null,
      error: null,
    },
    changeEmployeeStatus: {
      fetchingStatus: FetchingStatus.IDLE,
      data: null,
      error: null,
    },
    changeEmployeesStatus: {
      fetchingStatus: FetchingStatus.IDLE,
      data: null,
      error: null,
    },
    createEndUser: {
      fetchingStatus: FetchingStatus.IDLE,
      data: null,
      error: null,
    },
    bulkImportCsvFile: {
      fetchingStatus: FetchingStatus.IDLE,
      data: null,
      error: null,
    },
    cancelImportProgress: {
      fetchingStatus: FetchingStatus.IDLE,
      data: null,
      error: null,
    },
    getBulkUploadFile: {
      data: { companyBulkUpload: null, hansefitBulkUpload: null },
      fetchingStatus: FetchingStatus.IDLE,
      error: null,
    },
    employeeLicense: {
      data: null,
      fetchingStatus: FetchingStatus.IDLE,
      error: null,
    },
  },
  newEmployees: [],
  tableConfigurations: {},
  addEmployeeModalOpen: false,
  currentStep: BULK_IMPORT_STEPS.UPLOAD_STEP,
};
