import { styled } from '@mui/material';
import { Box } from '@mui/system';

const MainBlock = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '16px',
  padding: '40px 12px 20px',
}));

export default MainBlock;
