import { FetchingStatus, PimcoreErrorResponse, TranslationsKeys } from 'types';

export interface ContractsState {
  getContracts: {
    data: ContractResponse[];
    fetchingStatus: FetchingStatus;
    error?: PimcoreErrorResponse | null;
  };
  euCreationCondition: {
    data: IEUConditions | null;
    fetchingStatus: FetchingStatus;
    error?: PimcoreErrorResponse | null;
  };
}

export interface ContractsPayload {
  companyId: number;
}

export interface IEUConditions {
  regularStartDate: '1st_of_month' | 'flexible';
  startDate: string;
  endDate: string;
  class: ContractTypeNames;
  createEditEUPossible: boolean;
  deleteEu: boolean;
  endContractPossible: boolean;
  pauseContractPossible: boolean;
  reactivationAllowed: boolean;
  quotaSize: null | number;
}

export type contractPermissionsName =
  | 'createEditEUPossible'
  | 'deleteEu'
  | 'endContractPossible'
  | 'pauseContractPossible'
  | 'reactivationAllowed';

export enum hubSpotFeeType {
  TrainingInstructionFee = 'TrainingInstructionFee',
  ServiceFee = 'ServiceFee',
  CorporateFeeContract = 'CorporateFeeContract',
  FlatLicenseContract = 'FlatLicenseContract',
  QuotaLicenseContract = 'QuotaLicenseContract',
  SingleLicenseContract = 'SingleLicenseContract',
}
export interface hubspotProductResponse {
  amount: number;
  billingFrequency: 'Monthly' | 'OneTime';
  billingStartDate: string;
  payer: 'Member' | 'Company';
  term: string;
  type: hubSpotFeeType;
  unitPrice: number;
  unitPriceBrutto: number;
}

export interface IContractProduct {
  name: string;
  isMainProduct: boolean;
  localizedFields: Record<TranslationsKeys, { description: string | null }>;
}
export interface ContractResponse {
  endDate: string | null;
  id: number;
  isDirectPay: boolean;
  limitationOfLicense: null | number;
  monthlyFee: null | number;
  monthlyFeeNetto: null | number;
  products: IContractProduct[];
  serviceFee: number;
  serviceFeeNetto: number;
  startDate: string;
  status: ContractStatus;
  trainingInstructionFee: number;
  trainingInstructionFeeNetto: number;
  settleTrainingInstructionFeeFrom: string;
  type: IEUConditions;
  numberOfEmployees?: number;
  hubSpotProductUnits?: hubspotProductResponse[];
}

export type ContractTypeNames =
  | 'FlatLicenseContract'
  | 'CorporateFeeContract'
  | 'QuotaLicenseContract'
  | 'SingleLicenseContract'
  | 'CasLicenseContract';

export enum ContractStatus {
  NotStarted = 'not_started',
  Active = 'active',
  Paused = 'paused',
  Expired = 'expired',
}
