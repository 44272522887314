import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ICompanyAsset, PimcoreErrorResponse } from 'types';
import { axiosWithConfig } from 'api';
import { uploadAssetsState } from '../../../interfaces';
import { CompanyActions } from 'features/company/store';
import { showToast } from 'utils';

export const removeCompanyAssets = createAsyncThunk<
  uploadAssetsState,
  { assetType: 'logo' | 'picture'; assetId: number },
  { rejectValue: PimcoreErrorResponse }
>(
  'companyManagement/removeCompanyAssets',
  async ({ assetType, assetId }, { rejectWithValue, getState, dispatch }) => {
    const currentState: any = getState();
    const companyId =
      currentState.company.activeCompany.companyId ||
      currentState.company.companiesNames.data[0].companyId;

    const axios = await axiosWithConfig();
    const endpoint =
      process.env.REACT_APP_PIMCORE_ENDPOINT + `api/companies/${companyId}/assets/${assetId}`;

    const { status } = await axios.delete(endpoint);
    if (status === 204) {
      showToast('success', {
        id: `toast.company_asset.remove.success.${assetType}`,
        defaultMessage: `${
          assetType[0].toUpperCase() + assetType.slice(1)
        } was removed successfully`,
      });
    }

    dispatch(
      CompanyActions.updateAssets({
        type: assetType,
        value: {
          assetId: null,
          originalFilename: null,
          uri: null,
        } as ICompanyAsset,
      }),
    );

    try {
      return Promise.resolve({} as uploadAssetsState);
    } catch (e) {
      const error: AxiosError<PimcoreErrorResponse> = e as AxiosError<PimcoreErrorResponse>;
      const { response } = error;
      const data = response?.data as PimcoreErrorResponse;

      return rejectWithValue(data);
    }
  },
);
