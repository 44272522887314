import { RootState } from 'types';

const currentStep = (state: RootState) => state.selfRegistration.currentStep;

const api = (state: RootState) => state.selfRegistration.api;

const getAddPersonalInfoStep = (state: RootState) =>
  state.selfRegistration.steps.addPersonalInfoStep;

export const SelfRegistrationSelectors = {
  getAddPersonalInfoStep,
  currentStep,
  api,
};
