import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { FetchingStatus } from 'types';

import { StatsState } from '../../../interfaces';
import { getCoupons } from './getCoupons';

export const getCouponsExtraReducer = (builder: ActionReducerMapBuilder<StatsState>) => {
  builder.addCase(getCoupons.pending, (state, action) => {
    state.coupons.error = null;
    state.coupons.fetchingStatus = FetchingStatus.PENDING;
  });
  builder.addCase(getCoupons.fulfilled, (state, action) => {
    state.coupons.fetchingStatus = FetchingStatus.FULFILLED;
    state.coupons.error = null;
    state.coupons.data = action.payload;
  });

  builder.addCase(getCoupons.rejected, (state, action) => {
    state.coupons.fetchingStatus = FetchingStatus.REJECTED;
    state.coupons.error = action.error;
  });
};
