import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToast } from 'utils';
import { axiosWithConfig } from 'api';
import { UserManagementBaseResponse, GetInvoicesRequestParams, GetInvoicesResponse } from 'types';

export const getInvoices = createAsyncThunk<GetInvoicesResponse, GetInvoicesRequestParams>(
  'invoices/getInvoices',
  async ({ companyId }, { rejectWithValue }) => {
    const endpoint =
      process.env.REACT_APP_USER_ACCESS_ENDPOINT + `resourceAuth/companyAssetsS3/${companyId}`;
    const axios = await axiosWithConfig();

    try {
      const { data } = await axios.get(endpoint);

      return data;
    } catch (e) {
      const error: AxiosError<UserManagementBaseResponse> =
        e as AxiosError<UserManagementBaseResponse>;
      showToast('error', {
        id: 'toast.get_invoices.error',
        defaultMessage: error.message,
      } );
      return rejectWithValue(error.response?.data.message);
    }
  },
);
