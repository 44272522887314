import { styled, TablePagination } from '@mui/material';

export const TablePaginationStyled = styled(TablePagination)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  width: '100%',
  borderTop: `2px solid ${theme.palette.primaryLight.main}`,
  '& .MuiTablePagination-toolbar': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'relative',
    padding: 0,
    margin: 0,
    height: 60,
    paddingLeft: 16,
    paddingRight: 16,
  },
  '& .MuiTablePagination-spacer': {
    flex: 'initial',
    display: 'none',
  },
  '& .MuiTablePagination-selectLabel': {
    padding: 0,
    margin: 0,
    justifySelf: 'self-start',

    fontSize: 16,
  },
  '& .MuiInputBase-root.MuiInputBase-colorPrimary': {
    padding: 0,
    margin: 0,
    justifySelf: 'self-start',
    width: 115,
    border: `1px solid  ${theme.palette.neutralLight.dark}`,
    borderRadius: '4px',
    height: 40,
    marginLeft: 20,

    fontSize: 16,
  },
  '& .MuiTablePagination-select.MuiSelect-select.MuiSelect-standard': {
    textAlign: 'start',
    textAlignLast: 'start',
    paddingLeft: 15,

    fontSize: 16,
  },
  '& .MuiTablePagination-displayedRows': {
    padding: 0,
    margin: 0,
    flex: 1,
    textAlign: 'center',

    fontSize: 16,
  },
  '& .MuiTablePagination-actions': {
    padding: 0,
    margin: 0,
  },
}));
