import { FetchingStatus, SELF_REGISTRATION_STEPS, SelfRegistrationState } from 'types';

export const initialState: SelfRegistrationState = {
  api: {
    registerAdminUser: {
      fetchingStatus: FetchingStatus.IDLE,
      data: null,
      error: null,
    },
    getSignUpKeyData: {
      fetchingStatus: FetchingStatus.IDLE,
      data: null,
      error: null,
    },
  },
  currentStep: SELF_REGISTRATION_STEPS.ADD_PERSONAL_INFO,
  steps: {
    addPersonalInfoStep: null,
  },
};
