import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { PimcoreErrorResponse, ResendActivationParam } from 'types';
import { axiosWithConfig } from 'api';

import { showToast } from 'utils';

export const resendActivationLetter = createAsyncThunk<
  any,
  ResendActivationParam,
  { rejectValue: PimcoreErrorResponse }
>(
  'employeeManagement/resendActivationLetter',
  async ({ endUserId, language }, { rejectWithValue }) => {
    const axios = await axiosWithConfig({
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    const params = {
      language,
      type: 'resend',
    };

    const endpoint =
      process.env.REACT_APP_PIMCORE_ENDPOINT + `api/endusers/${endUserId}/send-activation`;

    try {
      const { data } = await axios.get(endpoint, {
        params,
      });
      showToast('success', {
        id: 'modal.resend_activation_letter.success',
        defaultMessage: 'Activation letter was resent',
      });

      return data;
    } catch (e) {
      const error: AxiosError<PimcoreErrorResponse> = e as AxiosError<PimcoreErrorResponse>;
      const { response } = error;
      const data = response?.data as PimcoreErrorResponse;
      showToast('error', {
        id: 'modal.error',
        defaultMessage: 'Error',
      });

      return rejectWithValue(data);
    }
  },
);
