import { CSSObject, Interpolation, Theme } from '@emotion/react';
import { ReactNode } from 'react';
import { default as theme } from '@theme/theme';

type CondensedAccountInfoProps = {
  firstname?: string;
  lastname?: string;
  companyname?: string;
  email: string;
  companyId: number;
  actionButton?: ReactNode;
  containerCss?: Interpolation<Theme> | CSSObject;
};

const CondensedAccountInfo = ({
  firstname = '',
  lastname = '',
  companyname = '',
  email,
  companyId = 0,
  actionButton,
  containerCss,
}: CondensedAccountInfoProps) => {
  const mergedCss = containerCss && typeof containerCss === 'object' ? containerCss : {};
  return (
    <div
      css={{
        padding: 16,
        background: theme.palette.primaryLight.light,
        borderRadius: 4,
        border: `0.50px ${theme.palette.primary.main} solid`,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: 8,
        display: 'inline-flex',
        width: '100%',
        ...mergedCss,
      }}>
      <div
        css={{
          color: theme.palette.neutralDark.dark,
          fontSize: 18,
          fontFamily: theme.typography.fontFamily,
          fontWeight: '600',
          wordWrap: 'break-word',
        }}>
        {[firstname, lastname].filter(name => name).join(' ')}
      </div>
      <div
        css={{
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: 8,
          display: 'inline-flex',
        }}>
        <div
          css={{
            flex: '1 1 0',
            color: theme.palette.neutralDark.main,
            fontSize: 16,
            fontFamily: theme.typography.fontFamily,
            fontWeight: '400',
          }}>
          {[companyname, email, companyId ? `ID:\u00A0${companyId}` : null]
            .filter(name => name)
            .join(' | ')}
        </div>
      </div>

      {actionButton}
    </div>
  );
};

export default CondensedAccountInfo;
