// A custom theme for this app
import { createTheme, Theme } from '@mui/material';
import { deDE } from '@mui/material/locale';
import FigmaDesignTokens from 'design/design-tokens.json';

import type {} from '@mui/x-date-pickers/themeAugmentation';
declare module '@mui/material/styles' {
  interface PaletteColor {
    darker?: string;
  }

  interface SimplePaletteColorOptions {
    darker?: string;
  }
  interface Palette {
    neutralLight: SimplePaletteColorOptions;
    neutralDark: SimplePaletteColorOptions;
    primaryLight: SimplePaletteColorOptions;
    transparent: SimplePaletteColorOptions & {
      dropdownSelected: string;
      boxShadowDark01: string;
      boxShadowDark02: string;
      dark05: string;
      blue05: string;
      blue07: string;
      disabledInput: string;
      darkYellow: string;
    };
    gradient: SimplePaletteColorOptions & { pinnedColumnsRight: string };
    tableTemplate: { fontColor: string; backgroundColor: string };
  }
  interface PaletteOptions {
    neutralLight: SimplePaletteColorOptions;
    neutralDark: SimplePaletteColorOptions;
    primaryLight: SimplePaletteColorOptions;
    transparent: SimplePaletteColorOptions & {
      dropdownSelected: string;
      boxShadowDark01: string;
      boxShadowDark02: string;
      dark05: string;
      blue05: string;
      blue07: string;
      disabledInput: string;
      darkYellow: string;
    };
    gradient: SimplePaletteColorOptions;
    tableTemplate: { fontColor: string; backgroundColor: string };
  }
}

const CCPTheme: Theme = createTheme(
  {
    typography: {
      fontFamily: ['Roboto-Regular', 'Helvetica', 'Arial', 'sans-serif', 'Nutmeg Headline'].join(
        ',',
      ),
      allVariants: {
        fontFamily: "'Roboto-Regular', 'Helvetica', 'Arial', sans-serif",
        textTransform: 'none',
      },
      h1: {
        fontFamily: `${FigmaDesignTokens.Heading.M.Medium.fontFamily}-${FigmaDesignTokens.Heading.M.Medium.fontWeight}`,
        fontSize: FigmaDesignTokens.Heading.M.Medium.fontSize,
        lineHeight: `{FigmaDesignTokens.Heading.M.Medium.lineHeight}px`,
        letterSpacing: FigmaDesignTokens.Heading.M.Medium.letterSpacing,
        textTransform: FigmaDesignTokens.Heading.M.Medium
          .textCase as React.CSSProperties['textTransform'],
        color: FigmaDesignTokens.txt.primary,
      },
      h2: {
        fontFamily: `${FigmaDesignTokens.Heading.XL20.fontFamily}-${FigmaDesignTokens.Heading.XL20.fontWeight}`,
        fontSize: FigmaDesignTokens.Heading.XL20.fontSize,
        lineHeight: `{FigmaDesignTokens.Heading.XL20.lineHeight}px`,
        letterSpacing: FigmaDesignTokens.Heading.XL20.letterSpacing,
        textTransform: FigmaDesignTokens.Heading.XL20
          .textCase as React.CSSProperties['textTransform'],
        color: FigmaDesignTokens.txt.primary,
      },
      h3: {
        fontFamily: `${FigmaDesignTokens.Body.L.medium.fontFamily}-${FigmaDesignTokens.Body.L.medium.fontWeight}`,
        fontSize: FigmaDesignTokens.Body.L.medium.fontSize,
        lineHeight: `{FigmaDesignTokens.Body.L.medium.lineHeight}px`,
        letterSpacing: FigmaDesignTokens.Body.L.medium.letterSpacing,
        textTransform: FigmaDesignTokens.Body.L.medium
          .textCase as React.CSSProperties['textTransform'],
        color: FigmaDesignTokens.txt.primary,
      },
      h4: {
        fontFamily: `${FigmaDesignTokens.Heading.S12.fontFamily}-${FigmaDesignTokens.Heading.S12.fontWeight}`,
        fontSize: FigmaDesignTokens.Heading.S12.fontSize,
        lineHeight: `{FigmaDesignTokens.Heading.S12.lineHeight}px`,
        letterSpacing: FigmaDesignTokens.Heading.S12.letterSpacing,
        textTransform: FigmaDesignTokens.Heading.S12
          .textCase as React.CSSProperties['textTransform'],
        color: FigmaDesignTokens.txt.primary,
        marginBottom: 16,
      },
      h5: {
        fontFamily: `${FigmaDesignTokens.Body.M.medium.fontFamily}-${FigmaDesignTokens.Body.M.medium.fontWeight}`,
        fontSize: FigmaDesignTokens.Body.M.medium.fontSize,
        lineHeight: `{FigmaDesignTokens.Body.M.medium.lineHeight}px`,
        letterSpacing: FigmaDesignTokens.Body.M.medium.letterSpacing,
        textTransform: FigmaDesignTokens.Body.M.medium
          .textCase as React.CSSProperties['textTransform'],
        color: FigmaDesignTokens.txt.secondary,
      },
      h6: {
        fontFamily: `${FigmaDesignTokens.Heading.L16.fontFamily}-${FigmaDesignTokens.Heading.L16.fontWeight}`,
        fontSize: FigmaDesignTokens.Heading.L16.fontSize,
        lineHeight: `{FigmaDesignTokens.Heading.L16.lineHeight}px`,
        letterSpacing: FigmaDesignTokens.Heading.L16.letterSpacing,
        color: FigmaDesignTokens.txt.primary,
        textTransform: FigmaDesignTokens.Heading.L16
          .textCase as React.CSSProperties['textTransform'],
      },
      subtitle1: {
        fontFamily: `${FigmaDesignTokens.Body.L.normal.fontFamily}-${FigmaDesignTokens.Body.L.normal.fontWeight}`,
        fontSize: FigmaDesignTokens.Body.L.normal.fontSize,
        lineHeight: `{FigmaDesignTokens.Body.L.normal.lineHeight}px`,
        letterSpacing: FigmaDesignTokens.Body.L.normal.letterSpacing,
        textTransform: FigmaDesignTokens.Body.L.normal
          .textCase as React.CSSProperties['textTransform'],
        color: FigmaDesignTokens.txt.secondary,
      },
      subtitle2: {
        fontFamily: `${FigmaDesignTokens.Body.M.normal.fontFamily}-${FigmaDesignTokens.Body.M.normal.fontWeight}`,
        fontSize: FigmaDesignTokens.Body.M.normal.fontSize,
        lineHeight: `{FigmaDesignTokens.Body.M.normal.lineHeight}px`,
        letterSpacing: FigmaDesignTokens.Body.M.normal.letterSpacing,
        textTransform: FigmaDesignTokens.Body.M.normal
          .textCase as React.CSSProperties['textTransform'],
        color: FigmaDesignTokens.txt.tertiary,
      },
      caption: {
        fontFamily: `${FigmaDesignTokens.Body.L.normal.fontFamily}-${FigmaDesignTokens.Body.L.normal.fontWeight}`,
        fontSize: FigmaDesignTokens.Body.L.normal.fontSize,
        lineHeight: `{FigmaDesignTokens.Body.L.normal.lineHeight}px`,
        letterSpacing: FigmaDesignTokens.Body.L.normal.letterSpacing,
        textTransform: FigmaDesignTokens.Body.L.normal
          .textCase as React.CSSProperties['textTransform'],
        color: FigmaDesignTokens.txt.tertiary,
      },
    },
    palette: {
      background: {
        default: FigmaDesignTokens.bg.bgPage,
        paper: FigmaDesignTokens.bg.surface,
      },
      primary: {
        main: FigmaDesignTokens.txt.Brand.brand,
        dark: FigmaDesignTokens.txt.Brand.brandHover,
        darker: FigmaDesignTokens.txt.Brand.brandActive,
        contrastText: FigmaDesignTokens.txt.inverted.invertPrimary,
      },
      primaryLight: {
        light: '#f8f9fb', //blue-light-000
        main: FigmaDesignTokens.bg.neutral.light.light,
        dark: FigmaDesignTokens.bg.neutral.light.lightHover,
        darker: FigmaDesignTokens.bg.neutral.light.lightActive,
      },
      neutralLight: {
        light: FigmaDesignTokens.bg.neutral.light.light,
        main: FigmaDesignTokens.bg.neutral.light.lightHover,
        dark: FigmaDesignTokens.bg.neutral.light.lightActive,
      },
      neutralDark: {
        light: FigmaDesignTokens.bg.neutral.bold.neutral,
        main: FigmaDesignTokens.bg.neutral.bold.neutralHover,
        dark: FigmaDesignTokens.bg.neutral.bold.neutralActive,
        contrastText: '#000',
      },
      error: {
        main: FigmaDesignTokens.bg.critical.bold.bold, //red-accent
      },
      warning: {
        main: FigmaDesignTokens.bg.warning.bold.bold, //orange-accent
      },
      success: {
        main: FigmaDesignTokens.bg.success.bold.bold, //green-accent
      },
      transparent: {
        light: '#ffffff33', // white 20%
        main: '#ffffff80', // white 50%
        dark: '#ffffffcc', // white 80%
        dropdownSelected: '#003bb34d', //rgba(0, 59, 179, 0.3)
        boxShadowDark01: '#0000001a', //rgba(0, 0, 0, 0.1)
        boxShadowDark02: '#00000033', //rgba(0, 0, 0, 0.2)
        dark05: '#00000080', //rgba(0, 0, 0, 0.5)
        blue05: '#eff2f680', //rgba(239, 242, 246, 0.5)
        blue07: '#eff2f6b3', //rgba(239, 242, 246, 0.7)
        disabledInput: '#d8d8d880', //rgba(216, 216, 216, 0.5)
        darkYellow: '#f9a50033', //rgba(249, 165, 0, 0.2)
      },

      gradient: {
        main: '', //MuiColorInput picks this color by default, results in crushing page
        light: 'linear-gradient(124deg, #002A80 14.57%, #D3E6F5 87.33%)', //gradient blue-01
      },

      tableTemplate: {
        fontColor: '#a9bbcd',
        backgroundColor: '#a9bbcd33',
      },
    },

    components: {
      MuiDialog: {
        styleOverrides: {
          root: {
            '.MuiBackdrop-root': {
              backgroundColor: '#eff2f6b3',
            },
          },
          paper: {
            maxWidth: 640,
            minWidth: 620,
            boxShadow: `0 10px 10px 0 #0000001a`,
            position: 'relative',
            '& .dialog-close': {
              position: 'absolute',
              top: '24px',
              right: '24px',
              cursor: 'pointer',
            },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontSize: '20px',
            padding: '32px 48px 24px',
            '& .dialog-icon': {
              paddingBottom: '24px',
              svg: {
                width: 36,
                height: 36,
              },
            },
          },
        },
      },
      MuiDialogContent: { styleOverrides: { root: { padding: '24px 48px' } } },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '24px 48px 32px',
            '&>:not(:first-of-type)': {
              marginLeft: '16px',
            },
          },
        },
      },
      MuiMenuList: {
        styleOverrides: {
          root: {
            padding: 0,
            boxShadow: '0 5px 6px -3px #0000001a, 0 3px 8px 2px #00000033',
            background: '#fff',
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            minHeight: '36px !important',
            fontSize: 16,
            lineHeight: 1.38,
            color: '#1f2126',
            padding: '8px 16px',
            '&:hover': { background: '#eff2f6' },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 18,
            width: 'fit-content',
            height: 'fit-content',
            minWidth: 142,
            padding: '8px 18px',
            border: 'none',
            fontFamily: 'Roboto-Medium',
            fontWeight: 500,
            fontSize: 16,
            lineHeight: '20px',
            textTransform: 'unset',
            '.MuiTouchRipple-root': {
              display: 'none',
            },
            '&.MuiButton-outlined': {
              padding: '6px 16px',
              border: '2px solid currentColor',
            },
            '&.MuiButton-outlinedPrimary:hover': {
              color: '#97B7E1',
              borderColor: '#97B7E1',
              backgroundColor: '#EFF2F6',
            },
            '&.MuiButton-outlinedPrimary:active': {
              backgroundColor: '#E5F1FF',
            },
            '&.MuiButton-outlinedError:hover': {
              color: '#EC7FA6',
            },
            '&.MuiButton-outlined:disabled': {
              color: '#6B7986',
              borderColor: '#6B7986',
              backgroundColor: 'transparent',
            },

            '&.MuiButton-containedPrimary:hover': {
              backgroundColor: '#002A80',
              color: '#97B7E1',
            },
            '&.MuiButton-containedPrimary:active': {
              backgroundColor: '#001F5C',
            },
            '&.MuiButton-containedError:hover': {
              backgroundColor: '#B20F49',
              color: '#EC7FA6',
            },
            '&.MuiButton-containedError:active': {
              backgroundColor: '#800E37',
            },
            '&.MuiButton-contained:disabled': {
              backgroundColor: '#A3ACB5',
              color: '#EBEBEB',
            },

            '&.MuiButton-textPrimary': {
              color: '#6B7986',
              backgroundColor: 'transparent',
            },
            '&.MuiButton-textSecondary': {
              color: '#003BB3',
              backgroundColor: 'transparent',
            },
            '&.MuiButton-textPrimary:hover, &.MuiButton-textSecondary:hover': {
              color: '#003BB3',
            },
            '&.MuiButton-textPrimary:active': {
              color: '#97B7E1',
            },
            '&.MuiButton-textPrimary:disabled': {
              color: '#A3ACB5',
            },
          },
        },
      },
      MuiStack: {
        defaultProps: {
          position: 'relative',
        },
      },
    },
  },
  deDE,
);

export default CCPTheme;
