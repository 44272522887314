import { Box, Typography } from '@mui/material';

import { ServiceWrapper } from './Service.styles';
import { useIntl } from 'react-intl';
import { CCPDefaultButton, Spacer } from 'components';
import ServiceImage from 'assets/image/service_provider.png';

const ServiceProviderPage = () => {
  const intl = useIntl();

  const handleAction = () => {
    window.open('https://hansefit.de/studio-finden/', '_blank')?.focus();
  };

  return (
    <ServiceWrapper>
      <Typography variant='h2'>
        {intl.formatMessage({ id: 'view.service.title', defaultMessage: 'Service providers' })}
      </Typography>
      <Spacer size='lg' />
      <Box className='content_wrapper'>
        <Box padding={4} className='content'>
          <Typography variant='h6'>
            {intl.formatMessage({
              id: 'view.service.find',
              defaultMessage: 'Find our partner in your area',
            })}
          </Typography>
          <Spacer size='md' />
          <Typography variant='caption'>
            {intl.formatMessage({
              id: 'view.service.content',
              defaultMessage:
                'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
            })}
          </Typography>
          <Typography variant='caption'>
            {intl.formatMessage({
              id: 'view.service.sub_content',
              defaultMessage:
                'At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
            })}
          </Typography>
          <Spacer size='xl' />
          <CCPDefaultButton variant='contained' onClick={handleAction}>
            {intl.formatMessage({
              id: 'view.service.go_to_search',
              defaultMessage: 'Go to studio search',
            })}
          </CCPDefaultButton>
        </Box>
        <Box className='imgWrapper'>
          <img alt='service provider' src={ServiceImage} />
        </Box>
      </Box>
    </ServiceWrapper>
  );
};

export default ServiceProviderPage;
