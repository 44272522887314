import { Box, IconButton, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { EmployeeStatus } from 'types';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '@hooks/useLanguage';
import { useAppDispatch, useAppSelector } from 'store';
import { checkTypeParameter } from 'utils/contractDependencies';
import { MenuWrapper } from 'components/TableGrid/Table.styles';
import { EmployeeManagementActions } from 'features/employeeManagement/store';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';
import { EmployeeManagementSelectors } from 'features/employeeManagement/store/employeeManagement.selectors';

import { AlertModal, GoToSettings, Spacer } from 'components';
import ResendLetterModal from '../../../Modals/ResendLetterModal';
import PauseEmployeeModal from '../../../Modals/PauseEmployeeModal';
import RemoveEmployeeModal from '../../../Modals/RemoveEmployeeModal';
import EmployeeRequestModal, { initRequestModal } from '../../../Modals/EmployeeRequestModal';
import PendingEmployeeModal from '../../../Modals/PendingEmployeeModal';
import DeactivationEmployeeModal from '../../../Modals/DeactiveEmployeeModal';

export interface EmployeeMoreExpanderProps {
  companyId?: number;
  userTitle?: string | null;
  userFullName: string;
  userId: number;
  userStatus: EmployeeStatus;
  userEntryDate: string;

  hasBusinessEmail: boolean;
  isDirectPay: boolean;
}

export const EmployeeMoreExpander = ({
  companyId,
  userTitle,
  userFullName,
  userId,
  userStatus,
  userEntryDate,

  hasBusinessEmail,
  isDirectPay,
}: EmployeeMoreExpanderProps) => {
  const initActivationModalState = {
    reactivateModal: false,
    deactivateModal: false,
  };
  const { formatMessage } = useIntl();
  const activeContract = useAppSelector(state => state.contracts.euCreationCondition.data);
  const activationLetterDistributionList = useAppSelector(
    SettingsSelectors.emailActivationLetterDistributionList,
  );
  const { activationLetterRecipientMode } = useAppSelector(SettingsSelectors.settingsSettings);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showPauseModal, setShowPauseModal] = useState<boolean>(false);
  const [showRemovePauseModal, setShowRemovePauseModal] = useState<boolean>(false);
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [requestModal, setRequestModal] = useState(initRequestModal);
  const [resendModal, setResendModal] = useState<boolean>(false);
  const [activationModal, setActivationModal] = useState(initActivationModalState);
  const [editStartDateModal, setEditStartDateModal] = useState<boolean>(false);
  const [goToSettingsModal, setGoToSettingsModal] = useState<boolean>(false);
  const [goToSettingsEUModal, setGoToSettingsEUModal] = useState<boolean>(false);

  const [currentEmployeeId, setCurrentEmployeeId] = useState('');
  const open = Boolean(anchorEl);
  const currentLanguage = useLanguage();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentEmployeeId) {
      dispatch(EmployeeManagementActions.getEmployeeStatus({ employeeId: currentEmployeeId }));
    }
  }, [dispatch, currentEmployeeId]);

  const employeeStatusApi = useAppSelector(EmployeeManagementSelectors.getGetEmployeeStatusApi);
  const plannedTransition = employeeStatusApi.data?.plannedTransition;

  const emptyCuList =
    activationLetterRecipientMode.toLowerCase() === 'cu' &&
    activationLetterDistributionList.length === 0;
  const euWithoutEmail = !hasBusinessEmail && activationLetterRecipientMode.toLowerCase() === 'eu';

  const setMenuOptions = (userStatus: EmployeeStatus.PENDING | string) => {
    const optionsArray = [
      {
        value: 'VIEW',
        title: formatMessage({
          id: 'employee.more.expander.view',
          defaultMessage: 'View profile',
        }),
      },
    ];
    const optionEditStartDate = {
      value: 'EDIT DATE',
      title: formatMessage({
        id: 'employee.more.expander.pending.edit_start_date',
        defaultMessage: 'Edit start date',
      }),
    };
    const optionResendActivationLetter = {
      value: 'RESEND',
      title: formatMessage({
        id: 'employee.more.expander.pending.resend',
        defaultMessage: 'Resend activation letter',
      }),
    };
    const optionConfirmRequest = {
      value: 'CONFIRM',
      title: formatMessage({
        id: 'employee.more.expander.applied.confirm',
        defaultMessage: 'Confirm request',
      }),
    };
    const optionDenyRequest = {
      value: 'DENY',
      title: formatMessage({
        id: 'employee.more.expander.applied.deny',
        defaultMessage: 'Deny request',
      }),
    };
    const optionStartPause = {
      value: 'PAUSE',
      title: formatMessage({
        id: 'employee.more.expander.edit_pause',
        defaultMessage: 'Edit planned pause',
      }),
    };
    const optionRemovePause = {
      value: 'REMOVE PAUSE',
      title: formatMessage({
        id: 'employee.more.expander.remove_pause',
        defaultMessage: 'Remove planned pause',
      }),
    };
    const optionDeactivateMembership = {
      value: 'DEACTIVATE',
      title: formatMessage({
        id: 'employee.more.expander.deactivate',
        defaultMessage: 'Pause membership unlimited',
      }),
    };
    const optionReactivateMembership = {
      value: 'REACTIVATE',
      title: formatMessage({
        id: 'employee.more.expander.reactivate',
        defaultMessage: 'Reactivate membership',
      }),
    };
    const optionRemoveMembership = {
      value: 'REMOVE',
      title:
        userStatus === EmployeeStatus.PENDING
          ? formatMessage({
              id: 'employee.more.expander.remove',
              defaultMessage: 'Cancel membership',
            })
          : formatMessage({
              id: 'employee.more.expander.remove',
              defaultMessage: 'Remove user from company',
            }),
    };
    switch (userStatus) {
      case EmployeeStatus.PENDING:
        optionsArray.push(optionEditStartDate);
        optionsArray.push(optionResendActivationLetter);
        if (checkTypeParameter(activeContract, 'deleteEu'))
          optionsArray.push(optionRemoveMembership);
        break;
      case EmployeeStatus.APPLIED:
        optionsArray.push(optionConfirmRequest);
        optionsArray.push(optionDenyRequest);
        break;
      case EmployeeStatus.ACTIVE:
        if (plannedTransition && plannedTransition.status === 'pause') {
          if (!isDirectPay && checkTypeParameter(activeContract, 'pauseContractPossible')) {
            optionsArray.push(optionStartPause);
            optionsArray.push(optionRemovePause);
          }
          optionsArray.push(optionResendActivationLetter);
          if (!isDirectPay && checkTypeParameter(activeContract, 'endContractPossible')) {
            optionsArray.push(optionDeactivateMembership);
          }
        } else {
          if (!isDirectPay && checkTypeParameter(activeContract, 'pauseContractPossible')) {
            optionsArray.push({
              value: 'PAUSE',
              title: formatMessage({
                id: 'employee.more.expander.pause',
                defaultMessage: 'Pause membership',
              }),
            });
          }
          optionsArray.push({
            value: 'RESEND',
            title: formatMessage({
              id: 'employee.more.expander.pending.resend',
              defaultMessage: 'Resend activation letter',
            }),
          });
          if (!isDirectPay && checkTypeParameter(activeContract, 'endContractPossible')) {
            optionsArray.push({
              value: 'DEACTIVATE',
              title: formatMessage({
                id: 'employee.more.expander.deactivate',
                defaultMessage: 'Pause membership unlimited',
              }),
            });
          }
        }
        if (checkTypeParameter(activeContract, 'deleteEu'))
          optionsArray.push(optionRemoveMembership);
        break;
      case EmployeeStatus.PAUSED:
        if (!isDirectPay && checkTypeParameter(activeContract, 'reactivationAllowed')) {
          optionsArray.push(optionReactivateMembership);
        }
        optionsArray.push(optionResendActivationLetter);
        if (!isDirectPay && checkTypeParameter(activeContract, 'endContractPossible')) {
          optionsArray.push(optionDeactivateMembership);
        }
        break;
      case EmployeeStatus.INACTIVE:
        optionsArray.push(optionResendActivationLetter);
        if (!isDirectPay && checkTypeParameter(activeContract, 'reactivationAllowed')) {
          optionsArray.push(optionReactivateMembership);
        }
        if (checkTypeParameter(activeContract, 'deleteEu'))
          optionsArray.push(optionRemoveMembership);
        break;
      default:
        break;
    }
    return optionsArray;
  };

  const employeeMenuOptions = setMenuOptions(userStatus);

  const openModalHandlers = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
    setCurrentEmployeeId(userId.toString());
  };

  const closeModalHandler = (option: { value: string; title: string }): void => {
    switch (option.value) {
      case 'PAUSE':
        setShowPauseModal(true);
        break;
      case 'DEACTIVATE':
        setActivationModal({ reactivateModal: false, deactivateModal: true });
        break;
      case 'CONFIRM':
        setRequestModal({ denyModal: false, confirmModal: true });
        break;
      case 'DENY':
        setRequestModal({ denyModal: true, confirmModal: false });
        break;
      case 'REACTIVATE':
        setActivationModal({ reactivateModal: true, deactivateModal: false });
        break;
      case 'EDIT DATE':
        setEditStartDateModal(true);
        break;
      case 'REMOVE PAUSE':
        setShowRemovePauseModal(true);
        break;
      case 'RESEND': {
        if (emptyCuList) {
          setGoToSettingsModal(true);
        } else if (euWithoutEmail) {
          setGoToSettingsEUModal(true);
        } else {
          setResendModal(true);
        }
        break;
      }
      case 'VIEW':
        navigate(`/${currentLanguage}/employee-management/${userId}/general-info/view`);
        break;
      case 'REMOVE': {
        setShowRemoveModal(true);
        break;
      }
      default:
        break;
    }

    setAnchorEl(null);
  };

  const confirmRemovePause = () => {
    submitRowModal({ transition: EmployeeStatus.PAUSE_REMOVED, startDate: '', endDate: '' });
  };

  const submitRowModal = async ({ transition = '', startDate = '', endDate = '' }) => {
    if (!companyId) return;

    const updateEmployeeStatus = {
      employeeId: userId,
      transition,
      startDate,
      endDate,
    };

    await dispatch(EmployeeManagementActions.changeEmployeeStatus(updateEmployeeStatus));
    setRequestModal(initRequestModal);
    setEditStartDateModal(false);
    setShowRemovePauseModal(false);

    await dispatch(
      EmployeeManagementActions.getEmployees({
        companyId,
      }),
    );
  };

  const resendActivationLetter = async () => {
    dispatch(
      EmployeeManagementActions.resendActivationLetter({
        endUserId: userId,
        language: currentLanguage,
      }),
    );
  };

  const closeRowModal = () => {
    setShowPauseModal(false);
    setRequestModal(initRequestModal);
    setActivationModal(initActivationModalState);
    setEditStartDateModal(false);
    setResendModal(false);
    setShowRemoveModal(false);
    setShowRemovePauseModal(false);
  };

  return (
    <>
      {(goToSettingsModal || goToSettingsEUModal) && (
        <GoToSettings
          isOpen={goToSettingsModal || goToSettingsEUModal}
          isResendLetted={goToSettingsModal}
          isResendToEUWithEmptyEmail={goToSettingsEUModal}
          handleClose={() => {
            setGoToSettingsModal(false);
            setGoToSettingsEUModal(false);
          }}
        />
      )}
      {requestModal.denyModal && (
        <EmployeeRequestModal
          employeeTitle={userTitle}
          employeeName={userFullName}
          employeeId={userId}
          transitionType={EmployeeStatus.DENIED}
          close={closeRowModal}
          onSubmit={submitRowModal}
        />
      )}
      {requestModal.confirmModal && (
        <EmployeeRequestModal
          employeeTitle={userTitle}
          employeeName={userFullName}
          transitionDate={userEntryDate}
          employeeId={userId}
          transitionType={EmployeeStatus.PENDING}
          close={closeRowModal}
          onSubmit={submitRowModal}
        />
      )}
      {editStartDateModal && (
        <PendingEmployeeModal
          employeeTitle={userTitle}
          employeeName={userFullName}
          transitionDate={plannedTransition?.startDate}
          transitionType={EmployeeStatus.ACTIVE}
          resendLetter={resendActivationLetter}
          cantResend={euWithoutEmail || emptyCuList}
          close={closeRowModal}
          onSubmit={submitRowModal}
        />
      )}
      {showPauseModal && (
        <PauseEmployeeModal
          employeeTitle={userTitle}
          employeeName={userFullName}
          transitionStartDate={plannedTransition?.startDate}
          transitionEndDate={plannedTransition?.endDate}
          transitionType={EmployeeStatus.PAUSED}
          close={closeRowModal}
          onSubmit={submitRowModal}
        />
      )}
      {showRemovePauseModal && (
        <AlertModal
          isOpen={showRemovePauseModal}
          handleClose={closeRowModal}
          handleConfirm={confirmRemovePause}
          title={formatMessage({
            id: 'alert.modal.remove_planned_pause.title',
            defaultMessage: 'Remove planned pause',
          })}
          hideDescription
          showConfirmButton
          confirmButtonColor='error'
          mainContent={
            <>
              <Typography variant='h3'>
                {userTitle ? ` ${userTitle} ` : ''}
                {userFullName}
              </Typography>
              <Spacer size='xl' />
              {formatMessage({
                id: 'alert.modal.remove_planned_pause.description',
                defaultMessage: 'Do you really want to delete the planned pause?',
              })}
            </>
          }
        />
      )}
      {activationModal.deactivateModal && (
        <DeactivationEmployeeModal
          employeeTitle={userTitle}
          employeeName={userFullName}
          transitionType={EmployeeStatus.INACTIVE}
          close={closeRowModal}
          onSubmit={submitRowModal}
        />
      )}
      {activationModal.reactivateModal && (
        <DeactivationEmployeeModal
          employeeName={userFullName}
          transitionType={EmployeeStatus.ACTIVE}
          close={closeRowModal}
          onSubmit={submitRowModal}
        />
      )}
      {showRemoveModal && (
        <RemoveEmployeeModal
          employeeTitle={userTitle}
          employeeName={userFullName}
          employeeStatus={userStatus}
          transitionStartDate={plannedTransition?.startDate}
          close={closeRowModal}
          onSubmit={submitRowModal}
        />
      )}
      {resendModal && <ResendLetterModal close={closeRowModal} onSubmit={resendActivationLetter} />}

      <Box>
        <IconButton
          aria-label='more'
          id='more-button'
          onClick={openModalHandlers}
          data-test-id='tableItemOptionBtn'>
          <MoreVertIcon />
        </IconButton>

        <MenuWrapper id='more-menu' anchorEl={anchorEl} open={open} onClose={closeModalHandler}>
          {employeeMenuOptions.map(option => (
            <MenuItem
              className={option.value === 'REMOVE' ? 'MuiMenuItem-attention' : ''}
              key={option.value}
              onClick={() => closeModalHandler(option)}
              data-test-id={`tableItemOptionItem_${option.value}`}>
              {option.title}
            </MenuItem>
          ))}
        </MenuWrapper>
      </Box>
    </>
  );
};

export default EmployeeMoreExpander;
