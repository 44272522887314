import * as yup from 'yup';

export const EndEmployeeSchema = yup
  .object({
    endMemberShipDate: yup
      .date()
      .typeError('schema.add_employee.error.dateOfBirth.type')
      .nullable()
      .required(),
  })
  .required();

export default EndEmployeeSchema;
