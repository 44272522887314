import S3 from 'aws-sdk/clients/s3';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { default as theme } from '@theme/theme';

import { viewFile, downloadFile, pdfReg } from 'utils';
import { InvoicesListProps, S3Credentials, InvoicesData, FetchingStatus } from 'types';

import { InvoicesTableWrapper, StatusChip } from './InvoicesTable.styles';
import { useSelector } from 'react-redux';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { S3CredentialsSelectors } from 'features/shared/s3Credentials/store/s3Credentials.selector';
import { useAppDispatch } from 'store';
import { S3CredentialsActions } from 'features/shared/s3Credentials/store';
import { getS3Credential } from 'utils/getS3Credential';
import { useIntl } from 'react-intl';
import TableGrid, { GRID_ACTIONS_COLUMN_NAME } from 'components/TableGrid';
import { getUniqueSingleSelectList } from 'components/TableGrid/helpers/getUniqueSingleSelectList';
import { ValueOptions } from '@mui/x-data-grid-pro';
import { getDateFilterOperators } from 'components/TableGrid/CustomFilters/Date';
import { FormatDate } from 'components/FormatDate';
import { CCPDefaultButton } from '@components/index';

export interface InvoicesTableProps {
  invoicesList: InvoicesListProps | null;
  data: InvoicesData[];
  isLoading?: boolean;
}

const InvoicesTable: React.FC<InvoicesTableProps> = ({ invoicesList, data, isLoading }) => {
  const intl = useIntl();
  const [images, setImages] = useState<{ [key: number]: any }>({});
  const { activeCompany } = useSelector(CompanySelectors.getCompanyState);
  const s3Data = useSelector(S3CredentialsSelectors.getS3Credentials);
  const dispatch = useAppDispatch();

  const convertImgS3 = async (
    key: string,
    s3Credential: { AccessKeyId: string; SessionToken: string; SecretAccessKey: string },
  ) => {
    const s3 = new S3({
      accessKeyId: s3Credential.AccessKeyId,
      secretAccessKey: s3Credential.SecretAccessKey,
      region: 'eu-central-1',
      sessionToken: s3Credential.SessionToken,
    });
    const params = {
      Bucket: invoicesList!.bucketName,
      Key: key,
    };
    const file = await s3.getObject(params).promise();

    return file;
  };

  const handleAction = async ({
    index,
    type,
    id,
  }: {
    index: number;
    type: 'download' | 'view';
    id: number;
  }) => {
    const imagesList = { ...images };
    let actionFile = imagesList[index];
    const pdfList = invoicesList!?.data?.filter(invoice => pdfReg.test(invoice));
    if (!pdfList.length || !pdfList[index]) {
      return;
    }

    let s3Credential = s3Data.data as S3Credentials;

    if (!actionFile && s3Data.expiration && activeCompany?.companyId) {
      const today = new Date().getTime() / 1000;
      if (s3Data.expiration < today - 60) {
        s3Credential = await dispatch(
          S3CredentialsActions.getS3CredentialsCompany({
            companyId: activeCompany.companyId,
          }),
        ).unwrap();
      }
      const s3Invoice = getS3Credential({ data: s3Credential, type: 'invoice' });
      if (!s3Invoice) {
        return;
      }
      actionFile = await convertImgS3(pdfList[index], { ...s3Invoice });
      imagesList[index] = actionFile;
      setImages(imagesList);
    }
    if (type === 'view') {
      viewFile(actionFile);
      return;
    }
    downloadFile(actionFile, id);
  };

  const renderStatusChip = (status: string) => {
    switch (status) {
      case '1':
        return (
          <StatusChip
            size='small'
            label={intl.formatMessage({ id: 'invoices.status.invoice', defaultMessage: 'Invoice' })}
            background={theme.palette.primary.darker || theme.palette.primary.dark}
          />
        );
      case '2':
        return (
          <StatusChip
            size='small'
            label={intl.formatMessage({
              id: 'invoices.status.correction',
              defaultMessage: 'Correction',
            })}
            background={theme.palette.warning.main}
          />
        );
      case '5':
        return (
          <StatusChip
            size='small'
            label={intl.formatMessage({
              id: 'invoices.status.instruction',
              defaultMessage: 'Training Instruction',
            })}
            background={theme.palette.primary.dark}
          />
        );
      case '6':
        return (
          <StatusChip
            size='small'
            label={intl.formatMessage({ id: 'invoices.status.fee', defaultMessage: 'Service Fee' })}
            background={theme.palette.primary.main}
          />
        );
      default:
        return null;
    }
  };

  const getFilterOptions = useMemo(() => {
    const defaultValues = [
      {
        value: '1',
        label: intl.formatMessage({
          id: 'invoices.status.invoice',
          defaultMessage: 'Invoice',
        }),
      },
      {
        value: '2',
        label: intl.formatMessage({
          id: 'invoices.status.correction',
          defaultMessage: 'Correction',
        }),
      },
      {
        value: '5',
        label: intl.formatMessage({
          id: 'invoices.status.instruction',
          defaultMessage: 'Training Instruction',
        }),
      },
      {
        value: '6',
        label: intl.formatMessage({
          id: 'invoices.status.fee',
          defaultMessage: 'Service Fee',
        }),
      },
    ];
    const singleList = getUniqueSingleSelectList(data.map(item => item.invoiceType));
    return defaultValues.filter(item => singleList.includes(item.value));
  }, [data, intl]);

  const invoicesTableColumns: GridColDef[] = useMemo(
    () => [
      {
        minWidth: 140,
        field: 'invoiceId',
        searchable: true,
        headerName: intl.formatMessage({
          id: 'view.invoice.table.invoice_id',
          defaultMessage: 'Invoice Id',
        }),
        renderCell: (params: GridRenderCellParams) => (
          <Typography className='table__id' variant='body1'>
            {params.value}
          </Typography>
        ),
      },
      {
        minWidth: 160,
        field: 'period',
        headerName: intl.formatMessage({
          id: 'view.invoice.table.period',
          defaultMessage: 'Period',
        }),
        renderCell: (params: GridRenderCellParams) => (
          <Typography className='table__period' variant='body1'>
            {moment(params.value).format('MM.YYYY')}
          </Typography>
        ),
        filterOperators: getDateFilterOperators(false),
      },
      {
        minWidth: 240,
        field: 'invoiceType',
        headerName: intl.formatMessage({
          id: 'view.invoice.table.invoice_type',
          defaultMessage: 'Invoice Type',
        }),
        type: 'singleSelect',
        valueOptions: getFilterOptions as ValueOptions[],
        renderCell: (params: GridRenderCellParams) => renderStatusChip(params.value),
      },
      {
        minWidth: 200,
        field: 'dateIssue',
        headerName: intl.formatMessage({
          id: 'view.invoice.table.date_of_issue',
          defaultMessage: 'Date of issue',
        }),
        filterOperators: getDateFilterOperators(false),
        renderCell: (params: GridRenderCellParams) => (
          <Typography variant='body1'>{FormatDate(params.value)}</Typography>
        ),
      },
      {
        field: GRID_ACTIONS_COLUMN_NAME,
        renderHeader: () => <div></div>,
        width: 320,
        sortable: false,
        disableReorder: true,
        disableColumnMenu: true,
        resizable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <Stack flexDirection='row'>
            <CCPDefaultButton
              variant='text'
              onClick={() =>
                handleAction({ index: params.row.index, type: 'view', id: params.row.invoiceId })
              }
              sx={{ padding: '7px' }}
              startIcon={<VisibilityIcon />}>
              {intl.formatMessage({ id: 'view.invoice.table.view', defaultMessage: 'View' })}
            </CCPDefaultButton>
            <CCPDefaultButton
              onClick={() =>
                handleAction({
                  index: params.row.index,
                  type: 'download',
                  id: params.row.invoiceId,
                })
              }
              sx={{ padding: '7px' }}
              startIcon={<DownloadIcon />}>
              {intl.formatMessage({
                id: 'view.invoice.table.download',
                defaultMessage: 'Download',
              })}
            </CCPDefaultButton>
          </Stack>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <InvoicesTableWrapper>
      <TableGrid
        tableName='invoices'
        columns={invoicesTableColumns}
        rowsData={data}
        isLoading={isLoading || s3Data.fetchingStatus === FetchingStatus.PENDING}
        searchPlaceholder={intl.formatMessage({
          id: 'invoices_management.search.placeholder',
          defaultMessage: 'Search by Invoice ID',
        })}
      />
    </InvoicesTableWrapper>
  );
};

export default InvoicesTable;
