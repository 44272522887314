import { Box, Stack, Typography } from '@mui/material';
import { CCPDefaultButton, Spacer } from 'components';
import React, { useState } from 'react';
import { StatusChip } from 'components/StatusChip';
import { EmployeePendingListItemStyled } from './EmployeePendingListItem.styled';
import { Employee, EmployeeStatus, TranslationsKeys } from 'types';
import { useIntl } from 'react-intl';
import { useGendersList } from 'utils/genders';
import { useLanguage } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { FormatDate } from 'components/FormatDate';
import { EmployeeManagementActions } from 'features/employeeManagement/store';
import EmployeeRequestModal, {
  initRequestModal,
} from '../EmployeeOverview/Modals/EmployeeRequestModal';
import { GlobalCustomFields } from '../EmployeeGeneralInfo/GlobalCustomFileds';
import { default as theme } from '@theme/theme';
import getCustomFieldEnumLabel from '@utils/getCustomFieldEnumLabel';

const EmployeePendingListItem: React.FC<{
  employee: Employee;
}> = ({ employee }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const genders = useGendersList();
  const language = useLanguage();
  const {
    getCompany: { data: companyData },
  } = useAppSelector(CompanySelectors.getApi);
  const companyLanguage = companyData
    ? (companyData.communicationLanguage.toLowerCase() as TranslationsKeys)
    : 'en';

  const [requestModal, setRequestModal] = useState(initRequestModal);

  const closeRequestModal = () => {
    setRequestModal(initRequestModal);
  };

  const submitRequestModal = async ({
    transition,
    startDate,
  }: {
    transition: EmployeeStatus | string;
    startDate?: string | undefined;
  }) => {
    let updDate;

    const updateEmployeeStatus = {
      employeeId: employee.id,
      transition,
      startDate: updDate ? updDate : startDate,
    };

    await dispatch(EmployeeManagementActions.changeEmployeeStatus(updateEmployeeStatus));
    closeRequestModal();
    if (companyData)
      dispatch(EmployeeManagementActions.getEmployees({ companyId: companyData.id }));
  };

  const filteredGlobalCustomFields = companyData?.globalCustomFields
    ? companyData.globalCustomFields.filter(
        field => field.isAddressRelevant === 'no' || field.key === 'hansefit_country',
      )
    : [];

  return (
    <EmployeePendingListItemStyled>
      {(requestModal.confirmModal || requestModal.denyModal) && (
        <EmployeeRequestModal
          employeeTitle={employee.title}
          employeeName={`${employee.firstName || ''} ${employee.lastName || ''}`}
          employeeId={employee.id}
          transitionType={
            requestModal.confirmModal ? EmployeeStatus.PENDING : EmployeeStatus.DENIED
          }
          close={closeRequestModal}
          onSubmit={submitRequestModal}
          transitionDate={requestModal.confirmModal ? employee.entryDate : undefined}
        />
      )}
      <Box display='flex' justifyContent='space-between'>
        <Typography
          variant='h3'
          className='notranslate'>{`${employee.firstName} ${employee.lastName}`}</Typography>
        <Box display='flex' flexDirection='column' alignItems='flex-end'>
          <StatusChip
            background={theme.palette.primary.main}
            label={intl.formatMessage({
              id: 'employee.pending.request',
              defaultMessage: 'Request',
            })}
          />
          <Typography variant='subtitle2'>
            {`${intl.formatMessage({
              id: 'employee.pending.request.start_date',
              defaultMessage: 'Start date',
            })}: `}
            {FormatDate(employee.entryDate)}
          </Typography>
        </Box>
      </Box>
      <Stack direction='row' spacing={7}>
        <Box textAlign='left'>
          <Typography
            variant='subtitle2'
            textTransform='uppercase'
            fontFamily='Roboto-Medium'
            textAlign='left'>
            {intl.formatMessage({
              id: 'employee.pending.request.personal_info',
              defaultMessage: 'Personal info',
            })}
          </Typography>
          <Spacer height={16} />
          <Stack direction='row' spacing={3}>
            <Typography variant='subtitle1' textAlign='left'>
              {intl.formatMessage({
                id: 'employee.pending.request.personal_info.gender',
                defaultMessage: 'Gender',
              })}
              : <br />
              <b className='notranslate'>
                {genders.filter(item => item.value === employee.gender)[0].title}
              </b>
            </Typography>
            <Typography variant='subtitle1' textAlign='left'>
              {intl.formatMessage({
                id: 'employee.pending.request.personal_info.birthday',
                defaultMessage: 'Birthday date',
              })}
              : <br />
              <b>{FormatDate(employee.dateOfBirth)}</b>
            </Typography>
          </Stack>
          <Spacer height={16} />
          <GlobalCustomFields data={employee.globalCustomFields} isAddressFields />
        </Box>
        <Box>
          <Typography
            variant='subtitle2'
            textTransform='uppercase'
            fontFamily='Roboto-Medium'
            textAlign='left'>
            {intl.formatMessage({
              id: 'employee.pending.request.contact_info',
              defaultMessage: 'Contact info',
            })}
          </Typography>
          <Spacer height={16} />
          <Stack direction='row' spacing={3}>
            <Typography variant='subtitle1' textAlign='left'>
              {intl.formatMessage({
                id: 'employee.pending.request.contact_info.email',
                defaultMessage: 'Corporate email',
              })}
              : <br />
              <b>{employee.businessEmail}</b>
            </Typography>
            <Typography variant='subtitle1' textAlign='left'>
              {intl.formatMessage({
                id: 'employee.pending.request.contact_info.phone',
                defaultMessage: 'Phone number',
              })}
              : <br />
              <b>{employee.phoneNumber}</b>
            </Typography>
          </Stack>
        </Box>
      </Stack>
      <Spacer height={36} />
      {companyData?.customFields && companyData?.customFields.length > 0 && (
        <Box>
          <Typography
            variant='subtitle2'
            textTransform='uppercase'
            fontFamily='Roboto-Medium'
            textAlign='left'>
            {intl.formatMessage({
              id: 'employee.pending.request.custom_fields',
              defaultMessage: 'Other',
            })}
          </Typography>
          <Spacer height={16} />
          <Stack direction='row' spacing={3}>
            {companyData.customFields.map(customField => (
              <Typography key={customField.key} variant='subtitle1' textAlign='left'>
                {customField.name[language] ||
                  customField.name['en'] ||
                  customField.name[companyLanguage]}
                : <br />
                <b>
                  {getCustomFieldEnumLabel(
                    employee.customFields[customField.key],
                    customField,
                    language,
                    companyLanguage,
                  )}
                </b>
              </Typography>
            ))}
            {filteredGlobalCustomFields.length > 0 &&
              filteredGlobalCustomFields.map(customField => (
                <Typography key={customField.key} variant='subtitle1' textAlign='left'>
                  {customField.name[language] ||
                    customField.name['en'] ||
                    customField.name[companyLanguage]}
                  : <br />
                  <b>{employee.globalCustomFields[customField.key]}</b>
                </Typography>
              ))}
          </Stack>
          <Spacer height={36} />
        </Box>
      )}
      <Stack direction='row' justifyContent='flex-end' spacing={2}>
        <CCPDefaultButton
          variant='outlined'
          color='error'
          onClick={() => setRequestModal({ denyModal: true, confirmModal: false })}>
          {intl.formatMessage({
            id: 'employee.pending.request.decline',
            defaultMessage: 'Decline',
          })}
        </CCPDefaultButton>
        <CCPDefaultButton
          variant='contained'
          onClick={() => setRequestModal({ denyModal: false, confirmModal: true })}>
          {intl.formatMessage({
            id: 'employee.pending.request.approve',
            defaultMessage: 'Approve request',
          })}
        </CCPDefaultButton>
      </Stack>
    </EmployeePendingListItemStyled>
  );
};

export default EmployeePendingListItem;
