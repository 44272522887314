import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '../../initialState';
import { AuthenticationState } from '../../interfaces';

export const setActiveStep: CaseReducer<AuthenticationState, PayloadAction<number>> = (
  state,
  { payload },
) => {
  state.forgotPassword.activeStep = payload;
};

export const setActiveEmail: CaseReducer<AuthenticationState, PayloadAction<string>> = (
  state,
  { payload },
) => {
  state.forgotPassword.email = payload;
};

export const resetAuthState: CaseReducer<AuthenticationState> = state => {
  state.error = initialState.error;
  state.fetchingStatus = initialState.fetchingStatus;
  state.forgotPassword = initialState.forgotPassword;
};

export const openSideBarMenu: CaseReducer<AuthenticationState> = state => {
  state.isOpenSidebar = !state.isOpenSidebar;
};
