import { Auth } from 'aws-amplify';
import { CompanyRoleStatusState } from 'features/authentication/store/interfaces';
import logoutUser from './logoutUser';

export const getCompanyTokenData = async (): Promise<CompanyRoleStatusState[]> => {
  let companyRoleStatus: CompanyRoleStatusState[] = [];
  try {
    const currentSession = await Auth.currentSession();
    const idTokenInfo = currentSession.getIdToken().payload;
    companyRoleStatus = JSON.parse(idTokenInfo?.company_role_status);
  } catch (err) {
    if (err instanceof Error && err.name === 'NotAuthorizedException') {
      logoutUser();
    }
  }
  return Promise.resolve(
    companyRoleStatus.map(item => ({ companyId: Number(item.companyId), role: item.role })),
  );
};
