import { Box } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { StatsActions } from '@features/stats/store';
import { useAppDispatch } from '@store/store';
import { CompanySelectors } from '@features/company/store/company.selectors';
import { getStatisticCount } from '@utils/getStatisticCount';
import { getStatsMonth } from '@utils/getStatsMonth';
import { RootState } from '@store/interfaces';
import { FetchingStatus, FiltersProps } from '@localTypes/index';
import StatsInfo from '@features/stats/components/StatsInfo';
import StatsTable from '@features/stats/components/StatsTable';
import CustomStatsWrapper from '../CustomStatsWrapper.styles';

const checkInsColumns = [
  {
    intlId: 'view.stats.table.service',
    columnName: 'provider',
    columnTitle: 'Service provider',
  },
  {
    intlId: 'dashboard__active_users',
    columnName: 'users',
    columnTitle: 'active users',
  },
  {
    intlId: 'view.stats.checkins',
    columnName: 'checkins',
    columnTitle: 'Check-Ins',
  },
];

const CheckInsPage = () => {
  const dispatch = useAppDispatch();
  const { checkIns } = useSelector((state: RootState) => state.stats);
  const { activeCompany } = useSelector(CompanySelectors.getCompanyState);

  const checkInsCount = useMemo(
    () => getStatisticCount({ data: checkIns.data, type: 'checkIns' }),
    [checkIns?.data],
  );

  const getCheckInsList = useCallback(() => {
    const { startMonth, endMonth, startYear, endYear } = checkIns.filters;
    const { startMonthString, endMonthString } = getStatsMonth({ startMonth, endMonth });

    if (!activeCompany?.companyId) return;

    dispatch(
      StatsActions.getCheckIns({
        companyId: activeCompany?.companyId,
        startMonth: startMonthString + `.${startYear}`,
        endMonth: endMonthString + `.${endYear}`,
      }),
    );
  }, [activeCompany?.companyId, checkIns.filters, dispatch]);

  const updateFiltersHandler = (filters?: FiltersProps) => {
    if (filters) dispatch(StatsActions.updateCheckInsFilters(filters));
  };

  useEffect(() => {
    getCheckInsList();
  }, [getCheckInsList]);

  const hasError = useMemo(
    () => checkIns.fetchingStatus === FetchingStatus.REJECTED,
    [checkIns.fetchingStatus],
  );
  const isLoading = useMemo(
    () => checkIns.fetchingStatus === FetchingStatus.PENDING,
    [checkIns.fetchingStatus],
  );
  const checkInsList = useMemo(
    () => (checkIns.data ? checkIns.data.checkInsList : []),
    [checkIns.data],
  );

  return (
    <CustomStatsWrapper>
      <Box className='container'>
        <StatsInfo
          data={checkInsList}
          count={checkInsCount}
          filters={checkIns.filters}
          fileName='check-ins'
          className='blue'
          isLoading={isLoading}
          updateFilters={updateFiltersHandler}
          hasError={hasError}
        />
        <StatsTable
          isLoading={isLoading}
          hasError={hasError}
          tableColumns={checkInsColumns}
          statsList={checkInsList}
        />
      </Box>
    </CustomStatsWrapper>
  );
};

export default CheckInsPage;
