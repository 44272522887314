import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosWithConfig } from 'api';
import { AxiosError } from 'axios';
import { showToast } from 'utils';
import {
  FetchingStatus,
  RootState,
  SettingsResponse,
  UpdateActivationCodeSettingsRequestParams,
} from 'types';
import { GlobalStateActions } from '@store/globalState';

export const updateSettings = createAsyncThunk<
  SettingsResponse,
  UpdateActivationCodeSettingsRequestParams
>(
  'settings/updateSettings',
  async ({ companyId, activationLetterRecipientMode }, { rejectWithValue, dispatch, getState }) => {
    const state = getState() as RootState;
    if (
      state.settings.settings.fetchingStatus !== FetchingStatus.PENDING &&
      state.settings.settings.activationLetterRecipientMode === activationLetterRecipientMode
    ) {
      setTimeout(() => {
        dispatch(GlobalStateActions.setActivationLettersPopupState(false));
      }, 10);
      return { activationLetterRecipientMode };
    }
    const endpoint = process.env.REACT_APP_PIMCORE_ENDPOINT + `api/companies/${companyId}/settings`;
    const axios = await axiosWithConfig();
    try {
      const response = await axios.patch(
        endpoint,
        {
          activationLetterRecipientMode,
          invoiceRecipientMode: state.settings.settings.invoiceRecipientMode,
        },
        {
          headers: {
            'content-type': 'application/merge-patch+json',
          },
        },
      );
      await dispatch(GlobalStateActions.setActivationLettersPopupState(false));
      return response.data;
    } catch (e) {
      const error = e as AxiosError;
      showToast('error', {
        id: 'toast.settings.update.error',
        defaultMessage: error.message,
      });
      return rejectWithValue(e);
    }
  },
);
