import * as yup from 'yup';

const AddPersonalInfoSchema = yup
  .object({
    name: yup.string().required('schema.add_personal_info.error.name.required'),
    surname: yup.string().required('schema.add_personal_info.error.surname.required'),
    position: yup.string().required('schema.add_personal_info.error.position.required'),
    email: yup
      .string()
      .trim()
      .email('schema.add_personal_info.error.email.email')
      .required('schema.add_personal_info.error.email.required'),
  })
  .required();

export default AddPersonalInfoSchema;
