import { CompanyRole, INavigationList } from 'types';

import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import FolderSpecialOutlinedIcon from '@mui/icons-material/FolderSpecialOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';

import generateKey from './generateKey';

export const navigationList: INavigationList[] = [
  {
    id: generateKey('Company profile'),
    defaultText: 'Company profile',
    text: 'navigation.dashboard',
    Icon: DashboardOutlinedIcon,
    link: 'dashboard',
    role: [],
  },
  {
    id: generateKey('Stats'),
    defaultText: 'Stats',
    text: 'navigation.stats',
    Icon: QueryStatsOutlinedIcon,
    link: 'stats',
    role: [],
    items: [
      {
        id: generateKey('Item 1'),
        defaultName: 'Check-ins',
        name: 'navigation.stats.checkins',
        link: 'stats/check-ins',
        route: 'check-ins',
      },
      {
        id: generateKey('Item 2'),
        defaultName: 'Active members',
        name: 'navigation.stats.members',
        link: 'stats/active-members',
        route: 'active-members',
      },
      {
        id: generateKey('Item 3'),
        defaultName: 'Coupon usage',
        name: 'navigation.stats.coupon',
        link: 'stats/coupon-usage',
        route: 'coupon-usage',
      },
    ],
  },
  {
    id: generateKey('Employee management'),
    defaultText: 'Employee management',
    text: 'navigation.employee',
    Icon: AssignmentIndOutlinedIcon,
    link: 'employee-management',
    role: [CompanyRole.Usermgmt],
  },
  {
    id: generateKey('Invoices'),
    defaultText: 'Invoices',
    text: 'navigation.invoices',
    Icon: ReceiptLongOutlinedIcon,
    link: 'invoices',
    role: [CompanyRole.Invoice],
  },
  {
    id: generateKey('Administration'),
    defaultText: 'Administration',
    text: 'navigation.administration',
    Icon: BusinessCenterOutlinedIcon,
    link: 'user-management',
    role: [CompanyRole.Admin],
  },
  {
    id: generateKey('Contract details'),
    defaultText: 'Contract details',
    text: 'navigation.contract',
    Icon: ContentPasteOutlinedIcon,
    link: 'contract',
    role: [],
  },
  {
    id: generateKey('Settings'),
    defaultText: 'Settings',
    text: 'navigation.settings',
    Icon: SettingsOutlinedIcon,
    link: 'settings/0',
    role: [],
  },
  {
    id: generateKey('Marketing materials'),
    defaultText: 'Marketing materials',
    text: 'navigation.marketing',
    Icon: FolderSpecialOutlinedIcon,
    link: 'marketing-materials',
    role: [],
  },
  {
    id: generateKey('Service providers'),
    defaultText: 'Service providers',
    text: 'navigation.service',
    Icon: LocationOnOutlinedIcon,
    link: 'service-provides',
    // link: 'https://hansefit.de/studio-finden/',
    role: [],
  },
  {
    id: generateKey('Support'),
    defaultText: 'Support',
    text: 'navigation.support',
    Icon: SupportOutlinedIcon,
    link: 'support',
    role: [],
  },
];
