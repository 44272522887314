import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from '../../initialState';
import { EmployeeManagementState, BULK_IMPORT_STEPS } from 'types';

export const resetGetEmployeesStatus: CaseReducer<EmployeeManagementState> = state => {
  state.api.getEmployees.fetchingStatus = initialState.api.getEmployees.fetchingStatus;
};

export const resetAddEmployeeApi: CaseReducer<EmployeeManagementState> = state => {
  state.api.addEmployee = initialState.api.addEmployee;
};

export const resetChangeEmployeeApi: CaseReducer<EmployeeManagementState> = state => {
  state.api.changeEmployee = initialState.api.changeEmployee;
};

export const resetApi: CaseReducer<EmployeeManagementState> = state => {
  state.api = initialState.api;
};

export const resetBulkImportCsvFile: CaseReducer<EmployeeManagementState> = state => {
  state.api.bulkImportCsvFile = initialState.api.bulkImportCsvFile;
  state.api.getBulkUploadFile = initialState.api.getBulkUploadFile;
};

export const setCurrentStep: CaseReducer<
  EmployeeManagementState,
  PayloadAction<BULK_IMPORT_STEPS>
> = (state, { payload }) => {
  state.currentStep = payload;
};

export const setAddEmployeeModalOpen: CaseReducer<
  EmployeeManagementState,
  PayloadAction<{ isOpen: boolean }>
> = (state, action) => {
  const { isOpen } = action.payload;
  state.addEmployeeModalOpen = isOpen;
};
