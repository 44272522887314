import { CompanyState } from './interfaces';
import { FetchingStatus } from '../../../api/interfaces';

export const initialState: CompanyState = {
  api: {
    getCompany: {
      data: null,
      fetchingStatus: FetchingStatus.IDLE,
      error: null,
    },
  },
  companiesNames: {
    data: [],
    fetchingStatus: FetchingStatus.IDLE,
    error: null,
  },
  countriesList: { fetchingStatus: FetchingStatus.IDLE, data: null, error: null },
  activeCompany: null,
};
