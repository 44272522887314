import { NotificationManager } from 'components';

import RoutesWrapper from './RoutesWrapper';

const Router = () => (
  <>
    <NotificationManager />
    <RoutesWrapper />
  </>
);

export default Router;
