import { ActionReducerMapBuilder, SerializedError } from '@reduxjs/toolkit';
import { changeUserEmail } from './changeUserEmail';
import { FetchingStatus } from 'types';

import { UserManagementState } from '../../../interfaces';

export const changeUserEmailExtraReducer = (
  builder: ActionReducerMapBuilder<UserManagementState>,
) => {
  builder.addCase(changeUserEmail.pending, (state, action) => {
    state.changeUserEmail.fetchingStatus = FetchingStatus.PENDING;
    state.changeUserEmail.error = null;
  });
  builder.addCase(changeUserEmail.rejected, (state, action) => {
    state.changeUserEmail.fetchingStatus = FetchingStatus.REJECTED;
    state.changeUserEmail.error = action.payload as SerializedError;
  });
  builder.addCase(changeUserEmail.fulfilled, (state, action) => {
    state.changeUserEmail.fetchingStatus = FetchingStatus.FULFILLED;
    state.changeUserEmail.error = null;
  });
};
