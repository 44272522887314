import { createSlice } from '@reduxjs/toolkit';
import {
  setStep,
  updateDefaultPasswordStepData,
  updateSetNewPasswordStepData,
} from './actions/sync/initialPassword.actions';
import { initialState } from './initialState';
import { changeTemporaryPasswordExtraReducer } from './actions/async/changeTemporaryPassword/changeTemporaryPassword.reducer';

const initialPasswordSlice = createSlice({
  name: 'initialPassword',
  initialState,
  reducers: {
    updateDefaultPasswordStepData,
    setStep,
    updateSetNewPasswordStepData,
  },
  extraReducers: builder => {
    changeTemporaryPasswordExtraReducer(builder);
  },
});

export const { name, actions } = initialPasswordSlice;
export const initialPasswordReducer = initialPasswordSlice.reducer;
