import { useSelector } from 'react-redux';
import { RootState } from 'types';
import { useAppDispatch } from 'store';
import { AuthenticationActions } from 'features/authentication/store';
import { Drawer } from './LeftSideBar.styles';
import AuthorizedSideBar from './AuthorizedSideBar';
import UnAuthorizedSideBar from './UnAuthorizedSideBar';

const LeftSideBar = () => {
  const dispatch = useAppDispatch();
  const { authenticated, isOpenSidebar } = useSelector((state: RootState) => state.authentication);

  const openModalToggler = () => {
    dispatch(AuthenticationActions.openSideBarMenu());
  };

  return (
    <Drawer open={!authenticated || isOpenSidebar} isAuthorized={authenticated} variant='permanent'>
      {!authenticated ? (
        <UnAuthorizedSideBar />
      ) : (
        <AuthorizedSideBar open={authenticated && isOpenSidebar} openModal={openModalToggler} />
      )}
    </Drawer>
  );
};

export default LeftSideBar;
