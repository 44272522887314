import { Auth } from 'aws-amplify';
import { AxiosError } from 'axios';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { CompanyActions } from 'features/company/store';
import { UserManagementBaseResponse } from 'types';
import * as Sentry from '@sentry/react';

const signOut = createAsyncThunk<CognitoUser>(
  'authentication/signOut',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await Auth.signOut();
      dispatch(CompanyActions.resetCompaniesData(null));
      localStorage.clear();
      Sentry.setUser(null);

      return response;
    } catch (e) {
      const error: AxiosError<UserManagementBaseResponse> =
        e as AxiosError<UserManagementBaseResponse>;

      return rejectWithValue(error.message);
    }
  },
);

export default signOut;
