import { useIntl } from 'react-intl';
import { CCPTextField, Spacer } from '@components/index';
import MainBlock from './RegistrationSettings.styles';
import CustomizationFooter from '../CustomizationFooter';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useEffect, useMemo, useState } from 'react';
import CCPTheme from '@theme/theme';
import { useAppDispatch, useAppSelector } from '@store/index';
import { FetchingStatus, ICustomizeFormData } from '@localTypes/index';
import { FormProvider, useForm } from 'react-hook-form';
import CustomizeSignupFormSchema from '@shared/schemas/CustomizeSignupFormSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { SettingsActions } from '@features/settings/store';
import Loader from '@components/Loader';

const WELCOME_TEXT_LENGTH = 70;
const RegistrationSettings: React.FC<{}> = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const signupPage = useAppSelector(state => state.settings.selfRegistration);
  const fetchingStatus = useAppSelector(
    state => state.settings.updateRegistrationForm.fetchingStatus,
  );
  const [expanded, setExpanded] = useState<boolean>(false);

  const defaultValues = useMemo(
    () =>
      signupPage
        ? {
            welcomeText: signupPage.welcomeText,
            emailAddress: signupPage.signupPageEmail,
            phoneNumber: signupPage.signupPagePhone,
          }
        : {
            welcomeText: '',
            emailAddress: '',
            phoneNumber: '',
          },
    [signupPage],
  );
  const methods = useForm<ICustomizeFormData>({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(CustomizeSignupFormSchema),
  });
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { isValid },
  } = methods;

  useEffect(() => {
    if (signupPage) reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    signupPage.welcomeText,
    signupPage.signupPageEmail,
    signupPage.signupPagePhone,
    reset,
    defaultValues,
  ]);

  const onSubmit = async (data: ICustomizeFormData) => {
    dispatch(SettingsActions.updateRegistrationForm(data));
  };

  const resetChanges = () => {
    reset(defaultValues);
  };

  const changeAccordion = () => setExpanded(!expanded);

  const welcome_text_left_characters = useMemo(() => {
    const currentVal = getValues('welcomeText');
    if (currentVal) {
      return WELCOME_TEXT_LENGTH - currentVal.length;
    } else {
      return WELCOME_TEXT_LENGTH;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues, watch('welcomeText')]);

  return (
    <MainBlock id='registration-form-sp-settings'>
      <Loader
        show={fetchingStatus === FetchingStatus.PENDING}
        inCard
        portalId='registration-form-sp-settings'
      />
      <Accordion className='accordion' expanded={expanded} onChange={changeAccordion}>
        <AccordionSummary className='accordion-header'>
          <Box>
            <Typography variant='h4'>
              {formatMessage({
                id: 'settings.self_registration.customize.registration_form.title',
                defaultMessage: 'Registration form',
              })}
            </Typography>
            <Typography variant='subtitle2'>
              {formatMessage({
                id: 'settings.self_registration.customize.registration_form.subtitle',
                defaultMessage:
                  'Set which fields your employees have to fill in and which information of your company is displayed on the page for queries.',
              })}
            </Typography>
          </Box>
          <KeyboardArrowDownIcon
            className='expand-icon'
            htmlColor={CCPTheme.palette.primary.main}
          />
        </AccordionSummary>
        <AccordionDetails>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Spacer size='3xl' />
              <Typography variant='h5'>
                {formatMessage({
                  id: 'landing.settings.welcome_text.title',
                  defaultMessage: 'Customise welcome text',
                })}
              </Typography>
              <Spacer size='md' />
              <Typography variant='subtitle2'>
                {formatMessage(
                  {
                    id: 'settings.self_registration.customize.registration_form.welcome_text.subtitle',
                    defaultMessage:
                      'It is possible to define a welcome text for your signup page. The default is "Welcome to {company}!"',
                  },
                  { company: signupPage.name },
                )}
              </Typography>
              <Spacer size='xl' />
              <Box maxWidth={688}>
                <CCPTextField
                  control={control}
                  name='welcomeText'
                  label={formatMessage({
                    id: 'settings.self_registration.customize.registration_form.welcome_text.label',
                    defaultMessage: 'Welcome text',
                  })}
                />
              </Box>
              {welcome_text_left_characters >= 0 && (
                <>
                  <Spacer size='sm' />
                  <Typography variant='subtitle2' fontSize='14px'>
                    {formatMessage(
                      {
                        id: 'landing.settings.welcome_text.textfield.hint',
                        defaultMessage: '{count} characters left',
                      },
                      { count: welcome_text_left_characters },
                    )}
                  </Typography>
                </>
              )}
              <Spacer size='3xl' />
              <Typography variant='h5'>
                {formatMessage({
                  id: 'landing.settings.contact_info.title',
                  defaultMessage: 'Company contact information for employees',
                })}
              </Typography>
              <Spacer size='xl' />
              <Box maxWidth={390}>
                <CCPTextField
                  control={control}
                  name='emailAddress'
                  label={formatMessage({
                    id: 'settings.self_registration.customize.registration_form.email_address.label',
                    defaultMessage: 'Email address',
                  })}
                />
                <Spacer size='lg' />
                <CCPTextField
                  control={control}
                  name='phoneNumber'
                  label={formatMessage({
                    id: 'settings.self_registration.customize.registration_form.phone_number.label',
                    defaultMessage: 'Phone number',
                  })}
                />
              </Box>
              <CustomizationFooter
                resetMethod={resetChanges}
                isValid={isValid}
                saveText={formatMessage({
                  id: 'settings.self_registration.customize_regitration_form.footer.save_changes',
                  defaultMessage: 'Publish',
                })}
              />
            </form>
          </FormProvider>
        </AccordionDetails>
      </Accordion>
    </MainBlock>
  );
};

export default RegistrationSettings;
