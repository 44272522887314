import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { CreateEndUserRequestBody, PimcoreErrorResponse } from 'types';
import { axiosWithConfig } from 'api';

export const createEndUser = createAsyncThunk<
  unknown,
  CreateEndUserRequestBody,
  { rejectValue: PimcoreErrorResponse }
>('employeeManagement/createEndUser', async (body, { rejectWithValue }) => {
  const { companyId, confirmationId } = body;
  const axios = await axiosWithConfig();
  const endpoint =
    process.env.REACT_APP_PIMCORE_ENDPOINT +
    `api/companies/${companyId}/endusers/bulk-upload/${confirmationId}`;

  try {
    return (await axios.put(endpoint)).data;
  } catch (e) {
    const error: AxiosError<PimcoreErrorResponse> = e as AxiosError<PimcoreErrorResponse>;
    const { response } = error;
    const data = response?.data as PimcoreErrorResponse;

    return rejectWithValue(data);
  }
});
