import { FetchingStatus } from 'types';
import { UserManagementState } from './interfaces';

export const initialState: UserManagementState = {
  users: {
    fetchingStatus: FetchingStatus.IDLE,
    data: null,
    error: null,
  },
  addUser: {
    fetchingStatus: FetchingStatus.IDLE,
    error: null,
  },
  changeUserEmail: {
    fetchingStatus: FetchingStatus.IDLE,
    error: null,
  },
  verifyChangeUserEmail: {
    fetchingStatus: FetchingStatus.IDLE,
    error: null,
  },
};
