import { Box } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '@store/index';
import GuestHeader from '@components/GuestHeader';
import Spacer from '@components/Spacer';
import GuestFormContainer from '@components/GuestFormContainer';
import Login from '@features/authentication/components/Login';
import { AuthenticationActions } from '@features/authentication/store';
import MFACode from '@features/authentication/components/MFACode';

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const mfa = useAppSelector(state => state.MFAState);

  const hasMFA = useMemo(() => !!mfa.user, [mfa]);

  useEffect(() => {
    const resetState = () => {
      dispatch(AuthenticationActions.resetAuthState());
    };

    window.addEventListener('load', resetState);
    return () => {
      window.removeEventListener('load', resetState);
    };
  }, [dispatch]);

  return (
    <Box sx={{ width: '100%' }}>
      <GuestHeader />
      <Spacer height={48} />
      <GuestFormContainer>{hasMFA ? <MFACode /> : <Login />}</GuestFormContainer>
    </Box>
  );
};

export default LoginPage;
