import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import {
  setStep,
  updatePersonalInfoStepData,
  resetProcess,
} from './actions/sync/selfRegistration.actions';
import { registerAdminUserExtraReducer } from './actions/async/registerAdminUser/registerAdminUser.reducer';
import { getSignUpKeyDataExtraReducer } from './actions/async/getSignUpKeyData/getSignUpKeyData.reducer';

const selfRegistrationSlice = createSlice({
  name: 'selfRegistration',
  initialState,
  reducers: {
    updatePersonalInfoStepData,
    setStep,
    resetProcess,
  },
  extraReducers: builder => {
    getSignUpKeyDataExtraReducer(builder);
    registerAdminUserExtraReducer(builder);
  },
});

export const { name, actions } = selfRegistrationSlice;
export const selfRegistrationReducer = selfRegistrationSlice.reducer;
