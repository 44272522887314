import { phoneRegExp } from 'utils';
import { getBirthDate } from 'utils/getUpdatedDate';
import * as yup from 'yup';

export const EmployeeEditProfileSchema = yup
  .object({
    firstName: yup
      .string()
      .required('form.employee.error.first_name.required')
      .max(190, 'employee_management.form.error.firstname.max')
      .matches(/^(?!\s+$).*/, 'schema.add.new_user.first_name.match'),
    lastName: yup
      .string()
      .required('form.employee.error.last_name.required')
      .max(190, 'employee_management.form.error.lastname.max')
      .matches(/^(?!\s+$).*/, 'schema.add.new_user.last_name.match'),
    gender: yup.string().required('form.employee.error.gender.required'),
    dateOfBirth: yup
      .date()
      .typeError('form.employee.error.date_of_birth.invalid')
      .nullable()
      .min(getBirthDate('min'), 'form.employee.error.date_of_birth.invalid')
      .max(getBirthDate('max'), 'form.employee.error.date_of_birth.invalid')
      .required('form.employee.error.date_of_birth.required'),
    businessEmail: yup
      .string()
      .trim()
      .email('form.employee.error.corporate_email.invalid'),
    phoneNumber: yup
      .string()
      .nullable()
      .test('isValid', 'form.employee.error.phone.invalid', val => {
        if (val) return phoneRegExp.test(val as string) === true;
        else return true;
      }),
    dialCode: yup
      .string()
      .nullable()
      .test('isRequired', '', (value, ctx) => {
        if (ctx.parent.phoneNumber) return Boolean(value);
        else return true;
      }),
  })
  .required();

export default EmployeeEditProfileSchema;
