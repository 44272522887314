import React, { useCallback, useState, useMemo } from 'react';
import { CCPDefaultButton, Spacer } from 'components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { useIntl } from 'react-intl';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EmployeeRequestForm } from './interfaces';
import { getEmployeeRequestSchema } from 'shared/schemas/EmployeeRequestSchema';
import { format, isFuture, startOfTomorrow } from 'date-fns';
import { EmployeeStatus, FetchingStatus } from 'types';
import {
  checkIsMonthPicker,
  prepareMaxDateForPicker,
  prepareMinDateForPicker,
} from 'utils/contractDependencies';
import { useAppSelector } from 'store';
import StartDatePicker from '@components/Form/StartDatePicker';
import Loader from '@components/Loader';

export const initRequestModal = {
  denyModal: false,
  confirmModal: false,
};

export const EmployeeRequestModal: React.FC<{
  employeeTitle?: string | null;
  employeeName?: string;
  employeeId: any;
  transitionDate?: string | undefined;
  transitionType: EmployeeStatus | string;
  close?: () => void;
  onSubmit?: ({
    transition,
    startDate,
  }: {
    transition: EmployeeStatus | string;
    startDate?: string;
  }) => void;
}> = ({
  employeeTitle,
  employeeName,
  transitionDate,
  transitionType,
  close = () => {},
  onSubmit = () => {},
}) => {
  const intl = useIntl();
  const [open, setOpen] = useState(true);

  const activeContract = useAppSelector(state => state.contracts.euCreationCondition.data);
  const employeeStatusFetching = useAppSelector(
    state => state.employeeManagement.api.changeEmployeeStatus.fetchingStatus,
  );

  const isMonthPicker = useMemo(
    () => checkIsMonthPicker(activeContract?.regularStartDate),
    [activeContract?.regularStartDate],
  );

  const tomorrow = startOfTomorrow();
  const prefilledStartDate =
    transitionDate && isFuture(new Date(transitionDate))
      ? prepareMinDateForPicker(new Date(transitionDate), isMonthPicker, true)
      : prepareMinDateForPicker(tomorrow, isMonthPicker);

  const handleClose = useCallback(() => {
    setOpen(false);
    if (close) {
      close();
    }
  }, [close]);

  const handleModalSubmit = (data?: EmployeeRequestForm) => {
    if (transitionType === EmployeeStatus.PENDING) {
      const startDate = format(new Date(data?.startDate as Date), 'yyyy-MM-dd');
      if (onSubmit) {
        onSubmit({ transition: transitionType, startDate });
      }
    } else {
      if (onSubmit) {
        onSubmit({ transition: transitionType });
      }
    }
  };

  const methods = useForm<EmployeeRequestForm>({
    defaultValues: {
      startDate:
        transitionType === EmployeeStatus.PENDING
          ? prefilledStartDate
            ? format(prefilledStartDate, 'yyyy-MM-dd')
            : undefined
          : null,
    },
    mode: 'all',
    resolver: yupResolver(
      getEmployeeRequestSchema(prepareMaxDateForPicker(activeContract?.endDate, isMonthPicker)),
    ),
  });
  const { control, handleSubmit } = methods;

  return (
    <>
      <Loader
        show={employeeStatusFetching === FetchingStatus.PENDING}
        inCard
        portalId='employeeRequestModal'
      />
      {transitionType === EmployeeStatus.PENDING && (
        <Dialog open={open} onClose={handleClose} id='employeeRequestModal'>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleModalSubmit)}>
              <DialogTitle>
                {intl.formatMessage({
                  id: 'employee.modal.confirm.title',
                  defaultMessage: 'Confirm employee membership',
                })}
              </DialogTitle>

              <DialogContent>
                <Typography variant='h3'>
                  {employeeTitle ? ` ${employeeTitle} ` : ''}
                  {employeeName}
                </Typography>
                <Spacer size='xl' />
                <Typography variant='subtitle1'>
                  {intl.formatMessage({
                    id: 'employee.modal.confirm.content',
                    defaultMessage: 'Confirm employee membership for this request?',
                  })}
                </Typography>
                <Spacer size='2xl' />
                <Stack direction='row' justifyContent='space-between' spacing={2}>
                  <StartDatePicker
                    control={control}
                    name='startDate'
                    minimumDate={prepareMinDateForPicker(tomorrow, isMonthPicker)}
                    maximumDate={prepareMaxDateForPicker(activeContract?.endDate, isMonthPicker)}
                    isMonthPicker={isMonthPicker}
                    label={`${intl.formatMessage({
                      id: 'form.employee.field.start_date.label',
                      defaultMessage: 'Start date',
                    })}`}
                    width={254}
                  />
                </Stack>
              </DialogContent>
              <DialogActions>
                <CCPDefaultButton variant='outlined' onClick={handleClose}>
                  {intl.formatMessage({ id: 'form.employee.cta.cancel', defaultMessage: 'Cancel' })}
                </CCPDefaultButton>
                <CCPDefaultButton variant='contained' type='submit'>
                  {intl.formatMessage({
                    id: 'form.employee.cta.confirm_request',
                    defaultMessage: 'Confirm request',
                  })}
                </CCPDefaultButton>
              </DialogActions>
            </form>
          </FormProvider>
        </Dialog>
      )}
      {transitionType === EmployeeStatus.DENIED && (
        <Dialog open={open} onClose={handleClose} id='employeeRequestModal'>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleModalSubmit)}>
              <DialogTitle>
                {intl.formatMessage({
                  id: 'employee.modal.deny.title',
                  defaultMessage: 'Deny employee membership',
                })}
              </DialogTitle>
              <DialogContent>
                <Typography variant='h3'>
                  {employeeTitle ? ` ${employeeTitle} ` : ''}
                  {employeeName}
                </Typography>
                <Spacer size='xl' />
                <Typography variant='subtitle1'>
                  {intl.formatMessage({
                    id: 'employee.modal.deny.content',
                    defaultMessage: 'Deny employee membership?',
                  })}
                </Typography>
              </DialogContent>
              <DialogActions>
                <CCPDefaultButton variant='outlined' onClick={handleClose}>
                  {intl.formatMessage({ id: 'form.employee.cta.cancel', defaultMessage: 'Cancel' })}
                </CCPDefaultButton>
                <CCPDefaultButton variant='contained' type='submit'>
                  {intl.formatMessage({
                    id: 'form.employee.cta.deny_request',
                    defaultMessage: 'Deny request',
                  })}
                </CCPDefaultButton>
              </DialogActions>
            </form>
          </FormProvider>
        </Dialog>
      )}
    </>
  );
};

export default EmployeeRequestModal;
