import { useState, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, MenuItem, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'store/store';
import { CCPDefaultButton, CCPSelect, CCPTextField, Spacer } from 'components';
import { UpdateUserInfoSchema } from 'shared/schemas';
import { FetchingStatus, IUserInfo, OwnUserData, UserAccountInfoProps } from 'types';
import { DeleteUserModal, ChangeEmailModal } from 'features/account/components';
import { useIntl } from 'react-intl';
import { useLanguageItems } from 'utils';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { SettingsActions } from 'features/settings/store';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';
import Loader from 'components/Loader';
import { AuthenticationActions } from '@features/authentication/store';
import { useLanguage } from '@hooks/useLanguage';

const GeneralInfo: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentLanguage = useLanguage();
  const languageItems = useLanguageItems();
  const [, setCookies] = useCookies(['language_app']);

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenEmailModal, setIsOpenEmailModal] = useState<boolean>(false);
  const fetchingStatus = useAppSelector(store => store.settings.ownUserDataFetchingStatus);
  const ownUserData = useAppSelector(SettingsSelectors.getOwnUserData);
  const userInfo = ownUserData?.userdetails || ({} as IUserInfo);

  //needs to be fixed
  const methods = useForm<UserAccountInfoProps>({
    defaultValues: {
      firstName: userInfo?.name,
      lastName: userInfo?.surname,
      language: currentLanguage,
    },
    mode: 'onTouched',
    resolver: yupResolver(UpdateUserInfoSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { isValid },
    getValues,
    reset,
  } = methods;

  useEffect(() => {
    if (userInfo.hasOwnProperty('name')) {
      reset({
        firstName: userInfo.name,
        lastName: userInfo.surname,
        language: currentLanguage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const closeModalToggler = (): void => {
    setIsOpenModal(!isOpenModal);
  };

  const closeEmailModalToggler = (): void => {
    setIsOpenEmailModal(!isOpenEmailModal);
  };

  const changeMode = () => {
    setIsEdit(!isEdit);
    reset();
  };

  const onSubmit = async (data: UserAccountInfoProps) => {
    if (!ownUserData) {
      return;
    }
    const payload: OwnUserData = {
      userdetails: {
        ...ownUserData.userdetails,
        name: data.firstName.trim(),
        surname: data.lastName.trim(),
        language: data.language,
      },
    } as OwnUserData;

    try {
      await dispatch(SettingsActions.changeOwnUserData(payload));
      if (userInfo?.language !== data.language) {
        dispatch(
          AuthenticationActions.updateUsersLanguage({
            language: data.language,
          }),
        );
        setCookies('language_app', data.language, { path: '/' });
        navigate(`/${data.language}/account?activeIndex=0`);
      }
      changeMode();
    } catch (e) {
      console.log(e);
    }
  };

  const isLoading = useMemo(() => fetchingStatus === FetchingStatus.PENDING, [fetchingStatus]);

  return (
    <FormProvider {...methods}>
      <Loader show={isLoading} inCard portalId='profileWrapper' />
      <Box
        className='content__form_wrapper'
        id='profileWrapper'
        component='form'
        onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Typography variant='h6'>
            {intl.formatMessage({ id: 'account.tab.profile', defaultMessage: 'Profile' })}
          </Typography>
          <Spacer size='md' />
          <Box className='content__form'>
            {isEdit ? (
              <>
                <Box className='content__form_top'>
                  <Box>
                    <CCPTextField
                      control={control}
                      name='firstName'
                      required
                      label={intl.formatMessage({
                        id: 'form.employee.field.first_name.label',
                        defaultMessage: 'First name',
                      })}
                      width={254}
                    />
                  </Box>
                  <Box>
                    <CCPTextField
                      control={control}
                      name='lastName'
                      required
                      label={intl.formatMessage({
                        id: 'form.employee.field.last_name.label',
                        defaultMessage: 'Last name',
                      })}
                      width={254}
                    />
                  </Box>
                </Box>
                <Spacer size='lg' />
              </>
            ) : (
              <>
                <Typography variant='h3' className='notranslate'>{`${userInfo?.name || ''} ${
                  userInfo?.surname || ''
                }`}</Typography>
                <Spacer size='3xl' />
              </>
            )}
            <Typography className='content__form_title' variant='subtitle2'>
              {intl.formatMessage({
                id: 'form.employee.field.corporate_email.label',
                defaultMessage: 'Corporate email',
              })}
            </Typography>
            <Spacer size='md' />
            <Box className='content__form_email'>
              <Typography variant='subtitle1' className='notranslate'>
                {userInfo?.email}
              </Typography>
              {isEdit && (
                <Typography
                  className='content__form_email_text'
                  variant='body1'
                  onClick={closeEmailModalToggler}>
                  {intl.formatMessage({
                    id: 'form.employee.cta.change_email',
                    defaultMessage: 'Change email',
                  })}
                </Typography>
              )}
            </Box>
            <Spacer size='3xl' />
            <Box className='content__form_languages'>
              <Typography className='content__form_title' variant='subtitle2'>
                {intl.formatMessage({
                  id: 'account.tab.info.portal_language',
                  defaultMessage: 'Portal Language',
                })}
              </Typography>
              <Spacer size='md' />
              {isEdit ? (
                <>
                  <CCPSelect control={control} name='language' label='' width={254}>
                    {languageItems.map(language => (
                      <MenuItem
                        key={language.value}
                        value={language.value}
                        selected={language.value === getValues('language')}>
                        {language.label}
                      </MenuItem>
                    ))}
                  </CCPSelect>
                  <Spacer size='lg' />
                  <Typography variant='subtitle2'>
                    {intl.formatMessage({
                      id: 'language.hint',
                      defaultMessage:
                        'With this setting you change the display language of the portal. The language of the mails is determined by the company communication language and can only be changed by Hansefit.',
                    })}
                  </Typography>
                </>
              ) : (
                <Typography variant='subtitle1'>
                  {languageItems
                    .filter(item => item.value === currentLanguage)
                    .map(item => item.label)}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box className='content__footer'>
          <CCPDefaultButton variant='outlined' color='error' onClick={closeModalToggler}>
            {intl.formatMessage({
              id: 'form.employee.cta.remove_account',
              defaultMessage: 'Remove Account',
            })}
          </CCPDefaultButton>
          {isEdit ? (
            <Box display='flex' gap='17px'>
              <CCPDefaultButton variant='outlined' onClick={changeMode}>
                {intl.formatMessage({ id: 'form.employee.cta.cancel', defaultMessage: 'Cancel' })}
              </CCPDefaultButton>
              <CCPDefaultButton disabled={!isValid} type='submit' variant='contained'>
                {intl.formatMessage({
                  id: 'form.employee.cta.save_changes',
                  defaultMessage: 'Save changes',
                })}
              </CCPDefaultButton>
            </Box>
          ) : (
            <CCPDefaultButton variant='contained' onClick={changeMode}>
              {intl.formatMessage({
                id: 'account.tab.info.edit',
                defaultMessage: 'Edit general info',
              })}
            </CCPDefaultButton>
          )}
        </Box>
      </Box>
      {isOpenModal && (
        <DeleteUserModal
          email={userInfo?.email || ''}
          isOpen={isOpenModal}
          closeModal={closeModalToggler}
        />
      )}
      {isOpenEmailModal && (
        <ChangeEmailModal isOpen={isOpenEmailModal} closeModal={closeEmailModalToggler} />
      )}
    </FormProvider>
  );
};

export default GeneralInfo;
