import { CompanyCustomFieldForm } from '@localTypes/index';
import * as yup from 'yup';
interface listProperties {
  value: string;
  keyName: string;
}
declare module 'yup' {
  interface StringSchema {
    uniqueValue(message: string): StringSchema;
  }
}

yup.addMethod(yup.string, 'uniqueValue', function (message: string) {
  return this.test('uniqueVal', message, function (value: string | undefined) {
    const self = this as yup.TestContext<String> & {
      from: [{ value: listProperties }, { value: CompanyCustomFieldForm }];
    };
    const val = value?.toLowerCase();
    if (
      self.from[1].value.localizedValueFields?.filter(item => item.value.toLowerCase() === val)
        .length > 1
    ) {
      return false;
    }
    return true;
  });
});

export const CustomFieldSchema = yup
  .object({
    inputType: yup.string().required('schema.custom_filed.data_type'),
    translationsFields: yup.array().of(
      yup.object().shape({
        language: yup.string(),
        value: yup
          .string()
          .nullable()
          .required('schema.custom_filed.field_name')
          .matches(/^(?!\s+$).*/, 'schema.add.new_user.first_name.match')
          .test(
            'schema.custom_filed.field_name.restricted_name',
            'schema.custom_filed.field_name.restricted_name',
            value => value?.toLowerCase() !== 'id',
          ),
      }),
    ),
    localizedValueFields: yup.array().when('inputType', {
      is: 'enum',
      then: schema =>
        schema.of(
          yup.object().shape({
            value: yup
              .string()
              .nullable()
              .required('schema.custom_filed.enum_field_name.empty')
              .uniqueValue('schema.custom_filed.enum_field_name.duplicate'),
          }),
        ),
    }),
  })
  .required();

export default CustomFieldSchema;
