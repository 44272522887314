import { styled } from '@mui/material';

export const GuestLayoutStyled = styled('div')(({ theme }) => ({
  '.container': {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '100vh',
    backgroundImage: theme.palette.gradient.light,
  },
  '.content': {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
  },
  '.verify': {
    alignItems: 'center',
    justifyContent: 'flex-start',

    '&__subtitle': {
      textAlign: 'center',
    },

    '&__text': {
      textAlign: 'center',

      fontSize: 16,
      lineHeight: 1.38,
      color: theme.palette.neutralDark.contrastText,
    },

    '&__link': {
      cursor: 'pointer',
      textAlign: 'center',

      p: {
        fontFamily: 'Roboto-Medium',
        color: theme.palette.primary.main,
      },
    },

    '&__divider': {
      marginBottom: 24,
    },

    '&__field': {
      gap: 30,
      display: 'flex',
      flexDirection: 'column',
      margin: '32px 0 46px',
    },

    '&__code': {
      gap: 10,
      margin: '8px 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '&_item': {
        width: '40px !important',
        height: 56,
        fontWeight: 600,
        color: theme.palette.neutralDark.contrastText,
        border: `1px solid  ${theme.palette.neutralLight.dark}`,
        userSelect: 'none',
        borderRadius: 4,

        '&_active': {
          border: `1px solid ${theme.palette.success.main}`,
          transition: 'border 0.4s ease',
        },
        '&_error': {
          border: `1px solid ${theme.palette.error.main}`,
          transition: 'border 0.4s ease',
        },

        '&:focus': {
          outline: 'none',
        },
      },
    },

    '&__footer': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
}));
