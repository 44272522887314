const logoutUser = () => {
  const activeCompanyId = window.localStorage.getItem('activeCompanyId') || '';
  window.localStorage.clear();
  window.localStorage.setItem('lastOpenedPathname', window.location.pathname);
  //we must save active company id to local storage after clearing
  window.localStorage.setItem('activeCompanyId', activeCompanyId);
  const defaultSettings: Record<string, string> = { language_app: 'de' };
  window.location.href = `${window.location.origin}/${
    document.cookie.split('; ').reduce((result, item) => {
      const cookie = item.split('=');
      if (cookie[1].trim() === '') {
        return result;
      }
      return { ...result, ...JSON.parse(`{"${cookie[0]}": "${cookie[1]}"}`) };
    }, defaultSettings).language_app
  }/login?session=expired`;
};

export default logoutUser;
