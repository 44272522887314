import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosWithConfig } from 'api';
import { AxiosError } from 'axios';
import {
  AddCompanyCustomFieldRequestParams,
  CompanyCustomFieldType,
  PimcoreErrorResponse,
} from 'types';
import { showToast } from 'utils';

export const addCompanyCustomField = createAsyncThunk<
  CompanyCustomFieldType,
  AddCompanyCustomFieldRequestParams,
  { rejectValue: PimcoreErrorResponse }
>('settings/addCompanyCustomField', async ({ companyId, body }, { rejectWithValue }) => {
  const axios = await axiosWithConfig();
  const endpoint =
    process.env.REACT_APP_PIMCORE_ENDPOINT + `api/companies/${companyId}/custom-fields`;
  try {
    const { data } = await axios.put(endpoint, body);

    return data;
  } catch (e) {
    const error: AxiosError<PimcoreErrorResponse> = e as AxiosError<PimcoreErrorResponse>;
    const { response } = error;
    const data = response?.data as PimcoreErrorResponse;

    let hasLanguageError = false,
      isEmptyDDL = false,
      hasKeyError = false;

    data?.violations?.forEach(violation => {
      hasLanguageError = violation.code === 'REQUIRED_LANGUAGE_IS_NOT_SET';
      isEmptyDDL = violation.code === 'AT_LEAST_ONE_LOCALIZED_VALUE_MUST_BE_FILLED';
      hasKeyError = violation.code === 'IS_NOT_UNIQUE_KEY';
    });

    if (hasKeyError) {
      showToast('error', {
        id: 'toast.settings.custom_fields.key_not_unique',
        defaultMessage: 'This custom field key is not unique',
      });
      return rejectWithValue(data);
    }
    if (!hasLanguageError && !isEmptyDDL) {
      showToast('error', data.detail);
    }

    return rejectWithValue(data);
  }
});
