import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { EmployeeStatusTransition, PimcoreErrorResponse } from 'types';
import { axiosWithConfig } from 'api';
import { EmployeeStatus } from 'types';

import { showToast } from 'utils';

export const changeEmployeeStatus = createAsyncThunk<
  EmployeeStatusTransition,
  any,
  { rejectValue: PimcoreErrorResponse }
>('employeeManagement/changeEmployeeStatus', async (params, { rejectWithValue }) => {
  const axios = await axiosWithConfig();

  const { employeeId } = params;
  const body = {
    transition: params.transition,
    startDate: params.startDate,
    endDate: params.endDate,
  };

  const endpoint = process.env.REACT_APP_PIMCORE_ENDPOINT + `api/endusers/${employeeId}/status`;

  if (body.transition === EmployeeStatus.PAUSE_REMOVED) {
    try {
      const { data } = await axios.delete(endpoint, employeeId);

      showToast(
        'success',
        {
          id: 'employee.transition.pause_removed',
          defaultMessage: `Pause has been removed`,
        },
        undefined,
      );

      return data;
    } catch (e) {
      const error: AxiosError<PimcoreErrorResponse> = e as AxiosError<PimcoreErrorResponse>;
      const { response } = error;
      const data = response?.data as PimcoreErrorResponse;

      return rejectWithValue(data);
    }
  }

  try {
    const { data } = await axios.patch(endpoint, body, {
      headers: {
        'content-type': 'application/merge-patch+json',
      },
    });

    switch (body.transition) {
      case EmployeeStatus.DENIED:
        showToast(
          'success',
          {
            id: 'employee.transition.denied',
            defaultMessage: `Request has been denied. Employee was not added to the employee list`,
          },
          undefined,
        );
        break;
      case EmployeeStatus.CONFIRMED:
        showToast(
          'success',
          {
            id: 'employee.transition.confirmed',
            defaultMessage: `Request has been approved Employee has been added to the employee list`,
          },
          undefined,
        );
        break;
      case EmployeeStatus.PENDING:
        showToast(
          'success',
          {
            id: 'employee.transition.confirmed',
            defaultMessage: `Request has been approved Employee has been added to the employee list`,
          },
          undefined,
        );
        break;
      case EmployeeStatus.ACTIVE:
        showToast(
          'success',
          {
            id: 'employee.transition.start_date_changed',
            defaultMessage: `Start date was successfully changed`,
          },
          undefined,
        );
        break;
      case EmployeeStatus.PAUSED:
        showToast(
          'success',
          {
            id: 'employee.transition.pause_set',
            defaultMessage: `Pause was successfully set`,
          },
          undefined,
        );
        break;
      case EmployeeStatus.INACTIVE:
        showToast(
          'success',
          {
            id: 'employee.transition.deactivation_date_set',
            defaultMessage: `End date is set. Membership will be terminated`,
          },
          undefined,
        );
        break;
      case EmployeeStatus.UNASSIGNED:
        showToast(
          'success',
          data.status === EmployeeStatus.UNASSIGNED
            ? {
                id: 'employee.transition.remove_pending',
                defaultMessage: `The employee was removed`,
              }
            : {
                id: 'employee.transition.remove',
                defaultMessage: `End date is set. The employee will be removed`,
              },
          undefined,
        );
        break;
      case 'pause_ended':
        showToast(
          'success',
          {
            id: 'employee.transition.pause_removed',
            defaultMessage: `Pause has been removed`,
          },
          undefined,
        );
        break;
    }

    return data;
  } catch (e) {
    const error: AxiosError<PimcoreErrorResponse> = e as AxiosError<PimcoreErrorResponse>;
    const { response } = error;
    const data = response?.data as PimcoreErrorResponse;
    showToast(
      'error',
      {
        id: 'employee.transition.error',
        defaultMessage: `Something error! Please contact with Hansefit administrator`,
      },
      undefined,
    );
    return rejectWithValue(data);
  }
});
