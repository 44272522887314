import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Avatar, Box, Menu, Typography } from '@mui/material';

import {
  AdminUserModal,
  AlertModal,
  BlockUserModal,
  CCPDefaultButton,
  DeleteAccountModal,
  NewUserModal,
  PermissionsChipGroup,
  PermissionsSettingsModal,
  Spacer,
} from 'components/index';
import { CompanySelectors } from 'features/company/store/company.selectors';

import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { UserOverviewWrapper, Wrapper, NameWrapper } from './UserOverview.styles';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store';
import TableGrid, { GRID_ACTIONS_COLUMN_NAME } from 'components/TableGrid';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { StatusChip } from 'components/StatusChip';
import { fullNameToAvatar, usePermissionsSettingsList, useUserOverviewMenuOptions } from 'utils';
import {
  IUserOverview,
  PermissionsSettingsState,
  FetchingStatus,
  ChangeUserRequestBody,
  CompanyRole,
  TRoles,
} from 'types';
import { MenuItem } from '@mui/material';
import { UserManagementActions } from 'features/userManagement/store';
import { AuthenticationSelectors } from 'features/authentication/store/authentication.selectors';
import { AuthenticationActions } from 'features/authentication/store';
import { useNavigate } from 'react-router';
import { useLanguage } from 'hooks';
import { getUniqueSingleSelectList } from 'components/TableGrid/helpers/getUniqueSingleSelectList';
import theme from '@theme/theme';

type modalTypes = 'BLOCK' | 'DELETE' | 'PERMISSION' | 'ADMIN' | 'UNBLOCK' | 'RESTORE';
type pemissionsRecords = Record<CompanyRole, string>;

const UserOverviewPage: React.FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const language = useLanguage();
  const company = useAppSelector(state => state.company.api.getCompany);
  const users = useAppSelector(state => state.userManagement.users);
  const { userInfo } = useAppSelector(AuthenticationSelectors.getAuthentication);
  const data = useMemo(
    () =>
      users.data?.map(item => ({
        ...item,
        id: item.cognitoId,
        fullName: `${item.name} ${item.surname}`,
      })),
    [users.data],
  );
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);
  const permissionsList: pemissionsRecords = usePermissionsSettingsList().reduce(
    (acc, item) => ({ ...acc, [item.name]: item.labelTitle }),
    {} as pemissionsRecords,
  );
  const [isOpenNewUser, setIsOpenNewUser] = useState<boolean>(false);
  const [rowPopupName, setRowPopupName] = useState<modalTypes | null>(null);
  const [currentRow, setCurrentRow] = useState<IUserOverview | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLastUserDeleting, setIsLastUserDeleting] = useState(false);
  const [state, setState] = useState<PermissionsSettingsState>({});

  const hasPendingEmployee = useMemo(() => data?.some(user => user.status === 'Pending'), [data]);

  const formatStatus = useCallback(
    (status: IUserOverview['status']) => {
      switch (status) {
        case 'Active':
          return intl.formatMessage({ id: 'cu.status.active', defaultMessage: 'Active' });
        case 'Pending':
          return intl.formatMessage({ id: 'cu.status.pending', defaultMessage: 'Pending' });
        case 'Blocked':
          return intl.formatMessage({ id: 'user.status.blocked', defaultMessage: 'Blocked' });
        case 'Deleted':
          return intl.formatMessage({ id: 'user.status.deleted', defaultMessage: 'Deleted' });
        default:
          return status;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const statusOptions = useMemo(() => {
    if (!data) return [];

    return getUniqueSingleSelectList(
      data.map(item => formatStatus(item.status)).filter(Boolean),
    ).filter(Boolean);
  }, [data, formatStatus]);

  const newUserModalToggler = useCallback(() => {
    setIsOpenNewUser(prev => {
      if (prev) {
        setIsLastUserDeleting(false);
      }
      return !prev;
    });
  }, [setIsOpenNewUser, setIsLastUserDeleting]);

  const administrationTableColumns: GridColDef[] = useMemo(
    () => [
      {
        minWidth: 300,
        field: 'fullName',
        headerName: intl.formatMessage({
          id: 'view.user_management.table.user_detail',
          defaultMessage: 'User details',
        }),
        renderCell: (params: GridRenderCellParams) => (
          <NameWrapper>
            <Box className='user'>
              <Avatar className='user__avatar'>
                {fullNameToAvatar(params.row.name, params.row.surname)}
              </Avatar>
              <Box>
                <Box display='flex' width='max-content' alignItems='center'>
                  <Typography className='user__name notranslate' variant='body1'>
                    {params.value}
                  </Typography>
                  {userInfo?.email === params.row.email && (
                    <Box className='user__own_wrapper'>
                      <Typography className='user__own' variant='body1'>
                        {intl.formatMessage({
                          id: 'view.administration.own.user',
                          defaultMessage: 'You',
                        })}
                      </Typography>
                    </Box>
                  )}
                </Box>

                <Typography className='user__position' variant='body1'>
                  {params.row.position}
                </Typography>
              </Box>
            </Box>
          </NameWrapper>
        ),
        searchType: 'string',
        searchable: true,
      },
      {
        minWidth: 300,
        field: 'email',
        headerName: intl.formatMessage({
          id: 'view.user_management.table.email',
          defaultMessage: 'E-Mail',
        }),
        renderCell: (params: GridRenderCellParams) => (
          <Typography className='table__period notranslate' variant='body1'>
            {params.value}
          </Typography>
        ),
        searchType: 'string',
        searchable: true,
      },
      {
        minWidth: hasPendingEmployee ? 300 : 150,
        field: 'status',
        headerName: intl.formatMessage({
          id: 'view.user_management.table.status',
          defaultMessage: 'Status',
        }),
        type: 'singleSelect',
        valueOptions: statusOptions,
        valueGetter: value => formatStatus(value),
        renderCell: (params: GridRenderCellParams) =>
          renderStatusChip(params.row.status, params.row),
      },
      {
        minWidth: 450,
        flex: 1,
        field: 'role',
        headerName: intl.formatMessage({
          id: 'view.user_management.table.permission',
          defaultMessage: 'Permissions',
        }),
        valueGetter: (value: TRoles) =>
          //replace default values to correct sorting using datagrid possibilities
          //https://hansefit.atlassian.net/browse/FE-698
          value.map(item => permissionsList[item]).join(','),
        renderCell: (params: GridRenderCellParams) => (
          <Box className='permissions'>
            <Box className='permissions__list'>
              <PermissionsChipGroup role={params.row.role} />
            </Box>
          </Box>
        ),
      },
      {
        field: GRID_ACTIONS_COLUMN_NAME,
        renderHeader: () => <div></div>,
        width: 50,
        sortable: false,
        disableReorder: true,
        disableColumnMenu: true,
        resizable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <IconButton
            className='permissions__more'
            aria-label='more'
            id='long-button'
            onClick={(event: React.MouseEvent<HTMLElement>) =>
              openModalHandlers(event, params.row)
            }>
            <MoreVertIcon />
          </IconButton>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, userInfo],
  );

  useEffect(() => {
    const companyId = activeCompany?.companyId;
    if (company.fetchingStatus === FetchingStatus.FULFILLED && companyId) {
      dispatch(UserManagementActions.getUsers({ companyId }));
    }
  }, [dispatch, activeCompany?.companyId, company.fetchingStatus]);

  const resendTempPassword = async (row: IUserOverview) => {
    setIsLoading(true);
    await dispatch(
      UserManagementActions.resendTempPassword({
        companyId: row.companyId,
        companyName: activeCompany?.companyName || row.companyName,
        email: row.email,
      }),
    );
    setIsLoading(false);
  };

  const unblockUser = async (row: IUserOverview) => {
    setIsLoading(true);
    await dispatch(
      UserManagementActions.changeUser({
        companyId: row.companyId,
        cognitoId: row.cognitoId,
        role: row.role,
        status: 'Active',
        fullName: `${row.name} ${row.surname}`,
        email: row.email,
      }),
    );
    setIsLoading(false);
  };

  const renderStatusChip = (status: string, row: IUserOverview) => {
    switch (status) {
      case 'Active':
        return (
          <StatusChip
            size='small'
            label={intl.formatMessage({ id: 'cu.status.active', defaultMessage: 'Active' })}
            background={theme.palette.success.main}
          />
        );
      case 'Pending':
        return (
          <Box className='status'>
            <StatusChip
              size='small'
              label={intl.formatMessage({ id: 'cu.status.pending', defaultMessage: 'Pending' })}
              background={theme.palette.warning.main}
            />
            <CCPDefaultButton
              variant='text'
              onClick={() => resendTempPassword(row)}
              className='status__update'
              startIcon={<RefreshIcon fontSize='small' />}>
              {intl.formatMessage({ id: 'table.action.resend', defaultMessage: 'Resend' })}
            </CCPDefaultButton>
          </Box>
        );
      case 'Blocked':
        return (
          <Box className='status'>
            <StatusChip
              size='small'
              label={intl.formatMessage({ id: 'user.status.blocked', defaultMessage: 'Blocked' })}
              background={theme.palette.neutralDark.main}
            />
            <CCPDefaultButton
              variant='text'
              onClick={() => unblockUser(row)}
              className='status__update'
              startIcon={<LockOpenIcon fontSize='small' />}>
              {intl.formatMessage({ id: 'table.action.unblock', defaultMessage: 'Unblock' })}
            </CCPDefaultButton>
          </Box>
        );
      case 'Deleted':
        return (
          <StatusChip
            size='small'
            label={intl.formatMessage({ id: 'user.status.deleted', defaultMessage: 'Deleted' })}
            background='red'
          />
        );
      default:
        return null;
    }
  };

  const openModalHandlers = (
    event: React.MouseEvent<HTMLElement>,
    row: IUserOverview | null,
  ): void => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
    if (row)
      setState({
        Admin: row.role?.includes(CompanyRole.Admin),
        Invoice: row.role?.includes(CompanyRole.Invoice),
        Usermgmt: row.role?.includes(CompanyRole.Usermgmt),
      });
  };

  const toggleRowPopup = useCallback(
    (option: modalTypes | null) => {
      setRowPopupName(option);
      setAnchorEl(null);
      if (!option) {
        setCurrentRow(null);
        setState({});
      }
    },
    [setRowPopupName, setAnchorEl, setCurrentRow, setState],
  );

  const userOverviewMenuOptions = useUserOverviewMenuOptions() as {
    value: modalTypes;
    title: string;
  }[];

  const renderMenuOptions = useCallback(() => {
    if (currentRow) {
      const isBlocked = currentRow.status === 'Blocked';
      const isPending = currentRow.status === 'Pending';
      const isDeleted = currentRow.status === 'Deleted';

      return userOverviewMenuOptions.map(option => {
        if (
          (isBlocked && option.value === 'PERMISSION') ||
          (isPending && option.value === 'BLOCK')
        ) {
          return null;
        }

        if (
          (isDeleted && option.value === 'PERMISSION') ||
          (isDeleted && option.value === 'BLOCK')
        ) {
          return null;
        }

        if (isDeleted && option.value === 'DELETE') {
          return (
            <MenuItem key='RESTORE' onClick={() => toggleRowPopup('RESTORE')}>
              {intl.formatMessage({
                id: 'user.menu_option.restore_user',
                defaultMessage: 'Reactivate account',
              })}
            </MenuItem>
          );
        }

        if (option.value === 'BLOCK' && isBlocked) {
          if (isBlocked)
            return (
              <MenuItem key='UNBLOCK' onClick={() => toggleRowPopup('UNBLOCK')}>
                {intl.formatMessage({
                  id: 'user.menu_option.unblock_user',
                  defaultMessage: 'Unblock user',
                })}
              </MenuItem>
            );
        }

        return (
          <MenuItem key={option.value} onClick={() => toggleRowPopup(option.value)}>
            {option.title}
          </MenuItem>
        );
      });
    }
  }, [currentRow, userOverviewMenuOptions, intl, toggleRowPopup]);

  const statusText = useMemo(() => {
    if (!rowPopupName) {
      return '';
    }
    switch (rowPopupName) {
      case 'BLOCK':
        return 'Blocked';
      case 'DELETE':
        return 'Deleted';
      case 'UNBLOCK':
        return 'Active';
      case 'RESTORE':
        return 'Active';
    }
  }, [rowPopupName]);

  const changeUser = useCallback(
    async (userData: ChangeUserRequestBody, isOwnUser: boolean, isPermissionPopup: boolean) => {
      toggleRowPopup(null);
      await dispatch(UserManagementActions.changeUser(userData));
      if (isOwnUser) {
        await dispatch(AuthenticationActions.signOut());
        navigate(`/${language}/login`);
      } else if (isPermissionPopup) {
        await dispatch(AuthenticationActions.getUserInfo({}));
      }
      setIsLoading(false);
    },
    [language, dispatch, toggleRowPopup, navigate, setIsLoading],
  );

  const submitRowModal = useCallback(async () => {
    if (currentRow) {
      let userData = {};
      if (rowPopupName === 'PERMISSION') {
        const activeRole = Object.keys(state).filter((key: string) => state[key]);
        if (currentRow.status === 'Active') {
          const activeUsers = data?.filter(user => user.status === 'Active');
          const activeAdmins = activeUsers?.filter(user => user?.role?.includes(CompanyRole.Admin));
          const isDeleteUser = currentRow.role?.includes(CompanyRole.Admin)
            ? Number(activeAdmins?.length) > 1
            : true;
          if (!isDeleteUser && !activeRole.includes(CompanyRole.Admin)) {
            toggleRowPopup('ADMIN');
            return;
          }
        }
        setIsLoading(true);
        userData = {
          companyId: currentRow.companyId,
          cognitoId: currentRow.cognitoId,
          role: activeRole,
          status: currentRow.status,
          fullName: `${currentRow.name} ${currentRow.surname}`,
          email: currentRow.email,
          transition: 'permissions',
        };
      } else {
        setIsLoading(true);
        userData = {
          companyId: currentRow.companyId,
          cognitoId: currentRow.cognitoId,
          role: currentRow.role,
          status: statusText,
          fullName: `${currentRow.name} ${currentRow.surname}`,
          email: currentRow.email,
          deleteOwnUser: userInfo?.email === currentRow.email,
        };
      }
      changeUser(userData, userInfo?.email === currentRow.email, rowPopupName === 'PERMISSION');
    }
  }, [
    currentRow,
    rowPopupName,
    setIsLoading,
    userInfo,
    toggleRowPopup,
    changeUser,
    data,
    statusText,
    state,
  ]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setState(prevState => ({
        ...prevState,
        [event.target.name]: event.target.checked,
      }));
    },
    [setState],
  );

  const currentRowModal = useMemo(() => {
    if (!currentRow || !rowPopupName) {
      return null;
    }
    switch (rowPopupName) {
      case 'UNBLOCK':
        return submitRowModal();
      case 'RESTORE':
        return submitRowModal();
      case 'BLOCK':
        return (
          <BlockUserModal
            email={currentRow.email}
            isOpen
            fullName={`${currentRow.name} ${currentRow.surname}`}
            isOwnCU={userInfo?.email === currentRow.email}
            handleClose={() => toggleRowPopup(null)}
            handleSubmit={submitRowModal}
          />
        );
      case 'DELETE':
        const activeUsers = data?.filter(user => user.status === 'Active');
        const activeOtherAdmins = activeUsers?.filter(
          user => user.cognitoId !== currentRow.cognitoId && user.role.includes(CompanyRole.Admin),
        );
        if (activeUsers && activeOtherAdmins?.length === 0) {
          setIsLastUserDeleting(activeUsers.length === 1);
          return (
            <AlertModal
              isOpen
              title={intl.formatMessage({
                id: 'alert.modal.assign_new_administrator.title',
                defaultMessage: 'Assign new administrator',
              })}
              description={intl.formatMessage({
                id: 'alert.modal.assign_new_administrator.subtitle',
                defaultMessage: `You are the last remaining portal user. To ensure that your company continues to have access to the customer portal, invite a new portal user who has all access rights.`,
              })}
              showConfirmButton
              confirmButtonText={intl.formatMessage({
                id: 'alert.modal.assign_new_administrator.cta',
                defaultMessage: 'Add new portal user',
              })}
              handleConfirm={() => {
                toggleRowPopup(null);
                newUserModalToggler();
              }}
              handleClose={() => toggleRowPopup(null)}
            />
          );
        }
        return (
          <DeleteAccountModal
            email={currentRow.email}
            isOpen
            fullName={`${currentRow.name} ${currentRow.surname}`}
            isOwnCU={userInfo?.email === currentRow.email}
            handleClose={() => toggleRowPopup(null)}
            handleSubmit={submitRowModal}
          />
        );
      case 'PERMISSION':
        return (
          <PermissionsSettingsModal
            state={state}
            isOpen
            handleClose={() => toggleRowPopup(null)}
            handleChange={handleChange}
            handleSubmit={submitRowModal}
            disabled={
              userInfo?.email === currentRow.email &&
              JSON.stringify({
                Admin: currentRow.role?.includes(CompanyRole.Admin),
                Invoice: currentRow.role?.includes(CompanyRole.Invoice),
                Usermgmt: currentRow.role?.includes(CompanyRole.Usermgmt),
              }) === JSON.stringify(state)
            }
            userEmail={currentRow.email}
            isOwnCU={userInfo?.email === currentRow.email}
          />
        );
      case 'ADMIN':
        return <AdminUserModal isOpen handleClose={() => toggleRowPopup(null)} />;
    }
  }, [
    intl,
    data,
    state,
    userInfo,
    currentRow,
    handleChange,
    rowPopupName,
    submitRowModal,
    toggleRowPopup,
    newUserModalToggler,
    setIsLastUserDeleting,
  ]);

  return (
    <>
      {/* <Loader show={isLoading} /> */}
      <UserOverviewWrapper>
        <Box className='header'>
          <Typography variant='h2'>
            {intl.formatMessage({
              id: 'view.user_management.title',
              defaultMessage: 'User Management',
            })}
          </Typography>
          <CCPDefaultButton
            onClick={newUserModalToggler}
            variant='contained'
            data-test-id='addNewPortalUserButton'>
            {intl.formatMessage({
              id: 'view.user_management.cta.add_new_user',
              defaultMessage: 'Add new user',
            })}
          </CCPDefaultButton>
        </Box>
        <Spacer size='lg' />
        <Wrapper>
          <TableGrid
            tableName='administration'
            isLoading={users.fetchingStatus === FetchingStatus.PENDING || isLoading}
            columns={administrationTableColumns}
            rowsData={data ? data : []}
            searchPlaceholder={intl.formatMessage({
              id: 'administration_management.search.placeholder',
              defaultMessage: 'Search by user details or email',
            })}
            noRecordsTranslation={intl.formatMessage({
              id: 'administration_management.grid.no_records',
              defaultMessage: `Sorry, we couldn’t find any administration users.`,
            })}
            addRecordsTranslation={intl.formatMessage({
              id: 'administration_management.grid.add_users',
              defaultMessage: '<tag>Click here</tag> to add new administration users',
            })}
            addNewRecords={newUserModalToggler}
          />
        </Wrapper>
      </UserOverviewWrapper>
      <Menu
        id='long-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => toggleRowPopup(null)}>
        {renderMenuOptions()}
      </Menu>
      {isOpenNewUser && (
        <NewUserModal
          isOpen={isOpenNewUser}
          handleClose={newUserModalToggler}
          activeCompanyId={activeCompany?.companyId}
          activeCompanyName={activeCompany?.companyName || ''}
          isLastUserDeleting={isLastUserDeleting}
        />
      )}
      {currentRowModal}
    </>
  );
};

export default UserOverviewPage;
