import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosWithConfig } from 'api';
import { GetEmployeeRequestParams, GetEmployeeResponse, PimcoreErrorResponse } from 'types';
import { AxiosError } from 'axios';

export const getEmployee = createAsyncThunk<
  GetEmployeeResponse,
  GetEmployeeRequestParams,
  { rejectValue: PimcoreErrorResponse }
>('employeeManagement/getEmployee', async ({ employeeId }, { rejectWithValue }) => {
  const endpoint = process.env.REACT_APP_PIMCORE_ENDPOINT + `api/endusers/${employeeId}`;
  const axios = await axiosWithConfig();

  try {
    return (await axios.get(endpoint)).data;
  } catch (e) {
    const error: AxiosError<PimcoreErrorResponse> = e as AxiosError<PimcoreErrorResponse>;
    const { response } = error;
    const data = response?.data as PimcoreErrorResponse;

    return rejectWithValue(data);
  }
});
