import { CSSObject } from '@emotion/react';
import { Button, ButtonTypeMap, FormHelperText } from '@mui/material';
import { ReactNode } from 'react';
import { Control, FieldError, FieldPath, FieldValues, UseFormRegister } from 'react-hook-form';

type HookFormSingleUploadProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  register: UseFormRegister<TFieldValues>;
  control: Control<TFieldValues>;
  name: TName;
  error?: FieldError;
  disabled?: boolean;
  required?: boolean;
  children?: ReactNode;
  css?: CSSObject;
  variant?: ButtonTypeMap['props']['variant'];
  className?: string;
  formHelperText?: ReactNode;
  validFileExtensions?: string[];
};

const HookFormSingleUpload = <TFieldValues extends Record<string, any>>({
  register,
  control,
  name,
  error,
  children,
  formHelperText,
  validFileExtensions,
  ...props
}: HookFormSingleUploadProps<TFieldValues>) => (
  <>
    <input
      type='file'
      id={name}
      {...register(name, { required: props.required })}
      css={{
        position: 'absolute',
        width: 1,
        height: 1,
        padding: 0,
        margin: -1,
        overflow: 'hidden',
        clip: 'rect(0,0,0,0)',
        border: 0,
        appearance: 'none',
      }}
      accept={validFileExtensions?.map(ext => `.${ext}`).join(',')}
    />
    {error && error.message && <FormHelperText error>{error.message}</FormHelperText>}

    <Button component='label' htmlFor={name} {...props}>
      {children}
    </Button>

    {formHelperText && <FormHelperText>{formHelperText}</FormHelperText>}
  </>
);

export default HookFormSingleUpload;
