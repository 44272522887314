import React from 'react';
import { useIntl } from 'react-intl';
import { CompanyRole, EditNotificationsModalProps, FetchingStatus } from 'types';
import { CCPDefaultButton, Spacer } from 'components';
import { FormProvider, useForm } from 'react-hook-form';
import { CCPLabelCheckbox } from 'components/Form/Checkbox/LabelCheckbox';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useAppSelector } from '@store/index';
import Loader from '@components/Loader';

const EditNotificationsModal: React.FC<EditNotificationsModalProps> = ({
  roles,
  notifications,
  isOpen,
  onSubmit,
  closeModal,
}) => {
  const { formatMessage } = useIntl();

  const autoConfirmSignup = useAppSelector(
    state => state.settings.defaultSelfRegistration.autoConfirmSignup,
  );
  const fetchingSignupStatus = useAppSelector(state => state.settings.selfRegistrationFetching);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      onboarding: notifications.includes('onboarding'),
      invoices: notifications.includes('invoices'),
    },
  });
  const {
    setValue,
    control,
    handleSubmit,
    formState: { isValid },
  } = methods;

  return (
    <Dialog open={isOpen} onClose={closeModal}>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ position: 'relative' }}
          id='editNotificationsWrapper'>
          <Loader
            inCard
            show={fetchingSignupStatus === FetchingStatus.PENDING}
            portalId='editNotificationsWrapper'
          />
          <DialogTitle>
            <Typography variant='h2'>
              {formatMessage({
                id: 'form.employee.cta.notifications.title',
                defaultMessage: 'Edit notification settings',
              })}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant='subtitle1'>
              {formatMessage({
                id: 'form.employee.cta.notifications.subtitle',
                defaultMessage: 'Select which notifications you would like to receive:',
              })}
            </Typography>
            {roles.includes(CompanyRole.Usermgmt) && (
              <>
                <Spacer size='xl' />
                <CCPLabelCheckbox
                  disabled={autoConfirmSignup}
                  control={control}
                  name='onboarding'
                  label={`${formatMessage({
                    id: 'user.info.notification.onboarding_checkbox.label',
                    defaultMessage: 'Notifications about onboarding',
                  })}`}
                  subLabel={`${formatMessage({
                    id: 'user.info.notification.onboarding_checkbox.description',
                    defaultMessage: 'Information about new pending employee confirmation',
                  })}`}
                />
              </>
            )}
            {roles.includes(CompanyRole.Invoice) && (
              <>
                <Spacer size='xl' />
                <CCPLabelCheckbox
                  control={control}
                  name='invoices'
                  label={`${formatMessage({
                    id: 'user.info.notification.invoices_checkbox.label',
                    defaultMessage: 'Notifications about invoices',
                  })}`}
                  subLabel={`${formatMessage({
                    id: 'user.info.notification.invoices_checkbox.description',
                    defaultMessage: 'Information about new invoices and open payments',
                  })}`}
                  setValue={setValue}
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <CCPDefaultButton onClick={closeModal} variant='outlined'>
              {formatMessage({ id: 'form.employee.cta.back', defaultMessage: 'Back to list' })}
            </CCPDefaultButton>
            <CCPDefaultButton type='submit' disabled={!isValid} variant='contained'>
              {formatMessage({
                id: 'form.employee.cta.save_changes',
                defaultMessage: 'Save changes',
              })}
            </CCPDefaultButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default EditNotificationsModal;
