import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { GridFilterModel, GridColDef } from '@mui/x-data-grid-pro';
import { DataGridTableConfiguration, TablesManagementState } from '../../interfaces';

export const setInitialTableConfiguration: CaseReducer<
  TablesManagementState,
  PayloadAction<{ tableName: string; companyId: number; tableColumns: GridColDef[] }>
> = (state, { payload: { tableName, companyId, tableColumns } }) => {
  const columnsFromLocalStorage = sessionStorage.getItem(
    `ccp_${tableName}_table_configuration_columns_${companyId}`,
  );
  const rowsPerPageFromLocalStorage = sessionStorage.getItem(
    `ccp_${tableName}_table_configuration_rows_per_page_${companyId}`,
  );
  const filtersFromLocalStorage = sessionStorage.getItem(
    `ccp_${tableName}_table_configuration_filters_${companyId}`,
  );

  let columns: { [key: string]: boolean } = {};
  let rowsPerPage: number = -1;
  let filters: any = {};

  if (rowsPerPageFromLocalStorage) {
    rowsPerPage = parseInt(rowsPerPageFromLocalStorage);
  } else {
    rowsPerPage = 25;
  }

  if (columnsFromLocalStorage) {
    columns = JSON.parse(columnsFromLocalStorage);
  } else if (!columnsFromLocalStorage && Object.keys(tableColumns).length > 0) {
    columns = tableColumns.reduce((acc, item) => ({ ...acc, [item.field]: true }), {});
  }

  if (filtersFromLocalStorage) {
    filters = JSON.parse(filtersFromLocalStorage);
  } else {
    filters = { items: [] };
  }
  if (!state[tableName]) {
    state[tableName] = {
      tableConfigurations: { [companyId.toString()]: {} as DataGridTableConfiguration },
    };
  }
  state[tableName].tableConfigurations[companyId] = {
    rowsPerPage,
    currentPage: 0,
    filters,
    columns,
    searchData: { hasVisibleSearchField: false, searchTerm: '' },
  };
};

export const resetTableConfiguration: CaseReducer<
  TablesManagementState,
  PayloadAction<{
    tableName: string;
    companyId: number;
    tableColumns: readonly GridColDef[];
  }>
> = (state, { payload: { tableName, companyId, tableColumns } }) => {
  let columns = {};
  const rowsPerPage: number = 25;
  const filters: any = { items: [] };
  const searchData = { hasVisibleSearchField: false, searchTerm: '' };

  if (tableColumns.length > 0) {
    columns = tableColumns.reduce((acc, item) => ({ ...acc, [item.field]: true }), {});
  }

  state[tableName].tableConfigurations[companyId] = {
    rowsPerPage,
    currentPage: 0,
    filters,
    columns,
    searchData,
  };

  sessionStorage.setItem(
    `ccp_${tableName}_table_configuration_columns_${companyId}`,
    JSON.stringify(state[tableName].tableConfigurations[companyId].columns),
  );

  sessionStorage.setItem(
    `ccp_${tableName}_table_configuration_rows_per_page_${companyId}`,
    JSON.stringify(rowsPerPage),
  );

  sessionStorage.setItem(
    `ccp_${tableName}_table_configuration_filters_${companyId}`,
    JSON.stringify(state[tableName].tableConfigurations[companyId].filters),
  );
};

export const toggleColumn: CaseReducer<
  TablesManagementState,
  PayloadAction<{ tableName: string; companyId: number; property: string; visible: boolean }>
> = (state, action) => {
  const { tableName, companyId, property, visible } = action.payload;
  if (!state[tableName]) {
    return;
  }
  state[tableName].tableConfigurations[companyId].columns[property] = visible;
  sessionStorage.setItem(
    `ccp_${tableName}_table_configuration_columns_${companyId}`,
    JSON.stringify(state[tableName].tableConfigurations[companyId].columns),
  );
};

export const updateRowsPerPage: CaseReducer<
  TablesManagementState,
  PayloadAction<{ tableName: string; companyId: number; rowsPerPage: number }>
> = (state, action) => {
  const { tableName, companyId, rowsPerPage } = action.payload;
  if (!state[tableName]) {
    return;
  }
  state[tableName].tableConfigurations[companyId].rowsPerPage = rowsPerPage;
  sessionStorage.setItem(
    `ccp_${tableName}_table_configuration_rows_per_page_${companyId}`,
    JSON.stringify(rowsPerPage),
  );
};

export const updateCurrentPage: CaseReducer<
  TablesManagementState,
  PayloadAction<{ tableName: string; companyId: number; currentPage: number }>
> = (state, action) => {
  const { tableName, companyId, currentPage } = action.payload;
  if (!state[tableName]) {
    return;
  }
  state[tableName].tableConfigurations[companyId].currentPage = currentPage;
};

export const updateFilters: CaseReducer<
  TablesManagementState,
  PayloadAction<{
    tableName: string;
    companyId: number;
    model: GridFilterModel;
  }>
> = (state, action) => {
  const { tableName, companyId, model } = action.payload;
  if (!state[tableName]) {
    return;
  }
  model.items.forEach(filter => {
    if (filter.value && typeof filter.value.getMonth === 'function') {
      filter.value = filter.value.toLocaleDateString('en-US');
    }
  });

  state[tableName].tableConfigurations[companyId].filters = model;

  sessionStorage.setItem(
    `ccp_${tableName}_table_configuration_filters_${companyId}`,
    JSON.stringify(state[tableName].tableConfigurations[companyId].filters),
  );
};

export const resetFilters: CaseReducer<
  TablesManagementState,
  PayloadAction<{ tableName: string; companyId: number }>
> = (state, action) => {
  const { tableName, companyId } = action.payload;
  if (!state[tableName]) {
    return;
  }
  state[tableName].tableConfigurations[companyId].filters = { items: [] };
  state[tableName].tableConfigurations[companyId].searchData = {
    hasVisibleSearchField: false,
    searchTerm: '',
  };
  if (state[tableName].tableConfigurations[companyId].currentPage !== 0) {
    state[tableName].tableConfigurations[companyId].currentPage = 0;
  }

  sessionStorage.setItem(
    `ccp_${tableName}_table_configuration_filters_${companyId}`,
    JSON.stringify(state[tableName].tableConfigurations[companyId].filters),
  );
};

export const updateSearchTerm: CaseReducer<
  TablesManagementState,
  PayloadAction<{ tableName: string; companyId: number; searchTerm: string }>
> = (state, action) => {
  const { tableName, companyId, searchTerm } = action.payload;
  if (!state[tableName]) {
    return;
  }
  state[tableName].tableConfigurations[companyId].searchData.searchTerm = searchTerm;
  if (state[tableName].tableConfigurations[companyId].currentPage !== 0) {
    state[tableName].tableConfigurations[companyId].currentPage = 0;
  }
};

export const updateSearchVisibility: CaseReducer<
  TablesManagementState,
  PayloadAction<{ tableName: string; companyId: number; hasVisibleSearchField: boolean }>
> = (state, action) => {
  const { tableName, companyId, hasVisibleSearchField } = action.payload;
  if (!state[tableName]) {
    return;
  }
  state[tableName].tableConfigurations[companyId].searchData.hasVisibleSearchField =
    hasVisibleSearchField;
};

export const resetSearchData: CaseReducer<
  TablesManagementState,
  PayloadAction<{ tableName: string; companyId: number }>
> = (state, action) => {
  const { tableName, companyId } = action.payload;
  if (!state[tableName]) {
    return;
  }
  state[tableName].tableConfigurations[companyId].searchData = {
    hasVisibleSearchField: false,
    searchTerm: '',
  };
  if (state[tableName].tableConfigurations[companyId].currentPage !== 0) {
    state[tableName].tableConfigurations[companyId].currentPage = 0;
  }
};
