import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { FetchingStatus, MarketingMaterialState } from 'types';
import { getFileCategory } from './getFileCategory';

export const getFileCategoryReducer = (
  builder: ActionReducerMapBuilder<MarketingMaterialState>,
) => {
  builder.addCase(getFileCategory.pending, (state, action) => {
    state.api.getFileCategory.fetchingStatus = FetchingStatus.PENDING;
    state.api.getFileCategory.error = null;
  });
  builder.addCase(getFileCategory.fulfilled, (state, action) => {
    state.api.getFileCategory.fetchingStatus = FetchingStatus.FULFILLED;
    state.api.getFileCategory.data = action.payload;
    state.api.getFileCategory.error = null;
  });
  builder.addCase(getFileCategory.rejected, (state, action) => {
    state.api.getFileCategory.fetchingStatus = FetchingStatus.REJECTED;
    state.api.getFileCategory.error = action.error;
  });
};
