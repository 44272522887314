import { useAppSelector } from 'store';
import { ICountriesList } from 'types';

export const useDialCodes = () => {
  const countries = useAppSelector(state => state.company.countriesList.data) || [];

  let prioritiesGroup = countries.reduce(function (result: any, a: ICountriesList) {
    result[a.priority] = result[a.priority] || [];
    result[a.priority].push({ dialCode: a.callingCode });
    return result;
  }, Object.create(null));

  let sortedCountries: { dialCode: string }[] = [];

  Object.keys(prioritiesGroup).forEach(group => {
    prioritiesGroup[group] = prioritiesGroup[group].sort(
      (a: { dialCode: string }, b: { dialCode: string }) => {
        return Number(a.dialCode) - Number(b.dialCode);
      },
    );
    sortedCountries = [...sortedCountries, ...prioritiesGroup[group]];
  });
  return sortedCountries;
};

export const useGetPhoneNumberValue = (phoneNumber: string) => {
  const dialCodes = useDialCodes();
  let dialCode = '';
  let phoneValue = phoneNumber;
  dialCodes.forEach(item => {
    const startsFromZero = phoneNumber.startsWith(item.dialCode.replace('+', '00'));
    if (phoneNumber.startsWith(item.dialCode) || startsFromZero) {
      dialCode = item.dialCode;
      phoneValue = phoneNumber.substring(
        startsFromZero ? item.dialCode.length + 1 : item.dialCode.length,
      );
    }
  });

  return [dialCode, phoneValue];
};
