import React from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

import { AdminUserModalProps } from 'types';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { useIntl } from 'react-intl';
import { CCPDefaultButton } from 'components';
import { default as theme } from '@theme/theme';

const AdminUserModal: React.FC<AdminUserModalProps> = ({ isOpen, handleClose }) => {
  const { formatMessage } = useIntl();
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <CloseIcon onClick={handleClose} className='dialog-close' />
      <DialogTitle>
        <Box className='dialog-icon'>
          <ErrorOutlineIcon htmlColor={theme.palette.error.main} />
        </Box>
        <Typography variant='h2'>
          {formatMessage({
            id: 'account.alert.assign_new_administrator.title',
            defaultMessage: 'Assign new administrator',
          })}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1'>
          {formatMessage({
            id: 'account.alert.assign_new_administrator.subtitle',
            defaultMessage: `You can not remove your account, since you’re only one Administrator in the system. Please, assign new Administrator`,
          })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <CCPDefaultButton onClick={handleClose} variant='outlined'>
          {formatMessage({ id: 'form.employee.cta.cancel', defaultMessage: 'Cancel' })}
        </CCPDefaultButton>
      </DialogActions>
    </Dialog>
  );
};

export default AdminUserModal;
