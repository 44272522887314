import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { getMarketingMaterials } from './getMarketingMaterials';
import { MarketingMaterialState } from '../../../interfaces';
import { FetchingStatus } from 'types';

export const getMarketingMaterialsExtraReducer = (
  builder: ActionReducerMapBuilder<MarketingMaterialState>,
) => {
  builder.addCase(getMarketingMaterials.pending, (state, action) => {
    state.api.getMarketingMaterials.fetchingStatus = FetchingStatus.PENDING;
    state.api.getMarketingMaterials.error = null;
  });
  builder.addCase(getMarketingMaterials.fulfilled, (state, action) => {
    state.api.getMarketingMaterials.fetchingStatus = FetchingStatus.FULFILLED;
    state.api.getMarketingMaterials.data = action.payload;
  });
  builder.addCase(getMarketingMaterials.rejected, (state, action) => {
    state.api.getMarketingMaterials.fetchingStatus = FetchingStatus.REJECTED;
    state.api.getMarketingMaterials.error = action.error;
  });
};
