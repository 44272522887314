import { Alert, Box, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { useIntl } from 'react-intl';
import { default as theme } from '@theme/theme';
import DomPurify from 'dompurify';
import { useMemo } from 'react';

const AlertToast: React.FC<{
  type: 'success' | 'error';
  msg: string | { id: string; defaultMessage: string };
  replacePlaceholders?: { [key: string]: string };
  hasBackdrop?: boolean;
}> = ({ type, msg, replacePlaceholders = null, hasBackdrop = false }) => {
  const { formatMessage } = useIntl();

  const santinizedText = useMemo(
    () =>
      DomPurify.sanitize(
        typeof msg === 'string' ? msg : formatMessage(msg, replacePlaceholders || {}),
      ),
    [formatMessage, msg, replacePlaceholders],
  );

  return (
    <Box className={hasBackdrop ? 'has-backdrop' : ''}>
      <Alert
        className='toast__wrapper_content'
        icon={
          type === 'success' ? (
            <CheckCircleRoundedIcon fontSize='small' htmlColor={theme.palette.success.main} />
          ) : (
            <ErrorRoundedIcon fontSize='small' htmlColor={theme.palette.error.main} />
          )
        }>
        <Typography
          variant='body1'
          dangerouslySetInnerHTML={{
            __html: santinizedText,
          }}
        />
      </Alert>
    </Box>
  );
};

const showToast = (
  type: 'success' | 'error',
  msg: string | { id: string; defaultMessage: string },
  autoClose?: number,
  replacePlaceholders?: { [key: string]: string },
  hasBackdrop?: boolean,
): void => {
  toast.success(
    <AlertToast
      type={type}
      msg={msg}
      replacePlaceholders={replacePlaceholders}
      hasBackdrop={hasBackdrop}
    />,
    {
      autoClose,
    },
  );
};

export default showToast;
