import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { getInvoicesExtraReducer } from './actions/async/getInvoices/getInvoices.reducer';

const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {},
  extraReducers: builder => {
    getInvoicesExtraReducer(builder);
  },
});

export const { name, actions } = invoicesSlice;
export const invoicesReducer = invoicesSlice.reducer;
