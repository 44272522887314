import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { AuthenticationState, TranslationsKeys } from 'types';

export const updateUsersLanguage: CaseReducer<
  AuthenticationState,
  PayloadAction<{ language: TranslationsKeys }>
> = (state, { payload }) => {
  if (state.userInfo?.language) {
    state.userInfo.language = payload.language;
  }
};
