import { Table, Paper, TableRow, TableCell, TableContainer } from '@mui/material';
import TableCellRow from './TableCellRow';
import { StyledTableHead, StyledTableBody, ShowAllDataWrapper } from './Table.styles';
import { useIntl } from 'react-intl';
import { useMemo, useState } from 'react';
import { useAppSelector } from 'store';
import { EmployeeManagementSelectors } from 'features/employeeManagement/store/employeeManagement.selectors';

export const MembershipTable = ({ employeeId }: { employeeId: number }) => {
  const intl = useIntl();
  const allEmployees = useAppSelector(EmployeeManagementSelectors.getTableOverviewEmployees);
  const allContracts = useAppSelector(state => state.contracts.getContracts.data);
  const [showAllData, setShowAllData] = useState(false);

  const currentEmployee = useMemo(
    () => allEmployees.find(item => item.id === employeeId),
    [allEmployees, employeeId],
  );

  const membershipData = useMemo(
    () =>
      currentEmployee?.licenses.map(licenseItem => {
        const newItem = { ...licenseItem };
        const currentContract = allContracts.find(
          contract => contract.id === licenseItem.contractId,
        );
        if (currentContract) {
          newItem['product'] = currentContract.products.reduce(
            (result: { name: string }[], currentProduct) => {
              result.push({ name: currentProduct.name });
              return result;
            },
            [],
          );
        }

        return newItem;
      }) || [],
    [currentEmployee, allContracts],
  );

  const data = useMemo(() => {
    const sortMembershipData = [...membershipData].sort((firstLicense, secondLicense) =>
      firstLicense.sequenceNumber > secondLicense.sequenceNumber ? -1 : 1,
    );
    if (showAllData || sortMembershipData?.length < 7) {
      return sortMembershipData;
    }
    return sortMembershipData.slice(0, 7);
  }, [showAllData, membershipData]);

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table>
        <StyledTableHead>
          <TableRow>
            <TableCell className='table_cell'>
              {intl.formatMessage({
                id: 'employee.membership.table.header.event',
                defaultMessage: 'Event',
              })}
            </TableCell>
            <TableCell className='table_cell'>
              {intl.formatMessage({
                id: 'employee.membership.table.header.date',
                defaultMessage: 'Date',
              })}
            </TableCell>
            <TableCell className='table_cell'>
              {intl.formatMessage({
                id: 'employee.membership.table.header.product',
                defaultMessage: 'Product',
              })}
            </TableCell>
          </TableRow>
        </StyledTableHead>
        <StyledTableBody>
          {data?.map(item => (
            <TableCellRow key={item.sequenceNumber} item={item} />
          ))}
        </StyledTableBody>
      </Table>
      {membershipData?.length > 7 && !showAllData && (
        <ShowAllDataWrapper onClick={() => setShowAllData(true)}>
          {intl.formatMessage({
            id: 'employee.membership.table.show_all',
            defaultMessage: 'Show all history',
          })}
        </ShowAllDataWrapper>
      )}
    </TableContainer>
  );
};
