import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { updateActiveCompany } from './actions/sync/updateActiveCompany.actions';
import { updateAssets } from './actions/sync/updateCompanyAssets.actions';
import { resetCompaniesData } from './actions/sync/resetCompaniesData.actions';
import { getCompanyExtraReducer } from './actions/async/getCompany/getCompany.reducer';
import { getCompaniesNamesExtraReducer } from './actions/async/getCompaniesNames/getCompaniesNames.reducers';
import { getCountriesListExtraReducer } from './actions/async/getCountriesList/getCountriesList.reducer';

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    updateActiveCompany,
    updateAssets,
    resetCompaniesData,
  },
  extraReducers: builder => {
    getCompanyExtraReducer(builder);
    getCompaniesNamesExtraReducer(builder);
    getCountriesListExtraReducer(builder);
  },
});

export const { name, actions } = companySlice;
export const companyReducer = companySlice.reducer;
