import { EmployeeBulkActions } from '@features/employeeManagement/components/EmployeeOverview/Modals/BulkActionsModal/interfaces';
import { EmployeeStatus } from 'types';

const getEmployeeInitStatus = (statusTransition: keyof typeof EmployeeBulkActions) => {
  switch (statusTransition) {
    case 'PAUSE':
      return [EmployeeStatus.ACTIVE];

    case 'EDIT_START_DATE':
      return [EmployeeStatus.PENDING];

    case 'CONFIRM':
      return [EmployeeStatus.APPLIED];

    case 'DENY':
      return [EmployeeStatus.APPLIED];

    case 'REACTIVATE':
      return [EmployeeStatus.PAUSED, EmployeeStatus.INACTIVE];

    case 'DEACTIVATE':
      return [EmployeeStatus.PAUSED, EmployeeStatus.ACTIVE];

    case 'REMOVE':
      return [EmployeeStatus.INACTIVE, EmployeeStatus.ACTIVE, EmployeeStatus.PENDING];

    case 'RESEND':
      return [
        EmployeeStatus.PENDING,
        EmployeeStatus.ACTIVE,
        EmployeeStatus.PAUSED,
        EmployeeStatus.INACTIVE,
      ];
    default:
      return [];
  }
};

export default getEmployeeInitStatus;
