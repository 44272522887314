export enum CompanyRole {
  Admin = 'Admin',
  Invoice = 'Invoice',
  Usermgmt = 'Usermgmt',
}

export type TranslationsKeys = 'en' | 'de';

export interface UserAccountPasswordProps {
  newPassword: string;
  currentPassword: string;
  confirmNewPassword: string;
}

export interface DeleteUserModalProps {
  email: string;
  isOpen: boolean;
  closeModal: () => void;
}

export interface VerifyCodeProps {
  email: string;
  updateStep: (step: number) => void;
}

export interface ChangeEmailProps {
  isOpen: boolean;
  closeModal: () => void;
  updateChangedEmail: (newEmail: string) => void;
}

export interface ChangeEmailModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

export type UpdateUserRequestBody = {
  name?: string;
  surname?: string;
  position?: string;
  companyId?: string;
  language?: string;
};

export type UserAccountInfoProps = {
  firstName: string;
  email: string;
  lastName: string;
  language: TranslationsKeys;
};

export type UserTypeShortcut = 'cu' | 'eu';
