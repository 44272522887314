import { GridColTypeDef, GridFilterInputValueProps, GridFilterItem } from '@mui/x-data-grid-pro';
import { DesktopDatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DefaultTextField } from 'components/Form/TextField/DefaultTextField.styles';
import { useMemo } from 'react';

const buildApplyDateFilterFn = (
  filterItem: GridFilterItem,
  compareFn: (value1: number, value2: number) => boolean,
  showTime: boolean = false,
) => {
  if (!filterItem.value) {
    return null;
  } else if (typeof filterItem.value.getTime === 'undefined') {
    filterItem.value = new Date(filterItem.value);
  }
  const filterValueMs = filterItem.value.getTime();

  return (value: string) => {
    if (!value) {
      return false;
    }

    // Make a copy of the date to not reset the hours in the original object
    const dateCopy = new Date(value);
    const valueDate = new Date(value);
    dateCopy.setHours(
      showTime ? valueDate.getHours() : 0,
      showTime ? valueDate.getMinutes() : 0,
      0,
      0,
    );
    const cellValueMs = dateCopy.getTime();

    return compareFn(cellValueMs, filterValueMs);
  };
};

export const getDateFilterOperators = (
  showTime: boolean = false,
): GridColTypeDef['filterOperators'] => [
  {
    value: 'is',
    getApplyFilterFn: filterItem =>
      buildApplyDateFilterFn(filterItem, (value1, value2) => value1 === value2, showTime),
    InputComponent: GridFilterDateInput,
    InputComponentProps: { showTime },
  },
  {
    value: 'not',
    getApplyFilterFn: filterItem =>
      buildApplyDateFilterFn(filterItem, (value1, value2) => value1 !== value2, showTime),
    InputComponent: GridFilterDateInput,
    InputComponentProps: { showTime },
  },
  {
    value: 'after',
    getApplyFilterFn: filterItem =>
      buildApplyDateFilterFn(filterItem, (value1, value2) => value1 > value2, showTime),
    InputComponent: GridFilterDateInput,
    InputComponentProps: { showTime },
  },
  {
    value: 'onOrAfter',
    getApplyFilterFn: filterItem =>
      buildApplyDateFilterFn(filterItem, (value1, value2) => value1 >= value2, showTime),
    InputComponent: GridFilterDateInput,
    InputComponentProps: { showTime },
  },
  {
    value: 'before',
    getApplyFilterFn: filterItem =>
      buildApplyDateFilterFn(filterItem, (value1, value2) => value1 < value2, showTime),
    InputComponent: GridFilterDateInput,
    InputComponentProps: { showTime },
  },
  {
    value: 'onOrBefore',
    getApplyFilterFn: filterItem =>
      buildApplyDateFilterFn(filterItem, (value1, value2) => value1 <= value2, showTime),
    InputComponent: GridFilterDateInput,
    InputComponentProps: { showTime },
  },
  {
    value: 'isEmpty',
    getApplyFilterFn:
      () =>
      ({ value }): boolean =>
        value == null,
    requiresFilterValue: false,
  },
  {
    value: 'isNotEmpty',
    getApplyFilterFn:
      () =>
      ({ value }): boolean =>
        value != null,
    requiresFilterValue: false,
  },
];

const GridFilterDateInput = ({
  item,
  showTime,
  applyValue,
  apiRef,
}: GridFilterInputValueProps & { showTime?: boolean }) => {
  const Component = showTime ? DateTimePicker : DesktopDatePicker;

  const handleFilterChange = (newValue: unknown) => {
    applyValue({ ...item, value: newValue });
  };

  const controlledValue = useMemo(() => (item.value ? new Date(item.value) : null), [item.value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Component
        value={controlledValue}
        format='dd.MM.yyyy'
        slots={{ textField: DefaultTextField }}
        slotProps={{
          textField: { label: apiRef.current.getLocaleText('filterPanelInputLabel') },
          inputAdornment: {
            sx: {
              '& .MuiButtonBase-root': {
                marginRight: -1,
              },
            },
          },
        }}
        onChange={handleFilterChange}
      />
    </LocalizationProvider>
  );
};
