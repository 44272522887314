import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CCPDatePicker, CCPDefaultButton, GoToSettings, Spacer } from 'components';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useIntl } from 'react-intl';
import { Employee, EmployeeStatus, FetchingStatus } from 'types';
import { format, isFuture, startOfTomorrow } from 'date-fns';
import { BulkActionsForm, EmployeeBulkActions } from './interfaces';
import EmployeeBulkActionsSchema from 'shared/schemas/EmployeeBulkActionsSchema';
import { PauseEmployeeSchema } from 'shared/schemas';
import {
  getDate,
  checkIsMonthPicker,
  prepareMaxDateForPicker,
  prepareMinDateForPicker,
} from 'utils/contractDependencies';
import { useAppSelector } from 'store';
import { getDayAfterToday } from 'utils/getDayAfterTomorrow';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';
import StartDatePicker from '@components/Form/StartDatePicker';
import { CCPLabelCheckbox } from '@components/Form/Checkbox/LabelCheckbox';
import RemoveEmployeeSchema from '@shared/schemas/RemoveEmployeeSchema';
import Loader from '@components/Loader';

const BulkActionsModal: React.FC<{
  transitionType: EmployeeStatus | string;
  actionType: string;
  employeesList: Employee[];
  employeesAffected: number;
  employeeInitStatus: EmployeeStatus[] | string[];
  hideStartDate?: boolean;
  entryDate?: string;
  open?: boolean;
  close?: () => void;
  onSubmit: ({
    initStatus,
    transition,
    startDate,
    endDate,
    resendLetters,
  }: {
    initStatus: EmployeeStatus[] | string[];
    transition: EmployeeStatus | string;
    startDate?: string;
    endDate?: string;
    resendLetters?: boolean;
  }) => void;
}> = ({
  transitionType,
  actionType,
  employeesList,
  employeesAffected,
  employeeInitStatus,
  entryDate,
  hideStartDate = false,
  open = false,
  close = () => {},
  onSubmit = () => {},
}) => {
  const { formatMessage } = useIntl();
  const activeContract = useAppSelector(state => state.contracts.euCreationCondition.data);
  const activationLetterDistributionList = useAppSelector(
    SettingsSelectors.emailActivationLetterDistributionList,
  );
  const { activationLetterRecipientMode } = useAppSelector(SettingsSelectors.settingsSettings);
  const fetchingStatus = useAppSelector(
    state => state.employeeManagement.api.changeEmployeesStatus.fetchingStatus,
  );
  const [employeesWithoutEmail, setEmployeesWithoutEmail] = useState<Employee[]>([]);
  const [resendFlag, setRsendFlag] = useState(false);

  const isMonthPicker = useMemo(
    () => checkIsMonthPicker(activeContract?.regularStartDate),
    [activeContract?.regularStartDate],
  );

  const isEuMode = useMemo(
    () => activationLetterRecipientMode.toLowerCase() === 'eu',
    [activationLetterRecipientMode],
  );
  const letterResendableCu = useMemo(
    () => !isEuMode && !activationLetterDistributionList.length,
    [isEuMode, activationLetterDistributionList],
  );
  const letterResendableEu = useMemo(
    () => isEuMode && employeesAffected - employeesWithoutEmail.length === 0,
    [isEuMode, employeesAffected, employeesWithoutEmail],
  );
  const isFlexibleDates = useMemo(
    () => activeContract?.regularStartDate === 'flexible',
    [activeContract?.regularStartDate],
  );
  const tomorrow = startOfTomorrow();

  useEffect(() => {
    if (
      employeesList.length > 0 &&
      (actionType === EmployeeBulkActions.RESEND ||
        actionType === EmployeeBulkActions.EDIT_START_DATE) &&
      isEuMode
    ) {
      setEmployeesWithoutEmail(employeesList.filter(employee => !employee.businessEmail));
    }
  }, [employeesList, actionType, activationLetterRecipientMode, isEuMode]);

  const handleClose = useCallback(() => {
    if (close) {
      close();
    }
  }, [close]);

  const handleModalSubmit = (data?: BulkActionsForm) => {
    if (data?.endDate && data?.startDate) {
      const startDate = format(
        getDate(data.startDate, activeContract?.regularStartDate),
        'yyyy-MM-dd',
      );
      const endDate = format(
        getDate(data.endDate, activeContract?.regularStartDate, true),
        'yyyy-MM-dd',
      );
      onSubmit({
        initStatus: employeeInitStatus,
        transition: transitionType,
        startDate,
        endDate,
      });
    } else if (data?.startDate) {
      const startDate = format(
        getDate(data.startDate, activeContract?.regularStartDate),
        'yyyy-MM-dd',
      );
      onSubmit({
        initStatus: employeeInitStatus,
        transition: transitionType,
        startDate,
        resendLetters: resendFlag,
      });
    } else {
      if (transitionType === EmployeeStatus.UNASSIGNED) {
        onSubmit({
          initStatus: employeeInitStatus,
          transition: transitionType,
          startDate: '',
          endDate: '',
        });
      } else {
        onSubmit({ initStatus: employeeInitStatus, transition: transitionType });
      }
    }

    if (resendFlag) setRsendFlag(false);
  };
  const maxEndDate = useMemo(
    () => prepareMaxDateForPicker(activeContract?.endDate, isMonthPicker, 36),
    [activeContract?.endDate, isMonthPicker],
  );
  const dayAfterTomorrow = getDayAfterToday(2);

  const prefilledStartDate = useMemo(() => {
    if (transitionType === EmployeeStatus.PAUSED || transitionType === EmployeeStatus.UNASSIGNED) {
      return prepareMinDateForPicker(tomorrow, isMonthPicker);
    }
    if (transitionType === EmployeeStatus.PENDING) {
      const prefilled =
        entryDate && isFuture(new Date(entryDate))
          ? prepareMinDateForPicker(new Date(entryDate), isMonthPicker, true)
          : prepareMinDateForPicker(tomorrow, isMonthPicker);
      return prefilled ? format(prefilled, 'yyyy-MM-dd') : undefined;
    }
    return null;
  }, [transitionType, tomorrow, entryDate, isMonthPicker]);

  const prefilledEndDate = useMemo(() => {
    if (transitionType === EmployeeStatus.PAUSED) {
      const currentEndDate = prepareMinDateForPicker(dayAfterTomorrow, isMonthPicker);
      if (
        isMonthPicker &&
        currentEndDate &&
        prefilledStartDate &&
        new Date(prefilledStartDate).getTime() === currentEndDate.getTime()
      ) {
        return new Date(currentEndDate.getFullYear(), currentEndDate.getMonth() + 1, 0);
      }
      return currentEndDate;
    }
    return null;
  }, [isMonthPicker, dayAfterTomorrow, prefilledStartDate, transitionType]);

  const getSchema = useCallback(() => {
    if (transitionType === EmployeeStatus.PAUSED) {
      return PauseEmployeeSchema(maxEndDate);
    }
    if (transitionType === EmployeeStatus.UNASSIGNED) {
      return RemoveEmployeeSchema(
        prepareMinDateForPicker(tomorrow, isMonthPicker) || tomorrow,
        prepareMaxDateForPicker(activeContract?.endDate, isMonthPicker),
        hideStartDate,
      );
    }
    return EmployeeBulkActionsSchema;
  }, [activeContract?.endDate, isMonthPicker, hideStartDate, maxEndDate, tomorrow, transitionType]);

  const methods = useForm<BulkActionsForm>({
    defaultValues: {
      startDate: prefilledStartDate,
      endDate: prefilledEndDate,
      removeEmployeeWarning: false,
    },
    mode: 'all',
    resolver: yupResolver(getSchema()),
  });
  const { control, handleSubmit, formState, watch, trigger, setValue } = methods;
  const { isValid } = formState;
  const noAffectedEmployees = employeesAffected === 0;

  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');
  useEffect(() => {
    trigger('endDate');
  }, [watchStartDate, trigger]);
  useEffect(() => {
    trigger('startDate');
    if (watchEndDate && activeContract?.regularStartDate === '1st_of_month') {
      if (maxEndDate.getTime() !== new Date(watchEndDate).getTime()) {
        const currentEndDate = new Date(watchEndDate);
        const newEndDate = new Date(currentEndDate.getFullYear(), currentEndDate.getMonth() + 1, 0);
        if (newEndDate.getTime() !== new Date(watchEndDate).getTime()) {
          setValue('endDate', newEndDate);
        }
      }
    }
  }, [watchEndDate, trigger, setValue, maxEndDate, activeContract?.regularStartDate]);

  if (actionType === EmployeeBulkActions.RESEND && letterResendableCu) {
    return <GoToSettings isOpen={open} isResendLetted handleClose={handleClose} />;
  }

  return (
    <>
      <Loader show={fetchingStatus === FetchingStatus.PENDING} />
      <FormProvider {...methods}>
        <Dialog
          open={open}
          onClose={handleClose}
          scroll='paper'
          component='form'
          onSubmit={handleSubmit(handleModalSubmit)}>
          {/* Actions */}
          {actionType === EmployeeBulkActions.RESEND && (
            <>
              <DialogTitle>
                {formatMessage({
                  id: 'employee.bulk.actions.modal.resend_letter.title',
                  defaultMessage: 'Resend activation letter',
                })}
              </DialogTitle>
              <DialogContent>
                {activationLetterRecipientMode === 'eu' ? (
                  <Typography variant='subtitle1'>
                    {formatMessage(
                      {
                        id: 'employee.bulk.actions.modal.content.resend_letter.eu',
                        defaultMessage:
                          '{amount} activation letters will be send directly to the employees.',
                      },
                      { amount: employeesAffected - employeesWithoutEmail.length },
                    )}
                  </Typography>
                ) : (
                  <>
                    <Typography variant='subtitle1'>
                      {formatMessage(
                        {
                          id: 'employee.bulk.actions.modal.content.resend_letter.cu',
                          defaultMessage:
                            '{amount} letters will be send to the following portal users:',
                        },
                        { amount: employeesAffected - employeesWithoutEmail.length },
                      )}
                    </Typography>
                    <Spacer size='xl' />
                    {activationLetterDistributionList.map(cu => (
                      <Typography key={cu} variant='h3'>
                        {cu}
                      </Typography>
                    ))}
                  </>
                )}
                {isEuMode && employeesWithoutEmail.length > 0 ? (
                  <>
                    <Spacer size='lg' />
                    <Typography variant='subtitle1'>
                      {formatMessage(
                        {
                          id: 'employee.bulk.actions.modal.resend_letter.employees_without_email',
                          defaultMessage:
                            '{amount} of the EUs do not have an email and that the letter will not be resend for these EUs.',
                        },
                        { amount: employeesWithoutEmail.length },
                      )}
                    </Typography>
                    <Spacer size='md' />
                    <Typography variant='h3'>
                      {employeesWithoutEmail
                        .map(employee => `${employee.firstName || ''} ${employee.lastName || ''}`)
                        .join(', ')}
                    </Typography>
                    <Spacer size='xl' />
                  </>
                ) : (
                  <Spacer size='xl' />
                )}
              </DialogContent>
              <DialogActions>
                <CCPDefaultButton variant='outlined' onClick={handleClose}>
                  {formatMessage({
                    id: 'form.employee.cta.cancel',
                    defaultMessage: 'Cancel',
                  })}
                </CCPDefaultButton>
                <CCPDefaultButton disabled={noAffectedEmployees} variant='contained' type='submit'>
                  {formatMessage({
                    id: 'form.employee.cta.edit_start_date',
                    defaultMessage: 'Confirm',
                  })}
                </CCPDefaultButton>
              </DialogActions>
            </>
          )}

          {actionType === EmployeeBulkActions.PAUSE && (
            <>
              <DialogTitle>
                {formatMessage({
                  id: 'employee.bulk.actions.modal.pause_membership.title',
                  defaultMessage: 'Pause employee membership',
                })}
              </DialogTitle>
              <DialogContent>
                <Typography variant='subtitle1'>
                  {formatMessage(
                    {
                      id: 'employee.bulk.actions.modal.content',
                      defaultMessage:
                        '{amount} employees will be affected by this action. Proceed?',
                    },
                    { amount: employeesAffected },
                  )}
                </Typography>
                <Spacer size='2xl' />
                <Stack direction='row' justifyContent='space-between' spacing={2}>
                  <CCPDatePicker
                    label={
                      isFlexibleDates
                        ? formatMessage({
                            id: 'form.employee.field.start_date.label',
                            defaultMessage: 'Start date',
                          })
                        : formatMessage({
                            id: 'form.employee.field.start_date_single.label',
                            defaultMessage: 'Start month',
                          })
                    }
                    name='startDate'
                    control={control}
                    width={254}
                    minDate={prepareMinDateForPicker(tomorrow, isMonthPicker)}
                    maxDate={prepareMaxDateForPicker(activeContract?.endDate, isMonthPicker)}
                    isMonthPicker={isMonthPicker}
                  />
                  <CCPDatePicker
                    label={
                      isFlexibleDates
                        ? formatMessage({
                            id: 'form.employee.field.end_date.label',
                            defaultMessage: 'End date',
                          })
                        : formatMessage({
                            id: 'form.employee.field.end_date_single.label',
                            defaultMessage: 'End month',
                          })
                    }
                    name='endDate'
                    control={control}
                    width={254}
                    minDate={prepareMinDateForPicker(dayAfterTomorrow, isMonthPicker)}
                    maxDate={maxEndDate}
                    isMonthPicker={isMonthPicker}
                  />
                </Stack>
              </DialogContent>
              <DialogActions>
                <CCPDefaultButton variant='outlined' onClick={handleClose}>
                  {formatMessage({
                    id: 'form.employee.cta.cancel',
                    defaultMessage: 'Cancel',
                  })}
                </CCPDefaultButton>
                <CCPDefaultButton
                  disabled={!isValid || noAffectedEmployees}
                  variant='contained'
                  type='submit'>
                  {formatMessage({
                    id: 'form.employee.cta.confirm_pause',
                    defaultMessage: 'Confirm & pause',
                  })}
                </CCPDefaultButton>
              </DialogActions>
            </>
          )}

          {actionType === EmployeeBulkActions.EDIT_START_DATE && (
            <>
              <DialogTitle>
                {formatMessage({
                  id: 'employee.bulk.actions.modal.edit_start_date.title',
                  defaultMessage: 'Edit start date',
                })}
              </DialogTitle>

              <DialogContent>
                <Typography variant='subtitle1'>
                  {formatMessage(
                    {
                      id: 'employee.bulk.actions.modal.content',
                      defaultMessage:
                        '{amount} employees will be affected by this action. Proceed?',
                    },
                    { amount: employeesAffected },
                  )}
                </Typography>
                <Spacer size='2xl' />
                <Stack direction='row' justifyContent='space-between' spacing={2}>
                  <StartDatePicker
                    control={control}
                    name='startDate'
                    isMonthPicker={isMonthPicker}
                    minimumDate={prepareMinDateForPicker(tomorrow, isMonthPicker)}
                    maximumDate={prepareMaxDateForPicker(activeContract?.endDate, isMonthPicker)}
                    label={`${formatMessage({
                      id: 'form.employee.field.start_date.label',
                      defaultMessage: 'Start date',
                    })}`}
                    width={254}
                  />
                </Stack>
                <Spacer size='md' />
                <FormControlLabel
                  disabled={letterResendableCu || letterResendableEu}
                  control={
                    <Checkbox value={resendFlag} onChange={() => setRsendFlag(flag => !flag)} />
                  }
                  label={
                    <Typography variant='h3'>
                      {isEuMode
                        ? formatMessage({
                            id: 'form.employee.start_date_change.checkbox.label.eu',
                            defaultMessage: 'Resend activation letter directly to the employee',
                          })
                        : formatMessage({
                            id: 'form.employee.start_date_change.checkbox.label.cu',
                            defaultMessage: 'Resend activation letter to the portal users',
                          })}
                    </Typography>
                  }
                />
                {letterResendableCu && (
                  <Typography variant='subtitle1'>
                    *{' '}
                    {formatMessage({
                      id: 'view.user_management.cant_create_user.description',
                      defaultMessage:
                        'Activation letter should be send to company user but company users list is empty',
                    })}
                  </Typography>
                )}
                {isEuMode && (
                  <Typography variant='subtitle1'>
                    {employeesAffected - employeesWithoutEmail.length === 0
                      ? formatMessage({
                          id: 'view.user_management.bulk.resend_letter.not_possible.hint',
                          defaultMessage:
                            'Cannot resend letters, because none of the selected users has an email address',
                        })
                      : formatMessage({
                          id: 'view.user_management.bulk.resend_letter.possible.hint',
                          defaultMessage:
                            'If the employee has an e-mail, we can resend the activation letter',
                        })}
                  </Typography>
                )}
              </DialogContent>
              <DialogActions>
                <CCPDefaultButton variant='outlined' onClick={handleClose}>
                  {formatMessage({
                    id: 'form.employee.cta.cancel',
                    defaultMessage: 'Cancel',
                  })}
                </CCPDefaultButton>
                <CCPDefaultButton
                  disabled={!isValid || noAffectedEmployees}
                  variant='contained'
                  type='submit'>
                  {formatMessage({
                    id: 'form.employee.cta.edit_start_date',
                    defaultMessage: 'Confirm',
                  })}
                </CCPDefaultButton>
              </DialogActions>
            </>
          )}

          {actionType === EmployeeBulkActions.REACTIVATE && (
            <>
              <DialogTitle>
                {formatMessage({
                  id: 'employee.bulk.actions.modal.reactivate.title',
                  defaultMessage: 'Reactivate employee membership',
                })}
              </DialogTitle>

              <DialogContent>
                <Typography variant='subtitle1'>
                  {formatMessage(
                    {
                      id: 'employee.bulk.actions.modal.content',
                      defaultMessage:
                        '{amount} employees will be affected by this action. Proceed?',
                    },
                    { amount: employeesAffected },
                  )}
                </Typography>
                <Spacer size='2xl' />
                <Stack direction='row' justifyContent='space-between' spacing={2}>
                  <StartDatePicker
                    control={control}
                    name='startDate'
                    isMonthPicker={isMonthPicker}
                    minimumDate={prepareMinDateForPicker(tomorrow, isMonthPicker)}
                    maximumDate={prepareMaxDateForPicker(activeContract?.endDate, isMonthPicker)}
                    label={formatMessage({
                      id: 'form.employee.field.select_reactivation_date.label',
                      defaultMessage: 'Select reactivation date',
                    })}
                    width={254}
                  />
                </Stack>
              </DialogContent>

              <DialogActions>
                <CCPDefaultButton variant='outlined' onClick={handleClose}>
                  {formatMessage({
                    id: 'form.employee.cta.cancel',
                    defaultMessage: 'Cancel',
                  })}
                </CCPDefaultButton>
                <CCPDefaultButton
                  disabled={!isValid || noAffectedEmployees}
                  variant='contained'
                  type='submit'>
                  {formatMessage({
                    id: 'form.employee.cta.confirm_reactivate',
                    defaultMessage: 'Confirm & Reactive',
                  })}
                </CCPDefaultButton>
              </DialogActions>
            </>
          )}

          {actionType === EmployeeBulkActions.CONFIRM && (
            <>
              <DialogTitle>
                {formatMessage({
                  id: 'employee.bulk.actions.modal.confirm.title',
                  defaultMessage: 'Confirm employee membership',
                })}
              </DialogTitle>

              <DialogContent>
                <Typography variant='subtitle1'>
                  {formatMessage(
                    {
                      id: 'employee.bulk.actions.modal.content',
                      defaultMessage:
                        '{amount} employees will be affected by this action. Proceed?',
                    },
                    { amount: employeesAffected },
                  )}
                </Typography>
                <Spacer size='2xl' />
                <Stack direction='row' justifyContent='space-between' spacing={2}>
                  <StartDatePicker
                    control={control}
                    name='startDate'
                    minimumDate={prepareMinDateForPicker(tomorrow, isMonthPicker)}
                    maximumDate={prepareMaxDateForPicker(activeContract?.endDate, isMonthPicker)}
                    isMonthPicker={isMonthPicker}
                    label={`${formatMessage({
                      id: 'form.employee.field.start_date.label',
                      defaultMessage: 'Start date',
                    })}`}
                    width={254}
                  />
                </Stack>
              </DialogContent>
              <DialogActions>
                <CCPDefaultButton variant='outlined' onClick={handleClose}>
                  {formatMessage({
                    id: 'form.employee.cta.cancel',
                    defaultMessage: 'Cancel',
                  })}
                </CCPDefaultButton>
                <CCPDefaultButton
                  disabled={!isValid || noAffectedEmployees}
                  variant='contained'
                  type='submit'>
                  {formatMessage({
                    id: 'form.employee.cta.confirm_request',
                    defaultMessage: 'Confirm request',
                  })}
                </CCPDefaultButton>
              </DialogActions>
            </>
          )}

          {actionType === EmployeeBulkActions.DENY && (
            <>
              <DialogTitle>
                {formatMessage({
                  id: 'employee.bulk.actions.modal.deny.title',
                  defaultMessage: 'Deny employee membership',
                })}
              </DialogTitle>
              <DialogContent>
                <Typography variant='subtitle1'>
                  {formatMessage(
                    {
                      id: 'employee.bulk.actions.modal.content',
                      defaultMessage:
                        '{amount} employees will be affected by this action. Proceed?',
                    },
                    { amount: employeesAffected },
                  )}
                </Typography>
              </DialogContent>
              <DialogActions>
                <CCPDefaultButton variant='outlined' onClick={handleClose}>
                  {formatMessage({
                    id: 'form.employee.cta.cancel',
                    defaultMessage: 'Cancel',
                  })}
                </CCPDefaultButton>
                <CCPDefaultButton
                  disabled={!isValid || noAffectedEmployees}
                  variant='contained'
                  type='submit'>
                  {formatMessage({
                    id: 'form.employee.cta.deny_request',
                    defaultMessage: 'Deny request',
                  })}
                </CCPDefaultButton>
              </DialogActions>
            </>
          )}

          {actionType === EmployeeBulkActions.DEACTIVATE && (
            <>
              <DialogTitle>
                {formatMessage({
                  id: 'employee.bulk.actions.modal.deactivate.title',
                  defaultMessage: 'Deactivate employee membership',
                })}
              </DialogTitle>
              <DialogContent>
                <Typography variant='subtitle1'>
                  {formatMessage(
                    {
                      id: 'employee.bulk.actions.modal.content',
                      defaultMessage:
                        '{amount} employees will be affected by this action. Proceed?',
                    },
                    { amount: employeesAffected },
                  )}
                </Typography>
                <Spacer size='xl' />
                <CCPDatePicker
                  control={control}
                  name='startDate'
                  label={`${formatMessage({
                    id: 'form.employee.field.select_end_date.label',
                    defaultMessage: 'Select end date',
                  })}`}
                  width={254}
                  minDate={prepareMinDateForPicker(tomorrow, isMonthPicker)}
                  maxDate={prepareMaxDateForPicker(activeContract?.endDate, isMonthPicker)}
                  isMonthPicker={isMonthPicker}
                />
              </DialogContent>
              <DialogActions>
                <CCPDefaultButton variant='outlined' onClick={handleClose}>
                  {formatMessage({
                    id: 'form.employee.cta.cancel',
                    defaultMessage: 'Cancel',
                  })}
                </CCPDefaultButton>
                <CCPDefaultButton
                  disabled={!isValid || noAffectedEmployees}
                  variant='contained'
                  type='submit'>
                  {formatMessage({
                    id: 'form.employee.cta.confirm_inactivate',
                    defaultMessage: 'Confirm & inactivate',
                  })}
                </CCPDefaultButton>
              </DialogActions>
            </>
          )}

          {actionType === EmployeeBulkActions.REMOVE && (
            <>
              <DialogTitle>
                {formatMessage({
                  id: 'employee.modal.bulk_remove.title',
                  defaultMessage: 'Remove employees from company',
                })}
              </DialogTitle>
              <DialogContent>
                <Typography variant='subtitle1'>
                  {formatMessage(
                    {
                      id: 'employee.bulk.actions.modal.content',
                      defaultMessage:
                        '{amount} employees will be affected by this action. Proceed?',
                    },
                    { amount: employeesAffected },
                  )}
                </Typography>
                <Spacer size='xl' />
                <Typography variant='subtitle1'>
                  {hideStartDate
                    ? formatMessage({
                        id: 'employee.modal.bulk_remove_immediately.content',
                        defaultMessage: `By clicking on Confirm, the employees are immediately removed from the employee list and no Hansefit membership is created. All employee data will be irrevocably deleted.`,
                      })
                    : employeesList.some(employee => employee.status === EmployeeStatus.PENDING)
                    ? activeContract?.regularStartDate === 'flexible'
                      ? formatMessage({
                          id: 'employee.modal.bulk_remove_all_flat.content',
                          defaultMessage: `By clicking on Confirm, the employees are removed from the employee list on the selected date and the Hansefit memberships are thus terminated on this date. Employees whose Hansefit membership has not yet started are immediately removed from the employee list and no Hansefit membership is created. All employee data will be irrevocably deleted.`,
                        })
                      : formatMessage({
                          id: 'employee.modal.bulk_remove_all_single.content',
                          defaultMessage: `By clicking on Confirm, the employees are removed from the employee list on the first day of the selected month and the Hansefit memberships are thus terminated on this date. Employees whose Hansefit membership has not yet started are immediately removed from the employee list and no Hansefit membership is created. All employee data will be irrevocably deleted.`,
                        })
                    : activeContract?.regularStartDate === 'flexible'
                    ? formatMessage({
                        id: 'employee.modal.bulk_remove_flat.content',
                        defaultMessage: `By clicking on Confirm, the employees are removed from the employee list on the selected date and the Hansefit memberships are thus terminated on this date. All employee data will be irrevocably deleted.`,
                      })
                    : formatMessage({
                        id: 'employee.modal.bulk_remove_single.content',
                        defaultMessage: `By clicking on Confirm, the employees are removed from the employee list on the first day of the selected month and the Hansefit memberships are thus terminated on this date. All employee data will be irrevocably deleted.`,
                      })}
                </Typography>
                <Spacer size='xl' />
                {!hideStartDate && (
                  <>
                    <Stack direction='row' justifyContent='space-between' spacing={2}>
                      <CCPDatePicker
                        label={`${formatMessage({
                          id: 'form.employee.field.last_date.label',
                          defaultMessage: 'Last date',
                        })}`}
                        name='startDate'
                        control={control}
                        width={254}
                        minDate={prepareMinDateForPicker(tomorrow, isMonthPicker)}
                        maxDate={prepareMaxDateForPicker(activeContract?.endDate, isMonthPicker)}
                        isMonthPicker={isMonthPicker}
                      />
                    </Stack>
                    <Spacer size='xl' />
                  </>
                )}
                <CCPLabelCheckbox
                  control={control}
                  name='removeEmployeeWarning'
                  label={
                    hideStartDate
                      ? formatMessage({
                          id: 'form.employee.field.bulk_remove_immediately_checkbox.label',
                          defaultMessage: `I am aware that I cannot undo this action.`,
                        })
                      : formatMessage({
                          id: 'form.employee.field.bulk_remove_checkbox.label',
                          defaultMessage: `I am aware that this employee can not be restored when I confirm this action`,
                        })
                  }
                />
              </DialogContent>
              <DialogActions>
                <CCPDefaultButton variant='outlined' onClick={handleClose}>
                  {formatMessage({
                    id: 'form.employee.cta.cancel',
                    defaultMessage: 'Cancel',
                  })}
                </CCPDefaultButton>
                <CCPDefaultButton
                  disabled={!isValid}
                  variant='contained'
                  color='error'
                  type='submit'>
                  {formatMessage({
                    id: 'form.employee.cta.confirm_remove',
                    defaultMessage: 'Confirm',
                  })}
                </CCPDefaultButton>
              </DialogActions>
            </>
          )}
        </Dialog>
      </FormProvider>
    </>
  );
};

export default BulkActionsModal;
