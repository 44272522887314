import { GridToolbarContainer } from '@mui/x-data-grid-pro';
import { styled, Chip, MenuItem, Select, Box } from '@mui/material';

import { StatusChipProps } from 'types';

export const InvoicesTableWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  background: theme.palette.background.paper,
  borderRadius: 4,
  position: 'relative',

  '.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
    outline: 'none !important',
  },

  '.MuiDataGrid-footerContainer': {
    border: 'none',
    minHeight: 'unset',
  },

  '.MuiDataGrid-columnHeaders, .MuiDataGrid-cell': {
    borderBottom: `2px solid ${theme.palette.primaryLight.main} !important`,
  },

  '.MuiDataGrid-columnHeaderTitle': {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.57,
    color: theme.palette.neutralDark.main,
  },

  '.table': {
    '&__id': {
      fontSize: 16,
      lineHeight: 1.38,
      color: theme.palette.neutralDark.contrastText,
    },

    '&__period': {
      fontFamily: 'Roboto-Bold',
      fontSize: 16,
      lineHeight: 1.38,
      color: theme.palette.neutralDark.contrastText,
    },

    // '&__date': {
    //   paddingRight: 16,
    //   gap: 16,
    //   width: '100%',
    //   display: 'flex',
    //   alignItems: 'center',
    //   justifyContent: 'space-between',

    //   '&_left': {
    //     gap: 38,
    //     display: 'flex',
    //     alignItems: 'center',

    //     '&_text': {
    //       fontSize: 16,
    //       lineHeight: 1.38,
    //       color: theme.palette.neutralDark.contrastText,
    //     },

    //     div: {
    //       padding: '2px 8px',
    //       background: theme.palette.success.main,
    //       borderRadius: 4,
    //       fontFamily: 'Roboto-Medium',
    //       fontSize: 14,
    //       fontWeight: 500,
    //       lineHeight: 1.29,
    //       color: theme.palette.primary.contrastText,
    //     },
    //   },

    //   '&_right': {
    //     gap: 39,
    //     display: 'flex',
    //     alignItems: 'center',

    //     '&_text': {
    //       fontFamily: 'Roboto-Medium',
    //       fontSize: 16,
    //       fontWeight: 500,
    //       lineHeight: 1.38,
    //       color: theme.palette.neutralDark.main,
    //     },

    //     div: {
    //       gap: 6,
    //       cursor: 'pointer',
    //       display: 'flex',
    //       alignItems: 'center',
    //     },
    //   },
    // },
  },
}));

export const FilterLoaderWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 114,
  background: theme.palette.transparent.dark,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  paddingTop: 150,

  '.title': {
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.38,
    color: theme.palette.neutralDark.contrastText,
    margin: '36px 0 16px',
  },

  '.text': {
    fontSize: 16,
    lineHeight: 1.38,
    color: theme.palette.neutralDark.main,
  },
}));

export const GridToolbarWrapper = styled(GridToolbarContainer)(({ theme }) => ({
  padding: '12px 14px',
  borderBottom: `2px solid ${theme.palette.primaryLight.main}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '.filters': {
    display: 'flex',
    alignItems: 'center',

    '&__left': {
      gap: 8,
      display: 'flex',
      alignItems: 'center',

      '&_apply': {
        maxHeight: 40,
        background: theme.palette.primaryLight.main,
        fontFamily: 'Roboto-Medium',
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 1.38,
        color: theme.palette.neutralDark.main,
        padding: '9px 15px 9px 17px',
      },
    },

    '&__control': {
      color: theme.palette.neutralDark.main,
      marginLeft: 44,
    },
  },

  '.search': {
    display: 'flex',
    alignItems: 'center',
    marginRight: 25,
  },
}));

export const SelectWrapper = styled(Select)(() => ({
  width: 200,

  '.MuiSelect-outlined': {
    padding: '8px 14px',

    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 0.15,
  },
}));

export const MenuItemWrapper = styled(MenuItem)(({ theme }) => ({
  fontSize: 16,
  lineHeight: 1.38,
  color: theme.palette.neutralDark.contrastText,
  padding: '8px 16px',
}));

export const StatusChip = styled(Chip)<StatusChipProps>(({ background, theme }) => ({
  background,
  borderRadius: 4,
  padding: '2px 10px',
  fontFamily: 'Roboto-Medium',
  fontSize: 14,
  fontWeight: 500,
  lineHeight: 1.29,
  color: theme.palette.primary.contrastText,
  textTransform: 'uppercase',
  height: 'auto',

  span: {
    padding: 0,
  },
}));

export const NoResultsWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  minHeight: '300px',
  background: theme.palette.background.paper,

  '.container': {
    maxWidth: 304,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    svg: {
      width: 36,
      height: 36,
    },

    '&__title': {
      fontFamily: 'NutmegHeadline-UltraBlack',
      fontSize: 20,
      fontWeight: 900,
      lineHeight: 1.6,
      color: theme.palette.neutralDark.contrastText,
      textTransform: 'uppercase',
      margin: '24px 0 16px',
      textAlign: 'center',
    },

    '&__text': {
      fontSize: 16,
      lineHeight: 1.38,
      color: theme.palette.neutralDark.main,
      textAlign: 'center',
    },
  },
}));
