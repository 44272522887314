import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { GuestFormContainer, GuestHeader, Spacer } from 'components';
import Error from 'features/selfRegistration/components/Error';
import SelfRegistrationStepper from 'features/selfRegistration/components/Stepper';
import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { FetchingStatus, SELF_REGISTRATION_STEPS, SignUpKeyRequestParams } from 'types';
import { AddPersonalInfo, SetNewPassword, VerifyEmail } from '../../components/Steps';
import { SelfRegistrationActions } from '../../store';
import { SelfRegistrationSelectors } from '../../store/selfRegistration.selectors';
import { SetNewPasswordForm } from '../../components/Steps/SetNewPassword/interfaces';

const SelfRegistrationPage = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const api = useAppSelector(SelfRegistrationSelectors.api);
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const step = useAppSelector(SelfRegistrationSelectors.currentStep);
  const personalInformation = useAppSelector(SelfRegistrationSelectors.getAddPersonalInfoStep);

  const [passGetSignupData, setPassGetSignupData] = useState<boolean>(false);
  const [passRegisterAdminUser, setPassRegisterAdminUser] = useState<boolean>(false);

  const [isLoadingRegisterAdminUser, setIsLoadingRegisterAdminUser] = useState<boolean>(true);
  const [isLoadingGetSignupData, setIsLoadingGetSignupData] = useState<boolean>(true);

  const handleReset = useCallback(() => {
    dispatch(SelfRegistrationActions.resetProcess());
  }, [dispatch]);

  const handleSubmit = useCallback(
    async (data: SetNewPasswordForm) => {
      if (personalInformation) {
        await dispatch(
          SelfRegistrationActions.registerAdminUser({
            password: data.password,
            name: personalInformation.name,
            surname: personalInformation.surname,
            email: personalInformation.email,
            position: personalInformation.position,
            signupKey: queryParams.signUpKey as string,
            companyId: queryParams.companyId as string,
          }),
        );
        dispatch(SelfRegistrationActions.setStep(SELF_REGISTRATION_STEPS.VERIFY_EMAIL));
      }
    },
    [queryParams, dispatch, personalInformation],
  );

  const handleBack = useCallback(() => {
    dispatch(SelfRegistrationActions.setStep(SELF_REGISTRATION_STEPS.ADD_PERSONAL_INFO));
  }, [dispatch]);

  useEffect(() => {
    if (api.getSignUpKeyData.fetchingStatus === FetchingStatus.IDLE) {
      const signUpDataRequestParams: SignUpKeyRequestParams = {
        signUpKey: queryParams.signUpKey as string,
        companyId: queryParams.companyId as string,
      };

      dispatch(SelfRegistrationActions.getSignUpKeyData(signUpDataRequestParams));
    }
  }, [api, dispatch, queryParams]);

  useEffect(() => {
    setIsLoadingGetSignupData(
      api.getSignUpKeyData.fetchingStatus === FetchingStatus.IDLE ||
        api.getSignUpKeyData.fetchingStatus === FetchingStatus.PENDING,
    );
  }, [api]);

  useEffect(() => {
    setIsLoadingRegisterAdminUser(
      api.registerAdminUser.fetchingStatus === FetchingStatus.IDLE ||
        api.registerAdminUser.fetchingStatus === FetchingStatus.PENDING,
    );
  }, [api]);

  useEffect(() => {
    setPassGetSignupData(api.getSignUpKeyData.fetchingStatus !== FetchingStatus.REJECTED);
  }, [api]);

  useEffect(() => {
    setPassRegisterAdminUser(api.registerAdminUser.fetchingStatus === FetchingStatus.FULFILLED);
  }, [api]);

  return (
    <Box>
      <GuestHeader />
      <Spacer height={44} />
      <GuestFormContainer>
        <Typography variant={'body1'} className={clsx('header')}>
          {intl
            .formatMessage({
              id: 'self_registration.header.title',
              defaultMessage: 'Self-registration',
            })
            .toUpperCase()}
        </Typography>

        <Spacer height={32} />

        {passGetSignupData && (
          <>
            {!isLoadingRegisterAdminUser && passRegisterAdminUser && (
              <>
                {' '}
                <SelfRegistrationStepper activeStep={step} />
                <Spacer height={16} />
              </>
            )}

            {step === SELF_REGISTRATION_STEPS.ADD_PERSONAL_INFO && <AddPersonalInfo />}

            {step === SELF_REGISTRATION_STEPS.SET_NEW_PASSWORD && (
              <SetNewPassword submitCallback={handleSubmit} backCallback={handleBack} />
            )}

            {!isLoadingRegisterAdminUser &&
              passRegisterAdminUser &&
              step === SELF_REGISTRATION_STEPS.VERIFY_EMAIL && <VerifyEmail />}
          </>
        )}

        {!isLoadingGetSignupData && !passGetSignupData && (
          <Error
            header={intl.formatMessage({
              id: 'verification.link_expired.header',
              defaultMessage: 'The verification link has unfortunately expired.',
            })}
            text={intl.formatMessage({
              id: 'verification.link_expired.text',
              defaultMessage: 'Please contact us to get a new link.',
            })}
          />
        )}

        {!isLoadingRegisterAdminUser && !passRegisterAdminUser && (
          <Error
            header={intl.formatMessage({
              id: 'registration.error.header',
              defaultMessage: 'Unfortunately, something went wrong.',
            })}
            text={intl.formatMessage({
              id: 'registration.error.text',
              defaultMessage: 'To continue please enter your data again.',
            })}
            ctaCallback={handleReset}
            ctaText={intl.formatMessage({
              id: 'registration.error.ctaText',
              defaultMessage: 'Verify your data and send again.',
            })}
          />
        )}
      </GuestFormContainer>
    </Box>
  );
};

export default SelfRegistrationPage;
