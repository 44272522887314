import { CompanyRole, RootState } from 'types';
import { createSelector } from '@reduxjs/toolkit';

const users = (state: RootState) => state.userManagement.users.data || [];

const getAdminAndUserMgmtUsers = createSelector(users, users =>
  users
    ? users?.filter(
        user =>
          user.role.indexOf(CompanyRole.Admin) !== -1 ||
          user.role.indexOf(CompanyRole.Usermgmt) !== -1,
      )
    : [],
);

const getAdminAndInvoiceUsers = createSelector(users, users =>
  users
    ? users?.filter(
        user =>
          user.role.indexOf(CompanyRole.Admin) !== -1 ||
          user.role.indexOf(CompanyRole.Invoice) !== -1,
      )
    : [],
);

const getAdminAndInvoiceUsersUniqueList = createSelector(
  getAdminAndInvoiceUsers,
  (_: RootState, emailList: string[]) => emailList,
  (adminAndInvoiceUsers, emailList) =>
    adminAndInvoiceUsers.filter(user => emailList.indexOf(user.email) === -1),
);

const getUserMgmtUsers = createSelector(users, users =>
  users ? users?.filter(user => user.role.indexOf(CompanyRole.Usermgmt) !== -1) : [],
);

const getUniqueActiveUserMgmtUsers = createSelector(
  getUserMgmtUsers,
  (_: RootState, emailList: string[]) => emailList,
  (uniqueUserMngList, emailList) =>
    uniqueUserMngList.filter(
      user => emailList.indexOf(user.email) === -1 && user.status === 'Active',
    ),
);

export const UserManagementSelectors = {
  users,
  getAdminAndUserMgmtUsers,
  getAdminAndInvoiceUsers,
  getAdminAndInvoiceUsersUniqueList,
  getUserMgmtUsers,
  getUniqueActiveUserMgmtUsers,
};
