import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { CCPDefaultButton, Spacer } from 'components';
import { ReactComponent as SettingIcon } from 'assets/icons/atoms-icon-nav-settings.svg';
import { CCPLabelCheckbox } from 'components/Form/Checkbox/LabelCheckbox';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useGetSettingTab, useLanguage } from 'hooks';
import { useCookies } from 'react-cookie';
import { SettingLink } from './CompanyCustomFieldModal.styles';

type CompanyCustomFieldModalProps = {
  open: boolean;
  handleClose: () => void;
  activeCompanyId?: number;
};

const CompanyCustomFieldModal = ({
  open,
  handleClose,
  activeCompanyId,
}: CompanyCustomFieldModalProps) => {
  const settingTabs = useGetSettingTab();
  const intl = useIntl();
  const navigate = useNavigate();
  const language = useLanguage();
  const [cookies, setCookies] = useCookies(['hideCustomerField']);
  const hideCustomerField = cookies.hideCustomerField || {};

  const methods = useForm<{ checked: boolean }>({
    defaultValues: {
      checked: false,
    },
  });

  const { handleSubmit, control } = methods;

  const onSubmit = (data: { checked: boolean }) => {
    if (data.checked && activeCompanyId) {
      hideCustomerField[activeCompanyId] = true;
      setCookies('hideCustomerField', hideCustomerField);
    }
    handleClose();
  };

  const goToSetting = () => {
    const customFieldTabIndex = settingTabs.findIndex(tab => tab.value === 'company_custom_fields');
    if (customFieldTabIndex !== -1) {
      navigate(`/${language}/settings/${customFieldTabIndex}`);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>
            {intl.formatMessage({
              id: 'employee.custom_field.modal.title',
              defaultMessage: 'Create company custom fields',
            })}
          </DialogTitle>
          <DialogContent>
            <Typography variant='subtitle1'>
              {intl.formatMessage({
                id: 'employee.custom_field.modal.label',
                defaultMessage:
                  'Please create custom specific information before you add employees. You can create up to 3 company specific information. Information added later must be entered manually in already created employees.',
              })}
            </Typography>
            <Spacer size='xl' />
            <Typography variant='subtitle1'>
              {intl.formatMessage({
                id: 'employee.custom_field.modal.content',
                defaultMessage: 'You find the configuration in the menu at',
              })}
              :
            </Typography>
            <SettingLink onClick={goToSetting}>
              <SettingIcon />
              <Typography>
                {intl.formatMessage({
                  id: 'employee.custom_field.modal.link',
                  defaultMessage: 'Settings / Company Custom fields',
                })}
              </Typography>
            </SettingLink>
            <Spacer size='xl' />
            <CCPLabelCheckbox
              control={control}
              name='checked'
              label={intl.formatMessage({
                id: 'employee.custom_field.check_box.label',
                defaultMessage: 'Don’t show this information again.',
              })}
            />
          </DialogContent>
          <DialogActions>
            <CCPDefaultButton variant='outlined' onClick={goToSetting}>
              {intl.formatMessage({
                id: 'button.cta.settings',
                defaultMessage: 'Go to settings',
              })}
            </CCPDefaultButton>
            <CCPDefaultButton variant='contained' type='submit'>
              {intl.formatMessage({
                id: 'button.cta.got_it',
                defaultMessage: 'Got it',
              })}
            </CCPDefaultButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default CompanyCustomFieldModal;
