import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosWithConfig } from 'api';
import { AxiosError } from 'axios';
import {
  GetMarketingMaterialsRequestParams,
  MarketingMaterialData,
  PimcoreErrorResponse,
} from 'types';

export const getMarketingMaterials = createAsyncThunk<
  MarketingMaterialData,
  GetMarketingMaterialsRequestParams
>(
  'marketingMaterialsManagement/getMarketingMaterials',
  async ({ companyId }, { rejectWithValue }) => {
    const endpoint =
      process.env.REACT_APP_PIMCORE_ENDPOINT + `api/companies/${companyId}/marketing-material`;
    const axios = await axiosWithConfig();

    try {
      return (await axios.get(endpoint)).data;
    } catch (e) {
      const error: AxiosError<PimcoreErrorResponse> = e as AxiosError<PimcoreErrorResponse>;
      const { response } = error;
      const data = response?.data as PimcoreErrorResponse;

      return rejectWithValue(data);
    }
  },
);
