import { RootState } from 'types';

const api = (state: RootState) => state.initialPassword.api;

const currentStep = (state: RootState) => state.initialPassword.currentStep;

const getDefaultPasswordStep = (state: RootState) =>
  state.initialPassword.steps.defaultPasswordStep;

const getSetNewPasswordStep = (state: RootState) => state.initialPassword.steps.setNewPasswordStep;

export const InitialPasswordSelectors = {
  currentStep,
  getDefaultPasswordStep,
  getSetNewPasswordStep,
  api,
};
