import { FC } from 'react';
import { CCPDefaultButton } from 'components';
import InfoIconOutlined from '@mui/icons-material/InfoOutlined';
import { IconContainer } from './UploadTab.styles';
import { useAppDispatch } from 'store';
import { EmployeeManagementActions } from 'features/employeeManagement/store';
import { useIntl } from 'react-intl';

interface UploadTabErrorBlockProps {
  onRefresh: () => void;
  message: string;
}

const UploadTabErrorBlock: FC<UploadTabErrorBlockProps> = ({ onRefresh, message }) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const refreshBulkUploadPage = () => {
    dispatch(EmployeeManagementActions.resetBulkImportCsvFile());
    onRefresh();
  };

  return (
    <div className="error-view">
      <IconContainer>
        <InfoIconOutlined />
      </IconContainer>

      <p>
        {intl.formatMessage({
          id: 'employee_management.bulk_upload.error.500',
          defaultMessage: '500 Internal Server Error',
        })}
      </p>
      <p className="desc">
        {intl.formatMessage({
          id: 'employee_management.bulk_upload.error.request',
          defaultMessage: 'Error in processing request',
        })}
      </p>
      <p>{message}</p>

      <CCPDefaultButton onClick={refreshBulkUploadPage}>
        {intl.formatMessage({
          id: 'employee_management.bulk_upload.cta.refresh_page',
          defaultMessage: 'Refresh page',
        })}
      </CCPDefaultButton>
    </div>
  );
};

export default UploadTabErrorBlock;
