import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { EmployeeManagementState, FetchingStatus } from 'types';
import { getEmployee } from './getEmployee';

export const getEmployeeExtraReducer = (
  builder: ActionReducerMapBuilder<EmployeeManagementState>,
) => {
  builder.addCase(getEmployee.pending, (state, action) => {
    state.api.getEmployee.fetchingStatus = FetchingStatus.PENDING;
    state.api.getEmployee.error = null;
  });
  builder.addCase(getEmployee.fulfilled, (state, action) => {
    state.api.getEmployee.fetchingStatus = FetchingStatus.FULFILLED;
    state.api.getEmployee.data = action.payload || null;
    state.api.getEmployee.error = null;
  });

  builder.addCase(getEmployee.rejected, (state, action) => {
    state.api.getEmployee.fetchingStatus = FetchingStatus.REJECTED;
    state.api.getEmployee.error = action.error;
  });
};
