import { FormatDate } from '@components/FormatDate';
import { ActiveMembersTableProps } from '@localTypes/stats';
import { TableCell, TableRow, Typography } from '@mui/material';

const ActiveMembersRow = ({
  customer_id,
  first_name,
  last_name,
  registration_date,
}: ActiveMembersTableProps) => (
  <TableRow key={customer_id}>
    <TableCell className='tablePadding'>{customer_id}</TableCell>
    <TableCell>
      <Typography variant='h3'>{`${first_name} ${last_name}`}</Typography>
    </TableCell>
    <TableCell>{FormatDate(registration_date)}</TableCell>
  </TableRow>
);

export default ActiveMembersRow;
