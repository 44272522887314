import { Typography } from '@mui/material';
import { ChangeEvent, useCallback } from 'react';
import { AntSwitch, TickboxWrapper } from './Tickbox.styled';

type TickboxProps = {
  label: string;
  fieldName: string;
  value: boolean;
  disabled?: boolean;
  handleChange: (key: string, value: boolean) => void;
  description?: string;
};

const Tickbox = ({
  label,
  fieldName,
  value,
  disabled = false,
  handleChange,
  description,
}: TickboxProps) => {
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      handleChange(fieldName, (event.target as HTMLInputElement).checked);
    },
    [fieldName, handleChange],
  );

  return (
    <>
      <TickboxWrapper
        label={
          <Typography variant='subtitle1' marginLeft='12px' fontWeight={value ? 'bold' : ''}>
            {label}
          </Typography>
        }
        control={
          <AntSwitch name={fieldName} checked={value} onChange={onChange} disabled={disabled} />
        }
      />
      {description && (
        <Typography variant='subtitle2' marginLeft='37px' paddingTop='8px'>
          {description}
        </Typography>
      )}
    </>
  );
};

export default Tickbox;
