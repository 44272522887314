import clsx from 'clsx';
import OtpInput from 'react18-input-otp';
import { useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '@store/index';
import { AuthenticationActions } from '@features/authentication/store';
import { AlertModal, CCPDefaultButton, ModalError, Spacer } from '@components/index';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { default as theme } from '@theme/theme';
import { MFAStateActions } from '@store/mfaState';
import Loader from '@components/Loader';
import { AuthenticationSelectors } from '@features/authentication/store/authentication.selectors';
import { FetchingStatus } from '@api/interfaces';

const MFACode = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const mfa = useAppSelector(state => state.MFAState);
  const { fetchingStatus, error } = useAppSelector(AuthenticationSelectors.getAuthentication);

  const [code, setCode] = useState('');
  const [seconds, setSeconds] = useState(
    ((mfa.timestamp || 0) + 10 * 60 * 1000 - new Date().getTime()) / 1000,
  );
  const [resetCount, setResetCount] = useState(0);
  const codeInvalid = useMemo(
    () => fetchingStatus === FetchingStatus.REJECTED && error?.code === 'NotAuthorizedException',
    [fetchingStatus, error],
  );
  const showMFAError = useMemo(() => mfa.error === 'INVALID_MFA', [mfa.error]);

  useEffect(() => {
    if (seconds <= 0) {
      return;
    }

    // Set up the timer
    const timer = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);

    // Clean up the timer
    return () => clearInterval(timer);
  }, [seconds]);

  useEffect(() => {
    if (mfa.timestamp) {
      setSeconds(((mfa.timestamp || 0) + 10 * 60 * 1000 - new Date().getTime()) / 1000);
    }
  }, [mfa.timestamp]);

  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60)
      .toString()
      .padStart(2, '0');
    const seconds = Math.floor(timeInSeconds % 60)
      .toString()
      .padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  const { handleSubmit } = useForm({
    mode: 'onChange',
  });

  const onSubmit = useCallback(() => {
    dispatch(AuthenticationActions.signIn({ code }));
  }, [dispatch, code]);

  const changeValueHandler = (code: string) => {
    setCode(code);
  };

  const resendLinkHandler = async () => {
    setResetCount(prevState => prevState + 1);
    await dispatch(AuthenticationActions.resetAuthState());
    if (mfa.email && mfa.password)
      dispatch(AuthenticationActions.signIn({ email: mfa.email, password: mfa.password }));
  };

  const cancelAuthentication = () => {
    dispatch(AuthenticationActions.resetAuthState());
    dispatch(MFAStateActions.resetMFAData());
  };

  const closeInvalidCodePopup = () => {
    dispatch(MFAStateActions.setErrorMFACode(''));
  };

  const isLoading = useMemo(() => fetchingStatus === FetchingStatus.PENDING, [fetchingStatus]);

  return (
    <Box className='wrapper'>
      <Loader show={isLoading} />
      <AlertModal
        isOpen={showMFAError}
        iconComponent={<ErrorOutlineIcon htmlColor={theme.palette.warning.main} />}
        handleClose={closeInvalidCodePopup}
        title={intl.formatMessage({
          id: 'mfa_code.modal.invalid_code.title',
          defaultMessage: 'Code is not correct',
        })}
        description={intl.formatMessage({
          id: 'mfa_code.modal.invalid_code.description',
          defaultMessage:
            'Sorry, this code is not correct. Please check the code you entered or have a new one sent to you.',
        })}
      />
      <Box component='form' onSubmit={handleSubmit(onSubmit)} data-test-id='confirmMFAForm'>
        <Typography variant='h2' textAlign='center' data-test-id='confirmMFATitle'>
          {intl.formatMessage({
            id: 'mfa_code.title.veryfy_email',
            defaultMessage: 'Confirm email address',
          })}
        </Typography>
        <Spacer size='md' />
        <Typography variant='subtitle1' textAlign='center'>
          {intl.formatMessage(
            {
              id: 'mfa_code.subtitle.verification_code',
              defaultMessage: `We have sent you an email to {mail}. Please enter the verification code here to confirm your email address.`,
            },
            { mail: <b>{mfa.email}</b> },
          )}
        </Typography>
        <Spacer size='lg' />
        <ModalError
          isInfo
          isErrorTitle={false}
          descriptionErrorMessage={
            <Typography variant='subtitle2'>
              <b>
                {intl.formatMessage({
                  id: 'mfa_code.info.attention',
                  defaultMessage: 'Attention',
                })}
                :{' '}
              </b>
              {intl.formatMessage({
                id: 'mfa_code.info.desctiption',
                defaultMessage:
                  'The code is only valid for 10 minutes. Please also check your spam folder if the email has not arrived.',
              })}
            </Typography>
          }
          errorContainerClassName='infoContainer'
        />
        <Spacer size='xl' />
        <OtpInput
          isInputNum
          inputStyle={clsx('verify__code_item', {
            verify__code_item_active: code.length === 6,
            verify__code_item_error: codeInvalid,
          })}
          isDisabled={seconds <= 0}
          containerStyle='verify__code'
          value={code}
          onChange={changeValueHandler}
          numInputs={6}
        />
        {codeInvalid && (
          <Typography variant='subtitle2' color={theme.palette.error.main} textAlign='center'>
            {intl.formatMessage({
              id: 'mfa_code.invalid.error',
              defaultMessage: `Code is invalid. Please check your input.`,
            })}
          </Typography>
        )}
        <Spacer size='xl' />
        {seconds > 0 ? (
          <>
            <Stack
              flexDirection='row'
              alignItems='center'
              justifyContent='center'
              gap='8px'
              data-test-id='mfaCodeWillExpireContainer'>
              <Typography className='verify__subtitle' variant='subtitle1'>
                {intl.formatMessage({
                  id: 'mfa_code.expiration.text',
                  defaultMessage: 'Your code will expire in',
                })}
              </Typography>
              <Box className='verify__link'>
                <Typography variant='body1'>{formatTime(seconds)}</Typography>
              </Box>
            </Stack>
            {/* show resend link button after 30 seconds and if user click resend button less than 2 times */}
            {seconds < 9 * 60 + 30 && resetCount < 2 && (
              <Stack
                flexDirection='row'
                alignItems='center'
                justifyContent='center'
                gap='8px'
                data-test-id='mfaDidntReceiveCodeContainer'>
                <Typography className='verify__subtitle' variant='subtitle1'>
                  {intl.formatMessage({
                    id: 'mfa_code.resend.text',
                    defaultMessage: `You didn't receive a code?`,
                  })}
                </Typography>
                <Box
                  className='verify__link'
                  onClick={resendLinkHandler}
                  data-test-id='mfaCodeResendLink'>
                  <Typography variant='body1'>
                    {intl.formatMessage({
                      id: 'mfa_code.resend.link',
                      defaultMessage: 'Resend code',
                    })}
                  </Typography>
                </Box>
              </Stack>
            )}
          </>
        ) : (
          <Stack
            flexDirection='row'
            alignItems='center'
            justifyContent='center'
            gap='8px'
            data-test-id='mfaCodeExpiredContainer'>
            <Typography className='verify__subtitle' variant='subtitle1'>
              {intl.formatMessage({
                id: 'mfa_code.expired.text',
                defaultMessage: `Your code was expired`,
              })}
            </Typography>
            {/* hide reset button if user clicked on resend button two times */}
            {resetCount < 2 && (
              <Box
                className='verify__link'
                onClick={resendLinkHandler}
                data-test-id='mfaCodeResendLink'>
                <Typography variant='body1'>
                  {intl.formatMessage({
                    id: 'mfa_code.resend.link',
                    defaultMessage: 'Resend code',
                  })}
                </Typography>
              </Box>
            )}
          </Stack>
        )}
        <Spacer size='3xl' />
        <Box className='verify__footer'>
          <CCPDefaultButton
            onClick={cancelAuthentication}
            variant='outlined'
            data-test-id='cancelConfirmMFAButton'>
            {intl.formatMessage({
              id: 'mfa_code.cta.cancel',
              defaultMessage: 'Cancel',
            })}
          </CCPDefaultButton>
          <CCPDefaultButton
            disabled={code.length !== 6 || seconds <= 0}
            color='primary'
            type='submit'
            variant='contained'
            data-test-id='checkConfirmMFAButton'>
            {intl.formatMessage({
              id: 'mfa_code.cta.confirm',
              defaultMessage: 'Check',
            })}
          </CCPDefaultButton>
        </Box>
      </Box>
    </Box>
  );
};

export default MFACode;
