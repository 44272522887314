import { useAppSelector } from 'store';
import { TableRow, Typography, TableCell, Stack } from '@mui/material';
import { FetchingStatus, ListEntries, ListType } from 'types';
import Loader from '@components/Loader';
import CustomTable from '@components/Table';
import { FormatDate } from '@components/FormatDate';
import { useMemo } from 'react';
import DeleteUserFromDistributionList from './DeleteUserFromDistributionList';
import AddUserToDistributionList from './AddUserToDistributionList';
import EditUserInDistributionList from './EditUserInDistributionList';
import AddEmailToInvoicesDistributionList from './AddEmailToInvoicesDistributionList';

const distributionTableColumns = [
  {
    columnName: 'email',
    columnTitle: 'Email',
    intlId: 'user_setting.self_registration.table.email',
  },
  {
    columnName: 'dateAdded',
    columnTitle: 'Date added',
    intlId: 'user_setting.self_registration.table.date_added',
  },
];

export type DistributionListProps = {
  listType: ListType;
  loaderCardId: string;
  externalUsersList?: boolean;
};

const DistributionList = ({
  listType,
  loaderCardId,
  externalUsersList = false,
}: DistributionListProps) => {
  const {
    data: distributionList,
    externalData: externalDistributionList,
    fetchingStatus,
  } = useAppSelector(store => store.settings.distributionLists[listType]);

  const isInvoices = useMemo(() => listType === ListType.INVOICES, [listType]);
  const isExternalUsersListForInvoices = useMemo(
    () => isInvoices && externalUsersList,
    [isInvoices, externalUsersList],
  );
  const hasActionsColumn = useMemo(
    () => (isInvoices && isExternalUsersListForInvoices) || !isInvoices,
    [isInvoices, isExternalUsersListForInvoices],
  );
  const tableColumns = useMemo(() => {
    const columns = [...distributionTableColumns];
    if (isExternalUsersListForInvoices) {
      columns.push({
        columnName: 'description',
        columnTitle: 'Note',
        intlId: 'user_setting.self_registration.table.note',
      });
    }
    if (hasActionsColumn) {
      columns.push({ columnName: 'actions', columnTitle: '', intlId: '' });
    }
    return columns;
  }, [hasActionsColumn, isExternalUsersListForInvoices]);

  const extraButton = useMemo(() => {
    if (isExternalUsersListForInvoices) {
      return <AddEmailToInvoicesDistributionList listType={listType} />;
    }
    if (isInvoices) {
      return null;
    }
    return <AddUserToDistributionList listType={listType} />;
  }, [isInvoices, isExternalUsersListForInvoices, listType]);

  const renderRow = (item: ListEntries) => (
    <TableRow key={item.unsubscribeKey}>
      <TableCell width='50%'>
        <Typography variant='h3'>{item.email}</Typography>
      </TableCell>
      <TableCell
        width='20%'
        className={`table_cell ${
          isInvoices && !isExternalUsersListForInvoices ? 'right-align' : ''
        }`}>
        {FormatDate(item.creationDate)}
      </TableCell>
      {isExternalUsersListForInvoices && (
        <TableCell width='20%'>
          <Typography variant='body1'>{item.description}</Typography>
        </TableCell>
      )}
      {hasActionsColumn && (
        <TableCell>
          <Stack flexDirection='row' flexWrap='nowrap' justifyContent='flex-end'>
            {isExternalUsersListForInvoices && (
              <EditUserInDistributionList
                listType={listType}
                email={item.email}
                description={item.description}
              />
            )}
            <DeleteUserFromDistributionList listType={listType} email={item.email} />
          </Stack>
        </TableCell>
      )}
    </TableRow>
  );

  return (
    <>
      <Loader inCard portalId={loaderCardId} show={fetchingStatus === FetchingStatus.PENDING} />
      <CustomTable
        columnsList={tableColumns}
        listData={externalUsersList ? externalDistributionList : distributionList}
        renderRow={renderRow}
        additionalButton={extraButton}
      />
    </>
  );
};

export default DistributionList;
