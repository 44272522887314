import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { FetchingStatus, S3CredentialsState } from 'types';
import { getS3CredentialsCompany } from './getS3CredentialsCompany';

export const getS3CredentialsCompanyReducer = (
  builder: ActionReducerMapBuilder<S3CredentialsState>,
) => {
  builder.addCase(getS3CredentialsCompany.pending, (state, action) => {
    state.api.getS3Credentials.fetchingStatus = FetchingStatus.PENDING;
    state.api.getS3Credentials.error = null;
  });
  builder.addCase(getS3CredentialsCompany.fulfilled, (state, action) => {
    const expiration = Object.values(action.payload)?.[0]?.Expiration || null;
    state.api.getS3Credentials.fetchingStatus = FetchingStatus.FULFILLED;
    state.api.getS3Credentials.data = action.payload || null;
    state.api.getS3Credentials.error = null;
    state.api.getS3Credentials.expiration = expiration ? Date.parse(expiration) / 1000 : null;
  });

  builder.addCase(getS3CredentialsCompany.rejected, (state, action) => {
    state.api.getS3Credentials.fetchingStatus = FetchingStatus.REJECTED;
    state.api.getS3Credentials.error = action.error;
  });
};
