import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosWithConfig } from 'api';
import { AxiosError } from 'axios';
import {
  CompanyCustomFieldType,
  GetCompanyCustomFieldRequestParams,
  PimcoreErrorResponse,
} from 'types';

export const getCompanyCustomField = createAsyncThunk<
  CompanyCustomFieldType[],
  GetCompanyCustomFieldRequestParams,
  { rejectValue: PimcoreErrorResponse }
>('settings/getCompanyCustomField', async ({ companyId }, { rejectWithValue }) => {
  const axios = await axiosWithConfig();
  const endpoint =
    process.env.REACT_APP_PIMCORE_ENDPOINT + `api/companies/${companyId}/custom-fields`;

  try {
    return (await axios.get(endpoint)).data;
  } catch (e) {
    const error: AxiosError<PimcoreErrorResponse> = e as AxiosError<PimcoreErrorResponse>;
    const { response } = error;
    const data = response?.data as PimcoreErrorResponse;

    return rejectWithValue(data);
  }
});
