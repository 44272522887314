import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToast } from 'utils';
import { axiosWithConfig } from 'api';
import { SettingsActions } from 'features/settings/store';
import { UserManagementBaseResponse, AddDistributionListRequestParams, RootState } from 'types';
import { AuthenticationActions } from 'features/authentication/store';

export const addRecordToDistributionList = createAsyncThunk<{}, AddDistributionListRequestParams>(
  'settings/addRecordToDistributionList',
  async (
    { email, listType, description, hideSuccessToast = false },
    { rejectWithValue, dispatch, getState },
  ) => {
    const currentState = getState() as RootState;
    const companyId = currentState.company.activeCompany?.companyId;
    const endpoint =
      process.env.REACT_APP_SETTINGS_ENDPOINT + `company/${companyId}/list/${listType}`;
    const axios = await axiosWithConfig();

    try {
      await axios.put(
        endpoint,
        {
          email_list: [email],
          description,
        },
        // patchHeaders,
      );

      if (!hideSuccessToast)
        showToast('success', {
          id: 'modal.success.add_user_to_distribution_list',
          defaultMessage: 'Success',
        });

      dispatch(
        SettingsActions.getDistributionList({
          companyId,
          listType,
        }),
      );
      if (companyId && currentState.authentication.userInfo) {
        dispatch(
          AuthenticationActions.changeDistributionList({
            companyId: companyId.toString(),
            distributionList: currentState.authentication.userInfo.companydetails
              .filter(item => item.companyId === companyId.toString())[0]
              .distributionListSubscribed.concat(listType),
          }),
        );
      }
    } catch (e) {
      const error: AxiosError<UserManagementBaseResponse> =
        e as AxiosError<UserManagementBaseResponse>;
      showToast('error', error.message);
      return rejectWithValue(error.response?.data);
    }
  },
);
