import { styled, Box, FormGroup } from '@mui/material';
export const WrapperInputsForm = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '36px 16px',
}));

export const WrapperCheckboxesForm = styled(FormGroup)(() => ({
  '& .item': {
    alignItems: 'flex-start',
    marginLeft: 0,
    marginBottom: '24px',
    ':last-child': {
      marginBottom: 0,
    },

    '& .checkbox': {
      width: 18,
      height: 18,
      margin: '2px 12px 0 0',
    },
  },
}));
