import { Link, useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';

import { RootState } from 'types';
import { useAppDispatch } from 'store';
import { SupportActions } from 'features/support/store';
import { CompanySelectors } from 'features/company/store/company.selectors';

import { SupportDetailWrapper } from './SupportDetail.styles';
import { useIntl } from 'react-intl';
import { Spacer } from 'components';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { default as theme } from '@theme/theme';
import { Breadcrumb } from '@components/Breadcrumb';
import CleanArticle from '@features/support/components/CleanArticle';

const SupportDetailPage = () => {
  const { language, id } = useParams();
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { data } = useSelector((state: RootState) => state.support);
  const { activeCompany } = useSelector(CompanySelectors.getCompanyState);

  useEffect(() => {
    dispatch(SupportActions.getFaqs({ language, companyId: activeCompany?.companyId }));
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activeFaqId = Number(id);
  const filterFaqsList = data?.categoryFaqs.find(item => item.id === activeFaqId);
  const getItemAnswer = (answer: any) => {
    const answerArray = answer?.split('\n');
    if (answerArray.length) {
      return answerArray.map((answer: string, idx: number) => (
        <CleanArticle key={idx} answer={answer} />
      ));
    }
  };

  const renderFaqsList = filterFaqsList?.faqs.map(item => (
    <Box key={item.id} className='content__item'>
      <Typography className='content__item_title' variant='body1'>
        {item.question}
      </Typography>
      <Box className='content__item_info'>
        {item.answer && getItemAnswer(item.answer)}
        <Box>
          <Link
            to={`/${language}/support/${filterFaqsList.id}/articles/${item.id}`}
            className='content__item_link'>
            {formatMessage({
              id: 'support.links.view_article',
              defaultMessage: 'View article',
            })}{' '}
            <Typography
              variant='subtitle2'
              style={{ fontWeight: 600, paddingLeft: 14, fontSize: 16 }}>
              {item.asset &&
                formatMessage({
                  id: 'support.links.view_article.has_asset',
                  defaultMessage: 'Video support available',
                })}
            </Typography>
            {item.asset && <PlayArrowIcon htmlColor={theme.palette.neutralDark.main} />}
          </Link>

          <Spacer height={28} />
        </Box>
      </Box>
    </Box>
  ));

  const breadcrumbRoutes = useMemo(
    () => [
      {
        url: `/${language}/support`,
        text: formatMessage({ id: 'footer.links.contact.faq', defaultMessage: 'FAQ' }),
      },
      {
        url: '',
        text: filterFaqsList?.categoryName || '',
      },
    ],
    [language, filterFaqsList?.categoryName, formatMessage],
  );

  return (
    <SupportDetailWrapper>
      <Breadcrumb routes={breadcrumbRoutes} />
      <Box className='content'>
        <Typography className='title' variant='h1'>
          {filterFaqsList?.categoryName}
        </Typography>
        <Typography className='content__text' variant='body1'>
          {filterFaqsList?.categoryDescription}
        </Typography>
        <Box>{renderFaqsList}</Box>
      </Box>
    </SupportDetailWrapper>
  );
};

export default SupportDetailPage;
