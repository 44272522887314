import { CCPDefaultButton, Spacer } from '@components/index';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/file_download.svg';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import InfoIconOutlined from '@mui/icons-material/InfoOutlined';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppSelector } from '@store/index';
import { AuthenticationSelectors } from '@features/authentication/store/authentication.selectors';
import { axiosWithConfig } from '@api/index';
import {
  CompanyResponse,
  CompanyRole,
  EmployeeTableOverwiewData,
  GetEmployeesResponse,
} from '@localTypes/index';
import { useLanguage } from '@hooks/useLanguage';
import { useGendersList } from '@utils/genders';
import { useEmployeeTitles } from '@utils/employeeTitle';
import csvDownload from 'json-to-csv-export';
import { manualFormatDateToString } from '@components/FormatDate';
import { default as theme } from '@theme/theme';
import * as XLSX from 'xlsx';
import { CompanySelectors } from '@features/company/store/company.selectors';
import { getStatusInfo } from '@utils/employeeOverview';
import { formatStatus } from '@utils/userStatus';
import { ColumnData } from '@hooks/useTableColumns';
import { useGridApiRef } from '@mui/x-data-grid-pro';

type CustomExportTableType = ColumnData<EmployeeTableOverwiewData> & {
  customScope?: boolean;
};

const ExportEmployee = () => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const { userInfo } = useAppSelector(AuthenticationSelectors.getAuthentication);
  const {
    companiesNames: { data: companiesData },
  } = useAppSelector(CompanySelectors.getCompanyState);
  const currentLanguage = useLanguage();

  const genders = useGendersList();
  const employeeTitles = useEmployeeTitles();

  const [isShowModal, setIsShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [preparedData, setPreparedData] = useState<{
    data: Array<Record<string, string>>;
    headers: Array<string>;
  }>({ data: [], headers: [] });
  const [unchecked, setUnchecked] = useState<Array<string>>([]);
  const apiRef = useGridApiRef();

  const isLimitedAccess = useMemo(() => {
    if (userInfo) {
      return !!userInfo.companydetails.find(
        company => !company.role.includes(CompanyRole.Usermgmt),
      );
    }
    return false;
  }, [userInfo]);

  const handleToggle = (value: string, isDisabled: boolean) => () => {
    if (isDisabled) {
      return;
    }
    const currentIndex = unchecked.findIndex(el => el === value);
    const newUnchecked = [...unchecked];

    if (currentIndex === -1) {
      newUnchecked.push(value);
    } else {
      newUnchecked.splice(currentIndex, 1);
    }

    setUnchecked(newUnchecked);
  };

  const resetToDefault = () => setUnchecked([]);

  const getAllCustomFields = async (
    companies: Array<{ companyId: string; companyName: string }>,
  ) => {
    if (companies.length === 0) return [];

    const axios = await axiosWithConfig();

    if (companies.length > 0) {
      const resp = (
        await Promise.allSettled(
          companies.map(({ companyId }) =>
            axios.get(`${process.env.REACT_APP_PIMCORE_ENDPOINT}api/companies/${companyId}`),
          ),
        )
      )
        .filter(r => r.status === 'fulfilled')
        .map(r => (r.status === 'fulfilled' ? r.value.data : {}));

      const alternateLanguage = currentLanguage === 'de' ? 'en' : 'de';
      return resp.reduce(
        (res, item: CompanyResponse) => [
          ...res,
          ...item.customFields.map(cf => ({
            field: cf.key,
            company: item.name,
            companyId: item.id.toString(),
            title: cf.name[currentLanguage] || cf.name[alternateLanguage],
          })),
        ],
        [],
      );
    }
  };

  const getFullCompanyList = async (companyId: string) => {
    const axios = await axiosWithConfig();
    const endpoint = `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/companies/${companyId}/endusers/list`;

    let resultData: GetEmployeesResponse = [];

    for (let page = 1, limit = 500; resultData.length % limit === 0; page++) {
      const newData = (await axios.get(endpoint, { params: { limit, page } })).data;
      resultData = [...resultData, ...newData];
      if (resultData.length === 0 || newData.length === 0) {
        break;
      }
    }

    return { companyId, employeeList: resultData };
  };

  const getAllUsersData = async (
    companiesId: Array<{ companyId: string; companyName: string }>,
  ) => {
    if (companiesId.length === 0) return [];

    const resp = (
      await Promise.allSettled(companiesId.map(({ companyId }) => getFullCompanyList(companyId)))
    )
      .filter(r => r.status === 'fulfilled')
      .map(r => (r.status === 'fulfilled' ? r.value : {}));

    return resp;
  };

  const prepareData = async () => {
    setPreparedData({ data: [], headers: [] });
    if (!userInfo) {
      return;
    }
    setIsLoading(true);

    const companies = userInfo.companydetails
      .filter(company => company.role.includes(CompanyRole.Usermgmt))
      .map(item => ({
        companyId: item.companyId,
        companyName:
          companiesData.find(cd => cd.companyId.toString() === item.companyId)?.companyName ||
          item.companyName,
      }));

    const customFields: Array<{
      field: string;
      company: string;
      companyId: string;
      title: string;
    }> = !unchecked.includes('customFields') ? await getAllCustomFields(companies) : [];
    const usersData = await getAllUsersData(companies);

    const translations: Record<string, { index: number; title: string }> = fieldsList.reduce(
      (list, column, index) => {
        if (unchecked.includes(column.field)) {
          return list;
        }
        //using index for column names to correct sorting fields
        return {
          ...list,
          [column.field]: {
            index,
            title: formatMessage({
              id: `${column.customScope ? '' : 'employee_management.overview.table.header.'}${
                column.i18nText
              }`,
              defaultMessage: column.defaultMessage,
            }),
          },
        };
      },
      {},
    );

    let dataToDownload: Array<Record<string, string>> = [];
    // let allCompaniesCFs: Record<string, string> = {};
    usersData.forEach(users => {
      const data = users as { companyId: string; employeeList: GetEmployeesResponse };
      const companyName = companies.find(item => item.companyId === data.companyId)?.companyName;
      dataToDownload = [
        ...dataToDownload,
        ...data.employeeList.map(user => {
          let resp = {};

          Object.keys(translations).forEach(key => {
            if (key !== 'customFields') {
              //put all checked columns except custom fields
              let columnValue =
                key === 'companyName' ? companyName : user[key as keyof typeof user] || '';

              if (key === 'gender') {
                columnValue = genders.find(gender => gender.value === user[key])?.title || '';
              }

              if (key === 'title') {
                columnValue = employeeTitles.find(title => title.value === user[key])?.title || '';
              }

              if (['dateofbirth', 'entrydate'].includes(key.toLowerCase()) && columnValue) {
                columnValue = manualFormatDateToString(columnValue.toString(), currentLanguage);
              }

              if (key === 'status') {
                const strValue = columnValue?.toString() || '';
                columnValue = strValue.charAt(0).toUpperCase() + strValue.slice(1);
              }

              // Use the column value getter if it exists, so we don't have to reformat the data and use the table definitions
              const col = fieldsList.find(item => item.field === key);
              const rowIdData = user as never as EmployeeTableOverwiewData;

              if (col && typeof col.valueGetter === 'function' && columnValue) {
                // call table getter function to avoid redundant formatter
                columnValue = col.valueGetter(columnValue as never, rowIdData, col, apiRef);
              }

              resp = { ...resp, [translations[key].index]: columnValue };
            }
          });

          if (translations.hasOwnProperty('customFields')) {
            //put custom fields columns
            resp = { ...resp, customFields: user.customFields };
          }
          return resp;
        }),
      ];
    });

    if (!unchecked.includes('customFields')) {
      //add all custom fields to each row
      const mainFieldsLength = Object.keys(translations).length;
      dataToDownload = dataToDownload.map(dataRow => {
        let newDataRow = { ...dataRow };
        customFields.forEach((cf, index) => {
          const currentCF = newDataRow.customFields as unknown as Record<string, string>;

          newDataRow = {
            ...newDataRow,
            [mainFieldsLength + index + 1]: currentCF[cf.field as keyof typeof currentCF] || '',
          };
        });
        delete newDataRow.customFields;
        return newDataRow;
      });
    }

    setPreparedData({
      data: dataToDownload,
      headers: Object.keys(translations)
        .filter(key => key !== 'customFields')
        .map(key => translations[key].title)
        .concat(customFields.map(item => item.title)),
    });
    setIsLoading(false);
  };

  const fieldsList: CustomExportTableType[] = [
    {
      field: 'companyName',
      customScope: true,
      i18nText: 'user.info.company_name',
      defaultMessage: 'Company Name',
    },
    {
      field: 'id',
      i18nText: 'user_id',
      defaultMessage: 'user id',
    },
    {
      field: 'title',
      i18nText: 'user_title',
      defaultMessage: 'Title',
    },
    {
      field: 'firstName',
      i18nText: 'first_name',
      defaultMessage: 'First name',
    },
    {
      field: 'lastName',
      i18nText: 'last_name',
      defaultMessage: 'Last name',
    },
    {
      field: 'gender',
      i18nText: 'gender',
      defaultMessage: 'Gender',
    },
    {
      field: 'businessEmail',
      i18nText: 'business_email',
      defaultMessage: 'corporate email',
    },
    {
      field: 'dateOfBirth',
      i18nText: 'birth_date',
      defaultMessage: 'Birthdate',
    },
    {
      field: 'phoneNumber',
      i18nText: 'phone_number',
      defaultMessage: 'Phone number',
    },
    {
      field: 'status',
      i18nText: 'status',
      defaultMessage: 'status',
      valueGetter: (_, row) => {
        const statusInfo = getStatusInfo(row);

        return formatStatus({
          status: statusInfo.status,
          entryDate: statusInfo.date,
          isPlannedTransition: statusInfo.plannedStatus,
          intl,
          language: currentLanguage,
        });
      },
    },
    {
      field: 'entryDate',
      i18nText: 'start_date',
      defaultMessage: 'Start date',
    },
    {
      field: 'customFields',
      customScope: true,
      i18nText: 'dashboard.modal.export.custom_fields',
      defaultMessage: 'Custom fields',
    },
  ];
  const showHideModal = () => {
    setIsShowModal(prev => !prev);
    setPreparedData({ data: [], headers: [] });
  };

  const downloadAsCsv = () => {
    csvDownload({
      data: preparedData.data,
      headers: preparedData.headers,
      delimiter: ',',
      filename: 'full-users-list',
    });
  };

  const downloadAsExcel = () => {
    var Heading = [preparedData.headers];
    var Data = preparedData.data;

    //Had to create a new workbook and then add the header
    const wb = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, Heading);

    //Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(ws, Data, { origin: 'A2', skipHeader: true });

    ws['!cols'] = Data.reduce(
      (res: { wch: number }[], item) =>
        Object.keys(item).map((m, i) => ({
          wch: Math.max(
            res[i] ? res[i].wch : 0,
            (item as Record<string, any>)[m].toString().length,
          ),
        })),
      [],
    );

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, `full-users-list.xlsx`, { compression: true });
  };

  const renderListItem = (item: CustomExportTableType) => {
    const disabled =
      item.field === 'companyName' ||
      (unchecked.length === fieldsList.length - 1 && !unchecked.includes(item.field));
    return (
      <ListItem sx={{ paddingLeft: 0 }} key={item.field}>
        <label
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={handleToggle(item.field, disabled)}
          htmlFor={item.field}>
          <Checkbox
            id={item.field}
            edge='start'
            checked={!unchecked.includes(item.field)}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': item.field }}
            sx={{ padding: '0px 9px' }}
            disabled={disabled}
          />
          <label>
            {formatMessage({
              id: `${item.customScope ? '' : 'employee_management.overview.table.header.'}${
                item.i18nText
              }`,
              defaultMessage: item.defaultMessage,
            })}
          </label>
        </label>
      </ListItem>
    );
  };

  return (
    <>
      <CCPDefaultButton
        onClick={showHideModal}
        variant='contained'
        data-test-id='exportEmployeeButton'>
        <DownloadIcon />
        {formatMessage({ id: 'export_employee_data', defaultMessage: 'Export employee data' })}
      </CCPDefaultButton>
      {preparedData.data.length > 0 || isLoading ? (
        <Dialog
          open={isShowModal}
          onClose={showHideModal}
          scroll='paper'
          data-test-id='exportEmployeeModal'>
          <CloseIcon onClick={showHideModal} className='dialog-close' />
          <DialogTitle>
            <Spacer size='xl' />
            <Box textAlign='center' className='dialog-icon'>
              {isLoading ? (
                <CircularProgress size='36px' />
              ) : (
                <CheckCircleOutlinedIcon htmlColor={theme.palette.success.main} />
              )}
            </Box>
            <Spacer size='sm' />
            <Typography variant='h3' textAlign='center'>
              {isLoading
                ? formatMessage({
                    id: 'dashboard.modal.export.title_loading',
                    defaultMessage: 'Generating file for export…',
                  })
                : formatMessage({
                    id: 'dashboard.modal.export.title_success',
                    defaultMessage: 'Ready for download',
                  })}
            </Typography>
            <Spacer size='xs' />
            <Typography variant='subtitle2' textAlign='center'>
              {isLoading
                ? formatMessage({
                    id: 'dashboard.modal.export.subtitle_loading',
                    defaultMessage: `Your employee list export is currently in progress. This may take a few moments. Please do not close this window or navigate away from the page.`,
                  })
                : formatMessage({
                    id: 'dashboard.modal.export.subtitle_success',
                    defaultMessage: `Your employee list is ready to download. Now you can download the file to your computer.`,
                  })}
            </Typography>
          </DialogTitle>
          <DialogActions>
            {!isLoading && (
              <Stack flexDirection='row' gap='16px' justifyContent='center' width='100%'>
                <CCPDefaultButton
                  variant='outlined'
                  onClick={downloadAsCsv}
                  data-test-id='downloadAsCsv'>
                  {formatMessage({
                    id: 'dashboard.modal.export.cta.csv',
                    defaultMessage: 'Download as CSV',
                  })}
                </CCPDefaultButton>
                <CCPDefaultButton
                  variant='outlined'
                  onClick={downloadAsExcel}
                  data-test-id='downloadAsExcel'>
                  {formatMessage({
                    id: 'dashboard.modal.export.cta.xls',
                    defaultMessage: 'Download as Excel',
                  })}
                </CCPDefaultButton>
              </Stack>
            )}
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          open={isShowModal}
          onClose={showHideModal}
          scroll='paper'
          data-test-id='exportEmployeeModal'>
          <CloseIcon onClick={showHideModal} className='dialog-close' />
          <DialogTitle data-test-id='modalTitle'>
            {formatMessage({
              id: 'dashboard.modal.export.title',
              defaultMessage: 'Export Employee List',
            })}
          </DialogTitle>
          <DialogContent>
            <Typography variant='subtitle2'>
              {formatMessage({
                id: 'dashboard.modal.export.subtitle',
                defaultMessage: `Easily export employee lists from multiple companies. Customize your view by including or excluding columns such as first name, last name, birthdate, status, gender, and more.`,
              })}
            </Typography>
            <Spacer size='md' />
            {isLimitedAccess && (
              <Box
                sx={{
                  display: 'flex',
                  gap: '12px',
                  backgroundColor: theme.palette.primaryLight.main,
                  border: `1px solid ${theme.palette.neutralLight.dark}`,
                  padding: '16px',
                  borderRadius: '4px',
                  color: '#64748B',
                }}>
                <WarningOutlinedIcon />
                <Box>
                  <Typography variant='h3'>
                    {formatMessage({
                      id: 'dashboard.modal.export.limited_access.title',
                      defaultMessage: 'Limited Access to Company Information',
                    })}
                  </Typography>
                  <Typography variant='subtitle2'>
                    {formatMessage({
                      id: 'dashboard.modal.export.limited_access.subtitle',
                      defaultMessage:
                        'Due to permission restrictions, some company data is unavailable and cannot be included in the generated file. Please contact your administrator if you need additional access.',
                    })}
                  </Typography>
                </Box>
              </Box>
            )}
            <Spacer size='md' />
            <Typography variant='h3'>
              {formatMessage({
                id: 'dashboard.modal.export.column_title',
                defaultMessage: 'Column',
              })}
            </Typography>
            <Stack flexDirection='row' justifyContent='space-between'>
              <List sx={{ width: '100%' }}>
                {fieldsList
                  .filter((item, i) => i < Math.ceil(fieldsList.length / 2))
                  .map(item => renderListItem(item))}
              </List>
              <List sx={{ width: '100%' }}>
                {fieldsList
                  .filter((item, i) => i >= Math.ceil(fieldsList.length / 2))
                  .map(item => renderListItem(item))}
              </List>
            </Stack>
          </DialogContent>
          <Box
            sx={{
              display: 'flex',
              padding: '8px 40px 16px 40px',
              justifyContent: 'space-between',
              alignItems: 'center',
              background: '#F1F5F9',
              height: '60px',
            }}>
            <Stack flexDirection='row' alignItems='center' gap='8px'>
              {unchecked.length === fieldsList.length - 1 && (
                <>
                  <InfoIconOutlined fontSize='medium' color='info' />
                  <Typography variant='subtitle1'>
                    {formatMessage({
                      id: 'dashboard.modal.export.error.last_column',
                      defaultMessage: 'At least 1 column should be selected',
                    })}
                  </Typography>
                </>
              )}
            </Stack>
            <Box>
              {unchecked.length > 0 && (
                <CCPDefaultButton
                  variant='text'
                  color='error'
                  onClick={resetToDefault}
                  data-test-id='resetToDefault'>
                  {formatMessage({
                    id: 'dashboard.modal.export.cta.reset',
                    defaultMessage: 'Reset to default',
                  })}
                </CCPDefaultButton>
              )}
            </Box>
          </Box>
          <DialogActions>
            <CCPDefaultButton
              variant='outlined'
              onClick={showHideModal}
              data-test-id='backToListButton'>
              {formatMessage({
                id: 'dashboard.modal.export.cta.back',
                defaultMessage: 'Back to list',
              })}
            </CCPDefaultButton>
            <CCPDefaultButton
              variant='contained'
              onClick={prepareData}
              data-test-id='sendExportButton'>
              {formatMessage({
                id: 'dashboard.modal.export.cta.export',
                defaultMessage: 'Export',
              })}
            </CCPDefaultButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default ExportEmployee;
