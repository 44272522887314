import { styled, Box, Select, MenuItem, SelectProps } from '@mui/material';

export const LanguagesBarWrapper = styled(Box)(() => ({
  padding: 0,
}));
type SelectFieldProps = SelectProps & {
  width?: string | number;
};
export const SelectWrapper = styled(Select)<SelectFieldProps>(({ theme, width }) => ({
  width: width ? width : '100%',
  '&:hover': {
    outline: `1px solid ${theme.palette.neutralLight.dark}`,
  },

  '.MuiSelect-outlined': {
    outline: `1px solid ${theme.palette.neutralLight.dark}  !important`,
    padding: '16.5px 14px;',
    background: 'transparent',

    fontSize: 14,
    lineHeight: 1.29,
    letterSpacing: 0.15,
    color: theme.palette.neutralDark.contrastText,
  },

  fieldset: {
    display: 'none',
  },

  svg: {
    path: {
      fill: theme.palette.neutralDark.main,
    },
  },
}));

export const MenuItemWrapper = styled(MenuItem)(({ theme }) => ({
  fontSize: 16,
  lineHeight: 1.38,
  color: theme.palette.neutralDark.contrastText,
  padding: '8px 16px',
}));
