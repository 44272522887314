import { styled, Box } from '@mui/material';

export const UserAccountWrapper = styled(Box)(({ theme }) => ({
  '.wrapper': {
    gap: 2,
    display: 'flex',

    '&__sidebar': {
      minWidth: 235,
      background: theme.palette.background.paper,
      paddingTop: 24,

      '&_item': {
        position: 'relative',

        fontSize: 16,
        lineHeight: 1.38,
        color: theme.palette.neutralDark.contrastText,
        cursor: 'pointer',
        padding: '13px 24px',

        '&_active': {
          color: theme.palette.primary.main,
          cursor: 'default',
          fontWeight: 600,

          ':after': {
            content: "''",
            position: 'absolute',
            top: 0,
            right: 0,
            background: theme.palette.primary.main,
            width: 4,
            height: '100%',
          },
        },
      },
    },
  },

  '.content': {
    width: 'calc(100% - 235px)',
    minHeight: 608,
    background: theme.palette.background.paper,
    padding: '48px 48px 36px',
    borderRadius: 0,
    display: 'flex',

    '&__form': {
      width: '100%',
      maxWidth: 524,
      display: 'flex',
      flexDirection: 'column',

      '&_wrapper': {
        gap: 16,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
      },

      '&_top': {
        gap: 16,
        display: 'flex',
        div: {
          flex: 1,
        },
      },

      '&_email': {
        gap: 16,
        display: 'flex',
        alignItems: 'center',

        '&_text': {
          fontWeight: 'bold',
          color: theme.palette.primary.main,
          cursor: 'pointer',
        },
      },
    },

    '&__footer': {
      gap: 16,
      display: 'flex',
      justifyContent: 'space-between',
    },
  },

  '.changePassword': {
    gap: 43,
  },
}));
