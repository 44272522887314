import { createSelector, ParametricSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { DataGridTableConfiguration } from './interfaces';

const tablesManagement = (state: RootState) => state.tablesManagement;

const getTableConfigurationByTableNameAndCompanyId: ParametricSelector<
  RootState,
  { tableName: string; companyId?: number },
  DataGridTableConfiguration | undefined
> = createSelector([tablesManagement, (_, companyId) => companyId], (configurations, params) => {
  const companyId = params.companyId;
  const tableName = params.tableName;

  if (companyId && tableName && configurations[tableName]?.tableConfigurations?.[companyId]) {
    return configurations[tableName].tableConfigurations[companyId];
  }

  return undefined;
});

export const TablesManagementSelectors = {
  getTableConfigurationByTableNameAndCompanyId,
};
