import { CouponTableProps } from '@localTypes/stats';
import { TableCell, TableRow, Typography } from '@mui/material';

const CouponsRow = ({ partner_name, coupons, partner_id }: CouponTableProps) => (
  <TableRow key={partner_id}>
    <TableCell className='tablePadding'>{partner_id}</TableCell>
    <TableCell>
      <Typography variant='h3'>{partner_name}</Typography>
    </TableCell>
    <TableCell>{coupons}</TableCell>
  </TableRow>
);

export default CouponsRow;
