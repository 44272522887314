import { PimcoreErrorResponse, ViolationError } from 'types';

export const getViolationErrors = (params: {
  scope: string;
  error: PimcoreErrorResponse | null | undefined;
}): ViolationError[] => {
  const { scope, error } = params;
  const violationErrors: ViolationError[] = [];

  error?.violations?.forEach(violation => {
    violationErrors.push({
      property: violation.propertyPath,
      message: `${scope}.form.error.${
        violation.propertyPath.toLowerCase().includes('customfields')
          ? 'customField'
          : violation.propertyPath
      }.${violation.message}`.toLowerCase(),
    });
  });

  return violationErrors;
};
