import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import { ISelfRegistration, SettingsState } from 'types';

export const selfRegistration: CaseReducer<
  SettingsState,
  PayloadAction<{ key: keyof ISelfRegistration; value: ISelfRegistration[keyof ISelfRegistration] }>
> = (state, { payload: { key, value } }) => {
  switch (key) {
    case 'euShare': {
      state.selfRegistration[key] = value?.toString();
      break;
    }
    case 'signupPassword':
    case 'validEmailDomains':
    case 'signupIdentifier': {
      if (typeof value === 'string') state.selfRegistration[key] = value;
      break;
    }
    case 'allowSEPA':
    case 'autoConfirmSignup':
    case 'passwordRequired':
    case 'showLandingPage':
    case 'invitationRequired':
    case 'domainValidation':
    case 'isUrlPublished': {
      if (typeof value === 'boolean') state.selfRegistration[key] = value;
      break;
    }
    default: {
      if (key) {
        state.selfRegistration[key] = value as unknown as never;
      }
    }
  }
};
