import { useMemo, useState } from 'react';
import { ReactComponent as ClearIcon } from 'assets/icons/atoms-icon-remove.svg';
import { useIntl } from 'react-intl';
import { AlertModal, CCPDefaultButton } from 'components';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { SettingsActions } from 'features/settings/store';
import { useAppDispatch, useAppSelector } from 'store';
import { ListType } from 'types';
import { Typography } from '@mui/material';
import Loader from '@components/Loader';

type DeleteUserFromDistributionListProps = {
  listType: ListType;
  email: string;
};

const DeleteUserFromDistributionList = ({
  listType,
  email,
}: DeleteUserFromDistributionListProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleDelete = async () => {
    if (activeCompany) {
      setIsLoading(true);
      await dispatch(
        SettingsActions.deleteRecordFromDistributionList({
          email,
          listType,
        }),
      );
      setIsLoading(false);
      handleClose();
    }
  };

  const description = useMemo(() => {
    if (listType === ListType.NEW_EU_REQUEST) {
      return formatMessage(
        {
          id: 'distribution_list.delete_company_user.new_eu_request.description',
          defaultMessage:
            'You are going to delete recipient {email} from the distribution list. It means this recipient won’t receive notification about the new requests. Continue?',
        },
        { email: <b>{email}</b> },
      );
    }
    return formatMessage(
      {
        id: 'user_setting.activation_code.delete_company_user.description',
        defaultMessage: 'You’re going to delete {email}. Continue?',
      },
      { email: <b>{email}</b> },
    );
  }, [listType, email, formatMessage]);

  return (
    <>
      <Loader show={isLoading} />
      <CCPDefaultButton variant='text' onClick={handleOpenModal} startIcon={<ClearIcon />}>
        {formatMessage({
          id: 'distribution_list.cta.delete_recipient',
          defaultMessage: 'Delete',
        })}
      </CCPDefaultButton>
      <AlertModal
        isOpen={isModalOpen}
        handleClose={handleClose}
        title={formatMessage({
          id: 'distribution_list.delete_company_user.title',
          defaultMessage: 'Delete recipient',
        })}
        hideDescription
        mainContent={<Typography variant='subtitle1'>{description}</Typography>}
        showConfirmButton
        confirmButtonColor='error'
        confirmButtonText={formatMessage({
          id: 'distribution_list.delete_company_user.confirm',
          defaultMessage: 'Yes, delete',
        })}
        buttonText={formatMessage({
          id: 'distribution_list.delete_company_user.cancel',
          defaultMessage: 'Cancel',
        })}
        handleConfirm={handleDelete}
      />
    </>
  );
};

export default DeleteUserFromDistributionList;
