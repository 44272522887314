import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccordionSummary, AccordionDetails, Box, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { ContractStatus, INavigationListItems, ISideBarItem } from 'types';
import { navigationList, linkRegex } from 'utils';

import { AccordionWrapper } from './LeftSideBar.styles';
import { useAppSelector } from 'store';
import { ContractsSelectors } from 'features/contract/store/contracts.selectors';
import { default as theme } from '@theme/theme';

const SideBarItem: React.FC<ISideBarItem> = ({ item, open, index, updateActiveIndex }) => {
  const { text, Icon, items, link, defaultText } = item;
  const isValidLink = linkRegex.test(link);

  const activeRoute = window.location.pathname.split('/')[2] || navigationList[0].link;
  const activeLanguage = window.location.pathname.split('/')[1] || 'de';
  const activeStatsRoute =
    activeRoute === 'stats'
      ? window.location.pathname.split('/')[3] || navigationList[0].link
      : null;
  const isActive = link.includes(activeRoute);

  const intl = useIntl();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const isOpenToggler = (): void => {
    updateActiveIndex(index, link);
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const statsNavItems = navigationList.find(navEl => navEl.items)?.items;
  const coupPageIndex = statsNavItems?.findIndex(subItem => subItem.route === 'coupon-usage');

  const contractsApi = useAppSelector(ContractsSelectors.getContracts);
  const contracts = contractsApi.getContracts.data;
  const hasFullAccess = contracts.some(
    contract =>
      contract.status === ContractStatus.Active &&
      contract.products.filter(item => item.name === 'Online+').length > 0,
  );

  const updateActiveItemHandler = useCallback(
    (index: number, link: string): void => {
      navigate(`/${activeLanguage}/${link}`);
    },
    [activeLanguage, navigate],
  );

  useEffect(() => {
    if (activeRoute === 'stats' && !isOpen && activeStatsRoute !== 'dashboard') {
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStatsRoute]);

  const getAccordionItems = useCallback(
    (items: INavigationListItems[] | undefined) =>
      items?.map((item, index) => (
        <Typography
          key={item.id}
          className={clsx('details__text', {
            details__text_active: item.route === activeStatsRoute,
          })}
          onClick={() => updateActiveItemHandler(index, item.link)}
          data-test-id='sideMenuSubItem'>
          {intl.formatMessage({ id: item.name, defaultMessage: item.defaultName })}
        </Typography>
      )),
    [intl, updateActiveItemHandler, activeStatsRoute],
  );

  const renderAccordionItem = getAccordionItems(
    hasFullAccess
      ? items
      : items?.filter(
          item => statsNavItems && coupPageIndex && item.id !== statsNavItems[coupPageIndex].id,
        ),
  );

  if (items?.length) {
    return (
      <AccordionWrapper expanded={open && isOpen && isActive}>
        <AccordionSummary
          className={clsx('summary', {
            summary_active: isActive,
            summary_hide: !open,
          })}
          expandIcon={<ArrowDropDownIcon htmlColor={theme.palette.primaryLight.main} />}
          aria-controls='panel1a-content'
          onClick={isOpenToggler}
          data-test-id='sideMenuItem'>
          <Icon htmlColor={theme.palette.primaryLight.main} />
          <Typography className='wrapper__item_text'>
            {intl.formatMessage({ id: text, defaultMessage: defaultText })}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className='details'>{renderAccordionItem}</AccordionDetails>
      </AccordionWrapper>
    );
  }

  if (isValidLink) {
    return (
      <a
        href={link}
        target='_blank'
        rel='noreferrer'
        className='wrapper__item'
        data-test-id='sideMenuItem'>
        <Icon htmlColor={theme.palette.primaryLight.main} />
        <Typography className='wrapper__item_text'>
          {intl.formatMessage({ id: text, defaultMessage: defaultText })}
        </Typography>
      </a>
    );
  }

  return (
    <Box
      className={clsx('wrapper__item', {
        wrapper__item_active: isActive,
      })}
      onClick={() => updateActiveIndex(index, link)}
      data-test-id='sideMenuItem'>
      <Icon htmlColor={theme.palette.primaryLight.main} />
      <Typography className='wrapper__item_text'>
        {intl.formatMessage({ id: text, defaultMessage: defaultText })}
      </Typography>
    </Box>
  );
};

export default SideBarItem;
