import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { EmployeeManagementState, FetchingStatus } from 'types';
import { changeEmployeeStatus } from './changeEmployeeStatus';

export const changeEmployeeStatusExtraReducer = (
  builder: ActionReducerMapBuilder<EmployeeManagementState>,
) => {
  builder.addCase(changeEmployeeStatus.pending, (state, action) => {
    state.api.changeEmployeeStatus.fetchingStatus = FetchingStatus.PENDING;
    state.api.changeEmployeeStatus.error = null;
  });
  builder.addCase(changeEmployeeStatus.fulfilled, (state, action) => {
    state.api.changeEmployeeStatus.fetchingStatus = FetchingStatus.FULFILLED;
    state.api.changeEmployeeStatus.data = action.payload || null;
    state.api.changeEmployeeStatus.error = null;
  });
  builder.addCase(changeEmployeeStatus.rejected, (state, action) => {
    state.api.changeEmployeeStatus.fetchingStatus = FetchingStatus.REJECTED;
    state.api.changeEmployeeStatus.error = action.error;
  });
};
