import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { useIntl } from 'react-intl';
import { CCPDefaultButton, ModalError, Spacer } from 'components';
import { default as theme } from '@theme/theme';
import { GlobalStateActions } from '@store/globalState';
import { useAppDispatch, useAppSelector } from '@store/index';
import { SettingsActions } from '@features/settings/store';
import { CompanySelectors } from '@features/company/store/company.selectors';
import { SettingsSelectors } from '@features/settings/store/settings.selectors';
import { UserTypeShortcut } from '@localTypes/user';

type GuardActivationLettersProps = {
  onConfirm: () => void;
  onCancel: () => void;
};

const GuardActivationLetters = ({ onCancel, onConfirm }: GuardActivationLettersProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);
  const defaultSettings = useAppSelector(SettingsSelectors.settingsSettings);
  const {
    getCompany: { data },
  } = useAppSelector(CompanySelectors.getApi);

  const addPortalUser = () => {
    onCancel();
    dispatch(GlobalStateActions.showActivationLettersAddUserPopup(true));
  };

  const cancelClick = () => {
    let companyId = activeCompany?.companyId;
    if (!companyId) {
      companyId = data?.id;
    }
    if (companyId)
      dispatch(
        SettingsActions.updateSettings({
          companyId,
          activationLetterRecipientMode: 'eu' as UserTypeShortcut,
          invoiceRecipientMode: defaultSettings.invoiceRecipientMode,
        }),
      );
    onConfirm();
  };

  return (
    <Dialog open onClose={onCancel}>
      <CloseIcon onClick={onCancel} className='dialog-close' />
      <DialogTitle>
        <Box className='dialog-icon'>
          <ErrorOutlineIcon htmlColor={theme.palette.error.main} />
        </Box>
        <Typography variant='h2'>
          {formatMessage({
            id: 'router.leaving.dialog.title',
            defaultMessage: 'No portal user selected',
          })}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1'>
          {formatMessage({
            id: 'router.leaving.dialog.subtitle',
            defaultMessage: `To send the activation letter to portal users, at least one portal user must be selected.`,
          })}
        </Typography>
        <Spacer size='md' />
        <ModalError
          headerErrorMessage={formatMessage({
            id: 'router.leaving.dialog.error_title',
            defaultMessage: 'Settings will be reset',
          })}
          descriptionErrorMessage={formatMessage({
            id: 'router.leaving.dialog.error_description',
            defaultMessage:
              'If no portal user is selected, the activation letters will be sent to the employees.',
          })}
          errorContainerClassName='infoContainer'
          isInfo
        />
      </DialogContent>
      <DialogActions>
        <CCPDefaultButton onClick={cancelClick} variant='outlined'>
          {formatMessage({ id: 'router.leaving.cta.cancel', defaultMessage: 'Cancel' })}
        </CCPDefaultButton>
        <CCPDefaultButton onClick={addPortalUser} variant='contained'>
          {formatMessage({
            id: 'router.leaving.cta.confirm',
            defaultMessage: 'Add portal user',
          })}
        </CCPDefaultButton>
      </DialogActions>
    </Dialog>
  );
};

export default GuardActivationLetters;
