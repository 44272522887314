import { SecurityResponse } from '@localTypes/index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '@store/interfaces';
import { axiosWithConfig } from 'api';
import { AxiosError } from 'axios';
import { showToast } from 'utils';

const getMFASetting = createAsyncThunk<SecurityResponse, {}>(
  'security/getMFASetting',
  async (_, { rejectWithValue, getState }) => {
    const state = getState() as RootState;

    const endpoint = `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/companies/${state.company.activeCompany?.companyId}/security`;
    const axios = await axiosWithConfig();

    try {
      return (await axios.get(endpoint)).data;
    } catch (e) {
      const error = e as AxiosError;
      showToast('error', {
        id: 'toast.get_settings.error',
        defaultMessage: error.message,
      });
      return rejectWithValue(e);
    }
  },
);

export default getMFASetting;
