import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { EmployeeManagementState, FetchingStatus } from 'types';
import { updateCsvFile } from './updateCsvFile';

export const updateCsvFileExtraReducer = (
  builder: ActionReducerMapBuilder<EmployeeManagementState>,
) => {
  builder.addCase(updateCsvFile.pending, state => {
    if (!state.api.bulkImportCsvFile) {
      state.api['bulkImportCsvFile'] = {
        fetchingStatus: FetchingStatus.IDLE,
        data: null,
        error: null,
      };
    } else {
      state.api.bulkImportCsvFile.fetchingStatus = FetchingStatus.PENDING;
      state.api.bulkImportCsvFile.data = null;
      state.api.bulkImportCsvFile.error = null;
    }
  });

  builder.addCase(updateCsvFile.fulfilled, (state, action) => {
    state.api.bulkImportCsvFile.fetchingStatus = FetchingStatus.FULFILLED;
    state.api.bulkImportCsvFile.data = action.payload;
  });

  builder.addCase(updateCsvFile.rejected, (state, action) => {
    state.api.bulkImportCsvFile.fetchingStatus = FetchingStatus.REJECTED;
    state.api.bulkImportCsvFile.error = action.payload;
  });
};
