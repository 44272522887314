import { styled } from '@mui/material';

export const FileTemplate = styled('table')(({ theme }) => ({
  width: '100%',
  marginTop: '30px',
  borderSpacing: '6px',

  th: {
    fontSize: '12px',
    color: theme.palette.tableTemplate.fontColor,
  },
  td: {
    height: '20px',
    margin: '6px 8px',
    backgroundColor: theme.palette.tableTemplate.backgroundColor,
  },
}));
