import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosWithConfig } from 'api';
import { AxiosError } from 'axios';
import { RegisterAdminUserRequestBody, UserManagementBaseResponse } from 'types';

export const registerAdminUser = createAsyncThunk<
  UserManagementBaseResponse,
  RegisterAdminUserRequestBody
>('selfRegistration/registerAdminUser', async (data, { rejectWithValue }) => {
  const endpoint = process.env.REACT_APP_USER_MANAGEMENT_ENDPOINT + `registerAdminUser`;
  const axios = await axiosWithConfig({ withAuthentication: false });

  try {
    return (await axios.post(endpoint, data)).data;
  } catch (e) {
    const error: AxiosError<UserManagementBaseResponse> =
      e as AxiosError<UserManagementBaseResponse>;

    return rejectWithValue(error.response?.data);
  }
});
