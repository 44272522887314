import { styled, Box } from '@mui/material';

export const SupportWrapper = styled(Box)(({ theme }) => ({
  '.header': {
    display: 'flex',
    justifyContent: 'space-between',
    '&__status': {
      display: 'inherit',
      gap: 16,
    },
  },
  '.main': {
    background: theme.palette.background.paper,
    padding: '36px',
    borderRadius: 4,
  },

  '.questions': {
    paddingBottom: 56,

    '&__list': {
      marginTop: 24,
      display: 'grid',
      gridGap: 36,
      gridTemplateColumns: 'repeat(2,1fr)',
    },

    '&__card': {
      border: `2px solid ${theme.palette.primaryLight.main}`,
      borderRadius: 8,
      padding: 48,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',

      '&_link': {
        display: 'block',
        marginBottom: 24,
        fontFamily: 'Roboto-Medium',
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 1.38,
        color: theme.palette.primary.main,
        textDecoration: 'none',

        '&:hover': {
          textDecoration: 'underline',
        },
      },

      button: {
        marginTop: 12,
      },
    },

    '.faq-empty': {
      marginTop: '24px',
      gap: 16,
      padding: '21px 24px',
      objectFit: 'contain',
      borderRadius: 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      border: `2px solid ${theme.palette.primaryLight.main}`,
      '.info': {
        gap: 16,
        display: 'flex',
        alignItems: 'center',
      },

      '.text': {
        fontSize: 16,
      },
    },
  },

  '.form': {
    display: 'flex',
    justifyContent: 'center',

    '&__wrapper': {
      maxWidth: 466,
      textAlign: 'center',

      '&_subtitle': {
        marginBottom: 36,

        fontSize: 14,
        lineHeight: 1.14,
        letterSpacing: 0.18,
        color: theme.palette.neutralDark.main,
      },

      '&_text': {
        margin: '24px 0 42px',
      },

      '&_field': {
        display: 'block',
        marginBottom: 30,
        flex: 1,
        textAlign: 'left',

        '&_subtitle': {
          fontSize: 14,
          lineHeight: 1.14,
          letterSpacing: 0.18,
          color: theme.palette.neutralDark.main,
        },

        div: {
          width: '100%',
        },
      },
    },

    '&__row': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: 16,
    },

    '&__footer': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      svg: {
        maxWidth: 164,
      },
    },
  },
}));
