import { useCallback, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { enUS, de } from 'date-fns/locale';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker, LocalizationProvider, DateView } from '@mui/x-date-pickers';
import { defaultTextfieldError } from 'utils/defaultErrorMessage';
import { default as theme } from '@theme/theme';
import { useLanguage } from '@hooks/useLanguage';

import { DefaultTextField } from '../TextField/DefaultTextField.styles';

export interface InputFieldProps {
  label: string;
  name: string;
  control: any;
  width?: number | string;
  disabled?: boolean;
  notShowErrorText?: boolean;
  minDate?: Date;
  maxDate?: Date;
  isMonthPicker?: boolean;
  isBirthdayPicker?: boolean;
  required?: boolean;
  isWarningError?: boolean;
}

const CCPDatePicker = ({
  label,
  control,
  name,
  width,
  disabled,
  notShowErrorText = false,
  minDate,
  maxDate,
  isMonthPicker,
  isBirthdayPicker = false,
  required = false,
  isWarningError = false,
}: InputFieldProps) => {
  const intl = useIntl();
  const currentLanguage = useLanguage();
  const { setValue, trigger } = useFormContext();

  const handleChange = useCallback(
    (value: Date | null) => {
      setValue(name, value);
      trigger(name);
    },
    [setValue, trigger, name],
  );

  const views = useMemo(() => {
    if (!isMonthPicker) {
      return ['year', 'month', 'day'] as DateView[];
    }
    if (minDate && maxDate && minDate.getFullYear() === maxDate.getFullYear()) {
      return ['month'] as DateView[];
    }
    return ['year', 'month'] as DateView[];
  }, [isMonthPicker, minDate, maxDate]);

  const errorIconColor = (error?: { message?: string } | undefined) =>
    isWarningError && !!error ? 'warning' : !!error ? 'error' : 'default';

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={currentLanguage === 'de' ? de : enUS}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <DesktopDatePicker
            {...field}
            minDate={minDate}
            maxDate={maxDate}
            disabled={disabled}
            views={views}
            value={field?.value ? new Date(field?.value) : null}
            label={label ? `${label}${required ? '*' : ''}` : ''}
            openTo={views[isBirthdayPicker ? 0 : views.length - 1]}
            format={isMonthPicker ? 'MM.yyyy' : 'dd.MM.yyyy'}
            onChange={handleChange}
            sx={{
              width,
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px',
              },
              '.Mui-disabled fieldset.MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.transparent.disabledInput,
              },
              '.MuiFormLabel-root.MuiInputLabel-root.Mui-error': {
                color: isWarningError ? theme.palette.warning.main : theme.palette.error.main,
              },
              '.MuiInputBase-root.MuiOutlinedInput-root.Mui-error fieldset.MuiOutlinedInput-notchedOutline':
                {
                  borderColor: isWarningError
                    ? theme.palette.warning.main
                    : theme.palette.error.main,
                },
            }}
            slots={{ textField: DefaultTextField }}
            slotProps={{
              openPickerButton: {
                color: errorIconColor(error),
              },
              textField: {
                error: !!error,
                inputProps: {
                  'data-test-id': `${name}InputDatePicker`,
                },
                helperText:
                  error?.message && !notShowErrorText ? (
                    <span data-test-id='validationErrorText'>
                      {intl.formatMessage({
                        id: error?.message,
                        defaultMessage: defaultTextfieldError[error.message] || error.message,
                      })}
                    </span>
                  ) : undefined,
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default CCPDatePicker;
