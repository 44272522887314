import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { SupportArticlesWrapper } from './SupportArticles.styles';
import { useSelector } from 'react-redux';
import { RootState } from 'types';
import { FaqsState, GetFaqsResponseData } from 'features/support/store/interfaces';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch } from 'store';
import { SupportActions } from 'features/support/store';
import { CompanySelectors } from 'features/company/store/company.selectors';

import DonwloadButton from 'features/support/components/DownloadButton';
import HlsPlayer from 'features/support/components/HlsPlayer';
import CleanArticle from '@features/support/components/CleanArticle';
import { Breadcrumb } from '@components/Breadcrumb';

const SupportArticlesPage = () => {
  const { categoryId, language, articleId } = useParams();
  const { formatMessage } = useIntl();
  const { data } = useSelector((state: RootState) => state.support);
  const [faqCategory, setFaqCategory] = useState<GetFaqsResponseData>();
  const [article, setArticle] = useState<FaqsState>();
  const [hasAsset, setHasAsset] = useState(false);
  const [hasStreamAsset, setHasStreamAsset] = useState(false);
  const [articleAnswer, setArticleAnswer] = useState<string[]>();

  const dispatch = useAppDispatch();
  const { activeCompany } = useSelector(CompanySelectors.getCompanyState);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(SupportActions.getFaqs({ language, companyId: activeCompany?.companyId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const parsedCategoryId = Number(categoryId);
    const parsedArticleId = Number(articleId);
    if (parsedCategoryId && data?.categoryFaqs && data.categoryFaqs.length > 0) {
      const currentCategory = data.categoryFaqs.find(item => item.id === parsedCategoryId);
      if (parsedArticleId && currentCategory && currentCategory.faqs?.length > 0) {
        setFaqCategory(currentCategory);
        const currentArticle = currentCategory.faqs.find(item => item.id === parsedArticleId);
        if (currentArticle) {
          setArticle(currentArticle);
          setArticleAnswer(currentArticle.answer?.split('\n'));
          setHasAsset(!!currentArticle.asset);
          setHasStreamAsset(!!currentArticle.assetStreamingLink);
        }
      }
    }
  }, [data, categoryId, articleId]);

  const breadcrumbRoutes = useMemo(
    () => [
      {
        url: `/${language}/support`,
        text: formatMessage({ id: 'footer.links.contact.faq', defaultMessage: 'FAQ' }),
      },
      {
        url: `/${language}/support/${faqCategory?.id}`,
        text: faqCategory?.categoryName || '',
      },
      {
        url: '',
        text: article?.question || '',
      },
    ],
    [language, faqCategory, article?.question, formatMessage],
  );

  const getType = (videoLink: string) => videoLink.substring(videoLink.lastIndexOf('.') + 1);
  return (
    <SupportArticlesWrapper>
      <Breadcrumb routes={breadcrumbRoutes} />
      <Box className='content'>
        <Box className='wrapper'>
          <Typography className='title' variant='h1'>
            {article?.question}
          </Typography>
          {articleAnswer &&
            articleAnswer.map((answer, idx) => <CleanArticle key={idx} answer={answer} />)}
          {hasAsset && !hasStreamAsset && <DonwloadButton article={article} />}
          {article?.assetStreamingLink && (
            <>
              {getType(article.assetStreamingLink) === 'm3u8' ? (
                <HlsPlayer url={article.assetStreamingLink} width='600' height='360' />
              ) : (
                <video width='600' height='360' controls>
                  <source
                    src={article.assetStreamingLink}
                    type={`video/${getType(article.assetStreamingLink)}`}
                  />
                </video>
              )}
            </>
          )}
        </Box>
      </Box>
    </SupportArticlesWrapper>
  );
};

export default SupportArticlesPage;
