import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { TableRow, Typography, TableCell, Box, Stack } from '@mui/material';
import { CCPDefaultButton, PermissionsChipGroup } from 'components';
import { CompanyRole, IUserInfo, ListType, OwnUserData } from 'types';
import { useAppDispatch, useAppSelector } from 'store';
import { useIntl } from 'react-intl';
import EditNotificationsModal from 'components/Modal/EditNotifications';
import { SettingsActions } from 'features/settings/store';
import { useNotificationsSettingsList } from 'utils';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';

const NotificationTableRow: React.FC<{
  item: IUserInfo & { notifications: ('invoices' | 'onboarding')[] };
}> = ({ item }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const notificationsSettingsList = useNotificationsSettingsList();
  const ownUserData = useAppSelector(SettingsSelectors.getOwnUserData);
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);

  const openModalHandler = () => {
    dispatch(SettingsActions.getRegistrationSettings({ companyId: item.companyId }));
    closeEditModalToggler();
  };
  const closeEditModalToggler = () => {
    setIsOpenEditModal(prev => !prev);
  };

  const submitEditModalHandler = (data: { onboarding: boolean; invoices: boolean }): void => {
    if (!ownUserData) {
      return;
    }
    const payload: OwnUserData = {} as OwnUserData;
    payload.userdetails = ownUserData.userdetails;
    const companydetails = ownUserData.companydetails.find(
      company => Number(company.companyId) === Number(item.companyId),
    );
    if (!companydetails) {
      return;
    }
    let { distributionListSubscribed } = companydetails;
    if (data.invoices && !distributionListSubscribed.includes(ListType.INVOICES)) {
      distributionListSubscribed = [...distributionListSubscribed, ListType.INVOICES];
    }
    if (!data.invoices && distributionListSubscribed.includes(ListType.INVOICES)) {
      distributionListSubscribed = distributionListSubscribed.filter(
        item => item !== ListType.INVOICES,
      );
    }
    if (data.onboarding && !distributionListSubscribed.includes(ListType.NEW_EU_REQUEST)) {
      distributionListSubscribed = [...distributionListSubscribed, ListType.NEW_EU_REQUEST];
    }
    if (!data.onboarding && distributionListSubscribed.includes(ListType.NEW_EU_REQUEST)) {
      distributionListSubscribed = distributionListSubscribed.filter(
        item => item !== ListType.NEW_EU_REQUEST,
      );
    }
    payload.companydetails = [
      {
        ...companydetails,
        distributionListSubscribed,
      },
    ];
    dispatch(SettingsActions.changeOwnUserData(payload));
    closeEditModalToggler();
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography variant='h3'>{item.companyName}</Typography>
        </TableCell>
        <TableCell>
          {item.notifications.length > 0 ? (
            <Box className='added'>
              <PermissionsChipGroup
                role={item.notifications}
                customChipList={notificationsSettingsList}
              />
            </Box>
          ) : item.role.includes(CompanyRole.Invoice) ||
            item.role.includes(CompanyRole.Usermgmt) ? (
            intl.formatMessage({
              id: 'account.notifications.empty_list',
              defaultMessage: 'Select notifications for this company',
            })
          ) : null}
        </TableCell>
        <TableCell>
          <Stack flexDirection='row' flexWrap='nowrap' justifyContent='flex-end'>
            {item.role.includes(CompanyRole.Invoice) || item.role.includes(CompanyRole.Usermgmt) ? (
              <CCPDefaultButton variant='text' onClick={openModalHandler} startIcon={<EditIcon />}>
                {intl.formatMessage({
                  id: 'employee_management.bulk_upload.table.column.action.edit',
                  defaultMessage: 'Edit',
                })}
              </CCPDefaultButton>
            ) : (
              <Typography variant='body1' fontStyle='italic'>
                {intl.formatMessage({
                  id: 'account.notifications.no_authorization',
                  defaultMessage: 'No authorisation for notifications',
                })}
              </Typography>
            )}
          </Stack>
        </TableCell>
      </TableRow>
      {isOpenEditModal && (
        <EditNotificationsModal
          isOpen
          roles={item.role}
          notifications={item.notifications}
          onSubmit={submitEditModalHandler}
          closeModal={closeEditModalToggler}
        />
      )}
    </>
  );
};

export default NotificationTableRow;
