import { Stack, Typography } from '@mui/material';
import { ModalError, Spacer } from 'components';
import { SettingsActions } from 'features/settings/store';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store';
import { ListType } from 'types';
import DistributionList from '@features/settings/components/DistributionList';

const Onboarding = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();

  const autoConfirmSignup = useAppSelector(
    state => state.settings.defaultSelfRegistration.autoConfirmSignup,
  );

  useEffect(() => {
    dispatch(SettingsActions.getDistributionList({ listType: ListType.NEW_EU_REQUEST }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack id='onboardingSettingsWrapper' height='100%'>
      <Typography variant='h6'>
        {formatMessage({
          id: 'user_setting.onboarding_emails',
          defaultMessage: 'Onboarding emails',
        })}
      </Typography>
      <Spacer size='md' />
      <Typography variant='subtitle1'>
        {formatMessage({
          id: 'user_setting.onboarding_emails.subtitle',
          defaultMessage:
            'This overview shows which company users receive information about new registrations.',
        })}
      </Typography>
      <Spacer size='xl' />
      {autoConfirmSignup && (
        <ModalError
          isErrorTitle={false}
          descriptionErrorMessage={formatMessage({
            id: 'user_setting.onboarding_emails.error_description',
            defaultMessage:
              'Please note: As long as employee requests are automatically approved, no notifications will be sent.',
          })}
          errorContainerClassName='infoContainer'
          isInfo
        />
      )}
      <Spacer size='2xl' />
      <DistributionList
        listType={ListType.NEW_EU_REQUEST}
        loaderCardId='onboardingSettingsWrapper'
      />
    </Stack>
  );
};

export default Onboarding;
