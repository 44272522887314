import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'store';
import { AddEmailSchema } from 'shared/schemas';
import { ChangeEmailProps, FetchingStatus } from 'types';
import { UserManagementActions } from 'features/userManagement/store';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import { useIntl } from 'react-intl';
import { CCPDefaultButton, CCPTextField, Spacer } from 'components';
import { AuthenticationSelectors } from 'features/authentication/store/authentication.selectors';
import { useEffect } from 'react';

const ChangeEmail: React.FC<ChangeEmailProps> = ({ closeModal, updateChangedEmail, isOpen }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(AuthenticationSelectors.getAuthentication);
  const changeUserEmail = useAppSelector(state => state.userManagement.changeUserEmail);

  const {
    control,
    handleSubmit,
    formState: { isValid },
    setError,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(AddEmailSchema),
  });

  useEffect(() => {
    if (
      changeUserEmail.fetchingStatus === FetchingStatus.REJECTED &&
      changeUserEmail.error?.message === 'NEW_EMAIL_SAME_AS_OLD_EMAIL'
    ) {
      setError('email', { message: 'form.employee.error.email.same_email' });
    }
  }, [changeUserEmail, setError]);

  const onSubmit = (data: any) => {
    if (!userInfo || !userInfo.cognitoId) {
      return;
    }
    updateChangedEmail(data.email);
    dispatch(
      UserManagementActions.changeUserEmail({
        cognitoID: userInfo.cognitoId,
        newEmail: data.email,
      }),
    );
  };

  return (
    <Dialog open={isOpen} onClose={closeModal} component='form' onSubmit={handleSubmit(onSubmit)}>
      <CloseIcon onClick={closeModal} className='dialog-close' />
      <DialogTitle>
        <Typography variant='h2'>
          {intl.formatMessage({
            id: 'account.tab.info.change_email_title',
            defaultMessage: 'Change company email',
          })}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1'>
          {intl.formatMessage({
            id: 'account.tab.info.change_email_subtitle',
            defaultMessage:
              'Please enter your new company email adress. In the next step you have to verify the new address.',
          })}
        </Typography>
        <Spacer size='xl' />
        <CCPTextField
          control={control}
          name='email'
          width='100%'
          required
          label={`${intl.formatMessage({
            id: 'form.employee.change_email.email',
            defaultMessage: 'E-mail',
          })}`}
        />
      </DialogContent>
      <DialogActions>
        <CCPDefaultButton onClick={closeModal} variant='outlined'>
          {intl.formatMessage({ id: 'form.employee.cta.cancel', defaultMessage: 'Cancel' })}
        </CCPDefaultButton>
        <CCPDefaultButton disabled={!isValid} type='submit' variant='contained'>
          {intl.formatMessage({ id: 'form.employee.cta.continue', defaultMessage: 'Continue' })}
        </CCPDefaultButton>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeEmail;
