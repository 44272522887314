import { CheckInsTableProps } from '@localTypes/stats';
import { TableCell, TableRow, Typography } from '@mui/material';
import formatNumber from '@utils/formatNumber';

const CheckInsRow = ({ partner_name, eus, checkins, partner_id }: CheckInsTableProps) => (
  <TableRow key={partner_id}>
    <TableCell className='tablePadding'>
      <Typography variant='h3'>{partner_name}</Typography>
    </TableCell>
    <TableCell>
      <Typography variant='h3'>{formatNumber(eus)}</Typography>
    </TableCell>
    <TableCell>
      <Typography variant='h3'>{formatNumber(checkins)}</Typography>
    </TableCell>
  </TableRow>
);

export default CheckInsRow;
