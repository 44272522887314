import { styled, TableHead } from '@mui/material';

export const TableEmptyDataWrapper = styled(TableHead)(() => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  '& .text': {
    fontFamily: 'Roboto-Medium',
    margin: '36px 0px',
  },
}));
