import { Box, styled } from '@mui/material';

export const InputTagWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  whiteSpace: 'break-spaces',
  alignItems: 'center',
  border: `1px solid ${theme.palette.neutralDark.light}`,
  width: 'fit-content',
  borderRadius: 20,
  padding: '2px 6px 1px 10px',
  marginRight: '4px',

  svg: {
    cursor: 'pointer',
    path: {
      fill: theme.palette.neutralDark.light,
    },
  },
}));
