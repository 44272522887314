import { Box, styled } from '@mui/material';

export const TwoRowsSideBarItemStyled = styled(Box)(({ theme }) => ({
  '&.wrapper__item': {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '12px 24px',
    cursor: 'pointer',

    '&.wrapper__item_active': {
      cursor: 'default',
      '.wrapper__item_text': {
        fontWeight: 600,
        color: theme.palette.primary.main,
      },

      '&:after': {
        content: '""',
        width: 4,
        height: '100%',
        background: theme.palette.primary.main,
        position: 'absolute',
        top: 0,
        right: 0,
      },
    },

    '.wrapper__item_text': {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.38,
      color: theme.palette.neutralDark.contrastText,
      textDecoration: 'none',
    },
  },
}));
