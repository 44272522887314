import { createAsyncThunk } from '@reduxjs/toolkit';
import { PimcoreErrorResponse } from 'types';
import { axiosWithConfig } from 'api';
import { AxiosError } from 'axios';
import { ContractsPayload, IEUConditions } from 'features/contract/store/interfaces';

export const getEUCondition = createAsyncThunk<
  IEUConditions,
  ContractsPayload,
  { rejectValue: PimcoreErrorResponse }
>('contracts/getEUCondition', async ({ companyId }, { rejectWithValue }) => {
  const endpoint =
    process.env.REACT_APP_PIMCORE_ENDPOINT + `api/companies/${companyId}/eu-creation-conditions`;
  const axios = await axiosWithConfig();

  try {
    return (await axios.get(endpoint)).data;
  } catch (e) {
    const error: AxiosError<PimcoreErrorResponse> = e as AxiosError<PimcoreErrorResponse>;
    const { response } = error;
    const data = response?.data as PimcoreErrorResponse;

    return rejectWithValue(data);
  }
});
