import { Auth } from 'aws-amplify';
import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { AxiosConfig } from '../interfaces';
import logoutUser from '@utils/authentication/logoutUser';

export const patchHeaders = {
  headers: {
    'content-type': 'application/merge-patch+json',
  },
};

export const axiosWithConfig = async ({
  withAuthentication = true,
  headers = {},
}: AxiosConfig = {}): Promise<AxiosInstance> => {
  const customHeaders: AxiosRequestHeaders = { ...headers };
  try {
    if (withAuthentication) {
      const currentSession = await Auth.currentSession();
      customHeaders['Authorization'] = 'Bearer ' + currentSession.getIdToken().getJwtToken();
    }
  } catch (err) {
    if (err instanceof Error && err.name === 'NotAuthorizedException') {
      logoutUser();
    }
  }
  return axios.create({
    timeout: 30000,
    headers: customHeaders,
  });
};
