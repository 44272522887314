import CheckIcon from '@mui/icons-material/Check';
import { SuccessIconContainer } from 'components/SuccessIcon/SuccessIcon.styles';

const SuccessIcon = () => {
  return (
    <SuccessIconContainer>
      <CheckIcon />
    </SuccessIconContainer>
  );
};

export default SuccessIcon;
