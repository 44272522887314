import { TextField } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { BulkActionsWrapper, MenuItemWrapper } from './BulkActions.styles';
import CCPRectButton from '@components/Button/Rect';
import { EmployeeBulkActions } from '@features/employeeManagement/components/EmployeeOverview/Modals/BulkActionsModal/interfaces';

export interface BulkActionOptions {
  option: keyof typeof EmployeeBulkActions;
  value: string;
  accent: boolean;
}

const BulkActions: React.FC<{
  enableButton: boolean;
  actionsList: BulkActionOptions[];
  bulkActionsDisableButton?: boolean;
  onClick?: (value: keyof typeof EmployeeBulkActions) => void;
}> = ({ enableButton, actionsList, onClick, bulkActionsDisableButton = false }) => {
  const intl = useIntl();
  const translationMessages: Record<
    keyof typeof EmployeeBulkActions,
    { id: string; defaultMessage: string }
  > = useMemo(
    () =>
      defineMessages({
        DEFAULT: {
          id: 'table.toolbar.button.bulk_actions',
          defaultMessage: 'Bulk actions',
        },
        CONFIRM: {
          id: 'table.toolbar.button.bulk_actions_CONFIRM',
          defaultMessage: 'Confirm Request',
        },
        DEACTIVATE: {
          id: 'table.toolbar.button.bulk_actions_DEACTIVATE',
          defaultMessage: 'Pause membership unlimited',
        },
        DENY: { id: 'table.toolbar.button.bulk_actions_DENY', defaultMessage: 'Deny Request' },
        EDIT_START_DATE: {
          id: 'table.toolbar.button.bulk_actions_EDIT_START_DATE',
          defaultMessage: 'Edit start date',
        },
        PAUSE: {
          id: 'table.toolbar.button.bulk_actions_PAUSE',
          defaultMessage: 'Pause membership',
        },
        REACTIVATE: {
          id: 'table.toolbar.button.bulk_actions_REACTIVATE',
          defaultMessage: 'Reactivate membership',
        },
        RESEND: {
          id: 'table.toolbar.button.bulk_actions_RESEND',
          defaultMessage: 'Resend activation letter',
        },
        REMOVE: {
          id: 'table.toolbar.button.bulk_actions_REMOVE',
          defaultMessage: 'Remove employee from company',
        },
      }),
    [],
  );
  const getTranslatedMenuItems = useCallback(
    (option: keyof typeof EmployeeBulkActions) => {
      if (translationMessages?.[option]) {
        return translationMessages[option];
      }
      return translationMessages.DEFAULT;
    },
    [translationMessages],
  );

  const defaultValue = 'DEFAULT';

  const [value, setValue] = React.useState<keyof typeof EmployeeBulkActions>(defaultValue);

  const updateValueHandler = (event: { target: { value: string } }): void => {
    setValue(event.target.value as keyof typeof EmployeeBulkActions);
  };

  const bulkActionsHandler = () => {
    if (onClick) {
      onClick(value);
    }
    setValue(defaultValue);
  };

  return (
    <BulkActionsWrapper>
      <TextField
        size='small'
        value={value}
        onChange={updateValueHandler}
        select
        className='TextField'>
        <MenuItemWrapper disabled value={defaultValue}>
          {intl.formatMessage(getTranslatedMenuItems(defaultValue))}
        </MenuItemWrapper>
        {actionsList.map((action, index) => (
          <MenuItemWrapper key={index} value={action.option}>
            <div className={`dots-in-text ${action.accent ? 'accent' : ''}`}>
              {intl.formatMessage(getTranslatedMenuItems(action.option))}
            </div>
          </MenuItemWrapper>
        ))}
      </TextField>
      <CCPRectButton
        className='ApplyButton'
        onClick={bulkActionsHandler}
        disabled={
          value === defaultValue ||
          !enableButton ||
          (bulkActionsDisableButton && value === 'RESEND')
        }>
        {intl.formatMessage({
          id: 'table.toolbar.button.apply',
          defaultMessage: 'Apply',
        })}
      </CCPRectButton>
    </BulkActionsWrapper>
  );
};

export default BulkActions;
