import { CaseReducer } from '@reduxjs/toolkit';
import { UserManagementState } from 'types';
import { initialState } from '../../initialState';

export const updateFetchingStatus: CaseReducer<UserManagementState> = state => {
  state.addUser.fetchingStatus = initialState.addUser.fetchingStatus;
};

export const resetChangeUserEmailStatus: CaseReducer<UserManagementState> = state => {
  state.changeUserEmail = initialState.changeUserEmail;
  state.verifyChangeUserEmail = initialState.verifyChangeUserEmail;
};
