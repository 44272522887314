import { Box, styled } from '@mui/material';

export const SuccessIconContainer = styled(Box)(({ theme }) => ({
  width: 'fit-content',
  border: `2px solid ${theme.palette.success.main}`,
  borderRadius: 32,
  svg: {
    height: 26,
    width: 32,
    fill: theme.palette.success.main,
    position: 'relative',
    top: 3,
  },
}));
