import { CCPDefaultButton, Spacer } from 'components';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useAppSelector } from 'store';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';

const ResendLetterModal: React.FC<{ close: () => void; onSubmit: () => void }> = ({
  close,
  onSubmit,
}) => {
  const activationLetterDistributionList = useAppSelector(
    SettingsSelectors.emailActivationLetterDistributionList,
  );
  const { activationLetterRecipientMode } = useAppSelector(SettingsSelectors.settingsSettings);
  const intl = useIntl();

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const handleSubmit = useCallback(() => {
    onSubmit();
    close();
  }, [onSubmit, close]);

  const content =
    activationLetterRecipientMode === 'eu' ? (
      intl.formatMessage({
        id: 'employee.expander.modal.resend_letter.content.eu',
        defaultMessage: 'Activation letter will be send to the employee, confirm?',
      })
    ) : (
      <>
        {' '}
        {intl.formatMessage({
          id: 'employee.expander.modal.resend_letter.content.cu',
          defaultMessage: 'Activation letter will be send to the following portal users:',
        })}
        <Spacer size='xl' />
        {activationLetterDistributionList.map(cu => (
          <Typography key={cu} variant='h3'>
            {cu}
          </Typography>
        ))}
      </>
    );

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>
        {intl.formatMessage({
          id: 'employee.more.expander.pending.resend',
          defaultMessage: 'Resend activation letter',
        })}
      </DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1'>{content}</Typography>
      </DialogContent>
      <DialogActions>
        <CCPDefaultButton variant='outlined' onClick={handleClose}>
          {intl.formatMessage({ id: 'form.employee.cta.cancel', defaultMessage: 'Cancel' })}
        </CCPDefaultButton>
        <CCPDefaultButton variant='contained' onClick={handleSubmit}>
          {intl.formatMessage({
            id: 'form.employee.cta.resend_activation_letter',
            defaultMessage: 'Resend',
          })}
        </CCPDefaultButton>
      </DialogActions>
    </Dialog>
  );
};

export default ResendLetterModal;
