import { styled, Box } from '@mui/material';

export const InvoicesLoadingWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  background: theme.palette.background.paper,

  '.skeleton': {
    padding: 16,
    background: theme.palette.background.paper,
    borderRadius: '0 0 4px 4px',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,

    '&__item': {
      width: '100%',
      background:
        'linear-gradient(to right, rgba(169, 187, 205, 0.2) 21%, rgba(230, 230, 230, 0.2) 86%)',
      height: 64,
    },
  },
}));

export const InvoicesWrapper = styled(Box)(() => ({
  '.header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    a: {
      textDecoration: 'unset',
    },
  },
}));

export const NoDataWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 571,
  borderRadius: 4,
  padding: 20,

  '.skeleton': {
    opacity: 0.5,

    '&__top': {
      display: 'grid',
      gridGap: 8,
      gridTemplateColumns: 'repeat(4,1fr)',
      marginBottom: 8,

      '&_text': {
        fontFamily: 'Roboto-Medium',
        fontSize: 13,
        fontWeight: 500,
        lineHeight: 1.69,
        color: theme.palette.neutralDark.main,
      },
    },

    '&__content': {
      display: 'grid',
      gridGap: 8,
      gridTemplateColumns: 'repeat(4,1fr)',

      '&_item': {
        background: theme.palette.tableTemplate.backgroundColor,
        height: 20,
      },
    },
  },

  '.content': {
    width: '100%',
    maxWidth: 614,
    position: 'relative',

    '&__info': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '&__title': {
      fontFamily: 'NutmegHeadline-UltraBlack',
      fontSize: 20,
      fontWeight: 900,
      lineHeight: 1.6,
      color: theme.palette.neutralDark.contrastText,
      textTransform: 'uppercase',
      marginBottom: 16,
    },

    '&__text': {
      fontSize: 16,
      lineHeight: 1.38,
      color: theme.palette.neutralDark.main,
    },
  },
}));
