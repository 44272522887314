import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  DefaultPasswordStep,
  INITIAL_PASSWORD_STEPS,
  InitialPasswordState,
  SetNewPasswordStep,
} from 'types';

export const updateDefaultPasswordStepData: CaseReducer<
  InitialPasswordState,
  PayloadAction<DefaultPasswordStep>
> = (state, { payload }) => {
  state.steps.defaultPasswordStep = payload;
};

export const updateSetNewPasswordStepData: CaseReducer<
  InitialPasswordState,
  PayloadAction<SetNewPasswordStep>
> = (state, { payload }) => {
  state.steps.setNewPasswordStep = payload;
};

export const setStep: CaseReducer<InitialPasswordState, PayloadAction<INITIAL_PASSWORD_STEPS>> = (
  state,
  { payload },
) => {
  state.currentStep = payload;
};
