import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { FetchingStatus, SettingsState } from 'types';
import { changeOwnUserData } from './changeOwnUserData';

export const changeOwnUserDataExtraReducer = (builder: ActionReducerMapBuilder<SettingsState>) => {
  builder.addCase(changeOwnUserData.pending, state => {
    if (!state.ownUserDataFetchingStatus) {
      state['ownUserDataFetchingStatus'] = FetchingStatus.PENDING;
    } else {
      state.ownUserDataFetchingStatus = FetchingStatus.PENDING;
    }
  });
  builder.addCase(changeOwnUserData.fulfilled, (state, action) => {
    state.ownUserDataFetchingStatus = FetchingStatus.FULFILLED;
    state.ownUserData = action.payload.detail;
  });
  builder.addCase(changeOwnUserData.rejected, state => {
    state.ownUserDataFetchingStatus = FetchingStatus.REJECTED;
  });
};
