import { Auth } from 'aws-amplify';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ChangePasswordRequestParams } from 'types';

export const changePassword = createAsyncThunk<{}, ChangePasswordRequestParams>(
  'authentication/changePassword',
  async ({ oldPassword, newPassword }, { rejectWithValue }) => {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
  },
);
