import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { FetchingStatus, SettingsState } from 'types';
import updateGeneralSettings from './updateGeneralSettings';
import { initialState } from '@features/settings/store/initialState';

const updateGeneralSettingsExtraReducer = (builder: ActionReducerMapBuilder<SettingsState>) => {
  builder.addCase(updateGeneralSettings.pending, (state, action) => {
    if (state.updateGeneralSettings) {
      state.updateGeneralSettings.fetchingStatus = FetchingStatus.PENDING;
      state.updateGeneralSettings.data = { lastUpdated: '' };
    } else {
      state['updateGeneralSettings'] = initialState.updateGeneralSettings;
    }
  });
  builder.addCase(updateGeneralSettings.fulfilled, (state, action) => {
    state.updateGeneralSettings.fetchingStatus = FetchingStatus.FULFILLED;
    state.selfRegistration = { ...state.selfRegistration, ...action.payload };
  });

  builder.addCase(updateGeneralSettings.rejected, (state, action) => {
    state.updateGeneralSettings.fetchingStatus = FetchingStatus.REJECTED;
    state.updateGeneralSettings.error = action.error;
  });
};

export default updateGeneralSettingsExtraReducer;
