import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { addUser } from './addUser';
import { FetchingStatus } from 'types';

import { UserManagementState } from '../../../interfaces';

export const addUserExtraReducer = (builder: ActionReducerMapBuilder<UserManagementState>) => {
  builder.addCase(addUser.pending, (state, action) => {
    state.addUser.fetchingStatus = FetchingStatus.PENDING;
    state.addUser.error = null;
  });
  builder.addCase(addUser.rejected, (state, action) => {
    state.addUser.fetchingStatus = FetchingStatus.REJECTED;
    state.addUser.error = action.error;
  });
  builder.addCase(addUser.fulfilled, (state, action) => {
    state.addUser.fetchingStatus = FetchingStatus.FULFILLED;
    state.addUser.error = null;
  });
};
