import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosWithConfig } from 'api';
import { CompaniesNamesResponse, PimcoreErrorResponse } from 'types';

export const getCompaniesNames = createAsyncThunk<
  CompaniesNamesResponse[],
  undefined,
  { rejectValue: PimcoreErrorResponse }
>('company/getCompaniesNames', async (_, { rejectWithValue }) => {
  const endpoint = process.env.REACT_APP_PIMCORE_ENDPOINT + `api/companies/names`;
  const axios = await axiosWithConfig();

  try {
    const { data } = await axios.get(endpoint);

    return data;
  } catch (e) {
    const error: AxiosError<PimcoreErrorResponse> = e as AxiosError<PimcoreErrorResponse>;
    const { response } = error;
    const data = response?.data as PimcoreErrorResponse;

    return rejectWithValue(data);
  }
});
