import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { FetchingStatus, SettingsState } from 'types';
import updateRegistrationForm from './updateRegistrationForm';
import { initialState } from '@features/settings/store/initialState';

const updateRegistrationFormExtraReducer = (builder: ActionReducerMapBuilder<SettingsState>) => {
  builder.addCase(updateRegistrationForm.pending, (state, action) => {
    if (state.updateRegistrationForm) {
      state.updateRegistrationForm.fetchingStatus = FetchingStatus.PENDING;
      state.updateRegistrationForm.data = { lastUpdated: '' };
    } else {
      state['updateRegistrationForm'] = initialState.updateRegistrationForm;
    }
  });
  builder.addCase(updateRegistrationForm.fulfilled, (state, action) => {
    state.updateRegistrationForm.fetchingStatus = FetchingStatus.FULFILLED;
    state.selfRegistration = { ...state.selfRegistration, ...action.payload };
  });

  builder.addCase(updateRegistrationForm.rejected, (state, action) => {
    state.updateRegistrationForm.fetchingStatus = FetchingStatus.REJECTED;
    state.updateRegistrationForm.error = action.error;
  });
};

export default updateRegistrationFormExtraReducer;
