import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosWithConfig } from 'api';
import { AxiosError } from 'axios';

import { showToast } from 'utils';
import { ChangeUserEmailRequestBody, UserManagementBaseResponse } from 'types';

export const changeUserEmail = createAsyncThunk<
  UserManagementBaseResponse,
  ChangeUserEmailRequestBody
>('userManagement/changeUserEmail', async (body, { rejectWithValue }) => {
  const endpoint = process.env.REACT_APP_USER_MANAGEMENT_ENDPOINT + 'changeUserEmail';
  const axios = await axiosWithConfig();

  try {
    const { data } = await axios.put(endpoint, body);

    if (body.isToastValid) {
      showToast(
        'success',
        {
          id: 'verify.email.resend_code_success',
          defaultMessage: 'We have sent you a new verification code',
        },
        undefined,
        undefined,
        true,
      );
    }

    return data;
  } catch (e) {
    const error: AxiosError<UserManagementBaseResponse> =
      e as AxiosError<UserManagementBaseResponse>;
    if (error.response?.data?.message !== 'NEW_EMAIL_SAME_AS_OLD_EMAIL') {
      showToast('error', {
        id: 'toast.change_user.email.error',
        defaultMessage: error.message,
      });
    }

    return rejectWithValue(error.response?.data);
  }
});
