import {
  Box,
  MenuItem,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { FetchingStatus, ListType, UserModalProps } from 'types/index';
import { useIntl } from 'react-intl';
import { useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { SettingsActions } from 'features/settings/store';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';
import Loader from 'components/Loader';
import { CCPSelect, Spacer } from 'components';
import { UserManagementSelectors } from 'features/userManagement/store/userManagement.selectors';
import { FormProvider, useForm } from 'react-hook-form';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { default as theme } from '@theme/theme';
import CCPDefaultButton from '@components/Button/Default';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const ReassignActivationLetterRecipient: React.FC<
  UserModalProps & { setUserReassigned: (value: boolean) => void }
> = ({ email, isOpen, fullName, isOwnCU, handleClose, handleSubmit, setUserReassigned }) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);
  const activationLetterDistributionList = useAppSelector(
    SettingsSelectors.emailActivationLetterDistributionList,
  );
  const activationLetterDistributionListFetchingStatus = useAppSelector(
    SettingsSelectors.activationLetterDistributionListFetchingStatus,
  );
  const adminAndInvoiceUsers = useAppSelector(rootState =>
    UserManagementSelectors.getAdminAndInvoiceUsersUniqueList(
      rootState,
      activationLetterDistributionList,
    ),
  );
  const users = useAppSelector(state => state.userManagement.users);

  const [isUserReassigned, setIsUserReassigned] = useState<boolean>(false);
  const [isReassigning, setIsReassigning] = useState<boolean>(false);

  const methods = useForm<{ selectedAdminAndInvoiceUser: string }>({
    defaultValues: {
      selectedAdminAndInvoiceUser: '',
    },
    mode: 'all',
  });

  const { control, watch } = methods;

  const selectedAdminAndInvoiceUser = watch('selectedAdminAndInvoiceUser');

  const userData =
    isUserReassigned && users.data
      ? users.data.find(item => item.email === selectedAdminAndInvoiceUser)
      : null;

  const modalData = useMemo(() => {
    if (isUserReassigned) {
      return {
        modalTitle: formatMessage({
          id: 'view.user_management.delete_and_reassign.title',
          defaultMessage: 'Another user has been assigned',
        }),
        modalContent: formatMessage(
          {
            id: 'view.user_management.delete_and_reassign.description',
            defaultMessage:
              'The user {name} {emailAddress} has been assigned for receiving the activation letters.',
          },
          {
            name: <b>{userData ? `${userData.name} ${userData.surname}` : ''}</b>,
            emailAddress: <b>({selectedAdminAndInvoiceUser})</b>,
          },
        ),
        secondaryCta: formatMessage({
          id: 'view.user_management.delete_account.cta.cancel',
          defaultMessage: 'Cancel',
        }),
        primaryCta: formatMessage({
          id: 'view.user_management.delete_self.cta.approve',
          defaultMessage: 'Yes, Delete',
        }),
      };
    }
    if (isOwnCU) {
      return {
        modalTitle: formatMessage({
          id: 'view.user_management.delete_self.last_al_recipient.title',
          defaultMessage: 'Your account can not be deleted',
        }),
        modalContent: formatMessage({
          id: 'view.user_management.delete_self.last_al_recipient.description',
          defaultMessage:
            'You’re going to delete your account from your company. Please select another user for receiving the activation letters.',
        }),
        secondaryCta: formatMessage({
          id: 'view.user_management.delete_self.cta.cancel',
          defaultMessage: 'Cancel',
        }),
        primaryCta: formatMessage({
          id: 'view.user_management.reassign_self.cta.approve',
          defaultMessage: 'Assign user',
        }),
      };
    }
    return {
      modalTitle: formatMessage({
        id: 'view.user_management.delete_account.last_al_recipient.title',
        defaultMessage: 'This account can not be deleted',
      }),
      modalContent: formatMessage(
        {
          id: 'view.user_management.delete_account.last_al_recipient.description',
          defaultMessage:
            'You’re going to delete {name} {emailAddress} from your company. Please select another user for receiving the activation letters.',
        },
        {
          name: <b>{fullName}</b>,
          emailAddress: <b>({email})</b>,
        },
      ),
      secondaryCta: formatMessage({
        id: 'view.user_management.delete_account.cta.cancel',
        defaultMessage: 'Cancel',
      }),
      primaryCta: formatMessage({
        id: 'view.user_management.reassign_self.cta.approve',
        defaultMessage: 'Assign user',
      }),
    };
  }, [
    isUserReassigned,
    isOwnCU,
    formatMessage,
    userData,
    selectedAdminAndInvoiceUser,
    fullName,
    email,
  ]);

  const modalContent = (
    <FormProvider {...methods}>
      <form>
        <Typography>{modalData.modalContent}</Typography>
        <Spacer size='xl' />
        {isUserReassigned ? (
          isOwnCU ? (
            formatMessage({
              id: 'view.user_management.deleted_self.last_al_recipient.description',
              defaultMessage: 'Delete your account from your company?',
            })
          ) : (
            formatMessage({
              id: 'view.user_management.deleted_account.last_al_recipient.description',
              defaultMessage: 'Delete this account from your company?',
            })
          )
        ) : (
          <CCPSelect
            label={formatMessage({
              id: 'user_setting.activation_code.select_company_user.dropdown',
              defaultMessage: 'Company user',
            })}
            required
            name='selectedAdminAndInvoiceUser'
            control={control}>
            {adminAndInvoiceUsers.map(user => (
              <MenuItem key={user.cognitoId} value={user.email}>
                {user.name} {user.surname} ({user.email})
              </MenuItem>
            ))}
          </CCPSelect>
        )}
      </form>
    </FormProvider>
  );

  const submitModal = async () => {
    if (isUserReassigned) {
      handleSubmit();
    } else {
      if (activeCompany) {
        const { companyId } = activeCompany;
        try {
          setIsReassigning(true);
          setUserReassigned(true);
          await dispatch(
            SettingsActions.deleteRecordFromDistributionList({
              companyId,
              email,
              listType: ListType.ACTIVATION_LETTER,
              hideSuccessToast: true,
            }),
          );
          await dispatch(
            SettingsActions.addRecordToDistributionList({
              email: selectedAdminAndInvoiceUser,
              listType: ListType.ACTIVATION_LETTER,
              hideSuccessToast: true,
            }),
          );
          setIsReassigning(false);
        } catch (ex) {
          console.log(ex);
          setIsReassigning(false);
          return;
        }
        setIsUserReassigned(true);
      }
    }
  };

  return (
    <>
      <Loader
        show={
          isReassigning ||
          (isUserReassigned &&
            activationLetterDistributionListFetchingStatus === FetchingStatus.PENDING)
        }
      />
      <Dialog open={isOpen} onClose={handleClose}>
        <CloseIcon onClick={handleClose} className='dialog-close' />
        <DialogTitle>
          <Box className='dialog-icon'>
            {isUserReassigned ? (
              <CheckCircleOutlinedIcon htmlColor={theme.palette.success.main} />
            ) : (
              <ErrorOutlineIcon htmlColor={theme.palette.error.main} />
            )}
          </Box>
          <Typography variant='h2'>{modalData.modalTitle}</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant='subtitle1'>{modalContent}</Typography>
        </DialogContent>
        <DialogActions>
          <CCPDefaultButton onClick={handleClose} variant='outlined'>
            {modalData.secondaryCta}
          </CCPDefaultButton>
          <CCPDefaultButton
            onClick={submitModal}
            variant='contained'
            color={isUserReassigned ? 'error' : 'primary'}
            disabled={selectedAdminAndInvoiceUser === ''}>
            {modalData.primaryCta}
          </CCPDefaultButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReassignActivationLetterRecipient;
