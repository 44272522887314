import { FetchingStatus } from '../../../../api/interfaces';
import { S3CredentialsState } from 'types';

export const initialState: S3CredentialsState = {
  api: {
    getS3Credentials: {
      fetchingStatus: FetchingStatus.IDLE,
      data: null,
      error: null,
      expiration: null,
    },
  },
};
