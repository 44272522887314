import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { AlertModalProps } from 'types/index';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';

import { useIntl } from 'react-intl';
import { CCPDefaultButton, Spacer } from 'components';
import { default as theme } from '@theme/theme';

const AlertModal: React.FC<AlertModalProps> = ({
  isOpen,
  handleClose,
  hideDescription = false,
  handleConfirm,
  showIcon = true,
  iconComponent = <ErrorOutlineIcon htmlColor={theme.palette.error.main} />,
  title,
  description,
  mainContent = null,
  buttonText,
  confirmButtonText,
  showConfirmButton = false,
  confirmButtonColor = 'primary',
}) => {
  const { formatMessage } = useIntl();
  return (
    <Dialog open={isOpen} onClose={handleClose} data-test-id='alertModal'>
      <CloseIcon onClick={handleClose} className='dialog-close' />
      <DialogTitle data-test-id='alertModalTitle'>
        {showIcon && <Box className='dialog-icon'>{iconComponent}</Box>}

        <Typography variant='h2' textAlign={showIcon ? 'left' : 'center'}>
          {title
            ? title
            : formatMessage({ id: 'alert.modal.title', defaultMessage: 'Error title' })}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {!hideDescription && (
          <Typography variant='subtitle1' textAlign={showIcon ? 'left' : 'center'}>
            {description
              ? description
              : formatMessage({
                  id: 'alert.modal.description',
                  defaultMessage:
                    'An error is an action which is inaccurate or incorrect. In some usage, an error is synonymous with a mistake.',
                })}
          </Typography>
        )}
        {!hideDescription && mainContent && <Spacer size='xl' />}
        {mainContent}
      </DialogContent>
      <DialogActions>
        <CCPDefaultButton
          onClick={handleClose}
          variant='outlined'
          data-test-id='alertModalCloseButton'>
          {buttonText
            ? buttonText
            : formatMessage({ id: 'alert.modal.button.close', defaultMessage: 'Close' })}
        </CCPDefaultButton>
        {showConfirmButton && (
          <CCPDefaultButton
            onClick={handleConfirm}
            variant='contained'
            color={confirmButtonColor}
            data-test-id='alertModalConfirmButton'>
            {confirmButtonText
              ? confirmButtonText
              : formatMessage({
                  id: 'alert.modal.button.confirm',
                  defaultMessage: 'Confirm',
                })}
          </CCPDefaultButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AlertModal;
