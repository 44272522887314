import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { ReactComponent as HansefitLogo } from 'assets/icons/hansefit-logo-footer.svg';

import { FooterWrapper } from './Footer.styles';
import { useIntl } from 'react-intl';
import { useLanguage } from 'hooks';

const Footer = () => {
  const intl = useIntl();
  const language = useLanguage();

  return (
    <FooterWrapper>
      <Box>
        <HansefitLogo className="logo" />
        <a
          className="link"
          href={intl.formatMessage({
            id: 'footer.links.contact.hansefit_link_url',
            defaultMessage: 'https://hansefit.de',
          })}
          rel="noreferrer"
          target="_blank">
          {intl.formatMessage({
            id: 'footer.links.contact.link',
            defaultMessage: 'www.hansefit.de_Link',
          })}
        </a>
        <a
          target="_blank"
          className="link"
          rel="noreferrer"
          href={intl.formatMessage({
            id: 'footer.links.contact.contact_link_url',
            defaultMessage: '/',
          })}>
          {intl.formatMessage({ id: 'footer.links.contact.text', defaultMessage: 'Contact' })}
        </a>
        <Link className="link" to={`/${language}/support`}>
          {intl.formatMessage({ id: 'footer.links.contact.faq', defaultMessage: 'FAQ' })}
        </Link>
      </Box>
      <Box>
        <a
          className="link"
          href={intl.formatMessage({
            id: 'footer.links.contact.imprint_link_url',
            defaultMessage: 'https://hansefit.de/impressum',
          })}
          rel="noreferrer"
          target="_blank">
          {intl.formatMessage({ id: 'footer.links.contact.imprint', defaultMessage: 'Imprint' })}
        </a>
        <a
          className="link"
          href={intl.formatMessage({
            id: 'footer.links.contact.privacy_link_url',
            defaultMessage: 'https://hansefit.de/datenschutz',
          })}
          rel="noreferrer"
          target="_blank">
          {intl.formatMessage({ id: 'footer.links.contact.privacy', defaultMessage: 'Privacy' })}
        </a>
        <a
          className="link"
          href={intl.formatMessage({
            id: 'footer.links.contact.terms_link_url',
            defaultMessage: 'https://static.hansefit.de/partner_nutzungsbedingungen.html',
          })}
          rel="noreferrer"
          target="_blank">
          {intl.formatMessage({ id: 'footer.links.contact.terms', defaultMessage: 'Terms of Use' })}
        </a>
      </Box>
    </FooterWrapper>
  );
};

export default Footer;
