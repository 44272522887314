import { useCallback, useState } from 'react';
import { CCPDefaultButton, Spacer } from 'components';
import {
  Box,
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { QRCodeCanvas } from 'qrcode.react';
import { useIntl } from 'react-intl';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { useAppSelector } from 'store';
import { useNavigate } from 'react-router';
import { useLanguage } from 'hooks';
import { ShareLinkWrapper } from '../DialogDefault.styles';

export const GetSignupLinkModal = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const language = useLanguage();
  const signupIdentifier = useAppSelector(
    state => state.settings.selfRegistration.signupIdentifier,
  );
  const [open, setOpen] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [showQrCode, setShowQrCode] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
    navigator.clipboard.writeText(`${process.env.REACT_APP_SIGNUP_URL}${signupIdentifier}`);
  };

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const qrCodeToggler = () => setShowQrCode(!showQrCode);

  const downloadQR = () => {
    const canvas: any = document.querySelector('.qrCodeImg > canvas');
    const link = document.createElement('a');
    link.href = canvas.toDataURL();
    link.download = 'QR.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const goToSettings = () => {
    navigate(`/${language}/settings/2`);
  };

  return (
    <>
      <CCPDefaultButton
        onClick={handleOpen}
        variant='outlined'
        fullWidth
        data-test-id='getSignupLinkButton'>
        {intl.formatMessage({
          id: 'employee.modal.get_signup_page_link.button',
          defaultMessage: 'Get Signup page link',
        })}
      </CCPDefaultButton>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {intl.formatMessage({
            id: 'employee.modal.get_signup_page_link.title',
            defaultMessage: 'Get Signup page link',
          })}
        </DialogTitle>

        <DialogContent>
          <Typography variant='subtitle1'>
            {intl.formatMessage({
              id: 'employee.modal.get_signup_page_link.content',
              defaultMessage: 'Share link or QR code with everyone in your company',
            })}
          </Typography>
          <Spacer size='xl' />
          {!showQrCode && (
            <ShareLinkWrapper>
              <Typography variant='body1'>{`${process.env.REACT_APP_SIGNUP_URL}${signupIdentifier}`}</Typography>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={openTooltip}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={intl.formatMessage({
                      id: 'user_setting.self_registration.invitation_settings.copiedTooltip',
                      defaultMessage: 'Link was copied',
                    })}>
                    <Button variant='text' startIcon={<CopyIcon />} onClick={handleTooltipOpen}>
                      {intl.formatMessage({
                        id: 'user_setting.self_registration.invitation_settings.copyButton',
                        defaultMessage: 'Copy',
                      })}
                    </Button>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            </ShareLinkWrapper>
          )}
          {showQrCode && (
            <Box display='flex' justifyContent='center' className='qrCodeImg'>
              <QRCodeCanvas
                value={`${process.env.REACT_APP_SIGNUP_URL}${signupIdentifier}`}
                size={256}
              />
            </Box>
          )}
          <Spacer size='md' />
          <Stack direction='row' gap='15px' justifyContent={showQrCode ? 'center' : 'left'}>
            <CCPDefaultButton variant='outlined' onClick={qrCodeToggler}>
              {showQrCode
                ? intl.formatMessage({
                    id: 'user_setting.self_registration.invitation_settings.qrCodeHide',
                    defaultMessage: 'Show Signup link',
                  })
                : intl.formatMessage({
                    id: 'user_setting.self_registration.invitation_settings.qrCodeButton',
                    defaultMessage: 'Get QR code',
                  })}
            </CCPDefaultButton>
            {showQrCode && (
              <CCPDefaultButton variant='contained' onClick={downloadQR}>
                {intl.formatMessage({
                  id: 'user_setting.self_registration.qrCode.modal.cta.download',
                  defaultMessage: 'Download',
                })}
              </CCPDefaultButton>
            )}
          </Stack>
        </DialogContent>

        <DialogActions>
          <CCPDefaultButton variant='outlined' onClick={handleClose}>
            {intl.formatMessage({ id: 'form.employee.cta.cancel', defaultMessage: 'Cancel' })}
          </CCPDefaultButton>
          <CCPDefaultButton variant='contained' onClick={goToSettings}>
            {intl.formatMessage({
              id: 'form.employee.cta.go_to_settings',
              defaultMessage: 'Go to Settings',
            })}
          </CCPDefaultButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GetSignupLinkModal;
