import { useState, useMemo } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Avatar, MenuItem } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'store';
import { fullNameToAvatar } from 'utils';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { AuthenticationActions } from 'features/authentication/store';

import { HeaderWrapper, MenuWrapper } from './Header.styles';
import { useIntl } from 'react-intl';
import { CompanyActions } from 'features/company/store';
import { useLanguage } from 'hooks';
import { SettingsSelectors } from '@features/settings/store/settings.selectors';
import { IUserInfo } from '@localTypes/index';
import { AuthenticationSelectors } from '@features/authentication/store/authentication.selectors';

const Header: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const auth = useAppSelector(AuthenticationSelectors.getAuthentication);
  const ownUserData = useAppSelector(SettingsSelectors.getOwnUserData);
  const userInfo = useMemo(
    () => ownUserData?.userdetails || (auth.userInfo as IUserInfo),
    [ownUserData, auth],
  );

  const { activeCompany, companiesNames } = useAppSelector(CompanySelectors.getCompanyState);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const fullName = useMemo(
    () =>
      userInfo?.name || userInfo?.surname ? `${userInfo.name || ''} ${userInfo.surname || ''}` : '',
    [userInfo],
  );

  const avatarLetters = useMemo(
    () => fullNameToAvatar(userInfo?.name, userInfo?.surname),
    [userInfo],
  );

  const activeLanguage = useLanguage();

  const openModalHandlers = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const closeModalHandler = (): void => {
    setAnchorEl(null);
  };

  const updateRoute = (index: number): void => {
    if (companiesNames.data.length > 1) dispatch(CompanyActions.updateActiveCompany(null));
    navigate({
      pathname: `/${activeLanguage}/account`,
      search: `?activeIndex=${index}`,
    });
    closeModalHandler();
  };

  const logoutHandler = () => {
    dispatch(AuthenticationActions.signOut());
    navigate(`/${activeLanguage}/login`);
    closeModalHandler();
  };

  const isNotProdEnv = !process.env.REACT_APP_PIMCORE_ENDPOINT?.includes('prod');

  return (
    <HeaderWrapper data-test-id='pageHeader'>
      <Typography className='title notranslate' data-test-id='companyNameTitle' variant='body1'>
        {activeCompany?.companyName}
      </Typography>
      <Box className='user'>
        <Typography className='user__name notranslate' variant='body1'>
          <Typography variant='subtitle2' className='version'>
            {isNotProdEnv && process.env.REACT_APP_VERSION}
          </Typography>
          {fullName}
        </Typography>
        <Avatar
          data-test-id='userAvatar'
          onClick={openModalHandlers}
          className={clsx('user__avatar', { user__avatar_active: open })}>
          {avatarLetters}
        </Avatar>
        <MenuWrapper
          anchorEl={anchorEl}
          open={open}
          onClose={closeModalHandler}
          data-test-id='userDropdown'>
          <MenuItem onClick={() => updateRoute(0)}>
            {intl.formatMessage({
              id: 'header.dropdown.profile_settings',
              defaultMessage: 'Profile settings',
            })}
          </MenuItem>
          <MenuItem onClick={logoutHandler}>
            {intl.formatMessage({ id: 'header.dropdown.logout', defaultMessage: 'Log out' })}
          </MenuItem>
        </MenuWrapper>
      </Box>
    </HeaderWrapper>
  );
};

export default Header;
