import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { FetchingStatus, ListType, UserModalProps } from 'types/index';
import { useIntl } from 'react-intl';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { SettingsActions } from 'features/settings/store';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';
import Loader from 'components/Loader';
import ReassignActivationLetterRecipient from 'components/Modal/ReassignActivationLetterRecipient';
import { default as theme } from '@theme/theme';
import CCPDefaultButton from '@components/Button/Default';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const DeleteAccountModal: React.FC<UserModalProps> = ({
  email,
  isOpen,
  fullName,
  isOwnCU,
  handleClose,
  handleSubmit,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);
  const activationLetterDistributionList = useAppSelector(
    SettingsSelectors.emailActivationLetterDistributionList,
  );
  const activationLetterDistributionListFetchingStatus = useAppSelector(
    SettingsSelectors.activationLetterDistributionListFetchingStatus,
  );
  const { activationLetterRecipientMode } = useAppSelector(SettingsSelectors.settingsSettings);

  const [isLastUserWithActivationAccess, setIsLastUserWithActivationAccess] =
    useState<boolean>(false);
  const [userReassigned, setUserReassigned] = useState<boolean>(false);

  useEffect(() => {
    if (
      activationLetterDistributionListFetchingStatus !== FetchingStatus.PENDING &&
      !userReassigned
    ) {
      if (
        activationLetterRecipientMode.toLowerCase() === 'cu' &&
        activationLetterDistributionList.length <= 1 &&
        activationLetterDistributionList.filter(item => item === email).length !== 0
      ) {
        setIsLastUserWithActivationAccess(true);
      } else {
        setIsLastUserWithActivationAccess(false);
      }
    }
  }, [
    activationLetterDistributionListFetchingStatus,
    activationLetterDistributionList,
    activationLetterRecipientMode,
    email,
    userReassigned,
  ]);

  useEffect(() => {
    if (activeCompany) {
      const { companyId } = activeCompany;

      dispatch(
        SettingsActions.getDistributionList({ companyId, listType: ListType.ACTIVATION_LETTER }),
      );
    }
  }, [dispatch, activeCompany]);

  const modalData = useMemo(() => {
    if (isOwnCU) {
      return {
        modalTitle: formatMessage({
          id: 'view.user_management.delete_self.title',
          defaultMessage: 'Delete my own account',
        }),
        modalContent: formatMessage({
          id: 'view.user_management.delete_self.text',
          defaultMessage:
            'You are about to delete your own account from your company. You will be logged out of the system and all the data associated with your account will be lost. Continue?',
        }),
        secondaryCta: formatMessage({
          id: 'view.user_management.delete_self.cta.cancel',
          defaultMessage: 'Cancel',
        }),
        primaryCta: formatMessage({
          id: 'view.user_management.delete_self.cta.approve',
          defaultMessage: 'Yes, Delete',
        }),
      };
    }
    return {
      modalTitle: formatMessage({
        id: 'view.user_management.delete_account.title',
        defaultMessage: 'Delete user account from copmpany',
      }),
      modalContent: formatMessage(
        {
          id: 'view.user_management.delete_account.text',
          defaultMessage:
            'You are going to delete {name} {emailAddress} from your company. All data associated with this member will be lost. Continue?',
        },
        { name: <b>{fullName}</b>, emailAddress: <b>({email})</b> },
      ),
      secondaryCta: formatMessage({
        id: 'view.user_management.delete_account.cta.cancel',
        defaultMessage: 'Cancel',
      }),
      primaryCta: formatMessage({
        id: 'view.user_management.delete_account.cta.approve',
        defaultMessage: 'Yes, Delete',
      }),
    };
  }, [isOwnCU, formatMessage, fullName, email]);

  return (
    <>
      <Loader
        show={
          activationLetterDistributionListFetchingStatus === FetchingStatus.PENDING &&
          !userReassigned
        }
      />
      {isLastUserWithActivationAccess ? (
        <ReassignActivationLetterRecipient
          email={email}
          isOpen={true}
          fullName={fullName}
          isOwnCU={isOwnCU}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          setUserReassigned={setUserReassigned}
        />
      ) : (
        <Dialog open={isOpen} onClose={handleClose}>
          <CloseIcon onClick={handleClose} className='dialog-close' />
          <DialogTitle>
            <Box className='dialog-icon'>
              <ErrorOutlineIcon htmlColor={theme.palette.error.main} />
            </Box>
            <Typography variant='h2'>{modalData.modalTitle}</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant='subtitle1'>{modalData.modalContent}</Typography>
          </DialogContent>
          <DialogActions>
            <CCPDefaultButton onClick={handleClose} variant='outlined'>
              {modalData.secondaryCta}
            </CCPDefaultButton>
            <CCPDefaultButton
              onClick={handleSubmit}
              variant='contained'
              color={!isLastUserWithActivationAccess ? 'error' : 'primary'}>
              {modalData.primaryCta}
            </CCPDefaultButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default DeleteAccountModal;
