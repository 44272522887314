import { Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { useIntl } from 'react-intl';
import { AlertModal, Spacer, Tickbox } from 'components';
import { default as theme } from '@theme/theme';
import { useEffect, useMemo, useState } from 'react';
import { SettingsActions } from '@features/settings/store';
import { useAppDispatch, useAppSelector } from '@store/index';
import { ListType } from '@localTypes/settings';
import { AuthenticationSelectors } from '@features/authentication/store/authentication.selectors';
import Loader from '@components/Loader';
import { FetchingStatus } from '@api/interfaces';

export type AutomaticApprovalAlertProps = {
  handleClose: () => void;
};

const AutomaticApprovalAlert: React.FC<AutomaticApprovalAlertProps> = ({ handleClose }) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const [addUserToList, setAddUserToList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { userInfo } = useAppSelector(AuthenticationSelectors.getAuthentication);
  const { data: distributionList, fetchingStatus } = useAppSelector(
    store => store.settings.distributionLists[ListType.NEW_EU_REQUEST],
  );

  const userInList = useMemo(
    () => distributionList.filter(users => users.email === userInfo?.email).length > 0,
    [userInfo, distributionList],
  );

  useEffect(() => {
    if (userInList) {
      setAddUserToList(true);
    }
  }, [userInList, setAddUserToList]);

  useEffect(() => {
    dispatch(SettingsActions.getDistributionList({ listType: ListType.NEW_EU_REQUEST }));
  }, [dispatch]);

  const tickboxChange = () => {
    setAddUserToList(prev => !prev);
  };

  const doSave = async (e?: {}) => {
    const event = e as React.ChangeEvent<HTMLInputElement>;
    if (event.currentTarget.getAttribute('type') === 'button' && userInfo) {
      setIsLoading(true);
      if (addUserToList && !userInList) {
        await dispatch(
          SettingsActions.addRecordToDistributionList({
            email: userInfo.email,
            listType: ListType.NEW_EU_REQUEST,
          }),
        );
      } else if (!addUserToList && userInList) {
        await dispatch(
          SettingsActions.deleteRecordFromDistributionList({
            email: userInfo.email,
            listType: ListType.NEW_EU_REQUEST,
          }),
        );
      }
      setIsLoading(true);
    }
    handleClose();
  };
  return fetchingStatus === FetchingStatus.PENDING || isLoading ? (
    <Loader
      inCard
      portalId='SelfRegistrationWrapper'
      show={fetchingStatus === FetchingStatus.PENDING || isLoading}
    />
  ) : (
    <AlertModal
      iconComponent={<ErrorOutlineIcon htmlColor={theme.palette.warning.main} />}
      isOpen
      handleClose={doSave}
      title={formatMessage({
        id: 'user_setting.self_registration.approvalAlertTitle',
        defaultMessage: 'Please note',
      })}
      hideDescription
      mainContent={
        <>
          <Typography variant='subtitle1'>
            {formatMessage({
              id: 'user_setting.self_registration.approvalAlertDescription',
              defaultMessage:
                'Please note that manually activating employee requests may delay the start of training. Therefore, check new requests regularly',
            })}
          </Typography>
          <Spacer size='xl' />
          <Tickbox
            label={formatMessage({
              id: 'user_setting.self_registration.general_settings.get_notification_for_new_requests',
              defaultMessage: 'Get email notifications for new requests.',
            })}
            value={addUserToList}
            fieldName='addUserToList'
            handleChange={tickboxChange}
          />
        </>
      }
      buttonText={formatMessage({
        id: 'user_setting.self_registration.alert.ctaConfirm',
        defaultMessage: 'OK',
      })}
    />
  );
};

export default AutomaticApprovalAlert;
