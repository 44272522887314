import { AxiosRequestHeaders } from 'axios';

export interface AxiosConfig {
  withAuthentication?: boolean;
  headers?: AxiosRequestHeaders;
}

export interface BaseErrorResponse {
  statusCode: number;
  status: string;
  message: string;
}
export interface UserManagementBaseResponse {
  statusCode: number;
  status: string;
  message: string;
}

export interface MarketingMaterialsManagementBaseResponse {
  statusCode: number;
  status: string;
  message: string;
}

export interface PimcoreErrorResponse {
  detail: string;
  title: string;
  type: string;
  violations?: PimcoreViolation[];
}

export interface PimcoreViolation {
  propertyPath: string;
  message: string;
  code: string;
}
export enum FetchingStatus {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  REJECTED = 'REJECTED',
}
