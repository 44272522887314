import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { FetchingStatus } from 'types';

import { forgotPassword } from './forgotPassword';
import { AuthenticationState } from '../../../interfaces';

export const forgotPasswordExtraReducers = (
  builder: ActionReducerMapBuilder<AuthenticationState>,
) => {
  builder.addCase(forgotPassword.pending, (state, action) => {
    state.forgotPassword.fetchingStatus = FetchingStatus.PENDING;
    state.forgotPassword.error = null;
  });
  builder.addCase(forgotPassword.fulfilled, (state, action) => {
    state.forgotPassword.fetchingStatus = FetchingStatus.FULFILLED;
    state.forgotPassword.error = null;
    state.forgotPassword.activeStep = 2;
  });
  builder.addCase(forgotPassword.rejected, (state, action) => {
    state.forgotPassword.fetchingStatus = FetchingStatus.REJECTED;
    state.forgotPassword.error = action.error;
  });
};
