import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToast } from 'utils';
import { axiosWithConfig } from 'api';
import { RootState, BaseErrorResponse, ISelfRegistration } from 'types';

export const getRegistrationSettings = createAsyncThunk<any, { companyId?: number }>(
  'settings/getRegistrationSettings',
  async ({ companyId }, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    companyId = companyId || state.company.activeCompany?.companyId;
    const endpoint = `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/companies/sp-settings/${companyId}`;

    const axios = await axiosWithConfig();

    try {
      let { data } = await axios.get(endpoint);

      Object.keys(data).forEach(key => {
        if (typeof data[key] === 'string') {
          const convertedKey = data[key].trim().toLowerCase();
          if (convertedKey === 'yes' || convertedKey === 'no') {
            data[key] = convertedKey === 'yes';
          }
        }
      });
      data['enable'] = data['password'] || data['invitationRequired'];
      data['passwordRequired'] = data['password'];
      data['signupPassword'] = data.password ? data['signupPassword'] : '';
      data['allowSEPA'] = data['paymentProviders'].includes('sepa');
      if (!data['domainValidation']) data['validEmailDomains'] = '';
      return data as ISelfRegistration;
    } catch (e) {
      const error: AxiosError<BaseErrorResponse> = e as AxiosError<BaseErrorResponse>;
      // showToast('error', {
      //   id: 'toast.registration_settings.get_data.error',
      //   defaultMessage: error.message,
      // });
      return rejectWithValue(error.response?.data);
    }
  },
);
