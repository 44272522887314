import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { signInExtraReducers } from './actions/async/signIn/signIn.reducers';
import { signOutExtraReducers } from './actions/async/signOut/signOut.reducers';
import { getUserInfoExtraReducers } from './actions/async/getUserInfo/getUserInfo.reducers';
import { forgotPasswordExtraReducers } from './actions/async/forgotPassword/forgotPassword.reducers';
import { forgotPasswordSubmitExtraReducers } from './actions/async/forgotPasswordSubmit/forgotPasswordSubmit.reducers';
import {
  setActiveStep,
  resetAuthState,
  openSideBarMenu,
  setActiveEmail,
} from './actions/sync/resetPassword.actions';
import { changeDistributionList } from './actions/sync/changeDistributionList.action';
import { updateUsersLanguage } from './actions/sync/updateUsersLanguage.action';

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    updateUsersLanguage,
    changeDistributionList,
    setActiveStep,
    resetAuthState,
    openSideBarMenu,
    setActiveEmail,
  },
  extraReducers: builder => {
    signInExtraReducers(builder);
    signOutExtraReducers(builder);
    getUserInfoExtraReducers(builder);
    forgotPasswordExtraReducers(builder);
    forgotPasswordSubmitExtraReducers(builder);
  },
});

export const { name, actions } = authenticationSlice;
export const authenticationReducer = authenticationSlice.reducer;
