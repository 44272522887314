import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosWithConfig } from 'api';
import {
  GetS3CredentialsResponse,
  PimcoreErrorResponse,
  GetS3CredentialsCompanyParams,
} from 'types';
import { AxiosError } from 'axios';

export const getS3CredentialsCompany = createAsyncThunk<
  GetS3CredentialsResponse,
  GetS3CredentialsCompanyParams,
  { rejectValue: PimcoreErrorResponse }
>('s3Credentials/getS3CredentialsCompany', async ({ companyId }, { rejectWithValue }) => {
  const endpoint =
    process.env.REACT_APP_USER_ACCESS_ENDPOINT + `resourceAuth/companyAssetsS3/${companyId}`;
  const axios = await axiosWithConfig();

  try {
    return (await axios.get(endpoint)).data;
  } catch (e) {
    const error: AxiosError<PimcoreErrorResponse> = e as AxiosError<PimcoreErrorResponse>;
    const { response } = error;
    const data = response?.data as PimcoreErrorResponse;

    return rejectWithValue(data);
  }
});
