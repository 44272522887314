import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { FetchingStatus, ListType } from 'types';

import { SettingsState } from '../../../interfaces';
import { getDistributionList } from './getDistributionList';

export const getDistributionListExtraReducer = (
  builder: ActionReducerMapBuilder<SettingsState>,
) => {
  builder.addCase(getDistributionList.pending, (state, action) => {
    if (action.meta.arg.listType) {
      state.distributionLists[action.meta.arg.listType].fetchingStatus = FetchingStatus.PENDING;
      state.distributionLists[action.meta.arg.listType].error = null;
    } else {
      (Object.keys(state.distributionLists) as ListType[]).forEach(listType => {
        state.distributionLists[listType].fetchingStatus = FetchingStatus.PENDING;
        state.distributionLists[listType].error = null;
      });
    }
  });
  builder.addCase(getDistributionList.fulfilled, (state, action) => {
    action.payload.listTypes.map((listType: ListType, i: number) => {
      if (action.payload.values[i]) {
        state.distributionLists[listType].fetchingStatus = FetchingStatus.FULFILLED;
        state.distributionLists[listType].data = action.payload.values[i];
        state.distributionLists[listType].externalData = action.payload.externalValues[i];
      } else {
        state.distributionLists[listType].fetchingStatus = FetchingStatus.REJECTED;
        state.distributionLists[listType].error = { message: 'CUSTOM_ERROR' };
      }
    });
  });

  builder.addCase(getDistributionList.rejected, (state, action) => {
    if (action.meta.arg.listType) {
      state.distributionLists[action.meta.arg.listType].fetchingStatus = FetchingStatus.REJECTED;
    } else {
      (Object.keys(state.distributionLists) as ListType[]).forEach(listType => {
        state.distributionLists[listType].fetchingStatus = FetchingStatus.REJECTED;
      });
    }
    state.error = action.error;
  });
};
