import MainCard from '@components/MainCard';
import { styled } from '@mui/material';

const MainBlock = styled(MainCard)(({ theme }) => ({
  '.allow_format_description': {
    marginLeft: 28,
    display: 'block',
  },
  '.cursor_hand': {
    cursor: 'pointer',
  },
  '.imageWrapper': {
    position: 'relative',
    maxWidth: '290px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '.removeIcon': {
      position: 'absolute',
      top: '0',
      right: '0',
      padding: '12px',
      width: '50px',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.transparent.dark05,
      cursor: 'pointer',
    },
  },
  '.imgLogo': {
    maxWidth: 290,
    maxHeight: 140,
  },
  '.logoImage, .colorImage': {
    fontSize: '60px',
    backgroundColor: theme.palette.primaryLight.main,
    width: '290px',
    height: '140px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.neutralDark.main,
  },
  '.logoImage svg, .colorImage svg': {
    //opacity: 0.3,
    marginBottom: 35,
  },
  '.logoImage svg.editMode': {
    marginBottom: 0,
  },
  '.colorImage': {
    width: '400px',
  },
  '.emptyImagesTitle': {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  '.colorPicker button': {
    position: 'absolute',
    width: 56,
    height: 56,
    top: 0,
    right: -80,
    fieldset: {
      borderWidth: '1px !important',
    },
  },
}));

export default MainBlock;
