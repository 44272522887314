import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosWithConfig } from 'api';
import { AxiosError } from 'axios';
import {
  DeleteCompanyCustomFieldRequestParams,
  CompanyCustomFieldType,
  PimcoreErrorResponse,
} from 'types';
import { showToast } from 'utils';

export const deleteCompanyCustomField = createAsyncThunk<
  CompanyCustomFieldType,
  DeleteCompanyCustomFieldRequestParams,
  { rejectValue: PimcoreErrorResponse }
>(
  'settings/deleteCompanyCustomField',
  async ({ companyId, customFieldId }, { rejectWithValue }) => {
    const axios = await axiosWithConfig();
    const endpoint =
      process.env.REACT_APP_PIMCORE_ENDPOINT +
      `api/companies/${companyId}/custom-fields/${customFieldId}`;
    try {
      const { data } = await axios.delete(endpoint);

      return data;
    } catch (e) {
      const error: AxiosError<PimcoreErrorResponse> = e as AxiosError<PimcoreErrorResponse>;
      const { response } = error;
      const data = response?.data as PimcoreErrorResponse;

      showToast('error', data.detail);

      return rejectWithValue(data);
    }
  },
);
