import { styled, Button, Select, MenuItem } from '@mui/material';

export const FileInput = styled('input')(() => ({
  height: '0',
  width: '0',
  overflow: 'hidden',
  visibility: 'hidden',
}));

export const IconContainer = styled('div')(({ theme }) => ({
  width: 'fit-content',
  marginBottom: 10,
  svg: {
    padding: 1,
    height: 40,
    width: 40,
    fill: theme.palette.error.main,
    position: 'relative',
    top: 3,
  },
}));

export const RoundedCornerButton = styled(Button)(() => ({
  fontWeight: 'bold',
  lineHeight: '22px',
  borderRadius: '25px',
  marginLeft: '25px',
  marginRight: '15px',
  textTransform: 'none',
}));

export const RoundedCornerSelect = styled(Select)(({ theme }) => ({
  fontWeight: 'bold',
  lineHeight: 1.38,
  borderRadius: '25px',
  marginLeft: '15px',
  marginRight: '15px',
  textTransform: 'none',
  color: theme.palette.primary.main,
  fontSize: 16,
  '& .MuiSelect-select ': {
    padding: '7px 16px',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
  },
  '&.openSelect': {
    backgroundColor: theme.palette.transparent.dropdownSelected,
  },
  '& .MuiOutlinedInput-notchedOutline, &:hover > .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
    borderWidth: '1px!important',
  },
}));
export const MenuITemSelect = styled(MenuItem)(() => ({
  '&.default': {
    display: 'none',
  },
}));
export const UploadTabContainer = styled('div')(() => ({
  marginTop: '50px',
}));

export const InfoAlert = styled('div')(({ theme }) => ({
  background: 'none',
  color: theme.palette.neutralDark.contrastText,
  textAlign: 'left',
  border: `1px solid  ${theme.palette.neutralLight.dark}`,
  padding: 16,
  display: 'flex',
  borderRadius: 4,
  fontSize: 14,
  strong: {
    marginLeft: 5,
  },
}));

export const CardTitle = styled('h1')(() => ({
  fontFamily: 'NutmegHeadline-UltraBlack',
  fontSize: 20,
}));

export const CardSubtitle = styled('p')(() => ({
  fontSize: 16,
}));

export const InitStep = styled('div')(() => ({
  paddingLeft: 280,
  paddingRight: 280,
}));
