import { styled, Box } from '@mui/material';

export const ServiceWrapper = styled(Box)(({ theme }) => ({
  '& .content_wrapper': {
    background: theme.palette.background.paper,
    padding: '36px',
    borderRadius: 4,
    display: 'grid',
    gridGap: 36,
    gridTemplateColumns: 'repeat(2,1fr)',
  },

  '& .content': {
    borderRadius: 8,
    border: `2px solid ${theme.palette.primaryLight.main}`,
    display: 'flex',
    flexDirection: 'column',
  },
  '& .imgWrapper': {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'center',
    '& img': {
      width: 326,
      height: 440,
    },
  },
}));
