export interface BulkActionsForm {
  startDate?: Date | null | string;
  endDate?: Date | null | string;
  removeEmployeeWarning?: boolean;
}

export enum EmployeeBulkActions {
  DEFAULT = 'Bulk actions',
  RESEND = 'Resend activation letter',
  PAUSE = 'Pause membership',
  EDIT_START_DATE = 'Edit start date',
  REACTIVATE = 'Reactivate membership',
  CONFIRM = 'Confirm Request',
  DENY = 'Deny Request',
  DEACTIVATE = 'Deactivate',
  REMOVE = 'Remove',
}
