import { styled, Box } from '@mui/material';

export const CompanyCustomFieldWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  '.new': {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 30,

    button: {
      minWidth: 216,
    },
  },

  '.tabs': {
    gap: 24,
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 11,
    marginBottom: 5,
    borderBottom: `1px solid ${theme.palette.primaryLight.main}`,

    '&__item': {
      fontFamily: 'Roboto-Medium',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.38,
      color: theme.palette.neutralDark.contrastText,
      cursor: 'pointer',
      position: 'relative',

      '&_active': {
        color: theme.palette.primary.main,

        '&::after': {
          content: '""',
          width: '100%',
          height: 4,
          background: theme.palette.primary.main,
          position: 'absolute',
          left: 0,
          bottom: -11,
        },
      },
    },
  },
}));
