import { FormControlLabel, styled, Switch } from '@mui/material';

export const TickboxWrapper = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: 0,
}));

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 24,
  height: 16,
  padding: 0,
  display: 'flex',

  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },

    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },

  '& .MuiSwitch-switchBase': {
    padding: 3,

    '&.Mui-checked': {
      transform: 'translateX(7px)',
      color: theme.palette.primary.contrastText,

      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },

  '& .MuiSwitch-thumb': {
    boxShadow: `0 2px 4px 0 ${theme.palette.transparent.boxShadowDark02}`,
    width: 10,
    height: 10,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },

  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.transparent.boxShadowDark02,
    boxSizing: 'border-box',
  },
}));
