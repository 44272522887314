import { styled } from '@mui/material';

export const FooterWrapper = styled('footer')(({ theme }) => ({
  padding: '13px 24px 14px',
  background: theme.palette.primaryLight.main,
  color: theme.palette.neutralDark.contrastText,
  gap: 16,
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  div: {
    gap: 24,
    display: 'flex',
  },

  '.link': {
    fontSize: 14,
    lineHeight: 1.57,
    letterSpacing: 0.13,
    color: theme.palette.neutralDark.main,
    textDecoration: 'unset',

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  '.logo': {
    marginRight: 24,
  },
}));
