import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToast } from 'utils';
import { axiosWithConfig } from 'api';
import { UserManagementBaseResponse, GetCouponsResponse, GetCheckInsRequestParams } from 'types';

export const getCoupons = createAsyncThunk<GetCouponsResponse, GetCheckInsRequestParams>(
  'stats/getCoupons',
  async ({ companyId, startMonth, endMonth }, { rejectWithValue }) => {
    const endpoint =
      process.env.REACT_APP_STATISTICS_ENDPOINT +
      `coupon?companyId=${companyId}&startMonth=${startMonth}&endMonth=${endMonth}&explain=true`;
    const axios = await axiosWithConfig();

    try {
      const { data } = await axios.get(endpoint);

      const couponsList = {
        couponsList: data.data,
        explain: data.explain,
      };

      return couponsList;
    } catch (e) {
      const error: AxiosError<UserManagementBaseResponse> =
        e as AxiosError<UserManagementBaseResponse>;
      showToast('error', {
        id: 'toast.coupons.get.error',
        defaultMessage: error.message,
      });
      return rejectWithValue(error.response?.data);
    }
  },
);
