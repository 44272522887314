import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { FetchingStatus } from 'api/interfaces';
import { SelfRegistrationState } from '../../../interfaces';
import { getSignUpKeyData } from './getSignUpKeyData';

export const getSignUpKeyDataExtraReducer = (
  builder: ActionReducerMapBuilder<SelfRegistrationState>,
) => {
  builder.addCase(getSignUpKeyData.pending, (state, action) => {
    state.api.getSignUpKeyData.fetchingStatus = FetchingStatus.PENDING;
    state.api.getSignUpKeyData.data = null;
    state.api.getSignUpKeyData.error = null;
  });
  builder.addCase(getSignUpKeyData.fulfilled, (state, action) => {
    state.api.getSignUpKeyData.fetchingStatus = FetchingStatus.FULFILLED;
    state.api.getSignUpKeyData.data = action.payload.detail;
  });

  builder.addCase(getSignUpKeyData.rejected, (state, action) => {
    state.api.getSignUpKeyData.fetchingStatus = FetchingStatus.REJECTED;
    state.api.getSignUpKeyData.error = action.error;
  });
};
