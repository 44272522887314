import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { FetchingStatus, SettingsState } from 'types';
import getMFASetting from './getMFASetting';

const getMFASettingReducer = (builder: ActionReducerMapBuilder<SettingsState>) => {
  builder.addCase(getMFASetting.pending, (state, action) => {
    state.security.mfa.fetchingStatus = FetchingStatus.PENDING;
  });
  builder.addCase(getMFASetting.fulfilled, (state, action) => {
    state.security.mfa.fetchingStatus = FetchingStatus.FULFILLED;
    state.security.mfa.data = action.payload;
  });

  builder.addCase(getMFASetting.rejected, (state, action) => {
    state.security.mfa.fetchingStatus = FetchingStatus.REJECTED;
    state.security.mfa.error = action.error;
  });
};

export default getMFASettingReducer;
