import { Box, styled } from '@mui/material';
export const SettingLink = styled(Box)(({ theme }) => ({
  marginTop: 9,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.main,
  '& p': {
    fontFamily: 'Roboto-Medium',
    fontWeight: 500,
    lineHeight: 1.38,
    marginLeft: 6.6,
  },
}));
