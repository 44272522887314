import {
  GridCsvExportMenuItem,
  GridExportMenuItemProps,
  GridToolbarExportContainer,
  GridValidRowModel,
  gridFilteredSortedRowIdsSelector,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';
import { MenuItem } from '@mui/material';
import { useIntl } from 'react-intl';
import * as XLSX from 'xlsx';
import { ColumnData } from '@hooks/useTableColumns';
import { GRID_ACTIONS_COLUMN_NAME } from '..';

type Dictionary = Record<string, any>;

const ExportList: React.FC<{
  showExportExcel?: boolean;
}> = <T extends GridValidRowModel>(props: any) => {
  const apiRef = useGridApiContext();
  const columnDefinition: ColumnData<T>[] = apiRef?.current?.getVisibleColumns() || [];
  const fields = columnDefinition
    .filter(header => header.field !== '__check__' && header.field !== GRID_ACTIONS_COLUMN_NAME)
    .map(header => header.field);

  if (!fields.length) return null;

  return (
    <GridToolbarExportContainer slotProps={{ button: { className: 'grid__button' } }} {...props}>
      <GridCsvExportMenuItem options={{ fields }} />
      {props.showExportExcel && <ExcelExportItem fields={fields} />}
    </GridToolbarExportContainer>
  );
};

export default ExportList;

interface ExcelExportItemProps extends GridExportMenuItemProps<{}> {
  fields: string[];
}

const ExcelExportItem = (props: ExcelExportItemProps) => {
  const { hideMenu, fields } = props;
  const { formatMessage } = useIntl();
  const apiRef = useGridApiContext();

  const currentRowIds = useMemo(() => gridFilteredSortedRowIdsSelector(apiRef) || [], [apiRef]);

  const rowData = useMemo(
    () => apiRef?.current?.state?.rows?.dataRowIdToModelLookup || {},
    [apiRef],
  );

  const headerColumn = useMemo(
    () =>
      (apiRef?.current?.getVisibleColumns() || [])
        .filter(item => fields.includes(item.field))
        .map(item => ({
          field: item.field,
          headerName: item.headerName || item.field,
          valueGetter: item.valueGetter,
          valueFormatter: item.valueFormatter,
          type: item.type,
        })),
    [apiRef, fields],
  );

  const currenRowData = useMemo(() => {
    const data: Dictionary[] = [];

    currentRowIds.forEach(id => {
      if (rowData[id]) {
        const row = {} as Dictionary;

        headerColumn.forEach(col => {
          const rowIdData = rowData[id];
          let rowValue = rowIdData[col.field];

          if (typeof col.valueGetter === 'function' && rowValue) {
            // call table getter function to avoid redundant formatter
            rowValue = col.valueGetter(rowValue as never, rowIdData, col, apiRef);
          }

          if (typeof col.valueFormatter === 'function' && rowValue && col.type !== 'singleSelect') {
            // call table formatter function to avoid redundant formatter
            // singleSelect type is already formatted
            rowValue = col.valueFormatter(rowValue as never, rowIdData, col, apiRef);
          }

          row[col.headerName] = rowValue;
        });

        data.push(row);
      }
    });

    return data;
  }, [currentRowIds, rowData, headerColumn, apiRef]);

  const handleExcelExport = () => {
    const sheetName = formatMessage({ id: 'sheet.name', defaultMessage: 'data' });
    const fileName = formatMessage({ id: 'html.title.tag', defaultMessage: 'Customer portal' });
    const ws = XLSX.utils.json_to_sheet(currenRowData);
    const wb = { Sheets: { data: ws }, SheetNames: [sheetName] };
    XLSX.writeFile(wb, `${fileName}.xlsx`);
    hideMenu?.();
  };
  return (
    <MenuItem onClick={handleExcelExport}>
      {formatMessage({ id: 'table.toolbar.export.excel', defaultMessage: 'Download as Excel' })}
    </MenuItem>
  );
};
