import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { FetchingStatus, EmployeeManagementState } from 'types';
import { changeImportingData } from 'utils/bulkUploadFixData';
import { uploadCsvFile } from './uploadCsvFile';

const uploadCsvFileExtraReducer = (builder: ActionReducerMapBuilder<EmployeeManagementState>) => {
  builder.addCase(uploadCsvFile.pending, state => {
    if (!state.api.bulkImportCsvFile) {
      state.api['bulkImportCsvFile'] = {
        fetchingStatus: FetchingStatus.IDLE,
        data: null,
        error: null,
      };
    } else {
      state.api.bulkImportCsvFile.fetchingStatus = FetchingStatus.PENDING;
      state.api.bulkImportCsvFile.data = null;
      state.api.bulkImportCsvFile.error = null;
    }
  });

  builder.addCase(uploadCsvFile.fulfilled, (state, action) => {
    state.api.bulkImportCsvFile.fetchingStatus = FetchingStatus.FULFILLED;
    state.api.bulkImportCsvFile.data = changeImportingData(action.payload) || null;
  });

  builder.addCase(uploadCsvFile.rejected, (state, action) => {
    state.api.bulkImportCsvFile.fetchingStatus = FetchingStatus.REJECTED;
    state.api.bulkImportCsvFile.error = action.payload;
  });
};

export default uploadCsvFileExtraReducer;
