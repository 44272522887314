import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ResetWithEmail from './ResetWithEmail';
import ResetPasswordCode from './ResetPasswordCode';
import { ResetPasswordWrapper } from './ResetPassword.styles';
import { AuthenticationSelectors } from 'features/authentication/store/authentication.selectors';
import GuestHeader from '@components/GuestHeader';
import Spacer from '@components/Spacer';
import { useAppDispatch } from '@store/index';
import { AuthenticationActions } from '@features/authentication/store';
import { FetchingStatus } from '@api/interfaces';
import Loader from '@components/Loader';

const ResetPassword = () => {
  const navigate = useNavigate();
  const { forgotPassword } = useSelector(AuthenticationSelectors.getAuthentication);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (forgotPassword.activeStep === 2 && !forgotPassword.email) {
      dispatch(AuthenticationActions.setActiveStep(1));
    }

    if (forgotPassword.activeStep === 3) {
      navigate('/de/login');
    }
  }, [dispatch, forgotPassword.activeStep, forgotPassword.email, navigate]);

  const changeEmailHandler = (email: string) => {
    dispatch(AuthenticationActions.setActiveEmail(email));
  };

  const renderActiveStep = () => {
    switch (forgotPassword.activeStep) {
      default:
      case 1:
        return <ResetWithEmail changeEmail={changeEmailHandler} />;
      case 2:
        return <ResetPasswordCode />;
    }
  };

  const isLoading = useMemo(
    () => forgotPassword.fetchingStatus === FetchingStatus.PENDING,
    [forgotPassword.fetchingStatus],
  );

  return (
    <ResetPasswordWrapper>
      <Loader show={isLoading} />
      <GuestHeader />
      <Spacer height={48} />
      {renderActiveStep()}
    </ResetPasswordWrapper>
  );
};

export default ResetPassword;
