import { LabelDisplayedRowsArgs } from '@mui/material';
import { useIntl } from 'react-intl';

const LabelDisplayedRows = ({ from, to, count }: LabelDisplayedRowsArgs) => {
  const intl = useIntl();
  return (
    <>
      {intl.formatMessage(
        {
          id: 'table.footer.counter',
          defaultMessage: `{from}-{to} of {count} Elements`,
        },
        {
          from: from,
          to: to,
          count: count,
        },
      )}
    </>
  );
};

export default LabelDisplayedRows;
