import { Controller, useFormContext } from 'react-hook-form';
import React, { ChangeEvent, useCallback } from 'react';
import { DefaultCheckbox } from '../DefaultCheckbox.styles';
import { LabelCheckboxStyled } from './LabelCheckbox.styles';
import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { defaultTextfieldError } from 'utils/defaultErrorMessage';
import { useMemo } from 'react';

interface CCPLabelCheckboxProps {
  label: string | React.ReactNode;
  name: string;
  control: any;
  setValue?: any; //TODO check type
  reset?: boolean;
  subLabel?: string;
  disabled?: boolean;
  regularWeight?: boolean;
}

export const CCPLabelCheckbox = (props: CCPLabelCheckboxProps) => {
  const intl = useIntl();
  const { name, label, control, subLabel, disabled, regularWeight } = props;

  const { setValue, trigger } = useFormContext();
  const handleChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      setValue(name, e.target.checked);
      await trigger(name);
    },
    [name, setValue, trigger],
  );

  const properties = useMemo(() => ({ onChange: handleChange }), [handleChange]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { ref, ...fieldProps } = field;
        return (
          <>
            <LabelCheckboxStyled
              control={
                <>
                  <DefaultCheckbox
                    {...fieldProps}
                    inputRef={field.ref}
                    color='primary'
                    {...properties}
                    checked={field.value}
                    disabled={disabled}
                    data-test-id={`${name}Checkbox`}
                    //onChange={() => handleChange(field.value, name)}
                  />
                </>
              }
              label={label}
              className={`${fieldState.error ? 'error_checkbox' : ''} ${
                regularWeight ? '' : 'font-weight-bold'
              }`}
              disabled={disabled}
            />
            {subLabel && (
              <Typography variant='subtitle2' style={{ paddingLeft: 28 }}>
                {subLabel}
              </Typography>
            )}
            {fieldState.error && (
              <span className='error_checkbox_helper' data-test-id='validationErrorText'>
                {fieldState.error?.message
                  ? intl.formatMessage({
                      id: fieldState.error?.message,
                      defaultMessage:
                        defaultTextfieldError[fieldState.error.message] || fieldState.error.message,
                    })
                  : undefined}
              </span>
            )}
          </>
        );
      }}
    />
  );
};
