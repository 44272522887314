import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { EmployeeManagementState, FetchingStatus } from 'types';
import { getEmployeeStatus } from './getEmployeeStatus';

export const getEmployeeStatusExtraReducer = (
  builder: ActionReducerMapBuilder<EmployeeManagementState>,
) => {
  builder.addCase(getEmployeeStatus.pending, (state, action) => {
    state.api.getEmployeeStatus.fetchingStatus = FetchingStatus.PENDING;
    state.api.getEmployeeStatus.error = null;
  });
  builder.addCase(getEmployeeStatus.fulfilled, (state, action) => {
    state.api.getEmployeeStatus.fetchingStatus = FetchingStatus.FULFILLED;
    state.api.getEmployeeStatus.data = action.payload || null;
    state.api.getEmployeeStatus.error = null;
  });

  builder.addCase(getEmployeeStatus.rejected, (state, action) => {
    state.api.getEmployeeStatus.fetchingStatus = FetchingStatus.REJECTED;
    state.api.getEmployeeStatus.error = action.error;
  });
};
