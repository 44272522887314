import { FetchingStatus } from 'types';
import { ContractsState } from './interfaces';

export const initialState: ContractsState = {
  getContracts: {
    data: [],
    fetchingStatus: FetchingStatus.IDLE,
    error: null,
  },
  euCreationCondition: {
    data: null,
    fetchingStatus: FetchingStatus.IDLE,
    error: null,
  },
};
