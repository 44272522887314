import { useUserSettingsTabs } from '../utils';
import { useAppSelector } from '../store';
import { AuthenticationSelectors } from '../features/authentication/store/authentication.selectors';
import { useMemo } from 'react';

export const useGetSettingTab = () => {
  const userSettingsTabs = useUserSettingsTabs();

  const { userInfo } = useAppSelector(AuthenticationSelectors.getAuthentication);
  const userRole = useMemo(() => userInfo?.role, [userInfo]);

  const resultTabs = useMemo(() => {
    if (!userRole) {
      return [];
    }
    return userSettingsTabs.map(tab => {
      const userHasRoles = tab.role.filter(tabRole => userRole.includes(tabRole)).length !== 0;
      return { ...tab, isVisible: userHasRoles };
    });
  }, [userRole, userSettingsTabs]);

  return resultTabs;
};
