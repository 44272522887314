import {
  Box,
  Checkbox,
  FormGroup,
  Typography,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import StarsIcon from '@mui/icons-material/Stars';

import { usePermissionsSettingsList } from 'utils';
import { FetchingStatus, ListType, PermissionsSettingsModalProps } from 'types';

import { WrapperOwnWrapper } from './PermissionsSettings.styles';
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import Spacer from 'components/Spacer';
import { useAppDispatch, useAppSelector } from 'store';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';
import { SettingsActions } from 'features/settings/store';
import Loader from 'components/Loader';
import CCPDefaultButton from '@components/Button/Default';
import { default as theme } from '@theme/theme';
import { WrapperCheckboxesForm } from '../NewUser/NewUser.styles';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '@hooks/useLanguage';

const PermissionsSettingsModal: React.FC<PermissionsSettingsModalProps> = ({
  state,
  isOpen,
  handleClose,
  handleChange,
  handleSubmit,
  userEmail,
  disabled = false,
  isOwnCU,
}) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const activeLanguage = useLanguage();
  const dispatch = useAppDispatch();
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);
  const activationLetterDistributionList = useAppSelector(
    SettingsSelectors.emailActivationLetterDistributionList,
  );
  const activationLetterDistributionListFetchingStatus = useAppSelector(
    SettingsSelectors.activationLetterDistributionListFetchingStatus,
  );

  const isDisabled = (!state.Admin && !state.Invoice && !state.Usermgmt) || disabled;
  const permissionsSettingsList = usePermissionsSettingsList();
  const [isOwnCUChanging, setIsOwnCUChanging] = useState<boolean>(false);
  const [isNeedsToReassignAL, setIsNeedsToReassignAL] = useState<boolean>(false);

  useEffect(() => {
    if (activeCompany) {
      const { companyId } = activeCompany;

      dispatch(
        SettingsActions.getDistributionList({ companyId, listType: ListType.ACTIVATION_LETTER }),
      );
    }
  }, [dispatch, activeCompany]);

  const customHandleSubmit = () => {
    if (
      !state.Usermgmt &&
      activationLetterDistributionList.length <= 1 &&
      activationLetterDistributionList.filter(item => item === userEmail).length !== 0
    ) {
      setIsNeedsToReassignAL(true);
      return;
    }
    if (isOwnCU && !isOwnCUChanging) {
      setIsOwnCUChanging(true);
      return;
    }
    handleSubmit();
  };

  const renderPermissionsList = permissionsSettingsList.map(({ name, labelText, labelTitle }) => (
    <FormControlLabel
      key={labelTitle}
      className='item'
      control={
        <Checkbox className='checkbox' checked={state[name]} onChange={handleChange} name={name} />
      }
      label={
        <Box>
          <Typography variant='h3'>
            {labelTitle}{' '}
            {name === 'Admin' && (
              <StarsIcon fontSize='small' htmlColor={theme.palette.neutralDark.main} />
            )}
          </Typography>
          <Typography color={theme.palette.neutralDark.main} variant='h5'>
            {labelText}
          </Typography>
        </Box>
      }
    />
  ));

  return (
    <>
      <Loader show={activationLetterDistributionListFetchingStatus === FetchingStatus.PENDING} />
      <Dialog open={isOpen} onClose={handleClose}>
        {!isOwnCUChanging && !isNeedsToReassignAL ? (
          <>
            <DialogTitle>
              <Box display='flex' alignItems='center'>
                <Typography variant='h2'>
                  {formatMessage({
                    id: 'view.user_management.permission_setting',
                    defaultMessage: 'Permissions settings',
                  })}
                </Typography>
                {isOwnCU && (
                  <WrapperOwnWrapper>
                    <Typography className='own_label' variant='h5'>
                      {formatMessage({
                        id: 'view.administration.own.user',
                        defaultMessage: 'You',
                      })}
                    </Typography>
                  </WrapperOwnWrapper>
                )}
              </Box>
            </DialogTitle>
            <DialogContent>
              <Typography variant='subtitle1'>
                {formatMessage({
                  id: 'view.user_management.permission_setting_title',
                  defaultMessage:
                    'Select a role for this member to grant them access to your resources.',
                })}
              </Typography>
              <Spacer size='lg' />
              <WrapperCheckboxesForm>
                <FormGroup>{renderPermissionsList}</FormGroup>
              </WrapperCheckboxesForm>
            </DialogContent>
            <DialogActions>
              <CCPDefaultButton onClick={handleClose} variant='outlined'>
                {formatMessage({
                  id: 'form.employee.cta.cancel',
                  defaultMessage: 'Cancel',
                })}
              </CCPDefaultButton>
              <CCPDefaultButton
                onClick={customHandleSubmit}
                disabled={isDisabled}
                variant='contained'>
                {isOwnCU
                  ? formatMessage({
                      id: 'form.permission_setting.confirm',
                      defaultMessage: 'Confirm',
                    })
                  : formatMessage({
                      id: 'dashboard__brands.apply',
                      defaultMessage: 'Apply & save',
                    })}
              </CCPDefaultButton>
            </DialogActions>
          </>
        ) : null}
        {isOwnCUChanging && (
          <>
            <CloseIcon onClick={handleClose} className='dialog-close' />
            <DialogTitle>
              <Box className='dialog-icon'>
                <ErrorOutlineIcon htmlColor={theme.palette.warning.main} />
              </Box>
              <Typography variant='h2'>
                {formatMessage({
                  id: 'account.alert.my_administrator.title',
                  defaultMessage: 'My permission changing',
                })}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Typography variant='subtitle1'>
                {formatMessage({
                  id: 'account.alert.my_administrator.question',
                  defaultMessage: 'Do you really want to change your permissions in the system?',
                })}
              </Typography>
              <Spacer size='md' />
              <Typography variant='subtitle1'>
                {formatMessage({
                  id: 'account.alert.my_administrator.subtitle',
                  defaultMessage: 'To apply these changes you have to log out.',
                })}
              </Typography>
            </DialogContent>
            <DialogActions>
              <CCPDefaultButton onClick={handleClose} variant='outlined'>
                {formatMessage({
                  id: 'form.employee.cta.cancel',
                  defaultMessage: 'Cancel',
                })}
              </CCPDefaultButton>
              <CCPDefaultButton onClick={customHandleSubmit} variant='contained'>
                {formatMessage({
                  id: 'form.employee.cta.change_logout',
                  defaultMessage: 'Yes, change & log out',
                })}
              </CCPDefaultButton>
            </DialogActions>
          </>
        )}
        {isNeedsToReassignAL && (
          <>
            <CloseIcon onClick={handleClose} className='dialog-close' />
            <DialogTitle>
              <Box className='dialog-icon'>
                <ErrorOutlineIcon htmlColor={theme.palette.warning.main} />
              </Box>
              <Typography variant='h2'>
                {formatMessage({
                  id: 'account.alert.reassign_al_recipients.title',
                  defaultMessage: 'You need to change the user who receives activation emails',
                })}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Typography variant='subtitle1'>
                {formatMessage({
                  id: 'account.alert.reassign_al_recipients.description',
                  defaultMessage:
                    'Go to settings and reassign the user receiving activation emails.',
                })}
              </Typography>
            </DialogContent>
            <DialogActions>
              <CCPDefaultButton onClick={handleClose} variant='outlined'>
                {formatMessage({
                  id: 'account.alert.reassign_al_recipients.cta.cancel',
                  defaultMessage: 'Cancel',
                })}
              </CCPDefaultButton>
              <CCPDefaultButton
                onClick={() => navigate(`/${activeLanguage}/settings/1`)}
                variant='contained'
                color='error'>
                {formatMessage({
                  id: 'account.alert.reassign_al_recipients.cta.approve',
                  defaultMessage: 'Open Settings',
                })}
              </CCPDefaultButton>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default PermissionsSettingsModal;
