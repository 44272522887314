import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';

import { AddEmailSchema } from 'shared/schemas';
import { useAppDispatch } from 'store';
import { ResetWithEmailProps } from 'types';
import { AuthenticationActions } from 'features/authentication/store';
import { useIntl } from 'react-intl';
import { CCPDefaultButton, CCPTextField, Spacer } from '@components/index';
import { useLanguage } from '@hooks/useLanguage';

const ResetWithEmail: React.FC<ResetWithEmailProps> = ({ changeEmail }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const language = useLanguage();
  const intl = useIntl();

  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(AddEmailSchema),
  });
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  const onSubmit = (data: any) => {
    changeEmail(data.email);
    dispatch(
      AuthenticationActions.forgotPassword({
        email: data.email,
      }),
    );
  };

  const navigateToLoginHandler = () => {
    navigate(`/${language}/login`);
  };

  return (
    <Box
      component='form'
      onSubmit={handleSubmit(onSubmit)}
      className='wrapper'
      data-test-id='resetPasswordForm'>
      <FormProvider {...methods}>
        <Box>
          <Typography variant='h2' data-test-id='resetPasswordTitle'>
            {intl.formatMessage({
              id: 'reset_password_code.title.reset_password',
              defaultMessage: 'Reset password',
            })}
          </Typography>
          <Spacer size='lg' />
          <Typography variant='subtitle1'>
            {intl.formatMessage({
              id: 'reset_password_code.subtitle.email',
              defaultMessage: 'Please enter your email address to reset the password.',
            })}
          </Typography>
          <Spacer size='xl' />
          <CCPTextField
            control={control}
            name='email'
            variant='outlined'
            label='Email'
            error={!!errors.email}
            reset
          />
        </Box>
        <Spacer size='3xl' />
        <Box className='verify__footer'>
          <CCPDefaultButton
            onClick={navigateToLoginHandler}
            variant='outlined'
            data-test-id='cancelResetButton'>
            {intl.formatMessage({
              id: 'reset_password_code.body.cta.cancel',
              defaultMessage: 'Cancel',
            })}
          </CCPDefaultButton>
          <CCPDefaultButton
            disabled={!isValid}
            color='primary'
            type='submit'
            variant='contained'
            data-test-id='continueResetButton'>
            {intl.formatMessage({
              id: 'reset_password_code.body.cta.continue',
              defaultMessage: 'Continue',
            })}
          </CCPDefaultButton>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default ResetWithEmail;
