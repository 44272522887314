import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { FetchingStatus } from 'types';
import { uploadAssetsState } from '../../../interfaces';
import { addCompanyAssets } from './addCompanyAssets';

export const addCompanyAssetsReducer = (builder: ActionReducerMapBuilder<uploadAssetsState>) => {
  builder.addCase(addCompanyAssets.pending, (state, action) => {
    state.status = FetchingStatus.PENDING;
    state.filesInprogress++;
    state.error = null;
  });
  builder.addCase(addCompanyAssets.fulfilled, (state, action) => {
    if (state.filesInprogress === 1) {
      state.status = FetchingStatus.FULFILLED;
    }
    state.filesInprogress--;
    state.error = null;
  });
  builder.addCase(addCompanyAssets.rejected, (state, action) => {
    if (state.filesInprogress === 1) {
      state.status = FetchingStatus.REJECTED;
    }
    state.filesInprogress--;
    state.error = 'One or more files were not uploaded';
  });
};
