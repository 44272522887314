import { Auth } from 'aws-amplify';
import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToast } from 'utils';
import { ForgotPasswordSubmitRequestParams, UserManagementBaseResponse } from 'types';

export const forgotPasswordSubmit = createAsyncThunk<any, ForgotPasswordSubmitRequestParams>(
  'authentication/forgotPasswordSubmit',
  async ({ email, code, new_password }, { rejectWithValue }) => {
    try {
      const response = await Auth.forgotPasswordSubmit(email, code, new_password);

      return response;
    } catch (e) {
      const error: AxiosError<UserManagementBaseResponse> =
        e as AxiosError<UserManagementBaseResponse>;
      showToast('error', {
        id: 'toast.authentificationforgot_password.error',
        defaultMessage: error.message,
      } );

      return rejectWithValue(error.message);
    }
  },
);
