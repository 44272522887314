import { FetchingStatus, INITIAL_PASSWORD_STEPS, InitialPasswordState } from 'types';

export const initialState: InitialPasswordState = {
  api: {
    changeTemporaryPassword: {
      fetchingStatus: FetchingStatus.IDLE,
      error: null,
    },
  },
  currentStep: INITIAL_PASSWORD_STEPS.DEFAULT_PASSWORD,
  steps: {
    defaultPasswordStep: {
      defaultPassword: '',
    },
    setNewPasswordStep: {
      email: '',
      newPassword: ''
    }
  },
};
