import {
  GridColumnVisibilityModel,
  GridFilterModel,
  GridRowSelectionModel,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridFilterItem,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { useDataGridLocale, useTableRows } from 'hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import Footer from './Footer';
import { TablesManagementActions } from './store';
import { Container, CustomFormControl, DataGridProTable } from './Table.styles';
import CustomToolbar from './Toolbar/CustomToolbar';
import DefaultNoResultsOverlay from './NoResultsOverlay';
import Loader from 'components/Loader';
import { default as theme } from '@theme/theme';
import { CustomTableProps } from '@localTypes/customTableProps';
import { TablesManagementSelectors } from './store/tablesManagement.selectors';

export const GRID_ACTIONS_COLUMN_NAME = 'Actions';

const TableGrid = ({
  tableName,
  columns,
  rowsData = [],
  isLoading,
  CustomNoResultsOverlay,
  CustomNoRowsOverlay,
  CustomLoadingOverlay,
  checkboxSelection = false,
  checkboxSelectionVisibleOnly = false,
  selectable = () => true,
  searchPlaceholder = '',
  noRecordsTranslation = '',
  addNewRecords = undefined,
  addRecordsTranslation = '',
  showExportExcel = false,
  hasBulkActions = false,
  bulkActions = [],
  bulkActionHandler,
  bulkActionsDisableButton = false,
  selectedRows,
  rowsSelected = [],

  getRowId,
  hideFooter = false,
}: CustomTableProps) => {
  const dispatch = useAppDispatch();
  const [applyButton, setApplyButton] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const { isOpenSidebar } = useAppSelector(state => state.authentication);
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);
  const tableConfiguration = useAppSelector(state =>
    TablesManagementSelectors.getTableConfigurationByTableNameAndCompanyId(state, {
      tableName,
      companyId: activeCompany?.companyId,
    }),
  );
  const apiRef = useGridApiRef();

  const dataGridLocale = useDataGridLocale();
  const rows = useTableRows({
    uniqueIdKey: 'id',
    customFieldKey: 'customFields',
    globalCustomFieldKey: 'globalCustomFields',
    data: rowsData,
  });

  const paginationModel = useMemo(
    () => ({
      pageSize: tableConfiguration ? tableConfiguration.rowsPerPage : 25,
      page: tableConfiguration ? tableConfiguration.currentPage : 0,
    }),
    [tableConfiguration],
  );

  useEffect(() => {
    if (rows.length !== 0) {
      //need to have a minimala delay because rows updating asyncronous
      setTimeout(() => {
        if (apiRef.current)
          apiRef.current.autosizeColumns({
            includeHeaders: true,
            includeOutliers: true,
          });
      }, 10);
    }
  }, [apiRef, rows, paginationModel]);

  useEffect(() => {
    if (!tableConfiguration && activeCompany?.companyId && columns.length > 0) {
      dispatch(
        TablesManagementActions.setInitialTableConfiguration({
          tableName,
          companyId: activeCompany?.companyId,
          tableColumns: columns.map(item => ({ field: item.field })),
        }),
      );
    }
  }, [dispatch, tableConfiguration, activeCompany?.companyId, columns, tableName]);

  const tableFilters = tableConfiguration?.filters;

  const filterModel = useMemo(
    () =>
      tableFilters && tableFilters.items.length > 0
        ? tableFilters
        : { items: [] as GridFilterItem[] },
    [tableFilters],
  );
  const filterModelChangeHandler = useCallback(
    (model: GridFilterModel) => {
      if (activeCompany) {
        dispatch(
          TablesManagementActions.updateFilters({
            tableName,
            companyId: activeCompany.companyId,
            model,
          }),
        );

        dispatch(
          TablesManagementActions.resetSearchData({
            tableName,
            companyId: activeCompany.companyId,
          }),
        );
      }
    },
    [activeCompany, dispatch, tableName],
  );

  const columnVisibilityModelChange = useCallback(
    (model: GridColumnVisibilityModel) => {
      if (activeCompany) {
        if (Object.keys(model).length === 0 && tableConfiguration) {
          //hack, I don't know why, but model is empty object when you click on the "select/deselect all" checkbox if some checkboxes were unchecked
          Object.keys(tableConfiguration.columns).forEach(item => {
            dispatch(
              TablesManagementActions.toggleColumn({
                tableName,
                companyId: activeCompany.companyId,
                property: item,
                visible: true,
              }),
            );
          });
        } else if (Object.keys(model).length !== 0) {
          Object.keys(model).forEach(item => {
            if (item !== GRID_ACTIONS_COLUMN_NAME && item !== '__check__') {
              dispatch(
                TablesManagementActions.toggleColumn({
                  tableName,
                  companyId: activeCompany.companyId,
                  property: item,
                  visible: model[item],
                }),
              );
            }
          });
        }
      }
    },
    [activeCompany, dispatch, tableName, tableConfiguration],
  );
  const selectionModelChangeHandler = useCallback(
    (model: GridRowSelectionModel) => {
      if (selectedRows) {
        setSelectionModel(model);
        selectedRows(model);
        setApplyButton(!!model.length ? true : false);
      }
    },
    [selectedRows],
  );

  const deselectAll = () => {
    if (selectedRows) {
      setSelectionModel([]);
      selectedRows([]);
      setApplyButton(false);
    }
  };

  const getDefaultNoRowsElement = () => (
    <DefaultNoResultsOverlay
      tableName={tableName}
      columnsData={columns}
      rowsData={rowsData}
      noRecordsTranslation={noRecordsTranslation}
      addNewRecords={addNewRecords}
      addRecordsTranslation={addRecordsTranslation}
    />
  );

  return (
    <Container
      id={`${tableName}-grid-wrapper`}
      className={`${tableName}-grid-wrapper`}
      isOpenSidebar={isOpenSidebar}>
      <DataGridProTable
        initialState={{
          pinnedColumns: {
            right: [GRID_ACTIONS_COLUMN_NAME, 'localizedFiles'],
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
          },
        }}
        apiRef={apiRef}
        paginationModel={paginationModel}
        autoHeight
        localeText={dataGridLocale}
        pagination
        paginationMode='client'
        checkboxSelection={checkboxSelection}
        isRowSelectable={selectable}
        checkboxSelectionVisibleOnly={checkboxSelectionVisibleOnly}
        onRowSelectionModelChange={selectionModelChangeHandler}
        rowSelectionModel={selectionModel}
        onFilterModelChange={filterModelChangeHandler}
        filterModel={filterModel}
        onColumnVisibilityModelChange={columnVisibilityModelChange}
        columnVisibilityModel={tableConfiguration?.columns}
        disableRowSelectionOnClick
        columns={columns}
        rows={rows}
        rowHeight={64}
        loading={isLoading}
        getRowId={getRowId}
        hideFooter={hideFooter}
        slots={{
          pagination: Footer,
          baseFormControl: CustomFormControl,
          toolbar: CustomToolbar,

          loadingOverlay: CustomLoadingOverlay
            ? CustomLoadingOverlay
            : () => <Loader show inCard portalId={`${tableName}-grid-wrapper`} />,
          noResultsOverlay: CustomNoResultsOverlay
            ? CustomNoResultsOverlay
            : getDefaultNoRowsElement,
          noRowsOverlay: CustomNoRowsOverlay ? CustomNoRowsOverlay : getDefaultNoRowsElement,
        }}
        slotProps={{
          toolbar: {
            setOpenedPanelName: () => {},
            rows,
            columnsData: columns,
            tableName,
            searchPlaceholder,
            showExportExcel,
            hasBulkActions,
            bulkActions,
            enableButton: applyButton,
            bulkActionHandler,
            bulkActionsDisableButton,

            rowsSelected,
            deselectAll,
            toolbarRef,
          },
          pagination: { tableName },
          baseSelect: {
            native: false,
            variant: 'outlined',
            notched: true,
          },
          basePopper: {
            sx: {
              '& .MuiDataGrid-panelContent': {
                paddingTop: '4px',
                paddingLeft: '12px',
                paddingRight: '12px',
                paddingBottom: '16px',
              },
              '& .MuiFormControlLabel-root': {
                fontSize: 16,
              },
              '& .MuiSwitch-track': {
                backgroundColor: theme.palette.transparent.boxShadowDark02,
                opacity: 1,
              },
              '& .MuiDataGrid-columnsPanelRow': {
                paddingTop: '8px',
              },
            },
          },
          baseTextField: {
            variant: 'outlined',
          },

          filterPanel: {
            sx: {
              padding: '8px',

              '.MuiDataGrid-filterFormDeleteIcon': {
                alignSelf: 'center',
              },
            },
          },
          baseFormControl: {
            sx: {
              marginLeft: '8px',
            },
          },
          baseInputLabel: {
            sx: {
              transform: 'translate(14px, -9px) scale(0.75)',
            },
          },
        }}
        sx={{ '--DataGrid-overlayHeight': '300px' }}
      />
    </Container>
  );
};

export default TableGrid;
