import * as yup from 'yup';

export const EmployeeReactivationSchema = (isDeactivation: boolean) =>
  isDeactivation
    ? yup
        .object({
          deactivationDate: yup
            .date()
            .min(new Date(), 'form.employee.error.past_date')
            .typeError('form.employee.error.end_date.invalid')
            .nullable()
            .required('form.employee.error.end_date.required'),
        })
        .required()
    : yup
        .object({
          reactivationDate: yup
            .date()
            .min(new Date(), 'form.employee.error.past_date')
            .typeError('form.employee.error.start_date.invalid')
            .nullable()
            .required('form.employee.error.start_date.required'),
        })
        .required();

export default EmployeeReactivationSchema;
