import { createSlice } from '@reduxjs/toolkit';
import { marketingMaterialInitialState } from './initialState';
import { getMarketingMaterialsExtraReducer } from './actions/async/getMarketingMaterials/getMarketingMaterials.reducer';
import { addMarketingMaterialReducer } from './actions/async/addMarketingMaterials/addMarketingMaterial.reducer';
import { getFileCategoryReducer } from './actions/async/getFileCategory/getFileCategory.reducer';

const marketingMaterialsManagementSlice = createSlice({
  name: 'marketingMaterialsManagement',
  initialState: marketingMaterialInitialState,
  reducers: {},
  extraReducers: builder => {
    getMarketingMaterialsExtraReducer(builder);
    addMarketingMaterialReducer(builder);
    getFileCategoryReducer(builder);
  },
});

export const { name, actions } = marketingMaterialsManagementSlice;
export const marketingMaterialsManagementReducer = marketingMaterialsManagementSlice.reducer;
