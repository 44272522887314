import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { FetchingStatus, SettingsState } from 'types';
import { addCompanyCustomField } from './addCompanyCustomField';

export const addCompanyCustomFieldExtraReducer = (
  builder: ActionReducerMapBuilder<SettingsState>,
) => {
  builder.addCase(addCompanyCustomField.fulfilled, (state, action) => {
    const companyId = action?.meta?.arg?.companyId;
    if (typeof companyId === 'number') {
      const oldData = [...(state.companyCustomField[companyId].data || [])];
      oldData.push(action.payload);
      state.companyCustomField[companyId].fetchingStatus = FetchingStatus.FULFILLED;
      state.companyCustomField[companyId].data = oldData;
    }
  });
};
