import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosWithConfig } from 'api';
import { AxiosError } from 'axios';

import { showToast } from 'utils';
import { ResendTempPasswordRequestBody, UserManagementBaseResponse } from 'types';

export const resendTempPassword = createAsyncThunk<
  UserManagementBaseResponse,
  ResendTempPasswordRequestBody
>('userManagement/resendTempPassword', async (body, { rejectWithValue }) => {
  const endpoint = process.env.REACT_APP_USER_MANAGEMENT_ENDPOINT + `resendTempPassword`;
  const axios = await axiosWithConfig();

  try {
    const { data } = await axios.put(endpoint, body);

    showToast('success', 'Resend temporary password has been successfully done');

    return data;
  } catch (e) {
    const error: AxiosError<UserManagementBaseResponse> =
      e as AxiosError<UserManagementBaseResponse>;
    showToast('error', error.message);
    rejectWithValue(error.response?.data);
  }
});
