import { Box, styled } from '@mui/material';
export const PersonalFieldsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
}));

export const DuplicateButton = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  backgroundColor: theme.palette.warning.main,
  color: theme.palette.neutralDark.contrastText,
  fontWeight: 'bold',
  padding: '2px 8px',
  borderRadius: '4px',
  margin: '0 8px',
  fontSize: 14,
  marginLeft: 'auto',
  height: 24,
}));
