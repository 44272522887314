import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useCallback, useState } from 'react';
import { useAppSelector } from 'store';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { EmployeeManagementSelectors } from 'features/employeeManagement/store/employeeManagement.selectors';
import { AlertModal, Spacer } from 'components';
import { default as theme } from '@theme/theme';

const BulkActionsResponseModal: React.FC<{
  open: boolean;
  close?: () => void;
  changedEmployees: number[];
  unchangedEmployees: number[];
}> = ({ close = () => {}, open, changedEmployees, unchangedEmployees }) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(open);

  const employees = useAppSelector(EmployeeManagementSelectors.getTableOverviewEmployees) || [];
  const notChangedEmployees = employees.filter(employee =>
    unchangedEmployees?.find(id => id === employee.id),
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
    if (close) {
      close();
    }
  }, [close]);

  return (
    <AlertModal
      iconComponent={<ErrorOutlineIcon htmlColor={theme.palette.error.main} />}
      isOpen={isOpen}
      handleClose={handleClose}
      title={intl.formatMessage({
        id: 'employee.bulk.actions.error_modal.title',
        defaultMessage: 'Some employees could not be changed',
      })}
      hideDescription
      mainContent={
        <>
          <Typography variant='subtitle1'>
            {intl.formatMessage({
              id: 'employee.bulk.actions.error_modal.content.top',
              defaultMessage: 'The following employees were not changed:',
            })}
          </Typography>
          <Spacer size='md' />
          <Typography variant='h3'>
            {notChangedEmployees?.map(employee => employee.businessEmail).join(', ')}
          </Typography>
          <Spacer size='md' />
          <Typography variant='subtitle1'>
            {intl.formatMessage(
              {
                id: 'employee.bulk.actions.error_modal.content.bottom',
                defaultMessage: '{amountChanged} employees were succesefully changed',
              },
              { amountChanged: changedEmployees.length },
            )}
          </Typography>
        </>
      }
    />
  );
};

export default BulkActionsResponseModal;
