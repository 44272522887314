import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import signOut from './signOut';
import { FetchingStatus } from '@api/interfaces';
import { AuthenticationState } from '@localTypes/index';

export const signOutExtraReducers = (builder: ActionReducerMapBuilder<AuthenticationState>) => {
  builder.addCase(signOut.pending, (state, action) => {
    state.fetchingStatus = FetchingStatus.PENDING;
    state.error = null;
  });
  builder.addCase(signOut.fulfilled, (state, action) => {
    state.fetchingStatus = FetchingStatus.FULFILLED;
    state.authenticated = false;
    state.isOpenSidebar = true;
    state.error = null;
    state.userInfo = null;
  });
  builder.addCase(signOut.rejected, (state, action) => {
    state.fetchingStatus = FetchingStatus.REJECTED;
    state.error = action.error;
  });
};
