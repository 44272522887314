import { CCPTextField, Spacer } from '@components/index';
import { CompanyCustomFieldForm } from '@localTypes/index';
import { InputAdornment, List, ListItem, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DragEvent, MutableRefObject, useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import { CCPLabelCheckbox } from '@components/Form/Checkbox/LabelCheckbox';
import { default as theme } from '@theme/theme';
import { AddOtherLanguage } from '../CustomFieldModal/CustomFieldModal.style';

type EnumSortableListProps = { listRef: MutableRefObject<HTMLUListElement | null> };

const FIELD_ARRAY_NAME = 'localizedValueFields';

const EnumSortableList = ({ listRef }: EnumSortableListProps) => {
  const { formatMessage } = useIntl();
  const { control, formState, trigger } = useFormContext<CompanyCustomFieldForm>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: FIELD_ARRAY_NAME,
  });

  let dragged: HTMLLIElement | null = null,
    over: HTMLLIElement | null = null;

  const dragStart = (e: DragEvent<HTMLLIElement>) => {
    dragged = e.currentTarget;
    e.dataTransfer.effectAllowed = 'move';
    // Firefox requires calling dataTransfer.setData
    // for the drag to properly work
    // @ts-ignore
    e.dataTransfer.setData('text/html', e.currentTarget);
  };
  const dragEnd = (e: DragEvent<HTMLLIElement>) => {
    if (dragged) dragged.style.visibility = 'visible';
  };
  const dragOver = (e: DragEvent<HTMLElement>) => {
    e.preventDefault();
    if (dragged) dragged.style.visibility = 'hidden';
    if (e.target instanceof HTMLElement) {
      over = e.target.closest('li');
    }
    if (over !== null) {
      const offsetTop = over.getBoundingClientRect().y;
      const relY = e.clientY - offsetTop;
      const height = over.offsetHeight / 2;
      const parent = over.parentNode;
      if (parent && dragged)
        if (relY > height) {
          parent.insertBefore(dragged, over.nextElementSibling);
        } else if (relY < height) {
          parent.insertBefore(dragged, over);
        }
    }
  };

  const handleAddNewValue = useCallback(() => {
    append({ value: '', keyName: '' });
  }, [append]);

  const handleRemoveEnumValue = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  const revalidateDuplicatedElements = useCallback(() => {
    if (formState.errors.localizedValueFields?.forEach) {
      formState.errors.localizedValueFields.forEach((item, index) => {
        trigger(`${FIELD_ARRAY_NAME}.${index}.value`);
      });
    }
  }, [formState, trigger]);

  return (
    <>
      <Spacer size='md' />
      <Box display='flex' justifyContent='space-between'>
        <Box width={210} height={56} display='flex' marginTop='8px'>
          <CCPLabelCheckbox
            label={formatMessage({
              id: 'form.custom_field.field.first_as_default',
              defaultMessage: 'Set first field as default',
            })}
            regularWeight
            control={control}
            name='isFirstValueDefault'
          />
        </Box>
        <Box width={300}>
          <List onDragOver={dragOver} ref={listRef} sx={{ padding: 0 }}>
            {fields.length > 0 &&
              fields.map((field, index) => (
                <ListItem
                  key={field.id}
                  data-id={index}
                  draggable
                  onDragEnd={dragEnd}
                  onDragStart={dragStart}
                  sx={{ paddingLeft: 0, paddingRight: 0 }}>
                  <CCPTextField
                    control={control}
                    name={`${FIELD_ARRAY_NAME}.${index}.value`}
                    placeholder={`${formatMessage({
                      id: 'form.custom_field.enum_field.empty.label',
                      defaultMessage: 'Enter field value',
                    })} *`}
                    type='string'
                    label=''
                    InputProps={{
                      onBlur: revalidateDuplicatedElements,
                      startAdornment: (
                        <InputAdornment position='start'>
                          <DragIndicatorIcon cursor='grab' />
                        </InputAdornment>
                      ),
                      endAdornment: fields.length > 1 && (
                        <InputAdornment position='end'>
                          <DeleteSharpIcon
                            htmlColor={theme.palette.neutralDark.main}
                            cursor='pointer'
                            onClick={() => handleRemoveEnumValue(index)}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </ListItem>
              ))}
          </List>
          <Box>
            {fields.length < 30 ? (
              <AddOtherLanguage marginTop='8px'>
                <Typography onClick={handleAddNewValue}>
                  {`+ ${formatMessage({
                    id: 'form.custom_field.add_new_enum_value',
                    defaultMessage: 'add value',
                  })}`}
                </Typography>
              </AddOtherLanguage>
            ) : (
              <Box marginTop='8px'>
                <Typography variant='caption'>
                  {formatMessage({
                    id: 'form.custom_field.fields_limit',
                    defaultMessage: 'You cannot add more than 30 values',
                  })}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default EnumSortableList;
