import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { EmployeeStatusResponse, GetEmployeeStatusRequestParams, PimcoreErrorResponse } from 'types';
import { axiosWithConfig } from 'api';

export const getEmployeeStatus = createAsyncThunk<
  EmployeeStatusResponse,
  GetEmployeeStatusRequestParams,
  { rejectValue: PimcoreErrorResponse }
>('employeeManagement/getEmployeeStatus', async ({ employeeId }, { rejectWithValue }) => {

  const endpoint = process.env.REACT_APP_PIMCORE_ENDPOINT + `api/endusers/${employeeId}/status`;
  const axios = await axiosWithConfig();

  try {
    return (await axios.get(endpoint)).data;
  } catch (e) {
    const error: AxiosError<PimcoreErrorResponse> = e as AxiosError<PimcoreErrorResponse>;
    const { response } = error;
    const data = response?.data as PimcoreErrorResponse;
    return rejectWithValue(data);
  }
});
