import { styled } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { default as theme } from '@theme/theme';

export const TableInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: 16,
  border: `1px solid ${theme.palette.primaryLight.main}`,
  borderRight: 'unset',
  borderLeft: 'unset',
  padding: '15px 24px',
}));

export const TotalErrorWrapper = styled('div')(() => ({
  display: 'flex',
  fontWeight: 'bold',
  justifyContent: 'space-between',
}));

export const TotalError = styled('span')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const ShowIssues = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  marginLeft: 20,
  cursor: 'pointer',
}));

export const TotalEmployee = styled('span')(() => ({
  color: theme.palette.neutralDark.main,
  fontWeight: 'normal',
}));

export const NoData = styled('span', {
  shouldForwardProp: prop => prop !== 'color',
})(({ color }) => ({
  color: color || theme.palette.neutralDark.contrastText,
  fontWeight: 'normal',
}));

export const StepName = styled('span')(() => ({
  fontSize: 13,
  fontWeight: 'normal',
}));

export const Topbar = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 24,
  marginTop: 8,

  '.topbar-right': {
    fontSize: 16,
    lineHeight: 1.38,
    color: theme.palette.neutralDark.main,
  },
  '.topbar-active': {
    color: theme.palette.primary.main,
  },
}));

export const ErrorIconStyle = styled(ErrorIcon)(() => ({
  margin: '0 8px',
  color: theme.palette.error.main,
}));
