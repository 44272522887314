import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { TableRow, Typography, TableCell, Box } from '@mui/material';

import { CCPDefaultButton, EditPositionModal, PermissionsChipGroup } from 'components';
import { IUserInfo, OwnUserData } from 'types';
import { useAppDispatch, useAppSelector } from 'store';
import { useIntl } from 'react-intl';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';
import { SettingsActions } from 'features/settings/store';
import { useLanguageItems } from 'utils';

const PermissionTableRow: React.FC<{ item: IUserInfo }> = ({ item }) => {
  const intl = useIntl();
  const languageItems = useLanguageItems();
  const dispatch = useAppDispatch();
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const ownUserData = useAppSelector(SettingsSelectors.getOwnUserData);

  const closeEditModalToggler = (): void => {
    setIsOpenEditModal(!isOpenEditModal);
  };

  const submitEditModalHandler = (data: { position: string }): void => {
    if (!ownUserData) {
      return;
    }

    const payload: OwnUserData = {} as OwnUserData;
    payload.userdetails = ownUserData.userdetails;
    const companydetails = ownUserData.companydetails.find(
      company => Number(company.companyId) === Number(item.companyId),
    );

    if (!companydetails) {
      return;
    }
    payload.companydetails = [
      {
        ...companydetails,
        position: data.position,
      },
    ];
    dispatch(SettingsActions.changeOwnUserData(payload));

    closeEditModalToggler();
  };

  const currentLanguage = languageItems.find(li => li.value === item.language.toLowerCase());
  return (
    <>
      <TableRow>
        <TableCell>
          <Typography variant='h3'>{item.companyName}</Typography>
        </TableCell>
        <TableCell>{item.position}</TableCell>
        <TableCell>
          <Box className='added'>
            <PermissionsChipGroup role={item.role} />
          </Box>
        </TableCell>
        <TableCell>{currentLanguage ? currentLanguage.label : ''}</TableCell>
        <TableCell>
          <CCPDefaultButton variant='text' onClick={closeEditModalToggler} startIcon={<EditIcon />}>
            {intl.formatMessage({
              id: 'employee_management.bulk_upload.table.column.action.edit',
              defaultMessage: 'Edit',
            })}
          </CCPDefaultButton>
        </TableCell>
      </TableRow>
      {isOpenEditModal && (
        <EditPositionModal
          position={item.position}
          isOpen={isOpenEditModal}
          onSubmit={submitEditModalHandler}
          closeModal={closeEditModalToggler}
        />
      )}
    </>
  );
};

export default PermissionTableRow;
