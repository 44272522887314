import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { addUserExtraReducer } from './actions/async/addUser/addUser.reducer';
import { getUsersExtraReducer } from './actions/async/getUsers/getUsers.reducer';
import { updateFetchingStatus } from './actions/sync/userManagement.actions';
import { resetChangeUserEmailStatus } from './actions/sync/userManagement.actions';
import { changeUserEmailExtraReducer } from './actions/async/changeUserEmail/changeUserEmail.reducer';
import { verifyChangeUserEmailExtraReducer } from './actions/async/verifyChangeUserEmail/verifyChangeUserEmail.reducer';

const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: { updateFetchingStatus, resetChangeUserEmailStatus },
  extraReducers: builder => {
    addUserExtraReducer(builder);
    getUsersExtraReducer(builder);
    changeUserEmailExtraReducer(builder);
    verifyChangeUserEmailExtraReducer(builder);
  },
});

export const { name, actions } = userManagementSlice;
export const userManagementReducer = userManagementSlice.reducer;
