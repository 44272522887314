import { Box, IconButton } from '@mui/material';
import SearchIconActive from 'assets/icons/atoms-icon-search-active-24-x-24.svg';
import SearchIcon from 'assets/icons/atoms-icon-search-24-x-24.svg';
import ClearIcon from 'assets/icons/atoms-icons-clear-16-x-16.svg';
import { ChangeEvent, useCallback, useState } from 'react';
import { SearchField } from './Search.styles';
import { useAppDispatch, useAppSelector } from 'store';
import {
  GridFilterItem,
  GridFilterModel,
  GridLogicOperator,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { getCustomItems } from 'utils/employeeOverview';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { useIntl } from 'react-intl';
import { TablesManagementActions } from 'components/TableGrid/store';
import { ColumnData } from 'hooks';
import { DataGridTableConfiguration } from '@components/TableGrid/store/interfaces';

type SearchableItems = {
  field: string;
  operator: string;
  id: number;
  value: string;
};

const Search = <T extends GridValidRowModel>({
  tableConfiguration: { searchData, ...additionalConfigs } = {} as DataGridTableConfiguration,
  tableName,
  searchPlaceholder,
}: {
  tableConfiguration?: DataGridTableConfiguration;
  tableName: string;
  searchPlaceholder: string;
}) => {
  const intl = useIntl();
  const apiRef = useGridApiContext();
  const dispatch = useAppDispatch();

  const customFields = useAppSelector(state => state.company.api.getCompany.data?.customFields);
  const company = useAppSelector(state => state.company.activeCompany);

  const [isFocus, setIsFocus] = useState(false);

  const getDefaultItems = (
    e: ChangeEvent<HTMLInputElement>,
    columns: ColumnData<T>[],
  ): SearchableItems[] => {
    if (columns?.length) {
      return columns
        .filter(item => item.searchable)
        .map((item, i) => ({
          field: item.field,
          operator: 'contains',
          id: i,
          value: e.target.value,
        }));
    }
    return [];
  };

  const handleSetSearchTerm = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (company) {
        dispatch(
          TablesManagementActions.updateSearchTerm({
            tableName,
            companyId: company.companyId,
            searchTerm: e.target.value,
          }),
        );
        apiRef?.current?.setPage(0);
        const defaultItems = getDefaultItems(e, apiRef.current.getAllColumns());
        let customItems: GridFilterItem[] = [];
        if (tableName === 'employee' && customFields) {
          customItems = getCustomItems(e, customFields, defaultItems.length);
        }
        const items = [...defaultItems, ...customItems] as GridFilterItem[];

        const gridFilterModel: GridFilterModel = {
          items,
          logicOperator: GridLogicOperator.Or,
        };

        dispatch(
          TablesManagementActions.updateFilters({
            tableName,
            companyId: company.companyId,
            model: gridFilterModel,
          }),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [company, additionalConfigs],
  );

  const handleClear = useCallback(() => {
    if (company) {
      dispatch(
        TablesManagementActions.resetSearchData({
          tableName,
          companyId: company.companyId,
        }),
      );
      apiRef?.current?.setPage(0);
      dispatch(
        TablesManagementActions.resetFilters({
          tableName,
          companyId: company.companyId,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  return (
    <Box flexGrow='1' display='flex' justifyContent='flex-end'>
      <SearchField
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        onChange={handleSetSearchTerm}
        data-test-id='searchInput'
        placeholder={
          searchPlaceholder
            ? searchPlaceholder
            : intl.formatMessage({
                id: 'table_grid.search.placeholder',
                defaultMessage: 'Search by some predefined fields',
              })
        }
        inputProps={{
          'aria-label': 'search courses',
        }}
        value={searchData?.searchTerm || ''}
        InputProps={{
          endAdornment: (
            <>
              {searchData?.searchTerm && (
                <IconButton onClick={handleClear} edge='end' size='small' className='clear-icon'>
                  <img alt='' src={ClearIcon} />
                </IconButton>
              )}
              <IconButton aria-label='search' className='search-icon'>
                <img alt='' src={isFocus ? SearchIconActive : SearchIcon} />
              </IconButton>
            </>
          ),
        }}
      />
    </Box>
  );
};

export default Search;
