import { styled, MenuItem, Box } from '@mui/material';

export const BulkActionsWrapper = styled(Box)(() => ({
  '.ApplyButton': {
    padding: '9px',
    marginLeft: 8,
    marginRight: 8,
  },

  '.TextField': {
    width: 178,
    minWidth: 'auto',
    '.dots-in-text': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: '145px',
    },
  },
}));

export const MenuItemWrapper = styled(MenuItem)(({ theme }) => ({
  fontSize: 16,
  lineHeight: 1.38,
  color: theme.palette.neutralDark.contrastText,
  padding: '8px 16px',

  '& .accent': {
    color: theme.palette.error.main,
  },
}));
