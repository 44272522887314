import { useIntl } from 'react-intl';
import { CompanyRole } from 'types';

export const useNotificationsSettingsList = () => {
  const intl = useIntl();
  return [
    {
      name: 'onboarding',
      labelText: intl.formatMessage({
        id: 'user.notification.onboarding.labelText',
        defaultMessage:
          'If user has company right for employee management, notifications for onboarding can be changed',
      }),
      labelTitle: intl.formatMessage({
        id: 'user.notification.onboarding.title',
        defaultMessage: 'Onboarding',
      }),
    },
    {
      name: 'invoices',
      labelText: intl.formatMessage({
        id: 'user.notification.invoices.labelText',
        defaultMessage:
          'If user has company right for invoices, notifications for new invoices can be changed',
      }),
      labelTitle: intl.formatMessage({
        id: 'user.notification.invoices.title',
        defaultMessage: 'Invoices',
      }),
    },
  ];
};

export const usePermissionsSettingsList = (): {
  name: CompanyRole;
  labelText: string;
  labelTitle: string;
}[] => {
  const intl = useIntl();
  return [
    {
      name: CompanyRole.Usermgmt,
      labelText: intl.formatMessage({
        id: 'user.permission.employees_management.description',
        defaultMessage:
          'When you assign an Usermgmt role to a user, you grant them user management privileges.',
      }),
      labelTitle: intl.formatMessage({
        id: 'user.permission.employees_management',
        defaultMessage: 'Employees management',
      }),
    },
    {
      name: CompanyRole.Invoice,
      labelText: intl.formatMessage({
        id: 'user.permission.contract_and_invoice.description',
        defaultMessage:
          'When you assign an Invoice role to a user, you grant them invoices privileges',
      }),
      labelTitle: intl.formatMessage({
        id: 'user.permission.contract_and_invoice',
        defaultMessage: 'Contract & invoices',
      }),
    },
    {
      name: CompanyRole.Admin,
      labelText: intl.formatMessage({
        id: 'user.permission.user_administration.description',
        defaultMessage:
          'When you assign an Admin role to a user, you grant them admin privileges and access to the Admin console.',
      }),
      labelTitle: intl.formatMessage({
        id: 'user.permission.user_administration',
        defaultMessage: 'User administration',
      }),
    },
  ];
};
export const useAddNewUserFieldList = () => {
  const intl = useIntl();
  return [
    {
      name: 'name',
      label: intl.formatMessage({
        id: 'form.employee.field.first_name.label',
        defaultMessage: 'First name',
      }),
      placeholder: intl.formatMessage({
        id: 'form.employee.field.first_name.placeholder',
        defaultMessage: 'Enter first name',
      }),
    },
    {
      name: 'surname',
      label: intl.formatMessage({
        id: 'form.employee.field.last_name.label',
        defaultMessage: 'Last name',
      }),
      placeholder: intl.formatMessage({
        id: 'form.employee.field.last_name.placeholder',
        defaultMessage: 'Enter last name',
      }),
    },
    {
      name: 'position',
      label: intl.formatMessage({
        id: 'form.employee.field.position.label',
        defaultMessage: 'Position',
      }),
      placeholder: intl.formatMessage({
        id: 'form.employee.field.position.placeholder',
        defaultMessage: 'Enter position',
      }),
    },
    {
      name: 'email',
      label: intl.formatMessage({
        id: 'form.employee.field.email.label',
        defaultMessage: 'EMail',
      }),
      placeholder: intl.formatMessage({
        id: 'form.employee.field.email.placeholder',
        defaultMessage: 'Enter email',
      }),
    },
  ];
};

export const useUserOverviewMenuOptions = () => {
  const intl = useIntl();
  return [
    {
      value: 'PERMISSION',
      title: intl.formatMessage({
        id: 'user.menu_option.edit_permissions',
        defaultMessage: 'Edit permissions',
      }),
    },
    {
      value: 'BLOCK',
      title: intl.formatMessage({
        id: 'user.menu_option.block_user',
        defaultMessage: 'Block user',
      }),
    },
    {
      value: 'DELETE',
      title: intl.formatMessage({
        id: 'user.menu_option.delete_account',
        defaultMessage: 'Delete account',
      }),
    },
  ];
};
