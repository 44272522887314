import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { getUsers } from './getUsers';
import { UserManagementState } from '../../../interfaces';
import { CompanyRole, FetchingStatus } from 'types';

export const getUsersExtraReducer = (builder: ActionReducerMapBuilder<UserManagementState>) => {
  builder.addCase(getUsers.pending, (state, action) => {
    state.users.fetchingStatus = FetchingStatus.PENDING;
  });
  builder.addCase(getUsers.fulfilled, (state, action) => {
    state.users.data = action.payload.detail
      ? action.payload.detail.map(item => {
          //admin must be first, invoice must be last
          item.role.sort((a, b) => {
            if (a === CompanyRole.Admin || b === CompanyRole.Invoice) return -1;
            if (a === CompanyRole.Invoice || b === CompanyRole.Admin) return 1;
            return a.localeCompare(b);
          });
          return item;
        })
      : null;
    state.users.fetchingStatus = FetchingStatus.FULFILLED;
  });

  builder.addCase(getUsers.rejected, (state, action) => {
    state.users.error = action.error;
    state.users.fetchingStatus = FetchingStatus.REJECTED;
  });
};
