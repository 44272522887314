import * as yup from 'yup';

const ForgotPasswordSchema = yup
  .object({
    newPassword: yup
      .string()
      .required('New password is required')
      .min(6, 'New password must be at least 6 characters'),
    confirmPassword: yup
      .string()
      .required('Confirm password is required')
      .oneOf([yup.ref('newPassword'), null], 'Passwords do not match')
      .matches(/[a-z]+/, 'One lowercase character')
      .matches(/[A-Z]+/, 'One uppercase character')
      .matches(/\d+/, 'One number')
      .min(10, 'Confirm password must be at least 10 characters'),
  })
  .required();

export default ForgotPasswordSchema;
