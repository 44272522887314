import { S3Credentials, S3Data } from 'features/shared/s3Credentials/store/interfaces';

export const getS3Credential = ({
  data,
  type,
}: {
  data: S3Credentials;
  type: string;
}): S3Data | null => {
  let s3Invoice: S3Data = {
    AccessKeyId: '',
    SecretAccessKey: '',
    SessionToken: '',
    s3Url: '',
    Expiration: '',
  };
  Object.keys(data).forEach(item => {
    if (item.includes(type)) {
      s3Invoice = { ...data[item], s3Url: item };
    }
  });
  return s3Invoice.AccessKeyId ? s3Invoice : null;
};
