import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  Fragment,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import {
  CompanyCustomFieldForm,
  CompanyCustomFieldType,
  FetchingStatus,
  PimcoreErrorResponse,
  TranslationsKeys,
  TranslationsRecord,
} from 'types';
import { AddOtherLanguage, FieldsWrapper } from './CustomFieldModal.style';
import { AlertModal, CCPDefaultButton, CCPSelect, CCPTextField, Spacer, Tickbox } from 'components';
import { useInputType } from 'utils/customField';
import { showToast, useLanguageItems } from 'utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { CustomFieldSchema } from 'shared/schemas';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import { useAppDispatch, useAppSelector } from 'store';
import { SettingsActions } from 'features/settings/store';
import { CompanyActions } from '../../../../company/store';
import { default as theme } from '@theme/theme';
import EnumSortableList from '../EnumSortableList';
import Loader from '@components/Loader';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

type AddNewCustomFieldModalProps = {
  open: boolean;
  handleClose: () => void;
  data: CompanyCustomFieldForm;
  type: 'add' | 'edit';
  companyLanguage: TranslationsKeys;
  companyId: number | undefined;
};

const CustomFieldModal = ({
  open,
  handleClose,
  data,
  type,
  companyLanguage,
  companyId,
}: AddNewCustomFieldModalProps) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const listRef: MutableRefObject<HTMLUListElement | null> = useRef(null);
  const customFields = useAppSelector(store => store.settings.companyCustomField);
  const activeCompanyLanguage = companyLanguage;
  const [isMandatory, setIsMandatory] = useState<boolean>(data.mandatory === 'yes');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRemovePopup, setIsRemovePopup] = useState<boolean>(false);

  const inputTypes = useInputType();
  const supportLanguages = useLanguageItems();

  const companyLanguageLabel = supportLanguages.find(
    lg => lg.value === activeCompanyLanguage,
  )?.label;

  const otherLanguage = supportLanguages?.filter(item => item.value !== activeCompanyLanguage);

  const methods = useForm<CompanyCustomFieldForm>({
    defaultValues: data,
    resolver: yupResolver(CustomFieldSchema),
    mode: 'all',
  });

  const { control, handleSubmit, reset, formState, watch, trigger } = methods;

  const currentType = watch('inputType');
  const isEnumType = useMemo(() => currentType === 'enum', [currentType]);

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'translationsFields',
  });

  const { isValid } = formState;

  const activeCompanyLanguageIndex = fields.findIndex(
    translationsFields => translationsFields.languageName === activeCompanyLanguage,
  );

  const onSubmit = async (dataSubmit: CompanyCustomFieldForm) => {
    if (!companyId) {
      return;
    }
    setIsLoading(true);
    const payload = {} as CompanyCustomFieldType;

    //collect data for translate field name
    const defaultLanguage =
      dataSubmit.translationsFields.find(item => item.languageName === activeCompanyLanguage) ||
      dataSubmit.translationsFields[0];
    const removeData = (
      data?.translationsFields?.filter(
        field =>
          dataSubmit.translationsFields.findIndex(
            item => item.languageName === field.languageName,
          ) === -1,
      ) || []
    ).map(field => ({ ...field, value: null }));

    const name = [...dataSubmit.translationsFields, ...removeData].reduce(
      (name, currentName) => ({ ...name, [currentName.languageName]: currentName.value }),
      {},
    );

    //collect enum values for dropdown lists
    const arr: Array<{ value: string; keyName: string; id?: string }> = [];

    if (listRef) {
      listRef.current?.childNodes.forEach(child => {
        const elementId = Number((child as HTMLLIElement).dataset.id);
        const currentElem = dataSubmit.localizedValueFields.find((_, index) => index === elementId);
        if (currentElem)
          arr.push({ ...currentElem, keyName: currentElem.keyName || currentElem.value });
      });
    }
    const enumValues: Record<string, TranslationsRecord> | undefined = arr.reduce((acc, item) => {
      if (data.localizedValuesMap && data.localizedValuesMap[item.keyName]) {
        return {
          ...acc,
          [item.keyName]: {
            ...data.localizedValuesMap[item.keyName],
            [activeCompanyLanguage]: item.value,
          },
        };
      } else {
        return { ...acc, ...{ [item.keyName]: { [activeCompanyLanguage]: item.value } } };
      }
    }, {});

    payload.key = dataSubmit.key || defaultLanguage.value.replace(/[^A-Za-z0-9]/g, '_');
    payload.name = name as TranslationsRecord;
    payload.inputType = dataSubmit.inputType;
    payload.mandatory = isMandatory ? 'yes' : 'no';
    payload.isAddressRelevant = 'no';
    payload.isFirstValueDefault = dataSubmit.isFirstValueDefault ? 'yes' : 'no';
    payload.localizedValuesMap = enumValues;
    try {
      if (type === 'add') {
        await dispatch(
          SettingsActions.addCompanyCustomField({
            companyId,
            body: payload,
          }),
        ).unwrap();
      }
      if (type === 'edit') {
        await dispatch(
          SettingsActions.updateCompanyCustomField({
            companyId,
            body: payload,
          }),
        ).unwrap();
      }
      dispatch(CompanyActions.getCompany({ companyId }));
      handleClose();
      reset();
    } catch (e) {
      const hasLanguageError = (e as PimcoreErrorResponse)?.violations?.find(
        violation => violation.code === 'REQUIRED_LANGUAGE_IS_NOT_SET',
      );
      if (hasLanguageError) {
        showToast(
          'error',
          intl.formatMessage({
            id: 'error.require_language_is_not_set',
            defaultMessage: 'Please log out and log in again, to update all company relevant data',
          }),
        );
      }

      const isEmptyDDL = (e as PimcoreErrorResponse)?.violations?.find(
        violation => violation.code === 'AT_LEAST_ONE_LOCALIZED_VALUE_MUST_BE_FILLED',
      );
      if (isEmptyDDL) {
        showToast(
          'error',
          intl.formatMessage({
            id: 'error.require_localized_values_is_not_set',
            defaultMessage: 'Please add minimum one value for the dropdown',
          }),
        );
      }
    }
    setIsLoading(false);
  };

  const handleCloseModal = () => {
    handleClose();
    reset();
  };

  const handleAddOtherLanguage = useCallback(() => {
    const newLanguage = otherLanguage?.find(
      item => !fields.some(translationsFields => translationsFields.languageName === item.value),
    );

    if (newLanguage) {
      const existIndex = fields.findIndex(
        translationsFields => translationsFields.languageName === newLanguage.value,
      );
      if (existIndex !== -1) {
        const id = fields[existIndex]?.id;
        update(existIndex, { id, languageName: newLanguage.value, value: '' });
        return;
      }
      append({ languageName: newLanguage.value, value: '' });
    }
  }, [append, otherLanguage, fields, update]);

  const handleRemoveLanguage = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  const tickboxChange = (key: string, value: boolean) => {
    setIsMandatory(value);
  };

  // validate form after loading data
  useEffect(() => {
    if (data && data.key !== '') {
      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const showRemovePopup = () => {
    setIsRemovePopup(true);
  };

  const deleteCustomField = async () => {
    if (companyId && data.key) {
      await dispatch(
        SettingsActions.deleteCompanyCustomField({ companyId, customFieldId: data.key }),
      );
      dispatch(CompanyActions.getCompany({ companyId }));
    }
  };

  const handleCloseModalAndRequest = () => {
    if (companyId)
      dispatch(
        SettingsActions.getCompanyCustomField({
          companyId,
        }),
      );
    handleClose();
    reset();
  };

  if (!companyId) {
    return <div></div>;
  }

  if (customFields[companyId].isRemoved) {
    return (
      <AlertModal
        isOpen
        hideDescription
        iconComponent={<CheckCircleOutlinedIcon htmlColor={theme.palette.success.main} />}
        title={intl.formatMessage({
          id: 'form.custom_field.modal.deleted.title',
          defaultMessage: 'Custom field has been deleted',
        })}
        mainContent={
          <Typography variant='subtitle1'>
            {intl.formatMessage(
              {
                id: 'form.custom_field.modal.deleted.description',
                defaultMessage:
                  'Custom field {higlightMessage} and associated data successfully deleted.',
              },
              {
                higlightMessage: (
                  <b>{`“${
                    activeCompanyLanguageIndex !== -1
                      ? data.translationsFields[activeCompanyLanguageIndex].value
                      : data.translationsFields[0].value
                  }”`}</b>
                ),
              },
            )}
          </Typography>
        }
        handleClose={handleCloseModalAndRequest}
        buttonText={intl.formatMessage({
          id: 'form.custom_field.modal.deleted.button.cancel',
          defaultMessage: 'Back to settings',
        })}
      />
    );
  }
  if (isRemovePopup) {
    return (
      <>
        <Loader show={customFields[companyId].fetchingStatus === FetchingStatus.PENDING} />
        <AlertModal
          isOpen
          showConfirmButton
          hideDescription
          title={intl.formatMessage({
            id: 'form.custom_field.modal.delete.title',
            defaultMessage: 'Delete Custom field',
          })}
          mainContent={
            <Typography variant='subtitle1'>
              {intl.formatMessage(
                {
                  id: 'form.custom_field.modal.delete.description',
                  defaultMessage:
                    'You are about to delete a custom field from the database. {permanentMessage} Continue?',
                },
                {
                  permanentMessage: (
                    <b>
                      {intl.formatMessage({
                        id: 'form.custom_field.modal.delete.description.permanent',
                        defaultMessage:
                          'This action will permanently remove all data associated with this field.',
                      })}
                    </b>
                  ),
                },
              )}
            </Typography>
          }
          handleClose={() => {
            setIsRemovePopup(false);
          }}
          handleConfirm={deleteCustomField}
          confirmButtonText={intl.formatMessage({
            id: 'form.custom_field.modal.delete.button.confirm',
            defaultMessage: 'Confirm & delete',
          })}
          confirmButtonColor='error'
          buttonText={intl.formatMessage({
            id: 'form.custom_field.modal.delete.button.cancel',
            defaultMessage: 'Cancel',
          })}
        />
      </>
    );
  }

  return (
    <FormProvider {...methods}>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        scroll='paper'
        component='form'
        onSubmit={handleSubmit(onSubmit)}>
        <Loader show={isLoading} />
        <DialogTitle>
          {type === 'add'
            ? intl.formatMessage({
                id: 'form.custom_field.modal.add.title',
                defaultMessage: 'Add new custom field',
              })
            : intl.formatMessage({
                id: 'form.custom_field.modal.edit.title',
                defaultMessage: 'Edit custom field',
              })}
        </DialogTitle>
        <DialogContent>
          <Typography variant='subtitle1'>
            {type === 'add'
              ? intl.formatMessage({
                  id: 'form.custom_field.modal.add.label',
                  defaultMessage:
                    'To collect specific information of employees you can create up to 3 company custom fields.',
                })
              : intl.formatMessage({
                  id: 'form.custom_field.modal.edit.label',
                  defaultMessage: 'Fill in the following fields to edit a custom field.',
                })}
          </Typography>
          <Spacer size='xl' />

          <FieldsWrapper>
            <Box width={210}>
              <TextField
                required
                disabled
                value={companyLanguageLabel}
                label={intl.formatMessage({
                  id: 'form.custom_field.field.company_language.label',
                  defaultMessage: 'Company language',
                })}
              />
            </Box>
            <Box width={300} display='flex' alignItems='flex-end'>
              <Typography className='note'>
                {intl.formatMessage({
                  id: 'form.custom_field.company_language.note',
                  defaultMessage: 'The custom field is created in the selected company language.',
                })}
              </Typography>
            </Box>
            <Spacer size='md' />
            <CCPSelect
              disabled={type === 'edit'}
              control={control}
              name='inputType'
              required
              label={intl.formatMessage({
                id: 'form.custom_field.field.input_type.label',
                defaultMessage: 'Select data type',
              })}
              width={212}>
              {inputTypes.map(inputType => (
                <MenuItem key={inputType.value} value={inputType.value}>
                  {inputType.title}
                </MenuItem>
              ))}
            </CCPSelect>
            <CCPTextField
              control={control}
              name={
                activeCompanyLanguageIndex !== -1
                  ? `translationsFields[${activeCompanyLanguageIndex}].value`
                  : 'translationsFields[0].value'
              }
              required
              label={intl.formatMessage({
                id: 'form.custom_field.field.name.label',
                defaultMessage: 'Enter custom field name',
              })}
              width={300}
            />
          </FieldsWrapper>
          {isEnumType && <EnumSortableList listRef={listRef} />}
          <Spacer size='md' />
          <Tickbox
            label={intl.formatMessage({
              id: 'form.custom_field.field.mandatory',
              defaultMessage: 'Make custom field mandatory',
            })}
            value={isMandatory}
            fieldName='mandatory'
            handleChange={tickboxChange}
          />
          <Spacer size='2xl' />
          <Typography variant='h6'>
            {intl.formatMessage({
              id: 'form.custom_field.translation.title',
              defaultMessage: 'Translation',
            })}
          </Typography>
          <Spacer size='md' />
          <Typography className='subHeader'>
            {intl.formatMessage({
              id: 'form.custom_field.translation.label',
              defaultMessage:
                'With the translations of the custom fields, the portal can be used in several languages and offers added value for your company.',
            })}
          </Typography>
          {fields.length > 0 && (
            <FieldsWrapper>
              <Spacer size='xl' />
              {fields.map((field, index) => (
                <Fragment key={field.id}>
                  {field.languageName !== activeCompanyLanguage && (
                    <>
                      <CCPSelect
                        required
                        control={control}
                        name={`translationsFields.${index}.languageName`}
                        label={intl.formatMessage({
                          id: 'form.custom_field.field.language.label',
                          defaultMessage: 'Language',
                        })}
                        width={184}>
                        {otherLanguage.map(supportLanguage => (
                          <MenuItem key={supportLanguage.value} value={supportLanguage.value}>
                            {supportLanguage.label}
                          </MenuItem>
                        ))}
                      </CCPSelect>
                      <CCPTextField
                        required
                        control={control}
                        name={`translationsFields.${index}.value`}
                        label={intl.formatMessage({
                          id: 'form.custom_field.field.language_translation.label',
                          defaultMessage: 'Enter translation',
                        })}
                        width={288}
                      />
                      <Box className='deleteIcon' onClick={() => handleRemoveLanguage(index)}>
                        <DeleteSharpIcon htmlColor={theme.palette.neutralDark.main} />
                      </Box>

                      <Spacer size='md' />
                    </>
                  )}
                </Fragment>
              ))}
            </FieldsWrapper>
          )}
          {fields.length < supportLanguages.length && (
            <Box>
              <Spacer size='md' />
              <AddOtherLanguage>
                <Typography onClick={handleAddOtherLanguage}>
                  {`+ ${intl.formatMessage({
                    id: 'form.custom_field.add_another_language',
                    defaultMessage: 'add another language',
                  })}`}
                </Typography>
              </AddOtherLanguage>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ padding: '24px 48px 32px 32px' }}>
          <Stack width='100%' flexDirection='row' justifyContent='space-between'>
            <Box>
              {type === 'edit' && (
                <CCPDefaultButton variant='text' color='error' onClick={showRemovePopup}>
                  {intl.formatMessage({
                    id: 'form.employee.cta.delete',
                    defaultMessage: 'Delete this field',
                  })}
                </CCPDefaultButton>
              )}
            </Box>
            <Box display='flex' gap='16px'>
              <CCPDefaultButton variant='outlined' onClick={handleCloseModal}>
                {intl.formatMessage({ id: 'form.employee.cta.cancel', defaultMessage: 'Cancel' })}
              </CCPDefaultButton>
              <CCPDefaultButton variant='contained' type='submit' disabled={!isValid}>
                {type === 'add'
                  ? intl.formatMessage({
                      id: 'button.cta.add_new_custom_field',
                      defaultMessage: 'Add new custom field',
                    })
                  : intl.formatMessage({
                      id: 'button.cta.save_custom_field',
                      defaultMessage: 'Save custom field',
                    })}
              </CCPDefaultButton>
            </Box>
          </Stack>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

export default CustomFieldModal;
