import { StatusChip } from 'components/StatusChip';
import { useMemo } from 'react';
import { useUserStatus } from '../../utils/userStatus';
import { getStatusColor } from '../../utils';
import {
  EmployeeStatus,
  TransitionStatus,
} from '../../features/employeeManagement/store/interfaces';

export interface StatusProps {
  status: EmployeeStatus | TransitionStatus;
  date?: string;
  plannedStatus?: boolean;
}

const StatusComponent = ({ status, date, plannedStatus }: StatusProps) => {
  const statusTitle = useUserStatus({
    status,
    entryDate: date,
    isPlannedTransition: plannedStatus,
  });

  const backgroundColor = useMemo(
    () =>
      getStatusColor(
        status === EmployeeStatus.LOCKED ? EmployeeStatus.BLOCKED : status,
        plannedStatus,
      ),
    [status, plannedStatus],
  );

  return (
    <StatusChip
      background={backgroundColor}
      label={statusTitle}
      className={status === 'pending' || plannedStatus ? 'isBlur' : ''}
    />
  );
};

export default StatusComponent;
