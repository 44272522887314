import { Button, styled } from '@mui/material';

const CCPGrayButton = styled(Button)(({ theme }) => ({
  '&:visited': {
    background: theme.palette.neutralDark.main,
    border: theme.palette.neutralDark.main,
  },
  '&:hover': {
    background: theme.palette.neutralDark.main,
    border: theme.palette.neutralDark.main,
    opacity: 0.8,
  },
  '&:active': {
    background: theme.palette.neutralDark.main,
    border: theme.palette.neutralDark.main,
  },
  padding: '7px 16px',
  color: theme.palette.primary.contrastText,
  background: theme.palette.neutralDark.main,
  fontFamily: 'Roboto-Medium',
  fontSize: 16,
  fontWeight: 500,
  lineHeight: 1.38,
  borderRadius: '32px',
  textTransform: 'unset',
  minWidth: 142,
  border: theme.palette.neutralDark.main,
  '&.Mui-disabled': {
    background: theme.palette.neutralDark.main,
    color: theme.palette.primary.contrastText,
  },
}));

export default CCPGrayButton;
