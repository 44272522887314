import { styled, Box } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  '.MuiDataGrid-main': {
    overflow: 'inherit',
  },
  '.status': { display: 'flex' },
  '.status__update': {
    '&:visited': { background: 'transparent' },
    '&:hover': { background: 'transparent' },
    '&:active': { background: 'transparent' },
    marginLeft: 16,
    padding: 0,
    color: theme.palette.neutralDark.main,
    span: {
      background: 'transparent',
      margin: 0,
    },

    svg: {
      fontSize: 16,
      marginRight: 8,
    },
  },
  '.permissions': {
    width: '100%',
    gap: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '&__list': {
      gap: '5px 12px',
      flexWrap: 'wrap',
      display: 'flex',
      alignItems: 'center',

      span: {
        fontSize: 16,
        lineHeight: 1.38,
        color: theme.palette.neutralDark.contrastText,
      },
    },
  },
}));

export const UserOverviewWrapper = styled(Box)(({ theme }) => ({
  '.header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export const NameWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: 'max-content',
  alignItems: 'center',
  '.user': {
    gap: 16,
    display: 'flex',
    alignItems: 'center',

    '&__avatar': {
      width: 44,
      height: 44,
      background: theme.palette.primaryLight.main,
      color: theme.palette.primary.main,
      fontFamily: 'Roboto-Medium',
      fontSize: 18,
      fontWeight: 500,
      lineHeight: 1.22,
      textTransform: 'uppercase',
    },

    '&__name': {
      fontFamily: 'Roboto-Bold',
      fontSize: 16,
      fontWeight: 'bold',
      lineHeight: 1.38,
      marginBottom: 2,
      maxWidth: 200,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },

    '&__position': {
      fontSize: 14,
      lineHeight: 1.43,
      letterSpacing: 0.18,
      color: theme.palette.neutralDark.main,
    },

    '&__own_wrapper': {
      backgroundColor: theme.palette.transparent.dropdownSelected,
      borderRadius: 2,
      height: 18,
      padding: '2px 4px',
      marginLeft: 8,
    },

    '&__own': {
      textTransform: 'uppercase',
      fontFamily: 'Roboto-Medium',
      color: theme.palette.neutralDark.main,
      fontSize: 12,
      lineHeight: 1.17,
    },
  },
}));
