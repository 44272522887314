import { Button, styled } from '@mui/material';

const CCPDefaultButton = styled(Button)(({ theme, variant }) => ({
  display: 'flex',
  gap: '8px',
  borderRadius: '9999px',
  padding: variant === 'text' ? '7px' : '8px 18px',
  minWidth: variant === 'text' ? 'auto' : 142,
  '& .MuiButton-startIcon': {
    margin: 0,
  },
}));

export default CCPDefaultButton;
