import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './initialState';
import { getFaqsExtraReducer } from './actions/async/getFaqs/getFaqs.reducer';

const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {},
  extraReducers: builder => {
    getFaqsExtraReducer(builder);
  },
});

export const { name, actions } = supportSlice;
export const supportReducer = supportSlice.reducer;
