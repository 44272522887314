import { Box, styled } from '@mui/material';

export const ShareLinkWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  border: `1px solid  ${theme.palette.neutralLight.dark}`,
  padding: '0 0 0 7px',
  width: 'fit-content',
  borderRadius: 4,
  p: {
    maxWidth: '300px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  button: {
    minWidth: 'auto',
    borderRadius: 4,
    color: theme.palette.neutralDark.main,
  },
}));
