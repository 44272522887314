import { SettingsActions } from 'features/settings/store';
import React, { useEffect, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import deTranslations from '../../../translations/de.json';
import enTranslations from '../../../translations/en.json';
import { ToastProvider } from 'components';
import { AuthenticationSelectors } from 'features/authentication/store/authentication.selectors';
import { useDocumentTitle } from '@hooks/useDocumentTitle';
import Loader from '@components/Loader';
import { useLanguage } from '@hooks/useLanguage';
import { TranslationsKeys } from '@localTypes/user';
export interface IntlProviderLayoutProps {
  children: React.ReactNode;
}

const IntlProviderLayout: React.FC<IntlProviderLayoutProps> = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentLanguage = useLanguage();
  const { pathname, search } = useLocation();
  const [cookies, setCookies] = useCookies(['language_app']);

  const [messages, setMessages] = useState<Record<string, string>>({});
  const [initialCookies, setInitialCookies] = useState(true);

  const { userInfo } = useAppSelector(AuthenticationSelectors.getAuthentication);

  const getActiveLanguage = () => {
    if (currentLanguage) {
      setCookies('language_app', currentLanguage, { path: '/' });
    } else {
      setCookies('language_app', 'de', { path: '/' });
    }
    setInitialCookies(false);
  };

  useEffect(() => {
    getActiveLanguage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMessage = async (selectedLanguage: TranslationsKeys) => {
    try {
      if (
        process.env.REACT_APP_TRANSLATE === 'remote' &&
        window.location.hostname !== 'localhost'
      ) {
        const messageResponse = await dispatch(
          SettingsActions.updateLanguage({
            activeLanguage: selectedLanguage,
          }),
        ).unwrap();
        setMessages(messageResponse);
      } else {
        if (selectedLanguage === 'de') {
          setMessages(deTranslations);
        }
        if (selectedLanguage === 'en') {
          setMessages(enTranslations);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (initialCookies) {
      return;
    }
    if (userInfo?.language) {
      const userLanguage = userInfo.language.toLowerCase();
      if (userLanguage !== currentLanguage) {
        setCookies('language_app', userLanguage, { path: '/' });
        const newPath = pathname.replace(currentLanguage, userLanguage) + search;
        navigate(newPath);
        return;
      }
      getMessage(userLanguage);
    } else {
      getMessage(currentLanguage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage, initialCookies, userInfo?.language]);

  useDocumentTitle(
    messages.hasOwnProperty('html.title.tag') ? messages['html.title.tag'] : 'Customer portal',
  );

  const loadingMessages = useMemo(() => Object.keys(messages).length === 0, [messages]);

  if (!cookies?.language_app) {
    return null;
  }

  return (
    <IntlProvider
      key={currentLanguage}
      locale={currentLanguage}
      messages={messages}
      onError={() => undefined}>
      <Loader show={loadingMessages} transparentLoader={false} />
      <ToastProvider />
      {children}
    </IntlProvider>
  );
};

export default IntlProviderLayout;
