import { createSlice } from '@reduxjs/toolkit';
import {
  removeNotification,
  setNotification,
} from 'features/shared/notification/store/actions/sync/notification.actions';
import { initialState } from './initialState';

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification,
    removeNotification,
  },
});

export const { name, actions } = notificationSlice;
export const notificationReducer = notificationSlice.reducer;
