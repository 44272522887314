import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CCPDatePicker, CCPDefaultButton, Spacer } from 'components';
import { PauseEmployeeForm } from './interfaces';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import PauseEmployeeSchema from 'shared/schemas/PauseEmployeeSchema';
import { useIntl } from 'react-intl';
import { EmployeeStatus } from 'types';
import { format, startOfTomorrow } from 'date-fns';
import { useAppSelector } from 'store';
import {
  checkTypeParameter,
  getDate,
  checkIsMonthPicker,
  prepareMaxDateForPicker,
  prepareMinDateForPicker,
} from 'utils/contractDependencies';
import { getDayAfterToday } from 'utils/getDayAfterTomorrow';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';

export const PauseEmployeeModal: React.FC<{
  employeeTitle?: string | null;
  employeeName?: string;
  transitionStartDate?: string | undefined;
  transitionEndDate?: string | undefined;
  transitionType: EmployeeStatus | string;
  close?: () => void;
  hasRevert?: boolean;
  onSubmit?: ({
    transition,
    startDate,
    endDate,
  }: {
    transition: EmployeeStatus | string;
    startDate?: string;
    endDate?: string;
  }) => void;
}> = ({
  employeeTitle,
  employeeName,
  transitionStartDate,
  transitionEndDate,
  transitionType,
  hasRevert = false,
  close = () => {},
  onSubmit = () => {},
}) => {
  const tomorrow = startOfTomorrow();
  const { formatMessage } = useIntl();
  const activeContract = useAppSelector(state => state.contracts.euCreationCondition.data);
  const isMonthPicker = useMemo(
    () => checkIsMonthPicker(activeContract?.regularStartDate),
    [activeContract?.regularStartDate],
  );
  const [open, setOpen] = useState(true);

  const handleClose = useCallback(() => {
    setOpen(false);
    if (close) {
      close();
    }
  }, [close]);

  const handleModalSubmit = (data?: PauseEmployeeForm) => {
    if (transitionType === EmployeeStatus.PAUSED) {
      const startDate = format(
        getDate(data?.startDate || null, activeContract?.regularStartDate),
        'yyyy-MM-dd',
      );
      const endDate = format(
        getDate(data?.endDate || null, activeContract?.regularStartDate, true),
        'yyyy-MM-dd',
      );

      if (onSubmit) {
        onSubmit({ transition: transitionType, startDate, endDate });
      }
    }
  };

  const maxEndDate = useMemo(
    () => prepareMaxDateForPicker(activeContract?.endDate, isMonthPicker, 36),
    [activeContract?.endDate, isMonthPicker],
  );

  const prefilledStartDate = useMemo(
    () =>
      transitionStartDate && transitionEndDate
        ? new Date(transitionStartDate)
        : prepareMinDateForPicker(tomorrow, isMonthPicker),
    [transitionStartDate, transitionEndDate, isMonthPicker, tomorrow],
  );

  const prefilledEndDate = useMemo(() => {
    if (transitionEndDate) {
      return new Date(transitionEndDate);
    }
    const currentEndDate = prepareMinDateForPicker(getDayAfterToday(2), isMonthPicker);
    if (
      isMonthPicker &&
      currentEndDate &&
      prefilledStartDate &&
      new Date(prefilledStartDate).getTime() === currentEndDate.getTime()
    ) {
      return new Date(currentEndDate.getFullYear(), currentEndDate.getMonth() + 1, 0);
    }
    return currentEndDate;
  }, [isMonthPicker, prefilledStartDate, transitionEndDate]);

  const methods = useForm<PauseEmployeeForm>({
    defaultValues: {
      startDate: prefilledStartDate,
      endDate: prefilledEndDate,
    },
    mode: 'all',
    resolver: yupResolver(PauseEmployeeSchema(maxEndDate)),
  });
  const {
    control,
    handleSubmit,
    formState: { isValid },
    watch,
    trigger,
    setValue,
  } = methods;

  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');
  useEffect(() => {
    trigger('endDate');
  }, [watchStartDate, trigger]);
  useEffect(() => {
    trigger('startDate');
    if (watchEndDate && activeContract?.regularStartDate === '1st_of_month') {
      if (maxEndDate.getTime() !== new Date(watchEndDate).getTime()) {
        const currentEndDate = new Date(watchEndDate);
        const newEndDate = new Date(currentEndDate.getFullYear(), currentEndDate.getMonth() + 1, 0);
        if (newEndDate.getTime() !== new Date(watchEndDate).getTime()) {
          setValue('endDate', newEndDate);
        }
      }
    }
  }, [watchEndDate, trigger, setValue, maxEndDate, activeContract?.regularStartDate]);

  const handleRevert = () => {
    onSubmit({ transition: EmployeeStatus.PAUSE_REMOVED, startDate: '', endDate: '' });
  };

  return !checkTypeParameter(activeContract, 'pauseContractPossible') ? null : (
    <Dialog open={open} onClose={handleClose}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleModalSubmit)}>
          <CloseIcon onClick={handleClose} className='dialog-close' />
          <DialogTitle>
            {formatMessage({
              id: 'employee.modal.pause.title',
              defaultMessage: 'Pause employee membership',
            })}
          </DialogTitle>

          <DialogContent>
            <Typography variant='h3'>
              {employeeTitle ? ` ${employeeTitle} ` : ''}
              {employeeName}
            </Typography>
            <Spacer size='xl' />
            <Typography variant='subtitle1'>
              {!isMonthPicker
                ? formatMessage({
                    id: 'employee.modal.pause.content',
                    defaultMessage: 'Description text, that explains what this action will cause',
                  })
                : formatMessage({
                    id: 'employee.modal.pause_single.content',
                    defaultMessage: `Click on Confirm to pause the employee's membership on the first day of the start month. The last day of the pause is the last day of the selected end month. Membership is active again on the next day.`,
                  })}
            </Typography>
            <Spacer size='2xl' />
            <Stack direction='row' justifyContent='space-between' spacing={2}>
              <CCPDatePicker
                label={`${
                  !isMonthPicker
                    ? formatMessage({
                        id: 'form.employee.field.start_date.label',
                        defaultMessage: 'Start date',
                      })
                    : formatMessage({
                        id: 'form.employee.field.start_date_single.label',
                        defaultMessage: 'Start month',
                      })
                }`}
                name='startDate'
                control={control}
                width={254}
                minDate={prepareMinDateForPicker(tomorrow, isMonthPicker)}
                maxDate={prepareMaxDateForPicker(activeContract?.endDate, isMonthPicker)}
                isMonthPicker={isMonthPicker}
              />
              <CCPDatePicker
                label={
                  !isMonthPicker
                    ? formatMessage({
                        id: 'form.employee.field.end_date.label',
                        defaultMessage: 'End date',
                      })
                    : formatMessage({
                        id: 'form.employee.field.end_date_single.label',
                        defaultMessage: 'End month',
                      })
                }
                name='endDate'
                control={control}
                width={254}
                minDate={prepareMinDateForPicker(tomorrow, isMonthPicker)}
                maxDate={maxEndDate}
                isMonthPicker={isMonthPicker}
              />
            </Stack>
          </DialogContent>

          <DialogActions>
            {hasRevert ? (
              <CCPDefaultButton variant='outlined' onClick={handleRevert} color='error'>
                {formatMessage({
                  id: 'form.employee.cta.revert_pause',
                  defaultMessage: 'Revert pause',
                })}
              </CCPDefaultButton>
            ) : (
              <CCPDefaultButton variant='outlined' onClick={handleClose}>
                {formatMessage({ id: 'form.employee.cta.cancel', defaultMessage: 'Cancel' })}
              </CCPDefaultButton>
            )}
            <CCPDefaultButton disabled={!isValid} variant='contained' type='submit'>
              {formatMessage({
                id: 'form.employee.cta.confirm_pause',
                defaultMessage: 'Confirm & pause',
              })}
            </CCPDefaultButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default PauseEmployeeModal;
