import { RoutePaths } from './paths';
import {
  StatsPage,
  LoginPage,
  SupportPage,
  CompanyPage,
  CheckInsPage,
  InvoicesPage,
  SettingsPage,
  SignupCustomize,
  CouponUsagePage,
  UserAccountPage,
  UserOverviewPage,
  AllCompaniesPage,
  SupportDetailPage,
  ActiveMembersPage,
  ResetPasswordPage,
  InitialPasswordPage,
  SupportArticlesPage,
  SelfRegistrationPage,
  EmployeeOverviewPage,
  EmployeeBulkUploadPage,
  EmployeeGeneralInfoPage,
  EmployeePendingRequestsPage,
  MarketingMaterialListPage,
} from 'features';

import { ConfigRoute, RouteType } from 'types';
import { ContractPage } from '../features/contract/pages';
import ServiceProviderPage from 'features/serviceProviders/pages';

const { AUTHORIZED, UNAUTHORIZED } = RouteType;

const routes: ConfigRoute[] = [
  {
    feature: 'login',
    routeType: UNAUTHORIZED,
    props: {
      path: RoutePaths.DefaultPage,
      component: LoginPage,
      title: '',
    },
  },
  {
    feature: 'login',
    routeType: UNAUTHORIZED,
    props: {
      path: RoutePaths.Login,
      component: LoginPage,
      title: '',
    },
  },
  {
    feature: 'resetPassword',
    routeType: UNAUTHORIZED,
    props: {
      path: RoutePaths.ResetPassword,
      component: ResetPasswordPage,
      title: '',
    },
  },
  {
    feature: 'selfRegistration',
    routeType: UNAUTHORIZED,
    props: {
      path: RoutePaths.InitialPassword,
      component: InitialPasswordPage,
      title: '',
    },
  },
  {
    feature: 'selfRegistration',
    routeType: UNAUTHORIZED,
    props: {
      path: RoutePaths.SelfRegistration,
      component: SelfRegistrationPage,
      title: '',
    },
  },
  {
    feature: 'userManagement',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.UsersOverview,
      component: UserOverviewPage,
      title: '',
    },
  },
  {
    feature: 'employeeManagement',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.EmployeeBulkUpload,
      component: EmployeeBulkUploadPage,
      title: '',
    },
  },
  {
    feature: 'employeeManagement',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.EmployeePendingRequests,
      component: EmployeePendingRequestsPage,
      title: '',
    },
  },
  {
    feature: 'employeeManagement',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.EmployeeOverview,
      component: EmployeeOverviewPage,
      title: '',
    },
  },
  {
    feature: 'employeeManagement',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.EmployeeGeneralInfo,
      component: EmployeeGeneralInfoPage,
      title: '',
    },
  },
  {
    feature: 'marketingMaterials',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.MarketingMaterialList,
      component: MarketingMaterialListPage,
      title: '',
    },
  },
  {
    feature: 'userManagement',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.Account,
      component: UserAccountPage,
      title: '',
    },
  },
  {
    feature: 'settings',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.Settings,
      component: SettingsPage,
      title: '',
    },
  },
  {
    feature: 'SignupCustomize',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.SettingsCustomizeForm,
      component: SignupCustomize,
      title: '',
    },
  },
  {
    feature: 'invoices',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.Invoices,
      component: InvoicesPage,
      title: '',
    },
  },
  {
    feature: 'companies',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.AllCompanies,
      component: AllCompaniesPage,
      title: '',
    },
  },
  {
    feature: 'companies',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.Company,
      component: CompanyPage,
      title: '',
    },
  },
  {
    feature: 'stats',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.Stats,
      component: StatsPage,
      title: '',
    },
  },
  {
    feature: 'stats',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.CheckIns,
      component: CheckInsPage,
      title: '',
    },
  },
  {
    feature: 'support',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.Support,
      component: SupportPage,
      title: '',
    },
  },
  {
    feature: 'support',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.SupportDetail,
      component: SupportDetailPage,
      title: '',
    },
  },
  {
    feature: 'support',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.SupportArticles,
      component: SupportArticlesPage,
      title: '',
    },
  },
  {
    feature: 'stats',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.ActiveMembers,
      component: ActiveMembersPage,
      title: '',
    },
  },
  {
    feature: 'stats',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.CouponUsage,
      component: CouponUsagePage,
      title: '',
    },
  },
  {
    feature: 'Contract',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.Contract,
      component: ContractPage,
      title: '',
    },
  },
  {
    feature: 'ServiceProviders',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.ServiceProviders,
      component: ServiceProviderPage,
      title: '',
    },
  },
];

export default routes;
