import { StepConnector, styled } from '@mui/material';

const CCPStepConnector = styled(StepConnector)(({ theme }) => ({
  zIndex: 1,
  backgroundColor: 'red',
  '.MuiStepConnector-line ': {
    height: 2,
    border: 0,
    backgroundColor: theme.palette.primaryLight.main,
  },
}));

export default CCPStepConnector;
