import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { FetchingStatus } from 'types';

import { SupportState } from '../../../interfaces';
import { getFaqs } from './getFaqs';

export const getFaqsExtraReducer = (builder: ActionReducerMapBuilder<SupportState>) => {
  builder.addCase(getFaqs.pending, (state, action) => {
    state.error = null;
    state.fetchingStatus = FetchingStatus.PENDING;
  });
  builder.addCase(getFaqs.fulfilled, (state, action) => {
    state.fetchingStatus = FetchingStatus.FULFILLED;
    state.error = null;
    state.data = action.payload;
  });

  builder.addCase(getFaqs.rejected, (state, action) => {
    state.fetchingStatus = FetchingStatus.REJECTED;
    state.error = action.error;
  });
};
