import { styled } from '@mui/material';

export const BreadcrumbContainer = styled('div')(({ theme }) => ({
  gap: 6,
  marginBottom: 24,
  display: 'flex',
  alignItems: 'center',

  '.breadcrumb__item, .breadcrumb__item > a': {
    color: theme.palette.primary.main,
    textDecoration: 'none',

    '&.active': {
      color: theme.palette.neutralDark.contrastText,
    },
  },
}));
