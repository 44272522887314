import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ErrorIconContainer } from './ErrorIcon.styles';

const ErrorIcon = () => (
  <ErrorIconContainer>
    <ErrorOutlineIcon />
  </ErrorIconContainer>
);

export default ErrorIcon;
