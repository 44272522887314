import { startOfTomorrow } from 'date-fns';
import * as yup from 'yup';

export const DeactivateEmployeeSchema = yup
  .object({
    deactivationDate: yup
      .date()
      .typeError('form.employee.error.start_date.invalid')
      .min(startOfTomorrow(), 'form.employee.error.past_date')
      .nullable(),
  })
  .required();

  export default DeactivateEmployeeSchema;

  export const ReactivateEmployeeSchema = yup
  .object({
    reactivationDate: yup
      .date()
      .min(startOfTomorrow(), 'form.employee.error.past_date')
      .typeError('form.employee.error.end_date.invalid')
      .nullable(),
  })
  .required();