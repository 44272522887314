import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { FetchingStatus, SettingsState } from 'types';
import { getCompanyCustomField } from './getCompanyCustomField';

export const getCompanyCustomFieldReducer = (builder: ActionReducerMapBuilder<SettingsState>) => {
  builder.addCase(getCompanyCustomField.pending, (state, action) => {
    const companyId = action?.meta?.arg?.companyId;
    if (typeof companyId === 'number') {
      state.companyCustomField[companyId] = {
        data: null,
        fetchingStatus: FetchingStatus.PENDING,
      };
    }
  });
  builder.addCase(getCompanyCustomField.fulfilled, (state, action) => {
    const companyId = action?.meta?.arg?.companyId;
    if (typeof companyId === 'number') {
      state.companyCustomField[companyId] = {
        data: action.payload,
        fetchingStatus: FetchingStatus.FULFILLED,
      };
    }
  });
  builder.addCase(getCompanyCustomField.rejected, (state, action) => {
    const companyId = action?.meta?.arg?.companyId;
    if (typeof companyId === 'number') {
      state.companyCustomField[companyId] = {
        data: null,
        fetchingStatus: FetchingStatus.REJECTED,
      };
    }
  });
};
