import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { FetchingStatus, S3CredentialsState } from 'types';
import { getS3CredentialsPartner } from './getS3CredentialsPartner';

export const getS3CredentialsPartnerReducer = (
  builder: ActionReducerMapBuilder<S3CredentialsState>,
) => {
  builder.addCase(getS3CredentialsPartner.pending, (state, action) => {
    state.api.getS3Credentials.fetchingStatus = FetchingStatus.PENDING;
    state.api.getS3Credentials.error = null;
  });
  builder.addCase(getS3CredentialsPartner.fulfilled, (state, action) => {
    state.api.getS3Credentials.fetchingStatus = FetchingStatus.FULFILLED;
    state.api.getS3Credentials.data = action.payload || null;
    state.api.getS3Credentials.error = null;
  });

  builder.addCase(getS3CredentialsPartner.rejected, (state, action) => {
    state.api.getS3Credentials.fetchingStatus = FetchingStatus.REJECTED;
    state.api.getS3Credentials.error = action.error;
  });
};
