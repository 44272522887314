import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToast } from 'utils';
import distinct from '@utils/distinct';
import { axiosWithConfig } from 'api';
import {
  UserManagementBaseResponse,
  GetCheckInsRequestParams,
  GetActiveMembersResponse,
} from 'types';

export const getActiveMembers = createAsyncThunk<
  GetActiveMembersResponse,
  GetCheckInsRequestParams
>('stats/getActiveMembers', async ({ companyId, startMonth, endMonth }, { rejectWithValue }) => {
  const endpoint = `${process.env.REACT_APP_STATISTICS_ENDPOINT}company/eu?companyId=${companyId}&status=E,A&startMonth=${startMonth}&endMonth=${endMonth}&explain=true`;
  const axios = await axiosWithConfig();

  try {
    const { data } = await axios.get(endpoint);

    const filteredData = distinct(data.data, 'customer_id');
    const activeMembersList = {
      activeMembersList: filteredData,
      explain: { ...data.explain, Count: filteredData.length },
    };

    return activeMembersList;
  } catch (e) {
    const error: AxiosError<UserManagementBaseResponse> =
      e as AxiosError<UserManagementBaseResponse>;
    showToast('error', {
      id: 'toast.active_members.get.error',
      defaultMessage: error.message,
    });
    return rejectWithValue(error.response?.data);
  }
});

export default getActiveMembers;
