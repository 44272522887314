import { startOfTomorrow } from 'date-fns';
import * as yup from 'yup';

export const PendingEmployeeSchema = yup
  .object({
    startDate: yup
      .date()
      .min(startOfTomorrow(), 'form.employee.error.past_date')
      .typeError('form.employee.error.start_date.invalid')
      .nullable()
      .required('form.employee.error.start_date.required'),
  })
  .required();

export default PendingEmployeeSchema;
