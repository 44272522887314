import { Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { NotificationSelectors } from 'features/shared/notification/store/notification.selectors';
import { useAppDispatch, useAppSelector } from 'store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SnackbarContainer, Message, SuccessIconContainer } from './Notification.styles';
import { NotificationActions } from 'features/shared/notification/store';
import DomPurify from 'dompurify';

const DURATION = 8000;

const NotificationManager = () => {
  const dispatch = useAppDispatch();
  const [id, setId] = useState<string>(Math.random().toString(36).slice(2, 7));
  const [open, setOpen] = useState<boolean>(false);
  const [duration, setDuration] = useState<number | null>(null);
  const notification = useAppSelector(NotificationSelectors.getNotification);

  const handleClose = useCallback(() => {
    dispatch(NotificationActions.removeNotification());
  }, [dispatch]);

  useEffect(() => {
    if (notification) {
      setId(Math.random().toString(36).slice(2, 7));
      setDuration(DURATION);
      setOpen(true);
    } else {
      setDuration(null);
      setOpen(false);
    }
  }, [notification]);

  const santinizedText = useMemo(
    () => DomPurify.sanitize(notification?.text || ''),
    [notification?.text],
  );

  if (!notification?.text) {
    return null;
  }

  return (
    <SnackbarContainer
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      onClose={handleClose}
      autoHideDuration={duration}
      message={
        <Message>
          <SuccessIconContainer>
            <CheckCircleIcon />
          </SuccessIconContainer>

          <Box dangerouslySetInnerHTML={{ __html: santinizedText }} />
        </Message>
      }
      key={id}
    />
  );
};

export default NotificationManager;
