import { styled, Box } from '@mui/material';

const MainCard = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: '16px 24px',
  borderRadius: '4px',
  background: theme.palette.background.paper,
  '.accordion': {
    boxShadow: 'none',
    '&.Mui-expanded .expand-icon': {
      transform: 'rotate(180deg)',
    },
    '.accordion-header': {
      padding: '0px',
    },
    '.MuiAccordionSummary-content': {
      margin: '0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '.MuiAccordionDetails-root': {
      padding: '0',
    },
  },
}));

export default MainCard;
