import { Breadcrumb } from '@components/Breadcrumb';
import { FormatDate } from '@components/FormatDate';
import Spacer from '@components/Spacer';
import GeneralSettings from '@features/settings/components/SelfRegistration/GeneralSettings';
// import LandingSettings from '@features/settings/components/SelfRegistration/LandingSettings';
import RegistrationSettings from '@features/settings/components/SelfRegistration/RegistrationSettings';
import { SettingsActions } from '@features/settings/store';
import { useLanguage } from '@hooks/useLanguage';
import { Stack, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@store/index';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

const SignupCustomize: React.FC<{}> = props => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const language = useLanguage();

  const { lastUpdate } = useAppSelector(state => state.settings.selfRegistration);

  const breadcrumbRoutes = useMemo(
    () => [
      {
        url: `/${language}/settings/2`,
        text: formatMessage({
          id: 'navigation.settings.self_registration',
          defaultMessage: 'Employee self-registration',
        }),
      },
      {
        url: '',
        text: formatMessage({
          id: 'navigation.settings.self_registration.customize',
          defaultMessage: 'Self Sign-up Customization',
        }),
      },
    ],
    [formatMessage, language],
  );

  useEffect(() => {
    dispatch(SettingsActions.getRegistrationSettings({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Breadcrumb routes={breadcrumbRoutes} />
      <Stack flexDirection='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='h2'>
          {formatMessage({
            id: 'settings.self_registration.customize.title',
            defaultMessage: 'Self Sign-up Customisation',
          })}
        </Typography>
        <Typography variant='subtitle2'>
          {formatMessage(
            {
              id: 'settings.self_registration.customize.general.modified_date',
              defaultMessage: 'Last updated: {date}',
            },
            {
              date: FormatDate(lastUpdate, new Date(lastUpdate).getTime()),
            },
          )}
        </Typography>
      </Stack>
      <Spacer size='lg' />
      <GeneralSettings />
      {/* <Spacer size='lg' />
      <LandingSettings /> */}
      <Spacer size='lg' />
      <RegistrationSettings />
    </>
  );
};

export default SignupCustomize;
