import { CircularProgress } from '@mui/material';

import { TableLoaderWrapper } from './TableLoader.styles';

const TableLoader = () => {
  return (
    <TableLoaderWrapper>
      <CircularProgress size={80} />
    </TableLoaderWrapper>
  );
};

export default TableLoader;
