import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { useLanguage } from 'hooks';
import { default as theme } from '@theme/theme';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CCPDefaultButton from '@components/Button/Default';
import { useMemo } from 'react';

const GoToSettings: React.FC<{
  isOpen: boolean;
  isUserCreate?: boolean;
  isResendLetted?: boolean;
  isResendToEUWithEmptyEmail?: boolean;
  handleClose: () => void;
}> = ({
  isOpen,
  handleClose,
  isUserCreate = false,
  isResendLetted = false,
  isResendToEUWithEmptyEmail = false,
}) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const activeLanguage = useLanguage();

  const modalData = useMemo(() => {
    if (isUserCreate) {
      return {
        modalTitle: formatMessage({
          id: 'view.user_management.cant_create_user.title',
          defaultMessage: 'Not possible to create an EU',
        }),
        modalContent: formatMessage({
          id: 'view.user_management.cant_create_user.description',
          defaultMessage:
            'Activation letter should be send to company user but company users list is empty',
        }),
        secondaryCta: formatMessage({
          id: 'view.user_management.cant_create_user.cta.cancel',
          defaultMessage: 'Cancel',
        }),
        primaryCta: formatMessage({
          id: 'view.user_management.cant_create_user.cta.approve',
          defaultMessage: 'Open Settings',
        }),
      };
    }
    if (isResendLetted) {
      return {
        modalTitle: formatMessage({
          id: 'view.employee_management.cant_resend_letter.title',
          defaultMessage: 'Not possible to resend the activation letter',
        }),
        modalContent: formatMessage({
          id: 'view.employee_management.cant_resend_letter.description',
          defaultMessage:
            'Activation letter should be send to company user but company users list is empty',
        }),
        secondaryCta: formatMessage({
          id: 'view.employee_management.cant_resend_letter.cta.cancel',
          defaultMessage: 'Cancel',
        }),
        primaryCta: formatMessage({
          id: 'view.employee_management.cant_resend_letter.cta.approve',
          defaultMessage: 'Open Settings',
        }),
      };
    }
    if (isResendToEUWithEmptyEmail) {
      return {
        modalTitle: formatMessage({
          id: 'view.employee_management.cant_resend_letter_to_eu.title',
          defaultMessage: 'Not possible to resend the activation letter',
        }),
        modalContent: formatMessage({
          id: 'view.employee_management.cant_resend_letter_to_eu.description',
          defaultMessage:
            'Activation letter should be send to employee, but the employee does not have an email address. Add email address to this employee or change the activation letter recipients settings.',
        }),
        secondaryCta: formatMessage({
          id: 'view.employee_management.cant_resend_letter_to_eu.cta.cancel',
          defaultMessage: 'Cancel',
        }),
        primaryCta: formatMessage({
          id: 'view.employee_management.cant_resend_letter_to_eu.cta.approve',
          defaultMessage: 'Open Settings',
        }),
      };
    }
    return {
      modalTitle: formatMessage({
        id: 'settings.cant_change_self_signup.title',
        defaultMessage: 'Signup page cannot be set online',
      }),
      modalContent: formatMessage({
        id: 'settings.cant_change_self_signup.description',
        defaultMessage:
          'You need to fill the company users list or select “Send activation letter to EU”',
      }),
      secondaryCta: formatMessage({
        id: 'settings.cant_change_self_signup.cta.cancel',
        defaultMessage: 'Cancel',
      }),
      primaryCta: formatMessage({
        id: 'settings.cant_change_self_signup.cta.approve',
        defaultMessage: 'Open Settings',
      }),
    };
  }, [isUserCreate, isResendLetted, isResendToEUWithEmptyEmail, formatMessage]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <CloseIcon onClick={handleClose} className='dialog-close' />
      <DialogTitle>
        <Box className='dialog-icon'>
          <ErrorOutlineIcon htmlColor={theme.palette.error.main} />
        </Box>
        <Typography variant='h2'>{modalData.modalTitle}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1'>{modalData.modalContent}</Typography>
      </DialogContent>
      <DialogActions>
        <CCPDefaultButton onClick={handleClose} variant='outlined'>
          {modalData.secondaryCta}
        </CCPDefaultButton>
        <CCPDefaultButton
          onClick={() => navigate(`/${activeLanguage}/settings/1`)}
          variant='contained'
          color='error'>
          {modalData.primaryCta}
        </CCPDefaultButton>
      </DialogActions>
    </Dialog>
  );
};

export default GoToSettings;
