import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { FetchingStatus, SettingsState } from 'types';
import { updateCompanyCustomField } from './updateCompanyCustomField';

export const updateCompanyCustomFieldExtraReducer = (
  builder: ActionReducerMapBuilder<SettingsState>,
) => {
  builder.addCase(updateCompanyCustomField.fulfilled, (state, action) => {
    const companyId = action?.meta?.arg?.companyId;
    if (typeof companyId === 'number') {
      const oldData = [...(state.companyCustomField[companyId].data || [])];
      const updateIndex = oldData.findIndex(item => item.key === action.payload.key);
      if (updateIndex !== -1) {
        state.companyCustomField[companyId].fetchingStatus = FetchingStatus.FULFILLED;
        state.companyCustomField[companyId].data?.splice(updateIndex, 1, action.payload);
      }
      oldData.push(action.payload);
    }
  });
};
