import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { FetchingStatus, SettingsState } from 'types';
import { setRegistrationIdentifier } from './setRegistrationIdentifier';

export const setRegistrationIdentifierExtraReducer = (
  builder: ActionReducerMapBuilder<SettingsState>,
) => {
  builder.addCase(setRegistrationIdentifier.pending, (state, action) => {
    state.selfRegistrationFetching = FetchingStatus.PENDING;
    state.selfRegistrationError = null;
  });
  builder.addCase(setRegistrationIdentifier.fulfilled, (state, action) => {
    state.selfRegistrationFetching = FetchingStatus.FULFILLED;
    state.defaultSelfRegistration = state.selfRegistration;
  });

  builder.addCase(setRegistrationIdentifier.rejected, (state, action) => {
    state.selfRegistrationFetching = FetchingStatus.REJECTED;
    state.selfRegistrationError = action.error;
  });
};
