import { CheckInsTableProps, CouponTableProps } from '../types';

export const getStatisticCount = ({ data, type }: { data: any; type: string }) => {
  if (type === 'activeMembers') return data?.explain?.Count || 0;

  if (type === 'checkIns')
    return (data?.checkInsList || []).reduce(
      (count: number, currentCheckin: CheckInsTableProps) =>
        count + (currentCheckin?.checkins || 0),
      0,
    );

  if (type === 'coupons')
    return (data?.couponsList || []).reduce(
      (count: number, currentCoupon: CouponTableProps) => count + (currentCoupon?.coupons || 0),
      0,
    );

  return 0;
};
