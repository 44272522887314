import FeedIcon from '@mui/icons-material/Feed';
import CloseIcon from '@mui/icons-material/Close';

import { FileUploadIconContainer } from './FileUploadContainer.styles';
import { Box, Grid, LinearProgress } from '@mui/material';
import { useIntl } from 'react-intl';

export interface FileUploadContainerProps {
  file: Blob | null;
  filename: string;
  progress: number;
}

const FileUploadContainer: React.FC<FileUploadContainerProps> = ({ file, filename, progress }) => {
  const filesize = file?.size || 0;
  const intl = useIntl();

  return (
    <Box mt={12.5}>
      <Box display="flex" width={770} mx="auto" maxWidth="80%">
        <Box width={64} mr={2}>
          <FileUploadIconContainer>
            <FeedIcon />
            <br />
            <span>
              {intl.formatMessage({
                id: 'employee_management.bulk_upload.uploading.file.csv',
                defaultMessage: 'csv.',
              })}
            </span>
          </FileUploadIconContainer>
        </Box>
        <Box width="calc(100% - 80px)">
          <Grid container>
            <Grid item xs={11} md={11}>
              <div style={{ textAlign: 'left', fontWeight: 'bold' }}>{filename}</div>
            </Grid>
            <Grid item xs={1} md={1}>
              <CloseIcon />
            </Grid>
            <Grid item xs={11} md={11}>
              <p style={{ textAlign: 'left' }}>
                {((progress * filesize) / 100).toFixed(0)}{' '}
                {intl.formatMessage({
                  id: 'employee_management.bulk_upload.uploading.from',
                  defaultMessage: 'from',
                })}{' '}
                {filesize}{' '}
                {intl.formatMessage({
                  id: 'employee_management.bulk_upload.uploading.file.size',
                  defaultMessage: 'KB uploaded',
                })}
              </p>
            </Grid>
            <Grid item xs={1} md={1}>
              {progress}%
            </Grid>
            <Grid item xs={12} md={12}>
              <LinearProgress variant="determinate" value={progress} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default FileUploadContainer;
