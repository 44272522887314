import { Box, StepIconProps } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import CheckIcon from '../../assets/icons/atoms-symbols-icons-check.svg';
import { StepIconContainer } from './StepIcon.styles';

export interface SelfRegistrationStepIconProps extends StepIconProps {
  stepNumber: number;
  label: string;
  defaultMessage?: string;
}

const StepIcon = ({
  active,
  completed,
  stepNumber,
  label,
  defaultMessage,
}: SelfRegistrationStepIconProps) => (
  <StepIconContainer
    className={clsx({
      active,
      completed,
    })}>
    {completed && <img src={CheckIcon} alt='' className='completedIcon' />}
    {!completed && <Box className='stepNumber'>{stepNumber}</Box>}
    <Box>
      <FormattedMessage id={label} defaultMessage={defaultMessage} />
    </Box>
  </StepIconContainer>
);
export default StepIcon;
