import { actions as CompanySyncActions } from './company.slice';
import { getCompany } from './actions/async/getCompany/getCompany';
import { getCompaniesNames } from './actions/async/getCompaniesNames/getCompaniesNames';
import { getCountriesList } from './actions/async/getCountriesList/getCountriesList';

export const CompanyActions = {
  ...CompanySyncActions,
  getCompany,
  getCompaniesNames,
  getCountriesList,
};
