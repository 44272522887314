import { Link, styled } from '@mui/material';

const CCPLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
  fontFamily: 'Roboto-Medium',
  fontSize: 16,
  fontWeight: 500,
}));

export default CCPLink;
