import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToast } from 'utils';
import { axiosWithConfig } from 'api';
import { ISelfRegistrationUser, RootState, BaseErrorResponse } from 'types';

export const getRegistrationUsers = createAsyncThunk<any, { companyId?: number }>(
  'settings/getRegistrationUsers',
  async ({ companyId }, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    companyId = companyId || state.company.activeCompany?.companyId;
    const endpoint = `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/companies/sp-token/${companyId}`;

    const axios = await axiosWithConfig();

    try {
      const { data } = await axios.get(endpoint);

      return data as ISelfRegistrationUser;
    } catch (e) {
      const error: AxiosError<BaseErrorResponse> = e as AxiosError<BaseErrorResponse>;
      showToast('error', {
        id: 'toast.get_registration_users.error',
        defaultMessage: error.message,
      });
      return rejectWithValue(error.response?.data);
    }
  },
);
