import { styled, Typography } from '@mui/material';

export const ResendCodeLink = styled(Typography)(({ theme }) => ({
  gap: 6,
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: '32px',
  div: {
    fontFamily: 'Roboto-Medium',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));
export const CodeWrapper = styled(Typography)(({ theme }) => ({
  '.verify__code': {
    gap: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&_item': {
      width: '40px !important',
      height: 56,

      fontSize: 16,
      lineHeight: 1.38,
      color: theme.palette.neutralDark.contrastText,
      border: `1px solid  ${theme.palette.neutralLight.dark}`,
      userSelect: 'none',
      borderRadius: 4,

      '&_active': {
        border: `1px solid ${theme.palette.success.main}`,
        transition: 'border 0.4s ease',
      },

      '&:focus': {
        outline: 'none',
      },
    },
  },
}));
