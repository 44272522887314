import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { FetchingStatus, SettingsState } from 'types';
import { getSettings } from './getSettings';

export const getSettingsReducer = (builder: ActionReducerMapBuilder<SettingsState>) => {
  builder.addCase(getSettings.pending, (state, action) => {
    state.settings.fetchingStatus = FetchingStatus.PENDING;
  });
  builder.addCase(getSettings.fulfilled, (state, action) => {
    state.settings = {
      fetchingStatus: FetchingStatus.FULFILLED,
      ...action.payload,
    };
  });

  builder.addCase(getSettings.rejected, state => {
    state.settings.fetchingStatus = FetchingStatus.REJECTED;
  });
};
