import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { FetchingStatus } from 'types';

import { StatsState } from '../../../interfaces';
import { getStats } from './getStats';

export const getStatsExtraReducer = (builder: ActionReducerMapBuilder<StatsState>) => {
  builder.addCase(getStats.pending, (state, action) => {
    state.statistics.error = null;
    state.statistics.fetchingStatus = FetchingStatus.PENDING;
  });
  builder.addCase(getStats.fulfilled, (state, action) => {
    state.statistics.fetchingStatus = FetchingStatus.FULFILLED;
    state.statistics.error = null;
    state.statistics.data = { ...state.statistics.data, ...action.payload };
  });

  builder.addCase(getStats.rejected, (state, action) => {
    state.statistics.fetchingStatus = FetchingStatus.REJECTED;
    state.statistics.error = action.error;
  });
};
