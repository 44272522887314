import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToast } from 'utils';
import { axiosWithConfig } from 'api';
import { RootState, BaseErrorResponse } from 'types';

const updateGeneralSettings = createAsyncThunk<
  any,
  {
    signupPageColor: string;
  }
>('settings/updateGeneralSettings', async ({ signupPageColor }, { rejectWithValue, getState }) => {
  const state = getState() as RootState;
  const companyId = state.company.activeCompany?.companyId;
  const endpoint = `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/companies/sp-settings/${companyId}/general`;

  const axios = await axiosWithConfig();

  const patchBody = { signupPageColor };

  try {
    const { data } = await axios.patch(endpoint, patchBody, {
      headers: {
        'content-type': 'application/merge-patch+json',
      },
    });
    showToast('success', {
      id: 'toast.settings.data_saved.success',
      defaultMessage: 'Data was saved successfully',
    });
    return signupPageColor === '' ? { ...data, signupPageColor: '' } : data;
  } catch (e) {
    const error: AxiosError<BaseErrorResponse> = e as AxiosError<BaseErrorResponse>;
    showToast('error', {
      id: 'toast.registration_settings.set.error',
      defaultMessage: error.message,
    });
    return rejectWithValue(error.response?.data);
  }
});

export default updateGeneralSettings;
