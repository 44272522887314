import { styled, Typography } from '@mui/material';

export const EmployeeName = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontFamily: 'Roboto-Bold',
  '&:hover': {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));
