import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from 'features/selfRegistration/store/initialState';
import { AddPersonalInfoStep, SELF_REGISTRATION_STEPS, SelfRegistrationState } from 'types';

export const updatePersonalInfoStepData: CaseReducer<
  SelfRegistrationState,
  PayloadAction<AddPersonalInfoStep>
> = (state, { payload }) => {
  state.steps.addPersonalInfoStep = payload;
};

export const setStep: CaseReducer<SelfRegistrationState, PayloadAction<SELF_REGISTRATION_STEPS>> = (
  state,
  { payload },
) => {
  state.currentStep = payload;
};

export const resetProcess: CaseReducer<SelfRegistrationState> = state => {
  state.api = initialState.api;
  state.currentStep = initialState.currentStep;
  state.steps = initialState.steps;
};
