import { useEffect, useState } from 'react';
import { UseFormClearErrors, UseFormSetError, UseFormWatch } from 'react-hook-form/dist/types/form';
import { ViolationError } from 'types';

export interface UseAsyncViolationFeedbackProps {
  errors: ViolationError[];
  withMessage: boolean;
  setError: UseFormSetError<any>;
  watch: UseFormWatch<any>;
  clearErrors: UseFormClearErrors<any>;
  isSubmitting: boolean;
}

export const useAsyncViolationFeedback = ({
  errors,
  withMessage,
  setError,
  watch,
  clearErrors,
  isSubmitting,
}: UseAsyncViolationFeedbackProps) => {
  const [errorsCache, setErrorsCache] = useState<ViolationError[]>();

  useEffect(() => {
    if (errors.length > 0 && JSON.stringify(errorsCache) !== JSON.stringify(errors)) {
      errors.forEach((error, idx) => {
        setError(
          error.property,
          {
            type: 'violation',
            message: withMessage ? error.message : '',
          },
          { shouldFocus: idx === 0 },
        );
      });
      setErrorsCache(errors);
    }
  }, [errorsCache, withMessage, setError, errors]);

  useEffect(() => {
    if (isSubmitting) {
      setErrorsCache([]);
    }
  }, [isSubmitting]);

  useEffect(() => {
    const subscription = watch((form, field) => {
      const error = errors.find(error => error.property === field.name);
      if (field.name === error?.property && field.type === 'change') {
        clearErrors(field.name);
      }
    });

    return () => subscription.unsubscribe();
  }, [clearErrors, withMessage, setError, errors, watch]);
};
