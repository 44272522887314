import { useIntl } from 'react-intl';
import Loader from '@components/Loader';
import { useForm } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { UserAccountPasswordProps } from 'types';
import { ChangePasswordSchema } from 'shared/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { useChangePassword } from '@hooks/useChangePassword';
import { CCPDefaultButton, CCPPasswordTextField, Spacer } from 'components';
import { useEffect } from 'react';

const ChangePassword: React.FC = () => {
  const intl = useIntl();
  const { isLoading, updatePassword } = useChangePassword();

  const {
    watch,
    control,
    handleSubmit,
    formState: { isDirty, isValid },
    trigger,
  } = useForm<UserAccountPasswordProps>({
    defaultValues: { currentPassword: '', newPassword: '', confirmNewPassword: '' },
    mode: 'onChange',
    resolver: yupResolver(ChangePasswordSchema),
  });
  const { currentPassword, newPassword, confirmNewPassword } = watch();

  useEffect(() => {
    if (newPassword) trigger('confirmNewPassword');
  }, [newPassword, trigger]);

  const onSubmit = (data: UserAccountPasswordProps) => {
    updatePassword(data);
  };

  const isDisabled =
    currentPassword === newPassword || newPassword !== confirmNewPassword || !isDirty || !isValid;

  return (
    <Box
      id='updatePasswordWrapper'
      className='content__form_wrapper'
      component='form'
      onSubmit={handleSubmit(onSubmit)}>
      <Loader show={isLoading} inCard portalId='updatePasswordWrapper' />
      <Box>
        <Typography variant='h6'>
          {intl.formatMessage({ id: 'account.tab.profile_password', defaultMessage: 'password' })}
        </Typography>
        <Spacer size='md' />
        <Box className='content__form changePassword'>
          <CCPPasswordTextField
            required
            label={intl.formatMessage({
              id: 'current_password',
              defaultMessage: 'Current password',
            })}
            name='currentPassword'
            control={control}
          />
          <CCPPasswordTextField
            required
            label={intl.formatMessage({
              id: 'set_new_password',
              defaultMessage: 'Set new password',
            })}
            name='newPassword'
            control={control}
          />
          <CCPPasswordTextField
            required
            label={intl.formatMessage({
              id: 'confirm_new_password',
              defaultMessage: 'Confirm new password',
            })}
            name='confirmNewPassword'
            control={control}
          />
        </Box>
      </Box>
      <Box className='content__footer'>
        <Box />
        <CCPDefaultButton
          type='submit'
          disabled={isDisabled}
          variant='contained'
          data-test-id='saveNewPassword'>
          {intl.formatMessage({
            id: 'form.employee.cta.save_changes',
            defaultMessage: 'Save changes',
          })}
        </CCPDefaultButton>
      </Box>
    </Box>
  );
};

export default ChangePassword;
