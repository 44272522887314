import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store';
import { MarketingMaterialAction } from 'features/marketingMaterials/store';
import UploadDocumentModal from 'features/marketingMaterials/components/MarketingMaterialList/UploadDocumentModal';
import {
  MarketingLocalizedMaterial,
  MarketingMaterial,
  MarketingMaterialTable,
  S3Credentials,
  TranslationsKeys,
} from 'types';
import { supported_language } from '@utils/language';
import { S3CredentialsActions } from 'features/shared/s3Credentials/store';
import { useIntl } from 'react-intl';
import { getS3Credential } from 'utils/getS3Credential';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { useLanguage } from 'hooks';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { formatBytes } from '@utils/numberHelpers';
import getFileType from '@utils/getFileType';
import { FormatDate } from '@components/FormatDate';
import {
  CellContainer,
  MarketingMaterialButton,
  MarketingMaterialTypeBadge,
  StyleDescription,
  StyleFileType,
  StyleName,
} from '@features/marketingMaterials/components/MarketingMaterialList/TableWrapper/Table.styles';
import { Download } from '@mui/icons-material';
import S3 from 'aws-sdk/clients/s3';
import { S3CredentialsSelectors } from 'features/shared/s3Credentials/store/s3Credentials.selector';
import { showToast } from 'utils';
import { InvoicesTableWrapper } from '@features/invoices/pages/Invoices/InvoicesTable/InvoicesTable.styles';
import Spacer from '@components/Spacer';
import TableGrid from '@components/TableGrid';

const MarketingMaterialListPage = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadedFiles, setDownloadedFiles] = useState<Record<number, any>>({});
  const currentLanguage = useLanguage();
  const s3Data = useAppSelector(S3CredentialsSelectors.getS3Credentials);
  const toggleUploadModal = () => {
    setIsOpenUploadModal(!isOpenUploadModal);
  };
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);
  const { getCompany } = useAppSelector(CompanySelectors.getApi);

  const companyData = getCompany.data;
  const communicationLanguage =
    currentLanguage ||
    'en' ||
    (companyData ? (companyData.communicationLanguage.toLowerCase() as TranslationsKeys) : 'en');

  const assetDefaultLanguage = currentLanguage || 'en' || communicationLanguage;

  const companyId = activeCompany?.companyId || 0;

  const [marketingMaterialList, setMarketingMaterialList] = useState<
    MarketingMaterialTable[] | null
  >(null);

  const checkCorrectFile = useCallback((itemUri: string, s3Key: string | undefined) => {
    if (s3Key) {
      const keyAsUri = fixedEncodeURI(s3Key),
        startPosFoundSubstr = itemUri.indexOf(keyAsUri);
      if (startPosFoundSubstr !== -1 && startPosFoundSubstr + keyAsUri.length === itemUri.length)
        return true;
    }
    return false;
  }, []);

  const getAvailableAssetLanguage = (
    localizedFiles: MarketingLocalizedMaterial | MarketingMaterial['localizedFields'],
    currentLanguage: TranslationsKeys,
  ) => {
    if (localizedFiles) {
      if (localizedFiles[currentLanguage]) return currentLanguage;
      if (localizedFiles['en']) return 'en';
      return 'de';
    }
    return 'de';
  };

  const getS3MarketingMaterial = useCallback(
    async (
      dataMaterial: MarketingMaterialTable[],
      companyId: number,
      language: TranslationsKeys,
    ) => {
      try {
        const s3Response = await dispatch(
          S3CredentialsActions.getS3CredentialsCompany({
            companyId,
          }),
        ).unwrap();
        if (!s3Response) {
          return;
        }
        const s3Material = getS3Credential({
          data: s3Response,
          type: `${companyId}/MarketingMaterial`,
        });

        const s3GeneralMaterial = getS3Credential({
          data: s3Response,
          type: 'Company/MarketingMaterial',
        });

        if (!s3Material || !s3GeneralMaterial) {
          return;
        }

        const bucket = s3Material.s3Url?.split('/')[2];

        const s3 = new S3({
          region: 'eu-central-1',
          accessKeyId: s3Material.AccessKeyId,
          secretAccessKey: s3Material.SecretAccessKey,
          sessionToken: s3Material.SessionToken,
        });

        const s3General = new S3({
          region: 'eu-central-1',
          accessKeyId: s3GeneralMaterial.AccessKeyId,
          secretAccessKey: s3GeneralMaterial.SecretAccessKey,
          sessionToken: s3GeneralMaterial.SessionToken,
        });

        const params = {
          Bucket: bucket,
          Prefix: `assets/Company/${companyId}/MarketingMaterial/`,
        };

        const generalParams = {
          Bucket: bucket,
          Prefix: `assets/Company/MarketingMaterial/`,
        };
        const companyMaterials = await s3.listObjectsV2(params).promise();
        const generalMaterials = await s3General.listObjectsV2(generalParams).promise();

        const allData = dataMaterial.map(item => {
          const availableLanguage = item.localizedFields[language]
            ? language
            : item.localizedFields['en']
            ? 'en'
            : 'de';

          if (!item.uri) {
            const fileMaterials = item.localizedFields[availableLanguage].uri.includes(
              `assets/Company/${companyId}/MarketingMaterial/`,
            )
              ? companyMaterials.Contents
              : generalMaterials.Contents;
            const checkFile =
              (fileMaterials || []).find(dataS3 =>
                checkCorrectFile(item.localizedFields[availableLanguage].uri, dataS3.Key),
              ) || {};
            const localizedFiles = {} as any;
            for (let i = 0; i < supported_language.length; i++) {
              if (item.localizedFields[supported_language[i]]) {
                localizedFiles[supported_language[i]] =
                  (fileMaterials || []).find(dataS3 =>
                    checkCorrectFile(item.localizedFields[supported_language[i]].uri, dataS3.Key),
                  ) || {};
              }
            }

            return { ...item, ...checkFile, localizedFiles };
          } else {
            const fileMaterials = item.uri.includes(
              `assets/Company/${companyId}/MarketingMaterial/`,
            )
              ? companyMaterials.Contents
              : generalMaterials.Contents;

            const checkFile =
              (fileMaterials || []).find(dataS3 => checkCorrectFile(item.uri, dataS3.Key)) || {};

            return { ...item, ...checkFile };
          }
        });

        setMarketingMaterialList(allData as MarketingMaterialTable[]);
      } catch (error) {
        console.log(error);
      }
    },
    [checkCorrectFile, dispatch],
  );

  const getInitialData = useCallback(
    async (companyId: number) => {
      try {
        setIsLoading(true);
        const dataResponse = await dispatch(
          MarketingMaterialAction.getMarketingMaterials({ companyId }),
        ).unwrap();
        if (!dataResponse.categoryMaterials) {
          return;
        }
        if (dataResponse.categoryMaterials) {
          const data: MarketingMaterialTable[] = [];
          dataResponse.categoryMaterials.forEach(item => {
            item?.marketingMaterials?.forEach(material => {
              data.push({ ...material, categoryName: item.categoryName });
            });
          });
          await getS3MarketingMaterial(data, companyId, assetDefaultLanguage);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, getS3MarketingMaterial, assetDefaultLanguage],
  );

  const fixedEncodeURI = (str: string) =>
    encodeURI(str).replace(/[!'()*@$&,;=]/g, function (c) {
      return '%' + c.charCodeAt(0).toString(16).toUpperCase();
    });

  const handleDownloadFileFromS3 = async ({
    localizedKey,
    s3Data,
  }: {
    localizedKey: string;
    s3Data: {
      AccessKeyId: string;
      SessionToken: string;
      SecretAccessKey: string;
      s3Url: string;
    };
  }) => {
    const bucket = s3Data.s3Url?.split('/')[2];
    const s3 = new S3({
      accessKeyId: s3Data.AccessKeyId,
      secretAccessKey: s3Data.SecretAccessKey,
      region: 'eu-central-1',
      sessionToken: s3Data.SessionToken,
    });
    const params = {
      Bucket: bucket,
      Key: localizedKey,
    };

    return await s3.getObject(params).promise();
  };

  const handleDownloadFile = (file: any, fileName: string) => {
    if (!file) {
      showToast('error', 'File error', 3000);
      return;
    }
    const fileBlob = new Blob([file?.Body], {
      type: file.ContentType,
    });
    const url = window.URL.createObjectURL(fileBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  const handleActionFile = useCallback(
    async (localizedFileKey: string, index: number) => {
      if (!localizedFileKey) {
        return;
      }

      // @todo What's the purpose of this?
      const newDownloadedFiles = { ...downloadedFiles };
      let fileDownload = newDownloadedFiles[index];
      let s3Credential = s3Data.data as S3Credentials;

      if (!fileDownload && s3Data.expiration && companyId) {
        const today = new Date().getTime() / 1000;
        if (s3Data.expiration < today - 60) {
          s3Credential = await dispatch(
            S3CredentialsActions.getS3CredentialsCompany({
              companyId,
            }),
          ).unwrap();
        }
        const s3CompanyMaterial = getS3Credential({
          data: s3Credential,
          type: `Company/${companyId}/MarketingMaterial`,
        });
        const s3GeneralMaterial = getS3Credential({
          data: s3Credential,
          type: 'Company/MarketingMaterial',
        });
        if (!s3CompanyMaterial || !s3GeneralMaterial) {
          return;
        }

        if (localizedFileKey.includes('assets/Company/MarketingMaterial')) {
          fileDownload = await handleDownloadFileFromS3({
            localizedKey: localizedFileKey,
            s3Data: s3GeneralMaterial,
          });
        }
        if (localizedFileKey.includes(`Company/${companyId}/MarketingMaterial`)) {
          fileDownload = await handleDownloadFileFromS3({
            localizedKey: localizedFileKey,
            s3Data: s3CompanyMaterial,
          });
        }
      }
      if (!fileDownload) {
        return;
      }

      setDownloadedFiles(newDownloadedFiles);
      handleDownloadFile(
        fileDownload,
        localizedFileKey.substring(localizedFileKey.lastIndexOf('/') + 1),
      );
    },
    [dispatch, downloadedFiles, s3Data, companyId],
  );

  useEffect(() => {
    if (companyId) {
      getInitialData(companyId);
    }
  }, [companyId, getInitialData]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: formatMessage({
          id: 'marketing_material.view.table.header.file_info',
          defaultMessage: 'File info',
        }),
        width: 350,
        minWidth: 250,
        maxWidth: 400,
        field: 'originalFilename',
        searchable: true,
        renderCell: (
          params: GridRenderCellParams<
            MarketingMaterialTable,
            MarketingMaterialTable['originalFilename']
          >,
        ) => (
          <CellContainer>
            <StyleName title={params.value}>{params.value}</StyleName>
            <StyleDescription>
              {params.row.localizedFields[
                getAvailableAssetLanguage(
                  params.row.localizedFields,
                  currentLanguage as TranslationsKeys,
                )
              ]?.description || ''}
            </StyleDescription>
          </CellContainer>
        ),
        valueGetter: (value, row) =>
          row.localizedFields[getAvailableAssetLanguage(row.localizedFields, currentLanguage)]
            .originalFilename || value,
      },
      {
        headerName: formatMessage({
          id: 'marketing_material.view.table.header.type',
          defaultMessage: 'Type',
        }),
        width: 300,
        minWidth: 250,
        maxWidth: 350,
        field: 'Key',
        searchable: true,
        renderCell: (
          params: GridRenderCellParams<MarketingMaterialTable, MarketingMaterialTable['Key']>,
        ) => {
          const size = formatBytes(params.row.Size || 0);
          const date = params.row?.LastModified ? FormatDate(params.row.LastModified) : '';

          return (
            <CellContainer>
              <StyleFileType>{params.value}</StyleFileType>
              <StyleDescription>
                {size},{' '}
                {formatMessage({
                  id: 'marketing_material.view.table.column.type.upload',
                  defaultMessage: 'Uploaded on',
                })}{' '}
                {date}
              </StyleDescription>
            </CellContainer>
          );
        },
        valueGetter: (_, row) => {
          const fileType =
            row?.Key?.split('.')?.pop()?.toLowerCase() ||
            row?.originalFilename?.split('.')?.pop()?.toLowerCase() ||
            '';
          const type = getFileType(fileType);
          return type;
        },
      },
      {
        headerName: formatMessage({
          id: 'marketing_material.view.table.header.category',
          defaultMessage: 'Category',
        }),
        width: 250,
        minWidth: 200,
        maxWidth: 300,
        field: 'categoryName',
        searchable: true,
        renderCell: (params: GridRenderCellParams) => (
          <CellContainer>
            <MarketingMaterialTypeBadge>{params.value}</MarketingMaterialTypeBadge>
          </CellContainer>
        ),
      },
      {
        headerName: formatMessage({
          id: 'marketing_material.view.table.header.available_languages',
          defaultMessage: 'Available languages',
        }),
        field: 'localizedFiles',
        width: 300,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params: GridRenderCellParams) => {
          if (!params.value) {
            return (
              <MarketingMaterialButton
                variant='text'
                startIcon={<Download />}
                onClick={() => handleActionFile(params.row.Key, 0)}>
                {formatMessage({
                  id: `marketing_material.view.table.column.action.download`,
                  defaultMessage: 'Download',
                })}
              </MarketingMaterialButton>
            );
          }

          return Object.keys(params.value).map((file, index) => {
            const translationLanguage = params.value?.[file as TranslationsKeys];

            if (!Object.keys(translationLanguage).length) {
              return null;
            }

            return (
              <MarketingMaterialButton
                key={file}
                variant='text'
                startIcon={<Download />}
                onClick={() => handleActionFile(translationLanguage.Key || '', index)}>
                {formatMessage({
                  id: `marketing_material.view.table.column.action.download.${file}`,
                  defaultMessage: file?.toUpperCase(),
                })}
              </MarketingMaterialButton>
            );
          });
        },
      },
    ],
    [currentLanguage, formatMessage, handleActionFile],
  );

  return (
    <Box>
      <Typography variant='h2'>
        {formatMessage({
          id: 'marketing_material.view.title',
          defaultMessage: 'Marketing materials',
        })}
      </Typography>
      <Spacer size='lg' />
      <Box>
        <InvoicesTableWrapper>
          <TableGrid
            tableName='marketingMaterials'
            isLoading={isLoading}
            getRowId={row => row.Key || row.id}
            rowsData={marketingMaterialList || []}
            columns={columns}
            hideFooter
            selectable={() => false}
          />
        </InvoicesTableWrapper>
      </Box>

      {isOpenUploadModal && (
        <UploadDocumentModal open={isOpenUploadModal} onClose={toggleUploadModal} />
      )}
    </Box>
  );
};

export default MarketingMaterialListPage;
