import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { EmployeeManagementState, FetchingStatus } from 'types';
import { cancelImportProgress } from './cancelImportProgress';

export const cancelImportProgressReducer = (
  builder: ActionReducerMapBuilder<EmployeeManagementState>,
) => {
  builder.addCase(cancelImportProgress.pending, (state, action) => {
    state.api.cancelImportProgress.fetchingStatus = FetchingStatus.PENDING;
    state.api.cancelImportProgress.error = null;
  });
  builder.addCase(cancelImportProgress.fulfilled, (state, action) => {
    state.api.cancelImportProgress.fetchingStatus = FetchingStatus.FULFILLED;
    state.api.cancelImportProgress.data = action.payload || null;
    state.api.cancelImportProgress.error = null;
  });

  builder.addCase(cancelImportProgress.rejected, (state, action) => {
    state.api.cancelImportProgress.fetchingStatus = FetchingStatus.REJECTED;
    state.api.cancelImportProgress.error = action.error;
  });
};
