import { actions as AuthenticationSyncActions } from './authentication.slice';
import signIn from './actions/async/signIn/signIn';
import signOut from './actions/async/signOut/signOut';
import { getUserInfo } from './actions/async/getUserInfo/getUserInfo';
import { refreshSession } from './actions/async/refreshSession/refreshSession';
import { forgotPassword } from './actions/async/forgotPassword/forgotPassword';
import { forgotPasswordSubmit } from './actions/async/forgotPasswordSubmit/forgotPasswordSubmit';

export const AuthenticationActions = {
  ...AuthenticationSyncActions,
  signIn,
  signOut,
  getUserInfo,
  refreshSession,
  forgotPassword,
  forgotPasswordSubmit,
};
