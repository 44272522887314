import React from 'react';
import { isArray } from 'lodash';
import StarsIcon from '@mui/icons-material/Stars';
import { default as theme } from '@theme/theme';

import { usePermissionsSettingsList } from 'utils';
import { CompanyRole, PermissionsChipGroupProps } from 'types';

import { PermissionsChip } from './PermissionsChipGroup.styles';

const PermissionsChipGroup: React.FC<PermissionsChipGroupProps> = ({
  role,
  customChipList = null,
}) => {
  const defaultPermissionsSettingsList = usePermissionsSettingsList();
  customChipList = customChipList ? customChipList : defaultPermissionsSettingsList;
  const isArrayRoles = isArray(role);
  const filterPermissionsName = (permission?: string): string => {
    if (customChipList) {
      const filterPermission = customChipList.find(item => item.name === permission);

      return filterPermission ? filterPermission.labelTitle : '';
    }
    return '';
  };

  if (isArrayRoles) {
    return (
      <>
        {role.map(item => {
          const labelText = filterPermissionsName(item);
          return labelText ? (
            <PermissionsChip
              icon={
                item === CompanyRole.Admin ? (
                  <StarsIcon fontSize='small' htmlColor={theme.palette.neutralDark.main} />
                ) : undefined
              }
              size='small'
              variant='outlined'
              key={item}
              label={filterPermissionsName(item)}
              className='permissions__list_item'
            />
          ) : null;
        })}
      </>
    );
  }

  return (
    <PermissionsChip
      icon={
        role === 'Admin' ? (
          <StarsIcon fontSize='small' htmlColor={theme.palette.neutralDark.main} />
        ) : undefined
      }
      size='small'
      variant='outlined'
      label={filterPermissionsName(role)}
      className='permissions__list_item'
    />
  );
};

export default PermissionsChipGroup;
