import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { ContractsState, FetchingStatus } from 'types';
import { getEUCondition } from './getEUCondition';

export const getEUConditionExtraReducer = (builder: ActionReducerMapBuilder<ContractsState>) => {
  builder.addCase(getEUCondition.pending, state => {
    if (!state.euCreationCondition) {
      state['euCreationCondition'] = {
        data: null,
        fetchingStatus: FetchingStatus.IDLE,
        error: null,
      };
    }
    state.euCreationCondition.fetchingStatus = FetchingStatus.PENDING;
    state.euCreationCondition.error = null;
  });

  builder.addCase(getEUCondition.fulfilled, (state, action) => {
    state.euCreationCondition.fetchingStatus = FetchingStatus.FULFILLED;
    state.euCreationCondition.data = action.payload;
  });

  builder.addCase(getEUCondition.rejected, (state, action) => {
    state.euCreationCondition.fetchingStatus = FetchingStatus.REJECTED;
    state.euCreationCondition.data = null;
    state.euCreationCondition.error = action.payload;
  });
};
