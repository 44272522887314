import { Box, styled } from '@mui/material';

export const ErrorIconContainer = styled(Box)(({ theme }) => ({
  width: 'fit-content',
  borderRadius: 32,
  svg: {
    padding: 1,
    height: 32,
    width: 32,
    fill: theme.palette.error.main,
    position: 'relative',
    top: 3,
  },
}));
