import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
  AddMarketingMaterialRequestBody,
  MarketingMaterialData,
  PimcoreErrorResponse,
} from 'types';
import { axiosWithConfig } from 'api';

export const addMarketingMaterial = createAsyncThunk<
  MarketingMaterialData,
  AddMarketingMaterialRequestBody,
  { rejectValue: PimcoreErrorResponse }
>('marketingMaterialsManagement/addMarketingMaterial', async (body, { rejectWithValue }) => {
  const { companyId } = body;
  const axios = await axiosWithConfig();
  const endpoint = process.env.REACT_APP_PIMCORE_ENDPOINT + `api/companies/${companyId}/assets`;
  const params = {
    language: body.language,
    description: body.description,
    filename: body.filename,
    originalFilename: body.originalFilename,
    categoryId: body.categoryId,
  };
  try {
    return (await axios.put(endpoint, params)).data;
  } catch (e) {
    const error: AxiosError<PimcoreErrorResponse> = e as AxiosError<PimcoreErrorResponse>;
    const { response } = error;
    const data = response?.data as PimcoreErrorResponse;

    return rejectWithValue(data);
  }
});
