import { CCPDefaultButton } from '@components/index';
import { useLanguage } from '@hooks/useLanguage';
import { CompanyCustomFieldType, TranslationsKeys } from '@localTypes/index';
import { Stack, TableCell, TableRow, Typography } from '@mui/material';
import { useInputType } from '@utils/customField';
import { useIntl } from 'react-intl';
import EditIcon from '@mui/icons-material/Edit';
import { useMemo } from 'react';

export const customFieldColumns = [
  {
    intlId: 'setting.custom_field.table.header.name',
    columnTitle: 'Custom field name',
    columnName: 'fieldName',
  },
  {
    intlId: 'setting.custom_field.table.header.type',
    columnTitle: 'Type',
    columnName: 'fieldType',
  },
  {
    intlId: 'setting.custom_field.table.header.mandatory',
    columnTitle: 'Mandatory',
    columnName: 'fieldIsMandatory',
  },
  {
    intlId: 'setting.custom_field.table.header.language',
    columnTitle: 'Language',
    columnName: 'languages',
  },
  { columnName: 'edit', columnTitle: '' },
];

const CustomFieldRow = ({
  customField,
  companyLanguage,
  handleEdit,
}: {
  customField: CompanyCustomFieldType;
  companyLanguage: TranslationsKeys;
  handleEdit: ({ data, type }: { data: CompanyCustomFieldType; type: 'add' | 'edit' }) => void;
}) => {
  const { formatMessage } = useIntl();
  const portalLanguage = useLanguage();
  const inputTypes = useInputType();
  const fallbackLanguage: TranslationsKeys = 'en';

  const customFieldValue = useMemo(() => {
    if (!customField.name) {
      return [];
    }
    return Object.keys(customField.name).filter(item => customField.name[item as TranslationsKeys]);
  }, [customField]);

  return (
    <TableRow key={customField.key}>
      <TableCell>
        <Typography variant='h3'>
          {customField.name[portalLanguage] ||
            customField.name[fallbackLanguage] ||
            customField.name[companyLanguage]}
        </Typography>
      </TableCell>
      <TableCell>{inputTypes.find(type => type.value === customField.inputType)?.title}</TableCell>
      <TableCell>
        {customField.mandatory === 'yes'
          ? formatMessage({
              id: 'setting.custom_field.table.cell.mandatory_yes',
              defaultMessage: 'Yes',
            })
          : formatMessage({
              id: 'setting.custom_field.table.cell.mandatory_no',
              defaultMessage: 'No',
            })}
      </TableCell>
      <TableCell>
        {customFieldValue.map((language, index) => (
          <span key={index} className='language'>
            {language}
            {index !== customFieldValue.length - 1 ? ', ' : ''}
          </span>
        ))}
      </TableCell>
      <TableCell>
        <Stack flexDirection='row' flexWrap='nowrap' justifyContent='flex-end'>
          <CCPDefaultButton
            variant='text'
            onClick={() => handleEdit({ data: customField, type: 'edit' })}
            startIcon={<EditIcon />}>
            {formatMessage({
              id: 'employee_management.bulk_upload.table.column.action.edit',
              defaultMessage: 'Edit',
            })}
          </CCPDefaultButton>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default CustomFieldRow;
