import { Box } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { InputTagWrapper } from './InputTag.styles';

export interface InputTagProps {
  input: string;
  index: number;
  removeTag: (domainIndex: number) => void;
}

const InputTag: React.FC<InputTagProps> = ({ input, removeTag, index }) => {
  return (
    <InputTagWrapper>
      <Box>{input}</Box>
      <CancelIcon fontSize="small" onClick={() => removeTag(index)}></CancelIcon>
    </InputTagWrapper>
  );
};

export default InputTag;
