import { styled, Box } from '@mui/material';

export const InvoicesWrapper = styled(Box)(({ theme }) => ({
  '.tabs': {
    gap: 24,
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 11,
    marginBottom: 5,
    borderBottom: `1px solid ${theme.palette.primaryLight.main}`,

    '&__item': {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.38,
      color: theme.palette.neutralDark.contrastText,
      cursor: 'pointer',
      position: 'relative',

      '&_active': {
        color: theme.palette.primary.main,
        fontWeight: 600,

        '&::after': {
          content: '""',
          width: '100%',
          height: 4,
          background: theme.palette.primary.main,
          position: 'absolute',
          left: 0,
          bottom: -11,
        },
      },
    },
  },
}));
