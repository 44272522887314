import { styled } from '@mui/material';

const StepperContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: 2,
  '.stepper': {
    backgroundColor: 'transparent',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 20,
  },
  '.step': {
    paddingLeft: 0,
    paddingRight: 0,
    display: 'table-cell',
    '& .MuiStepLabel-iconContainer': {
      display: 'initial',
      width: 'max-content',
      paddingRight: 5,
      paddingLeft: 5,
      zIndex: 2,
    },
  },
}));

export default StepperContainer;
