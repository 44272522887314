import { createSlice } from '@reduxjs/toolkit';
import { getContractsExtraReducer } from './actions/async/getContracts/getContracts.reducer';
import { getEUConditionExtraReducer } from './actions/async/getEUCondition/getEUCondition.reducer';
import { initialState } from './initialState';

const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {},
  extraReducers: builder => {
    getContractsExtraReducer(builder);
    // TODO: maybe we should extract this to a separate slice
    getEUConditionExtraReducer(builder);
  },
});

export const { name, actions } = contractsSlice;
export const contractsReducer = contractsSlice.reducer;
