import { Step, StepLabel, Stepper } from '@mui/material';
import { CCPStepConnector, StepIcon, StepperContainer } from 'components';

export interface InitialPasswordStepperProps {
  activeStep: number;
}

const InitialPasswordStepper = ({ activeStep }: InitialPasswordStepperProps) => {
  return (
    <StepperContainer>
      <Stepper
        alternativeLabel
        connector={<CCPStepConnector />}
        activeStep={activeStep}
        className={'stepper'}>
        <Step className={'step'}>
          <StepLabel
            StepIconComponent={props => (
              <StepIcon
                {...props}
                stepNumber={1}
                label={'default_password'}
                defaultMessage={'Default password'}
              />
            )}
          />
        </Step>
        <Step className={'step'}>
          <StepLabel
            StepIconComponent={props => (
              <StepIcon
                {...props}
                stepNumber={2}
                label={'set_new_password'}
                defaultMessage={'Set new password'}
              />
            )}
          />
        </Step>
        <Step className={'step'}>
          <StepLabel
            StepIconComponent={props => (
              <StepIcon {...props} stepNumber={3} label={'login'} defaultMessage={'Login'} />
            )}
          />
        </Step>
      </Stepper>
    </StepperContainer>
  );
};

export default InitialPasswordStepper;
