import React from 'react';
import { TwoRowsSideBarItemProps } from './interfaces';
import clsx from 'clsx';
import { Link, useParams } from 'react-router-dom';
import { TwoRowsSideBarItemStyled } from './TwoRowsSideBarItem.styles';
import { useLanguage } from 'hooks';

const TwoRowsSideBarItem: React.FC<TwoRowsSideBarItemProps> = ({ text, type, isActive }) => {
  const language = useLanguage();
  const { employeeId } = useParams<{ employeeId: string }>();

  return (
    <TwoRowsSideBarItemStyled
      className={clsx('wrapper__item', {
        wrapper__item_active: isActive,
      })}>
      <Link
        to={`/${language}/employee-management/${employeeId}/general-info/${type}`}
        className="wrapper__item_text">
        {text}
      </Link>
    </TwoRowsSideBarItemStyled>
  );
};

export default TwoRowsSideBarItem;
