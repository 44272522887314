import { useCookies } from 'react-cookie';
import { useParams } from 'react-router';
import { TranslationsKeys } from '@localTypes/user';

export const useLanguage = () => {
  const { language } = useParams<{ language: TranslationsKeys }>();
  const [cookies] = useCookies<'language_app', { language_app?: TranslationsKeys }>([
    'language_app',
  ]);

  let defaultLanguage: TranslationsKeys = 'de';
  if (cookies.language_app && ['en', 'de'].includes(cookies.language_app)) {
    defaultLanguage = cookies.language_app;
  }
  return (language?.toLowerCase() || defaultLanguage) as TranslationsKeys;
};
