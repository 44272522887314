import { Box, styled } from '@mui/material';

export const GlobalCustomFieldsWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridGap: 32,
  '.content': {
    gap: '30px 16px',
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));
