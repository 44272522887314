import { styled } from '@mui/material';

export const EmptyContractTable = styled('table')(({ theme }) => ({
  width: '100%',
  marginTop: '97px',
  marginBottom: '130px',
  borderSpacing: '6px',
  tableLayout: 'fixed',
  th: {
    fontSize: '12px',
    color: theme.palette.tableTemplate.fontColor,
    textTransform: 'uppercase',
    textAlign: 'left',
  },
  td: {
    height: '20px',
    margin: '6px 8px',
    backgroundColor: theme.palette.tableTemplate.backgroundColor,
  },
}));
