import { Controller, Control, Path, FieldValues } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import { DefaultTextField } from '../TextField/DefaultTextField.styles';
import { useIntl } from 'react-intl';
import { defaultTextfieldError } from '@utils/defaultErrorMessage';

interface CCPAutocompleteProps<
  O extends { id: string; label: string },
  TField extends FieldValues,
> {
  control: Control<TField>;
  name: Path<TField>;
  options: O[];
  label?: string;
  width?: number | string;
  disabled?: boolean;
  required?: boolean;
}

const CCPAutocomplete = <O extends { id: string; label: string }, TField extends FieldValues>(
  props: CCPAutocompleteProps<O, TField>,
) => {
  const { formatMessage } = useIntl();
  const { control, options, name, label, disabled, required = false, width } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const { onChange, value, ref } = field;
        return (
          <>
            <Autocomplete
              disabled={disabled}
              value={value ? options.find(option => value === option.id) ?? null : null}
              getOptionLabel={option => option.label}
              onChange={(_, newValue) => {
                onChange(newValue ? newValue.id : null);
              }}
              options={options}
              renderInput={params => (
                <DefaultTextField
                  {...params}
                  label={label ? `${label}${required ? '*' : ''}` : ''}
                  inputRef={ref}
                  error={!!error}
                  width={width}
                  data-test-id={`${name}Input`}
                  helperText={
                    error?.message
                      ? formatMessage({
                          id: error?.message,
                          defaultMessage: defaultTextfieldError[error.message] || error.message,
                        })
                      : undefined
                  }
                />
              )}
            />
          </>
        );
      }}
    />
  );
};

export default CCPAutocomplete;
