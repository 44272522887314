import { useIntl } from 'react-intl';
import { Header } from './GuestHeader.styles';

const GuestHeader = () => {
  const intl = useIntl();

  return (
    <>
      <Header variant={'body1'}>
        {intl.formatMessage({
          id: 'login_page.guest_header.top',
          defaultMessage: 'Hansefit',
        })}
      </Header>
      <Header variant={'body1'}>
        {intl.formatMessage({
          id: 'login_page.guest_header.bottom',
          defaultMessage: 'Corporate customer portal',
        })}
      </Header>
    </>
  );
};

export default GuestHeader;
