import { RootState } from 'types';

const materialList = (state: RootState) =>
  state.marketingMaterialsManagement.api.getMarketingMaterials.data;
const fileCategories = (state: RootState) =>
  state.marketingMaterialsManagement.api.getFileCategory.data;

export const MaterialsManagementSelectors = {
  materialList,
  fileCategories,
};
