import { FetchingStatus } from 'types';

import { SettingsState } from './interfaces';

export const initialState: SettingsState = {
  fetchingStatus: FetchingStatus.IDLE,
  data: null,
  error: null,
  translations: null,
  distributionLists: {
    CU_ADMIN: { fetchingStatus: FetchingStatus.IDLE, data: [], externalData: [], error: null },
    INVOICES: { fetchingStatus: FetchingStatus.IDLE, data: [], externalData: [], error: null },
    MARKETING_MATERIAL: {
      fetchingStatus: FetchingStatus.IDLE,
      data: [],
      externalData: [],
      error: null,
    },
    NEW_EU_REQUEST: {
      fetchingStatus: FetchingStatus.IDLE,
      data: [],
      externalData: [],
      error: null,
    },
    ACTIVATION_LETTER: {
      fetchingStatus: FetchingStatus.IDLE,
      data: [],
      externalData: [],
      error: null,
    },
  },
  selfRegistrationUsers: {
    fetchingStatus: FetchingStatus.IDLE,
    data: [],
    error: null,
    violationError: null,
  },
  updateRegistrationForm: {
    fetchingStatus: FetchingStatus.IDLE,
    data: { lastUpdated: '' },
    error: null,
  },
  updateGeneralSettings: {
    fetchingStatus: FetchingStatus.IDLE,
    data: { lastUpdated: '' },
    error: null,
  },
  updateLandingSettings: {
    fetchingStatus: FetchingStatus.IDLE,
    data: { lastUpdated: '' },
    error: null,
  },
  selfRegistrationError: null,
  selfRegistrationFetching: FetchingStatus.IDLE,
  selfRegistration: {
    lastUpdate: '',
    signupIdentifier: '',
    enable: false,
    autoConfirmSignup: true,
    allowSEPA: true,
    paymentProviders: ['sepa'],
    passwordRequired: false,
    signupPassword: '',
    showLandingPage: false,
    domainValidation: false,
    validEmailDomains: '',
    invitationRequired: false,
    welcomeText: '',
    signupPageHtmlContent: '',
    signupPageColor: '',
    signupPageEmail: '',
    signupPagePhone: '',
    directPay: false,
    isUrlPublished: false,
    landingPage: undefined,
    landingPageHeadline: '',
    name: '',
  },
  defaultSelfRegistration: {
    lastUpdate: '',
    signupIdentifier: '',
    enable: false,
    autoConfirmSignup: false,
    allowSEPA: true,
    paymentProviders: ['sepa'],
    passwordRequired: false,
    signupPassword: '',
    showLandingPage: false,
    domainValidation: false,
    validEmailDomains: '',
    invitationRequired: false,
    welcomeText: '',
    signupPageHtmlContent: '',
    signupPageColor: '',
    signupPageEmail: '',
    signupPagePhone: '',
    directPay: false,
    isUrlPublished: false,
    landingPage: undefined,
    landingPageHeadline: '',
    name: '',
  },
  ownUserDataFetchingStatus: FetchingStatus.IDLE,
  ownUserData: null,
  companyCustomField: {},
  settings: {
    fetchingStatus: FetchingStatus.IDLE,
    activationLetterRecipientMode: 'eu',
    invoiceRecipientMode: 'eu',
  },
  security: {
    mfa: {
      fetchingStatus: FetchingStatus.IDLE,
      data: { is2FAEnabled: false },
      error: null,
    },
  },
};
