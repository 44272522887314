import { ChangeEventHandler, FC, LegacyRef, useMemo, useState } from 'react';
import {
  InfoAlert,
  MenuITemSelect,
  RoundedCornerButton,
  RoundedCornerSelect,
} from './UploadTab.styles';
import { FileTemplate } from '../FileTemplate.styles';
import { UploadButtonContainer } from '../UploadButtonContainer.styles';
import { axiosWithConfig } from 'api';
import { AxiosError } from 'axios';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { useAppSelector } from 'store';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { useLanguage } from 'hooks';
import { default as theme } from '@theme/theme';
import DomPurify from 'dompurify';

interface UploadTabFromProps {
  fileInput: LegacyRef<HTMLInputElement>;
  handleUpload: ChangeEventHandler<HTMLInputElement>;
}

const UploadTabForm: FC<UploadTabFromProps> = ({ fileInput, handleUpload }) => {
  const { formatMessage } = useIntl();
  const { activeCompany, companiesNames } = useAppSelector(CompanySelectors.getCompanyState);
  const language = useLanguage();
  const santinizedText = useMemo(
    () =>
      DomPurify.sanitize(
        formatMessage({
          id: 'employee_management.bulk_upload.note.content.html',
          defaultMessage: `
                  <li>First name, surname, gender, birthday and start date are mandatory</li>
                  <li>Format for dates is DD.MM.YYYY</li>
                  <li>E-Mail is mandatory, if activation letter is send directly to employees</li>
                  <li>For gender, you can use "male", "female" or "no input", if you don't want to share this information</li>
                  <li>Phone number must start with the country code (+49 for Germany) and no other characters are allowed</li>
                  </>`,
        }),
      ),
    [formatMessage],
  );

  const templateHeaders = [
    formatMessage({
      id: 'employee_management.bulk_upload.table.header.first_name',
      defaultMessage: 'First name',
    }),
    formatMessage({
      id: 'employee_management.bulk_upload.table.header.last_name',
      defaultMessage: 'Last name',
    }),
    formatMessage({
      id: 'employee_management.bulk_upload.table.header.gender',
      defaultMessage: 'Gender',
    }),
    formatMessage({
      id: 'employee_management.bulk_upload.table.header.corporate_email',
      defaultMessage: 'Corporate email',
    }),
    formatMessage({
      id: 'employee_management.bulk_upload.table.header.birthday',
      defaultMessage: 'Birthday date',
    }),
    formatMessage({
      id: 'employee_management.bulk_upload.table.header.start',
      defaultMessage: 'Start date',
    }),
  ];

  const TemplateType = [
    {
      value: 'csv',
      title: formatMessage({
        id: 'employee_management.bulk_upload.cta.download.csv',
        defaultMessage: 'CSV file',
      }),
    },
    {
      value: 'xlsx',
      title: formatMessage({
        id: 'employee_management.bulk_upload.cta.download.xlsx',
        defaultMessage: 'XLSX file',
      }),
    },
  ];

  const [, setError] = useState<string>();
  const [, setStep] = useState('init-step');
  const [openSelect, setOpenSelect] = useState<boolean>(false);

  const onSelectClose = () => {
    setOpenSelect(false);
  };

  const onSelectOpen = () => {
    setOpenSelect(true);
  };

  const downloadTemplate = async (fileType: string) => {
    const endpoint =
      process.env.REACT_APP_PIMCORE_ENDPOINT +
      `api/companies/${
        activeCompany?.companyId || companiesNames.data[0].companyId
      }/endusers/bulk-template`;
    const axios = await axiosWithConfig();
    try {
      const response = await axios.get(endpoint, {
        responseType: 'arraybuffer',
        params: { type: fileType, language },
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `template.${fileType}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setStep('error-step');
      if (error instanceof AxiosError) {
        setError(error.message);
      } else {
        setError('Error while uploading file');
      }
    }
  };

  return (
    <Box px={{ xs: 5, md: 10, lg: 16, xl: 35 }}>
      <div>
        <span>
          {formatMessage({
            id: 'employee_management.bulk_upload.upload.avoid.label',
            defaultMessage: 'To avoid data mismatch',
          })}
          ,{' '}
          <b>
            {formatMessage({
              id: 'employee_management.bulk_upload.upload.template.label',
              defaultMessage: 'use our template',
            })}{' '}
          </b>{' '}
          {formatMessage({
            id: 'employee_management.bulk_upload.upload.import.label',
            defaultMessage: 'for preparing import data',
          })}
          .
        </span>
        <RoundedCornerSelect
          value='default'
          open={openSelect}
          onClose={onSelectClose}
          onOpen={onSelectOpen}
          className={openSelect ? 'openSelect' : ''}>
          <MenuITemSelect className='default' value='default'>
            {formatMessage({
              id: 'employee_management.bulk_upload.cta.download',
              defaultMessage: 'Download template',
            })}
          </MenuITemSelect>
          {TemplateType.map((item, index) => (
            <MenuITemSelect
              key={index}
              value={item.value}
              onClick={() => downloadTemplate(item.value)}>
              {item.title}
            </MenuITemSelect>
          ))}
        </RoundedCornerSelect>
      </div>

      <div style={{ position: 'relative' }}>
        <FileTemplate>
          <thead>
            <tr>
              {templateHeaders.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.from(Array(templateHeaders.length).keys()).map((_item, index) => (
              <tr key={index}>
                {templateHeaders.map((_, index) => (
                  <td key={index}></td>
                ))}
              </tr>
            ))}
          </tbody>
        </FileTemplate>

        <UploadButtonContainer>
          <input
            style={{ display: 'none' }}
            id='contained-button-file'
            ref={fileInput}
            onChange={handleUpload}
            type='file'
            multiple
            accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          />
          <RoundedCornerButton
            onClick={() => document.getElementById('contained-button-file')?.click()}
            variant='contained'>
            {formatMessage({
              id: 'employee_management.bulk_upload.cta.upload',
              defaultMessage: 'Upload from file',
            })}
          </RoundedCornerButton>
        </UploadButtonContainer>
      </div>
      <Box mt={7} px={3.5} maxWidth={670} mx='auto'>
        <InfoAlert>
          <ErrorOutlineIcon htmlColor={theme.palette.warning.main} />
          <Box>
            <strong>
              {formatMessage({
                id: 'employee_management.bulk_upload.note.label',
                defaultMessage: 'Please, note:',
              })}
            </strong>{' '}
            <ul style={{ margin: '16px 18px', paddingLeft: 0 }}>
              <div
                style={{ margin: '16px 18px', paddingLeft: 0 }}
                dangerouslySetInnerHTML={{
                  __html: santinizedText,
                }}
              />
            </ul>
          </Box>
        </InfoAlert>
      </Box>
    </Box>
  );
};

export default UploadTabForm;
