import { useCallback, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';
import { useIntl } from 'react-intl';
import { GuestFormContainer, GuestHeader, Spacer } from 'components';
import { useAppDispatch, useAppSelector } from 'store';
import { InitialPasswordSelectors } from '../../store/initialPassword.selectors';
import InitialPasswordStepper from '../../components/Stepper';
import { INITIAL_PASSWORD_STEPS } from '../../store/interfaces';
import DefaultPassword from '../../components/Steps/DefaultPassword';
import SetNewPassword from '../../../selfRegistration/components/Steps/SetNewPassword';
import { InitialPasswordActions } from '../../store';
import { SetNewPasswordForm } from '../../../selfRegistration/components/Steps/SetNewPassword/interfaces';
import { FetchingStatus } from 'types';

const InitialPasswordPage = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const step = useAppSelector(InitialPasswordSelectors.currentStep);
  const defaultPasswordStepData = useAppSelector(InitialPasswordSelectors.getDefaultPasswordStep);
  const api = useAppSelector(InitialPasswordSelectors.api);
  const dispatch = useAppDispatch();

  const handleBack = useCallback(() => {
    dispatch(InitialPasswordActions.setStep(INITIAL_PASSWORD_STEPS.DEFAULT_PASSWORD));
    dispatch(InitialPasswordActions.updateSetNewPasswordStepData({ newPassword: '', email: '' }));
  }, [dispatch]);

  const handleSubmit = useCallback(
    (data: SetNewPasswordForm) => {
      dispatch(
        InitialPasswordActions.updateSetNewPasswordStepData({
          email: queryParams.email as string,
          newPassword: data.password,
        }),
      );
      dispatch(
        InitialPasswordActions.changeTemporaryPassword({
          email: queryParams.email as string,
          temporaryPassword: defaultPasswordStepData.defaultPassword,
          newPassword: data.password,
        }),
      );
    },
    [queryParams, dispatch, defaultPasswordStepData],
  );
  useEffect(() => {
    const changeTemporaryPasswordFetchingStatus = api.changeTemporaryPassword.fetchingStatus;

    if (changeTemporaryPasswordFetchingStatus === FetchingStatus.FULFILLED) {
      navigate('/de/login?invite=success');
    }
  }, [navigate, api.changeTemporaryPassword.fetchingStatus]);

  return (
    <Box>
      <GuestHeader />
      <Spacer height={44} />
      <GuestFormContainer>
        <Typography variant='body1' className={clsx('header')}>
          {intl
            .formatMessage({
              id: 'self_registration.header.title',
              defaultMessage: 'Self-registration',
            })
            .toUpperCase()}
        </Typography>

        <Spacer height={32} />
        <InitialPasswordStepper activeStep={step} />
        <Spacer height={16} />
        {step === INITIAL_PASSWORD_STEPS.DEFAULT_PASSWORD && <DefaultPassword />}
        {step === INITIAL_PASSWORD_STEPS.SET_NEW_PASSWORD && (
          <SetNewPassword backCallback={handleBack} submitCallback={handleSubmit} />
        )}
      </GuestFormContainer>
    </Box>
  );
};

export default InitialPasswordPage;
