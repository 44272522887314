import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Typography,
} from '@mui/material';

import { StatusChip } from 'components/StatusChip';
import { useAppSelector } from 'store';
import { PendingModalProps } from 'types/employee';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';

import { useIntl } from 'react-intl';
import { CCPDefaultButton, Spacer } from 'components';
import { FormatDate } from 'components/FormatDate';
import { useLanguage } from 'hooks';
import { useGendersList } from 'utils/genders';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { default as theme } from '@theme/theme';

const PendingModal: React.FC<PendingModalProps> = ({ employee, isOpen, closeModal }) => {
  const intl = useIntl();
  const language = useLanguage();
  const genders = useGendersList();
  const { getCompany } = useAppSelector(CompanySelectors.getApi);
  const companyData = getCompany.data;

  return !employee ? null : (
    <Dialog open={isOpen} onClose={closeModal}>
      <CloseIcon onClick={closeModal} className='dialog-close' />
      <DialogTitle>
        <Typography variant='h2'>
          {intl.formatMessage({
            id: 'employee_management.review_pending_request.title',
            defaultMessage: 'Review pending request',
          })}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='h3'>{`${employee.firstName} ${employee.lastName}`}</Typography>
        <Spacer size='md' />
        <Stack direction='row' alignItems='center' spacing={1}>
          <StatusChip
            background={theme.palette.primary.main}
            label={intl.formatMessage({
              id: 'employee.pending.request',
              defaultMessage: 'Request',
            })}
          />
          <Typography variant='subtitle2'>{`${intl.formatMessage({
            id: 'employee.pending.request.start_date',
            defaultMessage: 'Start date',
          })}: ${FormatDate(employee.entryDate)}`}</Typography>
        </Stack>
        <Spacer size='lg' />
        <Typography
          variant='subtitle2'
          textTransform='uppercase'
          fontFamily='Roboto-Medium'
          textAlign='left'>
          {intl.formatMessage({
            id: 'employee.pending.request.personal_info',
            defaultMessage: 'Personal info',
          })}
        </Typography>
        <Spacer size='md' />
        <Stack direction='row' spacing={3}>
          <Typography variant='subtitle1' textAlign='left'>
            {intl.formatMessage({
              id: 'employee.pending.request.personal_info.gender',
              defaultMessage: 'Gender',
            })}
            : <br />
            <b>{genders.filter(item => item.value === employee.gender)[0].title}</b>
          </Typography>
          <Typography variant='subtitle1' textAlign='left'>
            {intl.formatMessage({
              id: 'employee.pending.request.personal_info.birthday',
              defaultMessage: 'Birthday date',
            })}
            : <br />
            <b>{FormatDate(employee.dateOfBirth)}</b>
          </Typography>
          <Typography variant='subtitle1' textAlign='left'>
            {intl.formatMessage({
              id: 'employee.pending.request.start_date',
              defaultMessage: 'Start date',
            })}
            : <br />
            <b>{FormatDate(employee.entryDate)}</b>
          </Typography>
        </Stack>
        <Spacer size='lg' />
        <Typography
          variant='subtitle2'
          textTransform='uppercase'
          fontFamily='Roboto-Medium'
          textAlign='left'>
          {intl.formatMessage({
            id: 'employee.pending.request.contact_info',
            defaultMessage: 'Contact info',
          })}
        </Typography>
        <Spacer size='md' />
        <Stack direction='row' spacing={3}>
          <Typography variant='subtitle1' textAlign='left'>
            {intl.formatMessage({
              id: 'employee.pending.request.contact_info.email',
              defaultMessage: 'Corporate email',
            })}
            : <br />
            <b>{employee.businessEmail}</b>
          </Typography>
          <Typography variant='subtitle1' textAlign='left'>
            {intl.formatMessage({
              id: 'employee.pending.request.contact_info.phone',
              defaultMessage: 'Phone number',
            })}
            : <br />
            <b>{employee.phoneNumber}</b>
          </Typography>
        </Stack>
        <Spacer size='lg' />
        {companyData?.customFields.length && companyData?.customFields.length > 0 && (
          <>
            <Typography
              variant='subtitle2'
              textTransform='uppercase'
              fontFamily='Roboto-Medium'
              textAlign='left'>
              {intl.formatMessage({
                id: 'employee.pending.request.custom_fields',
                defaultMessage: 'Other',
              })}
            </Typography>
            <Spacer size='md' />
            <Stack direction='column' spacing={2}>
              {companyData.customFields.map((customField, idx) => (
                <Typography key={customField.key} variant='subtitle1' textAlign='left'>
                  {customField.name[language]}
                  : <br />
                  {/* next row - how it should works */}
                  {/* <b>{employee.customFields[customField.key]}</b> */}
                  {/* next row - how it working now for both languages we are using name translation for english... we need to fix it on the backend side  */}
                  <b>{employee.customFields[customField.name.en || customField.name.de || '']}</b>
                </Typography>
              ))}
            </Stack>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <CCPDefaultButton variant='outlined' color='error'>
          {intl.formatMessage({
            id: 'employee.pending.request.decline',
            defaultMessage: 'Decline',
          })}
        </CCPDefaultButton>
        <CCPDefaultButton variant='contained'>
          {intl.formatMessage({
            id: 'employee.pending.request.approve',
            defaultMessage: 'Approve request',
          })}
        </CCPDefaultButton>
      </DialogActions>
    </Dialog>
  );
};

export default PendingModal;
