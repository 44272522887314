import { CustomField } from './EmployeeCustomField.styles';

export interface EmployeeCustomFieldProps {
  customField: string;
}

const EmployeeCustomField = ({ customField }: EmployeeCustomFieldProps) => {
  return <CustomField>{customField}</CustomField>;
};

export default EmployeeCustomField;
