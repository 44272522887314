import { EmployeeStatus, TransitionStatus } from '@localTypes/index';
import { default as theme } from '@theme/theme';

export const getStatusColor = (
  status: EmployeeStatus | TransitionStatus,
  isPlannedTransition?: boolean,
) => {
  if (isPlannedTransition) {
    switch (status) {
      case TransitionStatus.PAUSE: {
        return theme.palette.warning.main;
      }
      case TransitionStatus.PAUSED_UNLIMITED: {
        return theme.palette.neutralDark.main;
      }
      case TransitionStatus.DEACTIVATING:
      case TransitionStatus.DEACTIVATED:
      case TransitionStatus.DELETED: {
        return theme.palette.error.main;
      }
      case TransitionStatus.PAUSED:
      case TransitionStatus.PENDING: {
        return theme.palette.success.main;
      }
      default:
        return theme.palette.neutralDark.main;
    }
  }
  switch (status) {
    case EmployeeStatus.APPLIED:
      return theme.palette.primary.main;
    case EmployeeStatus.DENIED:
    case EmployeeStatus.INACTIVE:
      return theme.palette.neutralDark.main;
    case EmployeeStatus.ACTIVE:
    case EmployeeStatus.ELIGIBLE:
      return theme.palette.success.main;
    case EmployeeStatus.LOCKED:
    case EmployeeStatus.BLOCKED:
      return theme.palette.error.main;
    case EmployeeStatus.PAUSED:
      return theme.palette.warning.main;
    case EmployeeStatus.PENDING:
      return theme.palette.success.main;
    default:
      return theme.palette.neutralDark.main;
  }
};
