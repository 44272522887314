import * as yup from 'yup';

export const AddSelfSignupEmployeeSchema = yup
  .object({
    firstName: yup.string().required('form.employee.error.first_name.required'),
    lastName: yup.string().required('form.employee.error.last_name.required'),
    businessEmail: yup
      .string()
      .trim()
      .required('form.employee.error.corporate_email.required')
      .email('form.employee.error.corporate_email.invalid'),
  })
  .required();

export default AddSelfSignupEmployeeSchema;
