import { GridColDef, GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro';
import { CompanyCustomFieldType } from 'types';
import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import EmployeeCustomField from '../features/employeeManagement/components/EmployeeOverview/EmployeeTable/Columns/CustomField';
import { useLanguage } from 'hooks';

export type ColumnData<T extends GridValidRowModel> = GridColDef<T> & {
  i18nText?: string;
  defaultMessage?: string;
  searchType?: 'string' | 'date';
  searchable?: boolean;
};

type UseTableColumnsProps<T extends GridValidRowModel> = {
  scope: string;
  columnsData: ColumnData<T>[];
  customFields?: CompanyCustomFieldType[];
  globalCustomFields?: CompanyCustomFieldType[];
};

export const useTableColumns = <T extends GridValidRowModel>({
  scope,
  columnsData,
  customFields,
  globalCustomFields,
}: UseTableColumnsProps<T>) => {
  const intl = useIntl();
  const language = useLanguage();

  return useMemo(() => {
    const composedTableColumns: GridColDef[] = [];
    columnsData.forEach(data => {
      composedTableColumns.push({
        ...data,
        headerName: intl.formatMessage({
          id: `${scope}.${data.i18nText}`,
          defaultMessage: data.defaultMessage,
        }),
      });
    });

    if (customFields) {
      customFields.forEach(customField => {
        composedTableColumns.push({
          field: customField.key,
          headerName: customField.name[language] || undefined,
          width: 140,
          renderCell: (params: GridRenderCellParams) => (
            <EmployeeCustomField customField={params.value} />
          ),
        });
      });
    }
    if (globalCustomFields) {
      globalCustomFields.forEach(customField => {
        composedTableColumns.push({
          field: customField.key,
          headerName: customField.name[language] || undefined,
          width: 140,
          renderCell: (params: GridRenderCellParams) => (
            <EmployeeCustomField customField={params.value} />
          ),
        });
      });
    }
    return composedTableColumns;
  }, [intl, scope, columnsData, customFields, globalCustomFields, language]);
};
