import { Box, Typography } from '@mui/material';

import { ReactComponent as PhoneLogo } from 'assets/icons/phone-icon.svg';
import { ReactComponent as HansefitLogoLarge } from 'assets/icons/hansefit-logo-large.svg';
import { useIntl } from 'react-intl';

const UnAuthorizedSideBar = () => {
  const { formatMessage } = useIntl();

  return (
    <Box className='unauthorized'>
      <Box className='unauthorized__header'>
        <HansefitLogoLarge />
      </Box>
      <Box className='unauthorized__content'>
        <Box className='unauthorized__content_contact'>
          <PhoneLogo />
          <Box className='unauthorized__content_contact_info'>
            <Typography className='unauthorized__content_contact_title' variant='body1'>
              {formatMessage({ id: 'footer.links.contact.text', defaultMessage: 'Contact' })}
            </Typography>
            <Typography className='unauthorized__content_contact_link' variant='body1'>
              {formatMessage({ id: 'sidebar.phone', defaultMessage: 'Phone' })}:
              <a
                href={`tel:${formatMessage({
                  id: 'sidebar.phone.href',
                  defaultMessage: '+49042143818395',
                })}`}>
                {formatMessage({
                  id: 'sidebar.phone.value',
                  defaultMessage: '+49 (0) 421 43818395',
                })}
              </a>
            </Typography>
            <Typography className='unauthorized__content_contact_link' variant='body1'>
              {formatMessage({ id: 'sidebar.email', defaultMessage: 'E-Mail' })}:
              <a
                href={`mailto:${formatMessage({
                  id: 'sidebar.email.href',
                  defaultMessage: 'yourmail@gmail.com',
                })}`}>
                {formatMessage({
                  id: 'sidebar.email.value',
                  defaultMessage: 'verbundpartner@hansefit.de',
                })}
              </a>
            </Typography>
          </Box>
        </Box>
        <a
          className='unauthorized__content_link'
          href={formatMessage({ id: 'sidebar.privacy_policy.link', defaultMessage: '/' })}
          target='_blank'
          rel='noreferrer'>
          {formatMessage({ id: 'sidebar.privacy_policy', defaultMessage: 'Privacy Policy' })}
        </a>
        <a
          className='unauthorized__content_link'
          href={formatMessage({
            id: 'sidebar.term_and_conditions.link',
            defaultMessage: '/',
          })}
          target='_blank'
          rel='noreferrer'>
          {formatMessage({
            id: 'sidebar.term_and_conditions',
            defaultMessage: 'Terms & conditions',
          })}
        </a>
        <a
          className='unauthorized__content_link'
          href={formatMessage({
            id: 'sidebar.website.href',
            defaultMessage: 'https://www.hansefit.de',
          })}
          target='_blank'
          rel='noreferrer'>
          {formatMessage({
            id: 'sidebar.website',
            defaultMessage: 'hansefit.de',
          })}
        </a>
      </Box>
    </Box>
  );
};

export default UnAuthorizedSideBar;
