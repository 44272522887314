import * as yup from 'yup';

export const AddEmailSchema = yup
  .object({
    email: yup
      .string()
      .required('schema.add_personal_info.error.email.required')
      .trim()
      .email('schema.add_personal_info.error.email.email'),
    description: yup.string(),
  })
  .required();

export default AddEmailSchema;
