import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Typography,
} from '@mui/material';
import { CCPDefaultButton, CCPSelect, Spacer } from 'components';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { UserManagementSelectors } from 'features/userManagement/store/userManagement.selectors';
import { SettingsActions } from 'features/settings/store';
import { ActivationCodeSchema } from 'shared/schemas';
import { useAppDispatch, useAppSelector } from 'store';
import { ListType, RootState } from 'types';
import Loader from '@components/Loader';
import { SettingsSelectors } from '@features/settings/store/settings.selectors';
import { GlobalStateActions } from '@store/globalState';

type AddUserToDistributionListProps = {
  listType: ListType;
};

const AddUserToDistributionList = ({ listType }: AddUserToDistributionListProps) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);
  const { activationLetterRecipientMode } = useAppSelector(SettingsSelectors.settingsSettings);
  const {
    guardPopups: { showActivationLettersAddUser },
  } = useAppSelector(state => state.globalState);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const currentUsersInDistributionList = useAppSelector(
    store => store.settings.distributionLists[listType].data,
  ).map(item => item.email);

  //receiving list of available users with user management rights(Eployee role)
  const availableUsers = useAppSelector((rootState: RootState) =>
    UserManagementSelectors.getUniqueActiveUserMgmtUsers(rootState, currentUsersInDistributionList),
  );

  useEffect(() => {
    if (showActivationLettersAddUser) {
      setIsModalOpen(true);
      dispatch(GlobalStateActions.showActivationLettersAddUserPopup(false));
    }
  }, [showActivationLettersAddUser, dispatch]);

  const methods = useForm({
    defaultValues: {
      selectedEmployeeMngUser: '',
    },
    mode: 'all',
    resolver: yupResolver(ActivationCodeSchema),
  });
  const { setValue, control, watch } = methods;
  const selectedEmployeeMngUser = watch('selectedEmployeeMngUser');

  const handleClose = () => {
    setIsModalOpen(false);
    setValue('selectedEmployeeMngUser', '');
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleAddUserToList = async () => {
    if (activeCompany) {
      setIsLoading(true);
      await dispatch(
        SettingsActions.addRecordToDistributionList({
          email: selectedEmployeeMngUser,
          listType,
        }),
      );
      if (listType === ListType.ACTIVATION_LETTER && activationLetterRecipientMode === 'eu') {
        await dispatch(
          SettingsActions.updateSettings({
            companyId: activeCompany.companyId,
            activationLetterRecipientMode: 'cu',
          }),
        );
      }
      setIsLoading(false);
      handleClose();
    }
  };

  return (
    <>
      <Loader show={isLoading} />
      <CCPDefaultButton variant='contained' onClick={handleOpenModal}>
        {formatMessage({
          id: 'distribution_list.cta.add_new_recipient',
          defaultMessage: 'Add new recipient',
        })}
      </CCPDefaultButton>
      <FormProvider {...methods}>
        <Dialog open={isModalOpen} component='form'>
          <DialogTitle>
            {formatMessage({
              id: 'distribution_list.select_company_user.title',
              defaultMessage: 'Select company user',
            })}
          </DialogTitle>
          <DialogContent>
            {availableUsers.length > 0 && (
              <>
                <Typography variant='subtitle1'>
                  {formatMessage({
                    id: 'distribution_list.select_company_user.description',
                    defaultMessage: 'Add a new recipient to the email distribution  list.',
                  })}
                </Typography>
                <Spacer size='2xl' />

                <CCPSelect
                  label={formatMessage({
                    id: 'distribution_list.select_company_user.dropdown',
                    defaultMessage: 'Company user',
                  })}
                  required
                  name='selectedEmployeeMngUser'
                  control={control}>
                  {availableUsers.map(user => (
                    <MenuItem
                      key={user.cognitoId}
                      value={user.email}
                      selected={user.cognitoId === selectedEmployeeMngUser}>
                      {user.name} {user.surname} ({user.email})
                    </MenuItem>
                  ))}
                </CCPSelect>
              </>
            )}
            {availableUsers.length === 0 && (
              <Typography variant='subtitle1'>
                {formatMessage({
                  id: 'distribution_list.empty_users_list',
                  defaultMessage:
                    'No company user is available. Company users must have an active status and the ‘Employees management’ rights.',
                })}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <CCPDefaultButton variant='outlined' onClick={handleClose}>
              {formatMessage({
                id: 'distribution_list.select_company_user.cancel',
                defaultMessage: 'Cancel',
              })}
            </CCPDefaultButton>
            <CCPDefaultButton
              disabled={!selectedEmployeeMngUser}
              variant='contained'
              onClick={handleAddUserToList}>
              {formatMessage({
                id: 'distribution_list.select_company_user.add_company_user',
                defaultMessage: 'Add company user',
              })}
            </CCPDefaultButton>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default AddUserToDistributionList;
