import { useEffect, useState, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { ChangeEmailModalProps, FetchingStatus } from 'types';

import VerifyCode from './VerifyCode';
import ChangeEmail from './ChangeEmail';
import SuccessChanged from './SuccessChanged';
import { UserManagementActions } from 'features/userManagement/store';
import Loader from '@components/Loader';

const ChangeEmailModal: React.FC<ChangeEmailModalProps> = ({ isOpen, closeModal }) => {
  const dispatch = useAppDispatch();
  const { fetchingStatus: resendFetchingStatus } = useAppSelector(
    state => state.userManagement.changeUserEmail,
  );

  const { fetchingStatus: verifyFetchingStatus } = useAppSelector(
    state => state.userManagement.verifyChangeUserEmail,
  );

  const [email, setEmail] = useState<string>('');
  const [activeStep, setActiveStep] = useState<number>(1);

  const updateActiveStepHandler = (step: number): void => {
    setActiveStep(step);
  };

  const updateChangedEmail = (newEmail: string): void => {
    setEmail(newEmail);
  };

  useEffect(() => {
    if (resendFetchingStatus === FetchingStatus.FULFILLED) {
      updateActiveStepHandler(2);
    }
  }, [resendFetchingStatus]);

  useEffect(() => {
    if (verifyFetchingStatus === FetchingStatus.FULFILLED) {
      updateActiveStepHandler(3);
      dispatch(UserManagementActions.resetChangeUserEmailStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyFetchingStatus]);

  useEffect(
    () => () => {
      dispatch(UserManagementActions.resetChangeUserEmailStatus());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const isLoading = useMemo(
    () => [verifyFetchingStatus, resendFetchingStatus].includes(FetchingStatus.PENDING),
    [resendFetchingStatus, verifyFetchingStatus],
  );

  const renderActiveStep = () => {
    switch (activeStep) {
      case 2:
        return <VerifyCode email={email} updateStep={updateActiveStepHandler} />;
      case 3:
        return <SuccessChanged />;
      default:
        return (
          <ChangeEmail
            isOpen={isOpen}
            closeModal={closeModal}
            updateChangedEmail={updateChangedEmail}
          />
        );
    }
  };

  return (
    <>
      <Loader show={isLoading} />
      {renderActiveStep()}
    </>
  );
};

export default ChangeEmailModal;
