import { CompanyCustomFieldType, TranslationsKeys } from '@localTypes/index';

const getCustomFieldEnumLabel = (
  value: string,
  customField: CompanyCustomFieldType,
  currentLanguage: TranslationsKeys,
  companyLanguage?: TranslationsKeys,
): string => {
  if (
    customField.inputType === 'enum' &&
    customField.localizedValuesMap &&
    customField.localizedValuesMap[value]
  ) {
    value =
      customField.localizedValuesMap[value][currentLanguage] ||
      (companyLanguage && currentLanguage !== companyLanguage
        ? customField.localizedValuesMap[value][companyLanguage]
        : customField.localizedValuesMap[value][currentLanguage === 'en' ? 'de' : 'en']) ||
      value;
  }
  return value;
};

export default getCustomFieldEnumLabel;
