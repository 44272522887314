import { Amplify } from 'aws-amplify';
import { Provider } from 'react-redux';
import { LicenseInfo } from '@mui/x-license';
import { PersistGate } from 'redux-persist/integration/react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import 'react-toastify/dist/ReactToastify.css';

import awsConfig from './aws-exports';
import './assets/fonts/stylesheet.css';
import { CookiesProvider } from 'react-cookie';
import Router from '@routes/Router';
import CCPTheme from '@theme/theme';
import { store, persistor } from '@store/store';

LicenseInfo.setLicenseKey(
  'e3ab5516e4c537760d54e859af81277fTz04NzczOCxFPTE3NDM3NTUzNTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);
Amplify.configure({ ...awsConfig, authenticationFlowType: 'CUSTOM_AUTH' });

Sentry.init({
  dsn: 'https://75fab04ecee4650d0f1b7282578c5429@o4504718314831872.ingest.sentry.io/4505635681992704',
  environment: process.env.REACT_APP_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],

  enableTracing: true,
  tracesSampleRate: 0.5,
  tracePropagationTargets: ['localhost', 'pimcore.*.hansefit.de'],

  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});

const App = () => (
  <CookiesProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={CCPTheme}>
          <CssBaseline />
          <Router />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </CookiesProvider>
);

export default App;
