import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

import { CompanyRole, ContractStatus, FetchingStatus, RootState } from 'types';
import { Header, Footer } from 'components';

import { UserLayoutStyled } from './UserLayout.styles';
import { AuthenticationSelectors } from 'features/authentication/store/authentication.selectors';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { Navigate, useNavigate } from 'react-router-dom';
import { navigationList } from 'utils';
import { useCookies } from 'react-cookie';
import { ContractsSelectors } from 'features/contract/store/contracts.selectors';
import { useAppSelector } from 'store';

export interface UserLayoutProps {
  children: JSX.Element | JSX.Element[];
  leftNavigation: JSX.Element;
  path: string;
}

const UserLayout: React.FC<UserLayoutProps> = ({ children, leftNavigation, path }) => {
  const { isOpenSidebar } = useSelector((state: RootState) => state.authentication);
  const { companiesNames, activeCompany } = useSelector(CompanySelectors.getCompanyState);
  const authentication = useSelector(AuthenticationSelectors.getAuthentication);
  const navigate = useNavigate();
  const [cookies] = useCookies(['language_app']);
  const activeLanguage = cookies.language_app || 'de';
  const userData = useSelector((state: RootState) => state.userManagement.users);
  const contracts = useAppSelector(ContractsSelectors.getContracts).getContracts.data;

  const localStorageActiveCompanyId = localStorage.getItem('activeCompanyId');

  const [checkPermission, setCheckPermission] = useState(true);

  const { userInfo, authenticated } = authentication;

  const hasFullAccess = contracts.some(
    contract =>
      contract.status === ContractStatus.Active &&
      contract.products.filter(item => item.name === 'Online+').length > 0,
  );

  useEffect(() => {
    if (!authenticated || path.includes('dashboard') || path.includes('account')) {
      setCheckPermission(false);
      return;
    }
    if (
      (!localStorageActiveCompanyId || localStorageActiveCompanyId === 'All companies') &&
      !path.includes('dashboard') &&
      !path.includes('account')
    ) {
      navigate(`/${activeLanguage}/dashboard`);
      setCheckPermission(false);
      return;
    }
    if (
      (userData.fetchingStatus === FetchingStatus.IDLE ||
        userData.fetchingStatus === FetchingStatus.PENDING) &&
      localStorageActiveCompanyId &&
      userInfo?.role.includes(CompanyRole.Admin)
    ) {
      return;
    }
    const pathList = [
      ...navigationList,
      {
        link: 'account',
        role: [],
      } as { link: string; role: string[] },
    ];

    const navigationPath = pathList.find(item => path.includes(item.link));
    if (!navigationPath) {
      setCheckPermission(false);
      return;
    }

    if (userInfo) {
      const userRole = userInfo?.role || [];
      const userInfoRole = [...userRole];
      const { role } = navigationPath;
      const hasPermission =
        role.length === 0 || !!userInfoRole.filter(element => role.includes(element))?.length;
      if (!hasPermission) {
        navigate(
          `/${activeLanguage}/dashboard/${
            companiesNames.data.length === 1
              ? companiesNames.data[0].companyId
              : activeCompany
              ? activeCompany.companyId
              : ''
          }`,
        );
        return;
      }
    }

    setCheckPermission(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, userInfo, authenticated, activeLanguage, path, userData]);

  if (!authenticated) {
    return <Navigate to={`/${activeLanguage}/login`} replace />;
  }

  if (checkPermission) {
    return null;
  }

  if (!hasFullAccess && path.includes('coupon-usage')) {
    return <Navigate to={`/${activeLanguage}/stats`} />;
  }

  return (
    <UserLayoutStyled isAuthorized={isOpenSidebar}>
      {leftNavigation}
      <Box className='box__content'>
        <Header />
        <Box className='box__content__wrapper'>{children}</Box>
        <Footer />
      </Box>
    </UserLayoutStyled>
  );
};

export default UserLayout;
