import { styled, Box } from '@mui/material';

const StatsTableWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: 'auto',
  background: theme.palette.background.paper,
  borderRadius: '0 4px 4px 0',
  paddingBottom: '12px',
}));

export default StatsTableWrapper;
