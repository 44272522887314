import * as yup from 'yup';

export const RemoveEmployeeSchema = (
  minStartDate: Date,
  maxStartDate: Date,
  hideStartDate: boolean,
) =>
  hideStartDate
    ? yup
        .object({
          removeEmployeeWarning: yup
            .boolean()
            .oneOf([true], 'form.employee.error.remove_checkbox.invalid'),
        })
        .required()
    : yup
        .object({
          startDate: yup
            .date()
            .min(minStartDate, 'form.employee.error.past_date')
            .max(maxStartDate, 'form.employee.error.start_date_very_big')
            .typeError('form.employee.error.last_date.invalid')
            .nullable()
            .required('form.employee.error.last_date.required'),
          removeEmployeeWarning: yup
            .boolean()
            .oneOf([true], 'form.employee.error.remove_checkbox.invalid'),
        })
        .required();

export default RemoveEmployeeSchema;
