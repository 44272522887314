import { Box, styled } from '@mui/material';

export const AddOtherLanguage = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '56px',
  alignItems: 'center',
  '& p': {
    display: 'flex',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    fontSize: 16,
    fontFamily: 'Roboto-Medium',
    fontWeight: 500,
    lineHeight: 1.38,
  },
}));
export const FieldsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  '.note': {
    color: theme.palette.neutralDark.main,
    fontSize: 14,
    maxWidth: 260,
    marginBottom: 5,
  },
  '.MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.neutralLight.dark,
    },
  },
  '.MuiInputBase-root.Mui-disabled': {
    backgroundColor: theme.palette.transparent.disabledInput,
  },
  '.deleteIcon': {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
}));
