import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { FetchingStatus, INITIAL_PASSWORD_STEPS, InitialPasswordState } from 'types';
import { changeTemporaryPassword } from './changeTemporaryPassword';

export const changeTemporaryPasswordExtraReducer = (
  builder: ActionReducerMapBuilder<InitialPasswordState>,
) => {
  builder.addCase(changeTemporaryPassword.pending, state => {
    state.api.changeTemporaryPassword.fetchingStatus = FetchingStatus.PENDING;
    state.api.changeTemporaryPassword.error = null;
  });

  builder.addCase(changeTemporaryPassword.fulfilled, state => {
    state.api.changeTemporaryPassword.fetchingStatus = FetchingStatus.FULFILLED;
    state.api.changeTemporaryPassword.error = null;
  });

  builder.addCase(changeTemporaryPassword.rejected, (state, action) => {
    state.api.changeTemporaryPassword.fetchingStatus = FetchingStatus.REJECTED;
    state.api.changeTemporaryPassword.error = action.payload as string;
    state.currentStep = INITIAL_PASSWORD_STEPS.DEFAULT_PASSWORD;
  });
};
