import { useMemo } from 'react';
import { GridRowModel, GridValidRowModel } from '@mui/x-data-grid-pro';
import { countries } from 'features/employeeManagement/components/EmployeeOverview/GlobalCustomFields/hardcodedData';

export interface UseTableRowsProps {
  uniqueIdKey: string;
  customFieldKey?: string;
  globalCustomFieldKey?: string;
  data: GridValidRowModel[];
}

export const useTableRows = ({
  uniqueIdKey,
  customFieldKey,
  globalCustomFieldKey,
  data = [],
}: UseTableRowsProps) =>
  useMemo(() => {
    const rows = [] as GridValidRowModel[];

    data.forEach(entry => {
      const keys = Object.keys(entry);
      const rowEntry: GridRowModel = {};

      keys.forEach(key => {
        if (key === uniqueIdKey) {
          rowEntry['id'] = entry[key] as number;
          rowEntry[key] = entry[key] as string | number;
        } else if (key === customFieldKey) {
          const customFieldKeys = Object.keys(entry[key]);
          customFieldKeys.forEach(customKey => {
            // @ts-ignore
            rowEntry[customKey] = entry[key as string][customKey as string];
          });
        } else if (key === globalCustomFieldKey) {
          const customFieldKeys = Object.keys(entry[key]);
          customFieldKeys.forEach(customKey => {
            rowEntry[customKey] =
              customKey === 'hansefit_country'
                ? countries.filter(
                    country =>
                      // @ts-ignore
                      country.value === entry[key][customKey],
                  )[0]?.title
                : // @ts-ignore
                  entry[key as string][customKey as string];
          });
        } else {
          rowEntry[key] = entry[key] as string | number;
        }
      });

      return rows.push(rowEntry);
    });

    return rows;
  }, [data, uniqueIdKey, customFieldKey, globalCustomFieldKey]);
