import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { AuthenticationState, TDistributionLists } from 'types';

export const changeDistributionList: CaseReducer<
  AuthenticationState,
  PayloadAction<{ companyId: string; distributionList: TDistributionLists }>
> = (state, { payload }) => {
  if (state.userInfo?.companydetails) {
    state.userInfo.companydetails.forEach(item => {
      if (item.companyId === payload.companyId) {
        item.distributionListSubscribed = payload.distributionList;
      }
    });
  }
};
