import qs from 'qs';
import { useEffect, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, Link } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { FormProvider, useForm } from 'react-hook-form';
import { LoginStyled } from './Login.styles';
import { LoginForm } from './interfaces';
import { LoginSchema } from 'shared/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'store';
import {
  AlertModal,
  CCPDefaultButton,
  CCPPasswordTextField,
  CCPTextField,
  Spacer,
  SuccessIcon,
} from 'components';
import { AuthenticationActions } from '@features/authentication/store';
import { AuthenticationSelectors } from '@features/authentication/store/authentication.selectors';
import { InitialPasswordSelectors } from '@features/initialPassword/store/initialPassword.selectors';
import { default as theme } from '@theme/theme';
import Loader from '@components/Loader';
import { FetchingStatus } from '@api/interfaces';
import { useLanguage } from '@hooks/useLanguage';

const Login = () => {
  const intl = useIntl();
  const location = useLocation();
  const language = useLanguage();
  const dispatch = useAppDispatch();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { error, forgotPassword, fetchingStatus } = useAppSelector(
    AuthenticationSelectors.getAuthentication,
  );
  const setNewPasswordStep = useAppSelector(InitialPasswordSelectors.getSetNewPasswordStep);

  const [accountIsBlocked, setAccountIsBlocked] = useState<boolean>(false);
  const [sessionIsExpired, setSessionIsExpired] = useState<boolean>(false);

  const methods = useForm<LoginForm>({
    defaultValues: {
      email: setNewPasswordStep.email,
      password: setNewPasswordStep.newPassword,
    },
    resolver: yupResolver(LoginSchema),
  });
  const { control, handleSubmit, setError } = methods;

  const onSubmit = ({ email, password }: LoginForm) => {
    //it is a crutches to fix CCP-568 for correct handle situation where user get a link to complete registration(second link)
    //unfortunately I don't have a deeper knowlege in this project and we need to fix this problem maybe in a future
    //second part of this crutch is located in GuestLayout/index.tsx
    //oleksandr.bielik - 26.10.2022
    localStorage.setItem('loginWasPressed', 'true');
    dispatch(AuthenticationActions.signIn({ email, password }));
  };

  useEffect(() => {
    if (error) {
      setError('email', { type: 'custom', message: '' });
      setError('password', { type: 'custom', message: '' });
    }
  }, [setError, error]);

  useEffect(() => {
    setAccountIsBlocked(queryParams.auth === 'false');
    setSessionIsExpired(queryParams.session === 'expired');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const closeBlockedPopup = () => {
    setAccountIsBlocked(false);
  };

  const closeExpiredPopup = () => {
    setSessionIsExpired(false);
  };

  const isLoading = useMemo(() => fetchingStatus === FetchingStatus.PENDING, [fetchingStatus]);

  return (
    <LoginStyled>
      <Loader show={isLoading} />
      {accountIsBlocked && (
        <AlertModal
          isOpen={accountIsBlocked}
          handleClose={closeBlockedPopup}
          title={intl.formatMessage({
            id: 'alert.modal.account_is_blocked.title',
            defaultMessage: 'Your account is blocked',
          })}
        />
      )}
      {sessionIsExpired && (
        <AlertModal
          iconComponent={<ErrorOutlineIcon htmlColor={theme.palette.warning.main} />}
          isOpen={sessionIsExpired}
          handleClose={closeExpiredPopup}
          title={intl.formatMessage({
            id: 'alert.modal.session_expired.title',
            defaultMessage: 'Your session expired',
          })}
          description={intl.formatMessage({
            id: 'alert.modal.session_expired.description',
            defaultMessage:
              'Your session was expired and you were logged out automatically. Please log in again.',
          })}
        />
      )}
      <Box className='headerContainer'>
        {queryParams.invite === 'success' && (
          <>
            <SuccessIcon />
            <Spacer size='lg' />
            <Typography variant='h2'>
              {intl.formatMessage({
                id: 'registration.completed',
                defaultMessage: 'Registration completed successfully',
              })}
            </Typography>
            <Spacer size='lg' />
          </>
        )}
        {forgotPassword && forgotPassword.activeStep === 3 ? (
          <>
            <CheckCircleOutlinedIcon fontSize='large' htmlColor={theme.palette.success.main} />
            <Spacer size='lg' />
            <Typography variant='h2'>
              {intl.formatMessage({
                id: 'login.reset_password',
                defaultMessage: 'Password successfully reset',
              })}
            </Typography>
            <Spacer size='lg' />
          </>
        ) : (
          <>
            <Typography variant='h2' fontSize='24px'>
              {intl.formatMessage({
                id: 'Login',
                defaultMessage: 'Login',
              })}
            </Typography>
            <Spacer size='lg' />
          </>
        )}
        {queryParams.invite === 'error' && (
          <>
            <Box className='error'>
              <ErrorRoundedIcon fontSize='small' htmlColor={theme.palette.error.main} />
              <Typography variant='subtitle1'>
                {intl.formatMessage({
                  id: 'login.invite_error',
                  defaultMessage: 'An error occurred, please try again later or contact Hansefit.',
                })}
              </Typography>
            </Box>
            <Spacer size='lg' />
          </>
        )}
        {error?.message ? (
          <Box className='error'>
            <ErrorRoundedIcon fontSize='small' htmlColor={theme.palette.error.main} />
            <Typography variant='subtitle1'>
              {intl.formatMessage({
                id: 'login.wrong',
                defaultMessage: 'You enter wrong Email or password.',
              })}
            </Typography>
          </Box>
        ) : (
          <Typography variant='subtitle1'>
            {intl.formatMessage({
              id: 'login.account',
              defaultMessage: 'Login to your account to use the system.',
            })}
          </Typography>
        )}
      </Box>
      <Spacer size='2xl' />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CCPTextField control={control} name='email' label='Email' reset />
          <Spacer size='xl' />
          <CCPPasswordTextField
            label={intl.formatMessage({ id: 'password', defaultMessage: 'Password' })}
            name='password'
            control={control}
            isRepeat={false}
          />
          <Spacer size='md' />
          <Box className='forgotPasswortContainer'>
            <Typography variant='body1'>
              {intl.formatMessage({
                id: 'forgot_password',
                defaultMessage: 'Forgot Password?',
              })}
            </Typography>
            &nbsp;
            <Link to={`/${language}/reset-password`} data-test-id='resetPasswordLink'>
              {intl.formatMessage({
                id: 'reset_password',
                defaultMessage: 'Reset password',
              })}
            </Link>
          </Box>
          <Spacer size='4xl' />
          <Box className='ctaContainer'>
            <CCPDefaultButton
              type='submit'
              variant='contained'
              color='primary'
              data-test-id='loginButton'>
              {intl.formatMessage({ id: 'Login', defaultMessage: 'Login' })}
            </CCPDefaultButton>
          </Box>
        </form>
      </FormProvider>
    </LoginStyled>
  );
};

export default Login;
