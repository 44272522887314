import { format } from 'date-fns';
let today = new Date();

export const getUpdatedDate = () =>
  format(new Date(today.getTime() + 1000 * 60 * 60 * 24 * 14), 'yyyy-MM-dd');

export const getBirthDate = (border: string) => {
  if (border === 'min')
    return new Date(today.getFullYear() - 120, today.getMonth(), today.getUTCDate());
  else if (border === 'max')
    return new Date(today.getFullYear() - 10, today.getMonth(), today.getUTCDate());
  else return today;
};

export const isValidBirthday = (val: Date) => {
  const minDate = getBirthDate('min');
  const maxDate = getBirthDate('max');
  const isValid = val >= minDate && val < maxDate ? true : false;
  return isValid;
};
