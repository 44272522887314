import { combineReducers } from '@reduxjs/toolkit';

import { statsReducer } from '@features/stats/store/stats.slice';
import { supportReducer } from '@features/support/store/support.slice';
import { companyReducer } from '@features/company/store/company.slice';
import { invoicesReducer } from '@features/invoices/store/invoices.slice';
import { settingsReducer } from '@features/settings/store/settings.slice';
import { notificationReducer } from '@features/shared/notification/store/notification.slice';
import { s3CredentialsReducer } from '@features/shared/s3Credentials/store/s3Credentials.slice';
import { authenticationReducer } from '@features/authentication/store/authentication.slice';
import { userManagementReducer } from '@features/userManagement/store/userManagement.slice';
import { initialPasswordReducer } from '@features/initialPassword/store/initialPassword.slice';
import { selfRegistrationReducer } from '@features/selfRegistration/store/selfRegistration.slice';
import { employeeManagementReducer } from '@features/employeeManagement/store/employeeManagement.slice';
import { marketingMaterialsManagementReducer } from '@features/marketingMaterials/store/marketingMaterialsManagement.slice';
import { uploadAssetsReducer } from '@features/shared/companyAssets/store/uploadAssets.slice';
import { contractsReducer } from '@features/contract/store/contracts.slice';
import { tablesManagementReducer } from '@components/TableGrid/store/tablesManagement.slice';
import { globalStateReducer } from './globalState/globalState.slice';
import { MFAStateReducer } from './mfaState/mfaState.slice';

export const rootReducer = () =>
  combineReducers({
    notification: notificationReducer,
    selfRegistration: selfRegistrationReducer,
    authentication: authenticationReducer,
    userManagement: userManagementReducer,
    marketingMaterialsManagement: marketingMaterialsManagementReducer,
    initialPassword: initialPasswordReducer,
    employeeManagement: employeeManagementReducer,
    company: companyReducer,
    invoices: invoicesReducer,
    stats: statsReducer,
    settings: settingsReducer,
    s3Credentials: s3CredentialsReducer,
    support: supportReducer,
    uploadAssets: uploadAssetsReducer,
    tablesManagement: tablesManagementReducer,
    contracts: contractsReducer,
    globalState: globalStateReducer,
    MFAState: MFAStateReducer,
  });
