import { styled, Box } from '@mui/material';

export const CompanyWrapper = styled(Box)(({ theme }) => ({
  '.settings': {
    padding: 24,
    background: theme.palette.background.paper,
    borderRadius: 4,
    minHeight: 479,

    '&__title': {
      color: theme.palette.neutralDark.contrastText,
      fontSize: 14,
      fontFamily: 'NutmegHeadline-UltraBlack',
      fontWeight: 900,
      lineHeight: 1.57,
      marginBottom: 24,
      textTransform: 'uppercase',
    },
  },
}));
