import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosWithConfig } from 'api';
import { AxiosError } from 'axios';

import { showToast } from 'utils';
import { verifyChangeUserEmailRequestBody, UserManagementBaseResponse } from 'types';

export const verifyChangeUserEmail = createAsyncThunk<
  UserManagementBaseResponse,
  verifyChangeUserEmailRequestBody
>('userManagement/verifyChangeUserEmail', async (body, { rejectWithValue, dispatch }) => {
  const endpoint = process.env.REACT_APP_USER_MANAGEMENT_ENDPOINT + 'verifyChangeUserEmail';
  const axios = await axiosWithConfig();

  try {
    const { data } = await axios.put(endpoint, body);

    //dispatch(AuthenticationActions.signOut());

    return data;
  } catch (e) {
    const error: AxiosError<UserManagementBaseResponse> =
      e as AxiosError<UserManagementBaseResponse>;

    if (
      error.response &&
      Object.keys(verifyChangeUserEmailErrors).includes(error.response.data.message)
    ) {
      showToast(
        'error',
        verifyChangeUserEmailErrors[
          error.response.data.message as
            | 'ALIAS_ALREADY_EXISTS'
            | 'INVALID_VERIFICATION_CODE'
            | 'NO_EMAIL_CHANGE_REQUESTED'
        ],
      );
    } else {
      showToast('error', {
        id: 'toast.change_user.email.verify.error',
        defaultMessage: 'An unhandled server error',
      });
    }

    return rejectWithValue(error.response?.data.message);
  }
});

const verifyChangeUserEmailErrors = {
  ALIAS_ALREADY_EXISTS: {
    id: `error.verify.email.alias_already_exists`,
    defaultMessage: 'The email address you entered is already in use.',
  },
  INVALID_VERIFICATION_CODE: {
    id: `error.verify.email.invalid_verification_code`,
    defaultMessage: 'Verification code is invalid.',
  },
  NO_EMAIL_CHANGE_REQUESTED: {
    id: `error.verify.email.no_email_change_requested`,
    defaultMessage: 'You entered the same email.',
  },
};
