import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosWithConfig } from 'api';
import {
  CancelImportProgressRequestParams,
  CancelImportProgressResponse,
  PimcoreErrorResponse,
} from 'types';
import { AxiosError } from 'axios';

export const cancelImportProgress = createAsyncThunk<
  CancelImportProgressResponse,
  CancelImportProgressRequestParams,
  { rejectValue: PimcoreErrorResponse }
>(
  'importProgress/cancelImportProgress',
  async ({ companyId, confirmationId }, { rejectWithValue }) => {
    const endpoint =
      process.env.REACT_APP_PIMCORE_ENDPOINT +
      `api/companies/${companyId}/endusers/bulk-upload/${confirmationId}`;
    const axios = await axiosWithConfig();

    try {
      return (await axios.delete(endpoint)).data;
    } catch (e) {
      const error: AxiosError<PimcoreErrorResponse> = e as AxiosError<PimcoreErrorResponse>;
      const { response } = error;
      const data = response?.data as PimcoreErrorResponse;

      return rejectWithValue(data);
    }
  },
);
