import { useNavigate, useParams } from 'react-router-dom';
import { Typography, Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { useAppDispatch, useAppSelector } from 'store/store';
import { UserManagementActions } from 'features/userManagement/store';
import { AuthenticationActions } from 'features/authentication/store';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import {
  CompaniesNamesResponse,
  CompanyRole,
  DeleteUserModalProps,
  FetchingStatus,
  IUserOverview,
  ListEntries,
  ListType,
} from 'types';

import { AuthenticationSelectors } from 'features/authentication/store/authentication.selectors';
import { useIntl } from 'react-intl';
import { CCPDefaultButton } from 'components';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { useEffect, useMemo, useState } from 'react';
import { axiosWithConfig } from 'api';
import { showToast } from 'utils';
import Loader from 'components/Loader';
import { CompanyActions } from 'features/company/store';
import { default as theme } from '@theme/theme';
import { SettingsSelectors } from '@features/settings/store/settings.selectors';
import { SettingsActions } from '@features/settings/store';

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({ isOpen, email, closeModal }) => {
  const { formatMessage } = useIntl();
  const { language } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data } = useAppSelector(state => state.userManagement.users);
  const { userInfo } = useAppSelector(AuthenticationSelectors.getAuthentication);
  const { companiesNames } = useAppSelector(CompanySelectors.getCompanyState);
  const [showLoading, setShowLoading] = useState<boolean>(companiesNames.data.length > 1);
  const [isDeleteUser, setIsDeleteUser] = useState<boolean>(true);
  const [companyWithLastAdmin, setCompanyWithLastAdmin] = useState<CompaniesNamesResponse | null>(
    null,
  );
  const activationLetterDistributionList = useAppSelector(
    SettingsSelectors.emailActivationLetterDistributionList,
  );
  const { activationLetterRecipientMode } = useAppSelector(SettingsSelectors.settingsSettings);
  const activationLetterDistributionListFetchingStatus = useAppSelector(
    SettingsSelectors.activationLetterDistributionListFetchingStatus,
  );
  const [isLastLetterRecipient, setIsLastLetterRecipient] = useState<boolean>(false);

  useEffect(() => {
    if (companiesNames.data.length === 1) {
      dispatch(SettingsActions.getSettings({ companyId: companiesNames.data[0].companyId }));
      dispatch(
        SettingsActions.getDistributionList({
          companyId: companiesNames.data[0].companyId,
          listType: ListType.ACTIVATION_LETTER,
        }),
      );
    }
  }, [dispatch, companiesNames.data]);

  useEffect(() => {
    const checkLastAdmin = (user: IUserOverview) =>
      user.status === 'Active' && user.role.includes(CompanyRole.Admin);
    const getIsDeletePossibility = async () => {
      if (companiesNames.data.length === 1) {
        let result = true;
        if (userInfo?.role.includes(CompanyRole.Admin) && data) {
          result = data?.filter(user => checkLastAdmin(user)).length > 1;
        }
        if (
          activationLetterRecipientMode.toLowerCase() === 'cu' &&
          activationLetterDistributionList.length === 1 &&
          activationLetterDistributionList[0] === userInfo?.email
        ) {
          result = false;
          setIsLastLetterRecipient(true);
        }

        setCompanyWithLastAdmin(companiesNames.data[0]);
        setIsDeleteUser(result);
        return result;
      } else {
        setShowLoading(true);
        setIsLastLetterRecipient(false);

        const axios = await axiosWithConfig();

        try {
          const resp: {
            value: any;
            status: 'fulfilled' | 'rejected';
          }[] = (
            await Promise.allSettled([
              ...companiesNames.data.map(({ companyId }) =>
                axios.get(`${process.env.REACT_APP_USER_MANAGEMENT_ENDPOINT}getUsers/${companyId}`),
              ),
              ...companiesNames.data.map(({ companyId }) =>
                axios.get(
                  `${process.env.REACT_APP_SETTINGS_ENDPOINT}company/${companyId}/list/${ListType.ACTIVATION_LETTER}`,
                ),
              ),
              ...companiesNames.data.map(({ companyId }) =>
                axios.get(
                  `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/companies/${companyId}/settings`,
                ),
              ),
            ])
          ).map(p => ({ ...p, value: p.status === 'fulfilled' ? p.value : {} }));
          const respLength = resp.length / 3;
          for (let i = 0; i < respLength; i++) {
            if (
              resp[i].status === 'fulfilled' &&
              resp[i].value.data.detail.filter((user: IUserOverview) => checkLastAdmin(user))
                .length <= 1
            ) {
              setCompanyWithLastAdmin(companiesNames.data[i]);
              setShowLoading(false);
              setIsDeleteUser(false);
              return false;
            }
          }
          for (let i = respLength; i < respLength * 2; i++) {
            if (
              resp[i + respLength].status === 'fulfilled' &&
              resp[i + respLength].value.data.activationLetterRecipientMode === 'cu' &&
              resp[i].status === 'fulfilled' &&
              resp[i].value.data.detail.listEntries.length === 1 &&
              resp[i].value.data.detail.listEntries.filter(
                (user: ListEntries) => user.email === userInfo?.email,
              ).length === 1
            ) {
              setCompanyWithLastAdmin(companiesNames.data[i - respLength]);
              setIsLastLetterRecipient(true);
              setShowLoading(false);
              setIsDeleteUser(false);
              return false;
            }
          }

          setShowLoading(false);
          setIsDeleteUser(true);
          return true;
        } catch (err) {
          setShowLoading(false);
          showToast('error', {
            id: 'account.delete_user.error.failed_get_users',
            defaultMessage: 'Failed to receiving users for all companies',
          });
        }

        setIsDeleteUser(true);
        return true;
      }
    };

    getIsDeletePossibility();
  }, [
    companiesNames.data,
    data,
    userInfo?.role,
    userInfo?.email,
    activationLetterRecipientMode,
    activationLetterDistributionList,
  ]);

  const deletedUserInfo = data?.find(user => user.email === email);

  const deleteUserHandler = () => {
    if (isDeleteUser) {
      dispatch(
        UserManagementActions.changeUser({
          companyId: deletedUserInfo?.companyId,
          cognitoId: deletedUserInfo?.cognitoId,
          role: deletedUserInfo?.role,
          status: 'Deleted',
          fullName: `${userInfo?.name} ${userInfo?.surname}`,
          email,
          deleteOwnUser: true,
        }),
      );
      dispatch(AuthenticationActions.signOut());
      navigate(`/${language}/login`);
    } else {
      dispatch(CompanyActions.updateActiveCompany(companyWithLastAdmin));
      navigate(isLastLetterRecipient ? `/${language}/settings/1` : `/${language}/user-management`);
    }
  };

  const modalData = useMemo(() => {
    if (isDeleteUser) {
      return {
        modalTitle: formatMessage({
          id: 'account.alert.delete_your_account.title',
          defaultMessage: 'Delete your account',
        }),
        modalContent: formatMessage({
          id: 'account.alert.delete_your_account.subtitle',
          defaultMessage:
            'Deleting your account will permanently cancel access to the system. This action can’t be undone. Continue?',
        }),
        primaryCta: formatMessage({
          id: 'form.employee.cta.delete_account',
          defaultMessage: 'Yes, I want delete my account',
        }),
      };
    }
    if (isLastLetterRecipient) {
      return {
        modalTitle: formatMessage({
          id: 'account.alert.assign_new_letter_recipient.title',
          defaultMessage: 'Assign new activation letter recipient',
        }),
        modalContent: formatMessage({
          id: 'account.alert.assign_new_letter_recipient.subtitle',
          defaultMessage:
            'You can not remove your account, since you’re only one activation letter recipient in the system. Please, assign new activation letter recipient',
        }),
        primaryCta: formatMessage({
          id: 'account.alert.cta.assign_new_letter_recipient',
          defaultMessage: 'Go to settings',
        }),
      };
    }
    return {
      modalTitle: formatMessage({
        id: 'account.alert.assign_new_administrator.title',
        defaultMessage: 'Assign new administrator',
      }),
      modalContent: formatMessage({
        id: 'account.alert.assign_new_administrator.subtitle',
        defaultMessage: `You can not remove your account, since you’re only one Administrator in the system. Please, assign new Administrator`,
      }),
      primaryCta: formatMessage({
        id: 'form.employee.cta.administration',
        defaultMessage: 'Administration',
      }),
    };
  }, [isDeleteUser, isLastLetterRecipient, formatMessage]);

  return showLoading ||
    activationLetterDistributionListFetchingStatus === FetchingStatus.PENDING ? (
    <Loader show />
  ) : (
    <Dialog open={isOpen} onClose={closeModal}>
      <CloseIcon onClick={closeModal} className='dialog-close' />
      <DialogTitle>
        <Box className='dialog-icon'>
          <ErrorOutlineIcon htmlColor={theme.palette.error.main} />
        </Box>
        <Typography variant='h2'>{modalData.modalTitle}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1'>{modalData.modalContent}</Typography>
      </DialogContent>

      <DialogActions>
        <CCPDefaultButton onClick={closeModal} variant='outlined'>
          {formatMessage({ id: 'form.employee.cta.cancel', defaultMessage: 'Cancel' })}
        </CCPDefaultButton>
        <CCPDefaultButton
          onClick={deleteUserHandler}
          color={isDeleteUser ? 'error' : 'primary'}
          variant='contained'>
          {modalData.primaryCta}
        </CCPDefaultButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUserModal;
