import clsx from 'clsx';
import moment from 'moment';
import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { FiltersProps } from 'types';

import { MonthRangeWrapper } from './MonthPicker.styles';
import { useMonthList } from 'hooks';
import { CCPDefaultButton } from '@components/index';
import { default as theme } from '@theme/theme';

export interface MonthRangeProps {
  filters: FiltersProps;
  changeFilters: (key: string, value: string | number) => void;
  isEndMonthRange?: boolean;
}

const MonthRange: React.FC<MonthRangeProps> = ({
  filters,
  changeFilters,
  isEndMonthRange = false,
}) => {
  const currentYear = moment().year();
  const monthList = useMonthList();
  const yearList = Array.from({ length: 12 }, (_, i) => currentYear + i * -1);
  const yearKey = isEndMonthRange ? 'endYear' : 'startYear';
  const monthKey = isEndMonthRange ? 'endMonth' : 'startMonth';
  const activeYear = isEndMonthRange ? filters.endYear : filters.startYear;
  const activeMonth = isEndMonthRange ? filters.endMonth : filters.startMonth;

  const [isYear, setIsYear] = useState<boolean>(false);

  const openYearPicker = (): void => {
    setIsYear(!isYear);
  };

  const changeYearHandler = (year: number, isOpen: boolean = true): void => {
    changeFilters(yearKey, year);

    if (isOpen) {
      openYearPicker();
    }
  };

  const renderYearsList = yearList.map(year => {
    const endYearPicker = filters.startYear <= year;
    const startYearPicker = filters.endYear >= year;
    const disabled = isEndMonthRange ? endYearPicker : startYearPicker;
    const isValidYear = activeYear === year;

    return (
      <CCPDefaultButton
        key={year}
        variant={isValidYear ? 'contained' : 'text'}
        onClick={() => changeYearHandler(year)}
        className={clsx('list_item', {
          list_item_active: isValidYear || !disabled,
        })}
        disabled={!disabled}>
        {year}
      </CCPDefaultButton>
    );
  });

  const renderMonthsList = monthList.map((month, index) => {
    const isEqualYears = filters.startYear === filters.endYear;
    const endMonthPicker = isEqualYears ? filters.startMonth <= index : true;
    const startMonthPicker = isEqualYears ? filters.endMonth >= index : true;
    const disabled = isEndMonthRange ? endMonthPicker : startMonthPicker;
    const isValidMonth = activeMonth === index;

    return (
      <CCPDefaultButton
        key={month}
        variant={isValidMonth ? 'contained' : 'text'}
        onClick={() => changeFilters(monthKey, index)}
        className={clsx('list_item', {
          list_item_active: isValidMonth || !disabled,
        })}
        disabled={!disabled}>
        {month}
      </CCPDefaultButton>
    );
  });

  return (
    <MonthRangeWrapper>
      <Box className='top'>
        <Box />
        <Box className='top__wrapper' onClick={openYearPicker}>
          <Typography className='top_text' variant='body1'>
            {activeYear}
          </Typography>
          <ArrowDropDownIcon
            htmlColor={theme.palette.neutralDark.contrastText}
            sx={{
              transform: `rotate(${!isYear ? 180 : 0}deg)`,
            }}
          />
        </Box>
        <Box />
      </Box>
      <Box className='list'>{isYear ? renderYearsList : renderMonthsList}</Box>
    </MonthRangeWrapper>
  );
};

export default MonthRange;
