import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosWithConfig } from 'api';
import { AxiosError } from 'axios';

import { showToast } from 'utils';
import { AddUserRequestBody, UserManagementBaseResponse } from 'types';

import { getUsers } from '../getUsers/getUsers';

export const addUser = createAsyncThunk<UserManagementBaseResponse, AddUserRequestBody>(
  'userManagement/addUser',
  async (body, { rejectWithValue, dispatch }) => {
    const endpoint = process.env.REACT_APP_USER_MANAGEMENT_ENDPOINT + 'addUser';
    const axios = await axiosWithConfig();

    try {
      const { data } = await axios.post(endpoint, body);

      dispatch(
        getUsers({
          companyId: body.companyId,
        }),
      );

      return data;
    } catch (e) {
      const error: AxiosError<UserManagementBaseResponse> =
        e as AxiosError<UserManagementBaseResponse>;
      showToast('error', {
        id: 'toast.add_users.error',
        defaultMessage: error.message,
      });
      return rejectWithValue(error.response?.data.message);
    }
  },
);
