import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { AuthenticationActions } from 'features/authentication/store';

export const changeTemporaryPassword = createAsyncThunk<
  CognitoUser,
  { email: string; temporaryPassword: string; newPassword: string }
>(
  'initialPassword/changeTemporaryPassword',
  async ({ email, temporaryPassword, newPassword }, { dispatch, rejectWithValue }) => {
    try {
      const user: CognitoUser = await Auth.signIn(email, temporaryPassword);

      dispatch(AuthenticationActions.resetAuthState());

      return Auth.completeNewPassword(user, newPassword);
    } catch (e) {
      const error: SerializedError = e as SerializedError;
      return rejectWithValue(error.message);
    }
  },
);
