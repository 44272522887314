import { styled } from '@mui/material';

export const EmployeeProfileEditStyled = styled('div')(({ theme }) => ({
  flexGrow: '1',
  '.rightSideHeader': {
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: 14,
    fontWeight: 900,
    lineHeight: 1.57,
    color: theme.palette.neutralDark.contrastText,
    textTransform: 'uppercase',
  },
  '.productChip': {
    padding: '1px 12px',
    borderRadius: 11,
    border: `1px solid  ${theme.palette.neutralLight.dark}`,
    fontSize: 16,
    lineHeight: 1.38,
    color: theme.palette.neutralDark.contrastText,
  },

  '.errorContainer': {
    paddingLeft: '15px',
    maxWidth: '570px',
  },
}));
