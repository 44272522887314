import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import { CompaniesNamesResponse, CompanyState } from 'types';

export const updateActiveCompany: CaseReducer<
  CompanyState,
  PayloadAction<CompaniesNamesResponse | null>
> = (state, { payload }) => {
  state.activeCompany = payload;
  localStorage.setItem('activeCompanyId', payload?.companyId.toString() || 'All companies');
};
