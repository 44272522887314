import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { ContractsState, FetchingStatus } from 'types';
import { getContracts } from './getContracts';

export const getContractsExtraReducer = (builder: ActionReducerMapBuilder<ContractsState>) => {
  builder.addCase(getContracts.pending, state => {
    state.getContracts.fetchingStatus = FetchingStatus.PENDING;
    state.getContracts.data = [];
    state.getContracts.error = null;
  });

  builder.addCase(getContracts.fulfilled, (state, action) => {
    state.getContracts.fetchingStatus = FetchingStatus.FULFILLED;
    state.getContracts.data = action.payload;
  });

  builder.addCase(getContracts.rejected, (state, action) => {
    state.getContracts.fetchingStatus = FetchingStatus.REJECTED;
    state.getContracts.error = action.payload;
  });
};
