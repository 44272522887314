import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { FetchingStatus, SettingsState } from 'types';
import { getOwnUserData } from './getOwnUserData';

export const getOwnUserDataExtraReducer = (builder: ActionReducerMapBuilder<SettingsState>) => {
  builder.addCase(getOwnUserData.pending, state => {
    if (!state.ownUserDataFetchingStatus) {
      state['ownUserDataFetchingStatus'] = FetchingStatus.PENDING;
    } else {
      state.ownUserDataFetchingStatus = FetchingStatus.PENDING;
    }
    state.ownUserData = null;
  });
  builder.addCase(getOwnUserData.fulfilled, (state, action) => {
    state.ownUserDataFetchingStatus = FetchingStatus.FULFILLED;
    state.ownUserData = action.payload.detail;
  });
  builder.addCase(getOwnUserData.rejected, state => {
    state.ownUserDataFetchingStatus = FetchingStatus.REJECTED;
    state.ownUserData = null;
  });
};
