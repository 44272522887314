import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { initialState } from 'features/settings/store/initialState';

import { FetchingStatus, SettingsState } from 'types';
import { getRegistrationSettings } from './getRegistrationSettings';

export const getRegistrationSettingsExtraReducer = (
  builder: ActionReducerMapBuilder<SettingsState>,
) => {
  builder.addCase(getRegistrationSettings.pending, (state, action) => {
    state.selfRegistrationFetching = FetchingStatus.PENDING;
    state.selfRegistrationError = null;
  });
  builder.addCase(getRegistrationSettings.fulfilled, (state, action) => {
    state.selfRegistrationFetching = FetchingStatus.FULFILLED;
    state.selfRegistration = action.payload;
    state.defaultSelfRegistration = action.payload;
  });

  builder.addCase(getRegistrationSettings.rejected, (state, action) => {
    state.selfRegistrationFetching = FetchingStatus.REJECTED;
    state.selfRegistrationError = action.error;
    state.selfRegistration = initialState.selfRegistration;
  });
};
