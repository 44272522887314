import { actions as EmployeeManagementSyncActions } from './employeeManagement.slice';
import { addEmployee } from './actions/async/addEmployee/addEmployee';
import { getEmployees } from './actions/async/getEmployees/getEmployees';
import { changeEmployee } from './actions/async/changeEmployee/changeEmployee';
import { getEmployee } from './actions/async/getEmployee/getEmployee';
import { cancelImportProgress } from './actions/async/cancelImportProgress/cancelImportProgress';
import { createEndUser } from './actions/async/createEndUser/createEndUser';
import { getBulkUploadFile } from './actions/async/getBulkUploadFile/getBulkUploadFile';
import { getEmployeeLicense } from './actions/async/getEmployeeLicense/getEmployeeLicense';
import { changeEmployeeStatus } from './actions/async/changeEmployeeStatus/changeEmployeeStatus';
import { getEmployeeStatus } from './actions/async/getEmployeeStatus/getEmployeeStatus';
import { resendActivationLetter } from './actions/async/resendActivationLetter/resendActivationLetter';
import { resendActivationLetterBulk } from './actions/async/resendActivationLetter/resendActivationLetterBulk';
import { bulkStatusChange } from './actions/async/bulkStatusChange/bulkStatusChange';
import { uploadCsvFile } from './actions/async/uploadCsv/uploadCsvFile';
import { updateCsvFile } from './actions/async/updateCsv/updateCsvFile';

export const EmployeeManagementActions = {
  ...EmployeeManagementSyncActions,
  addEmployee,
  getEmployees,
  getEmployee,
  getEmployeeStatus,
  changeEmployee,
  changeEmployeeStatus,
  createEndUser,
  uploadCsvFile,
  updateCsvFile,
  bulkStatusChange,
  cancelImportProgress,
  getBulkUploadFile,
  getEmployeeLicense,
  resendActivationLetter,
  resendActivationLetterBulk,
};
