import { EmployeeStatus, TransitionStatus } from 'types';

import { StatusWrapper } from './Status.styles';
import StatusComponent from 'components/Status';

export interface EmployeeStatusChipProps {
  status: EmployeeStatus | TransitionStatus;
  openModalToggler?: (userId?: number) => void;
  userId?: number;
  date?: string;
  plannedStatus?: boolean;
}

const EmployeeStatusChip = ({ status, date, plannedStatus }: EmployeeStatusChipProps) => (
  <StatusWrapper>
    <StatusComponent status={status} date={date} plannedStatus={plannedStatus} />
  </StatusWrapper>
);

export default EmployeeStatusChip;
