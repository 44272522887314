import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { useAppSelector } from 'store';
import { FetchingStatus, IUserInfo } from 'types';
import CustomTable from 'components/Table';
import PermissionTableRow from '../Permissions/PermissionTableRow';
import { Spacer } from 'components';
import Loader from 'components/Loader';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';

const tableColumns = [
  { columnName: 'companyName', columnTitle: 'Company name', intlId: 'user.info.company_name' },
  { columnName: 'position', columnTitle: 'Position', intlId: 'user.info.position' },
  { columnName: 'role', columnTitle: 'Permission', intlId: 'user.info.permission' },
  { columnName: 'language', columnTitle: 'Language', intlId: 'user.info.language' },
  { columnName: 'edit', columnTitle: '', intlId: '' },
];

const PermissionSettings: React.FC = () => {
  const intl = useIntl();

  const [tableData, setTableData] = useState<IUserInfo[]>([]);
  const ownUserData = useAppSelector(SettingsSelectors.getOwnUserData);
  const companiesNames = useAppSelector(state => state.company.companiesNames.data);
  const fetchingStatus = useAppSelector(store => store.settings.ownUserDataFetchingStatus);

  useEffect(() => {
    if (ownUserData && companiesNames) {
      const tableData: any = ownUserData.companydetails.map((item: any) => {
        const currentCompany = companiesNames.find(
          comp => comp.companyId.toString() === item.companyId,
        );
        return {
          ...item,
          companyName: currentCompany?.companyName || item.companyName,
          language: currentCompany ? currentCompany.communicationLanguage : '',
        };
      });
      setTableData(tableData);
    }
  }, [ownUserData, companiesNames]);

  const renderRow = (item: IUserInfo) => <PermissionTableRow key={item.companyId} item={item} />;

  const isLoading = fetchingStatus === FetchingStatus.PENDING || tableData.length === 0;

  return (
    <Box position='relative' width='100%' id='permissionsWrapper'>
      <Loader show={isLoading} inCard portalId='permissionsWrapper' />
      <Typography variant='h6'>
        {intl.formatMessage({
          id: 'account.tab.position_and_permission',
          defaultMessage: 'Position & permission',
        })}
      </Typography>
      <Spacer size='md' />
      <Typography variant='subtitle1'>
        {intl.formatMessage({
          id: 'account.tab.position_and_permission.subtitle',
          defaultMessage:
            'Edit company settings like position and view your permissions for the company.',
        })}
      </Typography>
      <Spacer size='md' />
      {tableData.length > 0 && (
        <CustomTable columnsList={tableColumns} listData={tableData} renderRow={renderRow} />
      )}
      <Spacer size='4xl' />
      <Typography variant='h6'>
        {intl.formatMessage({
          id: 'account.tab.position_and_permission.language_title',
          defaultMessage: 'Company commucation language',
        })}
      </Typography>
      <Spacer size='md' />
      <Typography variant='subtitle2'>
        {intl.formatMessage({
          id: 'account.tab.position_and_permission.language_subtitle',
          defaultMessage:
            'The language of the mails is determined by the company communication language and can only be changed by Hansefit.',
        })}
      </Typography>
    </Box>
  );
};

export default PermissionSettings;
