import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { MFAState, PreloginState } from '@store/mfaState/interfaces';

export const setMFAData: CaseReducer<MFAState, PayloadAction<PreloginState>> = (state, action) => {
  state.email = action.payload.email;
  state.timestamp = action.payload.timestamp;
  state.user = action.payload.user;
  state.password = action.payload.password;
};

export const setErrorMFACode: CaseReducer<MFAState, PayloadAction<string>> = (state, action) => {
  state.error = action.payload;
};

export const resetMFAData: CaseReducer<MFAState> = (state, action) => {
  state.user = null;
  state.email = null;
  state.timestamp = null;
  state.password = null;
};
