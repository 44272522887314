import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { BULK_IMPORT_STEPS, EmployeeManagementState, FetchingStatus } from 'types';
import { changeImportingData } from 'utils/bulkUploadFixData';
import { getBulkUploadFile } from './getBulkUploadFile';

const getBulkUploadFileExtraReducer = (
  builder: ActionReducerMapBuilder<EmployeeManagementState>,
) => {
  builder.addCase(getBulkUploadFile.pending, (state, action) => {
    state.api.getBulkUploadFile.fetchingStatus = FetchingStatus.PENDING;
    state.api.getBulkUploadFile.error = null;
  });
  builder.addCase(getBulkUploadFile.fulfilled, (state, action) => {
    const userCompanyData = changeImportingData(
      action.payload?.find(
        item => !item.confirmationId.includes('-result') && item.owner !== 'Hansefit',
      ),
    );
    const hansefitData = changeImportingData(
      action.payload?.find(
        item => !item.confirmationId.includes('-result') && item.owner === 'Hansefit',
      ),
    );
    state.api.getBulkUploadFile.fetchingStatus = FetchingStatus.FULFILLED;
    state.api.getBulkUploadFile.data.companyBulkUpload = userCompanyData || null;
    state.api.getBulkUploadFile.data.hansefitBulkUpload = hansefitData || null;
    state.api.getBulkUploadFile.error = null;
    state.currentStep = userCompanyData
      ? BULK_IMPORT_STEPS.REVIEW_STEP
      : BULK_IMPORT_STEPS.UPLOAD_STEP;
    if (userCompanyData) {
      state.api.bulkImportCsvFile.fetchingStatus = FetchingStatus.FULFILLED;
      state.api.bulkImportCsvFile.data = userCompanyData;
    }
  });

  builder.addCase(getBulkUploadFile.rejected, (state, action) => {
    state.api.getBulkUploadFile.fetchingStatus = FetchingStatus.REJECTED;
    state.api.getBulkUploadFile.error = action.error;
    state.api.getBulkUploadFile.data.companyBulkUpload = null;
    state.api.getBulkUploadFile.data.hansefitBulkUpload = null;
    state.currentStep = BULK_IMPORT_STEPS.UPLOAD_STEP;
  });
};

export default getBulkUploadFileExtraReducer;
