import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { FetchingStatus } from 'types';

import { StatsState } from '../../../interfaces';
import { getCheckIns } from './getCheckIns';

export const getCheckInsExtraReducer = (builder: ActionReducerMapBuilder<StatsState>) => {
  builder.addCase(getCheckIns.pending, (state, action) => {
    state.checkIns.error = null;
    state.checkIns.fetchingStatus = FetchingStatus.PENDING;
  });
  builder.addCase(getCheckIns.fulfilled, (state, action) => {
    state.checkIns.fetchingStatus = FetchingStatus.FULFILLED;
    state.checkIns.error = null;
    state.checkIns.data = action.payload;
  });

  builder.addCase(getCheckIns.rejected, (state, action) => {
    state.checkIns.fetchingStatus = FetchingStatus.REJECTED;
    state.checkIns.error = action.error;
  });
};
