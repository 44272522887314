import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { EmployeeManagementState, FetchingStatus } from 'types';
import { getEmployees } from './getEmployees';

export const getEmployeesExtraReducer = (
  builder: ActionReducerMapBuilder<EmployeeManagementState>,
) => {
  builder.addCase(getEmployees.pending, (state, action) => {
    state.api.getEmployees.fetchingStatus = FetchingStatus.PENDING;
    state.api.getEmployees.error = null;
  });
  builder.addCase(getEmployees.fulfilled, (state, action) => {
    state.api.getEmployees.fetchingStatus = FetchingStatus.FULFILLED;
    state.api.getEmployees.data = action.payload;
    state.api.getEmployees.error = null;
  });

  builder.addCase(getEmployees.rejected, (state, action) => {
    state.api.getEmployees.fetchingStatus = FetchingStatus.REJECTED;
    state.api.getEmployees.error = action.error;
  });
};
