import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToast } from 'utils';
import { axiosWithConfig } from 'api';
import { UserManagementBaseResponse, GetStatsRequestParams, GetStatisticsResponse } from 'types';

export const getStats = createAsyncThunk<GetStatisticsResponse, GetStatsRequestParams>(
  'stats/getStats',
  async ({ companyId, days = 180 }, { rejectWithValue }) => {
    const endpoint = `${process.env.REACT_APP_STATISTICS_ENDPOINT}company/dashboard?companyId=${companyId}&days=${days}`;
    const axios = await axiosWithConfig();

    try {
      const { data } = await axios.get(endpoint);
      const statsList = data?.[Number(companyId)]?.[days] || {};

      return statsList;
    } catch (e) {
      const error: AxiosError<UserManagementBaseResponse> =
        e as AxiosError<UserManagementBaseResponse>;
      if (error.response !== undefined) {
        showToast('error', {
          id: 'toast.stats.get.error',
          defaultMessage: error.message,
        });
      }
      return rejectWithValue(error.response?.data);
    }
  },
);
