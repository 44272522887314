import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosWithConfig } from 'api';
import { AxiosError } from 'axios';

import { SignUpKeyRequestParams, SignUpKeyResponse, UserManagementBaseResponse } from 'types';

export const getSignUpKeyData = createAsyncThunk<SignUpKeyResponse, SignUpKeyRequestParams>(
  'selfRegistration/getSignUpKeyData',
  async ({ companyId, signUpKey }, { rejectWithValue, dispatch }) => {
    const endpoint =
      process.env.REACT_APP_USER_MANAGEMENT_ENDPOINT + `getSignupKeyData/${companyId}/${signUpKey}`;
    const axios = await axiosWithConfig({ withAuthentication: false });

    try {
      return (await axios.get(endpoint)).data;
    } catch (e) {
      const error: AxiosError<UserManagementBaseResponse> =
        e as AxiosError<UserManagementBaseResponse>;

      return rejectWithValue(
        error.response?.data
          ? error.response?.data
          : { statusCode: 0, status: '', message: error.message },
      );
    }
  },
);
