import { phoneRegExp } from 'utils';
import * as yup from 'yup';
import { getBirthDate } from 'utils/getUpdatedDate';

export const EditEmployeeSchema = (maxDate: Date | undefined) =>
  yup
    .object({
      firstName: yup
        .string()
        .required('form.employee.error.first_name.required')
        .matches(/^(?!\s+$).*/, 'schema.add.new_user.first_name.match')
        .max(190, 'employee_management.form.error.firstname.max'),
      lastName: yup
        .string()
        .required('form.employee.error.last_name.required')
        .matches(/^(?!\s+$).*/, 'schema.add.new_user.last_name.match')
        .max(190, 'employee_management.form.error.lastname.max'),
      gender: yup.string().required('form.employee.error.gender.required'),
      dateOfBirth: yup
        .date()
        .typeError('form.employee.error.birthday.invalid')
        .nullable()
        .min(getBirthDate('min'), 'form.employee.error.date_of_birth.invalid')
        .max(getBirthDate('max'), 'form.employee.error.date_of_birth.invalid')
        .required('form.employee.error.birthday.required'),
      startDate: yup
        .date()
        .typeError('form.employee.error.start_date.invalid')
        .nullable()
        .required('form.employee.error.start_date.required')
        .min(new Date(), 'form.employee.error.start_date.past')
        .max(maxDate, 'form.employee.error.start_date.out_of_boundaries'),
      businessEmail: yup.string().trim().email('form.employee.error.corporate_email.invalid'),
      phoneNumber: yup
        .string()
        .nullable()
        .test('isValid', 'form.employee.error.phone.invalid', val => {
          if (val) return phoneRegExp.test(val as string) === true;
          else return true;
        }),
      dialCode: yup
        .string()
        .nullable()
        .test('isRequired', '', (value, ctx) => {
          if (ctx.parent.phoneNumber) return Boolean(value);
          else return true;
        }),
    })
    .required();

export default EditEmployeeSchema;
