import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { EmployeeManagementState, FetchingStatus } from 'types';
import { bulkStatusChange } from './bulkStatusChange';

export const bulkStatusChangeExtraReducer = (
  builder: ActionReducerMapBuilder<EmployeeManagementState>,
) => {
  builder.addCase(bulkStatusChange.pending, (state, action) => {
    state.api.changeEmployeesStatus.fetchingStatus = FetchingStatus.PENDING;
    state.api.changeEmployeesStatus.error = null;
  });
  builder.addCase(bulkStatusChange.fulfilled, (state, action) => {
    state.api.changeEmployeesStatus.fetchingStatus = FetchingStatus.FULFILLED;
    state.api.changeEmployeesStatus.data = action.payload;
    state.api.changeEmployeesStatus.error = null;
  });
  builder.addCase(bulkStatusChange.rejected, (state, action) => {
    state.api.changeEmployeesStatus.fetchingStatus = FetchingStatus.REJECTED;
    state.api.changeEmployeesStatus.error = action.payload;
  });
};
