import { useIntl } from 'react-intl';
import { CompanyCustomFieldType } from 'types';
import * as yup from 'yup';

export const getRequiredError = (field: CompanyCustomFieldType, yupObj: yup.StringSchema) =>
  field.mandatory === 'yes' ? yupObj.required('form.employee.error.custom_field.required') : yupObj;

export const getCustomFieldValidationObject = (field: CompanyCustomFieldType) => {
  if (field.inputType === 'integer')
    return getRequiredError(
      field,
      yup.string().test('is-integer', 'form.employee.error.custom_field.invalid_integer', value => {
        if (value) {
          return /^\d+$/.test(value);
        } else return true;
      }),
    );
  else if (field.inputType === 'float')
    return getRequiredError(
      field,
      yup.string().test('is-decimal', 'form.employee.error.custom_field.invalid_decimal', value => {
        if (value) {
          return /^\d*[.{1}\d*]\d*$/.test(value);
        } else return true;
      }),
    );
  else return getRequiredError(field, yup.string()).nullable();
};

export const useInputType = () => {
  const intl = useIntl();

  return [
    {
      title: intl.formatMessage({
        id: 'form.custom_field.input_type.integer',
        defaultMessage: 'Integer',
      }),
      value: 'integer',
    },
    {
      title: intl.formatMessage({
        id: 'form.custom_field.input_type.string',
        defaultMessage: 'String',
      }),
      value: 'string',
    },
    {
      title: intl.formatMessage({
        id: 'form.custom_field.input_type.float',
        defaultMessage: 'Float',
      }),
      value: 'float',
    },
    {
      title: intl.formatMessage({
        id: 'form.custom_field.input_type.dropdown',
        defaultMessage: 'Drop down',
      }),
      value: 'enum',
    },
  ];
};
