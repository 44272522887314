import { styled } from '@mui/material';

export const LoginStyled = styled('div')(({ theme }) => ({
  '.headerContainer': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  '.error': {
    gap: 12,
    display: 'flex',
    alignItems: 'center',
  },
  '.ctaContainer': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  '.forgotPasswortContainer': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',

    a: {
      textDecoration: 'unset',
      fontFamily: 'Roboto-Medium',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.38,
      color: theme.palette.primary.main,
    },
  },
  '.errorContainer': {
    color: theme.palette.error.main,
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
}));
