import { styled, Typography, Box } from '@mui/material';

export const UserId = styled(Typography)(({ theme }) => ({
  fontSize: 16,

  position: 'relative',
}));

export const NewLabel = styled(Box)(({ theme }) => ({
  textTransform: 'uppercase',
  fontFamily: 'Roboto-Medium',
  fontSize: 11,
  color: theme.palette.success.main,
  backgroundColor: `${theme.palette.success.main}33`,
  textAlign: 'center',
  position: 'absolute',
  transform: 'translate(-50%, -50%)',
  left: 15,
  bottom: 20,
  paddingLeft: 4,
  paddingRight: 4,
  borderRadius: 2,
}));
