import * as yup from 'yup';

export const getEmployeeRequestSchema = (maxDate: Date | undefined) =>
  yup
    .object({
      startDate: yup
        .date()
        .min(new Date(), 'form.employee.error.past_date')
        .max(maxDate, 'form.employee.error.start_date.out_of_boundaries')
        .typeError('form.employee.error.start_date.invalid')
        .nullable(),
    })
    .required();
