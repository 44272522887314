import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const DefaultNoResultsOverlayStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  minHeight: '300px',
  background: theme.palette.background.paper,
  '.resetAllBtn': {
    fontSize: 16,
    fontFamily: 'Roboto-Medium',
    color: theme.palette.primary.main,
    padding: 0,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  '.noResultsIcon': {
    height: 72,
    width: 130,
  },
  '.header': {
    fontSize: 16,
    fontFamily: 'Roboto-Medium',
    letterSpacing: 0.15,
    color: theme.palette.neutralDark.contrastText,
  },
  '.additionalInfo': {
    fontSize: 16,
    color: theme.palette.neutralDark.main,
  },
}));
