import { styled, Chip } from '@mui/material';

export const PermissionsChip = styled(Chip)(({ theme }) => ({
  gap: 6,
  display: 'flex',
  alignItems: 'center',
  borderRadius: 11,
  padding: '1px 12px',
  border: `1px solid  ${theme.palette.neutralLight.dark}`,
  height: 'auto',

  span: {
    fontSize: 16,
    lineHeight: 1.38,
    color: theme.palette.neutralDark.contrastText,
    padding: 0,
  },

  svg: {
    marginLeft: '-6px !important',
  },
}));
