import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { changeEmployeeRequestParams, Employee, PimcoreErrorResponse } from 'types';
import { axiosWithConfig } from 'api';

export const changeEmployee = createAsyncThunk<
  Employee,
  changeEmployeeRequestParams,
  { rejectValue: PimcoreErrorResponse }
>('employeeManagement/changeEmployee', async (params, { rejectWithValue }) => {
  const axios = await axiosWithConfig();
  const { employeeId, body } = params;
  const endpoint = process.env.REACT_APP_PIMCORE_ENDPOINT + `api/endusers/${employeeId}`;

  try {
    return (
      await axios.patch(endpoint, body, {
        headers: {
          'content-type': 'application/merge-patch+json',
        },
      })
    ).data;
  } catch (e) {
    const error: AxiosError<PimcoreErrorResponse> = e as AxiosError<PimcoreErrorResponse>;
    const { response } = error;
    const data = response?.data as PimcoreErrorResponse;

    return rejectWithValue(data);
  }
});
