import { createSlice } from '@reduxjs/toolkit';
import { getS3CredentialsCompanyReducer } from './actions/async/getS3CredentialsCompany/getS3CredentialsCompany.reducer';
import { initialState } from './initialState';
import { resetGetS3CredentialsState } from './actions/sync/s3CredentialsManagement.action';
import { getS3CredentialsPartnerReducer } from './actions/async/getS3CredentialsPartner/getS3CredentialsPartner.reducer';

const s3CredentialsSlice = createSlice({
  name: 's3Credentials',
  initialState,
  reducers: {
    resetGetS3CredentialsState,
  },
  extraReducers: builder => {
    getS3CredentialsCompanyReducer(builder);
    getS3CredentialsPartnerReducer(builder);
  },
});

export const { name, actions } = s3CredentialsSlice;
export const s3CredentialsReducer = s3CredentialsSlice.reducer;
