import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { EmployeeManagementState, FetchingStatus } from 'types';
import { changeEmployee } from './changeEmployee';

export const changeEmployeeExtraReducer = (
  builder: ActionReducerMapBuilder<EmployeeManagementState>,
) => {
  builder.addCase(changeEmployee.pending, (state, action) => {
    state.api.changeEmployee.fetchingStatus = FetchingStatus.PENDING;
    state.api.changeEmployee.error = null;
  });
  builder.addCase(changeEmployee.fulfilled, (state, action) => {
    state.api.changeEmployee.fetchingStatus = FetchingStatus.FULFILLED;
    state.api.changeEmployee.data = action.payload;
    state.api.changeEmployee.error = null;

    state.api.getEmployee.data = action.payload;
  });
  builder.addCase(changeEmployee.rejected, (state, action) => {
    state.api.changeEmployee.fetchingStatus = FetchingStatus.REJECTED;
    state.api.changeEmployee.error = action.payload;
  });
};
