import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SetNewPasswordForm } from './interfaces';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import {
  HorizontalSpacer,
  CCPPasswordTextField,
  Spacer,
  StepContainer,
  CCPDefaultButton,
} from 'components';
import { SetNewPasswordSchema } from 'shared/schemas';

export interface SetNewPasswordProps {
  submitCallback: (data: SetNewPasswordForm) => void;
  backCallback: () => void;
}

const SetNewPassword = ({ submitCallback, backCallback }: SetNewPasswordProps) => {
  const intl = useIntl();
  const [firstValidated, setFirstValidated] = useState<boolean>(true);

  const methods = useForm<SetNewPasswordForm>({
    mode: 'all',
    defaultValues: {
      password: '',
      passwordRepeat: '',
    },
    resolver: yupResolver(SetNewPasswordSchema),
  });

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = methods;
  const onSubmit = (data: SetNewPasswordForm) => {
    submitCallback(data);
  };

  const handleBack = useCallback(() => {
    backCallback();
  }, [backCallback]);

  const onError = () => setFirstValidated(true);

  return (
    <StepContainer>
      <Typography variant='body1' className={clsx('hint')}>
        {intl.formatMessage({
          id: 'new_password.hint',
          defaultMessage:
            'Passwords must contain: a minimum of 1 lower case letter, a minimum of 1 upper case letter [A-Z] and a minimum of 1 numeric character. Passwords must be at least 10 characters in length. ',
        })}
      </Typography>
      <Spacer size='xl' />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <CCPPasswordTextField
            label={intl.formatMessage({ id: 'new_password', defaultMessage: 'New password' })}
            name='password'
            control={control}
            isRepeat={false}
          />
          <Spacer size='xl' />
          <CCPPasswordTextField
            label={intl.formatMessage({
              id: 'confirm_password',
              defaultMessage: 'Confirm password',
            })}
            name='passwordRepeat'
            control={control}
            isRepeat
          />
          <Spacer size='2xl' />
          <Box className='ctaContainer'>
            <CCPDefaultButton variant='outlined' color='primary' onClick={handleBack}>
              {intl.formatMessage({ id: 'new_password.back', defaultMessage: 'Back' })}
            </CCPDefaultButton>
            <HorizontalSpacer />
            <CCPDefaultButton
              disabled={firstValidated && !isValid}
              type='submit'
              variant='contained'
              color='primary'>
              {intl.formatMessage({
                id: 'new_password.finalize',
                defaultMessage: 'Finalize registration',
              })}
            </CCPDefaultButton>
          </Box>
        </form>
      </FormProvider>
    </StepContainer>
  );
};

export default SetNewPassword;
