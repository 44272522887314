import { RootState } from 'types';
import { createSelector } from '@reduxjs/toolkit';

const getSelfRegistration = (state: RootState) => state.settings.selfRegistration;
const getOwnUserData = (state: RootState) => state.settings.ownUserData;
const companyCustomFieldList = (state: RootState) => state.settings.companyCustomField;

const activationLetterDistributionList = (state: RootState) =>
  state.settings.distributionLists.ACTIVATION_LETTER.data;

const activationLetterDistributionListFetchingStatus = (state: RootState) =>
  state.settings.distributionLists.ACTIVATION_LETTER.fetchingStatus;

const emailActivationLetterDistributionList = createSelector(
  activationLetterDistributionList,
  list => list.map(item => item.email),
);

const settingsSettings = (state: RootState) => state.settings.settings;

export const SettingsSelectors = {
  getSelfRegistration,
  activationLetterDistributionList,
  emailActivationLetterDistributionList,
  activationLetterDistributionListFetchingStatus,
  getOwnUserData,
  companyCustomFieldList,
  settingsSettings
};
