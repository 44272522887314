import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosWithConfig } from 'api';
import { GetEmployeesRequestParams, GetEmployeesResponse, PimcoreErrorResponse } from 'types';
import { AxiosError } from 'axios';

export const getEmployees = createAsyncThunk<
  GetEmployeesResponse,
  GetEmployeesRequestParams,
  { rejectValue: PimcoreErrorResponse }
>('employeeManagement/getEmployees', async ({ companyId, headers }, { rejectWithValue }) => {
  const endpoint =
    process.env.REACT_APP_PIMCORE_ENDPOINT + `api/companies/${companyId}/endusers/list`;
  const axios = await axiosWithConfig({ headers });

  try {
    let resultData: GetEmployeesResponse = [];

    for (let page = 1, limit = 500; resultData.length % limit === 0; page++) {
      const newData = (await axios.get(endpoint, { params: { limit, page } })).data;
      resultData = [...resultData, ...newData];
      if (resultData.length === 0 || newData.length === 0) {
        break;
      }
    }

    return resultData;
  } catch (e) {
    const error: AxiosError<PimcoreErrorResponse> = e as AxiosError<PimcoreErrorResponse>;
    const { response } = error;
    const data = response?.data as PimcoreErrorResponse;

    return rejectWithValue(data);
  }
});
