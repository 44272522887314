import { FormContainer } from './GuestFormContainer.styles';
import React from 'react';

export interface GuestFormContainerProps {
  children: React.ReactNode;
}

const GuestFormContainer = ({ children }: GuestFormContainerProps) => {
  return <FormContainer>{children}</FormContainer>;
};

export default GuestFormContainer;
