import { createAsyncThunk } from '@reduxjs/toolkit';
import { PimcoreErrorResponse, UploadCsvFileResponse, UploadCsvRequestBody } from 'types';
import { axiosWithConfig } from 'api';
import { AxiosError } from 'axios';

export const updateCsvFile = createAsyncThunk<
  UploadCsvFileResponse,
  { payload: FormData; confirmationId: string | undefined },
  { rejectValue: PimcoreErrorResponse }
>(
  'employeeManagement/updateCsvFile',
  async ({ payload, confirmationId }, { rejectWithValue, getState }) => {
    const currentState: any = getState();
    const companyId =
      currentState.company.activeCompany.companyId ||
      currentState.company.companiesNames.data[0].companyId;
    const endpoint =
      process.env.REACT_APP_PIMCORE_ENDPOINT + `api/companies/${companyId}/endusers/bulk-upload`;
    const axios = await axiosWithConfig();

    try {
      if (confirmationId) {
        await axios.delete(
          `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/companies/${companyId}/endusers/bulk-upload/${confirmationId}`,
        );
      }
      // return (await axios.get(endpoint)).data;

      let response = await axios.post(endpoint, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data;
    } catch (e) {
      const error: AxiosError<PimcoreErrorResponse> = e as AxiosError<PimcoreErrorResponse>;
      const { response } = error;
      const data = response?.data as PimcoreErrorResponse;

      return rejectWithValue(data || { code: 500 });
    }
  },
);
