import { startOfTomorrow } from 'date-fns';
import * as yup from 'yup';

export const PauseEmployeeSchema = (maxEndDate: Date) =>
  yup
    .object({
      startDate: yup
        .date()
        .min(startOfTomorrow(), 'form.employee.error.past_date')
        .test(
          'end_date_before_start_date_test',
          'form.employee.error.end_date_before_start_date',
          function (value) {
            const { endDate } = this.parent;
            if (endDate && value) return value.getTime() < endDate.getTime();
            return true;
          },
        )
        .typeError('form.employee.error.start_date.invalid')
        .nullable()
        .required('form.employee.error.start_date.required'),
      endDate: yup
        .date()
        .max(maxEndDate, 'form.employee.error.end_date_very_big')
        .test(
          'end_date_before_start_date_test',
          'form.employee.error.end_date_before_start_date',
          function (value) {
            const { startDate } = this.parent;
            if (startDate && value) return value.getTime() > startDate.getTime();
            return true;
          },
        )
        .typeError('form.employee.error.end_date.invalid')
        .nullable()
        .required('form.employee.error.end_date.required'),
    })
    .required();

export default PauseEmployeeSchema;
