export const defaultTextfieldError: { [k: string]: any } = {
  'form.employee.error.first_name.required': 'First name is required',
  'form.employee.error.last_name.required': 'Last name is required',
  'form.employee.error.gender.required': 'Gender is required',
  'form.employee.error.birthday.invalid': 'Invalid birthday date format',
  'form.employee.error.birthday.required': 'Birthday date is required',
  'form.employee.error.start_date.invalid': 'Invalid start date format',
  'form.employee.error.start_date.required': 'Start date is required',
  'form.employee.error.last_date.invalid': 'Invalid last date format',
  'form.employee.error.last_date.required': 'Last date is required',
  'form.employee.error.corporate_email.required': 'Corporate email is required',
  'form.employee.error.corporate_email.invalid': 'Invalid corporate email format',
  'form.employee.error.remove_checkbox.invalid': 'The confirmation is mandatory.',
  'form.employee.error.confirm_fees.invalid': 'The confirmation is mandatory.',
  'form.employee.error.phone.required': 'Phone number is required',
  'form.employee.error.phone.invalid': 'Invalid phone number format',
  'form.employee.error.date_of_birth.invalid': 'Invalid date of birth format',
  'form.employee.error.date_of_birth.required': 'Date of birth is required',
  'form.employee.error.service_date.invalid': 'Invalid service start date format',
  'form.employee.error.service_date.required': 'Service start date is required',
  'form.employee.error.end_date.invalid': 'Invalid end date format',
  'form.employee.error.end_date.required': 'End date is required',
  'schema.login.error.email.required': 'Email is required',
  'schema.login.error.email.type': 'Invalid email format',
  'schema.login.error.password.required': 'Password is required',
  'schema.login.error.password.min.length': 'Password must be longer',
  'schema.add_personal_info.error.name.required': 'First name is required',
  'schema.add_personal_info.error.surname.required': 'Last name is required',
  'schema.add_personal_info.error.position.required': 'Position is required',
  'schema.add_personal_info.error.email.email': 'Invalid email format',
  'schema.add_personal_info.error.email.required': 'Email is required',
  'schema.set_new_password.error.password.required': 'Password is required',
  'schema.set_new_password.error.password.match': 'Password does not match requirements',
  'schema.set_new_password.error.password_repeat.required': 'Password is required',
  'schema.set_new_password.error.password_repeat.match': 'Password does not match requirements',
  'schema.set_new_password.error.password_repeat.not_same': 'Passwords do not match',
  'schema.login.error.password.min.characters': 'Password must be at least 6 characters',
  'schema.set_new_password.error.new_password.required': 'New password is required',
  'schema.login.error.new_password.min.characters': 'New password must be at least 6 characters',
  'schema.set_new_password.error.confirm_password.required': 'Confirm password is required',
  'schema.login.error.confirm_password.min.characters':
    'Confirm password must be at least 6 characters',
  'schema.new_password.error.password_repeat.not_same': 'New passwords do not match',
  user_password: 'User password',
  password_changed: 'has been changed',
  NotAuthorizedException: 'Incorrect username or password',
  LimitExceededException: 'Attempt limit exceeded, please try after some time.',
  'schema.add.new_user.first_name.min': 'First name must be at least 3 characters',
  'schema.add.new_user.first_name.max': 'First name must not exceed 12 characters',
  'schema.add.new_user.first_name.match':
    'Entered value cant start/end or contain only white spacing',
  'schema.add.new_user.last_name.min': 'Last name must be at least 3 characters',
  'schema.add.new_user.last_name.max': 'Last name must not exceed 12 characters',
  'schema.add.new_user.last_name.match':
    'Entered value cant start/end or contain only white spacing',
  'schema.add.error.checkbox': 'Please check one checkbox',
  'form.employee.error.email.same_email': 'The new email is your current email.',
  'employee_management.form.error.firstname.is_not_unique_name_and_birthdate':
    'First name is not unique name',
  'schema.custom_filed.field_name': 'Custom field name is required',
  'settings.self_registration.customize.registration_form.error.form_name.required':
    'Page title is required',
  'form.employee.error.end_date_before_start_date': 'End date before start date',
  'form.employee.error.end_date_very_big': 'End date must be less then 3 years in the future',
  'form.employee.error.start_date_very_big': 'Start date must be earlier',
  'settings.self_registration.customize.registration_form.error.welcome_text.exceeds_max':
    'Text must not exceed 70 characters',
  'employee_management.form.error.firstname.max': 'First name must not exceed 190 characters',
  'employee_management.form.error.lastname.max': 'Last name must not exceed 190 characters',
  'form.employee.error.custom_field.required': 'This field is required',
  'form.employee.error.past_date': 'Date cannot be in the past',
  'form.employee.error.start_date.past': 'Start date cannot be in the past',
  'schema.custom_filed.data_type': 'Data type is required',
  'form.employee.error.custom_field.invalid_integer': 'Invalid integer',
  'form.employee.error.custom_field.invalid_decimal': 'Invalid decimal',
  'employee_management.form.error.customfield.required_custom_fields_are_not_set':
    'This field is required',
  'form.employee.error.start_date.out_of_boundaries': 'The start date is out of boundaries',
  'schema.custom_filed.enum_field_name.empty': 'Field value must be filled',
  'schema.custom_filed.enum_field_name.duplicate': 'Duplicated field value',
};
