import { createSlice } from '@reduxjs/toolkit';
import { FetchingStatus } from 'types';
import { addCompanyAssetsReducer } from './actions/async/addCompanyAssets/addCompanyAssets.reducer';
import { removeCompanyAssetsReducer } from './actions/async/removeCoompanyAssets.ts/removeCompanyAssets.reducer';
import { uploadAssetsState } from './interfaces';

const initialValue: uploadAssetsState = {
  filesInprogress: 0,
  status: FetchingStatus.IDLE,
  error: null,
};

const uploadAssetsSlice = createSlice({
  name: 'uploadAssets',
  initialState: initialValue,
  reducers: {},
  extraReducers: builder => {
    addCompanyAssetsReducer(builder);
    removeCompanyAssetsReducer(builder);
  },
});

export const { name, actions } = uploadAssetsSlice;
export const uploadAssetsReducer = uploadAssetsSlice.reducer;
