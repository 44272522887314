import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToast } from 'utils';
import { axiosWithConfig } from 'api';
import { UserManagementBaseResponse } from 'types';

export const updateLanguage = createAsyncThunk<any, any>(
  'settings/updateLanguage',
  async ({ activeLanguage }, { rejectWithValue }) => {
    const endpoint =
      process.env.REACT_APP_TRANSLATE_ENDPOINT +
      `po?language=${activeLanguage}&project=CCP`;

    const axios = await axiosWithConfig({ withAuthentication: false });

    try {
      const { data } = await axios.get(endpoint);

      return data;
    } catch (e) {
      const error: AxiosError<UserManagementBaseResponse> =
        e as AxiosError<UserManagementBaseResponse>;
      showToast('error', {
        id: 'toast.language.update.error',
        defaultMessage: error.message,
      });
      return rejectWithValue(error.response?.data);
    }
  },
);
