import * as yup from 'yup';

const ChangePasswordSchema = yup
  .object({
    currentPassword: yup
      .string()
      .required('schema.login.error.password.required')
      .min(6, 'schema.login.error.password.min.characters'),
    newPassword: yup
      .string()
      .required('schema.set_new_password.error.new_password.required')
      .min(6, 'schema.login.error.new_password.min.characters'),
    confirmNewPassword: yup
      .string()
      .required('schema.set_new_password.error.confirm_password.required')
      .min(6, 'schema.login.error.confirm_password.min.characters')
      .oneOf([yup.ref('newPassword'), null], 'schema.new_password.error.password_repeat.not_same'),
  })
  .required();

export default ChangePasswordSchema;
