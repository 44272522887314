import Spacer from '@components/Spacer';
import { FetchingStatus, ICompanyAsset, S3Data } from '@localTypes/index';
import { Typography } from '@mui/material';
import { Box, Stack, Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@store/index';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorRounded from '@mui/icons-material/ErrorRounded';
import { ReactComponent as InsertPhotoIcon } from 'assets/icons/atoms-icon-image.svg';
import { CCPDefaultButton } from '@components/index';
import MainBlock from './GeneralSettings.styles';
import { MuiColorInput } from 'mui-color-input';
import CustomizationFooter from '../CustomizationFooter';
import { SettingsActions } from '@features/settings/store';
import { S3CredentialsActions } from '@features/shared/s3Credentials/store';
import { getS3Credential } from '@utils/getS3Credential';
import showToast from '@utils/showToast';
import S3 from 'aws-sdk/clients/s3';
import { uploadAssetsParams } from '@features/shared/companyAssets/store/interfaces';
import { addCompanyAssets } from '@features/shared/companyAssets/store/actions/async/addCompanyAssets/addCompanyAssets';
import { removeCompanyAssets } from '@features/shared/companyAssets/store/actions/async/removeCoompanyAssets.ts/removeCompanyAssets';
import Loader from '@components/Loader';
import { default as theme } from '@theme/theme';

const GeneralSettings = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const companyData = useAppSelector(state => state.company.api.getCompany.data);
  const signupPageData = useAppSelector(state => state.settings.selfRegistration);
  const fetchingStatus = useAppSelector(
    state => state.settings.updateGeneralSettings.fetchingStatus,
  );

  const logoInput = useRef<HTMLInputElement | null>(null);
  const [currentLogo, setCurrentLogo] = useState<ICompanyAsset | undefined>(undefined);
  const [logoFile, setLogoFile] = useState<FileList>();
  const [wasLogoRemoved, setWasLogoRemoved] = useState(false);
  const [colorValue, setColorValue] = useState('#00000000');
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (companyData) setCurrentLogo(companyData.logo);
  }, [companyData]);

  useEffect(() => {
    if (signupPageData) setColorValue(signupPageData.signupPageColor);
  }, [signupPageData]);

  const removeLogo = useCallback(() => {
    setCurrentLogo(undefined);
    setWasLogoRemoved(true);
  }, [setCurrentLogo]);

  const chooseFileHandler = () => {
    if (logoInput && logoInput.current) {
      logoInput.current.click();
    }
  };

  const logoUpload = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (logoInput && logoInput.current && logoInput.current.files) {
      setLogoFile(logoInput.current.files);
      if (evt.target.files) {
        setCurrentLogo({
          assetId: null,
          originalFilename: null,
          uri: URL.createObjectURL(evt.target.files[0]).toString(),
        });
      }
    }
  };

  const resetUploadedFile = useCallback(() => {
    if (logoInput && logoInput.current) {
      logoInput.current.value = '';
    }

    setLogoFile(undefined);
    setCurrentLogo(wasLogoRemoved ? undefined : companyData?.logo);
  }, [setLogoFile, setCurrentLogo, logoInput, wasLogoRemoved, companyData]);

  const handleChange = (newColorValue: any) => {
    const newCompanyColor = newColorValue === '#00000000' ? '' : newColorValue;

    setColorValue(newCompanyColor);
    // getCompanyColor(newCompanyColor);
  };

  const getS3Credentials = async (type: string) => {
    if (companyData) {
      try {
        const s3Response = await dispatch(
          S3CredentialsActions.getS3CredentialsCompany({ companyId: companyData.id }),
        ).unwrap();
        return getS3Credential({ data: s3Response, type });
      } catch (err) {
        showToast('error', {
          id: 'toast.settings.error.company_assets.access_denied',
          defaultMessage: 'Can not retrieve credentials to upload files',
        });
        return null;
      }
    }
  };

  const uploadFileToS3 = (credentials: S3Data, files: FileList, type: 'logo' | 'picture') => {
    if (!files || files.length === 0) {
      return;
    }
    if (companyData) {
      const s3 = new S3({
        accessKeyId: credentials.AccessKeyId,
        secretAccessKey: credentials.SecretAccessKey,
        region: 'eu-central-1',
        sessionToken: credentials.SessionToken,
      });
      //FE-1477 : we have regex pattern on the BE side, so for correct naming FE must do the same
      var regex = new RegExp(/[^A-Za-z0-9-_.!*'()]/g);
      Array.from(files).forEach(file => {
        const filename = file.name.replace(regex, '');
        const fr = new FileReader();
        fr.readAsArrayBuffer(file);
        fr.onload = function () {
          const arrayBuffer = fr.result;
          if (arrayBuffer) {
            const blobData = new Blob([arrayBuffer], {
              type: file.type.includes('svg') ? 'image/svg+xml' : 'image/jpeg',
            });
            const params = {
              Bucket: credentials.s3Url?.split('/')[2],
              Key: `assets/Company/${companyData.id}/Images/${
                type[0].toUpperCase() + type.slice(1)
              }/${filename}`,
              Body: blobData,
              // ACL: 'public-read',
              ContentType: file.type.includes('svg') ? 'image/svg+xml' : 'image',
            };
            s3.upload(params, async function (err: Error, data: unknown) {
              if (err) throw err;
              if (data) {
                const body: uploadAssetsParams = {
                  filename,
                  originalFilename: file.name.replace(regex, ''),
                  fileType: type,
                };
                const resp = await dispatch(addCompanyAssets(body));
                if (resp.meta.requestStatus === 'rejected') {
                  resetUploadedFile();
                } else {
                  setLogoFile(undefined);
                }
                setIsUploading(false);
              }
            });
          }
        };
      });
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault && e.preventDefault();
    let data: { signupPageColor: string } | undefined;
    if (logoFile) {
      setIsUploading(true);
    }
    if (companyData?.logo.assetId && wasLogoRemoved && !logoFile) {
      setIsUploading(true);
      await dispatch(removeCompanyAssets({ assetType: 'logo', assetId: companyData.logo.assetId }));
      setIsUploading(false);
    }
    if (logoFile) {
      getS3Credentials(`/Images/Logo/`).then(logoCredentials => {
        if (logoCredentials) {
          uploadFileToS3(logoCredentials, logoFile, 'logo');
        }
      });
    }

    if (colorValue && colorValue !== signupPageData.signupPageColor) {
      data = { ...data, signupPageColor: colorValue.slice(1) };
    } else if (colorValue === '') {
      data = { ...data, signupPageColor: '' };
    }
    if (data) dispatch(SettingsActions.updateGeneralSettings(data));
  };

  const showLoader = useMemo(
    () => isUploading || fetchingStatus === FetchingStatus.PENDING,
    [isUploading, fetchingStatus],
  );

  const resetChanges = useCallback(() => {
    if (companyData) setCurrentLogo(companyData.logo);
    if (signupPageData) setColorValue(signupPageData.signupPageColor);
    setLogoFile(undefined);
    setWasLogoRemoved(false);
  }, [companyData, setCurrentLogo, signupPageData, setColorValue, setLogoFile, setWasLogoRemoved]);

  return (
    <MainBlock id='general-sp-settings'>
      <Loader show={showLoader} inCard portalId='general-sp-settings' />
      <Typography variant='h4'>
        {formatMessage({
          id: 'settings.self_registration.customize.general.title',
          defaultMessage: 'General settings',
        })}
      </Typography>
      <Typography variant='subtitle2'>
        {formatMessage({
          id: 'settings.self_registration.customize.general.subtitle',
          defaultMessage: 'These settings affect both Landing page and Registration form.',
        })}
      </Typography>
      <form onSubmit={onSubmit}>
        <Spacer size='3xl' />
        <Grid container spacing='25px'>
          <Grid item xs={4}>
            <Typography variant='h5'>
              {formatMessage({
                id: 'dashboard__brands.company_logo',
                defaultMessage: 'Company logo',
              })}
            </Typography>
            <Spacer size='md' />
            {currentLogo?.uri ? (
              <Box className='imageWrapper'>
                {currentLogo.uri.slice(currentLogo.uri.lastIndexOf('.') + 1) === 'svg' ? (
                  <object className='imgLogo' data={currentLogo.uri} type='image/svg+xml'>
                    Alternative Text
                  </object>
                ) : (
                  <img className='imgLogo' src={currentLogo.uri} alt='logo' />
                )}
                {currentLogo.assetId && (
                  <DeleteIcon
                    htmlColor={theme.palette.primary.contrastText}
                    className='removeIcon'
                    onClick={removeLogo}
                  />
                )}
              </Box>
            ) : (
              <Box className='logoImage'>
                <InsertPhotoIcon fontSize='inherit' className='editMode' />
              </Box>
            )}
            <Spacer size='lg' />
            <Stack direction='row' alignItems='center' spacing={1}>
              <CCPDefaultButton onClick={chooseFileHandler} variant='outlined'>
                {formatMessage({
                  id: 'dashboard__brands.choose_file',
                  defaultMessage: 'Choose file',
                })}
              </CCPDefaultButton>
              <input
                style={{ display: 'none' }}
                id='logo-button-file'
                ref={logoInput}
                onChange={logoUpload}
                type='file'
                accept='.svg, .png, .jpg'
              />
              {logoFile?.length && (
                <>
                  <Typography variant='subtitle2'>{logoFile[0].name}</Typography>
                  <DeleteIcon className='cursor_hand' onClick={resetUploadedFile} />
                </>
              )}
            </Stack>
            <Spacer size='md' />
            <Box style={{ paddingLeft: '18px' }}>
              <Stack direction='row' alignItems='center' gap='8px' className='allow_format'>
                <ErrorRounded style={{ color: theme.palette.neutralDark.light }} />
                <Typography variant='h5' fontSize={14}>
                  {formatMessage({
                    id: 'dashboard__brands.allowed_formats',
                    defaultMessage: 'Allowed formats:',
                  })}
                </Typography>
              </Stack>
              <label className='allow_format_description'>
                {formatMessage(
                  {
                    id: 'dashboard__brands.logo_allow_extensions',
                    defaultMessage: '{ext1} or {ext2} with transparent background',
                  },
                  {
                    ext1: '.svg, .png',
                    ext2: '.jpg',
                  },
                )}
              </label>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='h5'>
              {formatMessage({
                id: 'dashboard__brands.company_color',
                defaultMessage: 'Company color',
              })}
            </Typography>
            <Spacer size='md' />
            <MuiColorInput
              className='colorPicker'
              name='colorCode'
              value={colorValue}
              label={formatMessage({
                id: 'dashboard__brands.color_code',
                defaultMessage: 'Color code',
              })}
              placeholder={formatMessage({
                id: 'dashboard__brands.color_code_placeholder',
                defaultMessage: 'Select color',
              })}
              format='hex8'
              onChange={handleChange}
              fallbackValue='#00000000'
            />
          </Grid>
        </Grid>
        <CustomizationFooter resetMethod={resetChanges} isValid />
      </form>
    </MainBlock>
  );
};

export default GeneralSettings;
