import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { format } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDialCodes, useGetPhoneNumberValue } from 'utils';

import {
  CCPDatePicker,
  CCPDefaultButton,
  CCPSelect,
  CCPTextField,
  Spacer,
  ModalError,
} from 'components';
import { useAppDispatch, useAppSelector } from 'store';
import { EditEmployeeSchema } from 'shared/schemas';
import { CompanySelectors } from '../../../../company/store/company.selectors';
import { FetchingStatus, ImportedEmployee } from '@localTypes/index';
import { CompanyActions } from '../../../../company/store';
import CustomFields from '../../EmployeeOverview/CustomFields';
import { EditEmployeeForm } from './interfaces';

import { useGendersList } from 'utils/genders';
import { useEmployeeTitles } from 'utils/employeeTitle';
import GlobalCustomFields from '../../EmployeeOverview/GlobalCustomFields';
import {
  getDate,
  checkIsMonthPicker,
  prepareMaxDateForStartPicker,
  prepareMinDateForStartPicker,
} from 'utils/contractDependencies';
import { getCustomFieldValidationObject } from 'utils/customField';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';
import StartDatePicker from '@components/Form/StartDatePicker';
import { getBirthDate } from '@utils/getUpdatedDate';
import { DuplicateButton, PersonalFieldsWrapper } from '../DialogStyled';

interface EditEmployeeProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleEdit: (importedLineId: string, employee: ImportedEmployee, deleteRow?: boolean) => void;
  employee: ImportedEmployee;
  isDelete: boolean;
}

const EditEmployeeModal = ({
  open,
  setOpen,
  employee,
  handleEdit,
  isDelete,
}: EditEmployeeProps) => {
  const { formatMessage } = useIntl();
  const [check, setCheck] = useState<boolean>(isDelete);
  const [duplicatedEmail, setDuplicatedEmail] = useState<string>('');
  const dispatch = useAppDispatch();
  const activeContract = useAppSelector(state => state.contracts.euCreationCondition.data);
  const settingsSettings = useAppSelector(SettingsSelectors.settingsSettings);
  const companyApi = useAppSelector(CompanySelectors.getApi);
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);
  const company = companyApi.getCompany;
  const customFields = useMemo(
    () => company?.data?.customFields || [],
    [company?.data?.customFields],
  );
  const dialCodes = useDialCodes();
  const globalCustomFields = useMemo(
    () => company?.data?.globalCustomFields || [],
    [company?.data?.globalCustomFields],
  );

  const genders = useGendersList();
  const employeeTitles = useEmployeeTitles();

  const schema: any = useMemo(() => {
    let newSchema: any = {};
    let EditEmployeeSchemaShaped = EditEmployeeSchema(prepareMaxDateForStartPicker(activeContract));
    if (settingsSettings && settingsSettings.activationLetterRecipientMode.toLowerCase() === 'eu') {
      EditEmployeeSchemaShaped = EditEmployeeSchemaShaped.shape({
        businessEmail: yup
          .string()
          .trim()
          .required('form.employee.error.corporate_email.required')
          .email('form.employee.error.corporate_email.invalid'),
      });
    }

    const mandatoryCustomField = customFields;
    if (mandatoryCustomField.length) {
      mandatoryCustomField.forEach(field => {
        newSchema[field.key] = getCustomFieldValidationObject(field);
      });
      EditEmployeeSchemaShaped = EditEmployeeSchemaShaped.shape({
        customFields: yup.object().shape({ ...newSchema }),
      });
    }
    const mandatoryGlobalCustomField = globalCustomFields.filter(
      field => field.mandatory === 'yes',
    );
    if (mandatoryGlobalCustomField.length) {
      newSchema = {};
      mandatoryGlobalCustomField.forEach(field => {
        newSchema[field.key] = yup.string().required('form.employee.error.custom_field.required');
      });
      EditEmployeeSchemaShaped = EditEmployeeSchemaShaped.shape({
        globalCustomFields: yup.object().shape({ ...newSchema }),
      });
    }
    return EditEmployeeSchemaShaped;
  }, [customFields, activeContract, globalCustomFields, settingsSettings]);

  const form = useForm<EditEmployeeForm>({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { isValid, errors },
    setError,
    unregister,
    trigger,
    setValue,
    clearErrors,
    watch,
  } = form;
  const [dialCode, phoneNumber] = useGetPhoneNumberValue(employee?.phoneNumber || '');

  const handleClose = useCallback(() => {
    setOpen(false);
    setCheck(false);
    reset();
  }, [setOpen, reset]);

  const onSubmit = useCallback(
    async (data: EditEmployeeForm) => {
      if (duplicatedEmail && data.businessEmail === duplicatedEmail) {
        setError(
          'businessEmail',
          {
            type: 'focus',
            message: formatMessage({
              id: 'form.employee.error.email.duplicated',
              defaultMessage: 'Duplicated Email',
            }),
          },
          { shouldFocus: true },
        );
      } else {
        const editEmployeeRequestBody = {
          ...employee,
          ...data,
          dateOfBirth: format(new Date(data.dateOfBirth as Date), 'yyyy-MM-dd'),
          startDate: format(
            getDate(data.startDate, activeContract?.regularStartDate),
            'yyyy-MM-dd',
          ),
          phoneNumber: data.phoneNumber ? (data.dialCode || '') + data.phoneNumber : '',
          invalid: false,
          isDatabaseDuplicate: false,
          isTemplateDuplicate: false,
          multipleFieldErrors: undefined,
          importedLineId: employee.importedLineId,
          title: data.title || '',
        };

        handleEdit(employee.importedLineId, editEmployeeRequestBody);
        handleClose();
      }
    },
    [
      activeContract?.regularStartDate,
      duplicatedEmail,
      employee,
      handleClose,
      handleEdit,
      formatMessage,
      setError,
    ],
  );

  const onDelete = () => {
    unregister('firstName');
    unregister('lastName');
    unregister('gender');
    unregister('dateOfBirth');
    unregister('startDate');
    unregister('businessEmail');
    unregister('title');
    unregister('phoneNumber');
    unregister('dialCode');
    handleEdit(employee.importedLineId, employee, true);
    handleClose();
  };

  const setDefaultDataToCustomFields = (customFields: any) => {
    const resultCustomFields: any = {};
    for (var key in customFields) {
      if (!customFields[key]) {
        resultCustomFields[key] = '';
      } else {
        resultCustomFields[key] = customFields[key];
      }
    }
    return resultCustomFields;
  };

  const combinationAlreadyExistsErrorTitle = formatMessage({
    id: 'form.employee.error.combination_already_exists',
    defaultMessage: 'A user with this combination already exists.',
  });

  useEffect(() => {
    const setInitialValue = async () => {
      setValue('firstName', employee.firstName ? employee.firstName : '');
      setValue('lastName', employee.lastName ? employee.lastName : '');
      setValue(
        'gender',
        employee.gender && ['male', 'female', 'other', 'no-input'].includes(employee.gender)
          ? employee.gender
          : '',
      );
      setValue(
        'dateOfBirth',
        employee.dateOfBirth ? moment(employee.dateOfBirth.toString()).toISOString() : null,
      );
      setValue(
        'startDate',
        employee.startDate
          ? format(new Date(moment(employee.startDate.toString()).toISOString()), 'yyyy-MM-dd')
          : null,
      );
      setValue('businessEmail', employee.businessEmail ? employee.businessEmail : '');
      setValue(
        'customFields',
        employee.customFields ? setDefaultDataToCustomFields(employee.customFields) : {},
      );
      setValue(
        'globalCustomFields',
        employee.globalCustomFields
          ? setDefaultDataToCustomFields(employee.globalCustomFields)
          : {},
      );
      setValue('phoneNumber', phoneNumber ? phoneNumber : '');
      setValue('dialCode', dialCode ? dialCode : '');
      setValue(
        'title',
        employee.title && ['Dr.', 'Prof.', 'Prof. Dr.'].includes(employee.title)
          ? employee.title
          : '',
      );

      await trigger();
      if (employee.notUniqueCombination) {
        setError('firstName', {
          type: 'custom',
          message: combinationAlreadyExistsErrorTitle,
        });
        setError('lastName', {
          type: 'custom',
          message: combinationAlreadyExistsErrorTitle,
        });
        setError('dateOfBirth', {
          type: 'custom',
          message: combinationAlreadyExistsErrorTitle,
        });
      }

      if (
        (employee.isDatabaseDuplicate || employee.isTemplateDuplicate) &&
        employee.businessEmail
      ) {
        setDuplicatedEmail(employee.businessEmail);
        setError('businessEmail', {
          type: 'focus',
          message: formatMessage({
            id: 'form.employee.error.email.duplicated',
            defaultMessage: 'Duplicated Email',
          }),
        });
      }
    };

    setInitialValue();
  }, [
    setValue,
    employee,
    phoneNumber,
    combinationAlreadyExistsErrorTitle,
    dialCode,
    trigger,
    formatMessage,
    setError,
  ]);

  useEffect(() => {
    setCheck(isDelete);
  }, [isDelete]);

  useEffect(() => {
    if (company.fetchingStatus === FetchingStatus.IDLE && activeCompany?.companyId) {
      dispatch(CompanyActions.getCompany({ companyId: activeCompany?.companyId }));
    }
  }, [dispatch, company, reset, handleClose, activeCompany?.companyId]);

  useEffect(() => {
    const subscription = watch((form, field) => {
      const uniqueFieldChange =
        field.name === 'firstName' || field.name === 'lastName' || field.name === 'dateOfBirth';

      if (field.type === 'change' && uniqueFieldChange) {
        if (errors?.firstName?.message === combinationAlreadyExistsErrorTitle) {
          clearErrors('firstName');
        }
        if (errors?.lastName?.message === combinationAlreadyExistsErrorTitle) {
          clearErrors('lastName');
        }
        if (errors?.dateOfBirth?.message === combinationAlreadyExistsErrorTitle) {
          clearErrors('dateOfBirth');
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [clearErrors, setError, errors, watch, combinationAlreadyExistsErrorTitle]);

  const isFirstNameError = useMemo(
    () => errors?.firstName?.message === combinationAlreadyExistsErrorTitle,
    [combinationAlreadyExistsErrorTitle, errors?.firstName?.message],
  );

  const isLastNameError = useMemo(
    () => errors?.lastName?.message === combinationAlreadyExistsErrorTitle,
    [combinationAlreadyExistsErrorTitle, errors?.lastName?.message],
  );

  const isDateOfBirthError = useMemo(
    () => errors?.dateOfBirth?.message === combinationAlreadyExistsErrorTitle,
    [combinationAlreadyExistsErrorTitle, errors?.dateOfBirth?.message],
  );

  const isDisabledSubmitButton =
    (!isEmpty(errors.firstName) && !isEmpty(errors.lastName) && !isEmpty(errors.dateOfBirth)) ||
    !isValid;

  return (
    <FormProvider {...form}>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll='paper'
        maxWidth='sm'
        component='form'
        onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <Box display='flex'>
            {formatMessage({
              id: 'form.employee.modal.edit.title',
              defaultMessage: 'Edit employee profile',
            })}
            {employee.isDatabaseDuplicate && (
              <DuplicateButton>
                {formatMessage({
                  id: 'form.employee.error.email.duplicated',
                  defaultMessage: 'Duplicated Email',
                })}
              </DuplicateButton>
            )}
          </Box>
        </DialogTitle>
        {isFirstNameError && isLastNameError && isDateOfBirthError && (
          <ModalError
            headerErrorMessage={formatMessage({
              id: 'form.employee.modal.add_new.error_title',
              defaultMessage: 'Are the details correct?',
            })}
            descriptionErrorMessage={formatMessage({
              id: 'form.employee.modal.add_new.error_description',
              defaultMessage:
                'If so, please contact your Hansefit representative to have a user created for you.',
            })}
          />
        )}
        <DialogContent dividers>
          <PersonalFieldsWrapper>
            <CCPSelect
              control={control}
              name='title'
              label={formatMessage({
                id: 'form.employee.field.title.label',
                defaultMessage: 'Title',
              })}
              width={254}>
              {employeeTitles.map(employeeTitle => (
                <MenuItem
                  key={employeeTitle.value}
                  value={employeeTitle.value}
                  selected={employeeTitle.value === getValues('title')}
                  style={{ minHeight: 36 }}>
                  {employeeTitle.title}
                </MenuItem>
              ))}
            </CCPSelect>
            <Spacer height={42} />
            <CCPTextField
              control={control}
              name='firstName'
              required
              label={formatMessage({
                id: 'form.employee.field.first_name.label',
                defaultMessage: 'First name',
              })}
              width={254}
              notShowErrorText={isFirstNameError}
              isWarningError={isFirstNameError}
            />
            <CCPTextField
              control={control}
              name='lastName'
              required
              label={formatMessage({
                id: 'form.employee.field.last_name.label',
                defaultMessage: 'Last name',
              })}
              width={254}
              notShowErrorText={isLastNameError}
              isWarningError={isLastNameError}
            />
            <Spacer height={42} />
            <CCPSelect
              control={control}
              name='gender'
              className='notranslate'
              required
              label={formatMessage({
                id: 'form.employee.field.gender.label',
                defaultMessage: 'Gender',
              })}
              width={254}>
              {genders.map(gender => (
                <MenuItem
                  key={gender.value}
                  value={gender.value}
                  className='notranslate'
                  selected={gender.value === getValues('gender')}>
                  {gender.title}
                </MenuItem>
              ))}
            </CCPSelect>
            <CCPTextField
              control={control}
              name='businessEmail'
              required={
                settingsSettings &&
                settingsSettings.activationLetterRecipientMode.toLowerCase() === 'eu'
              }
              label={formatMessage({
                id: 'form.employee.field.corporate_email.label',
                defaultMessage: 'Corporate email',
              })}
              width={254}
            />
            <Spacer height={42} />
            <CCPDatePicker
              control={control}
              minDate={getBirthDate('min')}
              maxDate={getBirthDate('max')}
              name='dateOfBirth'
              required
              label={formatMessage({
                id: 'form.employee.field.birthday.label',
                defaultMessage: 'Birthday date',
              })}
              width={254}
              notShowErrorText={isDateOfBirthError}
              isWarningError={isFirstNameError && isLastNameError && isDateOfBirthError}
            />
            <StartDatePicker
              control={control}
              name='startDate'
              isMonthPicker={checkIsMonthPicker(activeContract?.regularStartDate)}
              minimumDate={prepareMinDateForStartPicker(activeContract)}
              maximumDate={prepareMaxDateForStartPicker(activeContract)}
              required
              label={formatMessage({
                id: 'form.employee.field.start_date.label',
                defaultMessage: 'Start date',
              })}
              width={254}
            />
            <Spacer height={42} />
            <CCPSelect
              control={control}
              name='dialCode'
              label={`${formatMessage({
                id: 'form.employee.field.dialCode.label',
                defaultMessage: 'Prefix',
              })}`}
              width={104}>
              {dialCodes.map(({ dialCode }) => (
                <MenuItem
                  key={dialCode}
                  value={dialCode}
                  selected={dialCode === getValues('dialCode')}>
                  {dialCode}
                </MenuItem>
              ))}
            </CCPSelect>
            <CCPTextField
              control={control}
              name='phoneNumber'
              label={formatMessage({
                id: 'form.employee.field.phone.label',
                defaultMessage: 'Phone number',
              })}
              width={404}
              type='integer'
            />
          </PersonalFieldsWrapper>
          <Spacer height={31} />
          <GlobalCustomFields isAddressFields />
          <GlobalCustomFields />
          <CustomFields />
          <Spacer height={31} />
          <PersonalFieldsWrapper>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={!isDelete ? () => setCheck(!check) : () => {}}
                  checked={check}
                />
              }
              label={
                <Typography variant='subtitle1' fontWeight='bold'>
                  {formatMessage({
                    id: 'form.employee.checkbox.label',
                    defaultMessage: 'Remove this record',
                  })}
                </Typography>
              }
            />
          </PersonalFieldsWrapper>
        </DialogContent>
        <DialogActions>
          <CCPDefaultButton variant='outlined' onClick={handleClose}>
            {formatMessage({ id: 'form.employee.cta.cancel', defaultMessage: 'Cancel' })}
          </CCPDefaultButton>
          {!check ? (
            <CCPDefaultButton disabled={isDisabledSubmitButton} variant='contained' type='submit'>
              {formatMessage({
                id: 'form.employee.cta.save_changes',
                defaultMessage: 'Save changes',
              })}
            </CCPDefaultButton>
          ) : (
            <CCPDefaultButton onClick={onDelete} variant='contained' color='error' type='submit'>
              {formatMessage({
                id: 'form.employee.cta.confirm',
                defaultMessage: 'Confirm & delete',
              })}
            </CCPDefaultButton>
          )}
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

export default EditEmployeeModal;
