import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { SettingsState } from '../../../interfaces';
import updateMFASetting from './updateMFASetting';
import { FetchingStatus } from '@api/interfaces';

const updateMFASettingExtraReducer = (builder: ActionReducerMapBuilder<SettingsState>) => {
  builder.addCase(updateMFASetting.pending, (state, action) => {
    state.security.mfa.fetchingStatus = FetchingStatus.PENDING;
  });
  builder.addCase(updateMFASetting.fulfilled, (state, action) => {
    state.security.mfa.fetchingStatus = FetchingStatus.FULFILLED;
    state.security.mfa.data = action.payload;
  });

  builder.addCase(updateMFASetting.rejected, (state, action) => {
    state.security.mfa.fetchingStatus = FetchingStatus.REJECTED;
    state.error = action.error;
  });
};

export default updateMFASettingExtraReducer;
