import { styled } from '@mui/material';

export const EmployeeGeneralInfoStyled = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '.twoRowsProfile': {
    display: 'flex',
    background: theme.palette.background.paper,
    width: '100%',
    flexGrow: 1,
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,
    '& .profileLeftSide': {
      display: 'flex',
      flexDirection: 'column',
      width: 234,
      minWidth: 234,
      borderRight: `2px solid ${theme.palette.primaryLight.main}`,
    },
    '& .profileRightSide': {
      padding: 48,
      flex: 1,
      display: 'flex',
    },
  },
}));
