import { IconButton, InputAdornment, TextFieldProps } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DefaultTextField } from '../DefaultTextField.styles';
import { useIntl } from 'react-intl';
import { defaultTextfieldError } from 'utils/defaultErrorMessage';

export interface InputFieldProps {
  label: string;
  name: string;
  control: any;
  disabled?: boolean;
  width?: number | string;
  reset?: boolean;
  type?: 'string' | 'integer' | 'float';
  notShowErrorText?: boolean;
  helperTextId?: string;
  isCustomHelperText?: boolean;
  isWarningError?: boolean;
}

const CCPTextField = ({
  label,
  control,
  name,
  reset,
  width,
  type,
  disabled,
  notShowErrorText = false,
  isCustomHelperText = false,
  isWarningError = false,
  helperTextId,
  ...props
}: InputFieldProps & TextFieldProps) => {
  const intl = useIntl();
  const [isAutoFill, setIsAutoFill] = useState(false);
  const { resetField, setValue, trigger } = useFormContext();
  const handleClear = useCallback(() => {
    resetField(name);
  }, [resetField, name]);

  const handleChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (type === 'integer' && e.currentTarget.value.match(/^[0-9]*$/)) {
        setValue(name, e.currentTarget.value);
        await trigger(name);
      } else if (
        type === 'float' &&
        (e.currentTarget.value.match(/^\d{1,}(\.\d{0,4})?$/) || e.currentTarget.value === '')
      ) {
        setValue(name, e.currentTarget.value);
        await trigger(name);
      } else if (type === 'string' || type === undefined) {
        setValue(name, e.currentTarget.value);

        if (type === 'string') {
          await trigger(name);
        }
      } else {
        setValue(name, e.currentTarget.value.slice(0, -1));
      }
    },
    [trigger, type, name, setValue],
  );

  const properties = useMemo(() => (type ? { onChange: handleChange } : {}), [handleChange, type]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { ref, ...fieldProps } = field;
        const errorText =
          fieldState.error?.message && !notShowErrorText
            ? isCustomHelperText && helperTextId
              ? intl.formatMessage({
                  id: helperTextId,
                  defaultMessage: defaultTextfieldError[helperTextId || ''],
                })
              : intl.formatMessage({
                  id: fieldState.error?.message,
                  defaultMessage:
                    defaultTextfieldError[fieldState.error.message] || fieldState.error.message,
                })
            : undefined;

        return (
          <DefaultTextField
            {...props}
            {...fieldProps}
            inputRef={field.ref}
            disabled={disabled}
            {...properties}
            width={width}
            isWarningError={isWarningError}
            error={!!fieldState.error}
            helperText={errorText && <span data-test-id='validationErrorText'>{errorText}</span>}
            required={false}
            label={label ? `${label}${props.required ? '*' : ''}` : ''}
            data-test-id={`${name}Container`}
            type='text'
            InputLabelProps={{ shrink: isAutoFill || undefined }}
            InputProps={{
              ...props.InputProps,
              ...{
                onAnimationStart: (e: React.AnimationEvent<HTMLDivElement>) => {
                  e.animationName === 'mui-auto-fill' && setIsAutoFill(true);
                },
                onAnimationEnd: (e: React.AnimationEvent<HTMLDivElement>) =>
                  e.animationName === 'mui-auto-fill-cancel' && setIsAutoFill(false),
                onFocus: () => setIsAutoFill(false),
                endAdornment: reset ? (
                  <InputAdornment position='end'>
                    <IconButton onClick={handleClear} edge='end'>
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  props.InputProps?.endAdornment
                ),
              },
            }}
          />
        );
      }}
    />
  );
};

export default CCPTextField;
