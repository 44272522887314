import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { NotificationState, Notification } from 'types';

export const setNotification: CaseReducer<NotificationState, PayloadAction<Notification>> = (
  state,
  { payload },
) => {
  state.data = payload;
};

export const removeNotification: CaseReducer<NotificationState> = state => {
  state.data = null;
};
