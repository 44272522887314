import { styled } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid-pro';

export const CustomToolbarStyled = styled(GridToolbarContainer)(({ theme }) => ({
  '.toolbar-row': {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    borderBottom: `2px solid ${theme.palette.primaryLight.main}`,
    padding: '12px 14px',
    '.columns-badge': {
      position: 'relative',
      display: 'flex',

      '&.hidden-columns:after': {
        content: `''`,
        width: '10px',
        height: '10px',
        display: 'block',
        position: 'absolute',
        background: 'blue',
        borderRadius: '50%',
        right: 0,
      },
    },
  },
  '.grid__button': {
    minWidth: 'auto',
    padding: '7px',
    svg: {
      fontSize: '24px',
    },
  },
  '.gridToolbarItems': {
    display: 'flex',
    alignItems: 'center',
    hr: {
      height: 24,
      width: 2,
      backgroundColor: theme.palette.neutralLight.dark,
    },
  },
}));
