import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { UserModalProps } from 'types/index';
import { useIntl } from 'react-intl';
import { default as theme } from '@theme/theme';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CCPDefaultButton from '@components/Button/Default';
import { useMemo } from 'react';

const BlockUserModal: React.FC<UserModalProps> = ({
  email,
  isOpen,
  fullName,
  isOwnCU,
  handleClose,
  handleSubmit,
}) => {
  const { formatMessage } = useIntl();

  const modalData = useMemo(() => {
    if (isOwnCU) {
      return {
        modalTitle: formatMessage({
          id: 'view.user_management.block_self.title',
          defaultMessage: 'Block my account',
        }),
        modalContent: formatMessage({
          id: 'view.user_management.block_self.text',
          defaultMessage:
            'You are about to block your own account from your company. You will be logged out of the system. Continue?',
        }),
        secondaryCta: formatMessage({
          id: 'view.user_management.block_self.cta.cancel',
          defaultMessage: 'Cancel',
        }),
        primaryCta: formatMessage({
          id: 'view.user_management.block_self.cta.approve',
          defaultMessage: 'Yes, Block',
        }),
      };
    }
    return {
      modalTitle: formatMessage({
        id: 'view.user_management.block_account.title',
        defaultMessage: 'Block user',
      }),
      modalContent: formatMessage(
        {
          id: 'view.user_management.block_account.text',
          defaultMessage: 'Do you realy want to block {name} {emailAddress}  from your company?',
        },
        { name: <b>{fullName}</b>, emailAddress: <b>({email})</b> },
      ),
      secondaryCta: formatMessage({
        id: 'view.user_management.block_account.cta.cancel',
        defaultMessage: 'Cancel',
      }),
      primaryCta: formatMessage({
        id: 'view.user_management.block_account.cta.approve',
        defaultMessage: 'Yes, Block',
      }),
    };
  }, [isOwnCU, formatMessage, fullName, email]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <CloseIcon onClick={handleClose} className='dialog-close' />
      <DialogTitle>
        <Box className='dialog-icon'>
          <ErrorOutlineIcon htmlColor={theme.palette.warning.main} />
        </Box>
        <Typography variant='h2'>{modalData.modalTitle}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1'>{modalData.modalContent}</Typography>
      </DialogContent>
      <DialogActions>
        <CCPDefaultButton onClick={handleClose} variant='outlined'>
          {modalData.secondaryCta}
        </CCPDefaultButton>
        <CCPDefaultButton onClick={handleSubmit} variant='contained'>
          {modalData.primaryCta}
        </CCPDefaultButton>
      </DialogActions>
    </Dialog>
  );
};

export default BlockUserModal;
