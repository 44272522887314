import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { PimcoreErrorResponse, ResendActivationBulkParam } from 'types';
import { axiosWithConfig } from 'api';

import { showToast } from 'utils';

export const resendActivationLetterBulk = createAsyncThunk<
  any,
  ResendActivationBulkParam,
  { rejectValue: PimcoreErrorResponse }
>(
  'employeeManagement/resendActivationLetterBulk',
  async ({ companyId, endUserIds }, { rejectWithValue }) => {
    const axios = await axiosWithConfig();
    const endpoint =
      process.env.REACT_APP_PIMCORE_ENDPOINT +
      `api/companies/${companyId}/endusers/send-activation-bulk`;

    try {
      const { data } = await axios.put(endpoint, { endUserIds });

        showToast('success', {
          id: 'modal.bulk_actions.resend_activation.success',
          defaultMessage: 'Activation letters successefully resent',
        });

      return data;
    } catch (e) {
      const error: AxiosError<PimcoreErrorResponse> = e as AxiosError<PimcoreErrorResponse>;
      const { response } = error;
      const data = response?.data as PimcoreErrorResponse;
      showToast('error', {
        id: 'modal.bulk_actions.resend_activation.error',
        defaultMessage: 'Error',
      });

      return rejectWithValue(data);
    }
  },
);
