import * as yup from 'yup';

const DefaultPasswordSchema = yup
  .object({
    defaultPassword: yup
      .string()
      .required('schema.default_password.error.password.required')
      .min(3, 'schema.default_password.error.password.min.length'),
  })
  .required();

export default DefaultPasswordSchema;
