import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { getInvoices } from './getInvoices';
import { InvoicesState } from 'features/invoices/store/interfaces';
import { FetchingStatus } from 'types';

export const getInvoicesExtraReducer = (builder: ActionReducerMapBuilder<InvoicesState>) => {
  builder.addCase(getInvoices.pending, (state, action) => {
    state.fetchingStatus = FetchingStatus.PENDING;
    state.error = null;
  });
  builder.addCase(getInvoices.fulfilled, (state, action) => {
    state.fetchingStatus = FetchingStatus.FULFILLED;
    state.data = action.payload;
  });

  builder.addCase(getInvoices.rejected, (state, action) => {
    state.fetchingStatus = FetchingStatus.REJECTED;
    state.error = action.error;
  });
};
