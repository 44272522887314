import { Typography, Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { NoDataWrapper } from './EmployeePendingRequests.styles';


const InvoicesNoData = () => {
  const intl = useIntl();
  const boxList = Array.from(Array(3), (item: any, index: number) => index);

  const renderBoxList = boxList.map(item => 
  <Box key={item} className="skeleton__content">
      <Box className="skeleton__content_item gridTop" />
      <Box className="skeleton__content_item gridBottom" />
      <Box className="skeleton__content_item gridSideLeft" />
      <Box className="skeleton__content_item gridSideRight" />
      <Box className="skeleton__content_item gridSideRight" />
  </Box>  
  );

  return (
    <NoDataWrapper>
      <Box className="content">
        <Box className="skeleton">
          <Box className="skeleton__top">
            <Typography className="skeleton__top_text" variant="body1">
                No requests
            </Typography>
          </Box>
          {renderBoxList}
        </Box>
        <Box className="content__info">
          <Typography className="content__title" variant="body1">
            {intl.formatMessage({
              id: 'view.employees.table.no_requests.headline',
              defaultMessage: 'You have no pending requests.',
            })}
          </Typography>
          <Typography className="content__text" variant="body1">
            {intl.formatMessage({
              id: 'view.employees.table.no_requests.content',
              defaultMessage: 'All your requests have been handled',
            })}
          </Typography>
        </Box>
      </Box>
    </NoDataWrapper>
  );
};

export default InvoicesNoData;
