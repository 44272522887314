import { Auth } from 'aws-amplify';
import { AxiosError } from 'axios';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { UserManagementBaseResponse } from 'types';

export const refreshSession = createAsyncThunk<CognitoUser>(
  'authentication/refreshSession',
  async (_, { rejectWithValue }) => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession: any = await Auth.currentSession();

      return cognitoUser.refreshSession(currentSession.refreshToken);
    } catch (e) {
      const error: AxiosError<UserManagementBaseResponse> =
        e as AxiosError<UserManagementBaseResponse>;

      return rejectWithValue(error.message);
    }
  },
);
