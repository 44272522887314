import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { axiosWithConfig } from 'api';
import { ISelfRegistrationUser, PimcoreErrorResponse, RootState } from 'types';

export const addNewEmployeeInvitation = createAsyncThunk<
  any,
  { companyId?: number; firstName: string; lastName: string; businessEmail: string },
  { rejectValue: PimcoreErrorResponse }
>(
  'settings/addNewEmployeeInvitation',
  async ({ companyId, ...body }, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    companyId = companyId || state.company.activeCompany?.companyId;

    const endpoint = `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/companies/sp-token/${companyId}`;

    const axios = await axiosWithConfig();

    try {
      const { data } = await axios.post(endpoint, body);

      return data as ISelfRegistrationUser;
    } catch (e) {
      const error: AxiosError<PimcoreErrorResponse> = e as AxiosError<PimcoreErrorResponse>;
      const { response } = error;
      const data = response?.data as PimcoreErrorResponse;

      return rejectWithValue(data);
    }
  },
);
