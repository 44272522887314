import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { setMFAData, resetMFAData, setErrorMFACode } from './actions/sync/mfa.actions';

const MFAStateSlice = createSlice({
  name: 'MFAState',
  initialState,
  reducers: {
    setMFAData,
    resetMFAData,
    setErrorMFACode,
  },
});

export const { name, actions } = MFAStateSlice;
export const MFAStateReducer = MFAStateSlice.reducer;
