import { Box } from '@mui/material';
import { InvoicesLoadingWrapper } from './Invoices.styles';

const InvoicesLoading = () => {
  const skeletonList = Array.from(Array(9), (_, index: number) => index);

  const renderSkeletons = skeletonList.map(item => <Box className='skeleton__item' key={item} />);

  return (
    <InvoicesLoadingWrapper>
      <Box className='skeleton'>{renderSkeletons}</Box>
    </InvoicesLoadingWrapper>
  );
};

export default InvoicesLoading;
