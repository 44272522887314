import { useAppSelector } from '@store/index';
import { FormProvider, useForm } from 'react-hook-form';
import { format, startOfTomorrow } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  checkIsMonthPicker,
  getDate,
  prepareMaxDateForPicker,
  prepareMinDateForPicker,
} from 'utils/contractDependencies';
import { yupResolver } from '@hookform/resolvers/yup';
import RemoveEmployeeSchema from '@shared/schemas/RemoveEmployeeSchema';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import Spacer from '@components/Spacer';
import CCPDatePicker from '@components/Form/DatePicker';
import CCPDefaultButton from '@components/Button/Default';
import { CCPLabelCheckbox } from '@components/Form/Checkbox/LabelCheckbox';
import { EmployeeStatus, FetchingStatus } from '@localTypes/index';
import Loader from '@components/Loader';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';

type FormDataProps = { startDate: Date | null | string; removeEmployeeWarning: boolean };
type RemoveEmployeeModalProps = {
  employeeTitle?: string | null;
  employeeName: string;
  employeeStatus: EmployeeStatus;
  transitionStartDate?: string | undefined;
  close: () => void;
  onSubmit: ({
    transition,
    startDate,
    endDate,
  }: {
    transition: string;
    startDate: string;
    endDate: string;
  }) => void;
  hasRevert?: boolean;
};

const RemoveEmployeeModal = ({
  employeeTitle,
  employeeName,
  employeeStatus,
  transitionStartDate,
  close,
  onSubmit,
  hasRevert = false,
}: RemoveEmployeeModalProps) => {
  const tomorrow = startOfTomorrow();
  const { formatMessage } = useIntl();
  const activeContract = useAppSelector(state => state.contracts.euCreationCondition.data);
  const fetchingStatus = useAppSelector(
    state => state.employeeManagement.api.changeEmployeeStatus.fetchingStatus,
  );
  const [open, setOpen] = useState(true);

  const isMonthPicker = useMemo(
    () => checkIsMonthPicker(activeContract?.regularStartDate),
    [activeContract?.regularStartDate],
  );

  const hideStartDate = employeeStatus === EmployeeStatus.PENDING;

  const handleClose = useCallback(() => {
    setOpen(false);
    if (close) {
      close();
    }
  }, [close]);

  const handleModalSubmit = (data: FormDataProps) => {
    let startDate = '';
    if (!hideStartDate) {
      startDate = format(
        getDate(data.startDate || null, activeContract?.regularStartDate),
        'yyyy-MM-dd',
      );
    }

    onSubmit({ transition: EmployeeStatus.UNASSIGNED, startDate, endDate: '' });
  };

  const minStartDate = useMemo(
    () => prepareMinDateForPicker(tomorrow, isMonthPicker) || tomorrow,
    [isMonthPicker, tomorrow],
  );

  const methods = useForm<FormDataProps>({
    defaultValues: {
      ...{ removeEmployeeWarning: false },
      ...(hideStartDate
        ? {}
        : {
            startDate: transitionStartDate
              ? new Date(new Date(transitionStartDate).setHours(0, 0, 0, 0))
              : prepareMinDateForPicker(tomorrow, isMonthPicker),
          }),
    },
    mode: 'all',
    resolver: yupResolver(
      RemoveEmployeeSchema(
        minStartDate,
        prepareMaxDateForPicker(activeContract?.endDate, isMonthPicker),
        hideStartDate,
      ),
    ),
  });
  const { control, handleSubmit, formState, watch, trigger } = methods;
  const { isValid } = formState;
  const watchStartDate = watch('startDate');

  useEffect(() => {
    trigger('startDate');
  }, [watchStartDate, trigger]);

  const descriptionLabel = useMemo(
    () =>
      hideStartDate
        ? formatMessage({
            id: 'employee.modal.remove_immediately.content',
            defaultMessage:
              'Select the day on which the employee is to be removed from the company. If the employee would like to use Hansefit again in the future, there may be a registration fee.',
          })
        : !isMonthPicker
        ? formatMessage({
            id: 'employee.modal.remove_flat_license.content',
            defaultMessage: `By clicking on Confirm, the employee will be removed from the employee list on the selected date and the Hansefit membership will be cancelled on this date. All of the employee's data will be irrevocably deleted.`,
          })
        : formatMessage({
            id: 'employee.modal.remove.content',
            defaultMessage: `By clicking on Confirm, the employee will be removed from the employee list on the first day of the selected month and the Hansefit membership will be cancelled on this date. All of the employee's data will be irrevocably deleted.`,
          }),
    [formatMessage, hideStartDate, isMonthPicker],
  );

  const checkboxLabel = useMemo(
    () =>
      hideStartDate
        ? formatMessage({
            id: 'form.employee.field.immediately_remove_checkbox.label',
            defaultMessage: 'I am aware that I cannot undo this action.',
          })
        : formatMessage({
            id: 'form.employee.field.remove_checkbox.label',
            defaultMessage:
              'I am aware that I cannot undo this action. Should the employee take out a Hansefit membership again in the future, another membership fee may be charged.',
          }),
    [formatMessage, hideStartDate],
  );

  const handleRevert = () => {
    onSubmit({ transition: EmployeeStatus.PAUSE_REMOVED, startDate: '', endDate: '' });
  };

  return (
    <>
      <Loader show={fetchingStatus === FetchingStatus.PENDING} />
      <Dialog open={open} onClose={handleClose}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleModalSubmit)}>
            <CloseIcon onClick={handleClose} className='dialog-close' />
            <DialogTitle>
              {formatMessage({
                id: 'employee.modal.remove.title',
                defaultMessage: 'Remove employee from company',
              })}
            </DialogTitle>
            <DialogContent>
              <Typography variant='h3'>
                {employeeTitle ? ` ${employeeTitle} ` : ''}
                {employeeName}
              </Typography>
              <Spacer size='xl' />
              <Typography variant='subtitle1'>{descriptionLabel}</Typography>
              <Spacer size='2xl' />
              {!hideStartDate && (
                <>
                  <Stack direction='row' justifyContent='space-between' spacing={2}>
                    <CCPDatePicker
                      label={`${formatMessage({
                        id: 'form.employee.field.last_date.label',
                        defaultMessage: 'Last date',
                      })}`}
                      name='startDate'
                      control={control}
                      width={254}
                      minDate={prepareMinDateForPicker(tomorrow, isMonthPicker)}
                      maxDate={prepareMaxDateForPicker(activeContract?.endDate, isMonthPicker)}
                      isMonthPicker={isMonthPicker}
                    />
                  </Stack>
                  <Spacer size='xl' />
                </>
              )}
              <CCPLabelCheckbox
                control={control}
                name='removeEmployeeWarning'
                label={checkboxLabel}
              />
            </DialogContent>
            <DialogActions>
              {hasRevert ? (
                <CCPDefaultButton variant='outlined' onClick={handleRevert} color='error'>
                  {formatMessage({
                    id: 'form.employee.cta.revert_termination',
                    defaultMessage: 'Revert termination',
                  })}
                </CCPDefaultButton>
              ) : (
                <CCPDefaultButton variant='outlined' onClick={handleClose}>
                  {formatMessage({ id: 'form.employee.cta.cancel', defaultMessage: 'Cancel' })}
                </CCPDefaultButton>
              )}
              <CCPDefaultButton disabled={!isValid} variant='contained' color='error' type='submit'>
                {formatMessage({
                  id: 'form.employee.cta.confirm_remove',
                  defaultMessage: 'Confirm',
                })}
              </CCPDefaultButton>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default RemoveEmployeeModal;
