import { styled, TableHead } from '@mui/material';

export const TableErrorDataWrapper = styled(TableHead)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  '& .title': {
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: 20,
    lineHeight: 1.5,
    color: theme.palette.primary.darker,
    textTransform: 'uppercase',
    margin: '36px 0 18px 0',
  },
  '& .text': {
    fontSize: 16,
    color: theme.palette.neutralDark.contrastText,
    lineHeight: 1.38,
  },
  '& svg': {
    width: 36,
    height: 36,
  },
}));
