import { Box, styled } from '@mui/material';

import { MenuDrawerProps } from 'types';

export const UserLayoutStyled = styled(Box, {
  shouldForwardProp: prop => prop !== 'isOpen',
})<MenuDrawerProps>(({ isAuthorized }) => ({
  display: 'flex',

  '.box__content': {
    minWidth: `calc(100% - ${isAuthorized ? 300 : 71}px)`,
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    '&__wrapper': {
      flex: 1,
      borderRadius: 9,
      padding: '36px 24px 16px',
      zIndex: 1,
    },
  },
}));
