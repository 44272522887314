import { Typography } from '@mui/material';
import { ReactComponent as SearchResultsIcon } from 'assets/icons/illustrations-search-results.svg';
import { useIntl } from 'react-intl';

import { TableEmptyDataWrapper } from './TableEmptyData.styles';

const TableEmptyData = () => {
  const intl = useIntl();
  return (
    <TableEmptyDataWrapper>
      <SearchResultsIcon />
      <Typography variant="body1" className="text">
        {intl.formatMessage({
          id: 'statistics.empty_data',
          defaultMessage: 'Sorry, with the selected filter, no statistics could be found.',
        })}
      </Typography>
    </TableEmptyDataWrapper>
  );
};

export default TableEmptyData;
