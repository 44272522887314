import { axiosWithConfig } from '@api/index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import showToast from '@utils/showToast';
import { AxiosError } from 'axios';

import { SettingsActions } from 'features/settings/store';
import { EditDistributionListRequestParams, RootState, UserManagementBaseResponse } from 'types';

export const editRecordInDistributionList = createAsyncThunk<{}, EditDistributionListRequestParams>(
  'settings/editRecordInDistributionList',
  async ({ email, oldEmail, listType, description }, { rejectWithValue, dispatch, getState }) => {
    const currentState = getState() as RootState;
    const companyId = currentState.company.activeCompany?.companyId;
    if (email !== oldEmail) {
      const endpoint =
        process.env.REACT_APP_SETTINGS_ENDPOINT + `company/${companyId}/list/${listType}`;
      const axios = await axiosWithConfig();
      try {
        const config = {
          data: {
            email_list: [oldEmail],
          },
        };

        await axios.delete(endpoint, config);
      } catch (e) {
        const error: AxiosError<UserManagementBaseResponse> =
          e as AxiosError<UserManagementBaseResponse>;

        showToast('error', {
          id: 'toast.distribution_list.delete_record.error',
          defaultMessage: error.message,
        });
        return rejectWithValue(error.response?.data);
      }
    }
    const addedResp = await dispatch(
      SettingsActions.addRecordToDistributionList({
        email,
        description,
        listType,
        hideSuccessToast: true,
      }),
    );
    if (addedResp.meta.requestStatus === 'fulfilled') {
      showToast('success', {
        id: 'modal.success.edit_user_in_distribution_list',
        defaultMessage: 'User successfully updated',
      });
    }
  },
);
