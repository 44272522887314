import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { FetchingStatus, MarketingMaterialState } from 'types';
import { addMarketingMaterial } from './addMarketingMaterial';

export const addMarketingMaterialReducer = (
  builder: ActionReducerMapBuilder<MarketingMaterialState>,
) => {
  builder.addCase(addMarketingMaterial.pending, (state, action) => {
    state.api.addMarketingMaterials.fetchingStatus = FetchingStatus.PENDING;
    state.api.addMarketingMaterials.error = null;
  });
  builder.addCase(addMarketingMaterial.fulfilled, (state, action) => {
    state.api.addMarketingMaterials.fetchingStatus = FetchingStatus.FULFILLED;
    state.api.addMarketingMaterials.data = action.payload;
    state.api.addMarketingMaterials.error = null;
    state.api.getMarketingMaterials.data = action.payload;
  });
  builder.addCase(addMarketingMaterial.rejected, (state, action) => {
    state.api.addMarketingMaterials.fetchingStatus = FetchingStatus.REJECTED;
    state.api.addMarketingMaterials.error = action.error;
  });
};
