import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { formatNumber } from 'utils';
import { StatsPageProps } from 'types';

import { useIntl } from 'react-intl';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { CCPDefaultButton, Spacer } from '@components/index';
import theme from '@theme/theme';

const StatsCard = ({ item, count, hasError = false }: StatsPageProps) => {
  const { title, description, link } = item;
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const goToSelectedPage = () => {
    navigate(link);
  };
  const caption = useMemo(
    () =>
      hasError
        ? formatMessage({
            id: 'statistic.card.error',
            defaultMessage: 'Data can’t be loaded',
          })
        : title,
    [hasError, title, formatMessage],
  );

  return (
    <Box className='cards__item'>
      <Box className='cards__item_info'>
        {hasError ? (
          <ErrorOutline htmlColor={theme.palette.error.main} fontSize='large' />
        ) : (
          <Typography className='cards__item_info_title' variant='h2'>
            {formatNumber(count)}
          </Typography>
        )}
        <Typography variant='caption'>{caption}</Typography>
      </Box>
      <Spacer size='2xl' />
      <Typography textAlign='center' variant='h2'>
        {title}
      </Typography>
      <Spacer size='md' />
      <Typography textAlign='center' variant='body1'>
        {description}
      </Typography>
      <Spacer size='xl' />
      {!hasError && (
        <CCPDefaultButton variant='outlined' onClick={goToSelectedPage}>
          {formatMessage({
            id: 'cards.item.info.title_show',
            defaultMessage: 'Show',
          })}
        </CCPDefaultButton>
      )}
    </Box>
  );
};

export default StatsCard;
