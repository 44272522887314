import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosWithConfig } from 'api';
import { AxiosError } from 'axios';
import { BaseErrorResponse, OwnUserData } from 'types';
import { showToast } from 'utils';

export const changeOwnUserData = createAsyncThunk<{ detail: OwnUserData }, OwnUserData>(
  'settings/changeOwnUserData',
  async (body, { rejectWithValue }) => {
    const axios = await axiosWithConfig();
    const endpoint = `${process.env.REACT_APP_USER_MANAGEMENT_ENDPOINT}changeOwnUserData`;
    try {
      const { data } = await axios.put(endpoint, body);
      return data;
    } catch (e) {
      const error: AxiosError<BaseErrorResponse> = e as AxiosError<BaseErrorResponse>;
      showToast('error', {
        id: 'toast.change_user_data',
        defaultMessage: error.message,
      });
      return rejectWithValue(error.response?.data);
    }
  },
);
