import { Box } from '@mui/material';
import { default as theme } from '@theme/theme';

export interface DividerProps {
  marginTop?: number | string;
  marginBottom?: number | string;
  height?: number | string;
  backgroundColor?: string;
}

const Divider = ({
  marginTop = '39px',
  marginBottom = '40px',
  height = '1px',
  backgroundColor = theme.palette.neutralLight.dark,
}: DividerProps) => (
  <Box sx={{ marginTop, marginBottom, height, backgroundColor, display: 'flex', width: '100%' }} />
);

export default Divider;
