import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosWithConfig } from 'api';
import { AxiosError } from 'axios';
import { BaseErrorResponse, OwnUserData } from 'types';
import { showToast } from 'utils';

export const getOwnUserData = createAsyncThunk<{ detail: OwnUserData }>(
  'settings/getOwnUserData',
  async (_, { rejectWithValue }) => {
    const axios = await axiosWithConfig();
    const endpoint = `${process.env.REACT_APP_USER_MANAGEMENT_ENDPOINT}getOwnUserData`;
    try {
      const { data } = await axios.get(endpoint);
      return data;
    } catch (e) {
      const error: AxiosError<BaseErrorResponse> = e as AxiosError<BaseErrorResponse>;
      showToast('error', {
        id: 'toast.own_user.get_data.error',
        defaultMessage: error.message,
      } );
      return rejectWithValue(error.response?.data);
    }
  },
);
