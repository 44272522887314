import ReactRouterPrompt from 'react-router-prompt';
import { useAppSelector } from '@store/index';
import GuardActivationLetters from './Dialogs/GuardActivationLetters';
import { FetchingStatus } from '@api/interfaces';
import { useCallback } from 'react';
const RouterGuard = () => {
  const {
    guardPopups: { activationLetters },
  } = useAppSelector(state => state.globalState);

  const settingsFetchingStatus = useAppSelector(state => state.settings.settings.fetchingStatus);

  const blocker = useCallback(() => {
    if (settingsFetchingStatus !== FetchingStatus.PENDING) {
      return activationLetters;
    }
    return false;
  }, [settingsFetchingStatus, activationLetters]);

  return (
    <ReactRouterPrompt when={blocker}>
      {({ isActive, onConfirm, onCancel }) =>
        isActive && <GuardActivationLetters onConfirm={onConfirm} onCancel={onCancel} />
      }
    </ReactRouterPrompt>
  );
};

export default RouterGuard;
