import { styled, Box } from '@mui/material';

export const BannerWrapper = styled(Box)(({ theme }) => ({
  gap: 16,
  padding: '21px 24px',
  backgroundImage: theme.palette.gradient.light,
  objectFit: 'contain',
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '.info': {
    gap: 16,
    display: 'flex',
    alignItems: 'center',
  },

  '.text': {
    color: theme.palette.primaryLight.main,

    fontSize: 16,
  },
}));
