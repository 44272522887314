import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { FetchingStatus, SettingsState } from 'types';
import { setRegistrationSettings } from './setRegistrationSettings';

export const setRegistrationSettingsExtraReducer = (
  builder: ActionReducerMapBuilder<SettingsState>,
) => {
  builder.addCase(setRegistrationSettings.pending, (state, action) => {
    state.selfRegistrationFetching = FetchingStatus.PENDING;
    state.selfRegistrationError = null;
  });
  builder.addCase(setRegistrationSettings.fulfilled, (state, action) => {
    state.selfRegistrationFetching = FetchingStatus.FULFILLED;
    if (action.payload?.euShare !== undefined) {
      state.selfRegistration.euShare = action.payload.euShare;
    }
    state.defaultSelfRegistration = state.selfRegistration;
  });

  builder.addCase(setRegistrationSettings.rejected, (state, action) => {
    state.selfRegistrationFetching = FetchingStatus.REJECTED;
    state.selfRegistrationError = action.error;
  });
};
