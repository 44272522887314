import {
  ActivationCode,
  CompanyCustomField,
  Invoice,
  Onboarding,
  Security,
  SelfRegistration,
} from '@features/settings/pages/Settings/SettingsTabs';
import { useIntl } from 'react-intl';
import { CompanyRole, SettingsTabsProps, TranslationsKeys } from 'types';

export const useLanguageItems = (): { value: TranslationsKeys; label: string }[] => {
  const intl = useIntl();
  return [
    {
      value: 'de',
      label: intl.formatMessage({ id: 'language.bar.german', defaultMessage: 'German' }),
    },
    {
      value: 'en',
      label: intl.formatMessage({ id: 'language.bar.english', defaultMessage: 'English' }),
    },
  ];
};

export const useUserAccountTabs = () => {
  const intl = useIntl();
  return [
    {
      value: 'account.tab.profile',
      text: intl.formatMessage({
        id: 'account.tab.profile',
        defaultMessage: 'Profile',
      }),
    },
    {
      value: 'account.tab.profile_password',
      text: intl.formatMessage({
        id: 'account.tab.profile_password',
        defaultMessage: 'Password',
      }),
    },
    {
      value: 'account.tab.notifications',
      text: intl.formatMessage({
        id: 'account.tab.notifications',
        defaultMessage: 'Notification',
      }),
    },
    {
      value: 'account.tab.position_and_permission',
      text: intl.formatMessage({
        id: 'account.tab.position_and_permission',
        defaultMessage: 'Position & permission',
      }),
    },
  ];
};

export const useUserSettingsTabs = (): SettingsTabsProps[] => {
  const intl = useIntl();
  return [
    {
      value: 'invoices_email_distribution',
      label: intl.formatMessage({
        id: 'user_setting.invoice_email',
        defaultMessage: 'Invoice email',
      }),
      role: [CompanyRole.Admin, CompanyRole.Invoice],
      component: Invoice,
    },
    {
      value: 'dispatch_of_activation_letter',
      label: intl.formatMessage({
        id: 'user_setting.activation_code',
        defaultMessage: 'Activation code',
      }),
      role: [CompanyRole.Admin, CompanyRole.Usermgmt],
      component: ActivationCode,
    },
    {
      value: 'employee_self_signup',
      label: intl.formatMessage({
        id: 'user_setting.employee',
        defaultMessage: 'Employee self-registration',
      }),
      role: [CompanyRole.Admin, CompanyRole.Usermgmt],
      component: SelfRegistration,
    },
    {
      value: 'new_requests_email_distribution',
      label: intl.formatMessage({
        id: 'user_setting.onboarding_emails',
        defaultMessage: 'Onboarding emails',
      }),
      role: [CompanyRole.Admin, CompanyRole.Usermgmt],
      component: Onboarding,
    },
    {
      value: 'company_custom_fields',
      label: intl.formatMessage({
        id: 'user_setting.company_custom_field',
        defaultMessage: 'Company custom field',
      }),
      role: [CompanyRole.Admin, CompanyRole.Usermgmt],
      component: CompanyCustomField,
    },
    {
      value: 'security',
      label: intl.formatMessage({
        id: 'user_setting.security',
        defaultMessage: 'Security',
      }),
      role: [CompanyRole.Admin, CompanyRole.Usermgmt, CompanyRole.Invoice],
      component: Security,
    },
  ];
};
