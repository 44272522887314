import { Box, styled } from '@mui/material';

export const DownloadButtonWrapper = styled(Box)(({ theme }) => ({
  button: {
    color: theme.palette.neutralDark.main,
  },

  '&:hover': {
    backgroundColor: 'transparent',
  },
}));
