import { createAsyncThunk } from '@reduxjs/toolkit';
import { CompanyPayload, CompanyResponse, PimcoreErrorResponse } from 'types';
import { axiosWithConfig } from 'api';
import { AxiosError } from 'axios';

export const getCompany = createAsyncThunk<
  CompanyResponse,
  CompanyPayload,
  { rejectValue: PimcoreErrorResponse }
>('company/getCompany', async ({ companyId }, { rejectWithValue }) => {
  if (isNaN(companyId)) {
    return {};
  }
  const endpoint = process.env.REACT_APP_PIMCORE_ENDPOINT + `api/companies/${companyId}`;
  const axios = await axiosWithConfig();

  try {
    return (await axios.get(endpoint)).data;
  } catch (e) {
    const error: AxiosError<PimcoreErrorResponse> = e as AxiosError<PimcoreErrorResponse>;
    const { response } = error;
    const data = response?.data as PimcoreErrorResponse;

    return rejectWithValue(data);
  }
});
