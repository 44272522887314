import { Box, Radio, styled } from '@mui/material';
// import { CCPDefaultButton } from 'components';

export const ActivationCodeWrapper = styled(Box)(() => ({
  position: 'relative',
  height: '100%',
}));

export const RadioContainer = styled('div')`
  & .MuiTypography-root {
    font-family: Roboto-Bold;
    font-size: 16px;
    color: black;
  }
`;

export const RadioDescription = styled('div')`
  font-family: Roboto-Regular;
  font-size: 16px;
  color: black;
  margin-left: 32px;
`;
export const StyledRadio = styled(Radio)`
  svg {
    width: 20px;
    height: 20px;
  }
`;
