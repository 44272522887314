import { Box, Typography } from '@mui/material';
import { Spacer } from 'components';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useAppDispatch, useAppSelector } from 'store';
import { EmployeeDetailsStyled } from '../EmployeeDetails/EmployeeDetails.styles';
import { TranslationsKeys } from 'types';
import { CompanyActions } from 'features/company/store';
import {
  countries,
  addressGlobalCustomFieldsSort,
} from '../../EmployeeOverview/GlobalCustomFields/hardcodedData';
import { useIntl } from 'react-intl';

type CustomFieldsProp = {
  data: Record<any, any>;
  isAddressFields?: boolean;
};

export const GlobalCustomFields = ({ data, isAddressFields }: CustomFieldsProp) => {
  const intl = useIntl();
  const [cookies] = useCookies(['language_app']);
  const activeLanguage: TranslationsKeys = cookies.language_app || 'de';
  const company = useAppSelector(CompanySelectors.getApi);
  const companyData = company.getCompany.data;
  const companyDefaultLanguage =
    (companyData?.communicationLanguage.toLocaleLowerCase() as TranslationsKeys) || 'de';

  const companyCustomFields = companyData?.globalCustomFields;

  const renderCustomFields = (fields: { [key: string]: string }) => {
    let customFieldList: JSX.Element[] = [];

    if (companyCustomFields) {
      companyCustomFields.forEach(compField => {
        if (!isAddressFields && !addressGlobalCustomFieldsSort.includes(compField.key))
          customFieldList.push(
            <Box key={compField.key}>
              <Typography variant={'body2'} className={'employeeInfoHeader'}>
                {compField.name[activeLanguage]
                  ? compField.name[activeLanguage]
                  : compField.name['en']
                  ? compField.name['en']
                  : compField.name[companyDefaultLanguage]}
              </Typography>
              <Spacer height={16} />
              <Typography variant={'body2'} className={'infoData'}>
                {compField.key === 'hansefit_country'
                  ? countries.filter(country => country.value === fields[compField.key])[0]?.title
                  : fields[compField.key]}
              </Typography>
              <Spacer height={48} />
            </Box>,
          );
      });
    }

    return customFieldList;
  };

  let addressData = [];
  if (data && isAddressFields) {
    addressData = addressGlobalCustomFieldsSort.map(item => data[item]).filter(item => item);
  }

  return (
    <EmployeeDetailsStyled>
      {data && (
        <>
          {isAddressFields ? (
            addressData.length > 0 ? (
              <Box className={'infoColumn'}>
                <Typography variant={'body2'}>
                  {intl.formatMessage({
                    id: 'form.employee.field.global_address.label',
                    defaultMessage: 'Address',
                  })}
                  :
                </Typography>
                <Typography variant={'body2'} className={'infoData'}>
                  {addressData.join(', ')}
                </Typography>
              </Box>
            ) : null
          ) : (
            renderCustomFields(data)
          )}
        </>
      )}
    </EmployeeDetailsStyled>
  );
};
