import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import { CompanyState } from 'types';
import { initialState } from '../../initialState';

export const resetCompaniesData: CaseReducer<CompanyState, PayloadAction<null>> = state => {
  state.activeCompany = null;
  state.companiesNames.data = initialState.companiesNames.data;
  state.api.getCompany.data = initialState.api.getCompany.data;
};
