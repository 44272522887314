import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { EmployeeManagementState, FetchingStatus } from 'types';
import { createEndUser } from './createEndUser';

export const createEndUserExtraReducer = (
  builder: ActionReducerMapBuilder<EmployeeManagementState>,
) => {
  builder.addCase(createEndUser.pending, (state, action) => {
    state.api.createEndUser.fetchingStatus = FetchingStatus.PENDING;
    state.api.createEndUser.error = null;
  });
  builder.addCase(createEndUser.fulfilled, (state, action) => {
    state.api.createEndUser.fetchingStatus = FetchingStatus.FULFILLED;
    state.api.createEndUser.data = action.payload;
    state.api.createEndUser.error = null;
    state.api.getBulkUploadFile.data.companyBulkUpload = null;
  });
  builder.addCase(createEndUser.rejected, (state, action) => {
    state.api.createEndUser.fetchingStatus = FetchingStatus.REJECTED;
    state.api.createEndUser.error = action.payload;
  });
};
