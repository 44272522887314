import { StatsList } from '@localTypes/stats';
import { useCookies } from 'react-cookie';
import { useIntl } from 'react-intl';

export const useStatsList = () => {
  const intl = useIntl();
  const [cookies] = useCookies(['language_app']);
  const activeLanguage = cookies.language_app || 'de';

  return [
    {
      count: 456,
      title: intl.formatMessage({ id: 'view.stats.checkins', defaultMessage: 'Check-Ins' }),
      name: 'checkIns',
      description: intl.formatMessage({
        id: 'view.stats.checkins.card.description',
        defaultMessage: 'Here you can see the number of check-ins in the selected period.',
      }),
      link: `/${activeLanguage}/stats/check-ins`,
    },
    {
      count: 2456,
      title: intl.formatMessage({
        id: 'view.stats.active_member',
        defaultMessage: 'Active members',
      }),
      name: 'activeMembers',
      description: intl.formatMessage({
        id: 'view.stats.active_members.card.description',
        defaultMessage: 'Here you can see the number of active members in the selected period.',
      }),
      link: `/${activeLanguage}/stats/active-members`,
    },
    {
      count: 3456,
      title: intl.formatMessage({ id: 'view.stats.coupons_used', defaultMessage: 'Coupons used' }),
      name: 'coupons',
      description: intl.formatMessage({
        id: 'view.stats.coupons.card.description',
        defaultMessage: 'Here you can see the number of coupons in the selected period.',
      }),
      link: `/${activeLanguage}/stats/coupon-usage`,
    },
  ] as StatsList[];
};

export const couponUsedRowsList = [
  {
    title: '[Coupon title]',
    provider: '[Service provider/VP name]',
    id: 2302,
    couponUsed: 8203,
  },
  {
    title: '[Coupon title]',
    provider: '[Service provider/VP name]',
    id: 2302,
    couponUsed: 8203,
  },
  {
    title: '[Coupon title]',
    provider: '[Service provider/VP name]',
    id: 2302,
    couponUsed: 8203,
  },
  {
    title: '[Coupon title]',
    provider: '[Service provider/VP name]',
    id: 2302,
    couponUsed: 8203,
  },
  {
    title: '[Coupon title]',
    provider: '[Service provider/VP name]',
    id: 2302,
    couponUsed: 8203,
  },
  {
    title: '[Coupon title]',
    provider: '[Service provider/VP name]',
    id: 2302,
    couponUsed: 8203,
  },
  {
    title: '[Coupon title]',
    provider: '[Service provider/VP name]',
    id: 2302,
    couponUsed: 8203,
  },
  {
    title: '[Coupon title]',
    provider: '[Service provider/VP name]',
    id: 2302,
    couponUsed: 8203,
  },
  {
    title: '[Coupon title]',
    provider: '[Service provider/VP name]',
    id: 2302,
    couponUsed: 8203,
  },
  {
    title: '[Coupon title]',
    provider: '[Service provider/VP name]',
    id: 2302,
    couponUsed: 8203,
  },
  {
    title: '[Coupon title]',
    provider: '[Service provider/VP name]',
    id: 2302,
    couponUsed: 8203,
  },
  {
    title: '[Coupon title]',
    provider: '[Service provider/VP name]',
    id: 2302,
    couponUsed: 8203,
  },
  {
    title: '[Coupon title]',
    provider: '[Service provider/VP name]',
    id: 2302,
    couponUsed: 8203,
  },
  {
    title: '[Coupon title]',
    provider: '[Service provider/VP name]',
    id: 2302,
    couponUsed: 8203,
  },
  {
    title: '[Coupon title]',
    provider: '[Service provider/VP name]',
    id: 2302,
    couponUsed: 8203,
  },
  {
    title: '[Coupon title]',
    provider: '[Service provider/VP name]',
    id: 2302,
    couponUsed: 8203,
  },
];
