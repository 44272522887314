import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { DefaultTextField } from '../TextField/DefaultTextField.styles';
import { ReactElement } from 'react';
import { defaultTextfieldError } from 'utils/defaultErrorMessage';

export interface InputFieldProps {
  label: string;
  name: string;
  control: any;
  children: ReactElement<any, any>[];
  width?: number | string;
  height?: number | string;
  placeholder?: string;
  multiple?: boolean;
  disabled?: boolean;
  className?: string;
  required?: boolean;
}

const CCPSelect = ({
  label,
  control,
  name,
  children,
  width,
  height,
  placeholder,
  multiple,
  disabled,
  className,
  required = false,
}: InputFieldProps) => {
  const intl = useIntl();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <DefaultTextField
          {...field}
          disabled={disabled}
          width={width}
          className={className}
          height={height}
          error={!!fieldState.error}
          helperText={
            fieldState.error?.message ? (
              <span data-test-id='validationErrorText'>
                {intl.formatMessage({
                  id: fieldState.error?.message,
                  defaultMessage:
                    defaultTextfieldError[fieldState.error.message] || fieldState.error.message,
                })}
              </span>
            ) : undefined
          }
          label={label ? `${label}${required ? '*' : ''}` : ''}
          data-test-id={`${name}Container`}
          select
          SelectProps={{
            multiple: !!multiple,
            renderValue: multiple
              ? (selected: unknown) => {
                  const selectedValues = selected as string[];

                  if (selectedValues.length === 0) {
                    return <Typography className='placeholder'>{placeholder}</Typography>;
                  } else if (selectedValues.length === 1) {
                    return selectedValues[0];
                  } else {
                    return intl.formatMessage(
                      {
                        id: 'component.select.multiple_selected',
                        defaultMessage: '{selected} selected',
                      },
                      { selected: selectedValues.length },
                    );
                  }
                }
              : undefined,
            IconComponent: () => (
              <ArrowDropDownIcon className='MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined' />
            ),
          }}>
          {children}
        </DefaultTextField>
      )}
    />
  );
};

export default CCPSelect;
