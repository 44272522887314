import { Typography, Box } from '@mui/material';
import { Spacer } from 'components';
import { useIntl } from 'react-intl';

import { NoDataWrapper } from './Table.styles';

const TableNoData: React.FC<{
  columnsList: { columnName: string; columnTitle: string; intlId?: string }[];
  noDataText?: string;
  noDataHelperText?: string;

  additionalButton?: any;
}> = ({ columnsList, noDataText, noDataHelperText, additionalButton }) => {
  const intl = useIntl();
  const boxList = Array.from(Array(columnsList.length * 7), (item: any, index: number) => index);

  const renderBoxList = boxList.map(item => <Box key={item} className='skeleton__content_item' />);

  return (
    <NoDataWrapper>
      <Box className='skeleton_wrapper_content'>
        <Box className='skeleton'>
          <Box
            className='skeleton__top'
            style={{ gridTemplateColumns: `repeat(${columnsList.length},1fr)` }}>
            {columnsList.map(item => (
              <Typography className='skeleton__top_text' variant='body1' key={`${item.columnName}`}>
                {item.intlId
                  ? intl.formatMessage({
                      id: item.intlId,
                      defaultMessage: item.columnTitle,
                    })
                  : item.columnTitle}
              </Typography>
            ))}
          </Box>
          <Box
            className='skeleton__content'
            style={{ gridTemplateColumns: `repeat(${columnsList.length},1fr)` }}>
            {renderBoxList}
          </Box>
        </Box>
        <Box className='skeleton_wrapper_content__info'>
          <Typography variant='h2'>
            {noDataText
              ? noDataText
              : intl.formatMessage({
                  id: 'settings.email_distribution.no_data.headline',
                  defaultMessage: 'No data to display',
                })}
          </Typography>
          <Spacer size='md' />
          <Typography variant='caption'>
            {noDataHelperText
              ? noDataHelperText
              : intl.formatMessage({
                  id: 'settings.email_distribution.no_data.subheadline',
                  defaultMessage: 'You need to add some records to this list',
                })}
          </Typography>
          <Spacer size='md' />
          {additionalButton}
        </Box>
      </Box>
    </NoDataWrapper>
  );
};

export default TableNoData;
