import { CCPLink, ErrorIcon, Spacer } from 'components';
import React, { useCallback } from 'react';
import { ErrorContainer, Header, Text } from './Error.styles';

export interface ErrorProps {
  header: string;
  text: string;
  ctaText?: string;
  ctaCallback?: () => void;
}

const Error = ({ header, text, ctaText, ctaCallback }: ErrorProps) => {
  const handleCallback = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (ctaCallback) {
        ctaCallback();
      }
      e.preventDefault();
    },
    [ctaCallback],
  );
  return (
    <ErrorContainer>
      <ErrorIcon />
      <Spacer height={20} />
      <Header variant={'body2'}>{header}</Header>
      <Text>{text}</Text>
      {ctaCallback && (
        <>
          <Spacer height={32} />
          <CCPLink href={'#'} onClick={handleCallback}>
            {ctaText}
          </CCPLink>
          <Spacer height={54} />
        </>
      )}
      {!ctaCallback && <Spacer height={112} />}
    </ErrorContainer>
  );
};

export default Error;
