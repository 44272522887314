import { useCallback, useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { FetchingStatus } from 'types';
import { CCPDefaultButton, Spacer, SuccessIcon } from 'components';
import { useIntl } from 'react-intl';
import { useAppSelector } from 'store';
import { EmployeeManagementSelectors } from '../../../../store/employeeManagement.selectors';
import { SettingsSelectors } from 'features/settings/store/settings.selectors';

const SuccessInvitationModal = () => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const handleOpen = useCallback((businessEmail: any) => {
    setOpen(true);
    setEmail(businessEmail);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
    setEmail('');
  }, [setEmail, setOpen]);

  const addedEmployee = useAppSelector(EmployeeManagementSelectors.getAddEmployeeApi);
  const settingsSettings = useAppSelector(SettingsSelectors.settingsSettings);
  const activationLetterDistributionList = useAppSelector(
    store => store.settings.distributionLists.ACTIVATION_LETTER.data,
  );
  const { data, fetchingStatus } = addedEmployee;

  useEffect(() => {
    if (fetchingStatus === FetchingStatus.FULFILLED) {
      handleOpen(data?.businessEmail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleOpen, fetchingStatus]);

  return (
    <Dialog open={open} onClose={handleClose} data-test-id='activationLetterSentModal'>
      <DialogTitle data-test-id='modalTitle'>
        <SuccessIcon />
        <Spacer size='md' />
        {intl.formatMessage({
          id: 'form.end_user.sent',
          defaultMessage: 'Activation letter has been sent',
        })}
      </DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1' data-test-id='modalDescription'>
          {settingsSettings.activationLetterRecipientMode.toLowerCase() === 'cu'
            ? intl.formatMessage({
                id: 'form.end_user.sent_to_cu',
                defaultMessage: 'Activation letter has been sent to the following company users:',
              })
            : intl.formatMessage(
                {
                  id: 'form.end_user.sent_to_eu',
                  defaultMessage: 'Activation letter has been sent to {emailAddress}.',
                },
                { emailAddress: <b>{email}</b> },
              )}{' '}
          <Typography variant='h3'>
            {settingsSettings.activationLetterRecipientMode.toLowerCase() === 'cu' &&
            activationLetterDistributionList
              ? activationLetterDistributionList.map(item => item.email).join(', ') + '.'
              : ''}
          </Typography>
        </Typography>
      </DialogContent>
      <DialogActions>
        <CCPDefaultButton variant='outlined' onClick={handleClose} data-test-id='backToListButton'>
          {intl.formatMessage({ id: 'form.employee.cta.back', defaultMessage: 'Back to list' })}
        </CCPDefaultButton>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessInvitationModal;
