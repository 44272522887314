import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToast } from 'utils';
import { axiosWithConfig } from 'api';
import {
  GetDistributionListRequestParams,
  GetSettingsInvoicesRequestParams,
  ListType,
  RootState,
  UserManagementBaseResponse,
} from 'types';

export const getDistributionList = createAsyncThunk<any, GetDistributionListRequestParams>(
  'settings/getDistributionList',
  async ({ companyId, listType }, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    companyId =
      companyId ||
      (state.company.companiesNames.data.length === 1
        ? state.company.companiesNames.data[0].companyId
        : state.company.activeCompany?.companyId);
    const axios = await axiosWithConfig();

    if (listType) {
      const endpoint =
        process.env.REACT_APP_SETTINGS_ENDPOINT + `company/${companyId}/list/${listType}`;
      try {
        const { data } = await axios.get(endpoint);
        return {
          listTypes: [listType],
          values: [data.detail.listEntries],
          externalValues: [data.detail.listEntriesExternal],
        };
      } catch (e) {
        const error: AxiosError<UserManagementBaseResponse> =
          e as AxiosError<UserManagementBaseResponse>;
        showToast('error', {
          id: 'toast.distribution_list.get_list.error',
          defaultMessage: error.message,
        } );
        return rejectWithValue(error.response?.data);
      }
    } else {
      const listTypes = Object.keys(ListType);
      try {
        const respAxios = await Promise.allSettled(
          listTypes.map(listType =>
            axios.get(
              `${process.env.REACT_APP_SETTINGS_ENDPOINT}company/${companyId}/list/${listType}`,
            ),
          ),
        );

        if (respAxios.some(item => item.status === 'fulfilled')) {
          return {
            listTypes: listTypes,
            values: respAxios.map(resp =>
              resp.status === 'fulfilled' ? resp.value.data.detail.listEntries : null,
            ),
            externalValues: respAxios.map(resp =>
              resp.status === 'fulfilled' ? resp.value.data.detail.listEntriesExternal : null,
            ),
          };
        } else {
          throw {
            message: {
              id: 'settings.distribution_list.custom_error',
              defaultMessage: 'All distribution lists is unavailable!',
            },
            response: { data: 'CUSTOM_ERROR' },
          };
        }
      } catch (e) {
        const error: AxiosError<UserManagementBaseResponse> =
          e as AxiosError<UserManagementBaseResponse>;
        showToast('error', {
          id: 'toast.distribution_list.get_list.error',
          defaultMessage: error.message,
        });
        return rejectWithValue(error.response?.data);
      }
    }
  },
);
