import React, { useCallback, useState, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CCPDatePicker, CCPDefaultButton, Spacer } from 'components';
import { DeactivationEmployeeForm } from './interfaces';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { EmployeeStatus } from 'types';
import { format, startOfTomorrow } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import EmployeeReactivationSchema from 'shared/schemas/EmployeeReactivationSchema';
import { useAppSelector } from 'store';
import {
  getDate,
  checkIsMonthPicker,
  prepareMaxDateForPicker,
  prepareMinDateForPicker,
} from 'utils/contractDependencies';
import StartDatePicker from '@components/Form/StartDatePicker';
import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';

export const DeactivationEmployeeModal: React.FC<{
  employeeTitle?: string | null;
  employeeName?: string;
  transitionType: EmployeeStatus | string;
  hasRevert?: boolean;
  close?: () => void;
  onSubmit?: ({
    transition,
    startDate,
    endDate,
  }: {
    transition: EmployeeStatus | string;
    startDate?: string;
    endDate?: string;
  }) => void;
}> = ({
  employeeTitle,
  employeeName,
  transitionType,
  close = () => {},
  onSubmit = () => {},
  hasRevert = false,
}) => {
  const { formatMessage } = useIntl();
  const activeContract = useAppSelector(state => state.contracts.euCreationCondition.data);
  const [open, setOpen] = useState(true);
  const tomorrow = startOfTomorrow();

  const isMonthPicker = useMemo(
    () => checkIsMonthPicker(activeContract?.regularStartDate),
    [activeContract?.regularStartDate],
  );

  const handleClose = useCallback(() => {
    setOpen(false);
    if (close) {
      close();
    }
  }, [close]);

  const defaultDate = prepareMinDateForPicker(tomorrow, isMonthPicker);

  const methods = useForm<DeactivationEmployeeForm>({
    defaultValues: {
      deactivationDate: defaultDate ? format(defaultDate, 'yyyy-MM-dd') : null,
      reactivationDate: defaultDate ? format(defaultDate, 'yyyy-MM-dd') : null,
      deleteMembershipImmed: false,
    },
    mode: 'all',
    resolver: yupResolver(EmployeeReactivationSchema(transitionType === EmployeeStatus.INACTIVE)),
  });
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = methods;

  const handleModalSubmit = async (data?: DeactivationEmployeeForm) => {
    let date = null;
    if (data) {
      date =
        transitionType === EmployeeStatus.INACTIVE ? data.deactivationDate : data.reactivationDate;
    }
    const startDate = format(getDate(date, activeContract?.regularStartDate), 'yyyy-MM-dd');

    if (onSubmit) {
      onSubmit({ transition: transitionType, startDate });
    }
  };

  const handleRevert = () => {
    onSubmit({ transition: EmployeeStatus.PAUSE_REMOVED, startDate: '', endDate: '' });
  };

  return (
    <>
      {transitionType === EmployeeStatus.INACTIVE && (
        <Dialog open={open} onClose={handleClose}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleModalSubmit)}>
              <CloseIcon onClick={handleClose} className='dialog-close' />
              <DialogTitle>
                {formatMessage({
                  id: 'employee.modal.deactivate.title',
                  defaultMessage: 'Deactivate employee membership',
                })}
              </DialogTitle>

              <DialogContent>
                {/*<ModalError errors={addEmployeeViolationErrors} />*/}
                <Typography variant='h3'>
                  {employeeTitle ? ` ${employeeTitle} ` : ''}
                  {employeeName}
                </Typography>
                <Spacer size='xl' />
                <Typography variant='subtitle1'>
                  {formatMessage({
                    id: 'employee.modal.deactivate.content',
                    defaultMessage:
                      'You will be able to activate membership during three years after end date.',
                  })}
                </Typography>
                <Spacer size='2xl' />
                <CCPDatePicker
                  control={control}
                  name='deactivationDate'
                  minDate={prepareMinDateForPicker(tomorrow, isMonthPicker)}
                  maxDate={prepareMaxDateForPicker(activeContract?.endDate, isMonthPicker)}
                  isMonthPicker={isMonthPicker}
                  label={`${formatMessage({
                    id: 'form.employee.field.select_end_date.label',
                    defaultMessage: 'Select end date',
                  })}`}
                  width={254}
                />
              </DialogContent>

              <DialogActions>
                {hasRevert ? (
                  <CCPDefaultButton variant='outlined' onClick={handleRevert} color='error'>
                    {formatMessage({
                      id: 'form.employee.cta.revert_deactivate',
                      defaultMessage: 'Revert deactivation',
                    })}
                  </CCPDefaultButton>
                ) : (
                  <CCPDefaultButton variant='outlined' onClick={handleClose}>
                    {formatMessage({ id: 'form.employee.cta.cancel', defaultMessage: 'Cancel' })}
                  </CCPDefaultButton>
                )}

                <CCPDefaultButton disabled={!isValid} variant='contained' type='submit'>
                  {formatMessage({
                    id: 'form.employee.cta.confirm_inactivate',
                    defaultMessage: 'Confirm & inactivate',
                  })}
                </CCPDefaultButton>
              </DialogActions>
            </form>
          </FormProvider>
        </Dialog>
      )}

      {transitionType === EmployeeStatus.ACTIVE && (
        <Dialog open={open} onClose={handleClose}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleModalSubmit)}>
              <CloseIcon onClick={handleClose} className='dialog-close' />
              <DialogTitle>
                {formatMessage({
                  id: 'employee.modal.reactivate.title',
                  defaultMessage: 'Reactivate employee membership',
                })}
              </DialogTitle>

              <DialogContent>
                <Typography variant='h3'>
                  {employeeTitle ? ` ${employeeTitle} ` : ''}
                  {employeeName}
                </Typography>
                <Spacer size='xl' />
                <Typography variant='subtitle1'>
                  {formatMessage({
                    id: 'employee.modal.reactivate.content',
                    defaultMessage: 'Would you like to reactivate membership for this employee?',
                  })}
                </Typography>
                <Spacer size='2xl' />
                <StartDatePicker
                  control={control}
                  name='reactivationDate'
                  label={`${formatMessage({
                    id: 'form.employee.field.select_reactivation_date.label',
                    defaultMessage: 'Select reactivation date',
                  })}`}
                  width={254}
                  minimumDate={prepareMinDateForPicker(tomorrow, isMonthPicker)}
                  maximumDate={prepareMaxDateForPicker(activeContract?.endDate, isMonthPicker)}
                  isMonthPicker={isMonthPicker}
                />
              </DialogContent>

              <DialogActions>
                {hasRevert ? (
                  <CCPDefaultButton variant='outlined' onClick={handleRevert} color='error'>
                    {formatMessage({
                      id: 'form.employee.cta.revert_reactivate',
                      defaultMessage: 'Revert reactivation',
                    })}
                  </CCPDefaultButton>
                ) : (
                  <CCPDefaultButton variant='outlined' onClick={handleClose}>
                    {formatMessage({ id: 'form.employee.cta.cancel', defaultMessage: 'Cancel' })}
                  </CCPDefaultButton>
                )}
                <CCPDefaultButton disabled={!isValid} variant='contained' type='submit'>
                  {formatMessage({
                    id: 'form.employee.cta.confirm_reactivate',
                    defaultMessage: 'Confirm & Reactive',
                  })}
                </CCPDefaultButton>
              </DialogActions>
            </form>
          </FormProvider>
        </Dialog>
      )}
    </>
  );
};

export default DeactivationEmployeeModal;
