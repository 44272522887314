import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { FileCategoryRequestParams, FileCategoryType, PimcoreErrorResponse } from 'types';
import { axiosWithConfig } from 'api';

export const getFileCategory = createAsyncThunk<
  FileCategoryType[],
  FileCategoryRequestParams,
  { rejectValue: PimcoreErrorResponse }
>('marketingMaterialsManagement/getFileCategory', async (params, { rejectWithValue }) => {
  const axios = await axiosWithConfig();
  const endpoint = process.env.REACT_APP_PIMCORE_ENDPOINT + `api/company_file_categories`;

  try {
    return (await axios.get(endpoint, { params })).data;
  } catch (e) {
    const error: AxiosError<PimcoreErrorResponse> = e as AxiosError<PimcoreErrorResponse>;
    const { response } = error;
    const data = response?.data as PimcoreErrorResponse;

    return rejectWithValue(data);
  }
});
