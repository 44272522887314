import { Box, styled, Typography } from '@mui/material';

export const ErrorContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const Header = styled(Typography)(({ theme }) => ({
  fontFamily: 'Roboto-Medium',
  fontSize: 16,
  fontWeight: 'bold'
}));

export const Text = styled(Typography)(({ theme }) => ({}));
