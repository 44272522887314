import { getStats } from './actions/async/getStats/getStats';
import { getCoupons } from './actions/async/getCoupons/getCoupons';
import { getCheckIns } from './actions/async/getCheckIns/getCheckIns';
import { getActiveMembers } from './actions/async/getActiveMembers/getActiveMembers';
import { actions as StatsSyncActions } from './stats.slice';

export const StatsActions = {
  ...StatsSyncActions,
  getStats,
  getCoupons,
  getCheckIns,
  getActiveMembers,
};
