import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { EmployeeManagementState, FetchingStatus } from 'types';
import { getEmployeeLicense } from './getEmployeeLicense';

export const getEmployeeLicenseExtraBuilder = (
  builder: ActionReducerMapBuilder<EmployeeManagementState>,
) => {
  builder.addCase(getEmployeeLicense.pending, state => {
    state.api.employeeLicense.fetchingStatus = FetchingStatus.PENDING;
    state.api.employeeLicense.error = null;
  });
  builder.addCase(getEmployeeLicense.fulfilled, (state, action) => {
    state.api.employeeLicense.fetchingStatus = FetchingStatus.FULFILLED;
    state.api.employeeLicense.data = action.payload;
    state.api.employeeLicense.error = null;
  });
  builder.addCase(getEmployeeLicense.rejected, (state, action) => {
    state.api.employeeLicense.fetchingStatus = FetchingStatus.REJECTED;
    state.api.employeeLicense.error = action.error;
  });
};
