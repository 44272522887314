import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { SettingsState } from '../../../interfaces';
import { updateLanguage } from './updateLanguage';

export const updateLanguageExtraReducer = (builder: ActionReducerMapBuilder<SettingsState>) => {
  builder.addCase(updateLanguage.pending, (state, action) => {});
  builder.addCase(updateLanguage.fulfilled, (state, action) => {
    state.translations = action.payload;
  });

  builder.addCase(updateLanguage.rejected, (state, action) => {
    state.error = action.error;
  });
};
