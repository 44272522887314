import { FetchingStatus } from 'types';


export enum INITIAL_PASSWORD_STEPS {
  DEFAULT_PASSWORD,
  SET_NEW_PASSWORD,
}

export interface InitialPasswordState {
  api: {
    changeTemporaryPassword: {
      fetchingStatus: FetchingStatus;
      error: string | null;
    };
  };
  currentStep: INITIAL_PASSWORD_STEPS;
  steps: InitialPasswordSteps;
}

export interface InitialPasswordSteps {
  defaultPasswordStep: DefaultPasswordStep;
  setNewPasswordStep: SetNewPasswordStep;
}

export interface DefaultPasswordStep {
  defaultPassword: string;
}
export interface SetNewPasswordStep {
  email: string;
  newPassword: string;
}
