import { styled, Box, TableBody, TableHead } from '@mui/material';
export const ValidationMessage = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '10px',
  padding: '16px 35px 16px 16px',
  borderRadius: '4px',
  border: `1px solid  ${theme.palette.neutralLight.dark}`,
}));
export const SelfRegistrationWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  '.share': {
    '&__link': {
      gap: 16,
      margin: '24px 0',
      display: 'flex',

      '&_generate': {
        borderRadius: 4,
      },

      '&_wrapper': {
        display: 'flex',
        alignItems: 'center',
        border: `1px solid  ${theme.palette.neutralLight.dark}`,
        padding: '0 0 0 7px',
        width: 'fit-content',
        borderRadius: 4,
        p: {
          maxWidth: '300px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        button: {
          minWidth: 'auto',
          borderRadius: 4,
          color: theme.palette.neutralDark.main,
        },
      },

      // '&_generate': {
      //   fontFamily: 'Roboto-Medium',
      //   fontSize: 16,
      //   fontWeight: 500,
      //   lineHeight: 1.38,
      //   maxWidth: 144,
      //   width: '100%',
      //   textTransform: 'capitalize',
      //   padding: '9px 0',

      //   ':disabled': {
      //     background: theme.palette.primaryLight.main,
      //     color: theme.palette.neutralDark.main,
      //   },
      // },

      '&_code': {
        borderRadius: 18.5,
        width: '100%',
        maxWidth: 'max-content',
        padding: '7px 16px',
        fontFamily: 'Roboto-Bold',
        fontWeight: 'bold',
        fontSize: 16,
        lineHeight: '22px',
        boxShadow: 'unset',
        textTransform: 'capitalize',
      },
    },

    ////end

    '&__wrapper': {
      marginTop: 36,

      '&_radio': {
        gap: 8,
        margin: 0,
        fontFamily: 'Roboto-Bold',
        fontWeight: 'bold',
        fontSize: 16,
        lineHeight: '22px',
        color: theme.palette.neutralDark.contrastText,

        span: {
          padding: 0,
        },

        svg: {
          width: 16,
          height: 16,
        },
      },
    },
  },
  '.domains-wrapper .MuiOutlinedInput-root.MuiInputBase-root': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
    padding: '16.5px',
    height: 'auto',
    '.MuiOutlinedInput-input': {
      padding: '0',
      flexGrow: 1,
      width: 'auto',
      minWidth: '150px',
    },
  },
  '.self-registration-wrapper': {
    position: 'relative',
    '.self-registration-backdrop': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.transparent.dark,
      zIndex: '1',
    },
  },
}));

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  borderRadius: 4,
  background: theme.palette.transparent.blue05,

  '.table_cell': {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '23px',
    color: theme.palette.neutralDark.main,
    padding: '14px 16px 12px',
    borderBottom: `2px solid ${theme.palette.primary.contrastText}`,
    textTransform: 'uppercase',
  },
}));

export const StyledTableBody = styled(TableBody)(({ theme }) => ({
  td: {
    background: theme.palette.transparent.blue05,
    padding: '13px 14px 13px 16px',
    borderBottom: `2px solid ${theme.palette.primary.contrastText}`,
  },

  '.name': {
    fontFamily: 'Roboto-Bold',
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '22px',
  },

  '.email': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '&__text': {
      fontSize: 16,
      lineHeight: 1.38,
      color: theme.palette.neutralDark.contrastText,
    },

    '&__status': {
      gap: 12,
      display: 'flex',
      alignItems: 'center',

      '&_date': {
        fontSize: 16,
        lineHeight: 1.38,
        color: theme.palette.neutralDark.main,
      },
    },

    '&__update': {
      gap: 24,
      display: 'flex',
      alignItems: 'center',
      paddingRight: 24,

      div: {
        gap: 7,
        display: 'flex',
        alignItems: 'center',

        p: {
          fontFamily: 'Roboto-Bold',
          fontWeight: 'bold',
          fontSize: 16,
          lineHeight: '22px',
          color: theme.palette.neutralDark.main,
        },

        svg: {
          width: 18,
          height: 18,
        },
      },
    },
  },
}));
