import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { FetchingStatus } from 'types';
import { SelfRegistrationState } from '../../../interfaces';
import { registerAdminUser } from './registerAdminUser';

export const registerAdminUserExtraReducer = (
  builder: ActionReducerMapBuilder<SelfRegistrationState>,
) => {
  builder.addCase(registerAdminUser.pending, (state, action) => {
    state.api.registerAdminUser.fetchingStatus = FetchingStatus.PENDING;
    state.api.registerAdminUser.error = null;
  });

  builder.addCase(registerAdminUser.fulfilled, (state, action) => {
    state.api.registerAdminUser.fetchingStatus = FetchingStatus.FULFILLED;
    state.api.registerAdminUser.error = null;
  });

  builder.addCase(registerAdminUser.rejected, (state, action) => {
    state.api.registerAdminUser.fetchingStatus = FetchingStatus.REJECTED;
    state.api.registerAdminUser.error = action.error;
  });
};
