import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { FetchingStatus, SettingsState } from 'types';
import { updateSettings } from './updateSettings';
import { initialState } from '@features/settings/store/initialState';

export const updateSettingsExtraReducer = (builder: ActionReducerMapBuilder<SettingsState>) => {
  builder.addCase(updateSettings.pending, (state, action) => {
    if (state.settings) {
      state.settings.fetchingStatus = FetchingStatus.PENDING;
    } else {
      state['settings'] = initialState.settings;
    }
  });
  builder.addCase(updateSettings.fulfilled, (state, action) => {
    state.settings.fetchingStatus = FetchingStatus.FULFILLED;
    state.settings.activationLetterRecipientMode = action.payload.activationLetterRecipientMode;
  });

  builder.addCase(updateSettings.rejected, (state, action) => {
    state.settings.fetchingStatus = FetchingStatus.REJECTED;
  });
};
