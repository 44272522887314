import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { showToast } from 'utils';
import { axiosWithConfig } from 'api';
import { UserManagementBaseResponse, GetCheckInsResponse, GetCheckInsRequestParams } from 'types';

export const getCheckIns = createAsyncThunk<GetCheckInsResponse, GetCheckInsRequestParams>(
  'stats/getCheckIns',
  async ({ companyId, startMonth, endMonth }, { rejectWithValue }) => {
    const endpoint =
      process.env.REACT_APP_STATISTICS_ENDPOINT +
      `checkin?companyId=${companyId}&startMonth=${startMonth}&endMonth=${endMonth}&explain=true`;
    const axios = await axiosWithConfig();

    try {
      const { data } = await axios.get(endpoint);

      const checkInsList = {
        checkInsList: data.data,
        explain: data.explain,
      };

      return checkInsList;
    } catch (e) {
      const error: AxiosError<UserManagementBaseResponse> =
        e as AxiosError<UserManagementBaseResponse>;
      showToast('error', {
        id: 'toast.check_ins.get.error',
        defaultMessage: error.message,
      });
      return rejectWithValue(error.response?.data);
    }
  },
);
