import { Box } from '@mui/material';
import { useMemo } from 'react';

export interface SpacerProps {
  height?: number | string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl';
}

const Spacer = ({ height, size }: SpacerProps) => {
  const getHeight = useMemo(() => {
    if (height) return height;
    if (size) {
      switch (size) {
        case 'xs':
          return '4px';
        case 'sm':
          return '8px';
        case 'md':
          return '16px';
        case 'lg':
          return '24px';
        case 'xl':
          return '32px';
        case '2xl':
          return '48px';
        case '3xl':
          return '56px';
        case '4xl':
          return '64px';
      }
    }
    return 1;
  }, [height, size]);
  return <Box sx={{ height: getHeight, display: 'flex', width: '100%' }} />;
};

export default Spacer;
