import { Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { useIntl } from 'react-intl';
import { CCPDefaultButton, Spacer } from 'components';

const PromptModal: React.FC<{
  title: string;
  subtitle: string;
  mainContent?: any;
  cancelText?: string;
  okText?: string;
  isValid?: boolean;
  isOpen: boolean;
  closeModal: () => void;
  handleSubmit: () => void;
}> = ({
  title,
  subtitle,
  mainContent = null,
  cancelText,
  okText,
  isValid = true,
  isOpen,
  closeModal,
  handleSubmit,
}) => {
  const intl = useIntl();

  return (
    <Dialog open={isOpen} onClose={closeModal}>
      <DialogTitle>
        <Typography variant='h2' textAlign='center'>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant='subtitle1' textAlign='center'>
          {subtitle}
        </Typography>
        {mainContent ? <Spacer size='xl' /> : null}
        {mainContent}
      </DialogContent>
      <DialogActions>
        <CCPDefaultButton onClick={closeModal} variant='outlined'>
          {cancelText
            ? cancelText
            : intl.formatMessage({ id: 'form.employee.cta.cancel', defaultMessage: 'Cancel' })}
        </CCPDefaultButton>
        <CCPDefaultButton
          onClick={handleSubmit}
          type='submit'
          disabled={!isValid}
          variant='contained'>
          {okText
            ? okText
            : intl.formatMessage({
                id: 'form.employee.cta.save_changes',
                defaultMessage: 'Save changes',
              })}
        </CCPDefaultButton>
      </DialogActions>
    </Dialog>
  );
};

export default PromptModal;
