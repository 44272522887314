import * as yup from 'yup';

const LoginSchema = yup
  .object({
    email: yup
      .string()
      .required('schema.login.error.email.required')
      .trim()
      .email('schema.login.error.email.type'),
    password: yup
      .string()
      .required('schema.login.error.password.required')
      .min(3, 'schema.login.error.password.min.length'),
  })
  .required();

export default LoginSchema;
