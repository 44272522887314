import { NewLabel, UserId } from './EmployeeUserId.styles';
import { useAppSelector } from '../../../../../../../store';
import { EmployeeManagementSelectors } from '../../../../../store/employeeManagement.selectors';

export interface EmployeeUserIdProps {
  userId: number;
}

const EmployeeUserId = ({ userId }: EmployeeUserIdProps) => {
  const newEmployees = useAppSelector(EmployeeManagementSelectors.getNewEmployeeIds);

  return (
    <UserId>
      {newEmployees.indexOf(userId) !== -1 && <NewLabel>new</NewLabel>}
      {userId}
    </UserId>
  );
};

export default EmployeeUserId;
