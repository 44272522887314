import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store';
import { Employee, FetchingStatus } from 'types';
import { useIntl } from 'react-intl';
import { useLanguage } from 'hooks';
import { Breadcrumb } from 'components/Breadcrumb';
import EmployeePendingListItem from 'features/employeeManagement/components/EmployeePendingListItem';
import { EmployeeManagementSelectors } from 'features/employeeManagement/store/employeeManagement.selectors';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { EmployeeManagementActions } from 'features/employeeManagement/store';
import { MainBlock } from './EmployeePendingRequests.styles';
import NoPendingRequests from './NoPendingRequests';
import Spacer from '@components/Spacer';
import Loader from '@components/Loader';
import { useNavigate } from 'react-router-dom';

const EmployeePendingRequests = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const activeLanguage = useLanguage();
  const dispatch = useAppDispatch();
  const breadcrumbRoutes = [
    {
      url: `/${activeLanguage}/employee-management`,
      text: intl.formatMessage({
        id: 'employee_management.overview.title',
        defaultMessage: 'Employees',
      }),
    },
    {
      url: '',
      text: intl.formatMessage({
        id: 'employee_management.pending_requests.header',
        defaultMessage: 'Pending Requests',
      }),
    },
  ];
  const [pendingEmployees, setPendingEmployees] = useState<Employee[]>([]);
  const allEmployees = useAppSelector(EmployeeManagementSelectors.getGetEmployeesApi);
  const { getCompany: company } = useAppSelector(CompanySelectors.getApi);
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);

  useEffect(() => {
    if (allEmployees?.data?.length) {
      const pendingList = allEmployees?.data?.filter(employee => employee.status === 'applied');
      if (pendingList.length > 0) {
        setPendingEmployees(pendingList);
      } else {
        navigate(`/${activeLanguage}/employee-management`);
      }
    }
  }, [allEmployees, activeLanguage, navigate]);

  useEffect(() => {
    const companyFetchingStatus = company.fetchingStatus;
    const companyId = activeCompany?.companyId;
    if (companyFetchingStatus === FetchingStatus.FULFILLED && companyId) {
      dispatch(EmployeeManagementActions.getEmployees({ companyId }));
    }
  }, [activeCompany?.companyId, company.fetchingStatus, dispatch]);

  return (
    <Box>
      <Breadcrumb routes={breadcrumbRoutes} />
      <Loader show={allEmployees.fetchingStatus === FetchingStatus.PENDING} />
      <Typography variant='h2'>
        {intl.formatMessage({
          id: 'employee_management.pending_requests.title',
          defaultMessage: 'Pending requests',
        })}
      </Typography>
      <Spacer size='lg' />
      <MainBlock>
        {pendingEmployees.length > 0 &&
          pendingEmployees.map(employee => (
            <EmployeePendingListItem key={employee.id} employee={employee} />
          ))}
        {pendingEmployees.length === 0 && <NoPendingRequests />}
      </MainBlock>
    </Box>
  );
};

export default EmployeePendingRequests;
