import { FC } from 'react';
import { CircularProgress } from '@mui/material';
import { FileUploadContainer } from 'components';
import { EmployeeUploadFile } from './UploadTab.types';
import { useIntl } from 'react-intl';

interface UploadTabFilesListProps {
  total: number;
  files: Array<EmployeeUploadFile>;
}

const UploadTabFilesList: FC<UploadTabFilesListProps> = ({ total, files }) => {
  const intl = useIntl();
  return (
    <div className="in-progress-step" style={{ marginTop: '20px', padding: '0 24px' }}>
      <CircularProgress />
      <p>
        {files.length}{' '}
        {intl.formatMessage({
          id: 'employee_management.bulk_upload.uploading.from',
          defaultMessage: 'from',
        })}{' '}
        {total}
      </p>
      <strong>
        {intl.formatMessage({
          id: 'employee_management.bulk_upload.uploading.title',
          defaultMessage: 'Uploading data on server...',
        })}
      </strong>

      {files.map(({ progress, selectedFile, filename }, index) => (
        <FileUploadContainer
          key={index}
          progress={progress}
          file={selectedFile}
          filename={filename}
        />
      ))}
    </div>
  );
};

export default UploadTabFilesList;
