import { styled, Box } from '@mui/material';

export const StatusWrapper = styled(Box)(({ theme }) => ({
  gap: 16,
  display: 'flex',
  alignItems: 'center',

  '.label': {
    gap: 6,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',

    '&__text': {
      fontFamily: 'Roboto-Medium',
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.38,
      color: theme.palette.neutralDark.main,
    },
  },
}));
