import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { FetchingStatus } from 'types';

import { getUserInfo } from './getUserInfo';
import { AuthenticationState, IUserCompanyDetails } from '../../../interfaces';

export const getUserInfoExtraReducers = (builder: ActionReducerMapBuilder<AuthenticationState>) => {
  builder.addCase(getUserInfo.pending, (state, action) => {
    state.fetchingStatus = FetchingStatus.PENDING;
    state.error = null;
  });
  builder.addCase(getUserInfo.fulfilled, (state, action) => {
    state.fetchingStatus = FetchingStatus.FULFILLED;
    const currentCompanyData: IUserCompanyDetails = action.payload.userData.companydetails.filter(
      item => Number(item.companyId) === action.payload.companyId,
    )[0];
    if (currentCompanyData)
      state.userInfo = {
        ...action.payload.userData.userdetails,
        ...currentCompanyData,
        companyId: action.payload.companyId,
        companydetails: action.payload.userData.companydetails,
      };
    state.error = null;
  });
  builder.addCase(getUserInfo.rejected, (state, action) => {
    state.fetchingStatus = FetchingStatus.REJECTED;
    state.error = action.error;
  });
};
