import React from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { CCPDefaultButton, Spacer, SuccessIcon } from '../../../../../components';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useCookies } from 'react-cookie';

interface ApplyModalProps {
  isOpen: boolean;
}

const ApplyModal: React.FC<ApplyModalProps> = ({ isOpen }) => {
  const intl = useIntl();
  const [cookies] = useCookies(['language_app']);
  const activeLanguage = cookies.language_app || 'de';

  return (
    <Dialog open={isOpen}>
      <Box mt={6} display='flex' justifyContent='center'>
        <SuccessIcon />
      </Box>
      <DialogTitle align='center'>
        {intl.formatMessage({
          id: 'employee_management.bulk_upload.modal.success.title',
          defaultMessage: 'Your import has been successfully submitted',
        })}
        <Spacer height={14} />
      </DialogTitle>
      <DialogContent>
        <Typography align='center'>
          {intl.formatMessage({
            id: 'employee_management.bulk_upload.modal.success.content',
            defaultMessage:
              'It might take some time, we will notify you via email once process is completed.',
          })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Link
          to={`/${activeLanguage}/employee-management`}
          style={{ textDecoration: 'none' }}
          className='cancel-import-link'>
          <CCPDefaultButton variant='outlined' className='cancelBtn'>
            {intl.formatMessage({
              id: 'employee_management.bulk_upload.modal.success.cta.label',
              defaultMessage: 'Employee list',
            })}
          </CCPDefaultButton>
        </Link>
      </DialogActions>
    </Dialog>
  );
};

export default ApplyModal;
