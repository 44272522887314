import { useMemo, useState } from 'react';
import { Spacer } from 'components';
import { ContractWrapper, IconContainer } from './Contract.styles';
import { Box, Stack, Typography } from '@mui/material';
import ContractStatusChip from '../../components/ContractStatus';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { ContractSkeleton } from '@features/contract/components/ContractSkeleton';
import { ErrorOutline } from '@mui/icons-material';
import { useAppSelector } from 'store';
import { ContractsSelectors } from 'features/contract/store/contracts.selectors';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { useSelector } from 'react-redux';
import ContractCard from '@features/contract/components/ContractCard';
import { ShowAllDataWrapper } from '@features/employeeManagement/components/EmployeeMembership/MembershipTable/Table.styles';

const Contract = () => {
  const intl = useIntl();
  const [showAllData, setShowAllData] = useState(false);

  const { activeCompany } = useSelector(CompanySelectors.getCompanyState);
  const companyAddress = `${activeCompany?.street} ${activeCompany?.number}, ${activeCompany?.zip} ${activeCompany?.city}, ${activeCompany?.country}`;
  const {
    getContracts: {
      data: contracts,
      error: contractsError,
      fetchingStatus: fetchingGetContractsStatus,
    },
  } = useAppSelector(ContractsSelectors.getContracts);

  // The getContracts selector is fetching euCreationCondition and getContracts data
  const error = !!contractsError;

  const data = useMemo(() => {
    // if fetching status is not fulfilled, return empty array
    if (fetchingGetContractsStatus !== 'FULFILLED') {
      return [];
    }
    if (showAllData) {
      return _.sortBy(
        contracts,
        contract => contract.status !== 'active',
        contract => contract.status === 'expired',
      );
    }
    const isAllExpired = contracts.every(contract => contract.status === 'expired');
    if (isAllExpired && contracts[0]) {
      return [contracts[0]];
    }
    return _.sortBy(
      contracts.filter(contract => contract.status !== 'expired'),
      contract => contract.status !== 'active',
    );
  }, [showAllData, contracts, fetchingGetContractsStatus]);

  const showAllButton = useMemo(
    () => contracts.length > 1 && contracts?.some(contract => contract.status === 'expired'),
    [contracts],
  );

  return (
    <ContractWrapper>
      <Typography variant='h2'>
        {intl.formatMessage({ id: 'navigation.contract', defaultMessage: 'Contract details' })}
      </Typography>
      <Spacer size='lg' />
      <Box className='mainContent'>
        <Stack flexDirection='row' justifyContent='space-between' alignItems='baseline'>
          <Box className='companyInfoWrapper'>
            <Typography variant='h6' className='notranslate'>
              {activeCompany?.companyName}
            </Typography>
            <Spacer height={8} />
            <Typography variant='caption'>
              {`${intl.formatMessage({
                id: 'contracts.companyId',
                defaultMessage: 'Company ID',
              })}: ${activeCompany?.casCompanyId}`}
            </Typography>
            <Spacer height={16} />
            <Typography variant='subtitle1' className='notranslate'>
              {companyAddress}
            </Typography>
          </Box>
          <ContractStatusChip status={activeCompany?.status || 'unknown'} />
        </Stack>
        <Spacer height={33} />
        {error && (
          <Box
            mx='auto'
            mt='94px'
            mb='130px'
            textAlign='center'
            justifyContent='center'
            flexDirection='column'
            display='flex'
            alignItems='center'>
            <IconContainer>
              <ErrorOutline />
            </IconContainer>
            <Typography variant='h2'>
              {intl.formatMessage({
                id: 'view.contract.error.title',
                defaultMessage: 'No contract details found',
              })}
            </Typography>
            <Spacer size='md' />
            <Typography className='contractLabel'>
              {intl.formatMessage({
                id: 'view.contract.error.label',
                defaultMessage: 'Sorry, something went wrong, please try again later.',
              })}
            </Typography>
          </Box>
        )}
        {!data.length && !error && (
          <Box width='100%' maxWidth={682} mx='auto'>
            <ContractSkeleton />
          </Box>
        )}
        {data.length > 0 && !error && (
          <Box className='contractList'>
            {data.map(contract => (
              <ContractCard key={contract.id} contract={contract} />
            ))}
            {showAllButton && !showAllData && (
              <ShowAllDataWrapper onClick={() => setShowAllData(true)}>
                {intl.formatMessage({
                  id: 'view.contract.table.show_all',
                  defaultMessage: 'Show all contracts',
                })}
              </ShowAllDataWrapper>
            )}
          </Box>
        )}
      </Box>
    </ContractWrapper>
  );
};

export default Contract;
