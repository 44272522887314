import { startOfMonth, startOfToday, subDays } from 'date-fns';
import moment from 'moment';
import { IEUConditions, contractPermissionsName } from 'types';

export const getDate = (
  date: string | Date | null,
  regularStartDate: string | undefined,
  isLastDate?: boolean,
) => {
  let selectedDate = null;
  if (date) {
    if (regularStartDate === '1st_of_month') {
      if (isLastDate) {
        selectedDate = new Date(new Date(date).getFullYear(), new Date(date).getMonth() + 1, 0);
      } else {
        selectedDate = new Date(new Date(date).getFullYear(), new Date(date).getMonth(), 1);
      }
    } else if (regularStartDate === 'last_of_month') {
      selectedDate = new Date(new Date(date).getFullYear(), new Date(date).getMonth() + 1, 0);
    } else {
      selectedDate = new Date(date);
    }
  }
  return selectedDate || 0;
};

export const prepareMinDateForStartPicker = (activeContract?: IEUConditions | null) => {
  const today = new Date();
  if (activeContract) {
    const startContractDate = new Date(activeContract.startDate);
    if (checkIsMonthPicker(activeContract.regularStartDate)) {
      if (
        startContractDate < today ||
        (today.getMonth() === startContractDate.getMonth() &&
          today.getFullYear() === startContractDate.getFullYear())
      ) {
        // when we have a contract with start date in the past, or future active contract has a start date in current month
        return new Date(today.getFullYear(), today.getMonth() + 1, 1);
      } else if (startContractDate.getDate() > 1) {
        // when future contract has a start date not first of the month we need to select next month
        return new Date(startContractDate.getFullYear(), startContractDate.getMonth() + 1, 1);
      } else {
        return startContractDate;
      }
    } else {
      if (
        startContractDate < today ||
        (today.getDate() === startContractDate.getDate() &&
          today.getMonth() === startContractDate.getMonth() &&
          today.getFullYear() === startContractDate.getFullYear())
      ) {
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
      } else {
        return new Date(
          startContractDate.getFullYear(),
          startContractDate.getMonth(),
          startContractDate.getDate(),
        );
      }
    }
  }
  return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
};

export const prepareMaxDateForStartPicker = (activeContract: IEUConditions | null) => {
  const today = new Date();
  if (activeContract) {
    const startContractDate = subDays(prepareMinDateForStartPicker(activeContract), 1);
    if (checkIsMonthPicker(activeContract.regularStartDate)) {
      const endDateInHalfYear = moment(startContractDate).add(6, 'M').toDate();
      if (activeContract.endDate && new Date(activeContract.endDate) < endDateInHalfYear) {
        const contractEndDate = new Date(activeContract.endDate);
        return new Date(contractEndDate.getFullYear(), contractEndDate.getMonth() + 1, -1);
      } else {
        return new Date(endDateInHalfYear.getFullYear(), endDateInHalfYear.getMonth() + 1, -1);
      }
    } else {
      const endDateInHalfYear = moment(startContractDate).add(6, 'M').toDate();
      if (activeContract.endDate && new Date(activeContract.endDate) < endDateInHalfYear) {
        return new Date(activeContract.endDate);
      } else {
        return endDateInHalfYear;
      }
    }
  }
  return new Date(today.getFullYear(), today.getMonth() + 6, today.getDate());
};

export const prepareMinDateForPicker = (
  date: string | Date | null | undefined,
  isMonthPicker: boolean,
  isPrefilledEntryDate?: boolean,
) => {
  if (date) {
    if (isMonthPicker) {
      if (isPrefilledEntryDate) {
        const currentDate = new Date(date);
        return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      }
      const today = new Date();
      return new Date(today.getFullYear(), today.getMonth() + 1, 1);
    }
    return new Date(date);
  }
  return undefined;
};

export const prepareMaxDateForPicker = (
  date: string | null | undefined,
  isMonthPicker: boolean,
  monthLimit?: number,
) => {
  monthLimit = monthLimit || 6;
  const firstDate = isMonthPicker ? startOfMonth(new Date()) : startOfToday();
  const endDateInHalfYear = moment(firstDate).add(monthLimit, 'M').toDate();
  if (date) {
    return new Date(date) < endDateInHalfYear ? new Date(date) : endDateInHalfYear;
  } else {
    return endDateInHalfYear;
  }
};

export const checkIsMonthPicker = (regularStartDate: string | undefined) =>
  regularStartDate === '1st_of_month';

export const checkTypeParameter = (
  activeContract: IEUConditions | null,
  paramName: contractPermissionsName,
) => !!activeContract && activeContract.hasOwnProperty(paramName) && activeContract[paramName];
