import { useLanguage } from 'hooks';
import React from 'react';
import { TranslationsKeys } from 'types';

export const FormatDate = (date: string | number, time?: number) => {
  return (
    <DateComponent
      date={typeof date === 'number' ? new Date(date).toLocaleDateString('EN') : date}
      time={time}
    />
  );
};

export const manualFormatDateToString = (date: string, language: TranslationsKeys) => {
  return new Date(date).toLocaleDateString('DE' || language, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export const DateComponent: React.FC<{ date?: string; time?: number }> = ({ date, time }) => {
  const language = useLanguage();

  return (
    <>
      {date &&
        new Date(date).toLocaleDateString('DE' || language, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })}
      {time &&
        ` ${new Date(time).toLocaleTimeString('DE' || language, {
          hour: '2-digit',
          minute: '2-digit',
        })}`}
    </>
  );
};
