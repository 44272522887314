import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { verifyChangeUserEmail } from './verifyChangeUserEmail';
import { FetchingStatus } from 'types';

import { UserManagementState } from '../../../interfaces';

export const verifyChangeUserEmailExtraReducer = (
  builder: ActionReducerMapBuilder<UserManagementState>,
) => {
  builder.addCase(verifyChangeUserEmail.pending, (state, action) => {
    state.verifyChangeUserEmail.fetchingStatus = FetchingStatus.PENDING;
    state.verifyChangeUserEmail.error = null;
  });
  builder.addCase(verifyChangeUserEmail.rejected, (state, action) => {
    state.verifyChangeUserEmail.fetchingStatus = FetchingStatus.REJECTED;
    state.verifyChangeUserEmail.error = action.error;
  });
  builder.addCase(verifyChangeUserEmail.fulfilled, (state, action) => {
    state.verifyChangeUserEmail.fetchingStatus = FetchingStatus.FULFILLED;
    state.verifyChangeUserEmail.error = null;
  });
};
