import { Box, Stack, Typography } from '@mui/material';
import { CCPDefaultButton, Spacer } from 'components';
import { useIntl } from 'react-intl';
import { EmployeeMembershipStyled } from './EmplyeeMembership.styles';
import { useAppDispatch, useAppSelector } from 'store';
import { EmployeeManagementSelectors } from 'features/employeeManagement/store/employeeManagement.selectors';
import { Employee, EmployeeStatus } from 'features/employeeManagement/store/interfaces';
import { MembershipTable } from './MembershipTable';
import DeactivationEmployeeModal from '../EmployeeOverview/Modals/DeactiveEmployeeModal';
import PauseEmployeeModal from '../EmployeeOverview/Modals/PauseEmployeeModal';
import { FormatDate, manualFormatDateToString } from 'components/FormatDate';
import { EmployeeManagementActions } from 'features/employeeManagement/store';
import { checkTypeParameter } from 'utils/contractDependencies';
import { useCallback, useEffect, useMemo, useState } from 'react';
import EmployeeStatusChip from '../EmployeeOverview/EmployeeTable/Columns/Status';
import RemoveEmployeeModal from '../EmployeeOverview/Modals/RemoveEmployeeModal';
import { useLanguage } from '@hooks/useLanguage';
import { checkShowButton } from '@utils/employeeMembership';
import PendingEmployeeModal from '../EmployeeOverview/Modals/PendingEmployeeModal';
import { SettingsSelectors } from '@features/settings/store/settings.selectors';

export const EmployeeMembership: React.FC<{ isDirectPay: boolean }> = ({ isDirectPay }) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const language = useLanguage();
  const activeContract = useAppSelector(state => state.contracts.euCreationCondition.data);
  const employeeApi = useAppSelector(EmployeeManagementSelectors.getGetEmployeeApi);
  const employee = employeeApi.data as Employee;

  const employeeStatusApi = useAppSelector(EmployeeManagementSelectors.getGetEmployeeStatusApi);
  const { activationLetterRecipientMode } = useAppSelector(SettingsSelectors.settingsSettings);
  const activationLetterDistributionList = useAppSelector(
    SettingsSelectors.emailActivationLetterDistributionList,
  );
  const plannedTransition = employeeStatusApi.data?.plannedTransition;

  useEffect(() => {
    if (employee.id) {
      dispatch(EmployeeManagementActions.getEmployeeStatus({ employeeId: employee.id.toString() }));
    }
  }, [dispatch, employee.id]);

  const [showRemoveModal, setShowRemoveModal] = useState<boolean>();
  const [showDeactivateModal, setShowDeactivateModal] = useState<boolean>();
  const [showLimitedPauseModal, setShowLimitedPauseModal] = useState<boolean>();
  const [showEditStartDateModal, setShowEditStartDateModal] = useState<boolean>();

  const toggleRemoveModal = useCallback(
    () => setShowRemoveModal(prev => !prev),
    [setShowRemoveModal],
  );
  const toggleDeactivateModal = useCallback(
    () => setShowDeactivateModal(prev => !prev),
    [setShowDeactivateModal],
  );
  const toggleLimitedPauseModal = useCallback(
    () => setShowLimitedPauseModal(prev => !prev),
    [setShowLimitedPauseModal],
  );
  const toggleEditStartDateModal = useCallback(
    () => setShowEditStartDateModal(prev => !prev),
    [setShowEditStartDateModal],
  );

  const showRemoveButton = useMemo(
    () =>
      checkShowButton(activeContract, 'deleteEu', employeeStatusApi.data, 'unassigned', [
        'leave_company',
        'leave_company_from_active',
      ]),
    [activeContract, employeeStatusApi.data],
  );

  const showPauseUnlimitedButton = useMemo(
    () =>
      checkShowButton(activeContract, 'endContractPossible', employeeStatusApi.data, 'inactive', [
        'pause_ended',
        'end_date_reached',
      ]),
    [activeContract, employeeStatusApi.data],
  );

  const showReactivateButton = useMemo(
    () =>
      checkShowButton(activeContract, 'reactivationAllowed', employeeStatusApi.data, 'active', [
        'entry_date_reached',
        'pause_ended',
      ]),
    [activeContract, employeeStatusApi.data],
  );

  const showPausePeriodButton = useMemo(() => {
    if (!checkTypeParameter(activeContract, 'pauseContractPossible')) {
      return false;
    }
    if (!employeeStatusApi.data) {
      return false;
    }

    return (
      employeeStatusApi.data.transitions.filter(item => item.transition === 'paused').length > 0 &&
      (['end_date_reached', 'leave_company_from_active'].includes(
        employeeStatusApi.data.plannedTransition?.status || '',
      ) ||
        employeeStatusApi.data.plannedTransition === null)
    );
  }, [activeContract, employeeStatusApi.data]);

  const transitionData = useMemo(() => {
    if (plannedTransition?.status) {
      const showLabel = [
        'leave_company',
        'leave_company_from_active',
        'pause',
        'pause_ended',
        'end_date_reached',
        'entry_date_reached',
      ].includes(plannedTransition?.status);

      let labelText = '';
      let linkText = '';
      let toggleFunction = () => {};
      let showEditButton = true;

      switch (plannedTransition.status) {
        case 'leave_company':
        case 'leave_company_from_active': {
          labelText = formatMessage(
            {
              id: 'employee.details.planed_transition.label.leave',
              defaultMessage: 'Employee will be deleted on {startDate}',
            },
            { startDate: manualFormatDateToString(plannedTransition.startDate, language) },
          );
          linkText = formatMessage({
            id: 'employee.details.planed_transition.link.leave',
            defaultMessage: 'Edit planned deletion',
          });
          toggleFunction = toggleRemoveModal;
          showEditButton = checkTypeParameter(activeContract, 'deleteEu');
          break;
        }
        case 'pause': {
          labelText = formatMessage(
            {
              id: 'employee.details.planed_transition.label.pause',
              defaultMessage: 'Pause is planned from {startDate} to {endDate}',
            },
            {
              startDate: manualFormatDateToString(plannedTransition.startDate, language),
              endDate: manualFormatDateToString(plannedTransition.endDate, language),
            },
          );

          linkText = formatMessage({
            id: 'employee.details.planed_transition.link.pause',
            defaultMessage: 'Edit limited pause',
          });
          toggleFunction = toggleLimitedPauseModal;
          showEditButton = checkTypeParameter(activeContract, 'pauseContractPossible');
          break;
        }
        case 'pause_ended': {
          labelText = formatMessage(
            {
              id: 'employee.details.planed_transition.label.pause_ended',
              defaultMessage: 'Membership will re-start on {startDate}',
            },
            { startDate: manualFormatDateToString(plannedTransition.startDate, language) },
          );
          linkText = formatMessage({
            id: 'employee.details.planed_transition.link.pause_ended',
            defaultMessage: 'Edit re-entry date',
          });
          toggleFunction = toggleDeactivateModal;
          showEditButton = checkTypeParameter(activeContract, 'endContractPossible');
          break;
        }
        case 'end_date_reached': {
          labelText = formatMessage(
            {
              id: 'employee.details.planed_transition.label.end_date_reached',
              defaultMessage: 'Employee will be inactive from {startDate}',
            },
            { startDate: manualFormatDateToString(plannedTransition.startDate, language) },
          );
          linkText = formatMessage({
            id: 'employee.details.planed_transition.link.end_date_reached',
            defaultMessage: 'Edit unlimited pause',
          });
          toggleFunction = toggleDeactivateModal;
          showEditButton = checkTypeParameter(activeContract, 'endContractPossible');
          break;
        }
        case 'entry_date_reached': {
          if (employeeStatusApi.data?.status === EmployeeStatus.PENDING) {
            labelText = formatMessage(
              {
                id: 'employee.details.planed_transition.label.entry_date_reached.pending',
                defaultMessage: 'Membership will start on {startDate}',
              },
              { startDate: manualFormatDateToString(plannedTransition.startDate, language) },
            );
            linkText = formatMessage({
              id: 'employee.details.planed_transition.link.entry_date_reached.pending',
              defaultMessage: 'Edit start date',
            });
            toggleFunction = toggleEditStartDateModal;
          } else if (employeeStatusApi.data?.status === EmployeeStatus.INACTIVE) {
            labelText = formatMessage(
              {
                id: 'employee.details.planed_transition.label.entry_date_reached.inactive',
                defaultMessage: 'Membership will re-start on {startDate}',
              },
              { startDate: manualFormatDateToString(plannedTransition.startDate, language) },
            );
            linkText = formatMessage({
              id: 'employee.details.planed_transition.link.entry_date_reached.inactive',
              defaultMessage: 'Change re-entry date',
            });
            toggleFunction = toggleDeactivateModal;
            showEditButton = checkTypeParameter(activeContract, 'reactivationAllowed');
          }
          break;
        }
      }

      return {
        showPlannedTransitionLabel: showLabel,
        plannedTransitionLabel: labelText,
        changeTransitionLinkText: linkText,
        toggleFunction,
        showEditButton,
      };
    } else {
      return { showPlannedTransitionLabel: false };
    }
  }, [
    language,
    formatMessage,
    plannedTransition,
    employeeStatusApi.data?.status,
    toggleEditStartDateModal,
    toggleLimitedPauseModal,
    toggleDeactivateModal,
    toggleRemoveModal,
    activeContract,
  ]);

  const submitModal = async ({ transition = '', startDate = '', endDate = '' }) => {
    const updateEmployeeStatus = {
      employeeId: employee.id,
      transition,
      startDate,
      endDate,
    };

    await dispatch(EmployeeManagementActions.changeEmployeeStatus(updateEmployeeStatus));

    await dispatch(
      EmployeeManagementActions.getEmployeeStatus({ employeeId: employee.id.toString() }),
    );

    await dispatch(EmployeeManagementActions.getEmployee({ employeeId: employee.id.toString() }));

    if (transition === EmployeeStatus.UNASSIGNED) {
      toggleRemoveModal();
    }
    if (transition === EmployeeStatus.PAUSED) {
      toggleLimitedPauseModal();
    }
    if (transition === EmployeeStatus.ACTIVE || transition === EmployeeStatus.INACTIVE) {
      if (employeeStatusApi.data?.status === EmployeeStatus.PENDING) {
        toggleEditStartDateModal();
      } else {
        toggleDeactivateModal();
      }
    }

    if (transition === EmployeeStatus.PAUSE_REMOVED) {
      setShowLimitedPauseModal(false);
      setShowDeactivateModal(false);
      setShowRemoveModal(false);
    }
  };

  const emptyCuList =
    activationLetterRecipientMode.toLowerCase() === 'cu' &&
    activationLetterDistributionList.length === 0;
  const euWithoutEmail =
    !Boolean(employee.businessEmail) && activationLetterRecipientMode.toLowerCase() === 'eu';

  const resendActivationLetter = async () => {
    dispatch(
      EmployeeManagementActions.resendActivationLetter({
        endUserId: employee.id,
        language,
      }),
    );
  };

  return (
    <EmployeeMembershipStyled>
      <Typography variant='h6'>
        {formatMessage({
          id: 'employee.membership.title',
          defaultMessage: 'Membership',
        })}
      </Typography>
      <Spacer size='lg' />
      <Box display='flex' alignItems='center'>
        <Typography variant='subtitle1' minWidth={100}>
          {formatMessage({
            id: 'employee.details.status',
            defaultMessage: 'Status',
          })}
          :
        </Typography>
        <EmployeeStatusChip
          status={
            employee.status === EmployeeStatus.LOCKED ? EmployeeStatus.BLOCKED : employee.status
          }
          date={employee.entryDate}
        />
      </Box>
      <Box display='flex' mt={2} alignItems='center'>
        <Typography variant='subtitle1' minWidth={100}>
          {formatMessage({
            id: 'form.employee.field.start_date.label',
            defaultMessage: 'Start date',
          })}
          :
        </Typography>
        <Typography variant='subtitle1'>
          {employee?.entryDate ? FormatDate(employee.entryDate) : ''}
        </Typography>
      </Box>
      <Spacer size='lg' />
      {transitionData.showPlannedTransitionLabel && (
        <>
          <Stack className='planned-transition-row'>
            <label>
              <b>
                {formatMessage({
                  id: 'employee.details.planed_transition.label.important',
                  defaultMessage: 'Important',
                })}
                :
              </b>{' '}
              {transitionData.plannedTransitionLabel}
            </label>
            {transitionData.showEditButton && (
              <CCPDefaultButton
                variant='text'
                onClick={transitionData.toggleFunction}
                color='secondary'
                sx={{ textTransform: 'uppercase' }}>
                {transitionData.changeTransitionLinkText}
              </CCPDefaultButton>
            )}
          </Stack>
          <Spacer size='lg' />
        </>
      )}
      <MembershipTable employeeId={employee.id} />
      <Box sx={{ flexGrow: 1 }} />
      {(showRemoveButton ||
        showReactivateButton ||
        showPausePeriodButton ||
        showPauseUnlimitedButton) && <Spacer size='lg' />}
      <Stack flexDirection='row' justifyContent='flex-end' gap='24px'>
        {showRemoveButton && (
          <CCPDefaultButton variant='outlined' color='error' onClick={toggleRemoveModal}>
            {formatMessage({
              id: 'employee.modal.remove.button',
              defaultMessage: 'Remove',
            })}
          </CCPDefaultButton>
        )}
        {showReactivateButton && (
          <CCPDefaultButton variant='outlined' onClick={toggleDeactivateModal}>
            {formatMessage({
              id: 'employee.modal.reactivate.button',
              defaultMessage: 'Reactivate membership',
            })}
          </CCPDefaultButton>
        )}
        {showPausePeriodButton && (
          <CCPDefaultButton variant='outlined' onClick={toggleLimitedPauseModal}>
            {formatMessage({
              id: 'employee.modal.pause.button',
              defaultMessage: 'Pause membership for a period of time',
            })}
          </CCPDefaultButton>
        )}
        {showPauseUnlimitedButton && (
          <CCPDefaultButton variant='outlined' onClick={toggleDeactivateModal}>
            {formatMessage({
              id: 'employee.modal.deactivate.button',
              defaultMessage: 'Pause membership unlimited',
            })}
          </CCPDefaultButton>
        )}
      </Stack>
      {showRemoveModal && (
        <RemoveEmployeeModal
          employeeTitle={employee.title || ''}
          employeeName={`${employee.firstName} ${employee.lastName}`}
          employeeStatus={employee.status}
          transitionStartDate={plannedTransition?.startDate}
          close={toggleRemoveModal}
          onSubmit={submitModal}
          hasRevert={
            plannedTransition?.status === 'leave_company_from_active' ||
            plannedTransition?.status === 'leave_company'
          }
        />
      )}
      {showLimitedPauseModal && (
        <PauseEmployeeModal
          employeeTitle={employee.title || ''}
          employeeName={`${employee.firstName} ${employee.lastName}`}
          transitionStartDate={plannedTransition?.startDate}
          transitionEndDate={plannedTransition?.endDate}
          transitionType={EmployeeStatus.PAUSED}
          close={toggleLimitedPauseModal}
          onSubmit={submitModal}
          hasRevert={plannedTransition?.status === 'pause'}
        />
      )}
      {showDeactivateModal && (
        <DeactivationEmployeeModal
          employeeName={`${employee.firstName || ''} ${employee.lastName || ''}`}
          transitionType={
            ['pause_ended', 'entry_date_reached'].includes(plannedTransition?.status || '') ||
            ((plannedTransition === null || plannedTransition?.status === 'leave_company') &&
              employee.status === EmployeeStatus.INACTIVE)
              ? EmployeeStatus.ACTIVE
              : EmployeeStatus.INACTIVE
          }
          close={toggleDeactivateModal}
          onSubmit={submitModal}
          hasRevert={
            (employee.status === EmployeeStatus.ACTIVE &&
              plannedTransition?.status === 'end_date_reached') ||
            (employee.status === EmployeeStatus.INACTIVE &&
              plannedTransition?.status === 'entry_date_reached')
          }
        />
      )}
      {showEditStartDateModal && (
        <PendingEmployeeModal
          employeeTitle={employee.title || ''}
          employeeName={`${employee.firstName || ''} ${employee.lastName || ''}`}
          transitionDate={plannedTransition?.startDate}
          transitionType={EmployeeStatus.ACTIVE}
          resendLetter={resendActivationLetter}
          cantResend={euWithoutEmail || emptyCuList}
          close={toggleEditStartDateModal}
          onSubmit={submitModal}
        />
      )}
    </EmployeeMembershipStyled>
  );
};
