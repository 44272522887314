import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { SelfRegistrationActions } from 'features/selfRegistration/store';
import { SelfRegistrationSelectors } from 'features/selfRegistration/store/selfRegistration.selectors';
import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'store';
import {
  BottomHintContainer,
  StepContainer,
  VerifyEmailContainer,
  Spacer,
  SuccessIcon,
  CCPLink,
} from 'components';

const VerifyEmail = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const personalInformation = useAppSelector(SelfRegistrationSelectors.getAddPersonalInfoStep);

  const handleReset = useCallback(() => {
    dispatch(SelfRegistrationActions.resetProcess());
  }, [dispatch]);

  return (
    <StepContainer>
      <VerifyEmailContainer>
        <Spacer height={26} />
        <SuccessIcon />
        <Spacer height={'37px'} />
        <Typography variant={'body1'} className={clsx('hint')}>
          {personalInformation && (
            <Box>
              <FormattedMessage
                id="verification.hint.success"
                defaultMessage={
                  'We have send you a verification link at <b>{email}.</b> {br} Please, check your inbox and confirm your email.'
                }
                values={{
                  email: personalInformation.email || '',
                  b: (...chunks) => <b>{chunks}</b>,
                  br: <br />,
                }}
              />
            </Box>
          )}
        </Typography>
        <Spacer height={94} />
        <BottomHintContainer>
          <Typography variant={'body1'} className={clsx('hint')}>
            {intl.formatMessage({
              id: 'verification.hint.question',
              defaultMessage: 'Didn’t receive verification link?',
            })}
          </Typography>
          &nbsp;
          <CCPLink href={'#'} onClick={handleReset}>
            {intl.formatMessage({
              id: 'verification.hint.resend',
              defaultMessage: 'Resend confirmation link',
            })}
          </CCPLink>
        </BottomHintContainer>
      </VerifyEmailContainer>
    </StepContainer>
  );
};

export default VerifyEmail;
