import { styled, Box } from '@mui/material';

export const AllCompaniesWrapper = styled(Box)(({ theme }) => ({
  '.title': {
    fontFamily: 'NutmegHeadline-UltraBlack',
    fontSize: 20,
    fontWeight: 900,
    lineHeight: 1.6,
    color: theme.palette.neutralDark.contrastText,
    textTransform: 'uppercase',
  },
}));
