import { useCallback, useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { EmployeeGeneralInfoStyled } from './EmployeeGeneralInfo.styles';
import { Spacer } from 'components';
import EmployeeDetails from '../../components/EmployeeGeneralInfo/EmployeeDetails';
import EmployeeProfileEdit from '../../components/EmployeeGeneralInfo/EmployeeProfileEdit';
import TwoRowsSideBarItem from 'components/TwoRows/TwoRowsSideBarItem';
import { useAppDispatch, useAppSelector } from 'store';
import { EmployeeManagementSelectors } from '../../store/employeeManagement.selectors';
import { EmployeeManagementActions } from '../../store';
import { useNavigate, useParams } from 'react-router-dom';
import { FetchingStatus } from 'api/interfaces';

import { EManagementView } from 'components/TwoRows/TwoRowsSideBarItem/interfaces';
import { EmployeeMembership } from 'features/employeeManagement/components/EmployeeMembership';
import { useIntl } from 'react-intl';
import { useLanguage } from 'hooks';
import { SettingsActions } from 'features/settings/store';
import { checkTypeParameter } from 'utils/contractDependencies';
import Loader from '@components/Loader';
import { Breadcrumb } from '@components/Breadcrumb';

const EmployeeGeneralInfoPage: React.FC = () => {
  const params = useParams<{ employeeId: string; isEdit: string }>();
  const dispatch = useAppDispatch();
  const pageType = params.isEdit;
  const language = useLanguage();
  const navigate = useNavigate();
  const intl = useIntl();

  const selfRegistrationFetchingStatus = useAppSelector(
    state => state.settings.selfRegistrationFetching,
  );
  const activeContract = useAppSelector(state => state.contracts.euCreationCondition.data);
  const isDirectPay = useAppSelector(state => state.settings.selfRegistration.directPay);
  const canCreateAndEdit = () =>
    !!(activeContract && checkTypeParameter(activeContract, 'createEditEUPossible'));

  const employeeApi = useAppSelector(EmployeeManagementSelectors.getGetEmployeeApi);
  const employee = employeeApi.data;

  const isGeneralInfo = pageType === EManagementView.View || pageType === EManagementView.Edit;
  const isEdit = pageType === EManagementView.Edit;

  const handleEditProfile = useCallback((value: boolean) => {
    navigate(
      `/${language}/employee-management/${params.employeeId}/general-info/${
        value ? EManagementView.Edit : EManagementView.View
      }`,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breadcrumbRoutes = useMemo(
    () => [
      {
        url: `/${language}/employee-management`,
        text: intl.formatMessage({
          id: 'employee_management.overview.title',
          defaultMessage: 'Employees',
        }),
      },
      {
        url: '',
        text: employee ? `${employee.firstName} ${employee.lastName}` : '',
      },
    ],
    [intl, language, employee],
  );

  useEffect(() => {
    if (selfRegistrationFetchingStatus === FetchingStatus.IDLE) {
      dispatch(SettingsActions.getRegistrationSettings({}));
    }
  }, [selfRegistrationFetchingStatus, dispatch]);

  useEffect(() => {
    dispatch(EmployeeManagementActions.getEmployee({ employeeId: params.employeeId as string }));
  }, [dispatch, params.employeeId]);

  if (
    ![EManagementView.Edit, EManagementView.Membership, EManagementView.View].includes(
      pageType as EManagementView,
    ) ||
    !employee
  ) {
    return null;
  }

  return (
    <EmployeeGeneralInfoStyled>
      <Breadcrumb routes={breadcrumbRoutes} />
      <Typography variant='h2'>
        {intl.formatMessage({
          id: 'employee.details.profile',
          defaultMessage: 'Employee profile',
        })}
      </Typography>
      <Spacer size='lg' />

      <Box className='twoRowsProfile'>
        <Box className='profileLeftSide'>
          <Spacer size='lg' />
          <TwoRowsSideBarItem
            text={intl.formatMessage({
              id: 'employee.details.general_info',
              defaultMessage: 'General info',
            })}
            type={EManagementView.View}
            isActive={pageType === EManagementView.View || pageType === EManagementView.Edit}
          />
          <TwoRowsSideBarItem
            text={intl.formatMessage({
              id: 'employee.membership.title',
              defaultMessage: 'Membership',
            })}
            type={EManagementView.Membership}
            isActive={pageType === EManagementView.Membership}
          />
        </Box>
        <Box className='profileRightSide' id='employeeInfo' position='relative'>
          <Loader
            show={employeeApi.fetchingStatus === FetchingStatus.PENDING}
            inCard
            portalId='employeeInfo'
          />
          {isGeneralInfo ? (
            !isEdit ? (
              <EmployeeDetails
                handleEditProfile={handleEditProfile}
                allowEdit={canCreateAndEdit()}
              />
            ) : (
              <EmployeeProfileEdit
                handleEditProfile={handleEditProfile}
                allowEdit={canCreateAndEdit()}
              />
            )
          ) : (
            <EmployeeMembership isDirectPay={isDirectPay} />
          )}
        </Box>
      </Box>
    </EmployeeGeneralInfoStyled>
  );
};

export default EmployeeGeneralInfoPage;
