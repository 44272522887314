import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';

import { MainLayout, UserLayout, LeftSideBar, GuestLayout, IntlProviderLayout } from 'components';

import routes from './routeConfig';
import { RouteType } from './interfaces';
import * as Sentry from '@sentry/react';
import RouterGuard from '@components/RouterGuard';

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const RoutesWrapper = () => {
  const renderRoutesList = routes.map(route => {
    const {
      props: { path, component: Component, ...rest },
      routeType,
    } = route;

    const authorizedContainer = (
      <IntlProviderLayout>
        <MainLayout>
          <UserLayout leftNavigation={<LeftSideBar />} path={path}>
            <Component {...rest} />
            <RouterGuard />
          </UserLayout>
        </MainLayout>
      </IntlProviderLayout>
    );
    const unauthorizedRoutes = (
      <IntlProviderLayout>
        <MainLayout>
          <GuestLayout leftNavigation={<LeftSideBar />}>
            <Component {...rest} />
          </GuestLayout>
        </MainLayout>
      </IntlProviderLayout>
    );

    const isAuthorizedRoutes = routeType === RouteType.AUTHORIZED;

    return (
      <Route
        key={path}
        path={path}
        element={isAuthorizedRoutes ? authorizedContainer : unauthorizedRoutes}
      />
    );
  });

  return (
    <RouterProvider
      router={sentryCreateBrowserRouter(
        createRoutesFromElements(
          <Route>
            <Route path='*' element={<Navigate to='/' replace />} />
            {renderRoutesList}
          </Route>,
        ),
      )}
    />
  );
};

export default RoutesWrapper;
