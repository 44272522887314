import { useAppSelector } from 'store';
import { CompanySelectors } from '../../../../company/store/company.selectors';
import { CCPTextField } from 'components';
import { useFormContext } from 'react-hook-form';
import { CustomFieldsWrapper } from './CustomFields.styles';
import { useLanguage } from 'hooks';

import { TranslationsKeys } from 'types';
import CCPAutocomplete from '@components/Form/CCPAutocomplete';

const CustomFields = ({ width = 'auto' }: { width?: 'auto' | number }) => {
  const company = useAppSelector(CompanySelectors.getApi);
  const companyData = company.getCompany.data;
  const companyDefaultLanguage =
    (companyData?.communicationLanguage.toLocaleLowerCase() as TranslationsKeys) || 'de';
  const language = useLanguage();
  const { control, setValue, getValues } = useFormContext();

  return (
    <>
      {companyData && companyData.customFields.length > 0 && (
        <CustomFieldsWrapper>
          {companyData.customFields.map((customField, idx) => {
            const label =
              customField.name[language] ||
              customField.name[companyDefaultLanguage] ||
              customField.name[language === 'de' ? 'en' : 'de'] ||
              '';
            const fieldName = `customFields.${customField.key}`;
            if (customField.inputType === 'enum') {
              const options: Array<{ label: string; id: string }> = [];
              if (customField.localizedValuesMap) {
                Object.keys(customField.localizedValuesMap).forEach(keyName => {
                  if (customField.localizedValuesMap) {
                    const curr = customField.localizedValuesMap[keyName];
                    options.push({
                      id: keyName,
                      label:
                        curr[language] ||
                        curr[companyDefaultLanguage] ||
                        curr[language === 'de' ? 'en' : 'de'] ||
                        keyName,
                    });
                  }
                });
              }
              if (
                options.length === 1 ||
                (customField.isFirstValueDefault === 'yes' && !getValues(fieldName))
              ) {
                setValue(fieldName, options[0].id);
              }
              return (
                <CCPAutocomplete
                  key={idx}
                  options={options}
                  control={control}
                  disabled={options.length === 1}
                  name={fieldName}
                  width={width === 'auto' ? '100%' : width}
                  required={customField.mandatory === 'yes'}
                  label={label}
                />
              );
            } else {
              return (
                <CCPTextField
                  key={idx}
                  type={customField.inputType}
                  control={control}
                  name={fieldName}
                  required={customField.mandatory === 'yes'}
                  label={label}
                  isCustomHelperText
                  width={width}
                />
              );
            }
          })}
        </CustomFieldsWrapper>
      )}
    </>
  );
};

export default CustomFields;
