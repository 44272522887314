import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { useIntl } from 'react-intl';
import { CCPDefaultButton } from 'components';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { SettingsActions } from 'features/settings/store';
import { useAppDispatch, useAppSelector } from 'store';
import { EmailDistributionModalValuesProps, ListType } from 'types';
import Loader from '@components/Loader';
import EmailDistributionModal from './EmailDistributionModal';

type EditUserInDistributionListProps = {
  listType: ListType;
  email: string;
  description: string;
};

const EditUserInDistributionList = ({
  listType,
  email,
  description,
}: EditUserInDistributionListProps) => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const { activeCompany } = useAppSelector(CompanySelectors.getCompanyState);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleUpdate = async (data: EmailDistributionModalValuesProps) => {
    if (activeCompany) {
      setIsLoading(true);
      await dispatch(
        SettingsActions.editRecordInDistributionList({
          email: data.email,
          oldEmail: email,
          description: data.description,
          listType,
        }),
      );
      setIsLoading(false);
      handleClose();
    }
  };

  return (
    <>
      <Loader show={isLoading} />
      <CCPDefaultButton variant='text' onClick={handleOpenModal} startIcon={<EditIcon />}>
        {formatMessage({
          id: 'distribution_list.cta.edit_recipient',
          defaultMessage: 'Edit',
        })}
      </CCPDefaultButton>
      <EmailDistributionModal
        isEditPopup
        isOpen={isModalOpen}
        onSubmit={handleUpdate}
        closeModal={handleClose}
        email={email}
        description={description}
      />
    </>
  );
};

export default EditUserInDistributionList;
