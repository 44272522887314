import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { CompanyActions } from 'features/company/store';
import { CompanySelectors } from 'features/company/store/company.selectors';
import { UserManagementActions } from 'features/userManagement/store';
import { AuthenticationActions } from 'features/authentication/store';
import { AuthenticationSelectors } from 'features/authentication/store/authentication.selectors';
import { useNavigate } from 'react-router';
import { useLanguage } from 'hooks';
import { getCompanyTokenData } from 'utils/authentication';
import { ContractsActions } from 'features/contract/store';
import { CompanyRole } from '@localTypes/user';

export interface MainLayoutProps {
  children: JSX.Element | JSX.Element[];
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { authenticated, error, userInfo } = useAppSelector(
    AuthenticationSelectors.getAuthentication,
  );
  const { activeCompany, companiesNames } = useAppSelector(CompanySelectors.getCompanyState);

  const activeLanguage = useLanguage();

  useEffect(() => {
    document.documentElement.lang = activeLanguage;
  }, [activeLanguage]);

  useEffect(() => {
    if (activeCompany) {
      getCompanyTokenData().then(resp => {
        if (resp.length === 0) {
          return;
        }
        if (resp.filter(item => item.companyId === activeCompany.companyId).length !== 0) {
          //now user list also required for Usermgmt users (in settings > activation letter tab)
          dispatch(UserManagementActions.getUsers({ companyId: activeCompany.companyId }));
        }
        if (resp.filter(item => item.role.includes(CompanyRole.Usermgmt)).length !== 0) {
          dispatch(ContractsActions.getEUCondition({ companyId: activeCompany.companyId }));
        }
      });

      dispatch(CompanyActions.getCompany({ companyId: activeCompany.companyId }));
      dispatch(AuthenticationActions.getUserInfo({ companyId: activeCompany.companyId }));
      dispatch(ContractsActions.getContracts({ companyId: activeCompany.companyId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCompany?.companyId]);

  useEffect(() => {
    if (authenticated) {
      dispatch(CompanyActions.getCompaniesNames());
      dispatch(CompanyActions.getCountriesList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  useEffect(() => {
    if (error && error.message === 'NEW_PASSWORD_REQUIRED' && error.name) {
      navigate(`/${activeLanguage}/initial-password?email=${encodeURIComponent(error.name)}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    const activeCompanyId = Number(localStorage.getItem('activeCompanyId'));

    const filterActiveCompany =
      companiesNames?.data.length === 1
        ? companiesNames?.data[0]
        : companiesNames?.data?.find(item => item?.companyId === activeCompanyId);

    if (!activeCompany && companiesNames.data.length > 1) {
      //needs to be fixed
      if (userInfo?.companyId !== companiesNames.data[0].companyId)
        dispatch(
          AuthenticationActions.getUserInfo({ companyId: companiesNames.data[0].companyId }),
        );
    }

    if (companiesNames!.data!?.length > 1 && activeCompanyId && filterActiveCompany) {
      dispatch(CompanyActions.updateActiveCompany(filterActiveCompany));
    } else if (companiesNames!.data!?.length > 1) {
      dispatch(CompanyActions.updateActiveCompany(null));
    } else if (filterActiveCompany) {
      dispatch(CompanyActions.updateActiveCompany(filterActiveCompany));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companiesNames, dispatch]);

  return <>{children}</>;
};

export default MainLayout;
