import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { FetchingStatus } from 'types';

import { forgotPasswordSubmit } from './forgotPasswordSubmit';
import { AuthenticationState } from '../../../interfaces';

export const forgotPasswordSubmitExtraReducers = (
  builder: ActionReducerMapBuilder<AuthenticationState>,
) => {
  builder.addCase(forgotPasswordSubmit.pending, (state, action) => {
    state.forgotPassword.fetchingStatus = FetchingStatus.PENDING;
    state.forgotPassword.error = null;
  });
  builder.addCase(forgotPasswordSubmit.fulfilled, (state, action) => {
    state.forgotPassword.fetchingStatus = FetchingStatus.FULFILLED;
    state.forgotPassword.error = null;
    state.forgotPassword.activeStep = 3;
  });
  builder.addCase(forgotPasswordSubmit.rejected, (state, action) => {
    state.forgotPassword.fetchingStatus = FetchingStatus.REJECTED;
    state.forgotPassword.error = action.error;
  });
};
