import * as yup from 'yup';

export const AddUserSchema = yup
  .object({
    name: yup
      .string()
      .required('form.employee.error.first_name.required')
      .min(2, 'schema.add.new_user.first_name.min')
      .max(255, 'schema.add.new_user.first_name.max')
      .matches(/^(?!\s+$).*/, 'schema.add.new_user.first_name.match'),
    surname: yup
      .string()
      .required('form.employee.error.last_name.required')
      .min(2, 'schema.add.new_user.last_name.min')
      .max(255, 'schema.add.new_user.last_name.max')
      .matches(/^(?!\s+$).*/, 'schema.add.new_user.last_name.match'),
    position: yup.string().required('schema.add_personal_info.error.position.required'),
    email: yup
      .string()
      .trim()
      .required('schema.add_personal_info.error.email.required')
      .email('schema.add_personal_info.error.email.email'),
    Admin: yup.boolean(),
    Invoice: yup.boolean(),
    Usermgmt: yup.boolean(),
  })
  .test('checkboxGroupTest', {}, obj => {
    if (obj.Admin || obj.Invoice || obj.Usermgmt) {
      return true;
    }

    return new yup.ValidationError('schema.add.error.checkbox', null, 'checkboxGroupTest');
  })
  .required();

export default AddUserSchema;
